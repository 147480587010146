import { useEffect, FC, useState } from "react";

import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { Combobox, GroupedCombobox, Select as HightouchUiSelect } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, Container } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermission } from "src/contexts/permission-context";
import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon } from "src/ui/icons";
import { CreatableSelect, Select } from "src/ui/select";
import { NEW_ICON_MAP } from "src/utils/destinations";

import { ExtendedOption, Option } from "../../../../formkit";
import { suggest } from "../../utils/automap";
import { useFormkitContext, ColumnOption } from "./formkit-context";
import { Mapper, resolveFromValue } from "./mapper";
import { formatFromColumnOption, formatOptionLabel } from "./mappings";
import { MappingsHeader } from "./mappings-header";
import { JsonColumnProps, MappingType } from "./types";

type Props = {
  name: string;
  options?: ExtendedOption[];
  /**
  @default Columns in the model. Use ExtendedOptions for overriding that from model from destination.
  */
  fromOptions?: ExtendedOption[];
  /**
   * Type of mapping override for the left side of the mapping.
   */
  fromType?: "destinationOnlyMapping";
  /**
  @default Name of model
  */
  fromLabel?: string;
  /**
  @default Icon of source
  */
  fromIcon?: string;
  fromLoadingOptions?: boolean;
  fromReloadOptions?: () => void;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: Option[];
  loading?: boolean;
  object?: string;
  error?: string;
  reload?: () => void;
  useHightouchUi?: boolean;
  advanced?: boolean;
  mappingTypes?: MappingType[];
  templates?: { name: string; description: string; placeholders?: string[] }[];
};

const retrieveErrorMessage = (errors, name: string) => {
  for (const direction of ["from", "to"]) {
    const mappingPath = `${name}.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

/**
 * XXX: This is for making the options backward compatible
 *      since the new hightouchui needs the options to be a grouped options only.
 */
const toGroupedOption = (
  options: (ExtendedOption | ColumnOption)[],
  ungroupedOptionsLabel: string,
): { label: string; options: Option[] }[] => {
  const groupedOptions: any[] = [];
  const unGroupedOptions: any[] = [];

  for (const option of options) {
    if (Array.isArray(option?.["options"])) groupedOptions.push(option);
    else unGroupedOptions.push(option);
  }

  if (unGroupedOptions.length) groupedOptions.push({ label: ungroupedOptionsLabel, options: unGroupedOptions });

  return groupedOptions;
};

export const Mapping: FC<Readonly<Props>> = ({
  name,
  object,
  options,
  fromType,
  fromOptions,
  fromLabel,
  fromIcon,
  fromLoadingOptions,
  fromReloadOptions,
  creatable,
  creatableTypes,
  loading,
  reload,
  error,
  useHightouchUi = false,
  advanced = false,
  mappingTypes,
  templates,
}) => {
  const columns = fromOptions ? fromOptions : useFormkitContext().columns;
  const { errors } = useDestinationForm();
  const { watch, setValue } = useFormContext();

  const [jsonColumnProperties, setJsonColumnProperties] = useState<JsonColumnProps>({
    selectedColumnProps: undefined,
    allColumnsProps: undefined,
  });

  const loadEligibleInlineMapperColumns = () => {
    Sentry.captureException(new Error("loadEligibleInlineMapperColumns called for mapping formkit component."));
  };

  const value = watch(name);

  useEffect(() => {
    if (!value) {
      return setValue(name, {});
    }
  }, [value]);

  const permission = usePermission();

  if (!value) {
    return null;
  }

  return (
    <Column aria-label={`${name} mapping`}>
      <Grid
        sx={{
          gridTemplateColumns: useHightouchUi ? "minmax(0, 1fr) 16px minmax(0, 1fr) 36px" : "1fr max-content 1fr max-content",
          alignItems: "center",
        }}
      >
        <MappingsHeader
          columns={4}
          fromIcon={fromIcon}
          fromLabel={fromLabel}
          fromLoadingOptions={fromLoadingOptions}
          fromReloadOptions={fromReloadOptions}
          loading={loading}
          object={object}
          reload={reload}
          useHightouchUi={useHightouchUi}
          usingCustomFromOptions={Boolean(fromOptions)}
        />
      </Grid>
      <Container sx={{ marginTop: "12px" }}>
        <Controller
          name={name}
          render={(data) => {
            const destinationOptions = [...(options || [])];

            const isDestinationOptionExists = destinationOptions.some((option) => option.value === data.field.value?.to);

            // If mapping destination is selected, but option doesn't exist, this value was entered by the user
            const isCustomDestinationOption = Boolean(data.field.value?.to && !isDestinationOptionExists);

            if (isCustomDestinationOption) {
              destinationOptions.push({
                label: data.field.value?.to,
                value: data.field.value?.to,
              });
            }

            return (
              <Grid
                sx={{
                  gridTemplateColumns: useHightouchUi
                    ? "minmax(0, 1fr) 16px minmax(0, 1fr) 36px"
                    : "1fr max-content 1fr max-content",
                  alignItems: "center",
                }}
              >
                {advanced ? (
                  <Mapper
                    value={data.field.value || {}}
                    templates={templates ?? []}
                    onChange={(value) => {
                      if (!data.field.value?.to && destinationOptions?.length && value.type === MappingType.STANDARD) {
                        const fieldName = resolveFromValue(value.from);
                        data.field.onChange(suggest({ label: fieldName, value: value.from }, destinationOptions));
                      } else {
                        data.field.onChange({ to: data.field.value?.to, object: data.field.value?.object, ...value });
                      }
                    }}
                    onReloadEligibleInlineMapperColumns={loadEligibleInlineMapperColumns}
                    jsonColumnProperties={jsonColumnProperties}
                    onChangeJsonColumnProperties={setJsonColumnProperties}
                    selectedOption={undefined}
                    useHightouchUi={useHightouchUi}
                    isError={!!errors?.[`${data.field.name}.from`]}
                    isDisabled={permission.unauthorized}
                    mappingTypes={mappingTypes}
                  />
                ) : useHightouchUi ? (
                  <GroupedCombobox<any, string | undefined>
                    isDisabled={permission.unauthorized}
                    isInvalid={Boolean(errors?.[`${data.field.name}.from`])}
                    optionAccessory={(option) => {
                      return option?.value?.type === "additionalColumnReference"
                        ? {
                            type: "icon",
                            icon: Square3Stack3DIcon,
                          }
                        : undefined;
                    }}
                    optionGroups={toGroupedOption(columns, fromLabel ?? "Columns")}
                    optionLabel={(option) => {
                      return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                    }}
                    placeholder="Select a column..."
                    value={data.field.value?.from}
                    width="100%"
                    onChange={(value) => {
                      if (fromType) {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                          type: fromType,
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                        });
                      }
                    }}
                  />
                ) : (
                  <Select
                    formatOptionLabel={formatFromColumnOption}
                    isError={!!errors?.[`${data.field.name}.from`]}
                    options={columns}
                    placeholder="Select a column..."
                    value={data.field.value?.from}
                    onChange={(option) => {
                      if (fromType) {
                        data.field.onChange({
                          ...data.field.value,
                          from: option?.value || undefined,
                          type: fromType,
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          from: option?.value || undefined,
                        });
                      }
                    }}
                  />
                )}

                <ArrowRightIcon color="base.3" size={16} />

                <Column sx={useHightouchUi ? { gap: 2 } : undefined}>
                  {creatable ? (
                    <>
                      {useHightouchUi ? (
                        <Combobox
                          supportsCreatableOptions
                          emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          isClearable
                          isDisabled={permission.unauthorized}
                          isInvalid={Boolean(errors?.[`${data.field.name}.to`])}
                          isLoading={loading}
                          optionAccessory={(option) => {
                            return option?.extendedType?.type
                              ? {
                                  type: "icon",
                                  icon: NEW_ICON_MAP[option.extendedType.type],
                                }
                              : undefined;
                          }}
                          optionLabel={(option) => {
                            return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                          }}
                          options={destinationOptions}
                          placeholder="Select or add a field..."
                          value={data.field.value?.to}
                          width="100%"
                          onChange={(value) => {
                            const option = (options || []).find((option) => option.value === value);

                            data.field.onChange({
                              ...data.field.value,
                              to: value || undefined,
                              fieldType: option?.extendedType?.type || undefined,
                            });
                          }}
                          onCreateOption={(inputValue) => {
                            data.field.onChange({
                              ...data.field.value,
                              to: inputValue || undefined,
                            });
                          }}
                        />
                      ) : (
                        <CreatableSelect
                          isClearable
                          empty="Type in a field name and Hightouch will create the field in the destination!"
                          formatCreateLabel={(string) => `Create field "${string}"`}
                          formatOptionLabel={formatOptionLabel}
                          isError={!!errors?.[`${data.field.name}.to`]}
                          isLoading={loading}
                          isValidNewOption={(inputValue, _, selectOptions) => {
                            return !selectOptions?.find((v) => v.value === inputValue) && Boolean(inputValue);
                          }}
                          options={options || []}
                          placeholder="Select or add a field..."
                          value={data.field.value?.to}
                          onChange={(option) =>
                            data.field.onChange({
                              ...data.field.value,
                              to: option?.value || undefined,
                              fieldType: option?.type || undefined,
                            })
                          }
                          onCreateOption={(value) => {
                            data.field.onChange({ ...data.field.value, to: value || undefined });
                          }}
                        />
                      )}

                      {creatableTypes && isCustomDestinationOption && (
                        <>
                          {useHightouchUi ? (
                            <HightouchUiSelect
                              isDisabled={permission.unauthorized}
                              options={creatableTypes}
                              placeholder="Select a field type..."
                              value={data.field.value?.fieldType}
                              width="100%"
                              onChange={(value) => {
                                data.field.onChange({
                                  ...data.field.value,
                                  fieldType: value,
                                });
                              }}
                            />
                          ) : (
                            <Select
                              options={creatableTypes}
                              placeholder="Select a field type..."
                              value={data.field.value?.fieldType}
                              onChange={(creatableType) => {
                                data.field.onChange({ ...data.field.value, fieldType: creatableType?.value });
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {useHightouchUi ? (
                        <Combobox
                          isDisabled={permission.unauthorized}
                          isInvalid={Boolean(errors?.[`${data.field.name}.to`])}
                          isLoading={loading}
                          optionAccessory={(option) => {
                            return option?.extendedType?.type
                              ? {
                                  type: "icon",
                                  icon: NEW_ICON_MAP[option.extendedType.type],
                                }
                              : undefined;
                          }}
                          optionLabel={(option) => {
                            return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                          }}
                          options={destinationOptions}
                          placeholder="Select a field..."
                          value={data.field.value?.to}
                          width="100%"
                          onChange={(value) => {
                            data.field.onChange({
                              ...data.field.value,
                              to: value || undefined,
                            });
                          }}
                        />
                      ) : (
                        <Select
                          formatOptionLabel={formatOptionLabel}
                          isError={!!errors?.[`${data.field.name}.to`]}
                          options={options || []}
                          placeholder="Select a field..."
                          value={data.field.value?.to}
                          onChange={(option) => data.field.onChange({ ...data.field.value, to: option?.value || undefined })}
                        />
                      )}
                    </>
                  )}
                </Column>
              </Grid>
            );
          }}
        />
      </Container>
      <FieldError error={retrieveErrorMessage(errors, name) || error} />
    </Column>
  );
};

import { useState, FC } from "react";

import { pickBy, isEqual } from "lodash";

import {
  AlertsOrderBy,
  SyncQuery,
  useCreateSyncAlertsMutation,
  useCreateSyncTemplateAlertsMutation,
  useDeleteSyncAlertsMutation,
  useUpdateSyncMutation,
  useUpdateSyncTemplateMutation,
  useWorkspaceAlertsQuery,
  useWorkspaceAlertsWithStatusesQuery,
} from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { useTableConfig } from "src/ui/table";

import { AlertSelector, WorkspaceAlertSortKeys } from "../alerts/alert-selector";
import { SidebarForm } from "../page";
import { Permission } from "../permission";

type SyncAlert = Omit<SyncQuery["syncs"][0]["sync_alerts"][0], "__typename">;

type Props = {
  alerts: SyncAlert[];
  rowThresholdTotal?: number;
  rowThresholdAttempted?: number;
  syncId?: number;
  templateId?: number;
};

export type ProcessedSyncAlerts = Record<string, { row: boolean; fatal: boolean; success: boolean }>;

export const SyncAlerts: FC<Readonly<Props>> = ({ alerts, rowThresholdTotal, rowThresholdAttempted, syncId, templateId }) => {
  const processedAlerts: ProcessedSyncAlerts = alerts?.reduce((obj, instance) => {
    if (!obj[instance.id]) {
      obj[instance.id] = {};
    }
    obj[instance.id]["row"] = instance.row_error ?? null;
    obj[instance.id]["fatal"] = instance.fatal_error ?? null;
    obj[instance.id]["success"] = instance.alert_on_success ?? null;

    return obj;
  }, {});

  const [updating, setUpdating] = useState<boolean>(false);
  const [syncAlerts, setSyncAlerts] = useState(processedAlerts);
  const [thresholds, setThresholds] = useState({ total: rowThresholdTotal, attempted: rowThresholdAttempted });

  const { onSort, orderBy } = useTableConfig<AlertsOrderBy>({
    defaultSortKey: "name",
    sortOptions: Object.values(WorkspaceAlertSortKeys),
  });

  const { data: workspaceAlertsData } = useWorkspaceAlertsQuery(undefined, {
    enabled: Boolean(templateId),
  });

  const { data: workspaceAlertsWithStatusesData } = useWorkspaceAlertsWithStatusesQuery(
    { orderBy, syncId: String(syncId) },
    { enabled: Boolean(syncId) },
  );

  const workspaceAlerts = (workspaceAlertsData || workspaceAlertsWithStatusesData)?.alerts || [];

  const { mutateAsync: upsertSyncAlerts } = useCreateSyncAlertsMutation();
  const { mutateAsync: upsertSyncTemplateAlerts } = useCreateSyncTemplateAlertsMutation();
  const { mutateAsync: deleteAlertInstances } = useDeleteSyncAlertsMutation();
  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { mutateAsync: updateSyncTemplate } = useUpdateSyncTemplateMutation();

  const filteredSyncAlerts = pickBy(syncAlerts, (a) => {
    return a?.row !== null || a?.fatal !== null;
  });

  const update = async () => {
    setUpdating(true);

    const removedInstanceIds = Object.keys(syncAlerts).filter((i) => !Object.keys(filteredSyncAlerts).includes(i));

    const addedInstances = Object.entries(filteredSyncAlerts).map(([alertId, states]) => {
      return {
        destination_instance_id: syncId ? String(syncId) : null,
        sync_template_id: templateId ? Number(templateId) : null,
        alert_id: String(alertId),
        fatal_error: states?.fatal,
        row_error: states?.row,
        alert_on_success: states?.success,
      };
    });

    if (removedInstanceIds.length) {
      await deleteAlertInstances({ syncId: String(syncId), syncTemplateId: templateId, alertIds: removedInstanceIds });
    }

    if (addedInstances.length) {
      if (syncId) {
        await upsertSyncAlerts({ input: addedInstances });
      } else {
        await upsertSyncTemplateAlerts({ input: addedInstances });
      }
    }

    if (rowThresholdAttempted !== thresholds?.attempted || rowThresholdTotal !== thresholds?.total) {
      if (syncId) {
        await updateSync({
          id: String(syncId),
          object: {
            row_threshold_attempted: thresholds?.attempted ? String(thresholds.attempted) : null,
            row_threshold_total: thresholds?.total ? String(thresholds.total) : null,
          },
        });
      } else {
        await updateSyncTemplate({
          id: Number(templateId),
          input: {
            row_threshold_attempted: thresholds?.attempted ? String(thresholds.attempted) : null,
            row_threshold_total: thresholds?.total ? String(thresholds.total) : null,
          },
        });
      }
    }

    setUpdating(false);
  };

  const dirty =
    rowThresholdAttempted !== thresholds?.attempted ||
    rowThresholdTotal !== thresholds?.total ||
    !isEqual(filteredSyncAlerts, processedAlerts);

  return (
    <Row sx={{ width: "100%" }}>
      <Row mr={8} sx={{ flexGrow: 1, alignItems: "flex-start" }}>
        <AlertSelector
          alerts={syncAlerts}
          orderBy={orderBy}
          showStatuses={Boolean(syncId)}
          thresholds={thresholds}
          workspaceAlerts={workspaceAlerts}
          onAlertsChange={setSyncAlerts}
          onSort={onSort}
          onThresholdsChange={setThresholds}
        />
      </Row>
      <SidebarForm
        hideInviteTeammate
        hideSendMessage
        buttons={[
          <Permission key={0}>
            <Button disabled={!dirty} loading={updating} sx={{ width: "100%" }} onClick={update}>
              Save
            </Button>
          </Permission>,
        ]}
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/alerting/#setup`}
        invite="If you need help setting up this sync"
        name="monitoring and alerting"
      />
    </Row>
  );
};

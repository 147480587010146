import { components } from "react-select";
import { Flex } from "theme-ui";

export const ValueContainer = (props) => {
  const sx = props.getValue()?.[0]?.sx || {};

  return (
    <Flex sx={{ flex: 1, alignItems: "center", ...sx }}>
      {props?.selectProps?.beforeValue}
      <components.ValueContainer {...props} />
      {props?.selectProps?.afterValue}
    </Flex>
  );
};

import React from "react";

import { Link, Box as HightouchUiBox, Switch } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { Text } from "theme-ui";

import { SyncStatusBadge } from "@hightouch/app/src/components/extensions/sync-status-badge";
import { usePermission } from "src/contexts/permission-context";

import { Box, Row } from "../../ui/box";
import { Card } from "../../ui/card";

interface GitSyncableResourceToggleProps {
  fieldName: string;
  last_run_at?: string;
  error: any;
  description?: string;
  title: string;
  learnMoreUrl?: string;
  icon?: React.ReactNode;
  blocked?: boolean;
  onToggle?: (value: boolean) => void;
  renderError?: (error: any) => React.ReactNode;
  stdout?: { text: string; title: string; status: "success" };
}

export const GitSyncableResourceToggle = ({
  fieldName,
  error,
  learnMoreUrl,
  title,
  icon,
  renderError,
  blocked,
  onToggle,
  description,
  last_run_at,
  stdout,
}: GitSyncableResourceToggleProps) => {
  const syncEnabled = useFormContext().getValues(fieldName);

  const updatePermission = usePermission();

  const header = (
    <Row sx={{ justifyContent: "space-between", alignItems: "center", pb: "12px" }}>
      <Row sx={{ alignItems: "center" }}>
        {icon}
        <Text sx={{ ml: icon ? 2 : 0, fontSize: "20px" }}>{title}</Text>
      </Row>
      <Controller
        name={fieldName}
        render={({ field }) => (
          <HightouchUiBox alignItems="center" display="flex" gap={2}>
            <Text
              sx={{
                textTransform: "uppercase",
                fontSize: "10px",
                color: "base.4",
                fontWeight: "bold",
              }}
            >
              {field.value ? "Enabled" : "Disabled"}
            </Text>

            <Switch
              isChecked={field.value}
              isDisabled={blocked ?? updatePermission.unauthorized}
              onChange={(value) => {
                field.onChange(value);

                if (typeof onToggle === "function") {
                  onToggle(value);
                }
              }}
            />
          </HightouchUiBox>
        )}
      />
    </Row>
  );
  const descriptionComponent = (
    <Row sx={{ flexDirection: "column", maxWidth: "80%" }}>
      <Row sx={{ pb: 1 }}>
        <Text sx={{ mb: 2, fontSize: 1, color: "base.5" }}>{description}</Text>
      </Row>
      {learnMoreUrl && (
        <Row>
          <Link href={learnMoreUrl}>Learn more</Link>
        </Row>
      )}
    </Row>
  );

  const errorElement = () => {
    if (!error) {
      return null;
    }
    if (renderError) {
      return renderError(error);
    }
    return (
      <Row sx={{ pt: "24px" }}>
        <Box sx={{ maxWidth: "100%" }}>
          {error.message && (
            <Text
              as="b"
              sx={{
                p: 2,
                borderRadius: "5px 5px 0 0",
                fontWeight: "bold",
                wordBreak: "break-all",
                whiteSpace: "pre-wrap",
                fontSize: "13px",
                background: "reds.1",
              }}
            >
              {error.message}
            </Text>
          )}
          <Text
            as="pre"
            sx={{
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "10px",
              p: 2,
              borderRadius: "0 0 5px 5px",
              maxHeight: "250px",
              background: "reds.0",
              overflowY: "auto",
            }}
          >
            {JSON.stringify(error, null, 2)}
          </Text>
        </Box>
      </Row>
    );
  };

  const stdoutElement = () => {
    if (!stdout) {
      return null;
    }
    return (
      <Row sx={{ pt: "24px" }}>
        <Box sx={{ maxWidth: "100%" }}>
          <Text
            sx={{
              p: 2,
              fontWeight: "bold",
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "13px",
              borderRadius: "5px 5px 0 0",
              background: "base.1",
            }}
          >
            {stdout.title}
          </Text>
          <Text
            as="pre"
            sx={{
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "10px",
              p: 2,
              maxHeight: "250px",
              background: "base.0",
              overflowY: "auto",
              borderRadius: "0 0 5px 5px",
            }}
          >
            {stdout.text}
          </Text>
        </Box>
      </Row>
    );
  };

  const syncStatus = (
    <>
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          pt: "24px",
          userSelect: "none",
        }}
      >
        <Text
          sx={{
            fontSize: "13px",
            fontWeight: 500,
            color: "base.6",
          }}
        >
          Sync Status
        </Text>
        <SyncStatusBadge error={error} lastAttemptedAt={last_run_at} setup={!last_run_at} />
      </Row>
      {stdoutElement()}
      {errorElement()}
    </>
  );

  return (
    <Card>
      {header}
      {description && descriptionComponent}
      {syncEnabled && !blocked && syncStatus}
    </Card>
  );
};

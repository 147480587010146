import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ArrowRightIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 13 12">
      <path d="M6.5 0L5.4425 1.0575L9.6275 5.25L0.5 5.25L0.5 6.75L9.6275 6.75L5.435 10.935L6.5 12L12.5 6L6.5 0Z" />
    </Icon>
  );
};

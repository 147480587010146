/**
 * Creates an object from a list of key, value pairs. Similar to `Array.prototype.fromEntries()`.
 *
 * Ex:
 * ```
 * const array = [{ key: '1', value: 'a' }, { key: '2', value: 'b' }];
 * const result = createObjectFromOptions(array);
 * console.log(result);
 * // { 1: 'a', 2: 'b' }
 * ```
 *
 * @param options The array of objects with key value pairs
 * @returns The transformed options
 */
export const createObjectFromOptions = (options: { key: string; value: string }[]): Record<string, string> => {
  const result = {};

  options.forEach((option) => {
    result[option.key] = option.value;
  });

  return result;
};

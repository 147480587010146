import { useState, FC } from "react";

import { Box, Link, Column, Row, Avatar } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { SuccessfulQueryResponse } from "src/graphql";
import { newIntercomMessage } from "src/lib/intercom";
import { Button } from "src/ui/button";
import { XIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import ErrorSrc from "src/ui/table/error.svg";
import { TextWithTooltip } from "src/ui/text";
import { getColumnName } from "src/utils/models";

import { ErrorModal } from "../modals/error-modal";

export interface Props {
  rows: SuccessfulQueryResponse["rows"] | undefined;
  parentModel: any;
  splitTestGroupName?: string;
  error: string | undefined;
}

export const AudienceResults: FC<Readonly<Props>> = ({ parentModel, rows, splitTestGroupName, error }) => {
  const [selection, setSelection] = useState<number | undefined>();

  const primaryLabelKey = parentModel.visual_query_primary_label
    ? getColumnName(parentModel.visual_query_primary_label, parentModel.columns)
    : parentModel.primary_key;
  const secondaryLabelKey = getColumnName(parentModel.visual_query_secondary_label, parentModel.columns);

  const selectedRow = typeof selection !== "undefined" ? rows?.[selection] : undefined;

  if (error) {
    return (
      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
        <Box as="img" src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
        <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center", mb: 2 }}>Error</Text>
        <ErrorModal error={error} />
      </Column>
    );
  }

  if (!rows || !rows?.length) {
    return (
      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
        <Box as="img" src={ErrorSrc} sx={{ mb: 4 }} />
        <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center" }}>No results</Text>
        <Text sx={{ mt: 2, maxWidth: "500px", textAlign: "center" }}>
          Modify the query to return at least one row, or{" "}
          <Box
            display="inline-block"
            onClick={() => {
              newIntercomMessage("Could you help me create a model for a query with no results?");
            }}
          >
            <Link href="">message us on Intercom</Link>
          </Box>{" "}
          if your query cannot return results.
        </Text>
      </Column>
    );
  }

  return (
    <>
      <Column sx={{ flex: 1, overflow: "hidden" }}>
        {Array.isArray(rows) && rows.length > 0 ? (
          <Column sx={{ overflowY: "auto" }}>
            {rows.map((row, index) => {
              return (
                <Row
                  key={row[parentModel.primary_key]}
                  sx={{
                    p: 2,
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "md",
                    ":hover": { bg: "gray.100" },
                  }}
                  onClick={() => setSelection(index)}
                >
                  <Header primaryLabelKey={primaryLabelKey} row={row} secondaryLabelKey={secondaryLabelKey} />
                </Row>
              );
            })}
          </Column>
        ) : (
          <Column sx={{ alignItems: "center", pt: 24, px: 4, overflowY: "auto" }}>
            <Text sx={{ fontSize: 3, fontWeight: "bold", mb: 1 }}>No rows found</Text>
            <Text sx={{ color: "base.5" }}>There are no rows with a primary label matching your search.</Text>
          </Column>
        )}
      </Column>
      <Modal
        footer={
          typeof selection !== "undefined" && (
            <>
              <Button disabled={selection === 0} variant="secondary" onClick={() => setSelection(selection - 1)}>
                Previous
              </Button>
              <Text sx={{ fontSize: 0, color: "base.5", mx: 2 }}>
                {selection + 1} / {rows?.length}
              </Text>
              <Button disabled={selection === rows?.length - 1} variant="secondary" onClick={() => setSelection(selection + 1)}>
                Next
              </Button>
            </>
          )
        }
        header={
          <>
            <Header primaryLabelKey={primaryLabelKey} row={selectedRow} secondaryLabelKey={secondaryLabelKey} />
            <Button variant="plain" onClick={() => setSelection(undefined)}>
              <XIcon color="base.4" />
            </Button>
          </>
        }
        isOpen={Boolean(selectedRow)}
        sx={{ width: "100%", maxWidth: "600px" }}
        onClose={() => setSelection(undefined)}
      >
        <Details row={selectedRow} splitTestGroupName={splitTestGroupName} />
      </Modal>
    </>
  );
};

const Details = ({ row, splitTestGroupName }) => {
  // We want to filter out the split test group assignment since this may change come sync time.
  const columns = Object.keys(row).filter((column) => column !== splitTestGroupName);
  const maxColumnChar = Math.min(
    columns.reduce((max, key) => Math.max(max, key.length), 0),
    32,
  );
  const maxColumnWidth = `${maxColumnChar * 12}px`;

  return (
    <>
      {columns.map((key) => {
        const value = row[key];
        const sanitized = typeof value === "object" ? JSON.stringify(value) : value?.toString();
        return (
          <Row key={key} sx={{ p: 2, borderBottom: "small", overflow: "hidden", flexShrink: 0 }}>
            <Text
              sx={{
                width: maxColumnWidth,
                flexShrink: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "semi",
                color: "base.6",
              }}
            >
              {key}
            </Text>
            <Text>{sanitized}</Text>
          </Row>
        );
      })}
    </>
  );
};

const Header = ({ primaryLabelKey, secondaryLabelKey, row }) => {
  let primary = row[primaryLabelKey];
  let secondary = row[secondaryLabelKey];

  if (typeof primary !== "string") {
    primary = JSON.stringify(primary);
  }

  if (typeof secondary === "object") {
    secondary = JSON.stringify(primary);
  }

  return (
    <Row sx={{ alignItems: "center" }}>
      <Avatar name={primary} />
      <Column sx={{ ml: 4, gap: 1 }}>
        {primary && (
          <TextWithTooltip fontWeight="semibold" message={primary}>
            {primary}
          </TextWithTooltip>
        )}
        {secondary && (
          <TextWithTooltip color="gray.500" size="sm" message={secondary}>
            {secondary}
          </TextWithTooltip>
        )}
      </Column>
    </Row>
  );
};

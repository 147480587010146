import { FC, useState, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Column, Text, IconButton, Link, SearchInput, Button, Spinner } from "@hightouchio/ui";

import { useDbtSyncModelsQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { DBTIcon } from "src/ui/icons";
import { InfoModal } from "src/ui/modal/info-modal";

import { Editor } from "../editor";
import { DbtQuery } from "./preview/dbt-query";
import { SelectorRow } from "./selector-row";

type Source = {
  id: string;
  name: string;
  type: string;
  definition: {
    name: string;
    icon: string;
    isSampleDataSource: boolean;
  };
};

type Props = {
  dbtModelId: any;
  onChange: (dbtModel: any) => void;
  source: Source;
};

export const DBTModelSelector: FC<Readonly<Props>> = ({ dbtModelId, onChange, source }) => {
  const [search, setSearch] = useState<string>("");
  const [showSQL, setShowSQL] = useState<boolean>(false);

  const {
    data,
    isFetching: loading,
    refetch,
  } = useDbtSyncModelsQuery(
    {
      sourceId: source?.id,
    },
    { enabled: Boolean(source?.id) },
  );

  const noDBTModels = !loading && !data?.dbt_sync_models?.length;

  const dbtModel = data?.dbt_sync_models?.find(({ id }) => id === dbtModelId);
  const available = data?.dbt_sync_models?.filter((model) => model?.removed !== true);

  useEffect(() => {
    if (noDBTModels && !loading) {
      analytics.track("Add Model Query Mode Error", {
        source_type: source?.type,
        source_name: source?.name,
        query_mode: "dbt_model",
        error: "No dbt models were synced",
      });
    }
  }, [loading, noDBTModels]);

  if (noDBTModels) {
    return (
      <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Text color="danger.base">No dbt models were synced from the git repository associated to this source.</Text>
        <Text color="danger.base">
          Please add your git credentials in the <Link href="/extensions/dbt-models">settings page</Link> and select a
          repository in your <Link href={`/sources/${source?.id}`}>source</Link>.
        </Text>
      </Column>
    );
  }

  const models = search
    ? available?.filter(({ schema, name, data }) => {
        const lowercaseSearch = search.toLowerCase();
        return (
          schema?.toLowerCase()?.includes(lowercaseSearch) ||
          name?.toLowerCase()?.includes(lowercaseSearch) ||
          data?.alias?.toLowerCase()?.includes(lowercaseSearch)
        );
      })
    : available;

  const isPlaceholder = loading || !models?.length;

  return (
    <>
      <Column overflow="hidden" flex={1} minWidth={0}>
        <Row overflow="hidden" gap={8}>
          <Column overflow="hidden" flex={1} minW={0} border="1px" borderColor="base.border" borderRadius="md">
            <Row align="center" gap={2} p={4} borderBottom="1px" borderColor="base.border">
              <SearchInput
                placeholder="Search dbt models..."
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                variant="secondary"
                aria-label="Refresh dbt models"
                icon={ArrowPathIcon}
                isLoading={loading}
                onClick={() => {
                  refetch();
                }}
              />
            </Row>
            <Column
              overflow="auto"
              flex={1}
              minWidth={0}
              p={isPlaceholder ? 10 : undefined}
              align={isPlaceholder ? "center" : undefined}
              justify={isPlaceholder ? "center" : undefined}
            >
              {loading ? (
                <Spinner size="lg" />
              ) : !models?.length ? (
                <>
                  <Text size="lg" fontWeight="semibold">
                    {search ? "No dbt models match your search" : "No dbt models found"}
                  </Text>
                  <Text color="text.secondary">Try refreshing to populate new dbt models</Text>
                </>
              ) : (
                <>
                  {models?.map((model) => {
                    const active = model?.id === dbtModelId;
                    return (
                      <SelectorRow
                        key={model?.id}
                        icon={<DBTIcon size={16} color="var(--chakra-colors-text-secondary)" />}
                        selected={active}
                        onClick={() => onChange(model)}
                      >
                        {`${model?.schema}.${model?.data?.alias || model?.name}`}
                      </SelectorRow>
                    );
                  })}
                </>
              )}
            </Column>
          </Column>
          {dbtModel && (
            <DbtQuery
              model={dbtModel}
              actions={
                <Button
                  onClick={() => {
                    setShowSQL(true);
                  }}
                >
                  View SQL
                </Button>
              }
            />
          )}
        </Row>
        <InfoModal height="90%" isOpen={showSQL} p={0} title="SQL" width="800px" onClose={() => setShowSQL(false)}>
          <Editor readOnly language="sql" value={dbtModel?.data?.compiled_sql} />
        </InfoModal>
      </Column>
    </>
  );
};

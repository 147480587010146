import { BaseType, Props, ValidatorSync } from "./base";
import punycode from "punycode/";
import tlds from "tlds";

export interface EmailType extends BaseType {
  type: "EMAIL";
}

export function email(props?: Props<EmailType>): EmailType {
  return {
    type: "EMAIL",
    ...props,
  };
}

// TODO remove `isValidEmail`?
//  /Users/michaelgilbert/ht/mono-repo/packages/backend/sdk/lib/email-validator.ts
// 8,17:  export function isValidEmail(email: string): boolean {
export const validator: ValidatorSync<EmailType> = (_) => {
  return (value) => {
    if (typeof value !== "string" || !isValidEmail(value))
      throw new Error(`Invalid email ${value}.`);
  };
};

//RFC 2822 regex modified to reject usernames longer than 64 characters
const RFC2822_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isValidEmail(email: string): boolean {
  return (
    RFC2822_EMAIL_REGEX.test(punycode.toASCII(email)) &&
    tlds.includes(email.split(".").pop()?.toLowerCase() as any)
  );
}

import { FC, useState, useMemo } from "react";

import { TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Text,
  Button,
  ButtonGroup,
  Heading,
  Row,
  Column,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
  SearchInput,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import sourcePlaceholder from "src/assets/placeholders/source.svg";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  sourceTypeFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabels } from "src/components/labels/edit-labels";
import { Page } from "src/components/layout";
import { BulkDeleteSourcesModal } from "src/components/modals/bulk-delete-sources-modal";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ConnectionsBoolExp,
  ConnectionsOrderBy,
  ResourcePermissionGrant,
  useAddLabelsToSourcesMutation,
  useDeleteSourcesMutation,
  useSourceDefinitionsQuery,
  useSourcesFiltersQuery,
  useSourcesQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Modal } from "src/ui/modal";
import { PageTable, TableColumn, useTableSort, OrderBy, SortOption } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";
import { useUser } from "../../contexts/user-context";

const initialSort: SortOption<keyof ConnectionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof ConnectionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "type", direction: OrderBy.Asc, label: "Type" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const placeholder = {
  image: searchPlaceholder,
  title: "No sources found",
  error: "Sources failed to load, please try again.",
};

export const Sources: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [warningOpen, setWarningOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { workspace } = useUser();

  const { labels } = useLabels();

  const { hasPermission: hasDeletePerm } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);

  const { mutateAsync: bulkDelete, isLoading: isBulkDeleting } = useDeleteSourcesMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSourcesMutation();

  const orderBy = useTableSort<ConnectionsOrderBy>(initialSort, sortOptions);

  const { data: allSourcesForFilters, isLoading: filtersLoading } = useSourcesFiltersQuery(
    {},
    { select: (data) => data.connections },
  );
  const { data: sourceDefinitions } = useSourceDefinitionsQuery({}, { select: (data) => data.getSourceDefinitions });

  // used for filtering
  const allSourcesWithDefinitionData = useMemo(() => {
    return (allSourcesForFilters ?? []).map((source) => {
      const definition = (sourceDefinitions ?? []).find(({ type }) => type === source.type);

      return { ...source, definition };
    });
  }, [sourceDefinitions, allSourcesForFilters]);

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "source",
      loading: filtersLoading,
      filters: {
        source: {
          options: sourceTypeFilterConfig(
            (allSourcesWithDefinitionData || []).map((source) => {
              return { connection: source, ...source };
            }),
          ),
          title: "Type",
        },
        created: { options: createdByFilterConfig(allSourcesWithDefinitionData || []), title: "Created by" },
        label: { options: labelFilterConfig(allSourcesWithDefinitionData || []), title: "Labels" },
      },
    };
  }, [allSourcesWithDefinitionData]);

  const {
    result: { state: filters, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, selectView, updateCurrentView, resetViewFilters, clearFilters },
  } = useFilters(filterDefinitions);

  const buildHasuraFilters = () => {
    const labelsFilters: ConnectionsBoolExp["_or"] = filters.label?.selected.map((filter) => {
      const key = filter.id.split(":")[0];
      const value = filter.id.split(":")[1];
      const obj = {};
      obj[key!] = value;
      return {
        tags: { _contains: obj },
      };
    });

    const hasuraFilters: ConnectionsBoolExp = {
      _and: [
        {
          type: !filters.source?.isAllSelected ? { _in: filters.source?.selected.map((filter) => filter.id) } : {},
        },
        !filters.label?.isAllSelected
          ? {
              _or: labelsFilters,
            }
          : {},
        !filters.created?.isAllSelected
          ? {
              _or: [
                {
                  created_by: { _in: filters.created?.selected.map((f) => f.id) },
                },
                {
                  created_by: { _is_null: true },
                },
              ],
            }
          : {},
      ],
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  };

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filters]);

  const {
    data: sources,
    error,
    isLoading,
  } = useSourcesQuery({ filters: hasuraFilters, orderBy }, { select: (data) => data.connections });

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a source, upgrade your plan.";

  const deleteSources = async () => {
    if (hasDeletePerm) {
      try {
        await bulkDelete({ ids: selectedRows.map(String) });

        toast({
          id: "delete-sources",
          title: "Selected sources were deleted",
          variant: "success",
        });

        onRowSelect([]);
      } catch (error) {
        // XXX: This uses the old deletion modal, that doesn't automatically toast on error
        toast({
          id: "delete-sources",
          title: "Couldn't delete selected sources",
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await Promise.all(
        selectedRows.map((r) => {
          return addLabels({ id: r.toString(), labels });
        }),
      );
      setAddingLabels(false);

      toast({
        id: "bulk-add-source-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "source",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-source-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: (source) => (
          <Row align="center" gap={2} overflow="hidden">
            <Text isTruncated fontWeight="medium">
              {source.name ?? "Private source"}
            </Text>
            <SourceBadges isSampleDataSource={source?.definition?.isSampleDataSource} />
          </Row>
        ),
      },
      {
        name: "Type",
        cell: (source) => (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon name={source?.definition?.name} src={source?.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {source?.definition?.name ?? "Private source"}
            </Text>
          </Row>
        ),
      },
      {
        ...LastUpdatedColumn,
        breakpoint: "md",
      },
    ],
    [sourceDefinitions],
  );

  return (
    <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
      <Page
        sidebar={
          <>
            <Row pr={6} pl={2}>
              <SearchInput
                placeholder="Search all sources..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Row>
            <Column px={2} overflow="auto">
              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="source"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </Column>
          </>
        }
        title="Sources"
      >
        <PageTable
          header={
            <>
              <Heading size="xl">Sources</Heading>
              <ButtonGroup>
                {selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize("source", selectedRows.length, true)} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        <MenuItem
                          icon={TagIcon}
                          onClick={() => {
                            setAddingLabels(true);
                          }}
                        >
                          Add labels
                        </MenuItem>
                        {hasDeletePerm && (
                          <>
                            <MenuDivider />
                            <MenuItem
                              icon={TrashIcon}
                              variant="danger"
                              onClick={() => {
                                setConfirmingDeletion(true);
                              }}
                            >
                              Delete
                            </MenuItem>
                          </>
                        )}
                      </MenuList>
                    </Menu>
                  </Row>
                )}
                <PermissionedLinkButton
                  href="/sources/new"
                  isDisabled={overageLockout}
                  permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Source Clicked");
                  }}
                >
                  Add source
                </PermissionedLinkButton>
              </ButtonGroup>
            </>
          }
          columns={columns}
          data={sources}
          error={Boolean(error)}
          loading={isLoading}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={({ id }, event) => openUrl(`/sources/${id}`, navigate, event)}
          onSelect={onRowSelect}
          sortOptions={sortOptions}
        />

        <BulkDeleteSourcesModal
          isOpen={isConfirmingDeletion}
          loading={isBulkDeleting}
          sources={selectedRows as string[]}
          workspaceName={workspace?.name ?? ""}
          onCancel={() => {
            setConfirmingDeletion(false);
          }}
          onDelete={deleteSources}
        />
        <Modal
          footer={
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setWarningOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  navigate("/sources/new");
                }}
              >
                Add source
              </Button>
            </>
          }
          isOpen={warningOpen}
          title="Demo source"
          onClose={() => {
            setWarningOpen(false);
          }}
        >
          <Text>This is Hightouch's demo source. Create your own source in less than 5 minutes!</Text>
        </Modal>

        <EditLabels
          description="You can label sources that have similar properties"
          existingLabelOptions={labels}
          hint="Example keys: team, project, region, env."
          isOpen={addingLabels}
          loading={loadingAddLabels}
          saveLabel={`Apply to ${selectedRows.length} ${pluralize("source", selectedRows.length)}`}
          title="Add labels"
          onClose={() => setAddingLabels(false)}
          onSave={bulkAddLabels}
        />
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.source) {
    return <Sources />;
  }

  return (
    <Page fullWidth title="Sources">
      <Heading mb={8} size="xl">
        Sources
      </Heading>
      <Placeholder
        content={{
          image: sourcePlaceholder,
          title: "No sources in this workspace",
          body: "A source is where your data is stored and managed. Hightouch supports 20+ popular sources, including data warehouses like Snowflake and BigQuery, spreadsheets like Airtable and Google Sheets, and other data systems like SFTP, S3, and Tableau.",
          button: (
            <PermissionedLinkButton
              href="/sources/new"
              permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add source
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

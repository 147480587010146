import { FC } from "react";

import { BoxProps, Column } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

import { colors } from "./colors";

type Props = {
  conditionType: ConditionType.And | ConditionType.Or;
};

export const GroupIndicatorBar: FC<Readonly<Props & BoxProps>> = ({ conditionType = ConditionType.And, ...props }) => {
  return (
    <Column
      bg={colors.base[conditionType]}
      borderTopLeftRadius="md"
      borderBottomLeftRadius="md"
      flexShrink={0}
      width="24px"
      {...props}
    />
  );
};

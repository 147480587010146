import { ChangeEvent, FC, useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Checkbox,
  Column,
  ConfirmationDialog,
  IconButton,
  Paragraph,
  Row,
  SectionHeading,
  Select,
  StatusBadge,
  StatusBadgeProps,
  Switch,
  Text,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { addDays, intervalToDuration } from "date-fns";
import pluralize from "pluralize";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { array, object, string } from "yup";

import { ActionBar } from "src/components/action-bar";
import {
  EnrichmentStatus,
  EnrichmentStatusQuery,
  MatchboostingStatsQuery,
  ObjectColumnFragment,
  ResourcePermissionGrant,
  useEnrichmentStatusQuery,
  useMatchboostingProvidersQuery,
  useMatchboostingStatsQuery,
  useStartEnrichmentMutation,
  useUpdateMatchboostingSettingsMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { ArrowRightIcon } from "src/ui/icons";
import { formatDatetime } from "src/utils/time";

import Status from "./assets/status.svg";

type Props = {
  segmentType: "model" | "schema";
  model:
    | {
        id: any;
        matchboosting_enabled: boolean;
        connection: {
          definition: {
            type: string;
          } | null;
        } | null;
      }
    | null
    | undefined;
  columns: ObjectColumnFragment[] | undefined;
};

const validationSchema = object()
  .shape({
    columns: array().of(
      object().shape({
        columnName: string().required("Column name is required"),
        identifier: string().required("Identifier is required"),
      }),
    ),
  })
  .optional();

export const MatchBoostingSettingsWrapper: FC<Readonly<Props>> = ({ segmentType, model, columns }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <MatchBoostingSettings segmentType={segmentType} model={model} columns={columns} />
    </FormProvider>
  );
};

const MatchBoostingSettings: FC<Readonly<Props>> = ({ segmentType, model, columns }) => {
  const { toast } = useToast();
  const [enabled, setEnabled] = useState(Boolean(model?.matchboosting_enabled));
  const [cancelling, setCancelling] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
  const { hasPermission: hasUpdateColumnPermission } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Update] },
  ]);
  const { data: lastEnrichedStats } = useMatchboostingStatsQuery(
    {
      modelId: model?.id,
    },
    {
      enabled: Boolean(model?.id),
      refetchInterval: 5000, // 5 seconds is often enough.
      select: (data) => data.matchboosting_stats[0],
    },
  );

  const { data: enrichmentStatus, refetch } = useEnrichmentStatusQuery(
    {
      modelId: model?.id?.toString() ?? "",
    },
    {
      refetchInterval: 5000, // 5 seconds is often enough.
      enabled: Boolean(model?.id),
      select: (data) => data.enrichmentStatus,
    },
  );

  const { data: providers } = useMatchboostingProvidersQuery();

  const { mutateAsync: updateMatchboostingSettings } = useUpdateMatchboostingSettingsMutation();

  const { mutateAsync: runEnrichment } = useStartEnrichmentMutation();

  const objectColumns = columns?.map((col) => {
    return {
      identifier: col.semantic_type,
      columnName: col.name,
    };
  });

  useEffect(() => {
    model && setEnabled(model.matchboosting_enabled);
  }, [model?.matchboosting_enabled]);

  useEffect(() => {
    setCancelling(false);
  }, [enrichmentStatus?.status]);

  const rows = providers?.matchboostingProviders[0]?.optionalIdentifiers || [];

  const rowOptions = rows.map((row) => {
    return {
      label: row.alias,
      value: row.identifier,
    };
  });

  const formName = "columns";
  const { watch, reset, handleSubmit } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: formName,
  });

  const watchFieldArray = watch(formName);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    const existingMappings = columns
      ?.filter((column) => column.semantic_type)
      ?.map((column) => {
        return {
          id: column.name,
          columnName: column.name,
          identifier: column.semantic_type,
        };
      });

    reset({
      [formName]: existingMappings,
    });
  }, [columns]);

  const columnOptions =
    columns?.map((column) => ({
      value: column.name,
      label: column.name,
      semantic_type: column.semantic_type,
    })) || [];

  const onSubmit = async () => {
    if (controlledFields.length === 0) {
      toast({
        id: "update-column",
        title: "You must map at least 1 identifier",
        variant: "error",
      });
      return;
    }

    const columnsChanged: { name: string; semantic_type: string }[] = [];
    for (const originalColumn of objectColumns || []) {
      const newColumn = controlledFields?.find((col) => col.columnName === originalColumn.columnName);
      if ((newColumn?.identifier || null) !== originalColumn.identifier) {
        columnsChanged.push({
          name: originalColumn.columnName,
          semantic_type: newColumn?.identifier || null, // set to null to clear the semantic type.
        });
      }
    }

    try {
      await updateMatchboostingSettings({
        segmentId: model?.id,
        updates: columnsChanged.map((col) => {
          return {
            where: {
              name: { _eq: col.name },
              model_id: { _eq: model?.id },
            },
            _set: {
              semantic_type: col.semantic_type,
            },
          };
        }),
        enabled,
      });
    } catch (err) {
      Sentry.captureException(err);
      toast({
        id: "update-column",
        title: `Failed to updated match boosting settings`,
        variant: "error",
      });
    }

    // There is already an enrichment running - we need to get confirmation to cancel
    // the existing one before starting a new one.
    if (enrichmentStatus?.status === EnrichmentStatus.Processing) {
      setIsConfirmCancelOpen(true);
      return;
    }

    await cancelAndRunEnrichment();
  };

  const cancelAndRunEnrichment = async () => {
    try {
      if (enrichmentStatus?.status === EnrichmentStatus.Processing) {
        setCancelling(true);
      }
      setIsConfirmCancelOpen(false);
      await runEnrichment({
        modelId: model?.id.toString(),
        sendEmail,
      });
      refetch();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "run-enrichment",
        title: `Failed to start enrichment`,
        variant: "error",
      });
    }
  };

  const disableMatchbooster = async () => {
    await updateMatchboostingSettings({
      enabled: false,
      segmentId: model?.id,
      updates: [],
    });
  };

  return (
    <>
      {/* so we have some scroll before the sticky footer */}
      <Row mb={32}>
        <Column gap={6} maxWidth="700px">
          <Column gap={2}>
            <Row alignItems="center" gap={4}>
              <SectionHeading>Enable match boosting</SectionHeading>
              <Switch
                isChecked={enabled}
                isDisabled={!hasUpdateColumnPermission}
                onChange={async (value) => {
                  if (!value) {
                    if (model?.matchboosting_enabled) {
                      setShowConfirmDisable(true);
                    } else {
                      setEnabled(false);
                    }
                    return;
                  }

                  setEnabled(true);
                  if (!controlledFields.find((field) => field.identifier === "email")) {
                    append({
                      identifier: "email",
                    });
                  }
                }}
              />
            </Row>
            <Paragraph color="text.secondary" size="sm">
              Enrich this {segmentType} with additional identifiers from Hightouch's data partners to increase match rates when
              syncing to supported destinations.
            </Paragraph>
          </Column>

          {enabled && (
            <>
              <Column gap={2}>
                <SectionHeading>
                  Which identifiers should Hightouch use to find additional data to enrich your model?
                </SectionHeading>
                <Paragraph color="text.secondary" size="sm">
                  When match boosting is turned on, all syncs using this {segmentType} will also use the enriched data from
                  Hightouch's data partners.
                </Paragraph>
              </Column>

              <Box
                display="grid"
                gridTemplateColumns="1fr max-content 1fr max-content"
                rowGap={4}
                columnGap={2}
                alignItems="flex-start"
              >
                <>
                  <Text color="text.secondary" fontWeight="semibold" isTruncated size="sm" textTransform="uppercase">
                    Identifier type
                  </Text>
                  <Box />
                  <Text color="text.secondary" fontWeight="semibold" isTruncated size="sm" textTransform="uppercase">
                    Column Name
                  </Text>
                  <Box />
                </>

                {controlledFields.map(({ id }, index) => (
                  <>
                    <Controller
                      name={`${formName}.${index}.identifier`}
                      render={({ field, fieldState: { error } }) => (
                        <Column>
                          <Select
                            isDisabled={!hasUpdateColumnPermission}
                            options={rowOptions}
                            isOptionDisabled={(option) => {
                              return controlledFields.find((f) => {
                                return f.identifier === option.value && f.id !== id;
                              });
                            }}
                            isInvalid={Boolean(error)}
                            placeholder="Select an identifier..."
                            onChange={async (identifier) => {
                              field.onChange(identifier);
                            }}
                            value={field.value}
                          />
                          {error && (
                            <Text mt={1} color="text.danger">
                              {error.message}
                            </Text>
                          )}
                        </Column>
                      )}
                    />

                    <Row height="32px" alignItems="center">
                      <ArrowRightIcon color="base.3" size={16} />
                    </Row>

                    <Controller
                      name={`${formName}.${index}.columnName`}
                      render={({ field, fieldState: { error } }) => (
                        <Column>
                          <Select
                            isDisabled={!hasUpdateColumnPermission}
                            isOptionDisabled={(option) => {
                              return controlledFields.find((f) => {
                                return f.columnName === option.value && f.id !== id;
                              });
                            }}
                            options={columnOptions || []}
                            placeholder="Select a column..."
                            isInvalid={Boolean(error)}
                            onChange={async (columnName) => {
                              field.onChange(columnName);
                            }}
                            value={field.value}
                          />

                          {error && (
                            <Text mt={1} color="text.danger">
                              {error.message}
                            </Text>
                          )}
                        </Column>
                      )}
                    />

                    <IconButton
                      aria-label="Remove mapping"
                      icon={XMarkIcon}
                      isDisabled={!hasUpdateColumnPermission}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </>
                ))}

                <Select
                  isDisabled={!hasUpdateColumnPermission}
                  options={rowOptions}
                  placeholder="Add an identifier..."
                  onChange={async (identifier) => {
                    append({
                      identifier,
                    });
                  }}
                  value={undefined}
                />
              </Box>
            </>
          )}
        </Column>
        {enabled && (
          <Column flexGrow={1} gap={5} ml={14} borderLeft="1px" borderColor="gray.300" pl={8} borderStyle="solid">
            <Box>
              <Column gap={2}>
                <EnrichmentBadge
                  lastEnrichedStats={lastEnrichedStats}
                  segmentType={segmentType}
                  latestEnrichment={enrichmentStatus}
                  cancelling={cancelling}
                />
              </Column>
            </Box>
          </Column>
        )}
      </Row>

      {enabled && (
        <ActionBar>
          <Row gap={4}>
            <Button
              isDisabled={cancelling || enrichmentStatus?.status === EnrichmentStatus.Cancelling}
              size="lg"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save & run enrichment
            </Button>
            <Row alignItems="center" gap={2}>
              <Checkbox
                isChecked={sendEmail}
                onChange={(checked: ChangeEvent<HTMLInputElement>) => {
                  setSendEmail(checked.target.checked);
                }}
              />
              <Text>Email me when enrichment is complete</Text>
            </Row>
          </Row>
        </ActionBar>
      )}

      <ConfirmationDialog
        confirmButtonText="Cancel & re-run"
        isOpen={isConfirmCancelOpen}
        title="Cancel enrichment?"
        variant="danger"
        onClose={() => setIsConfirmCancelOpen(false)}
        onConfirm={cancelAndRunEnrichment}
      >
        <Paragraph>Enrichment on this {segmentType} is already running. Do you want to cancel and re-run enrichment?</Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        confirmButtonText="Disable"
        isOpen={showConfirmDisable}
        title="Disable match booster"
        variant="danger"
        onClose={() => setShowConfirmDisable(false)}
        onConfirm={disableMatchbooster}
      >
        <Paragraph>Do you want to disable match booster on this {segmentType}?</Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const EnrichmentBadge = ({
  lastEnrichedStats,
  cancelling,
  latestEnrichment,
  segmentType,
}: Omit<EnrichmentBadgeProps, "hasEnrichedBefore">) => {
  const enrichedStats = lastEnrichedStats;

  // Hasn't run before, and not running now, we don't show anything on the sidebar.
  if (!enrichedStats && !latestEnrichment) {
    return null;
  }

  if (!latestEnrichment) {
    return null;
  }

  const hasEnrichedBefore = Boolean(enrichedStats);

  let text: string | undefined, variant: StatusBadgeProps["variant"] | undefined;
  // Manually show canceling when the backend request is running.
  if (cancelling || latestEnrichment?.status === EnrichmentStatus.Cancelling) {
    text = "Cancelling";
    variant = "inactive";
  } else if (latestEnrichment.status === EnrichmentStatus.Cancelled) {
    text = "Cancelled";
    variant = "inactive";
  } else if (latestEnrichment.status === EnrichmentStatus.Failed) {
    text = "Failed";
    variant = "error";
  } else if (latestEnrichment.status === EnrichmentStatus.Success) {
    text = "Boosted";
    // leave variant as undefined - this indicates we are using the boosted badge.
  } else if (latestEnrichment.status === EnrichmentStatus.Processing) {
    text = "Processing";
    variant = "processing";
  }

  return (
    <Column gap={4}>
      <Row>{variant ? <StatusBadge variant={variant}>{text}</StatusBadge> : <Box as="img" src={Status} />}</Row>
      <Text size="sm" color="text.secondary">
        <LastEnrichedDetails
          hasEnrichedBefore={hasEnrichedBefore}
          lastEnrichedStats={lastEnrichedStats}
          latestEnrichment={latestEnrichment}
          cancelling={cancelling}
          segmentType={segmentType}
        />
      </Text>
    </Column>
  );
};

interface EnrichmentBadgeProps {
  hasEnrichedBefore: boolean;
  lastEnrichedStats: MatchboostingStatsQuery["matchboosting_stats"][0] | undefined | null;
  latestEnrichment: EnrichmentStatusQuery["enrichmentStatus"] | undefined | null;
  cancelling: boolean;
  segmentType: string;
}

const LastEnrichedDetails = ({
  segmentType,
  hasEnrichedBefore,
  lastEnrichedStats,
  latestEnrichment,
  cancelling,
}: EnrichmentBadgeProps) => {
  if (!hasEnrichedBefore) {
    if (latestEnrichment?.status === EnrichmentStatus.Processing) {
      return (
        <Row>
          <Text>
            Currently enriching this {segmentType}. Processing time can take up to a few hours. Please check back to see if
            enrichment is complete.
          </Text>
        </Row>
      );
    } else if (latestEnrichment?.status === EnrichmentStatus.Failed) {
      return (
        <Row>
          <Text>Something went wrong during enrichment. Try again.</Text>
        </Row>
      );
    }
  }

  const lastEnrichedAt = lastEnrichedStats?.last_enriched_at;
  const nextScheduled = lastEnrichedAt ? addDays(new Date(lastEnrichedAt), 14) : null;
  const enrichmentDuration = intervalToDuration({
    start: 0,
    end: lastEnrichedStats?.enriched_duration_ms ?? 0,
  });

  let formattedDuration = `${enrichmentDuration.minutes}m`;
  if ((enrichmentDuration?.hours || 0) > 0) {
    formattedDuration = `${enrichmentDuration.hours} ${pluralize("hr", enrichmentDuration.hours)} ${
      enrichmentDuration.minutes
    }m`;
  }

  return (
    <Column gap={4}>
      <Column>
        <Text fontWeight="semibold">Enrichment schedule</Text>
        <Text>Every two weeks</Text>
      </Column>
      <Column>
        <Text fontWeight="semibold">Last enrichment</Text>
        <Text>{lastEnrichedAt && formatDatetime(lastEnrichedAt)}</Text>
        <Text>Duration: {formattedDuration}</Text>
      </Column>
      {/* Only show the next schedule time if the current enrichment is a success,
       * or in otherwords, enrichment is not currently running
       */}
      {latestEnrichment?.status === EnrichmentStatus.Success && !cancelling && (
        <Column>
          <Text fontWeight="semibold">Next enrichment</Text>
          <Text>{nextScheduled && formatDatetime(nextScheduled.toISOString())}</Text>
        </Column>
      )}
    </Column>
  );
};

import { useState, FC } from "react";

import { Box, Heading, Button } from "@hightouchio/ui";
import { groupBy } from "lodash";

import { Settings } from "src/components/settings";
import { DestinationTiles } from "src/components/settings/destination-tiles";
import { FeatureCatalog } from "src/components/settings/feature-catalog";
import { WorkspaceMetricsTable, WorkspaceMetrics } from "src/components/settings/workspace-metrics-table";
import { useUser } from "src/contexts/user-context";
import { useDestinationDefinitionsQuery, useOrganizationMetricsQuery } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";

export const Usage: FC = () => {
  const { workspace } = useUser();
  const { data: organizationMetrics, isLoading: loadingOrgUsage } = useOrganizationMetricsQuery();
  const { data: entitlementsData, isLoading: loadingEntitlements } = useEntitlements(true);
  const { data: definitionsData, isLoading: definitionsLoading } = useDestinationDefinitionsQuery();

  const [showAllWorkspaces, setShowAllWorkspaces] = useState(false);
  const toggleAllWorkspaces = () => {
    setShowAllWorkspaces((prev) => !prev);
  };

  let usageMetrics: WorkspaceMetrics[] = organizationMetrics?.getOrganizationMetrics?.metrics || [];
  const usageDataLength = usageMetrics.length;
  if (!showAllWorkspaces && usageDataLength > 5) {
    usageMetrics = usageMetrics.slice(0, 5);
  }

  const rolesByWorkspaceId = groupBy(organizationMetrics?.getOrganizationMetrics?.roles, "workspace_id");

  usageMetrics.forEach((workspace) => {
    workspace.roles = rolesByWorkspaceId[workspace.id] || [];
  });

  return (
    <Settings route="usage" title="Usage">
      <Box display="flex" flexDirection="column" gap={20}>
        <Box>
          <Heading>Workspaces {workspace?.organization ? ` in ${workspace.organization.name}` : ""}</Heading>

          <Box mt={6}>
            <WorkspaceMetricsTable isLoading={loadingOrgUsage} metrics={usageMetrics} />
          </Box>

          {usageDataLength > 5 && (
            <Box mt={1}>
              <Button onClick={toggleAllWorkspaces}>{showAllWorkspaces ? "Show less" : "Show more"}</Button>
            </Box>
          )}
        </Box>

        <Box>
          <Heading>Destinations</Heading>

          <Box mt={6}>
            <DestinationTiles
              definitions={definitionsData}
              destinationBillingTypes={entitlementsData?.destinationBillingTypes || []}
              isLoadingDefinitions={definitionsLoading}
              isLoadingDestinationBillingTypes={loadingEntitlements}
            />
          </Box>
        </Box>

        <Box>
          <Heading>Platform features</Heading>

          <Box mt={6}>
            <FeatureCatalog />
          </Box>
        </Box>
      </Box>
    </Settings>
  );
};

import { FC, useState, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Column, SearchInput, Text, IconButton, Spinner } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TableIcon } from "src/ui/icons";

import { SelectorRow } from "./selector-row";

type Props = {
  objectDefinitions: any;
  reload: () => void;
  onChange: (table: string) => void;
  table: string | null | undefined;
  loading: boolean;
  source: {
    name: string;
    definition: {
      icon: string;
      name: string;
    };
  };
};

export const TableSelector: FC<Readonly<Props>> = ({ loading, table, onChange, objectDefinitions, reload, source }) => {
  const [search, setSearch] = useState<string>("");
  const [initialReload, setInitialReload] = useState(false);

  const customerObjectDefinitions = objectDefinitions?.filter(
    ({ object_definition_group }) => object_definition_group?.name?.toLowerCase() !== "hightouch_planner",
  );

  const tables = search
    ? customerObjectDefinitions?.filter(({ name, object_definition_group }) => {
        const lowercaseName = name?.toLowerCase();
        const displayName = object_definition_group?.name
          ? `${object_definition_group?.name?.toLowerCase()}.${lowercaseName}`
          : lowercaseName;

        return displayName.includes(search.toLowerCase());
      })
    : customerObjectDefinitions;

  useEffect(() => {
    if (!loading && !objectDefinitions?.length && !initialReload) {
      reload();
      setInitialReload(true);
    }
  }, [loading, objectDefinitions, initialReload]);

  const isPlaceholder = loading || !tables?.length;

  return (
    <Column width="100%" border="1px" borderColor="base.border" borderRadius="md" overflow="hidden" minWidth={0}>
      <Row align="center" px={4} py={4} borderBottom="1px" borderColor="base.border" gap={4} justify="space-between">
        <Row align="center" gap={2}>
          <IntegrationIcon src={source?.definition.icon} name={source?.definition.name} />
          <Text fontWeight="medium" size="lg">
            {source?.name ?? "Private source"}
          </Text>
        </Row>
        <Row gap={2}>
          <SearchInput placeholder="Search tables..." value={search} onChange={(event) => setSearch(event.target.value)} />
          <IconButton
            variant="secondary"
            aria-label="Refresh tables"
            icon={ArrowPathIcon}
            isLoading={loading}
            onClick={() => {
              reload();
            }}
          />
        </Row>
      </Row>
      <Column
        overflow="hidden"
        flex={1}
        minWidth={0}
        p={isPlaceholder ? 10 : undefined}
        align={isPlaceholder ? "center" : undefined}
        justify={isPlaceholder ? "center" : undefined}
      >
        {loading ? (
          <Spinner size="lg" />
        ) : !tables?.length ? (
          <>
            <Text size="lg" fontWeight="semibold">
              {search ? "No tables match your search" : "No tables found"}
            </Text>
            <Text color="text.secondary">Try refreshing to populate the tables</Text>
          </>
        ) : (
          <Column overflow="auto" flex={1} minWidth={0}>
            {tables?.map(({ name, object_definition_group }) => {
              const key = `${object_definition_group ? `${object_definition_group.name}.` : ""}${name}`;
              const active = key === table;

              return (
                <SelectorRow
                  key={key}
                  icon={<TableIcon size={16} color="var(--chakra-colors-text-secondary)" />}
                  selected={active}
                  onClick={() => onChange(key)}
                >
                  {key}
                </SelectorRow>
              );
            })}
          </Column>
        )}
      </Column>
    </Column>
  );
};

import { Link, Text, Box } from "@hightouchio/ui";

export const Terms = ({ variant }: { variant: "dark" | "light" }) => (
  <Box
    sx={{
      a: {
        fontSize: "inherit",
        color: variant === "dark" ? "lightspeed.400" : "gray.600",
        ":hover": { color: variant === "dark" ? "#B2D82A" : "gray.700" },
        ":active": { color: variant === "dark" ? "#8DA929" : "gray.800" },
      },
    }}
  >
    <Text size="sm" color={variant === "dark" ? "white" : "gray.900"}>
      By using Hightouch, you agree to our{" "}
      <Link href={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`}>privacy policy</Link> and{" "}
      <Link href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`}>terms of service</Link>.
    </Text>
  </Box>
);

import { FC, useEffect, useState } from "react";

import { Box, Column, Row, Heading, FormField, Select, TextInput, useToast, Textarea } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Explore } from "src/components/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { ScheduleManager } from "src/components/schedule/schedule-manager";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import { useCreateSyncMutation, SourceQuery, DestinationQuery, useCreateModelMutation, useModelQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { Step, Wizard } from "src/ui/wizard/wizard";
import { useModelRun, useModelState, useQueryState } from "src/utils/models";
import { isScheduleComplete } from "src/utils/schedule";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type Props = {
  source: SourceQuery["connections_by_pk"];
  destination: DestinationQuery["destinations_by_pk"];
  onSubmit: ({ id }: { id: string }) => void;
  onCancel: () => void;
};

export const CreateOnboardingSyncWizard: FC<Readonly<Props>> = ({ destination, source, onSubmit, onCancel }) => {
  const { toast } = useToast();
  const { user, workspace } = useUser();
  const flags = useFlags();
  const [modelId, setModelId] = useState<string>();
  const { modelState, setName: setModelName, setPrimaryKey, setDescription } = useModelState();
  const [schedule, setSchedule] = useState<any>({ type: ScheduleType.MANUAL });
  const [config, setConfig] = useState<any>();
  const [queryType, setQueryType] = useState<QueryType>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const { getSlug: getSyncSlug } = useResourceSlug(ResourceType.Sync);
  const { getSlug: getModelSlug } = useResourceSlug(ResourceType.Model);
  const [step, setStep] = useWizardStepper(0);
  const [syncDescription, setSyncDescription] = useState<string>("");

  const { data: model } = useModelQuery(
    {
      id: String(modelId),
    },
    { enabled: Boolean(modelId), select: (data) => data.segments_by_pk },
  );

  const { mutateAsync: createSync } = useCreateSyncMutation();
  const createModelMutation = useCreateModelMutation();

  const { queryState, isQueryDefined, setSQL, setTable, setDBTModel, setLookerLook, setSigma, setCustomQuery } =
    useQueryState();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(queryType, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const createModel = async () => {
    const slug = await getModelSlug(modelState.name);

    const data = await createModelMutation.mutateAsync({
      input: {
        slug,
        query_type: queryType,
        name: modelState.name,
        description: modelState.description,
        primary_key: modelState.primaryKey,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        query_integrations: queryState?.sigma,
        custom_query: queryState?.customQuery,
        columns: { data: columns },
        destination_instances: { data: [] },
        git_sync_metadata:
          queryType === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        draft: false,
      },
    });

    if (!data) {
      return;
    }

    const id = data.insert_segments_one?.id;

    analytics.track("Model Config Completed", {
      source_type: source?.type,
      source_id: source?.id,
      model_id: id,
      query_type: queryType,
    });

    analytics.track("Model Created", {
      workspace_id: workspace?.id,
      workspace_slug: workspace?.slug,
      model_id: id,
      model_name: modelState?.name,
      source_id: source?.id,
      source_type: source?.type,
      query_type: queryType,
      origin_page: location.pathname,
    });

    setModelId(id);
  };

  const submit = async () => {
    const slug = await getSyncSlug(`${model?.name} to ${destination?.name}`);

    const data = await createSync({
      object: {
        slug,
        destination_id: destination?.id,
        description: syncDescription,
        segment_id: model?.id,
        config: { ...config, configVersion: destination?.definition?.configVersion },
        schedule: schedule?.type === "manual" ? null : schedule,
        created_by: user?.id != null ? String(user?.id) : undefined,
        draft: workspace?.approvals_required,
      },
    });

    const id = data?.insert_destination_instances_one?.id;

    analytics.track("Sync Config Completed", {
      source_type: source?.type,
      source_id: source?.id,
      model_id: model?.id,
      query_type: model?.query_type,
      destination_type: destination?.type,
      destination_id: destination?.id,
      sync_id: id,
    });

    analytics.track("Sync Created", {
      destination_id: destination?.id,
      destination_name: destination?.name,
      destination_type: destination?.definition?.name,
      model_id: model?.id,
      model_name: model?.name,
      schedule_type: schedule?.type,
    });

    // If we need to create a draft, we don't want to open the toast here.
    if (!workspace?.approvals_required) {
      toast({
        id: "create-sync",
        title: "Sync was created",
        variant: "success",
      });
    }

    if (typeof onSubmit === "function" && id) {
      onSubmit({ id });
    }
  };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (step === 1) {
      analytics.track("Model Config Started", {
        source_id: source?.id,
      });
    }
    if (step === 3) {
      analytics.track("Sync Config Started", {
        source_id: source?.id,
        model_id: model?.id,
        destination_id: destination?.id,
      });
    }
  }, [step]);

  const steps: Step[] = [
    {
      title: "Query source",
      disabled:
        !isQueryDefined(queryType) ||
        (source?.definition?.supportsResultSchema ? false : !hasQueryColumns || Boolean(queryError)),
      header: <Heading>Query your source</Heading>,
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          const { error } = (await getSchema()) || {};
          if (error) {
            return;
          }
        }
        setStep((step) => step + 1);
      },
      continueTooltip:
        // if query is defined and we don't support result schema,
        // ask them to either fix their error or preview before continuing
        isQueryDefined(queryType) && !source?.definition?.supportsResultSchema && !hasQueryColumns
          ? queryError
            ? "Fix the error and preview again to continue"
            : "Preview query to continue"
          : undefined,
      render: () => {
        return (
          <>
            {queryType ? (
              <Explore
                cancelQuery={cancelQuery}
                columns={columns}
                isResultTruncated={Boolean(isResultTruncated)}
                numRowsWithoutLimit={numRowsWithoutLimit}
                rows={rows}
                runQuery={runQuery}
                source={source}
                type={queryType}
                {...queryState}
                error={queryError}
                errorAtLine={queryErrorAtLine}
                isQueryDefined={isQueryDefined}
                loading={queryLoading}
                rowsPerPage={15}
                onCustomQueryChange={setCustomQuery}
                onDBTModelChange={setDBTModel}
                onLookerLookChange={setLookerLook}
                onSQLChange={setSQL}
                onSigmaChange={setSigma}
                onTableChange={setTable}
                onTypeChange={setQueryType}
              />
            ) : (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <QueryTypeSelect selected={queryType} source={source!} onChange={setQueryType} />
            )}
          </>
        );
      },
    },
    {
      title: "Configure model",
      header: <Heading>Configure your model</Heading>,
      onContinue: async () => {
        await createModel();
        setStep((step) => step + 1);
      },
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name })) ?? [];

        return (
          <Column gap={6}>
            <FormField label="Model name">
              <TextInput value={modelState?.name} onChange={(event) => setModelName(event.target.value)} />
            </FormField>
            <FormField isOptional label="Description">
              <Textarea
                placeholder="Enter a description..."
                value={modelState?.description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormField>
            <FormField
              description="This is the column that uniquely identifies each row (e.g., customer ID, email address, invoice number)"
              label="Primary key"
            >
              <Select
                isDisabled={!columnOptions?.length}
                optionLabel={(co) => co.label}
                optionValue={(co) => co.value}
                options={columnOptions as { value: string; label: string }[]}
                placeholder="Select a column..."
                value={columnOptions?.find((co) => co.value === modelState?.primaryKey)?.value}
                onChange={(column) => {
                  setPrimaryKey(column);
                }}
              />
            </FormField>
          </Column>
        );
      },
    },
    {
      title: "Configure sync",
      continueProps: { form: "destination-form", type: "submit" as const },
      onContinue: () => {}, // required to defer continue to DestinationForm onSubmit
      header: (
        <Row align="center" gap={4}>
          <Box
            as="img"
            alt={destination?.definition?.name}
            src={destination?.definition?.icon}
            sx={{ width: "32px", objectFit: "contain", height: "32px" }}
          />
          <Heading>Configure sync to {destination?.name || destination?.definition?.name}</Heading>
        </Row>
      ),
      render: () => {
        if (destination && model && source) {
          return (
            <DestinationForm
              hideSave
              destination={destination}
              destinationDefinition={destination?.definition}
              model={model}
              slug={destination.type}
              sourceDefinition={source.definition}
              onSubmit={(config) => {
                setConfig(config);
                setStep((step) => step + 1);
                return Promise.resolve();
              }}
            />
          );
        }
        return null;
      },
    },
    {
      title: "Finalize sync",
      disabled: !isScheduleComplete({ schedule, flags, workspace }),
      header: <Heading>Finalize settings for this sync</Heading>,
      render: () => (
        <Column gap={6}>
          <FormField label="Description" isOptional>
            <Textarea
              placeholder="Enter a description..."
              value={syncDescription}
              onChange={(e) => setSyncDescription(e.target.value)}
            />
          </FormField>
          <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
        </Column>
      ),
    },
  ];

  return <Wizard title="Create sync" step={step} steps={steps} setStep={setStep} onSubmit={submit} onCancel={onCancel} />;
};

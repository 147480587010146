import { FC, useMemo } from "react";

import { Row, Text, LinkButton, SearchInput } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant, SyncTemplatesBoolExp, useSyncTemplatesQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Table, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const SyncTemplates: FC = () => {
  const navigate = useNavigate();
  const { schemaV2 } = useFlags();
  const [search, setSearch] = useQueryState("search");

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SyncTemplatesBoolExp = {};

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }
    return hasuraFilters;
  }, [search]);

  const { data: syncTemplates, isLoading: syncTemplatesLoading } = useSyncTemplatesQuery(
    { filters: hasuraFilters },
    { keepPreviousData: true, select: (data) => data.sync_templates },
  );

  const columns: TableColumn[] = [
    {
      name: "Name",
      key: "name",
      cell: (name) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Parent model",
      cell: ({ segment: { name, connection: source } }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon name={source?.definition?.name} src={source?.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {name ?? "Private model"}
            </Text>
            <SourceBadges isSampleDataSource={source?.definition?.isSampleDataSource} />
          </Row>
        );
      },
    },
    {
      name: "Destination",
      cell: ({ destination }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon name={destination?.definition?.name} src={destination?.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {destination?.name ?? destination?.definition?.name ?? "Private destination"}
            </Text>
          </Row>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      breakpoint: "md",
    },
  ];

  return (
    <PermissionProvider permissions={[{ resource: "sync_template", grants: [ResourcePermissionGrant.Create] }]}>
      <Row py={8} justify="space-between">
        <SearchInput placeholder="Search by name..." value={search ?? ""} onChange={(event) => setSearch(event.target.value)} />
        <Permission>
          <LinkButton
            href={schemaV2 ? `/schema-v2/settings/sync-templates/new` : "/schema/sync-templates/new"}
            variant="primary"
          >
            Add sync template
          </LinkButton>
        </Permission>
      </Row>
      <Table
        columns={columns}
        data={syncTemplates}
        error={false}
        loading={syncTemplatesLoading}
        placeholder={{
          title: "No sync templates",
          error: "Sync templates failed to load, please try again.",
        }}
        onRowClick={({ id }, event) =>
          openUrl(schemaV2 ? `/schema-v2/settings/sync-templates/${id}` : `/schema/sync-templates/${id}`, navigate, event)
        }
      />
    </PermissionProvider>
  );
};

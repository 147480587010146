import { useState, FC } from "react";

import { Box, Button } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { ThemeUIStyleObject } from "theme-ui";

import { InfoModal } from "src/ui/modal/info-modal";

type Props = {
  variant?: "error" | "warning";
  error: string;
  buttonSize?: "small" | "large";
  buttonSx?: ThemeUIStyleObject;
};

export const ErrorModal: FC<Readonly<Props>> = ({ error, variant = "error" }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button size="sm" variant="warning" onClick={() => setIsOpen(true)}>
        View {variant}
      </Button>
      <InfoModal isOpen={isOpen} title={capitalize(variant)} onClose={() => setIsOpen(false)}>
        <Box whiteSpace="pre-line" minWidth="300px">
          {error}
        </Box>
      </InfoModal>
    </>
  );
};

import { FC, useEffect, useRef, useState, useMemo } from "react";

import { Skeleton, SkeletonBox, SearchInput, Box, Text as HightouchUiText } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { matchSorter } from "match-sorter";
import { Flex, Container } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Column, Row as BoxRow } from "src/ui/box";
import { Input } from "src/ui/input";

import { ColumnOption, useFormkitContext } from "./formkit-context";
import { extractEligibleInlineMapperColumns, isValidJsonColumnMetadata } from "./mappings";
import { FormProps, JsonColumnProps } from "./types";

interface CustomColumnOption {
  label: ColumnOption["label"];
  type?: ColumnOption["type"];
  value: string;
  options?: ColumnOption["options"];
}

const rowSx = {
  minHeight: "32px",
  alignItems: "center",
  fontSize: 0,
  gap: "8px",
  paddingTop: 1,
  px: "8px",
  borderBottom: "small",
};

type ArrayPropertiesInputProps = FormProps & {
  jsonColumnProperties: JsonColumnProps;
  columnOptions: CustomColumnOption[] | undefined;
};

export const ArrayPropertiesInput: FC<Readonly<ArrayPropertiesInputProps>> = ({
  value,
  onChange,
  onReloadEligibleInlineMapperColumns,
  columnOptions,
  useHightouchUi,
  jsonColumnProperties,
}) => {
  const [search, setSearch] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const selectedColumnRef = useRef<HTMLDivElement>(null);
  const { loadingRows, rows, reloadRows } = useDestinationForm();
  const { columns } = useFormkitContext();

  const options = useMemo(() => {
    if (search) {
      const filteredColumns = matchSorter(columnOptions ?? [], search, { keys: ["label"] });
      return filteredColumns;
    }

    return columnOptions;
  }, [columnOptions, search]);

  const searchInputExists = useMemo(() => {
    if (search === value.from) return true;

    for (const option of options ?? []) {
      if (option.label === search) return true;
    }
    return false;
  }, [search, value.from]);

  const handleReloadRows = async () => {
    await onReloadEligibleInlineMapperColumns(value.from);
  };

  /**
   * These two useEffects work together to handle two functionalities:
   * 1. On load, we want to check if the json column metadata is valid. We will reloadRows to validate the metadata if it is not.
   * 2. handleReloadRows, which calls onReloadEligibleInlineMapperColumns will load the metadata to state only if it is valid. Therefore, it needs to have 'rows' as a dependency
   *    because if rows is updated, it can mean that either 1. we loaded the new valid metadata after we found that the previous one is invalid. 2. The user clicked on the 'Reload button' to re-validate the metadata and we need to extract that new data and set it to state.
   * This approach is needed to avoid multiple re-renders. The ideal approach would've been to make `reloadRows` a promise that resolves with the row data and we preform the extraction afterwards. However, that was not how reloadRows worked at this time, so this approach was chosen for the sake of time.
   * If this should be revisited, reloadRows should be changed into a promise so it can handle this scenario without multiple useEffects.
   */

  useEffect(() => {
    const jsonColumns = extractEligibleInlineMapperColumns(columns);
    if (!isValidJsonColumnMetadata(jsonColumns)) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    handleReloadRows();
  }, [rows, jsonColumnProperties.selectedColumnProps, jsonColumnProperties.allColumnsProps]);

  useEffect(() => {
    const timer = setTimeout(() => searchRef.current?.focus(), 10);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    selectedColumnRef.current?.scrollIntoView();
  }, []);

  const handleEnterPress = (event) => {
    if (!loadingRows && event.key === "Enter" && search && !searchInputExists) {
      onChange({
        ...value,
        from: search,
        custom: true,
      });
    }
  };
  return (
    <Column sx={{ p: 3, flex: 1 }}>
      <Container sx={{ paddingBottom: "8px" }}>
        {useHightouchUi ? (
          <SearchInput
            ref={searchRef}
            placeholder="Search..."
            value={search}
            width="100%"
            onChange={(event) => setSearch(event.target.value)}
            onKeyDown={handleEnterPress}
          />
        ) : (
          <Input
            ref={searchRef}
            placeholder="Search..."
            sx={{ width: "100%", flex: 0 }}
            value={search}
            onChange={setSearch}
            onKeyDown={handleEnterPress}
          />
        )}
      </Container>
      {loadingRows ? (
        <ShimmerLoadingState />
      ) : (
        <>
          <Flex sx={{ flexDirection: "column", overflowY: "auto" }}>
            {(options || []).map((option) => {
              const selected = isEqual(option.value, value.from);
              return (
                <Row
                  key={option.value}
                  selected={selected}
                  selectedColumnRef={selectedColumnRef}
                  useHightouchUi={useHightouchUi}
                  value={option.value}
                  onClickHandler={() => {
                    onChange({
                      ...value,
                      from: option.value,
                      custom: undefined,
                    });
                  }}
                />
              );
            })}
            {value.custom && (
              <Row
                selected={true}
                selectedColumnRef={selectedColumnRef}
                useHightouchUi={useHightouchUi}
                value={`Select "${value.from}" as an expected column value...`}
              />
            )}
            {search && !searchInputExists && (
              <Row
                selected={isEqual(search, value.from)}
                selectedColumnRef={selectedColumnRef}
                useHightouchUi={useHightouchUi}
                value={`Select "${search}" as an expected column value...`}
                onClickHandler={() => {
                  onChange({
                    ...value,
                    from: search,
                    custom: true,
                  });
                }}
              />
            )}
          </Flex>
        </>
      )}
    </Column>
  );
};

const Row: FC<{
  selected: boolean;
  value: string;
  onClickHandler?: () => void;
  selectedColumnRef: React.MutableRefObject<HTMLDivElement | null>;
  useHightouchUi?: boolean;
}> = ({ selected, value, onClickHandler, selectedColumnRef, useHightouchUi = false }) => {
  if (useHightouchUi) {
    return (
      <Box
        ref={selected ? selectedColumnRef : undefined}
        _hover={{
          bg: "gray.100",
        }}
        alignItems="center"
        bg={selected ? "forest.100" : undefined}
        borderBottom="1px"
        borderColor="gray.300"
        cursor="pointer"
        display="flex"
        flex="0"
        gap="8px"
        minHeight="32px"
        pointerEvents={selected ? "none" : undefined}
        px={2}
        sx={
          selected
            ? {
                span: {
                  color: "forest.600",
                },
              }
            : undefined
        }
        onClick={onClickHandler}
      >
        <Box maxWidth="fit-content" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <HightouchUiText>{value}</HightouchUiText>
        </Box>
      </Box>
    );
  }
  return (
    <Flex
      ref={selected ? selectedColumnRef : undefined}
      sx={{
        ...rowSx,
        color: selected ? "forest" : undefined,
        cursor: "pointer",
        bg: selected ? "avocado" : undefined,
        pointerEvents: selected ? "none" : undefined,
        ":hover": { bg: "base.0" },
      }}
      onClick={onClickHandler}
    >
      <Container sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value}</Container>
    </Flex>
  );
};

const containersWidth = ["25%", "20%", "35%", "35%", "25%", "20%", "15%"];

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {containersWidth.map((width, index) => (
      <BoxRow key={index} sx={rowSx}>
        <Container sx={{ width, margin: 0 }}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Container>
      </BoxRow>
    ))}
  </Skeleton>
);

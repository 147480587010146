import { FC } from "react";

import { ErrorOriginInfo, isDestinationErrorOriginInfo, isSourceErrorOriginInfo } from "@hightouch/lib/sync/error-origin-types";
import { StatusBadge } from "@hightouchio/ui";
import { Link } from "react-router-dom";
import { Image, Box, Text } from "theme-ui";

import placeholderDestination from "src/components/permission/destination.svg";
import { Maybe } from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Row } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";
import { SourceIcon } from "src/utils/sources";

import { IntegrationRow } from "./sync-name";

export type Props = {
  destination:
    | Maybe<{
        id: string;
        name: Maybe<string>;
        definition: {
          icon: string;
          name: string;
        };
      }>
    | undefined;
  originInfo: ErrorOriginInfo;
  source:
    | Maybe<{
        id: string;
        name: string;
        definition: {
          icon: string;
          name: string;
        };
      }>
    | undefined;
};

export const ErrorSyncName: FC<Readonly<Props>> = ({ destination, originInfo, source }) => {
  const isDestinationError = isDestinationErrorOriginInfo(originInfo);
  const isSourceError = isSourceErrorOriginInfo(originInfo);
  const colors = {
    destinationBorder: isDestinationError ? "var(--chakra-colors-danger-border)" : "white",
    destinationHoverBg: isDestinationError ? "var(--chakra-colors-danger-background)" : "base.0",
    destinationHoverBorder: isDestinationError ? "var(--chakra-colors-danger-border)" : "primaries.3",
    sourceBorder: isSourceError ? "var(--chakra-colors-danger-border)" : "white",
    sourceHoverBg: isSourceError ? "var(--chakra-colors-danger-background)" : "base.0",
    sourceHoverBorder: isSourceError ? "var(--chakra-colors-danger-border)" : "primaries.3",
  };

  return (
    <Row sx={{ justifyContent: "normal", alignItems: "center", flex: 1 }}>
      {source ? (
        <Link to={`/sources/${source?.id}`}>
          <Row
            sx={{
              alignItems: "center",
              bg: "white",
              border: "small",
              borderColor: colors.sourceBorder,
              borderRadius: 1,
              color: "unset",
              p: 3,
              position: "relative",
              pr: 8,
              ":hover": {
                bg: colors.sourceHoverBg,
                borderColor: colors.sourceHoverBorder,
              },
            }}
          >
            {isSourceError && (
              <Row sx={{ position: "absolute", right: "-7px", textTransform: "uppercase", top: "-12px", width: "58px" }}>
                <StatusBadge variant="error">Error</StatusBadge>
              </Row>
            )}
            <SourceIcon placeholder source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text
                sx={{
                  fontSize: 0,
                  color: "base.4",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Source
              </Text>
              <IntegrationRow>
                <Text sx={{ fontWeight: "bold" }}>{source?.definition?.name || source?.name}</Text>
              </IntegrationRow>
            </Box>
          </Row>
        </Link>
      ) : (
        <Tooltip text="This source is only visible to some users">
          <Row
            sx={{
              alignItems: "center",
              bg: "white",
              border: "small",
              borderColor: colors.sourceBorder,
              borderRadius: 1,
              color: "unset",
              p: 3,
              position: "relative",
              pr: 8,
            }}
          >
            {isSourceError && (
              <Row sx={{ position: "absolute", right: "-7px", textTransform: "uppercase", top: "-12px", width: "58px" }}>
                <StatusBadge variant="error">Error</StatusBadge>
              </Row>
            )}
            <SourceIcon placeholder source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text
                sx={{
                  fontSize: 0,
                  color: "base.4",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Source
              </Text>
              <Text sx={{ fontWeight: "bold" }}>Private Source</Text>
            </Box>
          </Row>
        </Tooltip>
      )}

      <Arrow size={20} />

      <Row sx={{ alignItems: "center" }}>
        {destination ? (
          <Link to={`/destinations/${destination?.id}`}>
            <Row
              sx={{
                alignItems: "center",
                bg: "white",
                border: "small",
                borderColor: colors.destinationBorder,
                borderRadius: 1,
                color: "unset",
                p: 3,
                position: "relative",
                pr: 8,
                ":hover": {
                  bg: colors.destinationHoverBg,
                  borderColor: colors.destinationHoverBorder,
                },
              }}
            >
              {isDestinationError && (
                <Row sx={{ position: "absolute", right: "-7px", textTransform: "uppercase", top: "-12px", width: "58px" }}>
                  <StatusBadge variant="error">Error</StatusBadge>
                </Row>
              )}
              <Image alt={destination?.definition?.name} src={destination?.definition?.icon} width={32} />
              <Box sx={{ ml: 4 }}>
                <Text
                  sx={{
                    fontSize: 0,
                    color: "base.4",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Destination
                </Text>
                <IntegrationRow>
                  <Text sx={{ fontWeight: "bold" }}>{destination?.definition?.name || destination?.name}</Text>
                </IntegrationRow>
              </Box>
            </Row>
          </Link>
        ) : (
          <Tooltip text="This destination is only visible to some users">
            <Row
              sx={{
                alignItems: "center",
                bg: "white",
                border: "small",
                borderColor: colors.destinationBorder,
                borderRadius: 1,
                color: "unset",
                p: 3,
                position: "relative",
                pr: 8,
              }}
            >
              {isDestinationError && (
                <Row sx={{ position: "absolute", right: "-7px", textTransform: "uppercase", top: "-12px", width: "58px" }}>
                  <StatusBadge variant="error">Error</StatusBadge>
                </Row>
              )}
              <Image alt="Private destination" src={placeholderDestination} width={32} />
              <Box sx={{ ml: 4 }}>
                <Text
                  sx={{
                    fontSize: 0,
                    color: "base.4",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Destination
                </Text>
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ fontWeight: "bold" }}>Private Destination</Text>
                </Row>
              </Box>
            </Row>
          </Tooltip>
        )}
      </Row>
    </Row>
  );
};

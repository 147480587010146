import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { number, object, string } from "yup";

import {
  AggregationType,
  AttributionWindow,
  ConditionType,
  GoalConfig,
  IntervalUnit,
  RawColumn,
  RelatedColumn,
} from "src/types/visual";

export type MetricFormData = {
  config?: {
    type: ConditionType.Event;
    eventModelId: GoalConfig["eventModelId"] | null;
    relationshipId: GoalConfig["relationshipId"] | null;
    filter?: GoalConfig["filter"];
  };
  column?: RawColumn | RelatedColumn | null;
  aggregationType: AggregationType;
  attributionWindow: AttributionWindow;
};

const validationSchema = object().shape({
  aggregationType: string().oneOf([AggregationType.Count, AggregationType.Sum]).required("Aggregation type required"),
  column: object()
    .nullable()
    .when("aggregationType", {
      is: AggregationType.Sum,
      then: object().required("Column is required"),
    }),
  config: object().shape({
    relationshipId: string().nullable(),
    eventModelId: string().nullable().required("Event is required"),
  }),
  attributionWindow: object()
    .shape({
      quantity: number().required(),
      unit: string().oneOf([IntervalUnit.Day, IntervalUnit.Week, IntervalUnit.Month]).required(),
    })
    .required("Attribution window required"),
});

export const defaultValues: MetricFormData = {
  aggregationType: AggregationType.Count,
  column: null,
  attributionWindow: { quantity: 0, unit: IntervalUnit.Day },
  config: {
    type: ConditionType.Event,
    eventModelId: null,
    relationshipId: null,
    filter: { subconditions: [] },
  },
};

export const useMetricForm = (): UseFormReturn<MetricFormData> => {
  const formData = useForm({
    resolver: yupResolver(validationSchema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular type problem with Condition[]
    defaultValues: defaultValues,
  });

  return formData;
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const StringIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 18 18">
      <path d="M6.84961 13C6.79102 13 6.74219 12.9648 6.70312 12.8945C6.66797 12.8242 6.65039 12.7598 6.65039 12.7012C6.65039 12.6348 6.66406 12.584 6.69141 12.5488C6.71875 12.5137 6.76953 12.4922 6.84375 12.4844C7.19922 12.4648 7.48828 12.418 7.71094 12.3438C7.9375 12.2656 8.05078 12.1289 8.05078 11.9336V5.26562H6.58594C6.38281 5.26562 6.21484 5.38477 6.08203 5.62305C5.95312 5.85742 5.85742 6.11914 5.79492 6.4082C5.73633 6.69727 5.69922 6.94336 5.68359 7.14648C5.67578 7.21289 5.63672 7.26172 5.56641 7.29297C5.5 7.32422 5.42773 7.33984 5.34961 7.33984C5.29102 7.33984 5.22656 7.32227 5.15625 7.28711C5.08594 7.24805 5.05078 7.19922 5.05078 7.14062C5.07031 6.55469 5.09375 6 5.12109 5.47656C5.15234 4.94922 5.1875 4.68555 5.22656 4.68555H12.7676C12.8066 4.68555 12.8398 4.94922 12.8672 5.47656C12.8984 6 12.9238 6.55469 12.9434 7.14062C12.9434 7.19922 12.9082 7.24805 12.8379 7.28711C12.7676 7.32227 12.7031 7.33984 12.6445 7.33984C12.5664 7.33984 12.4922 7.32422 12.4219 7.29297C12.3555 7.26172 12.3184 7.21289 12.3105 7.14648C12.2637 6.72461 12.1738 6.30664 12.041 5.89258C11.9082 5.47461 11.6973 5.26562 11.4082 5.26562H10.0195V11.916C10.0195 12.0723 10.0801 12.1895 10.2012 12.2676C10.3223 12.3457 10.4609 12.3984 10.6172 12.4258C10.7734 12.4492 10.9688 12.4688 11.2031 12.4844C11.2891 12.4922 11.3457 12.5098 11.373 12.5371C11.4004 12.5605 11.4141 12.6152 11.4141 12.7012C11.4141 12.7598 11.3945 12.8242 11.3555 12.8945C11.3203 12.9648 11.2734 13 11.2148 13H6.84961Z" />
    </Icon>
  );
};

import { FC } from "react";

import { Row } from "@hightouchio/ui";

import { OrderBy } from "src/graphql";

import { ArrowDownIcon, ArrowUpIcon } from "../icons";

type SortIcon = {
  className?: string;
  sortDirection?: OrderBy | null;
};

export const SortIcon: FC<SortIcon> = ({ sortDirection, ...props }) => {
  return (
    <Row align="center" borderRadius="md" ml={3} {...props}>
      <ArrowUpIcon
        size={10}
        sx={{
          svg: {
            fill: sortDirection?.startsWith("asc")
              ? "var(--chakra-colors-text-primary) !important"
              : "var(--chakra-colors-text-tertiary)",
          },
        }}
      />
      <ArrowDownIcon
        size={10}
        sx={{
          svg: {
            fill: sortDirection?.startsWith("desc")
              ? "var(--chakra-colors-text-primary) !important"
              : "var(--chakra-colors-text-tertiary)",
          },
        }}
      />
    </Row>
  );
};

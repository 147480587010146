import { RouteObject } from "./config";

const NullComponent = () => null;

/**
 * Old routes. Redirect to the new routes if visited.
 */
export const DEPRECATED_ROUTES: RouteObject[] = [
  {
    path: "audiences/setup/sync-templates/new",
    permanentRedirect: "/schema/sync-templates/new",
  },
  {
    path: "audiences/setup/sync-templates/:sync_template_id",
    permanentRedirect: "/schema/sync-templates/:sync_template_id",
  },
  {
    path: "audiences/setup/:object/:id/query",
    permanentRedirect: "/schema/:object/:id/query",
  },
  {
    path: "audiences/setup/:object/new",
    permanentRedirect: "/schema/:object/new",
  },
  {
    path: "audiences/setup/:object/:id",
    permanentRedirect: "/schema/:object/:id",
  },
  {
    path: "audiences/setup/:object",
    permanentRedirect: "/schema/:object",
  },
  {
    path: "audiences/priority-lists",
    permanentRedirect: "/priority-lists",
  },
  {
    path: "audiences/priority-lists/new",
    permanentRedirect: "/priority-lists/new",
  },
  {
    path: "audiences/priority-lists/:priority_list_id",
    permanentRedirect: "/priority-lists/:priority_list_id",
  },
  {
    path: "audiences/:id",
    permanentRedirect: "/audiences/:id/query",
  },
].map((route) => ({ ...route, analyticsData: { pageName: "" }, component: <NullComponent /> }));

import { useEffect, useMemo, FC } from "react";

import { Alert, FormField, Radio, RadioGroup as HightouchUiRadioGroup, useToast } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize, upperCase } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { AuthorizeConnection, ReauthorizeConnection } from "src/components/authorize-connection";
import { usePermission } from "src/contexts/permission-context";
import { Form as FormkitForm } from "src/formkit/components/form";
import { Field, FieldError } from "src/ui/field";

import { FormkitDestination, FormkitProvider, useFormkitContext } from "../../formkit/components/formkit-context";
import { processFormNode } from "../../formkit/formkit";
import {
  DestinationDefinitionFragment,
  useFormkitDestinationDefinitionQuery,
  useFormkitDestinationValidationQuery,
} from "../../graphql";
import { RadioGroup } from "../../ui/radio";
import { SelectCredential } from "../credentials";
import { TunnelSelect } from "../tunnels/tunnel-select";

interface Props {
  config: Record<string, any> | undefined;
  credentialId: string | undefined;
  definition: DestinationDefinitionFragment;
  destination?: FormkitDestination | null;
  setConfig: (config: Record<string, any>) => void;
  setCredentialId: (credentialId: string) => void;
  isSetup: boolean;
  disableAuthMethod?: boolean;
  onSubmit?: (config: any) => Promise<void>;
  error?: any;
  onConnectClick?(defintion: DestinationDefinitionFragment): void;
}

export const SetupForm: FC<Props> = ({
  config,
  setConfig,
  destination,
  credentialId,
  setCredentialId,
  definition,
  ...props
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { data } = useFormkitDestinationDefinitionQuery({ type: definition?.type });
  //Legacy Destination Config is configuration that does NOT use new formkit format with multiple authentication methods
  const setupMethods = data?.formkitDestinationDefinition;
  const isLegacyDestinationConfiguration = setupMethods?.length == 1 && setupMethods[0]?.key === null;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (setupMethods?.length === 0) {
      if (typeof props.onSubmit === "function") {
        return await props.onSubmit(data);
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      toast({
        id: "save-destination-config",
        title: "There was an error within the destination configuration",
        variant: "error",
      });
    } else {
      if (typeof props.onSubmit === "function") {
        await props.onSubmit(data);
      }
    }
  };

  const validate = async (config, context) => {
    if (!config) {
      return;
    }

    const response = await client.fetchQuery({
      queryFn: useFormkitDestinationValidationQuery.fetcher({
        type: definition.type,
        config,
        context,
      }),
      queryKey: useFormkitDestinationValidationQuery.getKey({ ...config, ...context }),
    });

    return response.formkitDestinationValidation;
  };

  const context = {
    destination: destination ?? undefined,
    destinationDefinition: definition,
    isSetup: props.isSetup,
    credentialId,
  };

  const methods = useForm();

  useEffect(() => {
    if (config && Object.keys(config).length) {
      methods.reset(config, { keepDefaultValues: true });
    }
  }, [Boolean(config)]);

  useEffect(() => {
    //Autoset authentication method if only one.
    const initialSetupMethod = setupMethods?.[0];
    if (!config?.methodKey && !isLegacyDestinationConfiguration && setupMethods?.length === 1 && initialSetupMethod) {
      setConfig((prev) => ({ ...prev, methodKey: initialSetupMethod.key }));
    }
  }, [config, definition.type, setupMethods?.length]);

  useEffect(() => {
    //Watches fields and sets config.
    const fieldWatcher = methods.watch((value) => {
      //Have to spread existing config as tunnel is with config, but not part of form.
      setConfig((o) => ({ ...o, ...value }));
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const setTunnel = (tunnel) => {
    methods.setValue("tunnel", tunnel);
  };

  const permission = usePermission();

  const { appUseHightouchUiInDestinationForms: useHightouchUi } = useFlags();
  const FieldComponent = useHightouchUi ? FormField : Field;

  if (!setupMethods) {
    return null;
  }

  return (
    <>
      <FormkitProvider {...context} useHightouchUi={useHightouchUi} validate={validate}>
        <FormProvider {...methods}>
          {setupMethods.length > 1 && (
            <FieldComponent label="Authentication method">
              <Controller
                control={methods.control}
                name="methodKey"
                render={({ field }) => {
                  return useHightouchUi ? (
                    <HightouchUiRadioGroup
                      isDisabled={permission.unauthorized || props.disableAuthMethod}
                      orientation="vertical"
                      value={config?.methodKey}
                      onChange={field.onChange}
                    >
                      {setupMethods.map((option) => (
                        <Radio key={option.key} label={option.label || capitalize(option.key || "")} value={option.key || ""} />
                      ))}
                    </HightouchUiRadioGroup>
                  ) : (
                    <RadioGroup
                      {...field}
                      disabled={props.disableAuthMethod}
                      options={setupMethods.map((o) => ({ label: o.label || capitalize(o.key || ""), value: o.key }))}
                      value={config?.methodKey}
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </FieldComponent>
          )}
          <SelectedMethodForm
            config={config || {}}
            credentialId={credentialId}
            definition={definition}
            setupMethods={setupMethods}
            id={destination?.id}
            isLegacyDestinationConfiguration={isLegacyDestinationConfiguration}
            isSetup={props.isSetup}
            setConfig={setConfig}
            setCredentialId={setCredentialId}
            setTunnel={setTunnel}
            onConnectClick={props.onConnectClick}
          />
          <FieldError error={props.error} />
          <form hidden id="destination-form" onSubmit={handleSubmit} />
        </FormProvider>
      </FormkitProvider>
    </>
  );
};

export type MethodProps = {
  definition: DestinationDefinitionFragment;
  config: Record<string, any>;
  setConfig?: (config: Record<string, any>) => void;
  setupMethods: Record<string, any>;
  id?: string;
  onConnectClick?: (defintion: DestinationDefinitionFragment) => void;
  credentialId?: any;
  setCredentialId?: any;
  setTunnel: (config?: Record<string, any>) => void;
  isLegacyDestinationConfiguration: boolean;
  isSetup: boolean;
};

const SelectedMethodForm: FC<Readonly<MethodProps>> = ({ definition, config, setupMethods, id, onConnectClick, ...props }) => {
  const matchingAuthMethod = useMemo(() => {
    if (props.isLegacyDestinationConfiguration) {
      return setupMethods?.[0];
    }
    if (config?.methodKey === "googleOauth") {
      return setupMethods?.find((o) => o.key === "googleOauth" || o.key === "oauth");
    }
    return setupMethods?.find((o) => o.key === config?.methodKey);
  }, [config?.methodKey, setupMethods, props.isLegacyDestinationConfiguration]);

  const { useHightouchUi } = useFormkitContext();

  const Form = useMemo(
    () =>
      matchingAuthMethod?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingAuthMethod.form, 0, definition, { useHightouchUi })}
        </FormkitForm>
      ),
    [matchingAuthMethod?.key, useHightouchUi],
  );

  if (matchingAuthMethod?.method === "form") {
    return (
      <>
        {matchingAuthMethod?.tunneling && (
          <TunnelSelect optional useHightouchUi={useHightouchUi} value={config.tunnel} onChange={props.setTunnel} />
        )}

        {matchingAuthMethod?.provider && useHightouchUi && (
          <FormField label={`${upperCase(matchingAuthMethod.provider)} Credentials`}>
            <SelectCredential
              useHightouchUi
              provider={matchingAuthMethod.provider}
              value={props.credentialId}
              onChange={(value) => props.setCredentialId(value)}
            />
          </FormField>
        )}

        {matchingAuthMethod?.provider && !useHightouchUi && (
          <Field label={`${upperCase(matchingAuthMethod.provider)} Credentials`} sx={{ pb: 4 }}>
            <SelectCredential
              provider={matchingAuthMethod.provider}
              value={props.credentialId}
              onChange={(value) => props.setCredentialId(value)}
            />
          </Field>
        )}
        {Form}
      </>
    );
  } else if (matchingAuthMethod?.method === "oauth") {
    if (!id) {
      return (
        <AuthorizeConnection
          href={`${import.meta.env.VITE_API_BASE_URL}${matchingAuthMethod.url}/${definition.type}`}
          icon={matchingAuthMethod?.authIcon || definition.icon}
          name={matchingAuthMethod?.authLabel || definition.name}
          onAuthorize={() => {
            if (onConnectClick) {
              onConnectClick(definition);
            }
          }}
        />
      );
    } else {
      return (
        <>
          {props.isSetup && (
            <Alert
              message={`Your ${definition.name} account is now connected to Hightouch.`}
              title="Authorization successful."
              variant="success"
            />
          )}
          {matchingAuthMethod?.form && Form}
          {!props.isSetup && (
            <ReauthorizeConnection
              href={`${import.meta.env.VITE_API_BASE_URL}${matchingAuthMethod.url}/${definition.type}/${id}`}
              icon={matchingAuthMethod?.authIcon || definition.icon}
              name={matchingAuthMethod?.authLabel || definition.name}
              onAuthorize={() => {
                if (onConnectClick) {
                  onConnectClick(definition);
                }
              }}
            />
          )}
        </>
      );
    }
  }
  return null;
};

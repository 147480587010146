import { FC } from "react";

import { UpsellHeading, Column, Row } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { Page } from "src/components/layout";
import { Container } from "src/ui/box";
import { AudienceIcon } from "src/ui/icons";

import { FeatureFull } from "../feature-gates";
import { Logos } from "./demo-logos";

export const AudiencesDemo: FC = () => {
  return (
    <Page fullWidth sx={{ px: 0 }} title="Audiences">
      <Container center size="large" sx={{ px: 6, gap: 8 }}>
        <Column sx={{ alignItems: "center", path: { fill: "space" } }}>
          <AudienceIcon color="space" size={64} />
          <UpsellHeading mb="2" mt="4" size="2xl">
            Hightouch Customer Studio
          </UpsellHeading>
        </Column>

        <Row sx={{ width: "100%" }}>
          <Column sx={{ flex: 1.25 }}>
            <FeatureFull
              enabled={false}
              featureDetails={{
                description:
                  "Customer Studio is a suite of purpose-built marketer-first features that enables anyone to create custom segments, traits and experiments... no SQL required. It empowers data-driven marketers to build captivating campaigns without engineering support.",
                pitch: "Unlock the power of the data warehouse for everyone.",
                bullets: [
                  "Use any trusted data in the data warehouse — users, events, custom objects.",
                  "Navigate through 360-degree customer profiles to better understand every customer.",
                  "Send audiences to 100's of email, ads, SMS, and other destinations.",
                  "Personalize your messages by defining traits and relationships.",
                  "Create experiments and A/B tests with built-in splits functionality.",
                  "Tap into complex campaign logic with priority lists and sync sequences.",
                ],
              }}
              featureName="Customer Studio"
            >
              <></>
            </FeatureFull>
          </Column>
          <Column sx={{ maxWidth: "600px", ml: 8, flex: 1 }}>
            <video
              controls={true}
              poster="https://cdn.sanity.io/images/pwmfmi47/production/c1c808b196f7bbf7fa2940b74efba0142da02bae-1344x767.webp"
              src="https://cdn.sanity.io/files/pwmfmi47/production/64ab7ff3eabb9cb47c0b59942e34c129861419cb.mp4"
              width="100%"
            />
          </Column>
        </Row>

        <Column sx={{ alignItems: "center" }}>
          <Text
            sx={{ fontWeight: "bold", color: "base.4", mb: 2, textTransform: "uppercase", letterSpacing: "1px", fontSize: 0 }}
          >
            Customer studio is used by
          </Text>
          <Logos />
        </Column>
      </Container>
    </Page>
  );
};

import { FC } from "react";

import { ArrowPathIcon, ShieldCheckIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { ChatBubbleLeftIcon, KeyIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Box, Column, Link, Paragraph, Row, UpsellButton, UpsellHeading } from "@hightouchio/ui";

import { newIntercomMessage } from "src/lib/intercom";
import { Logo } from "src/ui/brand";
import { Modal } from "src/ui/modal";

import { GRADIENT, SPACE_TINT } from "./styles";
import { FeatureProps } from "./types";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  featureName: string;
  featureDetails?: FeatureProps;
};

const UPSELL_PITCH = [
  {
    name: "Supercharged syncing",
    description: "Run your syncs at sub-hourly frequencies, send data to premium destinations, and more.",
    icon: ArrowPathIcon,
  },
  {
    name: "Enterprise security",
    description: "Access our enterprise features including SSO, RBAC, audit logs, and custom storage.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Streamlined workflows",
    description: "Integrate Hightouch with Git, turn on approval flows, and plug into tools like Fivetran.",
    icon: KeyIcon,
  },
  {
    name: "Premium add-on products",
    description: "Transform your warehouse into a Customer Data Platform (CDP) with a suite of no-code features.",
    icon: UserGroupIcon,
  },
];

export const UpsellModal: FC<Readonly<ModalProps>> = ({ featureName, featureDetails, isOpen, onClose }) => {
  const { pitch, description, bullets, video, image } = featureDetails || {};
  return (
    <Modal
      bodySx={{ position: "relative", p: 0 }}
      headerSx={{ display: "none" }}
      isOpen={isOpen}
      sx={{ maxWidth: !featureDetails ? "800px" : "1400px" }}
      onClose={onClose}
    >
      <Box
        _hover={{ color: "gray.800" }}
        as="button"
        color="gray.500"
        position="absolute"
        right={4}
        top={4}
        transition="all"
        transitionDuration="faster"
        type="button"
        onClick={onClose}
      >
        <Box as={XMarkIcon} boxSize={5} />
      </Box>
      <Row display="grid" gridTemplateColumns={featureDetails ? "3fr 4fr" : "1fr"}>
        {featureDetails && (
          <Column alignItems="start" bg="gray.100" justifyContent="center" p={8}>
            <Column>
              <UpsellHeading mb={4} size="lg">
                {pitch}
              </UpsellHeading>
              <Paragraph mb={2}>{description}</Paragraph>
              <Box as="ul">
                {bullets?.map((bullet, idx) => (
                  <Box key={idx} as="li" ml={4} sx={{ _notFirst: { mt: 2 } }}>
                    <Paragraph>{bullet}</Paragraph>
                  </Box>
                ))}
              </Box>
            </Column>
            {video && (
              <Column
                boxShadow="md"
                mt={4}
                sx={{
                  video: { borderRadius: 4 },
                }}
              >
                <video controls={true} poster={video.poster} src={video.src} />
              </Column>
            )}
            {image && (
              <Column
                boxShadow="md"
                mt={4}
                sx={{
                  img: { borderRadius: 4 },
                }}
              >
                <img src={image.src} />
              </Column>
            )}
          </Column>
        )}
        <Column bg={GRADIENT} gap={8} justifyContent="space-between" p={8}>
          <Column gap={8}>
            <Logo primaryColor="#222F51" secondaryColor="#7D8BB0" />
            <Column gap={4} sx={{ h2: { color: SPACE_TINT }, p: { maxWidth: "100%" } }}>
              <UpsellHeading size="2xl">
                {featureDetails ? "Upgrade your plan to Business Tier" : `Upgrade to Business Tier to get ${featureName}`}
              </UpsellHeading>
              <Paragraph>
                Unlock the full power of Hightouch with our Business Tier plan. Activate your data with enhanced security,
                governance, developer tools, and premium destinations
              </Paragraph>
            </Column>
            <Column gap={4}>
              <Box display="grid" gap={4} gridTemplateColumns="1fr 1fr">
                {UPSELL_PITCH.map((feature, idx) => (
                  <Row key={idx}>
                    <Column sx={{ mt: 0.5 }}>
                      <Box as={feature.icon} boxSize={5} />
                    </Column>
                    <Column ml={2} sx={{ h2: { fontWeight: "medium" } }}>
                      <UpsellHeading>{feature.name}</UpsellHeading>
                      <Paragraph>{feature.description}</Paragraph>
                    </Column>
                  </Row>
                ))}
              </Box>
              <Paragraph>
                There's more to business tier,{" "}
                <Link href="https://hightouch.com/pricing">see all the features and compare plans</Link>.
              </Paragraph>
            </Column>
          </Column>

          <Column gap={2} sx={{ p: { fontSize: "sm", maxWidth: "unset" } }}>
            <UpsellButton
              icon={ChatBubbleLeftIcon}
              size="lg"
              onClick={() =>
                newIntercomMessage(`Hi, I'm interested in trying out ${featureName} and upgrading to the Business Tier plan.`)
              }
            >
              Request to upgrade
            </UpsellButton>
            <Paragraph>
              Our team will ask you about your needs, help you understand how pricing works, and get you started with new
              features.
            </Paragraph>
          </Column>
        </Column>
      </Row>
    </Modal>
  );
};

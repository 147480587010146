import { FC, useState, useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { GitCredentials, useGithubRepositoriesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

import { isGitHub } from "./utils";

interface Props {
  credentials: GitCredentials | undefined;
}

export const GitRepositorySelector: FC<Readonly<Props>> = ({ credentials }) => {
  const { register, control } = useFormContext();

  const [error, setError] = useState<string>("");

  const { data: githubRepos, isLoading: githubRepoLoading } = useGithubRepositoriesQuery(
    {
      credentialId: String(credentials?.id),
    },
    {
      select: (data) => data.getGithubRepositories,
      enabled: isGitHub(credentials),
      onError: (error) => setError(error?.message),
    },
  );

  const githubRepoOptions = githubRepos?.map((repo) => ({
    label: repo.fullName,
    value: repo.htmlUrl,
  }));

  useEffect(() => {
    setError("");
  }, [credentials?.type]);

  return (
    <Field
      description={
        credentials?.type === "github" ? undefined : "Please use the full link of the git repository, including https."
      }
      error={error}
      label="Repository"
    >
      {isGitHub(credentials) && (githubRepoOptions || githubRepoLoading) ? (
        <Controller
          control={control}
          name="repository"
          render={({ field }) => (
            <Select
              isLoading={githubRepoLoading}
              options={githubRepoOptions}
              placeholder="Select a repository..."
              value={field?.value}
              onChange={(selected) => {
                field.onChange(selected?.value);
              }}
            />
          )}
        />
      ) : (
        <Input {...register("repository")} />
      )}
    </Field>
  );
};

import { FC, ReactNode, MouseEventHandler, useMemo } from "react";

import { Box, Row, Column, Link, Text, BoxProps } from "@hightouchio/ui";
import { noop } from "lodash";
import { ThemeUIStyleObject } from "theme-ui";

import { DotsIcon } from "src/ui/icons";
import { IconProps } from "src/ui/icons/icon";
import { Popout } from "src/ui/popout";
import { Placement } from "src/ui/popout/popout";

import { Indices } from "../../../../design";

export type MenuOption = {
  active?: boolean;
  label?: ReactNode;
  icon?: FC<IconProps>;
  onClick?: () => void;
  sx?: BoxProps["sx"];
  divider?: "top" | "bottom";
  disabled?: boolean;
  variant?: string;
  title?: string;
  description?: string;
  link?: string;
  newTab?: boolean;
  render?: FC;
  stickToTop?: boolean;
};

export interface MenuProps {
  contentSx?: ThemeUIStyleObject;
  disabled?: boolean;
  options: MenuOption[];
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
  width?: string;
  footer?: ReactNode;
  header?: ReactNode;
  placement?: Placement;
  offset?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  portal?: boolean;
  strategy?: "absolute" | "fixed";
  loading?: boolean;
  onClose?: () => void;
}

export const Menu: FC<Readonly<MenuProps>> = ({
  children,
  contentSx = {},
  disabled = false,
  options,
  footer,
  header,
  width,
  placement = "bottom-start",
  offset,
  sx = {},
  onClick,
  portal,
  strategy = "absolute",
  onClose,
}) => {
  const style = useMemo(
    () => ({
      minWidth: "112px",
      backgroundColor: "white",
      width: width,
      ...(portal ? { zIndex: Indices.Modal } : {}),
      ...contentSx,
    }),
    [contentSx, width, portal],
  );

  const nonScrollOptions = options.filter(({ stickToTop }) => stickToTop);
  const scrolledOptions = options.filter(({ stickToTop }) => !stickToTop);

  return (
    <Popout
      content={({ close }) => (
        <>
          {header && (
            <Row p={3} pb={1}>
              {header}
            </Row>
          )}
          {nonScrollOptions.length > 0 && (
            <Box p={1} pb={0}>
              {nonScrollOptions.map((option, i) => (
                <Option
                  key={i}
                  {...option}
                  onClick={() => {
                    close();
                    if (option.onClick) {
                      option.onClick();
                    }
                  }}
                />
              ))}
            </Box>
          )}
          <Box p={1} overflowY="auto">
            {scrolledOptions.map((option, i) => (
              <Option
                key={i}
                {...option}
                onClick={() => {
                  close();
                  if (option.onClick) {
                    option.onClick();
                  }
                }}
              />
            ))}
          </Box>
          {footer && (
            <Row p={3} pt={0}>
              {footer}
            </Row>
          )}
        </>
      )}
      contentSx={style}
      disabled={disabled}
      offset={offset}
      placement={placement}
      portal={portal}
      strategy={strategy}
      sx={sx}
      onClick={disabled ? noop : onClick}
      onClose={onClose}
    >
      {({ isOpen }) => {
        return children || <DotsIcon size={16} sx={{ color: isOpen ? "primary" : "base.4", p: 1 }} />;
      }}
    </Popout>
  );
};

const Option: FC<Readonly<MenuOption>> = ({
  active,
  variant,
  sx = {},
  title,
  divider,
  label,
  disabled,
  icon: Icon,
  onClick,
  description,
  newTab,
  link,
  render,
}) => {
  const element = (
    <Row
      align="center"
      bg={active ? "primary.background" : "transparent"}
      p={2}
      borderRadius="sm"
      cursor={disabled ? "not-allowed" : "pointer"}
      transition="100ms background-color"
      sx={{
        svg: {
          transition: "100ms fill",
        },
        "&:hover": {
          bg: disabled || active ? undefined : "gray.100",
          svg: {
            fill: "grass.base",
          },
        },
        ...sx,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) return;

        if (link && !newTab) {
          window.location.href = link;
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {render ? (
        render({})
      ) : (
        <>
          {divider === "top" ? <Box borderTop="1px solid" borderColor="base.border" /> : null}
          {Icon && <Icon color="forest" size={description ? 30 : 20} sx={{ mr: description ? 3 : "6px" }} />}
          <Column>
            <Text
              color={disabled ? "gray.300" : variant === "danger" ? "danger.base" : "text.primary"}
              fontWeight={description ? "medium" : "normal"}
            >
              {label}
            </Text>
            {description && <Text color="text.secondary">{description}</Text>}
          </Column>
          {divider === "bottom" ? <Box borderTop="1px solid" borderColor="base.border" /> : null}
        </>
      )}
    </Row>
  );

  return (
    <>
      {divider === "top" ? <Box borderTop="1px solid" borderColor="base.border" my={2} /> : null}
      {title ? (
        <Box fontSize="sm" fontWeight="medium" textTransform="uppercase" color="text.tertiary" mx={2} my="6px">
          {title}
        </Box>
      ) : null}
      {newTab ? <Link href={link ?? ""}>{element}</Link> : element}
      {divider === "bottom" ? <Box borderTop="1px solid" borderColor="base.border" my={2} /> : null}
    </>
  );
};

import { FC, ReactNode } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Container } from "src/ui/box";

export type PageContainerProps = {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
  fullWidth?: boolean;
};

export enum DefaultPageContainerPadding {
  Top = 4,
  Bottom = 20,
  X = 6,
}

export const PageContainer: FC<Readonly<PageContainerProps>> = ({ children, fullWidth, sx = {} }) => {
  return (
    <Container
      size={fullWidth ? "full" : "page"}
      sx={{
        minWidth: "400px",
        pt: DefaultPageContainerPadding.Top,
        pb: DefaultPageContainerPadding.Bottom,
        px: DefaultPageContainerPadding.X,
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};

import { FC, forwardRef, ReactNode, Ref } from "react";

import { Row } from "@hightouchio/ui";

import { NAV_WIDTHS_PER_BREAKPOINT } from "src/components/layout/constants";
import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import { SIZES } from "src/ui/box/container";

import { Indices } from "../../../design";

interface Props {
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
}

export const ActionBar: FC<Readonly<Props>> = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <Row
      ref={ref}
      borderTop="1px solid"
      borderColor="base.border"
      bottom={0}
      bg="white"
      boxShadow="0px 18px 48px -12px #1018282E"
      position="fixed"
      gap={2}
      left={NAV_WIDTHS_PER_BREAKPOINT}
      py={4}
      right={0}
      width="100%"
      zIndex={Indices.Content}
    >
      <Row
        justifyContent="flex-start"
        maxWidth={NAV_WIDTHS_PER_BREAKPOINT.map((size) => `calc(${size} + ${SIZES.page})`)}
        mx="auto"
        px={DefaultPageContainerPadding.X}
        width="100%"
      >
        {children}
      </Row>
    </Row>
  );
});

ActionBar.displayName = "ActionBar";

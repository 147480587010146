import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
} from "src/types/visual";

export const isAndOrCondition = <TCondition extends Condition>(
  condition: AndOrCondition<TCondition> | undefined,
): condition is AndCondition<TCondition> | OrCondition<TCondition> => {
  if (!condition) {
    return false;
  }

  return condition.type === ConditionType.And || condition.type === ConditionType.Or;
};

export const isPropertyOrReferencePropertyCondition = (
  condition: any,
): condition is PropertyCondition | ReferencedPropertyCondition => {
  if (!condition) {
    return false;
  }

  return condition.type === ConditionType.Property || condition.type === ConditionType.ReferenceProperty;
};

import { forwardRef, ReactNode } from "react";

import { Box as ThemedBox, BoxProps } from "theme-ui";

export const Box = forwardRef<HTMLElement, { children?: ReactNode } & BoxProps>(({ children, ...props }, ref) => {
  return (
    <ThemedBox ref={ref} {...props}>
      {children}
    </ThemedBox>
  );
});

Box.displayName = "Box";

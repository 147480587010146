import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SQLIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M4.862 15.77a3.439 3.439 0 0 1-1.574-.374A4.101 4.101 0 0 1 2 14.374l.692-.978c.302.335.644.609 1.025.82.382.201.787.302 1.216.302.493 0 .875-.125 1.145-.374.27-.25.406-.566.406-.95 0-.307-.06-.551-.18-.734a1.495 1.495 0 0 0-.488-.46 6.797 6.797 0 0 0-.692-.374l-1.097-.59a4.402 4.402 0 0 1-.787-.49 2.574 2.574 0 0 1-.632-.79c-.167-.327-.25-.72-.25-1.18 0-.49.111-.926.334-1.31.222-.393.532-.7.93-.92.397-.23.854-.346 1.371-.346.485 0 .938.11 1.36.33.42.212.782.495 1.085.85l-.62.92a2.82 2.82 0 0 0-.835-.618c-.294-.153-.644-.23-1.05-.23-.405 0-.735.11-.99.33-.254.221-.38.524-.38.907 0 .278.063.504.19.676.135.173.306.317.513.432.214.115.433.23.655.345l1.062.562c.326.153.616.34.87.56.263.212.47.476.62.792.151.317.227.705.227 1.166 0 .508-.115.973-.346 1.395a2.522 2.522 0 0 1-.978.993c-.421.24-.926.36-1.514.36Zm7.114 0a2.409 2.409 0 0 1-1.586-.576c-.453-.393-.81-.954-1.073-1.683-.254-.739-.382-1.626-.382-2.662 0-1.026.128-1.9.382-2.619.262-.729.62-1.28 1.073-1.654.461-.384.99-.576 1.586-.576.596 0 1.121.192 1.574.576.461.374.819.925 1.073 1.654.263.72.394 1.593.394 2.619 0 1.036-.131 1.923-.394 2.662-.254.729-.612 1.29-1.073 1.683a2.363 2.363 0 0 1-1.574.576Zm0-1.18c.366 0 .684-.144.954-.432.27-.287.481-.71.632-1.266.151-.556.227-1.237.227-2.043 0-.758-.076-1.405-.227-1.943-.15-.537-.362-.944-.632-1.223-.27-.287-.588-.431-.954-.431s-.684.144-.954.431c-.27.279-.48.686-.632 1.223-.15.538-.226 1.185-.226 1.943 0 .806.075 1.487.226 2.043.151.556.362.979.632 1.266.27.288.588.432.954.432Zm2.17 3.41c-.723 0-1.315-.23-1.776-.69-.461-.451-.795-1.022-1.002-1.713l1.228-.144c.08.307.199.561.358.763.167.201.362.345.584.431.23.096.481.144.752.144a1.29 1.29 0 0 0 .596-.144l.214 1.123c-.127.067-.27.12-.429.158a1.813 1.813 0 0 1-.525.072Zm2.917-2.403V6.173h1.18v8.216H22v1.208h-4.937Z" />
    </Icon>
  );
};

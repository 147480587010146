import { useState, useEffect, FC } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button as HightouchUiButton, IconButton, ButtonGroup, Tooltip, Combobox, TextInput } from "@hightouchio/ui";
import { Container, Grid, Text, Flex } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermission } from "src/contexts/permission-context";
import { isColumnReference } from "src/types/visual";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon, TraitIcon, XIcon } from "src/ui/icons";
import { AsteriskIcon } from "src/ui/icons/asterisk";
import { LightningIcon } from "src/ui/icons/lightning";
import { Input } from "src/ui/input";
import { CreatableSelect } from "src/ui/select";
import { flattenOptions } from "src/ui/select/select";
import { suggest, automap } from "src/utils/automap";
import { ICON_MAP, NEW_ICON_MAP } from "src/utils/destinations";

import { ArrayInlineMapper } from "./array-inline-mapper";
import { ColumnOption, useFormkitContext } from "./formkit-context";
import { Mapper, resolveFromValue } from "./mapper";
import {
  MappingType,
  Mapping,
  SelectedOptionContext,
  ArrayMapping,
  ObjectMapping,
  isArrayMapping,
  Option,
  InlineMapperProps,
} from "./types";

export const InlineMapper: FC<Readonly<InlineMapperProps>> = ({
  type,
  onChange,
  currentDepth,
  retrieveErrorMessage,
  errorPrefix,
  enabledNestedInlineMapper,
  value,
  selectedOptionContext,
  onChangeJsonColumnProperties,
  jsonColumnProperties,
  outerContainerSx,
  overwriteColumnsWithArrayProps,
  templates,
  useHightouchUi,
  required,
  onReloadEligibleInlineMapperColumns,
  parentMapping,
}) => {
  const [nestedMappings, setNestedMappings] = useState<Mapping[]>([]);
  const [suggestionState, setSuggestionState] = useState<"visible" | "disabled" | "hidden">("hidden");

  const { errors } = useDestinationForm();

  const jsonColumnPropsToOptions = (): ColumnOption[] | undefined => {
    if (!jsonColumnProperties.selectedColumnProps) {
      return undefined;
    }

    return Array.from(jsonColumnProperties.selectedColumnProps).map((prop) => {
      return {
        label: prop,
        value: prop,
        type: "array_property",
      };
    });
  };

  const { columns: contextColumns } = useFormkitContext();
  const columns = overwriteColumnsWithArrayProps ? jsonColumnPropsToOptions() : contextColumns;
  const permission = usePermission();

  /**
   * Populates the nestedMappings state on load with fields that were mapped previously and required fields that are not mapped.
   */
  const generateMappings = (
    value: ArrayMapping | ObjectMapping,
    properties: SelectedOptionContext[] | undefined,
  ): Mapping[] => {
    const requiredOptions: Mapping[] = [];
    const children = isArrayMapping(value) ? value?.children : value?.from;
    let mappings: Mapping[] = [];
    if (Array.isArray(properties)) {
      for (const option of properties) {
        if (option.required) {
          requiredOptions.push({
            to: option.value,
            type: MappingType.STANDARD,
          });
        }
      }
    }
    if (Array.isArray(children)) {
      const nestedMappings = new Set(children.map(({ to }) => to));
      mappings = [...children, ...requiredOptions.filter(({ to }) => !nestedMappings.has(to))];
    } else {
      mappings = requiredOptions;
    }

    // If there is nothing mapped previously and no required fields, initiate the mappings with one standard field for clarity.
    if (mappings.length === 0) {
      mappings.push({
        type: MappingType.STANDARD,
      });
    }
    return mappings;
  };

  useEffect(() => {
    setNestedMappings(generateMappings(value, selectedOptionContext));
  }, [value.to]);

  useEffect(() => {
    onChange(nestedMappings);
  }, [nestedMappings]);

  useEffect(() => {
    setSuggestionState((prevSuggestionState) => {
      const unmappedDestinationColumns = suggestColumns();
      if (unmappedDestinationColumns.length) {
        setSuggestionState("visible");
      } else if (
        ((selectedOptionContext || [])?.length > 0 && prevSuggestionState === "visible") ||
        !selectedOptionContext ||
        (selectedOptionContext || []).length === 0
      ) {
        setSuggestionState("disabled");
      }

      return prevSuggestionState;
    });
  }, [selectedOptionContext, nestedMappings, columns]);

  const isOptionExcluded = (option: Option) => {
    const valueAlreadyMapped = nestedMappings.some(
      (mapping) => mapping?.to === (isColumnReference(option.value) ? option.label : option.value),
    );
    return valueAlreadyMapped;
  };

  const suggestColumns = () => {
    const flatColumns = flattenOptions(columns || []);
    const unmappedOptions = (!selectedOptionContext?.length ? (flatColumns as any[]) : selectedOptionContext).filter(
      (option) => !isOptionExcluded(option),
    );
    return automap(columns || [], unmappedOptions as any[]);
  };

  const append = (newMapping: Mapping[]) => {
    setNestedMappings((currentMappings) => [...currentMappings, ...newMapping]);
  };

  const change = (index: number, newMapping: Mapping) => {
    setNestedMappings((currentMappings) => {
      const mappingsCopy = [...currentMappings];
      mappingsCopy[index] = newMapping;
      return mappingsCopy;
    });
  };

  const remove = (index: number) => {
    setNestedMappings((currentMappings) => {
      const mappingsCopy = [...currentMappings];
      mappingsCopy.splice(index, 1);
      return mappingsCopy;
    });
  };
  return (
    <Container
      sx={{
        borderLeftStyle: "solid",
        borderColor: "#8EA8A9",
        borderLeftWidth: "2px",
        marginLeft: "16px",
        paddingRight: "1px",
      }}
    >
      <Column>
        <Container
          sx={{
            bg: "#F8FAFC",
            width: "100%",
            padding: "16px",
            paddingTop: useHightouchUi ? undefined : "14px",
            marginTop: "16px",
            ...outerContainerSx,
          }}
        >
          {nestedMappings.map((mapping, index) => {
            const { to, type } = mapping;
            const selectedOption = selectedOptionContext?.find((option) => option.value == to);
            const isMappingRequired = Boolean(selectedOption?.required);
            const destinationFieldOptions = selectedOptionContext?.map((option) => {
              if (isOptionExcluded(option)) {
                return { ...option, disabled: true };
              } else {
                return option;
              }
            });
            // If mapping destination is configured, but it's not in the list of
            // available options, it's either a custom value typed by the user or
            // it comes from a suggested mapping
            if (typeof to === "string" && !selectedOption) {
              destinationFieldOptions?.push({
                label: to,
                value: to,
                disabled: false,
              });
            }

            return (
              <Grid
                key={to}
                sx={{
                  gridTemplateColumns: useHightouchUi
                    ? "minmax(0, 1fr) 16px minmax(0, 1fr) 36px"
                    : "1fr max-content 1fr max-content",
                  alignItems: "center",
                }}
              >
                <Mapper
                  columnOptions={columns}
                  enableInLineMapper={enabledNestedInlineMapper}
                  isDisabled={permission.unauthorized}
                  isError={!!errors?.[`${errorPrefix}[${index}].from`]}
                  jsonColumnProperties={jsonColumnProperties}
                  overwriteColumnsWithArrayProps={overwriteColumnsWithArrayProps}
                  parentMapping={parentMapping ? parentMapping : value}
                  placeholder="Select a value..."
                  selectedOption={selectedOption}
                  templates={templates ?? []}
                  useHightouchUi={useHightouchUi}
                  value={mapping}
                  onReloadEligibleInlineMapperColumns={onReloadEligibleInlineMapperColumns}
                  onChange={(option) => {
                    const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                    if (!to && availableOptions?.length && option?.type === MappingType.STANDARD) {
                      const fieldName = resolveFromValue(value.from);
                      change(index, suggest({ label: fieldName, value: option.from }, availableOptions) as Mapping);
                    } else {
                      change(index, {
                        ...option,
                        to,
                      });
                    }
                  }}
                  onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                />
                <ArrowRightIcon color="base.3" size={16} />
                {selectedOptionContext ? (
                  useHightouchUi ? (
                    <Flex sx={{ flexDirection: "column", flex: 1, gap: 2 }}>
                      <Combobox<any, string>
                        supportsCreatableOptions
                        createOptionMessage={(inputValue) => `Create field "${inputValue}"`}
                        emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                        isDisabled={permission.unauthorized || isMappingRequired}
                        isInvalid={!!errors?.[`${errorPrefix}[${index}].to`]}
                        isOptionDisabled={(option) => {
                          // For some reason when option is selected, it becomes disabled
                          // and has `disabled = true` property, so we need to take this into account
                          // and make sure selected option can't be disabled
                          const isSelectedOption = option.value === to;
                          if (isSelectedOption) {
                            return false;
                          }

                          return Boolean(option.disabled);
                        }}
                        optionAccessory={(option) => {
                          return option.type
                            ? {
                                type: "icon",
                                icon: NEW_ICON_MAP[option.type],
                              }
                            : undefined;
                        }}
                        optionLabel={(option) => {
                          return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                        }}
                        options={destinationFieldOptions || []}
                        placeholder="Select or add a field..."
                        value={to}
                        width="sm"
                        onChange={(value) => {
                          const option = destinationFieldOptions?.find((option) => option.value === value);
                          if (option?.type === "OBJECT" && enabledNestedInlineMapper) {
                            change(index, {
                              from: undefined,
                              to: option?.value ?? "",
                              type: MappingType.OBJECT,
                            });
                          } else if (option?.type === "array" && enabledNestedInlineMapper) {
                            change(index, {
                              from: undefined,
                              to: option?.value ?? "",
                              type: MappingType.ARRAY,
                              children: [],
                            });
                          } else {
                            change(index, {
                              ...mapping,
                              to: option?.value ?? "",
                            });
                          }
                        }}
                        onCreateOption={(inputValue) => {
                          change(index, {
                            ...mapping,
                            to: inputValue ?? "",
                          });
                        }}
                      />
                    </Flex>
                  ) : (
                    <CreatableSelect
                      empty="Type in a field name and Hightouch will create the field in the destination!"
                      formatCreateLabel={(string) => {
                        return `Create field "${string}"...`;
                      }}
                      formatOptionLabel={formatOptionLabel}
                      isClearable={false}
                      isDisabled={permission.unauthorized || isMappingRequired}
                      isError={!!errors?.[`${errorPrefix}[${index}].to`]}
                      isValidNewOption={(inputValue, _, selectOptions) => {
                        return !selectOptions.find((v) => v.value === inputValue) && Boolean(inputValue);
                      }}
                      options={destinationFieldOptions || []}
                      placeholder="Select or add a field..."
                      value={to}
                      onChange={(option) => {
                        if (option?.type === "OBJECT" && enabledNestedInlineMapper) {
                          change(index, {
                            from: undefined,
                            to: option?.value ?? "",
                            type: MappingType.OBJECT,
                          });
                        } else if (option?.type === "array" && enabledNestedInlineMapper) {
                          change(index, {
                            from: undefined,
                            to: option?.value ?? "",
                            type: MappingType.ARRAY,
                            children: [],
                          });
                        } else {
                          change(index, {
                            ...mapping,
                            to: option?.value ?? "",
                          });
                        }
                      }}
                      onCreateOption={(value) => {
                        change(index, {
                          ...mapping,
                          to: value ?? "",
                        });
                      }}
                    />
                  )
                ) : (
                  <InputField
                    disabled={permission.unauthorized}
                    error={!!errors?.[`${errorPrefix}[${index}].to`]}
                    useHightouchUi={useHightouchUi}
                    value={to ?? ""}
                    onBlur={(value) => {
                      change(index, {
                        ...mapping,
                        to: value,
                      });
                    }}
                  />
                )}

                {required && nestedMappings.length <= 1 ? (
                  // needs to exist to satisfy grid
                  <Row sx={{ width: useHightouchUi ? "36px" : "18px", minHeight: "36px" }}></Row>
                ) : isMappingRequired ? (
                  <Row
                    sx={{
                      width: useHightouchUi ? "36px" : "18px",
                      minHeight: "36px",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "2px",
                    }}
                  >
                    <Tooltip message="Required">
                      <AsteriskIcon color="red" size={12} />
                    </Tooltip>
                  </Row>
                ) : (
                  <>
                    {useHightouchUi ? (
                      <IconButton
                        aria-label="Remove mapping"
                        icon={XMarkIcon}
                        isDisabled={permission.unauthorized}
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    ) : (
                      <Button
                        aria-label="remove mapping"
                        disabled={permission.unauthorized}
                        variant="plain"
                        onClick={() => remove(index)}
                      >
                        <XIcon color="base.6" size={18} />
                      </Button>
                    )}
                  </>
                )}

                <Container
                  sx={{
                    marginTop: useHightouchUi ? "-12px" : "-14px",
                    paddingBottom: "14px",
                    gridRowStart: "2",
                    gridColumnStart: "1",
                    gridRowEnd: "3",
                    gridColumnEnd: "5",
                  }}
                >
                  {type === "object" && enabledNestedInlineMapper && (
                    <InlineMapper
                      currentDepth={currentDepth + 1}
                      enabledNestedInlineMapper={currentDepth < 2}
                      errorPrefix={`${errorPrefix}[${index}].from`}
                      jsonColumnProperties={jsonColumnProperties}
                      outerContainerSx={{ marginTop: "0px" }}
                      overwriteColumnsWithArrayProps={overwriteColumnsWithArrayProps}
                      retrieveErrorMessage={retrieveErrorMessage}
                      parentMapping={parentMapping ? parentMapping : value}
                      selectedOptionContext={selectedOption?.["properties"]}
                      templates={templates ?? []}
                      type="object"
                      useHightouchUi={useHightouchUi}
                      value={mapping}
                      onReloadEligibleInlineMapperColumns={onReloadEligibleInlineMapperColumns}
                      onChange={(value) => {
                        change(index, {
                          ...mapping,
                          from: value,
                          type: MappingType.OBJECT,
                        });
                      }}
                      onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                    />
                  )}
                  {type === "array" && enabledNestedInlineMapper && (
                    <ArrayInlineMapper
                      currentDepth={currentDepth + 1}
                      enabledNestedInlineMapper={currentDepth < 2}
                      errorPrefix={`${errorPrefix}[${index}].from`}
                      jsonColumnProperties={jsonColumnProperties}
                      outerContainerSx={{ marginTop: "0px" }}
                      retrieveErrorMessage={retrieveErrorMessage}
                      parentMapping={parentMapping ? parentMapping : value}
                      selectedOptionContext={selectedOption?.["properties"]}
                      templates={templates ?? []}
                      useHightouchUi={useHightouchUi}
                      value={mapping}
                      onChange={(value) => {
                        change(index, {
                          ...mapping,
                          children: value,
                          type: MappingType.ARRAY,
                        });
                      }}
                      onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                      onReloadEligibleInlineMapperColumns={onReloadEligibleInlineMapperColumns}
                    />
                  )}
                </Container>
              </Grid>
            );
          })}
          <FieldError error={retrieveErrorMessage(errors, nestedMappings.length, errorPrefix)} />
          {useHightouchUi ? (
            <ButtonGroup mt={4}>
              <HightouchUiButton
                isDisabled={permission.unauthorized}
                onClick={() => {
                  append([{ type: MappingType.STANDARD }]);
                }}
              >
                {type === "array" ? "Add array mapping" : "Add object mapping"}
              </HightouchUiButton>
              {suggestionState !== "hidden" && (
                <Tooltip isDisabled={suggestionState !== "disabled"} message="All possible suggestions applied">
                  <HightouchUiButton
                    isDisabled={suggestionState === "disabled" || permission.unauthorized}
                    onClick={() => {
                      append(suggestColumns() as Mapping[]);
                    }}
                  >
                    Suggest mappings
                  </HightouchUiButton>
                </Tooltip>
              )}
            </ButtonGroup>
          ) : (
            <Row sx={{ marginTop: "2px" }}>
              <Button
                disabled={permission.unauthorized}
                variant="secondary"
                onClick={() => append([{ type: MappingType.STANDARD }])}
              >
                {type === "array" ? "Add array mapping" : "Add object mapping"}
              </Button>

              {suggestionState !== "hidden" && (
                <Button
                  aria-label="suggest mappings"
                  disabled={suggestionState === "disabled" || permission.unauthorized}
                  sx={{ minWidth: "32px", ml: 1 }}
                  tooltip={suggestionState !== "disabled" ? "Suggest mappings" : "All possible suggestions applied"}
                  variant="text-secondary"
                  onClick={() => {
                    append(suggestColumns() as Mapping[]);
                  }}
                >
                  <LightningIcon size={16} />
                </Button>
              )}
            </Row>
          )}
        </Container>
      </Column>
    </Container>
  );
};

export const formatFromColumnOption = (option) => {
  if (typeof option?.value === "object") {
    if (option?.value?.type === "additionalColumnReference") {
      return (
        <Row sx={{ alignItems: "center" }}>
          <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
          <Text>{option.label}</Text>
        </Row>
      );
    }
  }
  return option?.label;
};

export const formatOptionLabel = (object) => {
  const Icon = ICON_MAP[object.type];

  return (
    <Row sx={{ alignItems: "center" }}>
      {Icon && object.type && (
        <Row sx={{ mr: 2 }}>
          <Icon size={14} />
        </Row>
      )}
      <Text>{object.label}</Text>
      {object?.object?.label && <Text sx={{ fontSize: 1, color: "blacks.5", ml: 2 }}>{object?.object?.label}</Text>}
    </Row>
  );
};

const InputField: FC<
  Readonly<{ value: string; onBlur: (value: string) => void; disabled: boolean; error: boolean; useHightouchUi?: boolean }>
> = ({ value, onBlur, disabled, error, useHightouchUi }) => {
  const [inputValue, setInputValue] = useState(value || "");

  /**
   * We use onBlur to update the nested mapping state. This is because if we use onChange in the `Input` component,
   * it will cause re-rendering and loss of focus on the input field whenever there is a change.
   * Since we are changing the nested mapping state onBlur, we cannot use the nested mapping's `to` as a value since it is not updated
   * whenever a user types.
   * This requires every `Input` component to have its own state that it references and we pass that state back once onBlur is called.
   */

  if (useHightouchUi) {
    return (
      <TextInput
        isDisabled={disabled}
        isInvalid={error}
        placeholder="Enter a field..."
        value={inputValue}
        width="auto"
        onBlur={() => onBlur(inputValue)}
        onChange={(event) => setInputValue(event.target.value)}
      />
    );
  }

  return (
    <Input
      disabled={disabled}
      error={error}
      placeholder="Enter a field..."
      value={inputValue}
      onBlur={() => onBlur(inputValue)}
      onChange={(to) => setInputValue(to)}
    />
  );
};

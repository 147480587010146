import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChartIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M15.1316 0H5.78939C2.38494 0 0.456055 1.92889 0.456055 5.33333V14.6667C0.456055 18.0711 2.38494 20 5.78939 20H15.1316C18.5361 20 20.4561 18.0711 20.4561 14.6667V5.33333C20.4561 1.92889 18.5361 0 15.1316 0"
        fill="#0F1825"
        fillOpacity="0.95"
        opacity="0.4"
      />
      <path
        d="M5.82471 7.36897C5.37138 7.36897 4.99805 7.74231 4.99805 8.20453V15.0756C4.99805 15.529 5.37138 15.9023 5.82471 15.9023C6.28694 15.9023 6.66027 15.529 6.66027 15.0756V8.20453C6.66027 7.74231 6.28694 7.36897 5.82471 7.36897"
        fill="#0F1825"
        fillOpacity="0.95"
      />
      <path
        d="M10.4917 4.08897C10.0384 4.08897 9.66504 4.46231 9.66504 4.92453V15.0756C9.66504 15.529 10.0384 15.9023 10.4917 15.9023C10.9539 15.9023 11.3273 15.529 11.3273 15.0756V4.92453C11.3273 4.46231 10.9539 4.08897 10.4917 4.08897"
        fill="#0F1825"
        fillOpacity="0.95"
      />
      <path
        d="M15.0963 10.9957C14.6341 10.9957 14.2607 11.369 14.2607 11.8312V15.0757C14.2607 15.529 14.6341 15.9023 15.0874 15.9023C15.5496 15.9023 15.923 15.529 15.923 15.0757V11.8312C15.923 11.369 15.5496 10.9957 15.0963 10.9957"
        fill="#0F1825"
        fillOpacity="0.95"
      />
    </Icon>
  );
};

import { FC } from "react";

import { Box, CheckboxGroup, Checkbox } from "@hightouchio/ui";
import { capitalize } from "lodash";

import { ResourcePermissionGrant, ResourceToPermission } from "src/graphql";
import { actions, Permission } from "src/pages/settings/roles";

const resourcesWithActions: Record<
  ResourceToPermission,
  { actions: { label: ResourcePermissionGrant; description?: string }[]; description: string }
> = {
  workspace: {
    actions: [{ label: "read" }, { label: "create" }, { label: "update" }, { label: "delete" }],
    description: "Workspace permissions govern the ability create workspaces under your organization.",
  },
  destination: {
    actions: [
      {
        label: "read",
        description: "Required to create any sync that writes to the underlying destination.",
      },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
    ],
    description: "Destination permissions govern the ability to manage destination connections.",
  },
  source: {
    actions: [
      {
        label: "read",
        description: "Required to create any model or sync that accesses the underlying source.",
      },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
    ],
    description: "Source permissions govern the ability to manage source connections.",
  },
  sync_template: {
    actions: [{ label: "read" }, { label: "create" }, { label: "update" }, { label: "delete" }],
    description: "Sync template permissions govern the ability to manage sync templates.",
  },
  alert: {
    actions: [{ label: "read" }, { label: "create" }, { label: "update" }, { label: "delete" }],
    description: "Alert permissions govern the ability manage alerts on syncs.",
  },
  workspace_membership: {
    actions: [{ label: "read" }, { label: "create" }, { label: "update" }, { label: "delete" }],
    description: "Workspace permissions govern the ability manage users and roles in the workspace.",
  },
  audience: {
    actions: [
      { label: "read" },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
      {
        label: "preview",
        description: "Required to preview individual record data when building an audience.",
      },
    ],
    description: "Audience permissions govern the ability to manage individual audiences.",
  },
  audience_schema: {
    actions: [
      { label: "read" },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
      {
        label: "preview",
        description: "Required to preview any query when building a parent model.",
      },
    ],
    description:
      "Audience schema permissions govern the ability to manage audiences, parent models, event models and related models.",
  },
  model: {
    actions: [
      { label: "read" },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
      {
        label: "preview",
        description: "Required to preview any query when building the model. ",
      },
      {
        label: "approve",
        description: "Required to review and approve changes when the Approval Flows functionality is enabled.",
      },
    ],
    description: "Model permissions govern the ability to manage models.",
  },
  sync: {
    actions: [
      { label: "read" },
      { label: "create" },
      { label: "update" },
      { label: "delete" },
      { label: "enable", description: "Required to turn on a sync once it's created." },
      {
        label: "debugger",
        description: "Required to access sync logs in the Debugger panel, including the underlying data.",
      },
      { label: "testrow", description: "Required to test rows before saving the sync." },
      { label: "start" },
      {
        label: "approve",
        description: "Required to review and approve changes when the Approval Flows functionality is enabled.",
      },
    ],
    description: "Sync permissions govern the ability to manage syncs. ",
  },
};

export const resourceToFriendlyName = {
  workspace: "Workspace",
  destination: "Destination",
  source: "Source",
  model: "Model",
  sync: "Sync",
  audience: "Audience",
  audience_schema: "Audience schema",
  sync_template: "Sync template",
  workspace_membership: "Workspace membership",
  alert: "Alert",
};

interface Props {
  permissions: Permission[];
  setPermissions: (permissions: Permission[]) => void;
}

const toArray = (maybeArray: string | string[]) => {
  return Array.isArray(maybeArray) ? maybeArray : [maybeArray];
};

export const RolePermissionBuilder: FC<Props> = ({ permissions, setPermissions }) => {
  const actionsWithoutStar = actions.filter((action) => action !== "*");

  const isAllResourcesAndActionsEnabled = () => {
    return (
      permissions.length > 0 &&
      permissions.every((permission) => {
        return permission.actions.length === actionsWithoutStar.length;
      })
    );
  };

  const allActionsEnabled = (perm: Permission) => {
    return perm.actions.includes("*") || perm.actions.length === actionsWithoutStar.length;
  };

  const isAllActionsOfResourceEnabled = (resource) => {
    return permissions.some((perm) => {
      const asArray = toArray(perm.resource);
      if (asArray.includes(resource) || asArray.includes("*")) {
        return allActionsEnabled(perm);
      }
      return false;
    });
  };

  const findResource = (resource: string) => {
    return permissions.find((perm) => {
      const asArray = toArray(perm.resource);
      return asArray.includes(resource) || asArray.includes("*");
    });
  };

  const isChecked = (checkedActions: string[], checkedResources: string[]) => {
    for (const permission of permissions) {
      const actions = Array.isArray(permission.actions) ? permission.actions : [permission.actions];
      for (const enabledAction of actions) {
        const resources = Array.isArray(permission.resource) ? permission.resource : [permission.resource];
        for (const enabledResource of resources) {
          if (checkedActions.includes(enabledAction) && checkedResources.includes(enabledResource)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const onClick = (checked: boolean, action: string, resource: string) => {
    if (checked) {
      const thisResource = findResource(resource);
      if (thisResource) {
        thisResource.actions = toArray(thisResource.actions).filter((a) => a !== action);
      } else {
        permissions.push({ resource, actions: [action], effect: "allow" });
      }
    } else {
      const thisResource = findResource(resource);
      if (thisResource) {
        thisResource.actions = toArray(thisResource.actions).concat(action);
      } else {
        permissions.push({ resource, actions: [action], effect: "allow" });
      }
    }
    setPermissions(permissions);
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {Object.keys(resourcesWithActions).map((resource) => {
        let isAllChecked = false;
        if (resource === "*") {
          isAllChecked = isAllResourcesAndActionsEnabled();
        } else {
          isAllChecked = isAllActionsOfResourceEnabled(resource);
        }

        const onClickAll = () => {
          if (resource === "*") {
            if (isAllChecked) {
              setPermissions([]);
            } else {
              setPermissions(
                Object.keys(resourcesWithActions).map((resource) => ({
                  resource,
                  actions: resourcesWithActions[resource],
                  effect: "allow",
                })),
              );
            }
            return;
          }

          const thisResource = findResource(resource);
          if (!thisResource) {
            permissions.push({ resource, actions: isAllChecked ? [] : actionsWithoutStar, effect: "allow" });
          } else {
            thisResource.actions = isAllChecked ? [] : actionsWithoutStar;
          }

          setPermissions(permissions);
        };

        return (
          <Box
            key={resource}
            border="1px"
            borderColor="gray.300"
            borderRadius="md"
            display="flex"
            flexDirection="column"
            gap={4}
            p={4}
          >
            <Checkbox
              description={resourcesWithActions[resource].description}
              isChecked={isAllChecked}
              label={resource === "*" ? "All resources" : resourceToFriendlyName[resource]}
              onChange={onClickAll}
            />

            <CheckboxGroup ml={7}>
              {resourcesWithActions[resource].actions.map((action) => {
                const checkedResources = ["*", resource];
                const checkedActions = ["*", action.label];
                const checked = isChecked(checkedActions, checkedResources);

                return (
                  <Checkbox
                    key={action.label}
                    description={action.description}
                    isChecked={checked}
                    label={capitalize(action.label)}
                    onChange={() => onClick(checked, action.label, resource)}
                  />
                );
              })}
            </CheckboxGroup>
          </Box>
        );
      })}
    </Box>
  );
};

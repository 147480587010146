import { FC, useState } from "react";

import { AddBoxIcon, Column, Button, FormField, TextInput, Combobox, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, useFieldArray, NestedValue } from "react-hook-form";
import { object, string, array } from "yup";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  initialPropertyCondition,
  PropertyCondition,
  RelatedColumn,
  TraitColumn,
  TraitDefinition,
  AudienceParent,
  AdditionalColumn,
  Audience,
} from "src/types/visual";
import { Modal } from "src/ui/modal";

import { QueryBuilderProvider } from "../explore/context/query-builder-context";
import { DetailButton } from "../explore/visual/condition-buttons";
import { PropertyFilter } from "../explore/visual/property-filter";

const schema = object().shape({
  alias: string().required("Name is a required field."),
  trait: object()
    .shape({
      id: string().required("Parent trait required"),
      name: string().required("Parent trait required"),
    })
    .required("Parent trait required"),
  conditions: array(),
});

export const AudienceTraitForm: FC<
  Readonly<{
    title?: string;
    alias?: string;
    trait: TraitDefinition | undefined;
    conditions?: PropertyCondition[] | null;
    audience?: Audience;
    parent: AudienceParent | undefined | null;
    traits?: TraitDefinition[];
    onClose: () => void;
    onSubmit: (value: AdditionalColumn) => Promise<void>;
  }>
> = (props) => {
  const { audience, title, onSubmit, traits, parent, onClose } = props;
  const { hasValidationErrors } = useFormErrorContext();

  const { toast } = useToast();

  const [submitting, setSubmitting] = useState(false);
  const { handleSubmit, control, watch } = useForm<{
    alias: string | undefined;
    trait: TraitDefinition | undefined;
    conditions: NestedValue<PropertyCondition[]>;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      alias: props.alias,
      trait: props.trait,
      conditions: (props.conditions || []) as NestedValue<PropertyCondition[]>,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const { fields, append, remove } = useFieldArray({ name: "conditions", control });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const trait = watch("trait");

  const conditions = watch("conditions");

  const traitOptions = traits?.map((trait) => ({ label: trait.name, value: trait }));

  const submit = async ({ alias, trait, conditions }) => {
    if (hasValidationErrors()) {
      toast({
        id: "audience-trait-form",
        title: "There was a problem saving your trait",
        message: "Please check your configuration and try again",
        variant: "error",
      });
      return;
    }

    setSubmitting(true);

    const traitColumn: TraitColumn = {
      type: "trait",
      traitDefinitionId: trait?.id,
      conditions,
    };
    const relatedColumn: RelatedColumn = {
      type: "related",
      path: [String(trait.relationship.id)],
      column: traitColumn,
    };

    await onSubmit({ alias, column: relatedColumn });

    setSubmitting(false);

    onClose();
  };

  return (
    <QueryBuilderProvider
      audience={audience}
      columns={trait?.relationship.to_model.filterable_audience_columns}
      parent={parent}
    >
      <Modal
        footer={
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="primary" isLoading={submitting} onClick={handleSubmit(submit)}>
              {title ? "Save" : "Add"}
            </Button>
          </>
        }
        sx={{ maxWidth: "554px", width: "100%" }}
        title={title}
        onClose={onClose}
      >
        <Column gap={6}>
          <Controller
            control={control}
            name="alias"
            render={({ field, fieldState: { error } }) => (
              <FormField label="Name" error={error?.message}>
                <TextInput
                  width="100%"
                  isInvalid={Boolean(error)}
                  ref={field.ref}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value ?? ""}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="trait"
            render={({ field, fieldState: { error } }) => (
              <FormField label="Parent trait" error={error ? "Parent trait required" : undefined}>
                {typeof traits === "undefined" ? (
                  <TextInput width="100%" isDisabled isReadOnly value={trait?.name ?? ""} />
                ) : (
                  <Combobox width="100%" isInvalid={Boolean(error)} options={traitOptions ?? []} {...field} />
                )}
              </FormField>
            )}
          />
          <FormField label="Conditions">
            <Column
              gap={2}
              p={4}
              bg="gray.100"
              borderRadius="6x"
              sx={{
                "& > :not(:last-child)": { mb: 2 },
              }}
            >
              {fields.map(({ id }, index) => {
                const condition = conditions[index];
                return (
                  <Controller
                    key={id}
                    control={control}
                    name={`conditions.${index}`}
                    render={({ field }) => (
                      <PropertyFilter
                        showSecondaryFilter
                        audience={undefined}
                        condition={field.value as PropertyCondition}
                        columns={trait?.relationship.to_model.filterable_audience_columns}
                        traits={[]}
                        parent={parent}
                        onChange={(value) => {
                          field.onChange({ ...condition, ...value });
                        }}
                        onRemove={() => remove(index)}
                      />
                    )}
                  />
                );
              })}

              <DetailButton isDisabled={!trait} icon={AddBoxIcon} size="sm" onClick={() => append(initialPropertyCondition)}>
                Where...
              </DetailButton>
            </Column>
          </FormField>
        </Column>
      </Modal>
    </QueryBuilderProvider>
  );
};

import { FC } from "react";

import { Text, ThemeUICSSObject } from "theme-ui";

import { Box, Row } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";
import { commaNumber } from "src/utils/numbers";

export const leftCircleBaseColor = "#e4eaff";
export const leftCircleHoverColor = "#B3B8DB";
export const rightCircleBaseColor = "#67E3F9";
export const rightCircleHoverColor = "#22CCEE";
export const overlapBaseColor = "#0092FF";
export const overlapHoverColor = "#044188";

const defaultSize = 75;
const maxDiameter = 150;
const minDiameter = 15;
const minOverlapPercentageGreaterThan0 = 0.075;
const defaultPercentageOverlap = 0.15;

const baseStyles: ThemeUICSSObject = {
  borderRadius: "50%",
  opacity: 0.5,
  flexShrink: 0,
};

type Props = {
  baseCircleSize?: number;
  disableHover?: boolean;
  currentAudienceSize?: number;
  comparedAudienceSize?: number;
  maxCircleDiameter?: number;
  minCircleDiameter?: number;
  sharedMembers?: number;
  sx?: ThemeUICSSObject;
};

export const VennDiagram: FC<Readonly<Props>> = ({
  baseCircleSize = defaultSize,
  disableHover,
  currentAudienceSize: undefinedOrCurrentAudienceSize,
  comparedAudienceSize: undefinedOrComparedAudienceSize,
  maxCircleDiameter = maxDiameter,
  minCircleDiameter = minDiameter,
  sharedMembers: undefinedOrSharedMembers,
  sx,
}) => {
  const currentAudienceSize = undefinedOrCurrentAudienceSize || baseCircleSize;
  const comparedAudienceSize = undefinedOrComparedAudienceSize || baseCircleSize;

  // if no shared members - show 15% of the current audience size
  const sharedMembers = undefinedOrSharedMembers ?? defaultPercentageOverlap * currentAudienceSize;

  // Right circle size stays static. Calculate left based off of the
  // ratio of log10 of each size
  const leftCircleSize = Math.max(
    minCircleDiameter,
    Math.min(maxCircleDiameter, baseCircleSize * (Math.log10(currentAudienceSize) / Math.log10(comparedAudienceSize))),
  );

  const smallestAudience = Math.min(currentAudienceSize, comparedAudienceSize);
  let percentageOfSmallestAudienceToOverlap = sharedMembers / smallestAudience;

  // If overlap is too small it can be hard to interact with the overlap
  if (percentageOfSmallestAudienceToOverlap > 0 && percentageOfSmallestAudienceToOverlap < minOverlapPercentageGreaterThan0) {
    percentageOfSmallestAudienceToOverlap = minOverlapPercentageGreaterThan0;
  }

  const displacement = Math.min(baseCircleSize, leftCircleSize) * percentageOfSmallestAudienceToOverlap;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexShrink: 0,
        maxHeight: `${maxCircleDiameter}px`,
        ...sx,
      }}
    >
      <Tooltip
        portal
        content={
          <Row sx={{ alignItems: "center" }}>
            <Box sx={{ mr: 2, flexShrink: 0, height: "8px", width: "8px", borderRadius: "50%", bg: leftCircleHoverColor }} />
            <Text sx={{ color: "base.9", fontWeight: 500, whiteSpace: "nowrap" }}>
              {commaNumber(currentAudienceSize)} members
            </Text>
          </Row>
        }
        disabled={disableHover}
        sx={{ flexShrink: 0 }}
      >
        <Box
          id="left-circle"
          sx={{
            ...baseStyles,
            zIndex: 1,
            height: `${leftCircleSize}px`,
            width: `${leftCircleSize}px`,
            bg: leftCircleBaseColor,
            ":hover": disableHover
              ? {}
              : {
                  bg: leftCircleHoverColor,
                },
          }}
        />
      </Tooltip>

      <Box
        id="overlap"
        sx={{
          zIndex: 2,
          position: "absolute",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <Box
          id="left-overlap"
          sx={{
            display: "flex",
            alignItems: "center",
            height: `${leftCircleSize}px`,
            width: `${leftCircleSize}px`,
            overflow: "hidden",
            borderRadius: "50%",
            pointerEvents: "none",
          }}
        >
          <Tooltip
            portal
            content={
              <Row sx={{ alignItems: "center" }}>
                <Box sx={{ mr: 2, flexShrink: 0, height: "8px", width: "8px", borderRadius: "50%", bg: overlapHoverColor }} />
                <Text sx={{ color: "base.9", fontWeight: 500, whiteSpace: "nowrap" }}>
                  {commaNumber(sharedMembers ?? 0)} members
                </Text>
              </Row>
            }
            disabled={disableHover}
            sx={{ flexShrink: 0 }}
          >
            <Box
              id="right-overlap"
              sx={{
                flexShrink: 0,
                height: `${baseCircleSize}px`,
                width: `${baseCircleSize}px`,
                ml: `${leftCircleSize - displacement}px`,
                bg: overlapBaseColor,
                opacity: 0.6,
                borderRadius: "50%",
                overflow: "hidden",
                pointerEvents: "auto",
                ":hover": disableHover
                  ? {}
                  : {
                      bg: overlapHoverColor,
                    },
              }}
            />
          </Tooltip>
        </Box>
      </Box>

      <Tooltip
        portal
        content={
          <Row sx={{ alignItems: "center" }}>
            <Box sx={{ mr: 2, flexShrink: 0, height: "8px", width: "8px", borderRadius: "50%", bg: rightCircleHoverColor }} />
            <Text sx={{ color: "base.9", fontWeight: 500, whiteSpace: "nowrap" }}>
              {commaNumber(comparedAudienceSize)} members
            </Text>
          </Row>
        }
        disabled={disableHover}
        sx={{ flexShrink: 0 }}
      >
        <Box
          id="right-circle"
          sx={{
            ...baseStyles,
            zIndex: 1,
            height: `${baseCircleSize}px`,
            width: `${baseCircleSize}px`,
            ml: `${-displacement}px`,
            bg: rightCircleBaseColor,
            opacity: 0.6,
            ":hover": disableHover
              ? {}
              : {
                  opacity: 1,
                  bg: rightCircleHoverColor,
                },
          }}
        />
      </Tooltip>
    </Box>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const RepeatIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M7 7H17V10L21 6L17 2V5H5V11H7V7ZM17 17H7V14L3 18L7 22V19H19V13H17V17Z" />
    </Icon>
  );
};

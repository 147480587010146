import { forwardRef, useImperativeHandle, useState } from "react";

import { keyframes } from "@emotion/react";
import { BoltIcon } from "@heroicons/react/24/solid";
import { Box } from "@hightouchio/ui";

const shineAnimation = keyframes`
  from {
    transform: translate(0, 0) rotate(45deg);
  }

  to {
    transform: translate(var(--size), calc(var(--size) * -1)) rotate(45deg);
  }
`;

export interface LightningIconApi {
  shine: () => void;
}

export const LightningIcon = forwardRef<LightningIconApi>((_props, ref) => {
  const [isShining, setShining] = useState(false);

  const shine = () => {
    setShining(true);
  };

  useImperativeHandle(ref, () => {
    return { shine };
  });

  return (
    <Box
      boxSize={4}
      overflow="hidden"
      position="relative"
      role="group"
      sx={{
        "--size": "var(--chakra-sizes-4)",
      }}
    >
      <BoltIcon />
      <Box
        _groupHover={
          isShining
            ? undefined
            : {
                animation: `${shineAnimation} 600ms ease-in forwards`,
              }
        }
        animation={isShining ? `${shineAnimation} 600ms ease-in forwards` : undefined}
        bg="rgba(255, 255, 255, .6)"
        bottom={0}
        filter="blur(2px)"
        h={2}
        left="calc(var(--size) / -2)"
        position="absolute"
        transform="rotate(45deg)"
        transformOrigin="center"
        width="100%"
        onAnimationEnd={() => {
          setShining(false);
        }}
      />
    </Box>
  );
});

LightningIcon.displayName = "LightningIcon";

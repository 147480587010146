import { useEffect, useRef, FC } from "react";

import { Box, Column } from "@hightouchio/ui";
import { SearchBox } from "react-instantsearch-hooks-web";

import { SearchIcon } from "src/ui/icons";

type Props = {
  placeholder: string;
};

export const AlgoliaSearchInput: FC<Props> = (props) => {
  const searchBoxElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchBoxElement.current) {
      const searchInput = searchBoxElement.current.querySelector("input") as HTMLInputElement | undefined;
      searchInput?.focus();
    }
  }, []);

  return (
    <Box ref={searchBoxElement} position="relative">
      <Column zIndex={300} height="100%" justify="center" position="absolute" top={0} left={4}>
        <SearchIcon color="var(--chakra-colors-gray-600)" />
      </Column>
      <SearchBox {...props} />
    </Box>
  );
};

import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { Outlet } from "react-router-dom";

import { Nav } from "src/components/layout/nav";
import { useUser } from "src/contexts/user-context";

export const Layout: FC = () => {
  const { user } = useUser();

  return (
    <>
      {user && <Nav />}

      <Column flex={1} minWidth={0}>
        <Outlet />
      </Column>
    </>
  );
};

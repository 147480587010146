import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const RewindIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M11 18V6l-8.5 6 8.5 6zm.5-6 8.5 6V6l-8.5 6z"></path>
    </Icon>
  );
};

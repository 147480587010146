import { forwardRef } from "react";

import { Box, FlexProps } from "theme-ui";

export const Column = forwardRef<HTMLDivElement, FlexProps>(({ children, sx = {}, ...props }, ref) => {
  return (
    <Box ref={ref} sx={{ display: "flex", flexDirection: "column", ...sx }} {...props}>
      {children}
    </Box>
  );
});

Column.displayName = "Column";

import { FC, useMemo } from "react";

import { Column, Drawer, Link, StatsItem, StatsItemTitle, StatsItemValue, StatusIndicator, Text } from "@hightouchio/ui";
import { round, startCase } from "lodash";

import { abbreviateNumber } from "src/utils/numbers";

type Props = {
  metadata: any;
  onClose: () => void;
  isOpen: boolean;
  definition: { name: string } | undefined | null;
};

export const DestinationMetadataDrawer: FC<Props> = ({ metadata, onClose, isOpen, definition }) => {
  const indicatorVariant = useMemo(() => {
    switch (metadata?.status) {
      case "healthy":
        return "success";
      case "processing":
        return "processing";
      case "warning":
        return "warning";
      default:
        return "inactive";
    }
  }, [metadata?.status]);

  return (
    <Drawer title={metadata?.audience?.name} isOpen={isOpen} onClose={onClose} size="md">
      <Column gap={8}>
        <Column gap={2}>
          <StatsItemTitle>External status</StatsItemTitle>
          <StatusIndicator variant={indicatorVariant}>{startCase(metadata?.status)}</StatusIndicator>
          <Text color="text.secondary">We detect and indicate any errors with this audience in {definition?.name}</Text>
        </Column>

        <Column>
          <StatsItem>
            <StatsItemTitle>Matched users</StatsItemTitle>
            <StatsItemValue>{processAudienceSize(metadata?.audienceSize)}</StatsItemValue>
          </StatsItem>
          <Text color="text.secondary">The number of targetable matched users in your audience</Text>
        </Column>

        <Column>
          <StatsItem>
            <StatsItemTitle>Match percentage</StatsItemTitle>
            <StatsItemValue>{processMatchRate(metadata?.matchRate)}</StatsItemValue>
          </StatsItem>
          <Text color="text.secondary">The percentage of users in your audience that were matched</Text>
        </Column>

        <Column>
          <StatsItem>
            <StatsItemTitle>Links</StatsItemTitle>
            <StatsItemValue>
              <Link href={metadata?.audience?.url}>View your audience in {definition?.name}</Link>
            </StatsItemValue>
          </StatsItem>
        </Column>
      </Column>
    </Drawer>
  );
};

export const processMatchRate = (matchRate: number | undefined): string => {
  if (matchRate === undefined || matchRate === null) {
    return "N/A";
  }
  return String(round(matchRate * 100, 2)) + "% matched";
};

export const processAudienceSize = (audienceSize: number | undefined): string => {
  if (audienceSize === -1 || audienceSize === undefined || audienceSize === null) {
    return "N/A";
  }
  return String(abbreviateNumber(audienceSize)) + " users";
};

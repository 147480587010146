import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { Drawer, DrawerContent } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { Column, Combobox, EmptyState, Heading, LinkButton, Row, Link } from "@hightouchio/ui";
import { Helmet } from "react-helmet";
import { Navigate, Outlet, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

import "reactflow/dist/style.css";
import schemaPlaceholder from "src/assets/placeholders/schema.svg";
import sourcePlaceholder from "src/assets/placeholders/source.svg";
import { PageHeader } from "src/components/layout/header/page-header";
import { NewFeatureDialog } from "src/components/new-feature-modal";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { useHeaderHeight } from "src/contexts/header-height-context";
import {
  ResourcePermissionGrant,
  useAudienceSchemaGraphQuery,
  useAudienceSetupQuery,
  useObjectQuery,
  useSourceQuery,
} from "src/graphql";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { updateIntercomPosition } from "src/lib/intercom";
import { SchemaSettings } from "src/pages/schema/settings";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { OverlaySpinner, PageSpinner } from "src/ui/loading";

import { CreateSchemaModelWizard } from "./create";
import { Graph, GraphBackground } from "./graph/graph";
import { SchemaObject } from "./view";

const FEATURE_MODAL_KEY = "app-schema-revamp-modal";

export const Schema: FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const sourceId = searchParams.get("source");
  const parentModelId = searchParams.get("parent");
  const type = searchParams.get("type") as SchemaModelType;
  const selectedId = searchParams.get("id");
  const persistedSource = usePersistedState("schema-source", "");

  const [showNewSchemaFeatureModal, setShowNewSchemaFeatureModal] = useState(
    localStorage.getItem(FEATURE_MODAL_KEY) === "false" ? false : true,
  );

  const confirmNewFeatures = () => {
    setShowNewSchemaFeatureModal(false);
    localStorage.setItem(FEATURE_MODAL_KEY, "false");
  };

  const { data } = useAudienceSetupQuery(undefined, {
    refetchOnMount: "always",
    onSuccess: (data) => {
      const sources = data?.connections?.filter((source) => source?.definition?.supportedQueries?.includes(QueryType.Visual));
      if (!sourceId || !sources?.find((source) => String(source.id) === String(sourceId))) {
        let id;
        if (data.segments?.length) {
          const counts = data.segments.reduce((acc, segment: any) => {
            const connectionId = segment?.connection?.id;
            acc[connectionId] = (acc[connectionId] || 0) + 1;
            return acc;
          }, {});
          // Find the connection with the highest count and return its ID
          id = Object.entries(counts).reduce(
            (max: any, [connectionId, count]: any) => {
              if (count > max[1]) {
                return [connectionId, count];
              } else {
                return max;
              }
            },
            ["", -1],
          )[0];
        } else {
          id = sources?.[0]?.id;
        }
        setSearchParams({ source: id }, { replace: true });
      }
      setLoading(false);
    },
  });

  const { data: graph } = useAudienceSchemaGraphQuery(
    { sourceId: sourceId ?? "", parentModelId },
    { enabled: Boolean(sourceId), select: (data) => data.getSchemaGraph, refetchOnMount: "always" },
  );

  const allParentModels = data?.segments;
  const sources = data?.connections?.filter((source) => source?.definition?.supportedQueries?.includes(QueryType.Visual));
  const parentModels = allParentModels?.filter((m) => m.connection?.id == sourceId);

  const options = useMemo(() => {
    return (
      sources?.map((source) => ({
        value: `${source.id}`,
        label: source.name,
        icon: source.definition.icon,
      })) ?? []
    );
  }, [sources]);

  const isPlaceholder = !sources?.length || (!parentModels?.length && !type);

  useEffect(() => {
    if (sourceId) {
      if (persistedSource.value !== sourceId) {
        persistedSource.set(sourceId);
      }
    } else if (persistedSource.value) {
      setSearchParams({ source: persistedSource.value }, { replace: true });
    }
  }, [sourceId]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Column width="100%" height="100%">
      <PageHeader />

      <Helmet>
        <title>Schema</title>
      </Helmet>

      <Row
        align="center"
        gap={6}
        px={6}
        height="64px"
        flexShrink={0}
        boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
        justify="space-between"
        zIndex={1}
      >
        <Row align="center" gap={6}>
          <Heading size="xl">Schema</Heading>
          {Boolean(sources?.length) && (
            <Combobox
              optionAccessory={(option: any) => ({
                type: "image",
                url: option.icon,
              })}
              value={sourceId ?? ""}
              options={options}
              onChange={(value) => {
                if (value) {
                  setSearchParams({ source: value });
                }
              }}
              width="xs"
            />
          )}
        </Row>
        <Row gap={4} align="center">
          <Link href="/schema">Legacy schema</Link>
          {!isPlaceholder && <LinkButton href="settings">Settings</LinkButton>}
        </Row>
      </Row>
      {!sources?.length ? (
        <Column pos="relative" height="100%" width="100%" justify="center" align="center">
          <Column maxWidth="720px" width="100%" p={10} sx={{ "& > div": { boxShadow: "md", zIndex: 1, bg: "white" } }}>
            <EmptyState
              imageUrl={sourcePlaceholder}
              title="This workspace contains no sources"
              message="Define a source where your data is stored and then create the schema to describe how your data source will be queried by Audiences."
            />
          </Column>
          <GraphBackground />
        </Column>
      ) : !parentModels?.length && !type ? (
        <Column pos="relative" height="100%" width="100%" justify="center" align="center">
          <Column maxWidth="720px" width="100%" p={10} sx={{ "& > div": { boxShadow: "md", zIndex: 1, bg: "white" } }}>
            <EmptyState
              imageUrl={schemaPlaceholder}
              title="This source contains no parent models"
              message="A parent model describes how your data source will be queried, allowing you to select the specific source data you want to send to your destination."
              actions={
                <PermissionedLinkButton
                  variant="primary"
                  href={`/schema-v2/new?source=${sourceId}&type=parent`}
                  permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }]}
                >
                  Create parent model
                </PermissionedLinkButton>
              }
            />
          </Column>
          <GraphBackground />
        </Column>
      ) : (
        <>
          <Routes>
            <Route
              element={
                <>
                  <ReactFlowProvider>
                    {graph && (
                      <Graph
                        relationships={graph.relationships}
                        models={graph.models}
                        sourceId={sourceId}
                        selectedId={selectedId}
                        type={type}
                      />
                    )}
                  </ReactFlowProvider>
                  <Outlet />
                </>
              }
            >
              <Route path="/" element={<></>} />
              <Route
                path="/new"
                element={<CreateModel selectedId={selectedId} sourceId={sourceId} parentModelId={parentModelId} type={type} />}
              />
              <Route path="/view/*" element={<ViewModel id={selectedId} />} />
            </Route>
          </Routes>
        </>
      )}
      <NewFeatureDialog
        isOpen={showNewSchemaFeatureModal}
        confirmButtonText="Got it"
        pages={[
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/2dac407f1c374cb64ed6f7e0e2d89640502895db-2000x1158.png",
            description: "Use the graph view to visualize your schema relationships.",
            altText: "Graph view.",
          },
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/c656199b2eaeeb504de37a96441805193795b71f-2000x730.png",
            description: "Hover over a model and click the + button to add a new related or event model.",
            altText: "Create a new relationship.",
          },
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/bfe1fdd5a46c1fdf21902240ba27d5b7bcb48e6a-2000x1166.png",
            description: "Access the model search from the top left button to easily jump between different models.",
            altText: "Model search.",
          },
        ]}
        title="🎉 The schema setup refresh is here!"
        onClose={confirmNewFeatures}
      />
    </Column>
  );
};

export const SchemaPage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<Schema />} />
      <Route path="/settings/*" element={<SchemaSettings />} />
    </Routes>
  );
};

export const drawerWidth = 728;

const CreateModel: FC<
  Readonly<{
    sourceId: string | null | undefined;
    parentModelId: string | null | undefined;
    selectedId: string | null | undefined;
    type: SchemaModelType | null;
  }>
> = ({ sourceId, parentModelId, selectedId, type }) => {
  const navigate = useNavigate();

  const { data: source } = useSourceQuery(
    { id: String(sourceId) },
    {
      select: (data) => data.connections_by_pk,

      enabled: Boolean(sourceId),
    },
  );

  if (!type) {
    return null;
  }

  return (
    <SchemaDrawer>
      {source ? (
        <CreateSchemaModelWizard
          fromModelId={selectedId ?? ""}
          type={type}
          source={source}
          onCreated={() => {
            if (type === SchemaModelType.Parent) {
              navigate(`/schema-v2?source=${sourceId}`);
            } else {
              navigate(`/schema-v2?source=${sourceId}${parentModelId ? `&parent=${parentModelId}` : ""}`);
            }
          }}
        />
      ) : (
        <OverlaySpinner />
      )}
    </SchemaDrawer>
  );
};

const ViewModel: FC<Readonly<{ id: string | null }>> = ({ id }) => {
  const { data, isLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      notifyOnChangeProps: "tracked",
    },
  );

  if (!isLoading && !data) {
    return <Navigate to="/schema-v2" />;
  }

  return (
    <SchemaDrawer>
      {data?.segments_by_pk ? (
        <SchemaObject object={data.segments_by_pk} audienceCount={data.audienceAggregate.aggregate?.count} />
      ) : (
        <OverlaySpinner />
      )}
    </SchemaDrawer>
  );
};

const SchemaDrawer: FC<{ children: ReactNode }> = ({ children }) => {
  const { headerHeight } = useHeaderHeight();

  useEffect(() => {
    updateIntercomPosition(drawerWidth);
    return () => {
      updateIntercomPosition(0);
    };
  }, []);

  // TODO: Add support for this drawer type to Hightouch UI
  // Global styles are a workaround for this bug https://github.com/chakra-ui/chakra-ui/issues/2893
  return (
    <>
      <Drawer
        size="xl"
        isOpen={true}
        onClose={() => {}}
        placement="right"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
      >
        <DrawerContent
          mt={`${64 + headerHeight}px`}
          borderLeft="1px"
          borderTop="1px"
          borderColor="base.border"
          maxWidth="728px"
        >
          {children}
        </DrawerContent>
      </Drawer>
      <Global
        styles={{
          ".chakra-modal__content-container": { pointerEvents: "none", zIndex: 10 },
          ".chakra-modal__content": { pointerEvents: "auto", zIndex: 10 },
        }}
      />
    </>
  );
};

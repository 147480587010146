import { FC } from "react";

import { Label, Text } from "theme-ui";

import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, useUsersWithPermisionQuery } from "src/graphql";
import { TextArea } from "src/ui/input";
import { MultiSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";

// Human readable names that we use for analytics tracking.
export const saveAndRequestApproval = "saved with approval";
export const saveOnly = "saved";
export const publishNow = "published";

interface Props {
  resource: ResourceToPermission;
  selectedRadioOption: string;
  setSelectedRadioOption: (option: string) => void;
  canCreateResource: boolean;
  comment: string;
  setComment: (comment: string) => void;
  selectedUsers: number[];
  setSelectedUsers: (users: number[]) => void;
}

export const DraftSubmissionForm: FC<Props> = ({
  selectedRadioOption,
  setSelectedRadioOption,
  comment,
  setComment,
  resource,
  canCreateResource,
  selectedUsers,
  setSelectedUsers,
}) => {
  const { workspace, user } = useUser();
  const { draft } = useDraft();
  const { data } = useUsersWithPermisionQuery({
    permission: {
      resource_type: resource,
      access_type: "approve",
    },
  });

  const users = new Set(data?.getUsersWithPermissions || []);

  const approverOptions = workspace?.all_memberships
    .filter((membership) => membership.user_id !== user?.id)
    .filter((membership) => users.has(membership.user_id.toString()))
    .map((membership) => {
      // the user is defined, but becasue its a not a foreign key in hasura, it is a maybe.
      return {
        label: membership.user?.name || "",
        value: membership.user?.id || "",
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const baseRadioOptions = [
    {
      label: (draft?.approval_requests || []).length > 0 ? "Resubmit approval request" : "Submit approval request",
      value: saveAndRequestApproval,
      description: `Notify teammates to approve and publish this ${resource}.`,
    },
    {
      label: (draft?.approval_requests || []).length > 0 ? "Update draft" : "Save as draft",
      value: saveOnly,
      description: `Continue editing this ${resource} until you're ready to request approval.`,
    },
  ];

  let publishNowDescription = `Publish this ${resource} directly without requesting approval.`;
  if (resource === ResourceToPermission.Sync) {
    publishNowDescription = `${publishNowDescription} This will also publish any pending dependent model drafts.`;
  }

  const createOption = {
    label: "Publish now",
    value: publishNow,
    description: publishNowDescription,
  };
  const radioGroupOptions = canCreateResource ? [createOption, ...baseRadioOptions] : baseRadioOptions;

  return (
    <>
      <RadioGroup
        options={radioGroupOptions}
        value={selectedRadioOption}
        onChange={(value) => {
          setSelectedRadioOption(value);
        }}
      />

      {selectedRadioOption === saveAndRequestApproval && (
        <>
          <Label sx={{ mt: 6, mb: 2, fontSize: 16, fontWeight: "bold" }}>Select teammates to notify</Label>
          <MultiSelect
            searchable
            label="Teammates"
            options={approverOptions}
            placeholder="Select teammates"
            value={selectedUsers}
            onChange={(values) => {
              setSelectedUsers(values);
            }}
          />
          <Label sx={{ mt: 6, mb: 2 }}>
            <>
              <Text sx={{ fontSize: 16, fontWeight: "bold" }}>Comment</Text>
              <Text sx={{ ml: 1, fontSize: 16, fontWeight: "bold" }} variant="subtle">
                (optional)
              </Text>
            </>
          </Label>
          <TextArea
            placeholder="Leave a comment with your request (optional)"
            rows={4}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </>
      )}
    </>
  );
};

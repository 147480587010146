import { FC } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  label: string;
  onDelete: () => Promise<void>;
  disabled?: boolean;
  content?: string;
};

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

export const BulkDeleteConfirmationModal: FC<Readonly<Props>> = ({
  isOpen,
  onClose,
  count,
  label,
  onDelete,
  disabled,
  content,
}) => {
  const { toast } = useToast();

  const pluralizedLabel = pluralize(label, count);

  const handleBulkDelete = async () => {
    try {
      await onDelete();
      toast({
        id: "bulk-delete-resource-toast",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "bulk-delete-resource-toast",
        title: error.message,
        variant: "error",
      });
      if (!(error instanceof ValidationError)) {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <ConfirmationDialog
      confirmButtonText="Delete"
      isConfirmButtonDisabled={disabled}
      isOpen={isOpen}
      title={disabled ? `Unable to delete ${count} ${pluralizedLabel}` : `Delete ${count} ${pluralizedLabel}`}
      variant="danger"
      onClose={onClose}
      onConfirm={handleBulkDelete}
    >
      {content ? (
        <Paragraph>{content}</Paragraph>
      ) : (
        <Paragraph>
          Are you sure you want to delete {count} {pluralizedLabel}? You won't be able to undo this.
        </Paragraph>
      )}
    </ConfirmationDialog>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const WarningIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" />
    </Icon>
  );
};

import { FC, useEffect, useRef } from "react";

import { Row, Box, Column, Text, EmptyState, Spinner } from "@hightouchio/ui";
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import { useInfiniteHits } from "react-instantsearch-hooks-web";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { CategoryMenu } from "src/components/destinations/catalog/category-menu";
import { getDefinitionFromHit } from "src/components/destinations/catalog/destination-hits";
import { useDestinationCatalogSearch } from "src/components/destinations/catalog/destinations-catalog";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { AlgoliaSearchInput } from "src/components/search/algolia-search-input";
import { SearchProvider } from "src/components/search/search-provider";
import { List, ListButton } from "src/components/welcome/components";
import { DestinationDefinition, useDestinationDefinitionsQuery } from "src/graphql";

export const DestinationCatalog: FC<Readonly<{ onSelect: (definition: DestinationDefinition) => void }>> = (props) => {
  return (
    <SearchProvider filters="type:destination AND status:released">
      <DestinationCatalogInternal {...props} />
    </SearchProvider>
  );
};

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const DestinationCatalogInternal: FC<Readonly<{ onSelect: (definition: DestinationDefinition) => void }>> = ({ onSelect }) => {
  const definitions = useDestinationDefinitionsQuery(undefined, { select: (data) => data.getDestinationDefinitions });
  const { results, clearRefinements, currentCategory, menuApi } = useDestinationCatalogSearch();
  const { hits, showMore, isLastPage } = useInfiniteHits({
    cache: sessionStorageCache,
  });

  const sentinelRef = useRef<HTMLDivElement & HTMLLIElement>(null);

  useEffect(() => {
    const pageScrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    if (sentinelRef?.current) {
      pageScrollObserver.observe(sentinelRef.current);
    }

    return () => {
      pageScrollObserver.disconnect();
    };
  }, [isLastPage, showMore]);

  return (
    <Column
      height="100%"
      sx={{
        "& > div": { width: "100%" },
        width: "100%",
        overflow: "hidden",
        ".ais-SearchBox-input": { py: 2 },
      }}
    >
      <AlgoliaSearchInput placeholder="Search all destinations..." />
      <Row overflow="hidden" gap={6} mt={6} align="flex-start" flex={1}>
        <Column overflow="auto" maxWidth="200px" width="100%" height="100%">
          <CategoryMenu
            clear={clearRefinements}
            currentCategory={currentCategory}
            items={menuApi.items}
            query={results?.query ?? ""}
            refine={menuApi.refine}
          />
        </Column>
        <List
          isLoading={definitions.isLoading}
          isEmpty={Boolean(results?.queryID && results?.nbHits === 0)}
          placeholder={
            <EmptyState imageUrl={searchPlaceholder} title="No destinations found" message="Try adjusting your search" />
          }
        >
          {hits.map((hit: any) => {
            if (hit) {
              return (
                <ListButton
                  key={hit.objectID}
                  onClick={() => {
                    const definition = getDefinitionFromHit(definitions.data, hit);
                    if (definition) {
                      onSelect(definition);
                    }
                  }}
                >
                  <IntegrationIcon src={`${hit.icon.asset.url}?h=48`} name={hit.name} />
                  <Text fontWeight="medium">{hit.name}</Text>
                </ListButton>
              );
            }
            return null;
          })}
          {!isLastPage && (
            <Box as="li" ref={sentinelRef} listStyleType="none" aria-label="Loading more results" mx="auto" px={4} py={2}>
              <Spinner />
            </Box>
          )}
        </List>
      </Row>
    </Column>
  );
};

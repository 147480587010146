const message = {
  maxWidth: "80ch",
  border: "small",
  borderRadius: 1,
  p: 4,
  display: "flex",
  fontWeight: "thin",
};

export default {
  green: {
    ...message,
    borderColor: "greens.1",
    bg: "greens.0",
    svg: {
      fill: "green",
    },
  },
  default: {
    ...message,
    borderColor: "blues.1",
    bg: "blues.0",
    svg: {
      fill: "blue",
    },
  },
  warning: {
    ...message,
    borderColor: "yellows.1",
    bg: "yellows.0",
    svg: {
      fill: "yellow",
    },
  },
  error: {
    ...message,
    borderColor: "reds.1",
    bg: "reds.0",
    svg: {
      fill: "red",
    },
  },
};

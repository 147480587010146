import { FC } from "react";

import { Box, Switch } from "@hightouchio/ui";
import { Flex, Text } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";

type ColumnOrConstantFieldProps = {
  property: string;
  error?: string;
  columnLabel: string;
  columnDescription?: string;
  constantLabel: string;
  constantDescription?: string;
  constantInput: any;
  fieldProps?: any;
  disabled?: boolean;
};

export const ColumnOrConstantField: FC<Readonly<ColumnOrConstantFieldProps>> = ({
  property,
  error,
  columnLabel,
  columnDescription,
  constantLabel,
  constantDescription,
  constantInput,
  fieldProps = {},
  disabled = false,
}) => {
  const { config, setConfig, errors, hightouchColumns, loadingModel, reloadModel } = useDestinationForm();

  const value = config[property];
  const setValue = (value) => {
    setConfig({ ...config, [property]: value });
  };

  const valueIsColumn = value && typeof value !== "string";

  return (
    <Field
      description={valueIsColumn ? columnDescription : constantDescription}
      error={errors?.[property] || errors?.[`${property}.column`] || error}
      label={valueIsColumn ? columnLabel : constantLabel}
      size="large"
      {...fieldProps}
    >
      <Flex sx={{ alignItems: "center" }}>
        {valueIsColumn ? (
          <Select
            isError={Boolean(errors?.[`${property}.column`])}
            isLoading={loadingModel}
            options={hightouchColumns}
            placeholder="Select a column..."
            reload={reloadModel}
            sx={{ maxWidth: "280px" }}
            value={value?.column}
            width="280px"
            onChange={(selected) => {
              const val = selected?.value;
              setValue(val ? { column: val } : undefined);
            }}
          />
        ) : (
          <Flex>{constantInput}</Flex>
        )}
        {!disabled && (
          <Box alignItems="center" display="flex" gap={2} ml={4}>
            <Text
              sx={{
                textTransform: "uppercase",
                fontSize: "10px",
                color: "base.4",
                fontWeight: "bold",
              }}
            >
              Use column
            </Text>

            <Switch
              isChecked={Boolean(valueIsColumn)}
              onChange={(value) => {
                if (value) {
                  setValue({ column: undefined });
                } else {
                  setValue(undefined);
                }
              }}
            />
          </Box>
        )}
      </Flex>
    </Field>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const TicketIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M21.25 13.4764C20.429 13.4764 19.761 12.8145 19.761 12.001C19.761 11.1865 20.429 10.5246 21.25 10.5246C21.449 10.5246 21.64 10.4463 21.78 10.3076C21.921 10.1679 22 9.97864 22 9.78146L21.999 7.10415C21.999 4.84102 20.14 3 17.856 3H6.144C3.86 3 2.001 4.84102 2.001 7.10415L2 9.86766C2 10.0648 2.079 10.2541 2.22 10.3938C2.36 10.5325 2.551 10.6108 2.75 10.6108C3.599 10.6108 4.239 11.2083 4.239 12.001C4.239 12.8145 3.571 13.4764 2.75 13.4764C2.336 13.4764 2 13.8093 2 14.2195V16.8949C2 19.158 3.858 21 6.143 21H17.857C20.142 21 22 19.158 22 16.8949V14.2195C22 13.8093 21.664 13.4764 21.25 13.4764Z"
        opacity="0.4"
      />
      <path d="M15.4305 11.5887L14.2515 12.7367L14.5305 14.3597C14.5785 14.6407 14.4655 14.9177 14.2345 15.0837C14.0055 15.2517 13.7065 15.2727 13.4545 15.1387L11.9995 14.3737L10.5415 15.1397C10.4335 15.1967 10.3155 15.2267 10.1985 15.2267C10.0455 15.2267 9.89446 15.1787 9.76446 15.0847C9.53446 14.9177 9.42146 14.6407 9.46946 14.3597L9.74746 12.7367L8.56846 11.5887C8.36446 11.3907 8.29346 11.0997 8.38146 10.8287C8.47046 10.5587 8.70046 10.3667 8.98146 10.3267L10.6075 10.0897L11.3365 8.61268C11.4635 8.35868 11.7175 8.20068 11.9995 8.20068H12.0015C12.2845 8.20168 12.5385 8.35968 12.6635 8.61368L13.3925 10.0897L15.0215 10.3277C15.2995 10.3667 15.5295 10.5587 15.6175 10.8287C15.7065 11.0997 15.6355 11.3907 15.4305 11.5887Z" />
    </Icon>
  );
};

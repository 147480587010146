import { FC } from "react";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { CreateSourceWizard } from "./create-source-wizard";

export const CreateSource: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>New source</title>
      </Helmet>

      <CreateSourceWizard
        onCancel={() => {
          navigate("/sources");
        }}
        onSubmit={({ id }) => navigate(`/sources/${id}`)}
      />
    </>
  );
};

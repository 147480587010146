import { FC } from "react";

import { WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import { Box, Column, Heading, Text } from "@hightouchio/ui";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Card } from "src/ui/card";

export const Maintenance: FC = () => {
  return (
    <Box sx={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}>
      <AuthLayout>
        <Card>
          <Column align="center" color="gray.900">
            <Box color="forest.600" mb={8}>
              <WrenchScrewdriverIcon width="60px" />
            </Box>
            <Heading mb={4} size="lg">
              Scheduled maintenance
            </Heading>
            <Text>We apologize for any inconvience, we will be back soon!</Text>
          </Column>
        </Card>
      </AuthLayout>
    </Box>
  );
};

import { FC, ReactNode } from "react";

import { Link, Column, Row, Box } from "@hightouchio/ui";

import { Circle } from "src/ui/circle";
import { LogoutIcon } from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";
import { getInitials } from "src/utils/user";

export const UserMenu: FC<Readonly<{ children: ReactNode; user: { name: string; email: string } | null }>> = ({
  children,
  user,
}) => {
  return (
    <>
      <Menu
        portal
        header={
          <Column gap={2} width="100%">
            <Row gap={3} justify="space-between" overflow="hidden">
              <Box overflow="hidden">
                <Box fontSize="lg" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {user?.name}
                </Box>
                <Box color="gray.600" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {user?.email}
                </Box>
              </Box>
              <Circle color="avocado" radius="32px" sx={{ color: "forest", fontWeight: "bold", fontSize: 0 }}>
                {getInitials(user?.name)}
              </Circle>
            </Row>

            <Row align="center" gap={2}>
              <Link href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`}>Terms</Link>
              <Circle color="dark.1" radius="4px" />
              <Link href={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`}>Privacy</Link>
            </Row>
          </Column>
        }
        options={[
          {
            divider: "top",
            icon: LogoutIcon,
            label: "Log out of Hightouch",
            link: `${import.meta.env.VITE_API_BASE_URL}/auth/logout`,
          },
        ]}
        placement="bottom-end"
        sx={{ mt: 0 }}
        width="300px"
      >
        {children}
      </Menu>
    </>
  );
};

import { useEffect, useMemo, FC } from "react";

import { Text } from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  ReferencePropertyDefaultOperators,
  FilterableColumn,
  isColumnReference,
  ReferencedPropertyCondition,
  ReferencePropertyOperatorOptions,
} from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack } from "./condition";
import { XButton } from "./condition-buttons";
import { validateReferencePropertyFilter } from "./condition-validation";
import { Filter } from "./filter";
import { getColumnOptions } from "./utils";

export type ReferencePropertyFilterProps = FilterProps<ReferencedPropertyCondition> & {
  referenceColumns?: FilterableColumn[];
};

export const ReferencePropertyFilter: FC<Readonly<ReferencePropertyFilterProps>> = (props) => {
  const { columns: overrideColumns, referenceColumns, condition, onChange, onRemove } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { columns: allColumns } = useQueryBuilderContext();
  const columns = overrideColumns ?? allColumns;

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const valueFromColumnError = filterErrors?.valueFromColumn;

  useEffect(() => {
    setFieldError(filterId, validateReferencePropertyFilter(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.property, condition.valueFromColumn, filterId]);

  const propertyOptions = getColumnOptions(columns ?? [], !isColumnReference(condition.property));
  // Use string for valueFromColumn instead of column reference
  const referenceOptions = getColumnOptions(referenceColumns ?? [], true);
  const connectionType = props.audience?.connection?.type;
  const operatorOptions = condition.propertyType
    ? ReferencePropertyOperatorOptions[condition.propertyType].filter(
        (op) => !("supportedSources" in op) || op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;

  return (
    <HStack gap={2} sx={{ alignItems: "flex-start" }}>
      <AttributeSelect
        error={propertyError}
        options={propertyOptions}
        placeholder="Select a property"
        value={condition.property}
        onChange={(value, { type }) => {
          onChange({
            propertyType: type,
            property: value,
            operator: ReferencePropertyDefaultOperators[type],
            valueFromColumn: null,
          });
        }}
      />
      {condition.property && (
        <Filter
          content={
            <HStack gap={2} sx={{ alignItems: "flex-start" }}>
              <NewSelect
                options={operatorOptions}
                placeholder="Filter on"
                sx={{ flex: "0 0 auto" }}
                value={condition.operator}
                onChange={(operator) => {
                  onChange({ operator });
                }}
              />
              <AttributeSelect
                error={valueFromColumnError}
                options={referenceOptions}
                placeholder="Select a parent property"
                value={condition.valueFromColumn}
                width={180}
                onChange={(valueFromColumn) => onChange({ valueFromColumn })}
              />
            </HStack>
          }
          error={valueFromColumnError}
        >
          <Text color="text.secondary" fontWeight="medium">
            {operatorLabel}
          </Text>
          <Text isTruncated color={condition.valueFromColumn === null ? "text.secondary" : ""}>
            {condition.valueFromColumn || "(Select a parent property)"}
          </Text>
        </Filter>
      )}
      {onRemove && <XButton onRemove={onRemove} />}
    </HStack>
  );
};

import { FolderType, FolderViewType } from "src/components/folders/types";
import { flattenFolders, nestFolders } from "src/components/folders/utils";
import { useListFoldersQuery } from "src/graphql";

export const useFolders = ({ folderType, viewType }: { folderType: FolderType | undefined; viewType: FolderViewType }) => {
  const { data, refetch, isLoading } = useListFoldersQuery(
    { resourceType: viewType, folderType: folderType },
    {
      select: (data) => {
        const {
          listFolders: { folders, audienceCount, totalCount, modelCount },
        } = data;
        const nestedFolders = nestFolders(folders);
        const flattenedFolders = flattenFolders(nestedFolders);
        return {
          flattenedFolders,
          nestedFolders,
          audienceCount,
          modelCount,
          totalCount,
        };
      },
    },
  );

  return { ...(data || {}), refetchFolders: refetch, loadingFolders: isLoading };
};

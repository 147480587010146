import { MouseEventHandler, useEffect, useState, FC } from "react";

import { TextInput, Button, Row, Box, Link, Text, ButtonGroup, Pill } from "@hightouchio/ui";
import pluralize from "pluralize";
import { Text as LegacyText } from "theme-ui";
import { isPresent } from "ts-extras";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  ListAllSourceDependenciesQuery,
  useDestinationDefinitionsQuery,
  useListAllSourceDependenciesQuery,
  useSourceDefinitionsQuery,
} from "src/graphql";
import { Field } from "src/ui/field";
import { ChartIcon, ExternalLinkIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { Table, TableColumn } from "src/ui/table";
import { Tabs } from "src/ui/tabs";

type Props = {
  isOpen?: boolean;
  loading?: boolean;
  sources: string[];
  onCancel(args?: Parameters<MouseEventHandler>[0]): void;
  onDelete(args?: Parameters<MouseEventHandler>[0]): void;
  workspaceName: string;
};

type MergedSourceDependencies = {
  models: NonNullable<ListAllSourceDependenciesQuery["listAllSourceDependencies"]["sources"][0]>["dependencies"]["models"];
  syncs: NonNullable<ListAllSourceDependenciesQuery["listAllSourceDependencies"]["sources"][0]>["dependencies"]["syncs"];
  sources: { id: string }[];
};

const mergeSourceDependencies = (sourceDependencies: ListAllSourceDependenciesQuery["listAllSourceDependencies"]) => {
  const mergedSourceDependencies: MergedSourceDependencies = {
    models: [],
    syncs: [],
    sources: [],
  };
  sourceDependencies.sources.filter(isPresent).forEach((source) => {
    source.dependencies.models.forEach((model) => {
      mergedSourceDependencies.models.push(model);
    });
    source.dependencies.syncs.forEach((sync) => {
      mergedSourceDependencies.syncs.push(sync);
    });
    mergedSourceDependencies.sources.push(source);
  });
  return mergedSourceDependencies;
};

enum Tab {
  MODELS = "Models",
  SYNCS = "Syncs",
}

const getTableTabs = (mergedDependencies: MergedSourceDependencies) => [
  {
    render: () => (
      <Box>
        <LegacyText sx={{ display: "inline" }}>{Tab.MODELS}</LegacyText>
        <Pill ml={2}>{mergedDependencies.models.length}</Pill>
      </Box>
    ),
    value: Tab.MODELS,
  },
  {
    render: () => (
      <Box>
        <LegacyText sx={{ display: "inline" }}>{Tab.SYNCS}</LegacyText>
        <Pill ml={2}>{mergedDependencies.syncs.length}</Pill>
      </Box>
    ),
    value: Tab.SYNCS,
  },
];

export const BulkDeleteSourcesModal: FC<Readonly<Props>> = ({
  isOpen,
  loading,
  sources,
  onCancel,
  onDelete,
  workspaceName,
}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [tab, setTab] = useState<Tab>(Tab.MODELS);

  const { data: sourceDependenciesData, isLoading: loadingDependencies } = useListAllSourceDependenciesQuery(
    { ids: sources },
    { enabled: isOpen },
  );

  const { data: sourceDefinitions } = useSourceDefinitionsQuery(undefined, {
    enabled: isOpen,
    select: (data) => data.getSourceDefinitions,
  });

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(undefined, {
    enabled: isOpen,
    select: (data) => data.getDestinationDefinitions,
  });

  const [mergedSourceDependencies, setMergedSourceDependencies] = useState<MergedSourceDependencies>({
    sources: [],
    models: [],
    syncs: [],
  });
  useEffect(() => {
    if (sourceDependenciesData?.listAllSourceDependencies) {
      setMergedSourceDependencies(mergeSourceDependencies(sourceDependenciesData.listAllSourceDependencies));
    }
  }, [sourceDependenciesData]);

  const modelsColumns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name: modelName, modelSourceType }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        const definition = sourceDefinitions?.find((def) => def.type === modelSourceType);
        return (
          <Row gap={2} align="center">
            {definition ? (
              <IntegrationIcon name={modelSourceType} src={definition.icon} />
            ) : (
              <ChartIcon sx={{ width: "24px", flexShrink: 0, height: "24px", objectFit: "contain" }} />
            )}
            <Text fontWeight="medium" isTruncated>
              {modelName ?? "Private model"}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "Type",
      max: "max-content",
      cell: ({ modelType }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return modelType.toUpperCase();
      },
    },
    {
      max: "max-content",
      cell: ({ id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Link href={`/models/${id}`}>
            <ExternalLinkIcon color="base.5" />
          </Link>
        );
      },
    },
  ];

  const syncsColumns: TableColumn[] = [
    {
      name: "Model",
      cell: ({ modelName }: NonNullable<MergedSourceDependencies["syncs"][0]>) => {
        return (
          <Text fontWeight="medium" isTruncated>
            {modelName}
          </Text>
        );
      },
    },
    {
      name: "Destination",
      cell: ({ destinationName, destinationType }: NonNullable<MergedSourceDependencies["syncs"][0]>) => {
        const definition = destinationDefinitions?.find((def) => def.type === destinationType);
        return (
          <Row gap={2} align="center" overflow="hidden">
            <IntegrationIcon name={destinationName ?? "Destination icon"} src={definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {destinationName ?? "Private destination"}
            </Text>
          </Row>
        );
      },
    },
    {
      max: "max-content",
      cell: ({ id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Link href={`/syncs/${id}`}>
            <ExternalLinkIcon color="base.5" />
          </Link>
        );
      },
    },
  ];

  const sourceDependencyContent = (mergedDependencies: MergedSourceDependencies, loadingDependencies: boolean) => {
    return (
      <>
        <Box mb={4} fontSize="md" px={5}>
          We found some resources using {pluralize("this", mergedDependencies.sources.length, false)}{" "}
          {pluralize("source", mergedDependencies.sources.length, false)}. In order to delete the source we will need to also
          permanently delete the following dependent resources:
        </Box>

        <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={getTableTabs(mergedDependencies)} />

        {tab === Tab.MODELS && <Table columns={modelsColumns} data={mergedDependencies.models} loading={loadingDependencies} />}

        {tab === Tab.SYNCS && <Table columns={syncsColumns} data={mergedDependencies.syncs} loading={loadingDependencies} />}
      </>
    );
  };

  return (
    <Modal
      bodySx={{ px: 0 }}
      footer={
        <>
          <Field label="Type your workspace name to confirm deletion">
            <TextInput
              placeholder={workspaceName}
              value={deleteConfirmation}
              onChange={(event) => setDeleteConfirmation(event.target.value)}
            />
          </Field>
          <ButtonGroup>
            <Button
              onClick={() => {
                onCancel();
                setDeleteConfirmation("");
              }}
            >
              Cancel
            </Button>
            <Button
              isDisabled={deleteConfirmation !== workspaceName}
              isLoading={loading}
              variant="danger"
              onClick={() => {
                onDelete();
                setDeleteConfirmation("");
                onCancel();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </>
      }
      footerSx={{ alignItems: "end", justifyContent: "space-between" }}
      title={`Delete ${pluralize("this", sources.length, false)} ${pluralize("source", sources.length, false)}?`}
      isOpen={isOpen}
      sx={{ width: "600px" }}
      onClose={onCancel}
    >
      {mergedSourceDependencies.models.length > 0 ? (
        sourceDependencyContent(mergedSourceDependencies, loadingDependencies)
      ) : (
        <Box mb={4} fontSize="md" px={5}>
          These sources will be permanently deleted. Please confirm this action below
        </Box>
      )}
    </Modal>
  );
};

export const colors = {
  /* NEW COLORS. PLEASE USE */
  forest: "#03484D",
  "forest.light": "#4f7f82",
  "forest.dark": "#043d41",
  "forest.darker": "#002528",
  lightspeed: "#CAF23A",
  "lightspeed.dark": "#B2D82A",
  "lightspeed.darker": "#8DA929",
  space: "#222F51",
  ocean: "#658DF4",
  grass: "#4FC26B",
  peridot: "#F1F6D4",
  lilac: "#F1E7FC",
  plum: "#633CC6",
  electric: "#B979FF",
  sky: "#E2EDF6",
  avocado: "#E8F8EC",

  gray: {
    "50": "#fcfcfd",
    "100": "#f8fafc",
    "200": "#eef2f6",
    "300": "#e3e8ef",
    "400": "#cdd5df",
    "500": "#99a3b2",
    "600": "#697586",
    "700": "#4b5565",
    "800": "#364152",
    "900": "#121926",
  },

  /* DEPRECATED COLORS. DO NOT USE */
  black: "#0A0A11",
  blacks: [
    "rgba(0,0,0, .05)",
    "rgba(0,0,0, .1)",
    "rgba(0,0,0, .2)",
    "rgba(0,0,0, .3)",
    "rgba(0,0,0, .4)",
    "rgba(0,0,0, .5)",
    "rgba(0,0,0, .6)",
    "rgba(0,0,0, .7)",
    "rgba(0,0,0, .8)",
    "rgba(0,0,0, .9)",
  ],

  white: "#ffffff",
  whites: [
    "rgba(255,255,255, .05)",
    "rgba(255,255,255, .1)",
    "rgba(255,255,255, .2)",
    "rgba(255,255,255, .3)",
    "rgba(255,255,255, .4)",
    "rgba(255,255,255, .5)",
    "rgba(255,255,255, .6)",
    "rgba(255,255,255, .7)",
    "rgba(255,255,255, .8)",
    "rgba(255,255,255, .9)",
  ],

  primary: "#4928ED",
  primaries: [
    "rgba(73,40, 237, .04)",
    "rgba(73,40, 237, .08)",
    "rgba(73,40, 237, .12)",
    "rgba(73,40, 237, .16)",
    "rgba(73,40, 237, .3)",
    "rgba(73,40, 237, .4)",
    "rgba(73,40, 237, .5)",
    "rgba(73,40, 237, .6)",
    "rgba(73,40, 237, .7)",
    "rgba(73,40, 237, .8)",
    "rgba(73,40, 237, .9)",
  ],

  secondary: "#271F4D",
  secondaries: [
    "rgba(39, 31, 77, 0.04)",
    "rgba(39, 31, 77, 0.08)",
    "rgba(39, 31, 77, 0.12)",
    "rgba(39, 31, 77, 0.16)",
    "rgba(39,31,77,.1)",
    "rgba(39,31,77,.2)",
    "rgba(39,31,77,.3)",
    "rgba(39,31,77,.4)",
    "rgba(39,31,77,.5)",
    "rgba(39,31,77,.6)",
    "rgba(39,31,77,.7)",
    "rgba(39,31,77,.8)",
    "rgba(39,31,77,.9)",
  ],

  secondaryDark: "#0D0526",

  base: [
    "#FAFBFC",
    "#F7F9FC",
    "#E9ECF5",
    "#D0D7E2",
    "#A7AFBC",
    "#7B8698",
    "#596279",
    "#374253",
    "#192638",
    "#0F1825",
  ],

  indigo: "#0F0037",
  indigos: ["#A7ACF2", "#666EE8", "#4928ED", "#241948"],

  blue: "#3355FF",
  blues: ["rgba(51,85,255,.05)", "rgba(51,85,255,.35)"],

  red: "#DD425A",
  darkRed: "#7D2A47",
  reds: ["rgba(221,66,90,.05)", "rgba(221,66,90,.35)"],

  yellow: "#F8BC3B",
  yellows: ["rgba(248,188,59, .15)", "rgba(248,188,59, .35)"],

  green: "#4CAF50",
  greens: ["rgba(56,161, 105, .15)", "rgba(56,161, 105, .35)"],

  dark: [
    "rgba(10, 10, 17, 0.32)",
    "rgba(10, 10, 17, 0.42)",
    "rgba(10, 10, 17, 0.6)",
  ],

  highlight: "transparent",

  amber: "#ED6700",
  ambers: [
    "#FFF8E1",
    "#FFECB3",
    "#FFE082",
    "#FFD54F",
    "#FFCA28",
    "#F8BC3B",
    "#FFB300",
    "#FFA000",
    "#FF8F00",
    "#ED6700",
  ],

  teal: "#006547",
  teals: [
    "#E0F6F2",
    "#B2E9DE",
    "#7DDCC9",
    "#3DCDB3",
    "#00C0A1",
    "#00B391",
    "#00A483",
    "#009372",
    "#008364",
    "#006547",
  ],

  cyan: "#00605E",
  cyans: [
    "#E0F7F8",
    "#B1ECED",
    "#7FDFE2",
    "#4AD1D7",
    "#1CC7D0",
    "#00BDCB",
    "#00ADB8",
    "#00989F",
    "#008488",
    "#00605E",
  ],

  purple: "#4E00C6",
  purples: [
    "#F2E7FC",
    "#DDC4F7",
    "#C79BF2",
    "#B16FED",
    "#9E4AE8",
    "#8C18E2",
    "#8110DC",
    "#7300D3",
    "#6500CE",
    "#4E00C6",
  ],

  pink: "#852259",
  pinks: [
    "#FEF6FB",
    "#FDF2FA",
    "#FCE7F6",
    "#FCCEEE",
    "#FAA7E0",
    "#F670C7",
    "#EE46BC",
    "#DD2590",
    "#C11574",
    "#851651",
  ],

  orange: "#BF420C",
  oranges: [
    "#FBEDE7",
    "#FFD0BC",
    "#FFB291",
    "#FF9365",
    "#FF7B43",
    "#FF6422",
    "#F45E1E",
    "#E65719",
    "#D85015",
    "#BF420C",
  ],

  brown: "#64473A",
  browns: [
    "#FAF0E7",
    "#EAD9CE",
    "#D8C1B2",
    "#C4A794",
    "#B3917B",
    "#A27C62",
    "#95715A",
    "#84634F",
    "#755646",
    "#64473A",
  ],
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SixDots: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g fill="currentColor">
        <circle cx="15.5" cy="12" r="1.5"></circle>
        <circle cx="8.5" cy="12" r="1.5"></circle>
        <circle cx="15.5" cy="4.5" r="1.5"></circle>
        <circle cx="8.5" cy="4.5" r="1.5"></circle>
        <circle cx="15.5" cy="19.5" r="1.5"></circle>
        <circle cx="8.5" cy="19.5" r="1.5"></circle>
      </g>
    </Icon>
  );
};

import { ReactNode, forwardRef } from "react";

import { Button, Row } from "@hightouchio/ui";

type FilterPopoverButtonProps = {
  hasError?: boolean;
  children: ReactNode;
  isDisabled?: boolean;
};

export const FilterSelectButton = forwardRef<HTMLButtonElement, FilterPopoverButtonProps>(
  ({ children, hasError = false, isDisabled = false, ...props }, ref) => {
    return (
      <Row
        as={Button}
        ref={ref}
        isDisabled={isDisabled}
        p={0}
        _hover={{ borderColor: isDisabled ? "base.border" : "gray.border", bg: "base.lightBackground" }}
        borderColor={hasError ? "danger.border" : "base.border"}
        bg="base.lightBackground"
        fontWeight="medium"
        sx={{ "&[aria-expanded=true]": { boxShadow: hasError ? "outlineRed" : "outline", borderColor: "primary.base" } }}
        {...props}
      >
        {children}
      </Row>
    );
  },
);

FilterSelectButton.displayName = "FilterSelectButton";

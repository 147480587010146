import { ReactElement, useEffect, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Column, Text, Button, Box, useToast, Select, FormField, Row, IconButton, Link } from "@hightouchio/ui";
import * as Yup from "yup";

import { useMongodbSourceListCollectionsQuery, useMongodbSourceListDatabasesQuery } from "src/graphql";
import { getlineNumberofChar } from "src/utils/json";

import { Editor } from "../../../editor";
import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "../custom-query";

function QueryForm({ source, query, onChange, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const [filterErrorLine, setFilterErrorLine] = useState<number>();
  const {
    data: databases,
    isLoading: databaseLoading,
    error: databaseError,
    refetch: listDatabases,
  } = useMongodbSourceListDatabasesQuery({ connectionId: String(source.id) });

  const {
    data: collections,
    isLoading: collectionLoading,
    error: collectionError,
    refetch: listCollections,
  } = useMongodbSourceListCollectionsQuery({ connectionId: String(source.id), database: String(query?.database) });

  const { toast } = useToast();

  useEffect(() => {
    if (!query?.type) {
      onChange({ type: "mongodb" });
    }
  }, [query]);

  useEffect(() => {
    setError(databaseError);
  }, [databaseError]);

  useEffect(() => {
    setError(collectionError);
  }, [collectionError]);

  const checkValidJson = (filter: string) => {
    try {
      const parsed = JSON.parse(filter);
      const formattedFilter = JSON.stringify(parsed, null, 2);
      onChange({ ...query, filter: formattedFilter });
      setFilterErrorLine(0);

      toast({
        id: "check-json",
        title: "JSON filter is valid",
        variant: "success",
      });
    } catch (e) {
      setFilterErrorLine(getlineNumberofChar(filter, parseInt(e.message.split("at position ")[1])));

      toast({
        id: "check-json",
        title: "JSON filter is invalid",
        variant: "error",
      });
    }
  };

  return (
    <>
      <FormField label="Database" error={databaseError?.message}>
        <Row gap={2}>
          <Select
            isLoading={databaseLoading}
            options={
              databases?.mongodbSourceListDatabases?.map((d) => ({
                label: d.name,
                value: d.name,
              })) ?? []
            }
            placeholder="Select a database..."
            value={query?.database}
            onChange={(database) => {
              onChange({ ...query, database });
            }}
          />
          <IconButton
            aria-label="Refresh databases"
            icon={ArrowPathIcon}
            isDisabled={databaseLoading}
            variant="secondary"
            onClick={() => listDatabases()}
          />
        </Row>
      </FormField>
      <FormField label="Collection/View" error={collectionError?.message}>
        <Row gap={2}>
          <Select
            isLoading={collectionLoading}
            options={
              collections?.mongodbSourceListCollections?.map((d) => ({
                label: d.name + `${d.isView ? " (View)" : ""}`,
                value: d.name,
              })) ?? []
            }
            placeholder="Select a collection/view..."
            value={query?.collection}
            onChange={(collection) => {
              onChange({ ...query, collection });
            }}
          />
          <IconButton
            aria-label="Refresh collections"
            icon={ArrowPathIcon}
            isDisabled={collectionLoading}
            variant="secondary"
            onClick={() => listCollections()}
          />
        </Row>
      </FormField>
      <FormField
        isOptional
        description={
          <Text>
            The filter should be in{" "}
            <Link href="https://www.mongodb.com/docs/manual/reference/mongodb-extended-json/">Extended JSON v2 format</Link>
          </Text>
        }
        label="Filter"
      >
        <Box
          sx={{
            width: "100%",
            border: "1px solid",
            borderRadius: "md",
            borderColor: "base.border",
            height: "100px",
            mb: 2,
          }}
        >
          <Editor
            highlightErroredLine={filterErrorLine}
            language="json"
            placeholder={`{ "account_id": "20" }`}
            value={String(query?.filter ?? "")}
            onChange={(value) => {
              onChange({ ...query, filter: value ?? "" });
            }}
          />
        </Box>
        <Button
          size="sm"
          onClick={() => {
            checkValidJson(String(query?.filter));
          }}
        >
          Validate
        </Button>
      </FormField>
    </>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> | null {
  const configuration = props.source.config;
  return (
    <>
      <Column>
        <Text fontWeight="medium">Cluster URL</Text>
        {String(configuration["host"])}
      </Column>

      <Column>
        <Text fontWeight="medium">Database</Text>
        {String(props.query["database"])}
      </Column>

      <Column>
        <Text fontWeight="medium">Collection</Text>
        {String(props.query["collection"])}
      </Column>

      {props.query["filter"] ? (
        <Column>
          <Text fontWeight="medium">Filter</Text>
          <Editor language="json" readOnly={true} value={String(props.query["filter"])} />
        </Column>
      ) : null}
    </>
  );
}

export default {
  QueryForm,
  QueryView,
  querySchema: Yup.lazy<CustomQuery | undefined>(() => {
    return Yup.object().shape({
      type: Yup.string().required().equals(["mongodb"]),
      database: Yup.string().required(),
      collection: Yup.string().required(),
    });
  }),
};

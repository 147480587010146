import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const BitbucketIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon color="#0052CC" {...props}>
      <path d="M.778 1.213a.768.768 0 00-.768.892l3.263 19.81c.084.5.515.868 1.022.873H19.95a.772.772 0 00.77-.646l3.27-20.03a.768.768 0 00-.768-.891zM14.52 15.53H9.522L8.17 8.466h7.561z" />
    </Icon>
  );
};

import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { LookupMapper } from "src/components/destinations/lookup-mapper";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useUser } from "src/contexts/user-context";
import { useHubSpotLegacyColumnsQuery, useHubSpotLegacyObjectsQuery } from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape(
  {
    mode: Yup.string()
      .required()
      .when("object", {
        is: (object) => isEngagementType(object),
        then: Yup.string().oneOf(["insert"]),
        otherwise: Yup.string().default("upsert").oneOf(["upsert", "update"]),
      }),
    object: Yup.string().required().default("contacts"),
    objectName: Yup.string().required().default("contacts"),
    association: Yup.boolean().notRequired(),
    associationType: Yup.string().when("association", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    associatedObject: Yup.string().when("association", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    associatedObjectExternalIdMapping: Yup.object().when("association", {
      is: true,
      then: COMMON_SCHEMAS.externalIdMapping,
      otherwise: Yup.object().nullable().notRequired().default(undefined),
    }),

    externalIdMapping: Yup.object().when(["externalIdMappings", "mode"], {
      is: (externalIdMappings, mode) => !externalIdMappings && mode !== "insert",
      then: COMMON_SCHEMAS.externalIdMapping,
      otherwise: Yup.object().notRequired().default(undefined),
    }),

    externalIdMappings: Yup.array().when(["mode", "externalIdMapping"], {
      is: (mode, externalIdMapping) => (mode === "upsert" || mode === "update") && !externalIdMapping,
      then: Yup.array()
        .of(COMMON_SCHEMAS.externalIdMapping)
        .min(1)
        .required("There must be at least one merge rule when using the update or upsert mode"),
      otherwise: Yup.array().notRequired(),
    }),

    mappings: COMMON_SCHEMAS.mappings,
    secondaryEmailsFrom: Yup.mixed().notRequired(),
  },
  [["externalIdMapping", "externalIdMappings"]],
);

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const ENGAGEMENT_OBJECT_MODES = [{ label: "Insert", value: "insert" }];

// https://developers.hubspot.com/docs/api/crm/crm-custom-objects
// "Take note of the new id. This is used in combination with the metaType (which is always 2 for portal-specific objects) to generate the objectTypeId. The objectTypeId for the car object is: 2-529881."
const objectIds = {
  contacts: "0-1",
  companies: "0-2",
  deals: "0-3",
  tasks: "0-27",
  notes: "0-46",
  meetings: "0-47",
  calls: "0-48",
  emails: "0-49",
};

// Want to use static fields for engagement type objects because we need to use the v1 api
// when inserting (some properties that we need to write to are read only with v3)
// However, we can still use the v3 api to associate emails with other objects
const emailFields = [
  "metadata.from.email",
  "metadata.from.firstName",
  "metadata.from.lastName",
  "metadata.to.email",
  "metadata.to.firstName",
  "metadata.to.lastName",
  "metadata.cc",
  "metadata.bcc",
  "metadata.subject",
  "metadata.html",
  "metadata.text",
  "metadata.emailSendEventId.id",
  "engagement.timestamp",
];

const isEngagementType = (object: string) => {
  return object === "emails" || object === "calls" || object === "meetings" || object === "tasks" || object === "notes";
};

const getEngagementObjectFields = (object: string) => {
  switch (object) {
    case "emails":
      return emailFields.map((field) => ({ label: field.replace(/engagement.|metadata./, ""), value: field }));
    default:
      return [];
  }
};

export const HubspotLegacyForm: FC = () => {
  const { config, setConfig, errors, hightouchColumns, destination, reloadModel, loadingModel } = useDestinationForm();
  const { featureFlags } = useUser();

  const {
    data: objectsData,
    error: objectsError,
    isFetching: loadingObjects,
    refetch: getObjects,
  } = useHubSpotLegacyObjectsQuery({
    destinationId: String(destination?.id),
  });

  const objects = objectsData?.hubspotLegacyDescribeGlobal?.objectsV2;

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useHubSpotLegacyColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.hubspotLegacyDescribeObject;

  const {
    data: associatedColumnsData,
    error: associatedColumnsError,
    isFetching: loadingAssociatedColumns,
    refetch: getAssociatedColumns,
  } = useHubSpotLegacyColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.associatedObject,
    },
    { enabled: Boolean(config?.associatedObject) },
  );

  const associatedColumns = associatedColumnsData?.hubspotLegacyDescribeObject;

  const objectOptions = objects?.map((a) => ({
    label: a.name,
    value: a.id,
    object: a,
  }));

  const selectedObject = config?.object ? objectOptions?.find((s) => config?.object === s.value) : null;

  const associatedKeyColOpts =
    associatedColumns?.fields?.map((field) => {
      return {
        label: field.name,
        value: field.name,
      };
    }) || [];

  const sfKeyCols = columns?.fields?.map((field) => field.name) || [];

  const allAssociationOpts =
    columns?.associations.map((asc) => {
      return {
        label: asc.name,
        value: asc.name,
        association: asc,
      };
    }) || [];

  const associationOpts = allAssociationOpts.filter((a) => {
    const id = selectedObject?.object?.id;
    const associationObjectId = typeof id === "string" ? objectIds[id] || id : id;
    return a.association.fromObjectTypeId === associationObjectId;
  });

  const fieldMapperOpts = isEngagementType(config?.object)
    ? getEngagementObjectFields(config?.object)
    : columns?.fields?.map((field) => ({ label: field.name, value: field.name })) || [];

  return (
    <>
      <ObjectField
        error={errors?.objectName || objectsError?.message}
        loading={loadingObjects}
        options={objectOptions}
        reload={getObjects}
        onChange={(object) =>
          setConfig({
            object,
            objectName: objectOptions?.find((s) => object === s.value)?.label || undefined,
            mode: isEngagementType(object) ? "insert" : config?.mode,
          })
        }
      />

      {config?.object && (
        <ModeField
          options={isEngagementType(config?.object) ? ENGAGEMENT_OBJECT_MODES : MODES}
          onChange={(mode) => {
            setConfig({
              object: config?.object,
              objectName: config?.objectName,
              mode,
            });
          }}
        />
      )}

      {config?.object && (config?.mode === "upsert" || config?.mode === "update") && (
        <Section>
          <Field
            required
            error={errors?.externalIdMappings || columnsError?.message}
            help="These rules will be used to match rows from Hightouch with rows on Hubspot. If there are multiple rules, Hightouch will try each rule in order until there is a match. Each rule will be used as a field mapping as well."
            label="How should records between query results and destination be matched?"
            size="large"
          >
            <LookupMapper
              destColumns={sfKeyCols}
              destColumnsDisabled={!columns}
              destColumnsLoading={loadingColumns}
              destName="Hubspot"
              errors={errors?.externalIdMappings}
            />
          </Field>
        </Section>
      )}
      {config?.object && (
        <>
          <Section>
            <Field
              key={config?.associationType || columnsError?.message}
              optional
              label="Would you like to add an object association?"
              size="large"
            >
              <Select
                isClearable
                disabled={!selectedObject}
                isLoading={loadingColumns}
                options={associationOpts}
                placeholder="Select an association..."
                reload={getColumns}
                value={associationOpts.find((o) => o.value === config?.associationType)}
                width="240px"
                onChange={(selected) => {
                  if (!selected?.value) {
                    setConfig({
                      ...config,
                      association: false,
                      associationType: undefined,
                      associatedObject: undefined,
                      associatedObjectExternalIdMapping: undefined,
                    });
                    return;
                  }
                  const opt = associationOpts.find((o) => o.association?.name === selected.value);

                  setConfig({
                    ...config,
                    association: true,
                    associationType: opt?.association?.name,
                    associatedObject: opt?.association?.toObjectTypeId,
                    associatedObjectExternalIdMapping: undefined,
                  });
                }}
              />
            </Field>
          </Section>

          {config?.association && config?.associationType && (
            <Section>
              <Field
                error={
                  errors?.associatedObjectExternalIdMapping ||
                  errors?.["associatedObjectExternalIdMapping.from"] ||
                  errors?.["associatedObjectExternalIdMapping.to"] ||
                  associatedColumnsError?.message
                }
                label="How should records between query results and the associated object be matched?"
                size="large"
              >
                <Grid columns="repeat(3, max-content)" gap={8} sx={{ alignItems: "center" }}>
                  <Select
                    isError={errors?.associatedObjectExternalIdMapping || errors?.["associatedObjectExternalIdMapping.from"]}
                    isLoading={loadingModel}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    reload={reloadModel}
                    value={config?.associatedObjectExternalIdMapping?.from}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected.value;
                      const mp = config?.associatedObjectExternalIdMapping
                        ? { ...config?.associatedObjectExternalIdMapping }
                        : {};

                      mp.from = val;

                      setConfig({
                        ...config,
                        associatedObjectExternalIdMapping: mp,
                      });
                    }}
                  />
                  <Arrow />
                  <Select
                    disabled={!associatedColumns}
                    isError={errors?.associatedObjectExternalIdMapping || errors?.["associatedObjectExternalIdMapping.to"]}
                    isLoading={loadingAssociatedColumns}
                    options={associatedKeyColOpts}
                    placeholder="Select a field..."
                    reload={getAssociatedColumns}
                    value={associatedKeyColOpts.find((o) => o.value === config?.associatedObjectExternalIdMapping?.to)}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected.value;
                      const mp = config?.associatedObjectExternalIdMapping
                        ? { ...config?.associatedObjectExternalIdMapping }
                        : {};

                      mp.to = val;

                      setConfig({
                        ...config,
                        associatedObjectExternalIdMapping: mp,
                      });
                    }}
                  />
                </Grid>
              </Field>
            </Section>
          )}
        </>
      )}

      {config?.object && (
        <>
          <Section>
            <MappingsField
              error={columnsError?.message}
              loading={loadingColumns}
              options={fieldMapperOpts}
              reload={getColumns}
            />
          </Section>

          {featureFlags?.hubspot_secondary_email && config?.object === "contacts" && (
            <Section>
              <Field
                description="Optional - select a column used to drive secondary emails in HubSpot. To send multiple emails, return a comma-separated string from your query."
                label="Hubspot - Secondary email column (optional)"
                size="large"
              >
                <Select
                  isClearable
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.secondaryEmailsFrom}
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, secondaryEmailsFrom: val });
                  }}
                />
              </Field>
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default {
  form: HubspotLegacyForm,
  validation,
};

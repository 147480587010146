import { FC, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { FormField, Select, Spinner, IconButton, Row } from "@hightouchio/ui";
import * as Yup from "yup";

import { useMetabaseListQuestionsQuery } from "src/graphql";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError,
    refetch: listQuestions,
  } = useMetabaseListQuestionsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(questionsError);
  }, [questionsError]);

  return (
    <FormField label="Question" error={questionsError?.message}>
      <Row gap={2}>
        <Select
          isLoading={questionsLoading}
          options={questions?.metabaseListQuestions?.map((q) => ({ label: q.name, value: q.id })) ?? []}
          placeholder="Select a question..."
          value={query?.id}
          onChange={(id) => onChange({ ...query, id })}
        />
        <IconButton
          aria-label="Refresh questions"
          icon={ArrowPathIcon}
          isDisabled={questionsLoading}
          variant="secondary"
          onClick={() => listQuestions()}
        />
      </Row>
    </FormField>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError,
  } = useMetabaseListQuestionsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(questionsError);
  }, [questionsError]);

  const question = questions?.metabaseListQuestions?.find((q) => q.id === query.id);
  const questionName = question?.name || `Unknown question with id ${query.id}`;

  return <FormField label="Question">{questionsLoading ? <Spinner /> : questionName}</FormField>;
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["question"]),
  id: Yup.number().required(),
});

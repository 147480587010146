import { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface UrlType extends BaseType {
  type: "URL";
  maxLength?: number;
}

export function url(props?: Props<UrlType>): UrlType {
  return {
    type: "URL",
    ...props,
  };
}

export const validator: ValidatorSync<UrlType> = (type) => {
  let schema = yup.string().url();

  if (typeof type.maxLength === "number") schema = schema.max(type.maxLength);

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const MinimizeIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M7.41016 18.59L8.83016 20L12.0002 16.83L15.1702 20L16.5802 18.59L12.0002 14L7.41016 18.59ZM16.5902 5.41L15.1702 4L12.0002 7.17L8.83016 4L7.41016 5.41L12.0002 10L16.5902 5.41Z" />
    </Icon>
  );
};

import {
  ComponentType,
  FormkitComponent,
  FormkitForm,
  FormkitNode,
  NodeType,
  OptionsOrReference,
} from "../../api";
import { cloneDeep, omit } from "lodash";
import { KeyValueMappingComponent } from "../../api/components/key-value-mapping";
import { Liquid } from "liquidjs";

type Secret = {
  key: string;
  node?: FormkitComponent;
};

// get all component keys in a form, recursively
export function getKeysInForm(node: FormkitNode): Set<string> {
  const keys = new Set<string>();
  if (node.type === NodeType.Component) {
    keys.add(node.key);
  }

  for (const child of node.children ?? []) {
    const childKeys = getKeysInForm(child);
    for (const key of childKeys.values()) {
      keys.add(key);
    }
  }
  return keys;
}

/**
 * Returns a copy of a FormkitNode tree without validation properties for each component
 * so the tree can be properly serialized before being passed
 * to the frontend.
 */
export function getNodesWithoutValidations(
  nodes: FormkitNode[],
): FormkitNode[] {
  const clonedTree = cloneDeep(nodes);
  removeNodeValidations(clonedTree);
  return clonedTree;
}

/**
 * Recursively removes validation properties from a FormkitNode tree.
 * Note: operates in-place and directly changes the input object
 */
function removeNodeValidations(nodes: FormkitNode[]): void {
  for (const node of nodes) {
    if (node.type === NodeType.Component) {
      const { validation, ...propsWithoutValidation } = node.props;
      node.props = propsWithoutValidation;
    }

    removeNodeValidations(node.children ?? []);
  }
}

export function getZerothIndexAsDefault(props: {
  options: OptionsOrReference;
  default?: any;
  placeholder?: string;
}) {
  let defaultValue;
  let defaultPlaceholder;

  if (props.options instanceof Array && props.options.length > 0) {
    defaultValue = props.default || props.options[0]?.value;

    if (props.placeholder) {
      defaultPlaceholder = props.placeholder;
    } else {
      defaultPlaceholder = props.options[0]?.label ?? undefined;
    }
  }

  return { defaultValue, defaultPlaceholder };
}

/**
 * Returns a lists of keys coorelated with Secret Components
 * @returns List of keys
 */
export function retrieveAllSecrets(form: FormkitForm): Secret[] {
  const autoGeneratedSecretFields: Secret[] = [];

  const iterateThroughNodes = (node?: FormkitNode) => {
    if (!node) {
      return;
    }

    if (
      node?.type === NodeType.Component &&
      (node?.component === ComponentType.Secret ||
        (isKeyValueMapping(node) && node.props.enableEncryption))
    ) {
      autoGeneratedSecretFields.push({
        key: node.key,
        node,
      });
    } else if (
      node.type === NodeType.Layout ||
      node.type === NodeType.Modifier
    ) {
      node.children.forEach(iterateThroughNodes);
    }
  };

  iterateThroughNodes(form);
  return autoGeneratedSecretFields;
}
export const maybeNested = (key: string, parent: string | undefined) => {
  return parent ? `${parent}.${key}` : key;
};

const isKeyValueMapping = (
  node: FormkitComponent,
): node is KeyValueMappingComponent => {
  if (node?.component === ComponentType.KeyValueMapping) return true;
  return false;
};

export function hasLiquid(value: unknown) {
  if (!value) return false;
  const stringified = typeof value === "string" ? value : JSON.stringify(value);
  return (
    stringified.includes("{{") &&
    stringified.includes("}}") &&
    stringified.indexOf("{{") < stringified.indexOf("}}")
  );
}

export function getLiquidEngine() {
  const engine = new Liquid();
  // Add filter to use a default value when input value is empty
  engine.registerFilter("default", (inputValue, defaultValue) => {
    return inputValue == null || inputValue === "" ? defaultValue : inputValue;
  });

  engine.registerFilter("omit", (input, ...keys) => {
    if (typeof input === "object" && input) {
      return omit(input, keys);
    }

    return input;
  });

  return engine;
}

export const SKIP_LIQUID_PARSE_FIELDS = [
  "template",
  "placeholder",
  "variables",
];
export function processValuesWithLiquid(
  valueToParse: unknown | Record<string, unknown> | unknown[],
  context: Record<string, unknown>,
  engine: Liquid,
) {
  if (!valueToParse || typeof valueToParse !== "object") {
    if (typeof valueToParse === "string") {
      try {
        return engine.parseAndRenderSync(valueToParse, context);
      } catch (err) {
        return valueToParse;
      }
    }
    return valueToParse;
  }
  if (Array.isArray(valueToParse)) {
    return valueToParse.map((item) =>
      processValuesWithLiquid(item, context, engine),
    );
  }
  for (const key of Object.keys(valueToParse)) {
    const value = valueToParse[key];
    if (value == null) continue;
    try {
      // Do not parse and render template mappings
      if (
        !SKIP_LIQUID_PARSE_FIELDS.includes(key) &&
        (typeof value === "string" || typeof value === "object")
      ) {
        valueToParse[key] = processValuesWithLiquid(value, context, engine);
      }
    } catch (err) {
      // do nothing -- continue
    }
  }
  return valueToParse;
}

import { useMemo } from "react";

import { LinkButton, Row, SearchInput, Text } from "@hightouchio/ui";
import { useNavigate } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Permission } from "src/components/permission";
import { SegmentsBoolExp, useRelatedModelsQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const RelatedModels = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");

  const hasuraFilters = useMemo(() => {
    const filters: SegmentsBoolExp = {
      _and: [{ is_schema: { _eq: true } }, { _not: { event: {} } }, { primary_key: { _is_null: true } }],
    };

    if (search) {
      filters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return filters;
  }, [search]);

  const relatedModelsQuery = useRelatedModelsQuery({ filters: hasuraFilters }, { keepPreviousData: true });

  const columns = [
    {
      name: "Name",
      cell: ({ name, connection }) => {
        return (
          <Row align="center" gap={2}>
            <IntegrationIcon name={connection?.definition?.name} src={connection?.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {name ?? "Private source"}
            </Text>
            <SourceBadges isSampleDataSource={connection?.definition?.isSampleDataSource} />
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  return (
    <>
      <Row py={8} justify="space-between">
        <SearchInput placeholder="Search by name..." value={search ?? ""} onChange={(event) => setSearch(event.target.value)} />
        <Permission>
          <LinkButton href="/schema/related-models/new" variant="primary">
            Add related model
          </LinkButton>
        </Permission>
      </Row>

      <Table
        columns={columns}
        data={relatedModelsQuery.data?.segments}
        error={Boolean(relatedModelsQuery.error)}
        loading={relatedModelsQuery.isLoading || relatedModelsQuery.isRefetching}
        placeholder={{
          title: "No related models",
          error: "Related models failed to load, please try again.",
        }}
        onRowClick={({ id }, event) => openUrl(`/schema/related-models/${id}`, navigate, event)}
      />
    </>
  );
};

import { Text } from "theme-ui";

export const Strike = ({ old, _new }: { old?: string; _new?: string }) => {
  return (
    <>
      {old && (
        <Text as="span" sx={{ mr: 1, display: "inline", textDecoration: "line-through" }}>
          {old}
        </Text>
      )}
      <Text as="span" sx={{ display: "inline" }}>
        {_new}
      </Text>
    </>
  );
};

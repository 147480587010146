import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ArrowDownIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M4.9,17.6l6,6c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.4-0.1c0.2-0.1,0.4-0.2,0.5-0.3l6-6c0.6-0.6,0.6-1.6,0-2.2c-0.6-0.6-1.6-0.6-2.2,0l-3.4,3.4V1.5C13.5,0.7,12.8,0,12,0
      c-0.8,0-1.5,0.7-1.5,1.5v17.3l-3.4-3.4C6.8,15.1,6.4,15,6,15c-0.4,0-0.8,0.1-1.1,0.4C4.3,16,4.3,17,4.9,17.6z"
      />
    </Icon>
  );
};

import { FC } from "react";

import pluralize from "pluralize";

import { SelectComponentProps } from "../types";
import { Container } from "./container";

export const MultiValue: FC<SelectComponentProps & { value: any; label: string }> = (props) => {
  const { value, label } = props;

  return <Container {...props}>{`${value.length} ${pluralize(label, value.length)}`}</Container>;
};

import { FC, forwardRef } from "react";

import { Textarea as ThemedTextarea, TextareaProps } from "theme-ui";

type Props = {
  error?: boolean;
  onValue?: (value: string) => void;
  type?: string;
} & TextareaProps;

export const TextArea: FC<Readonly<Props>> = forwardRef<HTMLTextAreaElement, Props>(
  ({ error, sx = {}, onChange, onValue, ...props }, ref) => (
    <ThemedTextarea
      ref={ref}
      sx={{
        resize: "vertical",
        borderColor: error ? "red" : undefined,
        ...sx,
      }}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (onValue) {
          onValue(event.target.value);
        }
      }}
      {...props}
    />
  ),
);

TextArea.displayName = "TextArea";

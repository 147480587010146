import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SlackIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.8752 11.2498C20.9106 11.2498 21.75 10.4104 21.75 9.37497C21.75 8.33952 20.9105 7.50012 19.8751 7.50012C18.8395 7.50012 18 8.33959 18 9.37512V11.2498H19.8752ZM14.625 11.2498C15.6605 11.2498 16.5 10.4103 16.5 9.37481V4.12512C16.5 3.08959 15.6605 2.25012 14.625 2.25012C13.5895 2.25012 12.75 3.08959 12.75 4.12512V9.37481C12.75 10.4103 13.5895 11.2498 14.625 11.2498Z"
        fill="#2EB67D"
      />
      <path
        d="M4.12484 12.7501C3.08939 12.7501 2.25 13.5895 2.25 14.625C2.25 15.6604 3.08947 16.4998 4.12492 16.4998C5.16045 16.4998 6 15.6603 6 14.6248V12.7501H4.12484ZM9.375 12.7501C8.33948 12.7501 7.5 13.5896 7.5 14.6251V19.8748C7.5 20.9103 8.33948 21.7498 9.375 21.7498C10.4105 21.7498 11.25 20.9103 11.25 19.8748V14.6251C11.25 13.5896 10.4105 12.7501 9.375 12.7501Z"
        fill="#E01E5A"
      />
      <path
        d="M12.75 19.8752C12.75 20.9106 13.5894 21.75 14.6248 21.75C15.6603 21.75 16.4997 20.9105 16.4997 19.8751C16.4997 18.8395 15.6602 18 14.6247 18H12.75V19.8752ZM12.75 14.625C12.75 15.6605 13.5895 16.5 14.625 16.5H19.8747C20.9102 16.5 21.7497 15.6605 21.7497 14.625C21.7497 13.5895 20.9102 12.75 19.8747 12.75H14.625C13.5895 12.75 12.75 13.5895 12.75 14.625Z"
        fill="#ECB22E"
      />
      <path
        d="M11.2497 4.12484C11.2497 3.08939 10.4103 2.25 9.37486 2.25C8.33941 2.25 7.50002 3.08947 7.50002 4.12492C7.50002 5.16046 8.33949 6 9.37502 6H11.2497V4.12484ZM11.2497 9.375C11.2497 8.33948 10.4102 7.5 9.37471 7.5H4.125C3.08947 7.5 2.25 8.33948 2.25 9.375C2.25 10.4105 3.08947 11.25 4.125 11.25H9.37471C10.4102 11.25 11.2497 10.4105 11.2497 9.375Z"
        fill="#36C5F0"
      />
    </Icon>
  );
};

import type { FormkitComponent } from "./components";
import type { FormkitLayout } from "./layouts";
import type { FormkitModifier } from "./modifiers";

export type FormkitNode = FormkitLayout | FormkitModifier | FormkitComponent;

export type BaseFormkitNode = {
  type: NodeType;
};

export enum NodeType {
  Layout = "layout",
  Modifier = "modifier",
  Component = "component",
}

import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { ArrowRightIcon } from "../icons";

type ArrowProps = {
  bg?: string;
  color?: string;
  sx?: ThemeUIStyleObject;
  size?: number;
};

export const Arrow: FC<Readonly<ArrowProps>> = ({ color = "var(--chakra-colors-gray-400)", size = 16, sx = {} }) => (
  <ArrowRightIcon color={color} size={size} sx={{ mx: 4, ...sx }} />
);

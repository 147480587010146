import { useEffect, useState } from "react";

import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Row,
  Column,
  SectionHeading,
  Link,
  Paragraph,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
  StatusIndicator,
  MenuActionsButton,
  EmptyState,
} from "@hightouchio/ui";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { Settings } from "src/components/settings";
import { CreateNormalTunnelForm, CreateReverseTunnelForm } from "src/components/tunnels/create-tunnel";
import { DeleteTunnel } from "src/components/tunnels/delete-tunnel";
import { EditTunnelForm } from "src/components/tunnels/edit-tunnel";
import { useTestTunnelQuery, useTunnelsQuery } from "src/graphql";
import { InfoModal } from "src/ui/modal/info-modal";
import { Table, TableColumn } from "src/ui/table";

import { useRegionConfig } from "../../utils/regions";

enum TunnelType {
  REVERSE = "reverse",
  NORMAL = "normal",
}

export const Tunnels = () => {
  const navigate = useNavigate();
  const params = useParams<{ modal_type?: string }>();
  const modal = params?.modal_type;
  const [editTunnel, setEditTunnel] = useState();
  const [deleteTunnel, setDeleteTunnel] = useState<any>();
  const [tunnelError, setTunnelError] = useState<string | undefined | null>();

  const { data, refetch, isLoading } = useTunnelsQuery();

  const { tunnelsSupported } = useRegionConfig();
  if (!tunnelsSupported) {
    return (
      <Settings route="tunnels" title="Tunnels">
        <EmptyState
          message="SSH tunneling support is currently in private beta in this region. Please contact us if you would like to request access."
          title="Tunneling not available"
        />
      </Settings>
    );
  }

  const startColumns = [
    {
      name: "Name",
      key: "tunnel",
      cell: ({ name }) => (
        <Text fontWeight="medium" isTruncated>
          {name}
        </Text>
      ),
    },
    {
      name: "Status",
      key: "tunnel",
      max: "120px",
      cell: ({ id }) => {
        const [polling, setPolling] = useState<boolean>(false);

        const { data, isLoading } = useTestTunnelQuery({ id }, { refetchInterval: polling ? 3000 : undefined });

        const error = data?.checkTunnel?.error;

        useEffect(() => {
          if (error) {
            setPolling(true);
          }
        }, [error]);

        if (isLoading) {
          return null;
        }

        return (
          <StatusIndicator variant={data?.checkTunnel?.success ? "success" : "error"}>
            {data?.checkTunnel?.success ? "Connected" : "Error"}
          </StatusIndicator>
        );
      },
    },
  ];

  const createdAtColumn: TableColumn = {
    name: "Created at",
    key: "tunnel",
    max: "260px",
    cell: ({ createdAt }) => moment(createdAt).calendar(),
    breakpoint: "md",
  };

  const normalTunnelColumns = [
    ...startColumns,
    {
      name: "SSH host",
      key: "tunnel",
      max: "140px",
      cell: ({ sshHost, sshPort }) => `${sshHost}:${sshPort}`,
    },
    {
      name: "Service host",
      key: "tunnel",
      max: "140px",
      cell: ({ serviceHost, servicePort }) => `${serviceHost}:${servicePort}`,
    },
    createdAtColumn,
    {
      max: "40px",
      cell: ({ tunnel }) => {
        return (
          <Menu>
            <MenuActionsButton />

            <MenuList>
              <MenuItem icon={PencilSquareIcon} onClick={() => setEditTunnel(tunnel)}>
                Edit
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={TrashIcon} variant="danger" onClick={() => setDeleteTunnel(tunnel)}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  const reverseTunnelColumns = [
    ...startColumns,
    {
      name: "Tunnel port",
      key: "tunnel.tunnelPort",
      max: "120px",
    },
    createdAtColumn,
    {
      max: "40px",
      cell: ({ tunnel }) => {
        return (
          <Menu>
            <MenuActionsButton />

            <MenuList>
              <MenuItem icon={PencilSquareIcon} onClick={() => setEditTunnel(tunnel)}>
                Edit
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={TrashIcon} variant="danger" onClick={() => setDeleteTunnel(tunnel)}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  const tunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.NORMAL);
  const reverseTunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.REVERSE);

  return (
    <>
      <Settings route="tunnels" title="Tunnels">
        <Column flex={1} mb={10}>
          <Row justify="space-between" mb={8}>
            <Box maxWidth="2xl">
              <SectionHeading>Standard tunnels</SectionHeading>
              <Paragraph mt={1}>
                Access services within a private network by configuring Hightouch to open an SSH connection to your bastion,
                then open a port forwarding connection from your bastion to the private service. Learn more in our{" "}
                <Link href="https://hightouch.com/docs/security/ssh-tunneling">docs</Link>.
              </Paragraph>
            </Box>

            <Button
              variant="primary"
              onClick={() => {
                navigate("/settings/tunnels/create");
              }}
            >
              Add standard tunnel
            </Button>
          </Row>

          <Table
            columns={normalTunnelColumns}
            data={tunnels}
            loading={isLoading}
            placeholder={{
              title: "No tunnels",
              error: "Tunnels failed to load, please try again.",
            }}
          />
        </Column>

        <Column flex={1}>
          <Row justify="space-between" mb={8}>
            <Box maxWidth="2xl">
              <SectionHeading>Reverse tunnels</SectionHeading>
              <Paragraph mt={1}>
                Access services within a private network by connecting as a client to an SSH server managed by Hightouch, then
                forwarding a port. Learn more in our <Link href="https://hightouch.com/docs/security/ssh-tunneling">docs</Link>.
              </Paragraph>
            </Box>

            <Button
              variant="primary"
              onClick={() => {
                navigate("/settings/tunnels/create-reverse");
              }}
            >
              Add reverse tunnel
            </Button>
          </Row>

          <Table
            columns={reverseTunnelColumns}
            data={reverseTunnels}
            loading={isLoading}
            placeholder={{
              title: "No reverse tunnels",
              error: "Tunnels failed to load, please try again.",
            }}
          />
        </Column>
      </Settings>

      {modal === "create" && (
        <CreateNormalTunnelForm
          onClose={() => {
            navigate("/settings/tunnels");
          }}
        />
      )}
      {modal === "create-reverse" && <CreateReverseTunnelForm onClose={() => navigate("/settings/tunnels")} />}
      {deleteTunnel && (
        <DeleteTunnel
          id={deleteTunnel?.id}
          name={deleteTunnel?.name}
          refetch={() => refetch()}
          onClose={() => setDeleteTunnel(undefined)}
        />
      )}
      {editTunnel && <EditTunnelForm tunnel={editTunnel} onClose={() => setEditTunnel(undefined)} />}
      <InfoModal isOpen={tunnelError ? true : false} title="Error" onClose={() => setTunnelError("")}>
        <Box maxWidth="2xl">
          <Paragraph>{tunnelError}</Paragraph>
        </Box>
      </InfoModal>
    </>
  );
};

import emailIcon from "src/components/logos/email.svg";
import pagerdutyIcon from "src/components/logos/pagerduty.svg";
import slackIcon from "src/components/logos/slack.svg";
import smsIcon from "src/components/logos/sms.svg";

import { EmailForm, emailValidator } from "./email";
import { PagerdutyForm } from "./pagerduty";
import { SlackForm, slackValidator } from "./slack";
import { SmsForm, smsValidator } from "./sms";
import { AlertTypes } from "./types";

export type AlertDetails = {
  icon: string;
  name: string;
  form: (props: any) => JSX.Element;
  validator?: (values: any) => boolean;
};

export const alertTypes: Record<AlertTypes, AlertDetails> = {
  [AlertTypes.Email]: {
    name: "Email",
    icon: emailIcon,
    form: EmailForm,
    validator: emailValidator,
  },
  [AlertTypes.SMS]: {
    name: "SMS",
    icon: smsIcon,
    form: SmsForm,
    validator: smsValidator,
  },
  [AlertTypes.Slack]: {
    name: "Slack",
    icon: slackIcon,
    form: SlackForm,
    validator: slackValidator,
  },
  [AlertTypes.PagerDuty]: {
    name: "PagerDuty",
    icon: pagerdutyIcon,
    form: PagerdutyForm,
  },
};

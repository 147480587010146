import { FC } from "react";

import { Box, Code, Paragraph, Text as HightouchUiText } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";

import { FormProps, NEW_STATIC_ICONS, StaticType, STATIC_ICONS } from "./types";

enum VariableType {
  MODEL_ID = "model-id",
  MODEL_NAME = "model-name",
  CURRENT_TIMESTAMP = "current-timestamp",
  SYNC_ID = "sync-id",
  SYNC_RUN_ID = "sync-run-id",
}

export const VARIABLE_OPTIONS = [
  {
    label: "model-id",
    variable: VariableType.MODEL_ID,
    type: StaticType.STRING,
  },
  {
    label: "model-name",
    variable: VariableType.MODEL_NAME,
    type: StaticType.STRING,
  },
  {
    label: "current-timestamp",
    variable: VariableType.CURRENT_TIMESTAMP,
    type: StaticType.DATETIME,
  },
  {
    label: "sync-id",
    variable: VariableType.SYNC_ID,
    type: StaticType.NUMBER,
  },
  {
    label: "sync-run-id",
    variable: VariableType.SYNC_RUN_ID,
    type: StaticType.NUMBER,
  },
];

export const VariableInput: FC<Readonly<FormProps>> = ({ useHightouchUi = false, value, onChange }) => {
  const getInput = () => {
    switch (value.variable) {
      case VariableType.MODEL_ID:
        if (useHightouchUi) {
          return (
            <Paragraph>
              <Code>model-id</Code> will send the ID of the current model as a string.
            </Paragraph>
          );
        }

        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>model-id</Text> will send the id of
            the current model as a string.
          </Text>
        );
      case VariableType.MODEL_NAME:
        if (useHightouchUi) {
          return (
            <Paragraph>
              <Code>model-name</Code> will send the name of the current model as a string.
            </Paragraph>
          );
        }

        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>model-name</Text> will send the name
            of the current model as a string.
          </Text>
        );
      case VariableType.CURRENT_TIMESTAMP:
        if (useHightouchUi) {
          return (
            <Paragraph>
              <Code>current-timestamp</Code> will send the current timestamp as a string.
            </Paragraph>
          );
        }

        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>current-timestamp</Text> will send
            the current timestamp as a string.
          </Text>
        );
      case VariableType.SYNC_ID:
        if (useHightouchUi) {
          return (
            <Paragraph>
              <Code>sync-id</Code> will send the ID of the current sync as a number.
            </Paragraph>
          );
        }

        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>sync-id</Text> will send the id of
            the current sync as a number.
          </Text>
        );
      case VariableType.SYNC_RUN_ID:
        if (useHightouchUi) {
          return (
            <Paragraph>
              <Code>sync-run-id</Code> will send the ID of the current sync run as a number.
            </Paragraph>
          );
        }

        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>sync-run-id</Text> will send the id
            of the current sync run as a number.
          </Text>
        );
      default:
        return undefined;
    }
  };

  if (useHightouchUi) {
    return (
      <Box display="flex" flex="1" minWidth={0}>
        <Box
          borderColor="gray.300"
          borderRightWidth="1px"
          display="flex"
          flex="none"
          flexDirection="column"
          gap={1}
          p={3}
          width="180px"
        >
          {VARIABLE_OPTIONS.map(({ label, variable, type }) => {
            const isActive = variable === value.variable;
            const Icon = NEW_STATIC_ICONS[type];

            return (
              <Box
                key={label}
                _hover={{
                  bg: isActive ? "forest.100" : "gray.100",
                }}
                alignItems="center"
                bg={isActive ? "forest.100" : undefined}
                borderRadius="base"
                cursor="pointer"
                display="flex"
                gap={2}
                px={2}
                py={1.5}
                sx={
                  isActive
                    ? {
                        span: {
                          color: "forest.600",
                        },
                      }
                    : undefined
                }
                onClick={() => {
                  onChange({
                    type: "variable",
                    variable,
                  });
                }}
              >
                <Box as={Icon} boxSize={3} />
                <HightouchUiText size="sm">{label}</HightouchUiText>
              </Box>
            );
          })}
        </Box>

        <Box flex="1" minWidth={0} p={3}>
          {getInput()}
        </Box>
      </Box>
    );
  }

  return (
    <Row sx={{ flex: 1 }}>
      <Column sx={{ p: 3, borderRight: "small", width: "180px" }}>
        {VARIABLE_OPTIONS.map((option) => {
          const active = option.variable === value.variable;
          const Icon = STATIC_ICONS[option.type];

          return (
            <Row
              key={option.label}
              sx={{
                fontWeight: "semi",
                p: 2,
                borderRadius: 1,
                cursor: "pointer",
                minHeight: "32px",
                alignItems: "center",
                fontSize: 0,
                flex: 0,
                px: 2,
                bg: active ? "avocado" : undefined,
                ":hover": { bg: active ? undefined : "base.0" },
              }}
              onClick={() => onChange({ type: "variable", variable: option.variable })}
            >
              <Icon color="base.5" size={18} sx={{ mr: 2 }} />
              <Text sx={{ color: active ? "forest" : "base.5" }}>{option.label}</Text>
            </Row>
          );
        })}
      </Column>
      <Column sx={{ flex: 1, p: 3 }}>
        <Row>{getInput()}</Row>
      </Column>
    </Row>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DocsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M11.5538 7.75296C10.564 7.27063 9.45202 7 8.27688 7C7.10173 7 5.9898 7.27063 5 7.75296V17.1155C5.9898 16.6331 7.10173 16.3625 8.27688 16.3625C9.83911 16.3625 11.2896 16.8408 12.49 17.6589C13.6904 16.8408 15.1409 16.3625 16.7031 16.3625C17.8783 16.3625 18.9902 16.6331 19.98 17.1155V7.75296C18.9902 7.27063 17.8783 7 16.7031 7C15.528 7 14.416 7.27063 13.4262 7.75296V14.49C13.4262 15.0071 13.0071 15.4263 12.49 15.4263C11.9729 15.4263 11.5538 15.0071 11.5538 14.49V7.75296Z"
        fill="white"
        fillOpacity="0.3"
      />
    </Icon>
  );
};

import { ExtendedTypesSync, validatorSync as typeValidator } from ".";
import { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface ObjectType extends BaseType {
  type: "OBJECT";
  /**
   *  If true, object is considered invalid if it contains unknown properties.
   * @default false
   */
  strict?: boolean;
  /**
   * Shape of properties.
   * @default `{ [key: string]: UnknownType }`
   */
  properties?: { [key: string]: ExtendedTypesSync };
}

export function object(opts?: Props<ObjectType>): ObjectType {
  return {
    ...opts,
    type: "OBJECT",
  };
}

export const validator: ValidatorSync<ObjectType> = (type) => {
  let schema = yup.object();

  if (type.properties) {
    const validatorMap: Record<string, (value: unknown) => void> = {};
    for (const [field, propType] of Object.entries(type.properties)) {
      validatorMap[field] = typeValidator(propType);
    }
    schema = schema.test((v) => {
      Object.entries(validatorMap).forEach(([field, validate]) => {
        try {
          validate(v[field]);
        } catch (e) {
          throw new Error(
            `invalid value in property ${field}: ${e?.message || String(e)}`,
          );
        }
      });

      if (type.strict) {
        const invalidFields: string[] = [];
        for (const field in v) {
          if (!type.properties?.[field]) invalidFields.push(field);
        }

        if (invalidFields.length)
          throw new Error(
            `unknown object ${
              invalidFields.length === 1 ? "property" : "properties"
            }: ${invalidFields.join()}`,
          );
      }

      return true;
    });
  }

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ExtensionsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M8 8.84V7.88C8 6.28942 9.34315 5 11 5H13C14.6569 5 16 6.28942 16 7.88V8.84H18C19.1046 8.84 18.5 8.84 20 8.84V14.1879C17.5096 15.0749 14.8149 15.5599 12 15.5599C9.18515 15.5599 6.49037 15.0749 4 14.1879V10.76C4 9.69961 4 10 4 8.84H8ZM10 7.88C10 7.34981 10.4477 6.92 11 6.92H13C13.5523 6.92 14 7.34981 14 7.88V8.84H10V7.88ZM11 12.68C11 12.1498 11.4477 11.72 12 11.72H12.01C12.5623 11.72 13.01 12.1498 13.01 12.68C13.01 13.2102 12.5623 13.64 12.01 13.64H12C11.4477 13.64 11 13.2102 11 12.68Z"
        fillOpacity="0.3"
        fillRule="evenodd"
      />
      <path d="M4.00977 15.5605V19.776C5 19.776 4.9052 19.7805 6.00977 19.7805H18.0098C19.1143 19.7805 18.5 19.696 20.0098 19.776V15.5606C17.497 16.3748 14.8062 16.8159 12.0098 16.8159C9.21339 16.8159 6.52256 16.3748 4.00977 15.5605Z" />
    </Icon>
  );
};

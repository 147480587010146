import type { FormkitBoolean } from "./booleans";
import type { BaseFormkitNode, FormkitNode, NodeType } from "./nodes";
import { FormkitGraphQLReference, GraphQLVariables } from "./references";

export type FormkitModifier = BaseFormkitNode &
  (ShowModifierNode | ResetModifierNode | AsyncReferenceModifierNode);

export type ShowModifierNode = {
  type: NodeType.Modifier;
  modifier: ModifierType.Show;
  condition: FormkitBoolean;
  children: FormkitNode[];
};

export type ResetModifierNode = {
  type: NodeType.Modifier;
  modifier: ModifierType.Reset;
  keys: string[];
  children: FormkitNode[];
};

export type AsyncReferenceModifierNode = {
  type: NodeType.Modifier;
  modifier: ModifierType.AsyncReference;
  handler: FormkitGraphQLReference<GraphQLVariables>;
  children: FormkitNode[];
};

export enum ModifierType {
  Show = "show",
  Reset = "reset",
  AsyncReference = "async-reference",
}

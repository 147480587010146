import { FC } from "react";

import { Column } from "src/ui/box";
import { SearchInput } from "src/ui/input";
import { Pagination, Table, TableColumn } from "src/ui/table";

import { Indices } from "../../../design/indices";

type Props = {
  label: string;
  search: string;
  loading: boolean;
  data: any;
  onSelect: (any) => void;
  onSearch: (string) => void;
  error: boolean;
  columns: TableColumn[];
  pagination?: Pagination;
};

type Pagination = {
  page: number;
  limit: number;
  count: number | undefined;
  setPage: (number) => void;
};

export const ResourceSelect: FC<Readonly<Props>> = ({
  data,
  onSearch,
  search,
  loading,
  columns,
  pagination,
  onSelect,
  error,
  label,
}) => {
  return (
    <Column
      sx={{
        mt: -8,
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <Column
        sx={{
          position: "sticky",
          top: "144px",
          bg: "white",
          zIndex: Indices.Content,
          width: "100%",
          height: "80px",
          justifyContent: "flex-end",
          mb: 6,
        }}
      >
        <SearchInput
          autoFocus
          placeholder="Search by name..."
          sx={{ width: "100%" }}
          value={search}
          variant="large"
          onChange={onSearch}
        />
      </Column>
      <Table
        columns={columns}
        data={data}
        error={error}
        loading={loading}
        placeholder={{
          title: `No ${label}s`,
          error: `${label}s failed to load, please try again.`,
        }}
        onRowClick={onSelect}
      />
      {pagination && <Pagination {...pagination} label={`${label}s`} rowsPerPage={pagination.limit} />}
    </Column>
  );
};

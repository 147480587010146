import { FC, ReactNode, useState } from "react";

import { MessageIcon, HeartIcon, TicketIcon, CalendarIcon } from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";

import { FeatureRequestForm } from "../contact/feature-request-form";
import { FeedbackForm } from "../contact/feedback-form";
import { IntegrationRequestForm } from "../contact/integration-request-form";

interface Props {
  children: ReactNode;
}

export const FeedbackMenu: FC<Props> = ({ children }) => {
  const [form, setForm] = useState<"feedback" | "feature-request" | "integration-request" | undefined>();

  const closeForm = () => {
    setForm(undefined);
  };

  return (
    <>
      <Menu
        portal
        options={[
          {
            icon: MessageIcon,
            label: "Give us feedback",
            description: "Help improve our product",
            onClick: () => setForm("feedback"),
          },
          {
            icon: HeartIcon,
            label: "Submit a feature request",
            description: "Need a product feature we don’t support?",
            onClick: () => setForm("feature-request"),
          },
          {
            icon: TicketIcon,
            label: "Submit an integration request",
            description: "Need a different source or destination?",
            onClick: () => setForm("integration-request"),
          },
          {
            icon: CalendarIcon,
            label: "Book a meeting with Calendly",
            description: "Put 30 minutes on our calendar",
            onClick: () => {
              window.open(`https://calendly.com/hightouch-experts/30min`, "_blank");
            },
          },
        ]}
        placement="top-start"
        sx={{ width: "100%", alignItems: "stretch" }}
      >
        {children}
      </Menu>
      {form === "feature-request" && <FeatureRequestForm onClose={closeForm} />}
      {form === "integration-request" && <IntegrationRequestForm onClose={closeForm} />}
      {form === "feedback" && <FeedbackForm onClose={closeForm} />}
    </>
  );
};

import { FC, useEffect, useMemo, useRef, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  Skeleton,
  SkeletonBox,
  SearchInput,
  Box,
  Text as HightouchUiText,
  Tooltip,
  IconButton,
  EmptyState,
  Button,
  Link,
} from "@hightouchio/ui";
import { isEqual } from "lodash";
import { matchSorter } from "match-sorter";
import { Flex, Text, Container } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Column, Row } from "src/ui/box";
import { ReloadButton } from "src/ui/button";
import { Input } from "src/ui/input";

import { useFormkitContext } from "./formkit-context";
import { extractEligibleInlineMapperColumns, isValidJsonColumnMetadata } from "./mappings";
import { FormProps, MappingType, JsonColumnProps } from "./types";

const rowSx = {
  minHeight: "32px",
  alignItems: "center",
  fontSize: 0,
  gap: "8px",
  paddingTop: 1,
  px: "8px",
  borderBottom: "small",
};

type ArrayInputProps = {
  jsonColumnProperties: JsonColumnProps;
  onChangeJsonColumnProperties: React.Dispatch<React.SetStateAction<JsonColumnProps>>;
};

export const ArrayInput: FC<Readonly<FormProps & ArrayInputProps>> = ({
  value,
  onChange,
  jsonColumnProperties,
  onChangeJsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  useHightouchUi,
}) => {
  const [search, setSearch] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const selectedColumnRef = useRef<HTMLDivElement>(null);
  const { reloadRows, loadingRows, rows } = useDestinationForm();
  const { columns: allColumns } = useFormkitContext();

  const columns = useMemo(() => {
    const arrayColumns = Object.keys(jsonColumnProperties?.allColumnsProps || {});
    if (search) {
      const filteredColumns = matchSorter(arrayColumns, search);
      return filteredColumns;
    }

    return arrayColumns;
  }, [jsonColumnProperties.allColumnsProps, search]);

  useEffect(() => {
    const timer = setTimeout(() => searchRef.current?.focus(), 10);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    selectedColumnRef.current?.scrollIntoView();
  }, []);

  const handleReloadRows = async () => {
    onReloadEligibleInlineMapperColumns(value.from);
  };

  /**
   * These two useEffects work together to handle two functionalities:
   * 1. On load, we want to check if the json column metadata is valid. We will reloadRows to validate the metadata if it is not.
   * 2. handleReloadRows, which calls onReloadEligibleInlineMapperColumns will load the metadata to state only if it is valid. Therefore, it needs to have 'rows' as a dependency
   *    because if rows is updated, it can mean that either 1. we loaded the new valid metadata after we found that the previous one is invalid. 2. The user clicked on the 'Reload button' to re-validate the metadata and we need to extract that new data and set it to state.
   * This approach is needed to avoid multiple re-renders. The ideal approach would've been to make `reloadRows` a promise that resolves with the row data and we preform the extraction afterwards. However, that was not how reloadRows worked at this time, so this approach was chosen for the sake of time.
   * If this should be revisited, reloadRows should be changed into a promise so it can handle this scenario without multiple useEffects.
   */

  useEffect(() => {
    const jsonColumns = extractEligibleInlineMapperColumns(allColumns);
    if (!isValidJsonColumnMetadata(jsonColumns)) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    handleReloadRows();
  }, [rows]);

  return (
    <Column sx={{ p: 3, flex: 1 }}>
      {useHightouchUi ? (
        <Box paddingBottom={2}>
          <HightouchUiText>Select a column that contains an array of objects:</HightouchUiText>
        </Box>
      ) : (
        <Text sx={{ paddingBottom: "8px" }}>Select a column that contains an array of objects:</Text>
      )}
      <Flex sx={{ paddingBottom: "8px", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        {useHightouchUi ? (
          <>
            <SearchInput
              ref={searchRef}
              placeholder="Search..."
              value={search}
              width="100%"
              onChange={(event) => setSearch(event.target.value)}
            />
            <Box sx={{ ml: 2 }}>
              <Tooltip message="Refresh properties">
                <IconButton
                  aria-label="Refresh array properties"
                  icon={ArrowPathIcon}
                  isLoading={loadingRows}
                  size="md"
                  variant="secondary"
                  onClick={reloadRows}
                />
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Input
              ref={searchRef}
              placeholder="Search..."
              sx={{ width: "100%", flex: 1 }}
              value={search}
              onChange={setSearch}
            />
            <ReloadButton
              aria-label="Reload array properties"
              loading={loadingRows}
              sx={{ ml: 2 }}
              tooltip="Refresh properties"
              onClick={reloadRows}
            />
          </>
        )}
      </Flex>
      {loadingRows ? (
        <ShimmerLoadingState />
      ) : !columns?.length ? (
        <EmptyState
          title="No eligible columns found"
          message={
            <>
              Reference our <Link href="https://hightouch.com/docs/syncs/mapping-data#required-data-format">documentation</Link>{" "}
              to find out about the required data format to use the array in-line mapper.
            </>
          }
          actions={
            <Button variant="primary" onClick={reloadRows}>
              Reload Columns
            </Button>
          }
        />
      ) : useHightouchUi ? (
        (columns || []).map((column) => {
          const selected = isEqual(column, value.from);
          return (
            <Box
              key={column}
              ref={selected ? selectedColumnRef : undefined}
              _hover={{
                bg: "gray.100",
              }}
              alignItems="center"
              bg={selected ? "forest.100" : undefined}
              borderBottom="1px"
              borderColor="gray.300"
              cursor="pointer"
              display="flex"
              flex="0"
              gap={2}
              minHeight={8}
              pointerEvents={selected ? "none" : undefined}
              px={2}
              onClick={() => {
                onChangeJsonColumnProperties((currentValue) => ({
                  ...currentValue,
                  selectedColumnProps: currentValue.allColumnsProps?.[column],
                }));
                onChange({ type: MappingType.ARRAY, from: column });
              }}
            >
              <Box maxWidth="fit-content" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                <HightouchUiText color={selected ? "forest.600" : undefined}>{column}</HightouchUiText>
              </Box>
              <Box
                maxWidth="fit-content"
                overflow="hidden"
                sx={{ span: { color: "#A7AFBC" } }}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                <HightouchUiText>{Array.from(jsonColumnProperties.allColumnsProps?.[column] || []).join(", ")}</HightouchUiText>
              </Box>
            </Box>
          );
        })
      ) : (
        <Flex sx={{ flexDirection: "column", overflowY: "auto" }}>
          {(columns || []).map((column) => {
            const selected = isEqual(column, value.from);
            return (
              <Flex
                key={column}
                ref={selected ? selectedColumnRef : undefined}
                sx={{
                  ...rowSx,
                  color: selected ? "forest" : undefined,
                  cursor: "pointer",
                  bg: selected ? "avocado" : undefined,
                  pointerEvents: selected ? "none" : undefined,
                  ":hover": { bg: "base.0" },
                }}
                onClick={() => {
                  onChangeJsonColumnProperties((currentValue) => ({
                    ...currentValue,
                    selectedColumnProps: currentValue.allColumnsProps?.[column],
                  }));
                  onChange({ type: MappingType.ARRAY, from: column });
                }}
              >
                <Container sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "fit-content" }}>
                  {column}
                </Container>
                <Container sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "base.4" }}>
                  {Array.from(jsonColumnProperties.allColumnsProps?.[column] || []).join(", ")}
                </Container>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Column>
  );
};

const containers = [
  ["25%", "75%"],
  ["20%", "80%"],
  ["35%", "85%"],
  ["35%", "65%"],
  ["25%", "95%"],
  ["20%", "35%"],
  ["15%", "75%"],
];

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {containers.map((container, index) => (
      <Row key={index} sx={rowSx}>
        <Container sx={{ width: container[0], margin: 0 }}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Container>
        <Container sx={{ width: "100%", margin: 0 }}>
          <SkeletonBox borderRadius="sm" height="18px" width={container[1]} />
        </Container>
      </Row>
    ))}
  </Skeleton>
);

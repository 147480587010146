import { FC, useState } from "react";

import { Paragraph, UpsellCard } from "@hightouchio/ui";

import { UpsellModal } from "./modal";
import { FeatureProps } from "./types";

type FeaturePreviewProps = {
  enabled: boolean;
  featureName: string;
  featureDetails: FeatureProps;
  variant?: "limited" | "full" | "hidden";
  mb?: number;
};

export const FeaturePreview: FC<Readonly<FeaturePreviewProps>> = ({
  enabled,
  featureName,
  featureDetails,
  variant = "full",
  mb = 0,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  //TODO: Use docsLink
  const { pitch, description } = featureDetails;

  if (enabled) {
    return null;
  }

  let action = "";

  if (variant === "limited") {
    action = `Get full access to ${featureName}`;
  } else if (variant === "full") {
    action = `Upgrade to a plan with ${featureName}`;
  } else {
    action = `Unlock ${featureName}`;
  }

  return (
    <>
      <UpsellCard
        actionText={action}
        badgeText="Business tier"
        mb={mb}
        title={pitch}
        onAction={() => {
          setModalOpen(true);
        }}
      >
        <Paragraph>{description}</Paragraph>
      </UpsellCard>
      <UpsellModal
        featureDetails={featureDetails}
        featureName={featureName}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

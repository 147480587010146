interface BaseAlert {
  id?: number;
  name?: string;
  alert_interval?: number;
  fatal_error?: boolean;
  fatal_error_default?: boolean;
  row_error?: boolean;
  row_error_default?: boolean;
  alert_on_success?: boolean;
  on_success_default?: boolean;
  last_attempted?: Date;
}

export enum AlertTypes {
  Slack = "slack",
  PagerDuty = "pagerduty",
  Email = "email",
  SMS = "sms",
}

export enum SuccessAlertTypes {
  Slack = AlertTypes.Slack,
  Email = AlertTypes.Email,
}

export function isSuccessAlertType(type: unknown): type is SuccessAlertTypes {
  return Object.values(SuccessAlertTypes).includes(String(type));
}

export function isSuccessAlert(alert: Alert): alert is EmailAlert | SlackAlert {
  return isSuccessAlertType(alert.type);
}

export interface EmailAlert extends BaseAlert {
  type: AlertTypes.Email;
  config: EmailAlertConfig;
}

export interface EmailAlertConfig {
  recipients?: string[];
}

export interface PagerdutyAlert extends BaseAlert {
  type: AlertTypes.PagerDuty;
  config: PagerdutyAlertConfig;
  row_error_severity?: string;
  pagerduty_credential_id?: string;
}

export function isPagerdutyAlert(alert: Alert): alert is PagerdutyAlert {
  return alert.type === AlertTypes.PagerDuty;
}

export interface PagerdutyAlertConfig {
  fatalErrorMessage?: string;
  rowErrorMessage?: string;
  rowErrorSeverity?: string;
}

export interface SlackAlert extends BaseAlert {
  type: AlertTypes.Slack;
  config: SlackAlertConfig;
  slack_credential_id?: string;
}

export function isSlackAlert(alert: Alert): alert is SlackAlert {
  return alert.type === AlertTypes.Slack;
}

export interface SlackAlertConfig {
  fatalErrorBlocks?: string;
  rowErrorBlocks?: string;
  channelId?: string;
}

export interface SmsAlert extends BaseAlert {
  type: AlertTypes.SMS;
  config: SmsAlertConfig;
}

export interface SmsAlertConfig {
  recipients?: string[];
}

export interface Alert
  extends Omit<EmailAlert, "type">,
    Omit<PagerdutyAlert, "type">,
    Omit<SlackAlert, "type">,
    Omit<SmsAlert, "type"> {
  type?: AlertTypes;
  config: EmailAlertConfig & PagerdutyAlertConfig & SlackAlertConfig & SmsAlertConfig;
}

import { FC } from "react";

import { Button, LinkButton, Tooltip, ButtonProps, LinkButtonProps } from "@hightouchio/ui";
import { RequireExactlyOne } from "type-fest";

import useHasPermission, { ResourcePermission } from "src/hooks/use-has-permission";

interface PermissionedButtonProps extends ButtonProps {
  permissions: ResourcePermission[];
  tooltip?: string | boolean | undefined;
}

const permissionText = "You do not have permisson to perform this action";

export const PermissionedButton: FC<
  Readonly<PermissionedButtonProps & Partial<RequireExactlyOne<Pick<ButtonProps, "icon" | "imageUrl">, "icon" | "imageUrl">>>
> = ({ permissions, tooltip, ...props }) => {
  const { hasPermission } = useHasPermission(permissions);

  return (
    <Tooltip isDisabled={tooltip ? false : hasPermission} message={hasPermission ? String(tooltip) : permissionText}>
      <Button {...props} isDisabled={!hasPermission || props.isDisabled} />
    </Tooltip>
  );
};

interface PermissionedLinkButtonProps extends LinkButtonProps {
  permissions: ResourcePermission[];
  tooltip?: string | boolean | undefined;
}

export const PermissionedLinkButton: FC<
  Readonly<
    PermissionedLinkButtonProps & Partial<RequireExactlyOne<Pick<LinkButtonProps, "icon" | "imageUrl">, "icon" | "imageUrl">>
  >
> = ({ permissions, tooltip, ...props }) => {
  const { hasPermission } = useHasPermission(permissions);

  return (
    <Tooltip isDisabled={tooltip ? false : hasPermission} message={hasPermission ? String(tooltip) : permissionText}>
      <LinkButton {...props} isDisabled={!hasPermission || props.isDisabled} />
    </Tooltip>
  );
};

import { FC } from "react";

import { Badge } from "src/ui/badge";

export enum SyncSequenceRunStatus {
  Running = "running",
  Done = "done",
  Failed = "failed",
  Cancelled = "cancelled",
}

export const SequenceStatusBadge: FC<Readonly<{ status: string | undefined | null }>> = ({ status }) => {
  switch (status) {
    case SyncSequenceRunStatus.Done:
      return <Badge variant="green">Completed</Badge>;
    case SyncSequenceRunStatus.Failed:
      return <Badge variant="red">Failed</Badge>;
    case SyncSequenceRunStatus.Cancelled:
      return <Badge variant="base">Cancelled</Badge>;
    case SyncSequenceRunStatus.Running:
      return <Badge variant="indigo">Running</Badge>;
    default:
      return <Badge variant="base">Pending</Badge>;
  }
};

import { FC } from "react";

import { Column } from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { Section } from "../../../ui/section";
import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().when("object", {
    is: "customers",
    then: Yup.string().required().default("upsert"),
    otherwise: Yup.string().required().default("insert"),
  }),
  externalIdMapping: Yup.object().when("object", {
    is: "customers",
    then: COMMON_SCHEMAS.externalIdMapping,
    otherwise: Yup.object().notRequired(),
  }),
  object: Yup.string().required().default("customers"),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  deleteMode: Yup.string().notRequired(),
});

const MODES = {
  customers: [{ label: "Upsert", value: "upsert" }],
  transactions: [{ label: "Insert", value: "insert" }],
};

const OBJECTS = [
  { label: "Customers", value: "customers" },
  { label: "Transactions", value: "transactions" },
];

export const PartnerstackForm: FC = () => {
  const { config, setConfig } = useDestinationForm();
  const COLUMNS = {
    customers: [
      { label: "Email", value: "email", type: StandardFieldType.STRING, required: true },
      { label: "Partner Key", value: "partner_key", type: StandardFieldType.STRING, required: true },
      { label: "Customer Key", value: "key", type: StandardFieldType.STRING, required: true },
      { label: "Name", value: "name", type: StandardFieldType.STRING },
      { label: "Website", value: "website", type: StandardFieldType.STRING },
      { label: "Phone", value: "phone", type: StandardFieldType.STRING },
      { label: "Company", value: "company", type: StandardFieldType.STRING },

      { label: "IP Address", value: "ip_address", type: StandardFieldType.STRING },
    ],
    transactions: [
      { label: "Customer Key", value: "customer_key", type: StandardFieldType.STRING, required: true },
      { label: "Transaction Key", value: "key", type: StandardFieldType.STRING, required: true },
      { label: "Amount", value: "amount", type: StandardFieldType.NUMBER },
      { label: "Category Key", value: "category_key", type: StandardFieldType.STRING },
      { label: "Currency", value: "currency", type: StandardFieldType.STRING },
      { label: "Product Key", value: "product_key", type: StandardFieldType.STRING },
    ],
  };

  const EXTERNAL_FIELDS =
    config?.object === "customers"
      ? [
          { label: "Customer Email", value: "email" },
          { label: "Customer Key", value: "key" },
        ]
      : null;
  const fields = config?.object ? COLUMNS[config?.object] : [];
  const modes = config?.object ? MODES[config?.object] : [];

  return (
    <>
      <ObjectField options={OBJECTS} />

      {config?.object && (
        <>
          <ModeField
            options={modes}
            onChange={(mode) => {
              setConfig({
                object: config?.object,
                mode,
              });
            }}
          />
        </>
      )}

      {config?.object && config?.mode && (
        <>
          {config?.object === "customers" && (
            <Section>
              <Column gap={8}>
                <IdMappingField options={EXTERNAL_FIELDS} />
              </Column>
            </Section>
          )}

          <Section>
            <Column gap={8}>
              <MappingsField options={fields} />
            </Column>
          </Section>
          {config?.object === "customers" && (
            <Section>
              <MappingsField isCustom />
            </Section>
          )}

          {config?.object === "transactions" && <DeleteField modes={["delete"]} />}
        </>
      )}
    </>
  );
};

export default {
  validation,
  form: PartnerstackForm,
};

import { ReactElement, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { FormField, IconButton, Select, Row } from "@hightouchio/ui";
import * as Yup from "yup";

import { useMicrosoftexcelListSheetsQuery, useMicrosoftexcelListWorkbooksQuery } from "src/graphql";

import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "./custom-query";

function QueryForm({ source, query, onChange }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  if (query?.type && query?.type !== "excel") {
    return <>{`Query type ${query?.type} not valid for Excel source`}</>;
  }

  useEffect(() => {
    if (!query?.type) {
      onChange({ ...query, type: "excel" });
    }
  }, [query]);

  const {
    data: workbooksData,
    isLoading: workbooksLoading,
    refetch: refetchWorkbooks,
    error: workbooksError,
  } = useMicrosoftexcelListWorkbooksQuery({
    connectionId: String(source.id),
  });
  const workbooks = workbooksData?.microsoftexcelListWorkbooks || [];

  const {
    data: sheetsData,
    isLoading: sheetsLoading,
    refetch: refetchSheets,
    error: sheetsError,
  } = useMicrosoftexcelListSheetsQuery(
    {
      connectionId: String(source.id),
      workbookId: String(query?.workbookId),
    },
    { enabled: Boolean(query?.workbookId) },
  );
  const sheets = sheetsData?.microsoftexcelListSheets || [];

  return (
    <>
      <FormField error={workbooksError?.message} label="Workbook">
        <Row gap={2}>
          <Select
            isLoading={workbooksLoading}
            options={workbooks ?? []}
            placeholder="Select a workbook..."
            value={query?.workbookId}
            onChange={(workbookId) =>
              onChange({
                ...query,
                workbookId,
              })
            }
          />
          <IconButton
            aria-label="Refresh workbooks"
            icon={ArrowPathIcon}
            isDisabled={workbooksLoading}
            variant="secondary"
            onClick={() => refetchWorkbooks()}
          />
        </Row>
      </FormField>
      {query?.workbookId && (
        <FormField error={sheetsError?.message} label="Sheet">
          <Row gap={2}>
            <Select
              isLoading={sheetsLoading}
              options={sheets}
              placeholder="Select a sheet..."
              value={query?.sheetId}
              onChange={(sheetId) =>
                onChange({
                  ...query,
                  sheetId,
                })
              }
            />
            <IconButton
              aria-label="Refresh sheets"
              icon={ArrowPathIcon}
              isDisabled={sheetsLoading}
              variant="secondary"
              onClick={() => refetchSheets()}
            />
          </Row>
        </FormField>
      )}
    </>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  const {
    data: workbooksData,
    isLoading: workbooksLoading,
    error: workbooksError,
  } = useMicrosoftexcelListWorkbooksQuery({
    connectionId: String(props.source.id),
  });
  const workbooks = workbooksData?.microsoftexcelListWorkbooks || [];

  const {
    data: sheetsData,
    isLoading: sheetsLoading,
    error: sheetsError,
  } = useMicrosoftexcelListSheetsQuery(
    {
      connectionId: String(props.source.id),
      workbookId: String(props.query.workbookId),
    },
    { enabled: Boolean(props.query.workbookId) },
  );
  const sheets = sheetsData?.microsoftexcelListSheets || [];

  return (
    <>
      <FormField error={workbooksError?.message} label="Workbook">
        <Select
          isDisabled
          isLoading={workbooksLoading}
          options={workbooks}
          value={props.query.workbookId ?? ""}
          onChange={() => null}
        />
      </FormField>
      <FormField label="Sheet" error={sheetsError?.message}>
        <Select isDisabled isLoading={sheetsLoading} options={sheets} value={props.query.sheetId ?? ""} onChange={() => null} />
      </FormField>
    </>
  );
}

const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
  return Yup.object().shape({
    type: Yup.string().required(),
    workbookId: Yup.string().required(),
    sheetId: Yup.string().required(),
  });
});

export default { QueryForm, QueryView, querySchema };

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const PriorityListIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M5 16h15v2H5v-2Z" fillOpacity=".3" />
      <path clipRule="evenodd" d="M18 10H4v4h14v-4ZM7 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fillOpacity=".7" fillRule="evenodd" />
      <path d="M5 6h15v2H5V6Z" fillOpacity=".3" />
    </Icon>
  );
};

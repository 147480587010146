import { FC } from "react";

import { Text as HightouchUiText, Link, Code } from "@hightouchio/ui";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { Text } from "theme-ui";

interface Props extends ReactMarkdownOptions {
  useHightouchUi?: boolean;
  disableParagraphs?: boolean;
}

export const Markdown: FC<Readonly<Props>> = ({ useHightouchUi = false, disableParagraphs = false, children, ...props }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => {
          return disableParagraphs ? <>{children}</> : <p>{children}</p>;
        },
        strong: ({ children }) => {
          return useHightouchUi ? (
            <HightouchUiText fontWeight="semibold">{children}</HightouchUiText>
          ) : (
            <Text
              as="strong"
              sx={{
                display: "inline",
                fontWeight: "bold",
              }}
            >
              {children}
            </Text>
          );
        },
        a: ({ href, children }) => <Link href={href ?? "#"}>{children}</Link>,
        code: ({ children }) => {
          return useHightouchUi ? (
            <Code>{children}</Code>
          ) : (
            <Text
              as="code"
              sx={{
                fontFamily: "code",
                display: "inline",
                backgroundColor: "base.1",
                px: 1,
              }}
            >
              {children}
            </Text>
          );
        },
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
};

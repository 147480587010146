import { ArrowsRightLeftIcon, CalendarDaysIcon, Cog6ToothIcon, PlayPauseIcon } from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";
import { capitalize } from "lodash";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import { normalizeName, ResourceActivityMapper } from "src/components/resource-activity/timeline";
import { scheduleTypeToLabel } from "src/components/schedule/types";
import { ObjectPropertyDiff } from "src/hooks/use-resource-activity";

import { isMapping, isMappingArray, Mapping } from "./sync-activity-utils";

function displayMappedValueChange(
  value: Mapping,
  operation: ObjectPropertyDiff["operation"],
  mappingName: string,
): JSX.Element | null {
  // get the 'from' value from the mapping
  let fromValue: string | undefined;

  switch (value.type) {
    case "static":
      fromValue = String(value.value);
      break;
    case "reference":
      fromValue = value.lookup.from;
      break;
    case "template":
      fromValue = value.template;
      break;
    case "variable":
      fromValue = value.variable;
      break;
    default:
      if ("lookup" in value) {
        fromValue = value.lookup.from;
        break;
      }
      if ("from" in value) {
        if (typeof value.from === "string") {
          fromValue = value.from;
          break;
        }
        if ("type" in value.from && (value as any).from?.type === "related") {
          fromValue = `Audience related model column ${(value as any).from?.column?.name}`;
          break;
        }
      }
      fromValue = undefined;
  }

  return (
    <Text size="sm">
      {capitalize(operation)} {value.type ?? ""} {normalizeName(mappingName)} from <Code>{fromValue}</Code> to{" "}
      <Code>{String(value.to)}</Code>
    </Text>
  );
}

export const syncActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "schedule_paused",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `${activity.metadata.user_name} ${parsedDiff.value ? "paused" : "resumed"} sync scheduling`,
        icon: <PlayPauseIcon />,
      };
    },
  },
  // Handles top-level config keys and config mappings
  {
    accessor: "config",
    parser: (activity, { parsedDiff, newValue, oldValue }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const nestedKeys = Object.keys(parsedDiff.nested);
      let mappingKeyCount = 0;

      const changes: (JSX.Element | null)[] = [];
      for (const key of nestedKeys) {
        const parsed = parsedDiff.nested[key];
        const newKeyValue = newValue[key];
        const oldKeyValue = oldValue[key];
        if (!parsed) {
          continue;
        }

        // We need to check both new and old config key values to see if they are mappings
        // as mappings might have been removed and [] should return false for isMappingArray
        if (isMappingArray(oldKeyValue) || isMappingArray(newKeyValue)) {
          mappingKeyCount++;
          if (parsed.type !== "array") {
            continue;
          }
          changes.push(...parsed.array.map((item) => displayMappedValueChange(item.value, item.operation, key)));
          continue;
        }

        if (isMapping(oldKeyValue) || isMapping(newKeyValue)) {
          mappingKeyCount++;
          if (parsed.type === "value") {
            changes.push(
              displayMappedValueChange(parsed.operation === "added" ? newKeyValue : oldKeyValue, parsed.operation, key),
            );
            continue;
          }
          changes.push(displayMappedValueChange(newKeyValue, "updated", key));
          continue;
        }

        if (parsed.type === "value") {
          // capitalize operation name
          switch (parsed.operation) {
            case "added":
              changes.push(
                <Text size="sm">
                  Set <Code>{normalizeName(key)}</Code> to <Code>{String(parsed.value)}</Code>
                </Text>,
              );
              break;
            case "removed":
              changes.push(
                <Text size="sm">
                  Removed <Code>{normalizeName(key)}</Code>
                </Text>,
              );
              break;
            case "updated":
              changes.push(
                <Text size="sm">
                  {capitalize(parsed.operation)} <Code>{normalizeName(key)}</Code> to <Code>{String(parsed.value)}</Code>
                </Text>,
              );
              break;
          }
        }
      }

      const isMappingChange = mappingKeyCount === nestedKeys.length;
      return {
        message: `${activity.metadata.user_name} updated ${isMappingChange ? "the sync mappings" : "the configuration"}`,
        icon: isMappingChange ? <ArrowsRightLeftIcon /> : <Cog6ToothIcon />,
        changes: changes.filter(Boolean),
      };
    },
  },
  // Handle setting sync schedule to manual which makes the whole schedule object null
  {
    accessor: "schedule",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type === "value" && parsedDiff.value === null) {
        return {
          message: <Text>{activity.metadata.user_name} disabled automatic scheduling</Text>,
          icon: <CalendarDaysIcon />,
        };
      }
      return null;
    },
  },
  {
    accessor: "schedule.type",
    overrideDiffAccessor: { or: [{ var: "schedule.type" }, { var: "schedule.1.type" }] },
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the schedule type to <Code>{scheduleTypeToLabel(parsedDiff.value)}</Code>
          </Text>
        ),
        icon: <CalendarDaysIcon />,
      };
    },
  },
  {
    accessor: "schedule.schedule.expression",
    overrideDiffAccessor: { or: [{ var: "schedule.schedule.expression" }, { var: "schedule.1.schedule.expression" }] },
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value" || parsedDiff.operation === "removed") {
        return null;
      }
      return {
        message: <Text>{activity.metadata.user_name} updated the cron expression</Text>,
        icon: <CalendarDaysIcon />,
        changes: [
          <Text key={1} size="sm">
            Set cron expression to <Code>{parsedDiff.value}</Code>
          </Text>,
        ],
      };
    },
  },
  {
    accessor: "schedule.schedule.interval",
    overrideDiffAccessor: { or: [{ var: "schedule.schedule.interval" }, { var: "schedule.1.schedule.interval" }] },
    parser: (activity, { parsedDiff, newValue }) => {
      if (parsedDiff.type === "array" || (parsedDiff.type == "value" && parsedDiff.operation === "removed")) {
        return null;
      }
      let change;
      if (parsedDiff.type === "value") {
        change = (
          <Text key={1} size="sm">
            Set interval to every <Code>{parsedDiff.value?.quantity}</Code> <Code>{parsedDiff.value?.unit}(s)</Code>
          </Text>
        );
      } else {
        change = (
          <Text key={1} size="sm">
            Updated interval to every{" "}
            <Code>
              {newValue.quantity} {newValue.unit}(s)
            </Code>
          </Text>
        );
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the sync interval</Text>,
        icon: <CalendarDaysIcon />,
        changes: [change],
      };
    },
  },
  {
    accessor: "schedule.schedule.expressions",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: <Text>{activity.metadata.user_name} updated the custom recurrence</Text>,
        icon: <CalendarDaysIcon />,
        // Changes are a bit harder to grok here, we can come back to this if it's really bad
        changes: [
          <Text key={1} size="sm">
            Updated recurrence days and or times
          </Text>,
        ],
      };
    },
  },
];

import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useSendgridColumnsQuery } from "src/graphql";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Upsert", value: "upsert" }];

export const SendgridForm: FC = () => {
  const { destination } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useSendgridColumnsQuery({
    destinationId: String(destination?.id),
  });

  const columns = columnsData?.sendGridContactSchema;

  const ID_COLUMNS = [{ label: "email", value: "email", required: true }];

  // Reserved is for default fields (aka not customfields)
  const reservedColumns = columns?.fields?.filter((field) => !field.isCustom);
  const customColumns = columns?.fields?.filter((field) => field.isCustom);

  const reservedColumnsOptions =
    reservedColumns
      ?.filter((field) => field.name !== "email")
      ?.map((field) => ({
        label: field.name,
        value: field.name,
      })) || [];

  // SendGrid requires the custom_field "id" to update the field
  const customColumnsOptions =
    customColumns?.map((field) => ({
      label: field.name,
      value: field.id,
    })) || [];

  return (
    <>
      <ModeField options={MODES} value="upsert" />

      <Section>
        <IdMappingField options={ID_COLUMNS} />
      </Section>

      <Section>
        <MappingsField
          error={columnsError?.message}
          loading={loadingColumns}
          options={reservedColumnsOptions}
          reload={getColumns}
        />
      </Section>

      <Section>
        <MappingsField
          isCustom
          error={columnsError?.message}
          loading={loadingColumns}
          options={customColumnsOptions}
          reload={getColumns}
        />
      </Section>
    </>
  );
};

export default { form: SendgridForm, validation };

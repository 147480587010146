import { FC } from "react";

import { Box, Link } from "@hightouchio/ui";
import { Text } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";

type Props = {
  category: string;
};

export const RequestDestination: FC<Readonly<Props>> = ({ category }) => {
  return (
    <Box>
      <Text sx={{ fontSize: 1, color: "base.5", fontWeight: "semi", pt: 8 }}>
        Don’t see what you’re looking for? Let us know{" "}
        <Box
          display="inline-block"
          onClick={() => {
            newIntercomMessage(
              `Hi, I would like to request a new destination. I want to sync data to ____ in order to help the _____ team accomplish _____.`,
            );
            analytics.track("Destination Catalog Suggest Destination Clicked", {
              current_category: category,
            });
          }}
        >
          <Link href="">here.</Link>
        </Box>
      </Text>
    </Box>
  );
};

import { FC, useEffect, useState } from "react";

import { Column, FormField, Heading, SectionHeading, Select, Textarea, TextInput, useToast } from "@hightouchio/ui";
import { capitalize } from "lodash";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { RefreshIntervals } from "src/components/audiences/column-settings";
import { getSchemaModelTypeFromPath, getSchemaModelTypeNames } from "src/components/audiences/utils";
import { Explore } from "src/components/explore";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import { useCreateEventMutation, useCreateObjectMutation, useUpdateModelColumnsMutation } from "src/graphql";
import { QueryableSource, QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { isTimestampColumn, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { ResourceType, useResourceSlug } from "src/utils/slug";

export const CreateAudienceObject: FC = () => {
  const { object: type } = useParams<{ object: "related-models" | "parent-models" | "events" }>();
  const navigate = useNavigate();

  if (type) {
    const schemaType = getSchemaModelTypeFromPath(type);
    return (
      <CreateSchemaModelWizard
        type={getSchemaModelTypeFromPath(type)}
        onCreated={() => {
          let params = "";
          if (schemaType === SchemaModelType.Event) {
            params = "?eventCreated=true";
          }
          navigate(`/schema/${type}${params}`);
        }}
      />
    );
  }
  return null;
};

export const CreateSchemaModelWizard: FC<
  Readonly<{ source?: QueryableSource; type: SchemaModelType; onCreated?: (id: string) => void }>
> = ({ source: initialSource, type, onCreated }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { typeName } = getSchemaModelTypeNames(type);
  const { getSlug } = useResourceSlug(ResourceType.Model);
  const [source, setSource] = useState<QueryableSource | undefined>(initialSource);
  const [step, setStep] = useState(0);
  const {
    queryState,
    resetQueryState,
    setSQL,
    setTable,
    setDBTModel,
    setCustomQuery,
    setLookerLook,
    setSigma,
    isQueryDefined,
  } = useQueryState();
  const { modelState, setName, setPrimaryKey, setTimestampColumn, setDescription } = useModelState();
  const [queryType, setQueryType] = useState<QueryType>(QueryType.Table);
  const [primaryLabel, setPrimaryLabel] = useState("");
  const [secondaryLabel, setSecondaryLabel] = useState("");
  const [creating, setCreating] = useState(false);
  const [hasQueryColumns, setHasQueryColumns] = useState(false);

  const isParentModel = type === SchemaModelType.Parent;
  const isEvent = type === SchemaModelType.Event;

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: createEvent } = useCreateEventMutation();
  const { mutateAsync: updateModelColumns } = useUpdateModelColumnsMutation();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(queryType, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const create = async () => {
    setCreating(true);

    const slug = await getSlug(modelState?.name);

    const data = await createObject({
      input: {
        slug,
        is_schema: true,
        query_type: queryType,
        name: modelState?.name,
        description: modelState?.description,
        primary_key: modelState?.primaryKey || undefined,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        columns: { data: columns },
        destination_instances: { data: [] },
        git_sync_metadata:
          queryType === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        visual_query_primary_label: isParentModel ? primaryLabel : undefined,
        visual_query_secondary_label: isParentModel ? secondaryLabel : undefined,
      },
    });

    const id = data.insert_segments_one?.id;

    if (isEvent && id) {
      await createEvent({
        input: {
          model_id: id,
          timestamp_column: modelState?.timestampColumn,
        },
      });
    }

    if (isParentModel) {
      // Enable suggestions with weekly refresh interval by default upon model creation.
      await updateModelColumns({
        id,
        input: {
          top_k_enabled: true,
          top_k_sync_interval: RefreshIntervals.Weekly,
        },
      });
    }

    setCreating(false);

    toast({
      id: `create-${type}`,
      title: `${capitalize(modelState?.name ?? "object")} was created`,
      variant: "success",
    });

    if (onCreated) {
      onCreated(id);
    }
  };

  const steps: Step[] = [
    {
      title: "Define query",
      disabled:
        !isQueryDefined(queryType) ||
        Boolean(queryError) ||
        (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      header: queryType ? null : <Heading>Select a modeling method</Heading>,
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      continue: queryType ? "" : "Click on a modeling method to continue",
      render: () => (
        <>
          {source && (
            <Explore
              cancelQuery={cancelQuery}
              columns={columns}
              isQueryDefined={isQueryDefined}
              isResultTruncated={Boolean(isResultTruncated)}
              numRowsWithoutLimit={numRowsWithoutLimit}
              rows={rows}
              runQuery={runQuery}
              source={source}
              type={queryType}
              {...queryState}
              error={queryError}
              errorAtLine={queryErrorAtLine}
              loading={queryLoading}
              rowsPerPage={15}
              onDBTModelChange={setDBTModel}
              onSQLChange={setSQL}
              onTableChange={setTable}
              onTypeChange={setQueryType}
              onLookerLookChange={setLookerLook}
              onSigmaChange={setSigma}
              onCustomQueryChange={setCustomQuery}
            />
          )}
        </>
      ),
    },
    {
      title: `Configure`,
      disabled:
        !modelState?.name || (isParentModel && (!primaryLabel || !secondaryLabel)) || (isEvent && !modelState?.timestampColumn),
      submitting: creating,
      header: <Heading>Configure {typeName}</Heading>,
      render: () => {
        const filteredColumns = isEvent ? columns?.filter(isTimestampColumn) : columns;
        const columnOptions: { value: string; label: string }[] = filteredColumns.map(({ name }) => ({
          value: name,
          label: name,
        }));

        return (
          <Column gap={8}>
            <Column gap={6}>
              <FormField label="Name" tip="Something simple like “users”">
                <TextInput
                  placeholder="Enter a name..."
                  value={modelState?.name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormField>
              <FormField label="Description" isOptional>
                <Textarea
                  placeholder="Enter a description..."
                  value={modelState?.description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormField>
              {isParentModel && (
                <>
                  <FormField label="Primary key" tip="Key that is unique and consistent across queries. E.g. “id” or “email”">
                    <Select
                      options={columnOptions}
                      placeholder="Select an option..."
                      value={modelState?.primaryKey}
                      onChange={(value) => value !== undefined && setPrimaryKey(value)}
                    />
                  </FormField>
                </>
              )}
              {isEvent && (
                <FormField label="Timestamp">
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={modelState?.timestampColumn}
                    onChange={(value) => value !== undefined && setTimestampColumn(value)}
                  />
                </FormField>
              )}
            </Column>

            {isParentModel && (
              <Column gap={6}>
                <SectionHeading>Content labels</SectionHeading>
                <FormField label="Primary label" tip="Used when previewing results from an audience. E.g. “full_name”">
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={primaryLabel}
                    onChange={(value) => value !== undefined && setPrimaryLabel(value)}
                  />
                </FormField>
                <FormField label="Secondary label" tip="Used when previewing results from an audience. E.g. “email”">
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={secondaryLabel}
                    onChange={(value) => value !== undefined && setSecondaryLabel(value)}
                  />
                </FormField>
              </Column>
            )}
          </Column>
        );
      },
    },
  ];

  if (!initialSource) {
    steps.unshift({
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <SourceSelect
          queryType={QueryType.Visual}
          onSelect={(source) => {
            setSource(source);
            setStep((step) => step + 1);
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (source) {
      resetQueryState();
    }
  }, [source]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  return (
    <>
      <Helmet>
        <title>New {typeName}</title>
      </Helmet>

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title={`New ${typeName}`}
        onCancel={() => {
          navigate(-1);
        }}
        onSubmit={create}
      />
    </>
  );
};

import { useEffect, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { GitCredentials, useGithubBranchesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

import { isGitHub } from "./utils";

interface GitBranchSelectorProps {
  credentials: GitCredentials | undefined;
  description?: string;
  label?: string;
  name?: string;
}

export const GitBranchSelector = ({ label, description, credentials, name = "branch" }: GitBranchSelectorProps) => {
  const { register, control, watch } = useFormContext();

  const repository: string = watch("repository");
  const [error, setError] = useState<string>("");

  const getGithubUrlName = (url?: string) => {
    if (!url) {
      return "";
    }
    try {
      return new URL(repository).pathname.slice(1);
    } catch (error) {
      return url;
    }
  };
  const { data: githubBranches, isLoading: githubBranchLoading } = useGithubBranchesQuery(
    {
      credentialId: String(credentials?.id),
      // parse the github URL to get the owner and repo name
      repo: getGithubUrlName(repository),
    },
    {
      select: (data) => data.getGithubBranches,
      // We only want to try and fetch branches if the customer has selected a repo rather than manually inputting one
      enabled: isGitHub(credentials) && Boolean(repository),
      onError: (error) => setError(error?.message),
    },
  );

  const githubBranchOptions = githubBranches?.map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));

  useEffect(() => {
    setError("");
  }, [credentials?.type, repository]);

  return (
    <Field description={description} error={error} label={label || "Branch"}>
      {isGitHub(credentials) ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              disabled={githubBranchLoading || !repository}
              isLoading={githubBranchLoading}
              options={githubBranchOptions}
              placeholder="Select a branch..."
              value={field?.value}
              onChange={(selected) => {
                field.onChange(selected?.value);
              }}
            />
          )}
        />
      ) : (
        <Input {...register(name)} />
      )}
    </Field>
  );
};

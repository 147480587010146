import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const XCircleIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z" />
    </Icon>
  );
};

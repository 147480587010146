import { FC } from "react";

import { Box, Row, Column, Link, Spinner } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { ListSourceTestStepsQuery, SourceDefinition, TestSourceQuery, useCloudCredentialsV2Query } from "src/graphql";
import { Code } from "src/ui/code";
import { CheckCircleIcon, WarningCircleIcon, XCircleIcon } from "src/ui/icons";
import { Markdown } from "src/ui/markdown";
import { Message } from "src/ui/message";

import { Context } from "../setup/constants";
import { DEFAULT_SUGGESTIONS, DEFAULT_TIME_ELAPSED_WARNING, SUGGESTIONS, TIME_ELAPSED_WARNING } from "./constants";

const TestingState = () => (
  <>
    <Spinner size="sm" />
    <Text sx={{ ml: 2, fontSize: 0, fontWeight: "bold", color: "base.4", textTransform: "uppercase" }}>Testing...</Text>
  </>
);

const SuccessState = () => (
  <>
    <CheckCircleIcon color="green" size={18} />
    <Text sx={{ ml: 2, fontSize: 0, fontWeight: "bold", color: "green", textTransform: "uppercase" }}>Success</Text>
  </>
);

const ErrorState = () => (
  <>
    <WarningCircleIcon color="red" size={18} />
    <Text sx={{ ml: 2, fontSize: 0, fontWeight: "bold", color: "red", textTransform: "uppercase" }}>Failed</Text>
  </>
);

const SkippedState = () => (
  <>
    <XCircleIcon color="base.4" size={18} />
    <Text sx={{ ml: 2, fontSize: 0, fontWeight: "bold", color: "base.4", textTransform: "uppercase" }}>Skipped</Text>
  </>
);

export type TestingProps = {
  plannerDatabase: string | undefined;
  sourceDefinition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  credentialId: string | undefined;
  steps: ListSourceTestStepsQuery["listSourceTestSteps"] | undefined;
  results: Partial<TestSourceQuery["testSource"]> | undefined;
  timeElapsed: number | undefined;
  isSetup: boolean;
  onContinue?: () => void;
};

export const Testing: FC<Readonly<TestingProps>> = ({
  plannerDatabase,
  sourceDefinition,
  steps,
  results,
  config,
  credentialId,
  timeElapsed,
  isSetup,
  onContinue,
}) => {
  if (!sourceDefinition) {
    return null;
  }

  const { data } = useCloudCredentialsV2Query({ id: String(credentialId) }, { enabled: Boolean(credentialId) });

  const ctx: Context = {
    plannerDatabase,
    definitionName: sourceDefinition.name,
    configuration: config,
    credential: data?.getCloudCredentials?.[0],
  };

  const timeElapsedWarning = TIME_ELAPSED_WARNING[sourceDefinition.type] || DEFAULT_TIME_ELAPSED_WARNING;

  return (
    <Column gap={4}>
      {Boolean(timeElapsed && timeElapsed > 60) && (
        <Message hideIcon sx={{ mb: 4, maxWidth: "100%" }} variant="warning">
          {timeElapsedWarning(ctx)}
          {onContinue && (
            <Text sx={{ mt: 2 }}>
              If you're sure that your configuration settings are correct, you can{" "}
              <Box
                display="inline-block"
                onClick={() => {
                  onContinue();
                }}
              >
                <Link href="">continue to the next step.</Link>
              </Box>
            </Text>
          )}
        </Message>
      )}
      {steps?.map((s, idx) => {
        const result = results?.stepResults?.find((r) => s.id === r.id);
        const testRunning = result || results?.success !== undefined;
        const suggestion = SUGGESTIONS[sourceDefinition.type]?.[s.id] || DEFAULT_SUGGESTIONS[s.id];
        return (
          <Row
            key={idx}
            sx={{
              alignItems: "start",
              ":not(:last-child)": { pb: 4, borderBottom: "1px solid", borderColor: "base.border" },
            }}
          >
            <Row sx={{ alignItems: "center", pr: 2, width: "150px" }}>
              {testRunning ? (
                result !== undefined ? (
                  result.success ? (
                    <SuccessState />
                  ) : (
                    <ErrorState />
                  )
                ) : (
                  <SkippedState />
                )
              ) : (
                <TestingState />
              )}
            </Row>
            <Column flex={1}>
              <Text>
                <Markdown>{s.name}</Markdown>
              </Text>
              {result?.success === false && (
                <>
                  <Message hideIcon sx={{ mt: 4, width: "100%" }} variant="error">
                    <Column>
                      <Text sx={{ fontWeight: "bold", color: "red" }}>{result?.error?.message}</Text>
                      {result?.error?.cause && <Text sx={{ fontFamily: "monospace", mt: 2 }}>{result?.error?.cause}</Text>}
                    </Column>
                  </Message>
                  {suggestion && (
                    <Column borderTop="1px solid" borderColor="base.border" pt={4} mt={4} fontSize="sm">
                      <Text sx={{ textTransform: "uppercase", color: "base.5", mb: 2, fontSize: 0, fontWeight: "bold" }}>
                        Recommended action
                      </Text>
                      <Text>
                        <Markdown>{suggestion.reason(ctx)}</Markdown>
                      </Text>
                      {Boolean(suggestion.code.length) &&
                        suggestion.code.map((block, idx) => (
                          <Code key={idx} title={suggestion.code.length > 1 ? block.title(ctx) : undefined}>
                            <Text sx={{ whiteSpace: "pre-wrap" }}>{block.code(ctx)}</Text>
                          </Code>
                        ))}
                    </Column>
                  )}
                </>
              )}
            </Column>
          </Row>
        );
      })}
      {results?.success && (
        <Message hideIcon sx={{ mt: 4, maxWidth: "100%" }} variant="green">
          <Text sx={{ fontWeight: "bold", color: "green" }}>All tests successfully passed!</Text>
          {isSetup && <Text sx={{ mt: 1 }}>Continue to complete setting up your {sourceDefinition.name} source.</Text>}
        </Message>
      )}
    </Column>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const FivetranIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon color="#007fff" viewBox="0 0 138.01 192" {...props}>
      <g data-name="Layer 2" id="Layer_2">
        <g data-name="Layer 1" id="Layer_1-2">
          <path
            className="cls-1"
            d="M95.43,96h19.28a2.8,2.8,0,0,0,2.85-2.73,3,3,0,0,0-.22-1.14L84.17,1.84A2.8,2.8,0,0,0,81.54,0H62.19a2.79,2.79,0,0,0-2.8,2.78,2.69,2.69,0,0,0,.18,1L92.74,94.17A2.78,2.78,0,0,0,95.43,96Z"
          />
          <path
            className="cls-1"
            d="M92,192h19.28a2.8,2.8,0,0,0,2.63-3.76L45.45,1.84A2.8,2.8,0,0,0,42.82,0H23.56a2.79,2.79,0,0,0-2.8,2.78,2.69,2.69,0,0,0,.18,1L89.33,190.18A2.78,2.78,0,0,0,92,192Z"
          />
          <path
            className="cls-1"
            d="M54,192H73.27A2.79,2.79,0,0,0,76,188.24L42.73,97.83A2.79,2.79,0,0,0,40.1,96H20.73A2.79,2.79,0,0,0,18,98.84a2.61,2.61,0,0,0,.19.92l33.17,90.42A2.78,2.78,0,0,0,54,192Z"
          />
          <path
            className="cls-1"
            d="M97.76,3.78l15.55,42.29a2.78,2.78,0,0,0,2.62,1.84h19.28a2.78,2.78,0,0,0,2.8-2.77,2.7,2.7,0,0,0-.17-1L122.29,1.84A2.8,2.8,0,0,0,119.66,0H100.38a2.79,2.79,0,0,0-2.8,2.77A2.73,2.73,0,0,0,97.76,3.78Z"
          />
          <path
            className="cls-1"
            d="M18.43,192H37.71a2.78,2.78,0,0,0,2.62-3.76L24.69,145.83A2.8,2.8,0,0,0,22.07,144H2.81A2.79,2.79,0,0,0,0,146.76a2.91,2.91,0,0,0,.18,1l15.55,42.41A2.8,2.8,0,0,0,18.43,192Z"
          />
        </g>
      </g>
    </Icon>
  );
};

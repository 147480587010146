import { Box, FormField, TextInput } from "@hightouchio/ui";

export const azureConfigValidator = (config): boolean => {
  return Boolean(config.tenant_id && config.client_id && config.client_secret);
};

/**
 * Azure Form
 * @param config
 * @param setConfig
 * @constructor
 */
export const AzureForm = ({ config, setConfig }) => {
  return (
    <>
      <FormField description="Azure -> Active Directory -> App Registrations" label="Configure Azure credentials" mt={6}>
        <FormField label="Tenant ID">
          <Box display="flex" gap={3}>
            <TextInput
              value={config.tenant_id}
              onChange={(event) => setConfig({ ...config, tenant_id: event.target.value?.trim() })}
            />
          </Box>
        </FormField>

        <FormField label="Client ID" mt={6}>
          <Box display="flex" gap={3}>
            <TextInput
              value={config.client_id}
              onChange={(event) => setConfig({ ...config, client_id: event.target.value?.trim() })}
            />
          </Box>
        </FormField>

        <FormField label="Client Secret" mt={6}>
          <Box display="flex" gap={3}>
            <TextInput
              type="password"
              value={config.client_secret}
              onChange={(event) => setConfig({ ...config, client_secret: event.target.value })}
            />
          </Box>
        </FormField>
      </FormField>
    </>
  );
};

import { useEffect, useState } from "react";

import { ResourceLabelsQuery, useResourceLabelsQuery } from "../../graphql";

type Label = { key: string; values: string[] };
interface UseLabels {
  labelsLoading: boolean;
  labels: Label[];
}
export const useLabels = (): UseLabels => {
  const { data, isLoading } = useResourceLabelsQuery();
  const [reducedLabels, setReducedLabels] = useState<Label[]>([]);

  useEffect(() => {
    setReducedLabels(labelsFromResourceTags(data?.resource_tag_values ?? []));
  }, [data?.resource_tag_values]);
  return { labels: reducedLabels, labelsLoading: isLoading };
};

export function labelsFromResourceTags(resourceTags: ResourceLabelsQuery["resource_tag_values"]): Label[] {
  /**
   * Right now resource tags are 1:1. This mapping may change at a future point
   * Remove duplicate keys to have a { tag: [values] }
   */
  const combinedLabels: Record<string, Set<string>> = {};

  for (const resourceTag of resourceTags || []) {
    if (!resourceTag.key) {
      continue;
    }
    if (!combinedLabels[resourceTag.key]) {
      combinedLabels[resourceTag.key] = new Set(resourceTag.values);
      continue;
    }
    for (const value of resourceTag.values) {
      combinedLabels[resourceTag.key]?.add(value);
    }
  }
  // now convert the set to an array
  const labels = Object.entries(combinedLabels).map(([key, values]) => ({
    key,
    values: Array.from(values),
  }));
  return labels;
}

import { useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "lodash";

export const useContentVisibility = <TargetElement extends HTMLElement>() => {
  const ref = useRef<TargetElement>(null);
  const [isVisible, setIsVisible] = useState(true);

  const setVisibility = useCallback(() => {
    if (ref.current) {
      setIsVisible(ref.current.scrollWidth === ref.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    setVisibility();
  }, []);

  useEffect(() => {
    const debouncedCallback = debounce(setVisibility, 300);

    window.addEventListener("resize", debouncedCallback);

    return () => {
      debouncedCallback.cancel();
      window.removeEventListener("resize", debouncedCallback);
    };
  }, [setVisibility]);

  return { isVisible, ref };
};

import { FC } from "react";

import { AddBoxIcon, Row, Text } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";

import { initialPropertyCondition, PropertyCondition, isRelatedColumn, isTraitColumn } from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterProps } from "./condition";
import { DetailButton } from "./condition-buttons";
import { PropertyFilter } from "./property-filter";

export type TraitFilterProps = FilterProps<PropertyCondition>;

export const TraitFilter: FC<Readonly<TraitFilterProps>> = (props) => {
  const { relationships: relationshipsOverride, condition, onChange } = props;

  const { relationships: allRelationships } = useQueryBuilderContext();

  const relationships = relationshipsOverride ?? allRelationships;

  const columns = relationships?.find(({ id }) => isRelatedColumn(condition.property) && id === condition.property.path[0])
    ?.to_model.filterable_audience_columns;

  const addCondition = () => {
    const update = immutableUpdate(condition, {
      property: { column: { conditions: { $push: [initialPropertyCondition] } } },
    });
    onChange(update);
  };

  const updateCondition = (index: number) => (updates: any) => {
    onChange(
      immutableUpdate(condition, {
        property: { column: { conditions: { [index]: { $merge: updates } } } },
      }),
    );
  };

  const removeCondition = (index: number) => () => {
    onChange(
      immutableUpdate(condition, {
        property: { column: { conditions: { $splice: [[index, 1]] } } },
      }),
    );
  };

  if (isRelatedColumn(condition.property) && isTraitColumn(condition.property.column)) {
    return (
      <>
        {condition.property.column.conditions.map((condition, index) => (
          <Row key={index} align="flex-start" gap={2} pl={12} width="100%">
            <Text mt={1.5} ml={index === 0 ? 0 : 4} color="text.secondary">
              {index === 0 ? "Where" : "And"}
            </Text>
            <PropertyFilter
              {...props}
              showSecondaryFilter
              columns={columns}
              condition={condition}
              traits={[]}
              onChange={updateCondition(index)}
              onRemove={removeCondition(index)}
            />
          </Row>
        ))}
        <Row pl={12}>
          <DetailButton icon={AddBoxIcon} size="sm" onClick={() => addCondition()}>
            Where...
          </DetailButton>
        </Row>
      </>
    );
  }

  return null;
};

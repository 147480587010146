import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SigmaIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon color="#171717" viewBox="0 0 33 30" {...props}>
      <path d="M14.4523 15.3524L0 0.833252L21.4587 7.77987L14.4523 15.3524Z" fill="#171717" />
      <path d="M15.2997 16.1905L26.9131 30.6246L22.2956 8.60604L15.2997 16.1905Z" fill="#171717" />
      <path d="M25.4971 30.751L9.08954 23.5399L19.6969 23.5435L25.4971 30.751Z" fill="#171717" />
      <path d="M27.84 0.988769L22.4184 6.85587L17.1924 5.16107L27.84 0.988769Z" fill="#171717" />
      <path d="M25.9213 4.64943L28.9927 1.49197L34.2844 4.80138L25.9213 4.64943Z" fill="#171717" />
      <path
        d="M47.7862 9.6079C48.8888 9.6079 49.7826 8.66864 49.7826 7.51C49.7826 6.35137 48.8888 5.41211 47.7862 5.41211C46.6837 5.41211 45.7899 6.35137 45.7899 7.51C45.7899 8.66864 46.6837 9.6079 47.7862 9.6079Z"
        fill="#171717"
      />
      <path
        d="M89.5356 16.7473V24.3486H86.2425V17.0082C86.2425 14.8323 85.1254 13.7586 83.5789 13.7586C81.6604 13.7586 80.4576 15.2093 80.601 17.6465L80.5722 17.5305V24.3486H77.2493V17.0082C77.2493 14.8323 76.1326 13.7586 74.7007 13.7586C73.1543 13.7586 71.6079 14.629 71.6079 17.2115V24.3486H68.285V10.7991H71.6079V13.1203C72.0945 11.4086 73.9277 10.5068 75.5866 10.5068C77.5339 10.5068 79.1376 11.437 79.9106 13.1761C80.3367 12.3479 80.9845 11.6583 81.7798 11.1861C82.5751 10.714 83.4857 10.4785 84.407 10.5068C87.7596 10.509 89.5356 12.8013 89.5356 16.7473Z"
        fill="#171717"
      />
      <path
        clipRule="evenodd"
        d="M65.5838 22.4329V10.7992L62.3409 10.7966V12.5684C61.4184 11.1757 59.6572 10.5082 57.9801 10.5082C54.5413 10.5082 51.5783 13.2068 51.5783 17.5589C51.5783 21.8818 54.5693 24.638 58.0081 24.638C59.6013 24.638 61.4184 23.9416 62.3409 22.5494V22.7523C62.3409 26.6109 60.7757 28.1197 58.2595 28.1197C57.5338 28.1103 56.8246 27.8998 56.2086 27.5111C55.5927 27.1224 55.0935 26.5703 54.7652 25.9146L52.0815 27.1912C52.6241 28.3862 53.4957 29.3971 54.5916 30.1023C55.6876 30.8074 56.9612 31.177 58.2595 31.1664C62.7322 31.1664 65.5838 28.5259 65.5838 22.4329ZM62.0113 16.0522C62.1978 16.5221 62.2908 17.0246 62.285 17.531L62.2849 17.5306V17.5323L62.285 17.531C62.3014 18.0433 62.2159 18.5537 62.0337 19.0319C61.8515 19.5102 61.5761 19.9465 61.2241 20.3149C60.872 20.6832 60.4504 20.976 59.9844 21.1758C59.5183 21.3757 59.0174 21.4786 58.5113 21.4783C56.4983 21.4783 54.8491 19.7954 54.8491 17.5323C54.8291 17.0312 54.9087 16.5312 55.0835 16.062C55.2582 15.5928 55.5243 15.164 55.8661 14.8012C56.2078 14.4384 56.6181 14.149 57.0726 13.9503C57.527 13.7515 58.0163 13.6475 58.5113 13.6444C59.0116 13.6468 59.5065 13.7494 59.9674 13.9464C60.4284 14.1435 60.8463 14.431 61.1971 14.7924C61.5479 15.1538 61.8246 15.582 62.0113 16.0522Z"
        fill="#171717"
        fillRule="evenodd"
      />
      <path d="M49.4591 10.7992H46.1363V24.3487H49.4591V10.7992Z" fill="#171717" />
      <path
        d="M36.1091 20.144H32.8076C32.8684 23.045 35.6867 24.6698 38.5037 24.6711C41.3806 24.6711 44.2579 23.2191 44.2579 20.463C44.2579 19.2151 43.6826 17.3006 40.5027 16.517L38.2291 15.9367C37.4115 15.7626 36.715 15.3564 36.715 14.6601C36.715 13.8189 37.4719 13.2386 38.5623 13.2386C39.5618 13.2386 40.4402 13.8189 40.4402 14.7761H43.8019C43.8325 11.7298 40.9854 10.54 38.4714 10.54C35.382 10.54 33.2016 12.397 33.2016 14.5152C33.2016 16.2277 34.0192 17.8516 37.1995 18.664L39.0773 19.1283C40.1372 19.3604 40.7431 19.7099 40.7431 20.463C40.7431 21.3334 39.8342 21.9424 38.4412 21.9424C36.9573 21.9424 36.1091 21.0742 36.1091 20.144Z"
        fill="#171717"
      />
      <path
        clipRule="evenodd"
        d="M104 24.3488V16.2536C104 12.5397 101.437 10.5087 97.9204 10.5078C95.4668 10.5078 93.2042 11.4943 92.1138 12.9743L93.9129 14.8892C94.5945 14.048 96.0937 13.4385 97.7023 13.4385C99.5832 13.4385 100.728 14.6283 100.728 16.3399V17.1523C99.4592 16.5097 98.0577 16.1816 96.639 16.1951C93.6947 16.1951 91.3775 17.7034 91.3775 20.4307C91.3775 22.9849 93.3677 24.6389 96.1484 24.6389C97.9204 24.6389 99.8289 23.7973 100.728 22.5499V24.3488H104ZM100.728 19.4163V19.9674C99.9378 21.1572 98.2204 21.8823 96.7754 21.8832C95.3597 21.8832 94.4852 21.2475 94.4852 20.2885C94.4852 19.3295 95.385 18.5167 97.2117 18.5167C98.4411 18.5022 99.653 18.8123 100.728 19.4163Z"
        fill="#171717"
        fillRule="evenodd"
      />
    </Icon>
  );
};

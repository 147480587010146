import { FC, useState } from "react";

import { Text, Button, FormField, Row, Textarea, RadioGroup, Radio } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { Banner } from "src/ui/banner";
import { WarningIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { switchWorkspace } from "src/utils/workspaces";

const other = "Other";
const options = [
  "Troubleshooting an issue (per customer request)",
  "Troubleshooting an issue (proactively/internal request)",
  "Understanding usage to prepare for a customer call",
  "Learning about a feature",
  other,
];

export const WorkspaceWarning: FC = () => {
  const { user } = useUser();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [upgradeReason, setUpgradeReason] = useState("");
  const [selectedValue, setSelectedValue] = useState(options[0]);

  if (!user) {
    return null;
  }

  if (!user.not_member_of_current_workspace) {
    return null;
  }

  const hasWritePermissions = Boolean(user.permissions?.find((perm) => perm.resource === "workspace"));

  const getUpgradeReason = () => {
    if (selectedValue === other) {
      return upgradeReason;
    }

    return selectedValue;
  };

  const onChangePermission = async (requestWritePermission: boolean) => {
    user.current_workspace_id &&
      (await switchWorkspace(user.current_workspace_id, window.location.pathname, {
        requestWritePermission,
        upgradeReason: getUpgradeReason(),
      }));
  };

  return (
    <Banner>
      <Row alignItems="center" gap="4">
        <Row color="white">
          <WarningIcon color="white" />
          <Text fontWeight="semibold" mx="4" size="md" textTransform="uppercase" color="inherit">
            You do not belong to this workspace ({user.current_workspace_id}). Be careful.
          </Text>
          <WarningIcon color="white" />
        </Row>
        <Button onClick={() => (hasWritePermissions ? onChangePermission(false) : setUpgradeModalOpen(true))}>
          {!hasWritePermissions ? "Click here to get admin permissions" : "Downgrade to viewer role"}
        </Button>
      </Row>

      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setUpgradeModalOpen(false)}>
              Cancel
            </Button>
            <Button isDisabled={!getUpgradeReason()} variant="danger" onClick={() => onChangePermission(!hasWritePermissions)}>
              Upgrade
            </Button>
          </>
        }
        isOpen={upgradeModalOpen}
        title="Upgrade"
        onClose={() => setUpgradeModalOpen(false)}
      >
        <FormField
          description="Provide a reason why you want to upgrade to access admin permissions. This will be saved for future reference."
          label="Upgrade reason"
        >
          <RadioGroup value={selectedValue} onChange={setSelectedValue}>
            {options.map((option) => {
              return <Radio key={option} label={option} value={option} />;
            })}
          </RadioGroup>
          {selectedValue === other && (
            <Textarea
              autoFocus
              mt="4"
              placeholder="Why do you want to upgrade to admin?"
              value={upgradeReason}
              onChange={(event) => setUpgradeReason(event.target.value)}
            />
          )}
        </FormField>
      </Modal>
    </Banner>
  );
};

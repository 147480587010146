import { FC, useRef, useState, useEffect } from "react";

import { DestinationBadge } from "@hightouchio/ui";
import { Link } from "react-router-dom";
import { Image, Text, Grid } from "theme-ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { AnimatedBox } from "src/ui/animations";
import { Row, Column } from "src/ui/box";
import { Circle } from "src/ui/circle";
import { ChevronRightIcon } from "src/ui/icons";
import { Popout } from "src/ui/popout";
import { getObjectName, getSyncStatusColor } from "src/utils/syncs";
import { formatDatetime } from "src/utils/time";

type Props = {
  syncs: any;
};

const gap = 8;

const variants = {
  expand: (index) => ({
    x: index * gap,
  }),
  collapse: (index) => ({
    x: index * -gap,
  }),
};

export const SyncsCell: FC<Readonly<Props>> = ({ syncs }) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [openSyncIndex, setOpenSyncIndex] = useState<number | undefined>();

  const parent = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (expand) {
      if (!mouseOver && openSyncIndex === undefined) {
        setExpand(false);
      }
    } else if (mouseOver || openSyncIndex !== undefined) {
      setExpand(true);
    }
  }, [mouseOver, expand, openSyncIndex]);

  if (syncs?.length === 0) {
    return <Text sx={{ fontWeight: "semi", color: "base.4" }}>No syncs</Text>;
  }

  const visibleSyncs = syncs?.slice(0, 4);
  const collapsedSyncs = syncs?.slice(4, syncs?.length);

  return (
    <Row
      ref={parent}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      {visibleSyncs?.map((sync, index) => {
        const statusColor = getSyncStatusColor(sync.status);

        return (
          <AnimatedBox
            key={sync.id}
            animate={expand ? "expand" : "collapse"}
            custom={index}
            sx={{ zIndex: visibleSyncs?.length + 1 - index }}
            variants={variants}
          >
            <Popout
              portal
              content={<Sync sync={sync} />}
              contentSx={{ width: "350px" }}
              isOpen={openSyncIndex === index}
              placement="right"
              onClose={() => {
                setOpenSyncIndex(undefined);
              }}
            >
              <Row
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid",
                  borderRadius: "15px",
                  borderColor: statusColor,
                  width: "30px",
                  height: "30px",
                  bg: "white",
                }}
                onClick={() => {
                  if (openSyncIndex === index) {
                    setOpenSyncIndex(undefined);
                  } else {
                    setOpenSyncIndex(index);
                  }
                }}
              >
                <Image
                  alt={sync.destination?.definition?.name}
                  src={sync.destination?.definition?.icon}
                  sx={{ height: "16px", width: "16px", objectFit: "contain" }}
                />
              </Row>
            </Popout>
          </AnimatedBox>
        );
      })}
      {collapsedSyncs?.length > 0 && (
        <AnimatedBox animate={expand ? "expand" : "collapse"} custom={4} sx={{ zIndex: 1 }} variants={variants}>
          <Popout
            portal
            content={
              <Grid gap={6}>
                {collapsedSyncs.map((sync) => {
                  return <Sync key={sync.id} sync={sync} />;
                })}
              </Grid>
            }
            contentSx={{ width: "350px", overflowY: "auto" }}
            isOpen={openSyncIndex === 6}
            maxHeight={400}
            offset={0}
            placement="right"
            onClose={() => {
              setOpenSyncIndex(undefined);
            }}
          >
            <Row
              sx={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px",
                border: "small",
                borderColor: "white",
                width: "30px",
                height: "30px",
                bg: "base.7",
                zIndex: 6,
              }}
              onClick={() => {
                if (openSyncIndex === 6) {
                  setOpenSyncIndex(undefined);
                } else {
                  setOpenSyncIndex(6);
                }
              }}
            >
              <Text sx={{ color: "white", fontWeight: "semi", fontSize: "10px" }}>+{collapsedSyncs.length}</Text>
            </Row>
          </Popout>
        </AnimatedBox>
      )}
    </Row>
  );
};

const Sync = ({
  sync: {
    id,
    config,
    status,
    destination: { name, definition },
    sync_requests,
  },
}) => {
  const request = sync_requests?.[0];

  const statusColor = getSyncStatusColor(status);

  return (
    <Column sx={{ px: 5 }}>
      <Row sx={{ py: 3, borderBottom: "small", alignItems: "center" }}>
        <IntegrationIcon name={definition?.name} src={definition?.icon} />
        <Text sx={{ fontWeight: "bold", ml: 2 }}>{name ?? definition?.name ?? "Private destination"}</Text>
      </Row>
      {config?.object && (
        <Row
          sx={{
            py: 3,
            alignItems: "center",
            borderBottom: "small",
            ":hover": { svg: { fill: "black" } },
          }}
        >
          <Text sx={{ fontWeight: "semi", color: "base.5", width: "80px" }}>Object</Text>
          <DestinationBadge>{getObjectName(config?.object)}</DestinationBadge>
        </Row>
      )}

      {request && (
        <Link to={`/syncs/${id}/runs/${request?.id}`}>
          <Row
            sx={{
              py: 3,
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "small",
              ":hover": { svg: { fill: "black" } },
            }}
          >
            <Row sx={{ alignItems: "center" }}>
              <Text sx={{ fontWeight: "semi", color: "base.5", width: "80px" }}>Last run</Text>
              <Circle color={statusColor} radius="10px" />
              <Text sx={{ ml: 2, fontWeight: "semi", color: "base.7" }}>{formatDatetime(request?.created_at)}</Text>
            </Row>
            <ChevronRightIcon color="base.4" />
          </Row>
        </Link>
      )}

      <Link to={`/syncs/${id}`}>
        <Row
          sx={{
            py: 3,
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "small",
            ":hover": { svg: { fill: "black" } },
          }}
        >
          <Text>View sync</Text>
          <ChevronRightIcon color="base.4" />
        </Row>
      </Link>
    </Column>
  );
};

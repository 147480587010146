import { FC, MouseEvent, ReactElement, ReactNode, useCallback } from "react";

import { Text, Box, BoxProps } from "@hightouchio/ui";
import { get } from "lodash";

import { OrderBy } from "src/graphql";

import { SortIcon } from "./sortIcon";
import { TableColumn } from "./table";

export type HeaderCell = {
  children: ReactNode;
  onClick?: (event: MouseEvent) => void;
  sortDirection?: OrderBy | null;
  top?: number | string;
  isSortMenu?: boolean;
  isDense?: boolean;
};

export type TableCellProps<Data> = {
  column: TableColumn;
  row: Data;
  onClick?: (row: any, event: MouseEvent) => void;
  height?: string;
  isDense?: boolean;
};

export const Cell: FC<Readonly<{ children?: ReactNode; onClick?: (event: MouseEvent) => void } & BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box
      as="td"
      display="flex"
      alignItems="center"
      px={4}
      borderBottom="1px"
      {...props}
      borderColor={props.borderColor ?? "gray.100"}
    >
      {typeof children === "string" ? <Text isTruncated>{children}</Text> : children}
    </Box>
  );
};

export function TableCell<Data>({
  column: { key, cell, defaultValue = "", divider, disabled, whitespace },
  row,
  height,
  isDense,
}: Readonly<TableCellProps<Data>>): ReactElement {
  const value = key ? get(row, key, defaultValue) : row;

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) {
        event.stopPropagation();
      }
    },
    [disabled],
  );

  return (
    <Cell
      onClick={handleClick}
      minHeight={height}
      whiteSpace={whitespace || "nowrap"}
      p={isDense ? 2 : undefined}
      borderLeft={isDense || divider ? "1px" : undefined}
      borderColor={isDense ? "base.border !important" : undefined}
      sx={{ "&:last-of-type": { borderRight: isDense ? "1px" : undefined } }}
    >
      {cell ? cell(value) : String(value)}
    </Cell>
  );
}

export const HeaderCell: FC<HeaderCell> = ({ children, onClick, sortDirection, top, isSortMenu, isDense, ...props }) => {
  return (
    <Box
      as="th"
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      whiteSpace="nowrap"
      fontWeight="semibold"
      bg="white"
      fontSize="sm"
      py={3}
      pos={typeof top !== "undefined" ? "sticky" : undefined}
      zIndex={98}
      top={top}
      color={isDense ? "text.primary" : "text.secondary"}
      textTransform={isDense ? undefined : "uppercase"}
      userSelect="none"
      borderLeft={isDense ? "1px" : undefined}
      borderTop={isDense ? "1px" : undefined}
      borderBottom={isDense ? "1px" : "2px"}
      sx={{
        "&:first-of-type": {
          pl: 8,
        },
        "&:last-of-type": {
          pr: isSortMenu ? 0 : 8,
          borderRight: isDense ? "1px" : undefined,
        },
        "&:hover > .sort-icon": {
          transition: "background fill stroke 150ms ease-in",
          svg: {
            fill: "text.secondary",
          },
        },

        cursor: onClick ? "pointer" : "auto",
      }}
      onClick={onClick}
      px={isDense ? "8px !important" : isSortMenu ? 0 : 4}
      borderColor="base.border !important"
      {...props}
    >
      {children}
      {onClick && <SortIcon className="sort-icon" sortDirection={sortDirection} />}
    </Box>
  );
};

import { FC } from "react";

import { Text, Row, ClipboardButton } from "@hightouchio/ui";

type DisplaySlugProps = {
  currentSlug: string | undefined | null;
};

export const DisplaySlug: FC<Readonly<DisplaySlugProps>> = ({ currentSlug }) => {
  return (
    <Row align="center" maxW="200px" gap={2}>
      <Text isTruncated>{currentSlug}</Text>
      <ClipboardButton text={currentSlug ?? ""} />
    </Row>
  );
};

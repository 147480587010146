interface Option {
  key: string;
  value: string;
}

/**
 * Creates a list of options from the key value pairs in an object.
 *
 * Ex:
 * ```
 * const object = { 1: 'a', 2: 'b' };
 * const result = createOptionsFromObject(object);
 * console.log(result);
 * // [{ key: '1', value: 'a' }, { key: '2', value: 'b' }]
 * ```
 *
 * @param object The object of key value pairs
 * @returns The transformed options
 */
export const createOptionsFromObject = (object: Record<string, string> | undefined): Option[] => {
  const result: Option[] = [];

  if (!object) {
    return result;
  }

  for (const [key, value] of Object.entries(object)) {
    result.push({ key, value });
  }

  return result;
};

import { FC, useEffect, useState } from "react";

import { ArrowPathIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Row, Column, useToast, Text, Button, Menu, MenuItem, MenuList, MenuActionsButton, Avatar } from "@hightouchio/ui";
import { isEqual, omit, omitBy } from "lodash";
import { useParams } from "react-router-dom";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { DeleteSourceWarning } from "src/components/modals/delete-source-warning";
import { OverageModal } from "src/components/modals/overage-modal";
import { SaveWarning } from "src/components/modals/save-warning";
import { Header, SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SourceForm } from "src/components/sources/setup";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ListSourceTestStepsQueryVariables,
  ResourcePermissionGrant,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Tabs } from "src/ui/tabs";
import { SourceBadges, SourceConfig } from "src/utils/sources";
import { formatDate } from "src/utils/time";

import { useLabels } from "../../components/labels/use-labels";
import { SyncLog } from "./sync-log";

enum Tab {
  OVERVIEW = "Overview",
  CONFIGURATION = "Configuration",
  SYNC_LOG = "Sync logs",
}

export const Source: FC = () => {
  const { source_id: id } = useParams<{ source_id: string }>();
  const { user } = useUser();
  const { toast } = useToast();
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>();
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);

  const [tab, setTab] = useState<Tab>();
  const [deleteSource, setDeleteSource] = useState<boolean>(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const {
    data: source,
    isLoading: loadingSource,
    refetch,
  } = useSourceQuery({ id: String(id) }, { enabled: Boolean(id), select: (data) => data.connections_by_pk });

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();

  const { isLoading: updateLoading, mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const { labels } = useLabels();

  const updatePermission = useHasPermission([
    { resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const deletePermission = useHasPermission([
    { resource: "source", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);

  useEffect(() => {
    if (!tab && source) {
      setTab(source?.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION);
    }
    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
    setLightningEnabled(source?.plan_in_warehouse ?? undefined);
    setPlannerDatabase(source?.plan_in_warehouse_config?.plannerDatabase ?? undefined);
  }, [source]);

  if (!id || loadingSource || updatePermission.isLoading || deletePermission.isLoading) {
    return <PageSpinner />;
  }

  if (!source) {
    return <Warning subtitle="It may have been deleted" title="Source not found" />;
  }

  const TABS = [source.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION];

  if (source.plan_in_warehouse) {
    TABS.push(Tab.SYNC_LOG);
  }

  const currentLabels = source.tags ?? {};
  const labelKeys = Object.keys(currentLabels);

  const onUpdate = () => {
    toast({
      id: "update-source",
      title: "Source was updated",
      variant: "success",
    });
  };

  const saveName = async (name: string) => {
    await updateSource({
      id,
      object: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const save = async () => {
    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        tunnel_id: tunnelId ? tunnelId : null,
        credential_id: credentialId != null ? String(credentialId) : undefined,
        config: updatedConfig,
        plan_in_warehouse: lightningEnabled || undefined,
        plan_in_warehouse_config: plannerDatabase ? { plannerDatabase } : undefined,
      },
    });

    refetch();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    try {
      await updateSource({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-source-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(credentialId, source.credential_id ?? undefined);
  const lightningEnabledChanged = !isEqual(lightningEnabled, source.plan_in_warehouse ?? undefined);
  const plannerDatabaseChanged = !isEqual(plannerDatabase, source.plan_in_warehouse_config?.plannerDatabase ?? undefined);

  const dirty = tunnelChanged || configChanged || credentialChanged || lightningEnabledChanged || plannerDatabaseChanged;
  const complete = tunnelId !== null;

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source.definition?.type,
    sourceId: String(source.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled ? { plannerDatabase } : undefined,
  };

  const updatedByUsername = source.updated_by_user?.name || source.created_by_user?.name;

  return (
    <>
      <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[{ label: "Sources", link: "/sources" }, { label: source.name ?? "" }]}
          title={`${source.name ?? "Unnamed source"} - Sources`}
        >
          <Column width="100%" mb={6}>
            <Header
              actions={
                (updatePermission.hasPermission || deletePermission.hasPermission) && (
                  <Menu>
                    <MenuActionsButton variant="secondary" />
                    <MenuList>
                      {updatePermission.hasPermission && (
                        <MenuItem
                          icon={PencilIcon}
                          onClick={() => {
                            setIsEditLabelModalOpen(true);
                          }}
                        >
                          Edit labels
                        </MenuItem>
                      )}
                      {deletePermission.hasPermission && (
                        <MenuItem
                          icon={TrashIcon}
                          variant="danger"
                          onClick={() => {
                            setDeleteSource(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )
              }
              title={source.name ?? ""}
              onNameChange={saveName}
            />

            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon src={source.definition?.icon} name={source.definition?.name} />
                <Text fontWeight="medium">{source.name}</Text>
                <SourceBadges isSampleDataSource={source.definition?.isSampleDataSource} />
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Last updated:</Text>
                <Row gap={1} align="center">
                  {formatDate((source.updated_at || source.created_at)!)}
                  {updatedByUsername && (
                    <>
                      <Text>by</Text>
                      <Avatar size="xs" name={updatedByUsername} />
                    </>
                  )}
                </Row>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Slug:</Text>
                <DisplaySlug currentSlug={source.slug} />
              </Row>
              {labelKeys?.length > 0 && (
                <Row>
                  <Labels labels={currentLabels} />
                </Row>
              )}
            </DetailBar>
          </Column>
          <Column width="100%">
            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {tab === Tab.CONFIGURATION && (
              <Row align="flex-start">
                <Column gap={8} flexGrow={1} minWidth={0}>
                  {source.definition && (
                    <SourceForm
                      //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                      key={`${updateLoading}`}
                      hideSecret
                      config={config}
                      credentialId={credentialId}
                      definition={source.definition}
                      hasSetupLightning={Boolean(source.plan_in_warehouse)}
                      isSetup={false}
                      lightningEnabled={lightningEnabled}
                      plannerDatabase={plannerDatabase}
                      setConfig={setConfig}
                      setCredentialId={setCredentialId}
                      setLightningEnabled={setLightningEnabled}
                      setPlannerDatabase={setPlannerDatabase}
                      setTunnelId={setTunnelId}
                      sourceId={id}
                      tunnelId={tunnelId}
                    />
                  )}
                </Column>
                <SidebarForm
                  buttons={
                    <>
                      <Permission
                        permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                      >
                        <Button variant="primary" isDisabled={!dirty || !complete} isLoading={updateLoading} onClick={save}>
                          Save changes
                        </Button>
                      </Permission>
                      {!source.definition?.disableTest && config ? (
                        <Permission
                          permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            isDisabled={!complete}
                            onClick={async () => {
                              await getTestSteps(variables);
                              runTest(variables);
                              setTestConnectionModalOpen(true);
                            }}
                          >
                            Test connection
                          </Button>
                        </Permission>
                      ) : null}
                    </>
                  }
                  docsUrl={source.definition?.docs ?? ""}
                  message={`Can you help me with ${source.definition?.name}? `}
                  name={source.definition?.name ?? ""}
                />
              </Row>
            )}

            {tab === Tab.OVERVIEW && (
              <Row align="flex-start" gap={8}>
                <Column gap={4}>
                  <Text size="lg" fontWeight="semibold">
                    {source.definition?.name}
                  </Text>
                  <Text color="text.secondary">{source.definition?.longPitch}</Text>
                </Column>
                <SidebarForm docsUrl={source.definition?.docs ?? ""} name={source.definition?.name ?? ""} />
              </Row>
            )}

            {tab === Tab.SYNC_LOG && (
              <Permission permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}>
                <SyncLog source={source} />
              </Permission>
            )}
          </Column>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels ?? {}}
        loading={updateLoading}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <OverageModal />

      <Modal
        footer={
          <>
            <Button
              variant={testResults?.success !== false && !dirty ? undefined : "secondary"}
              onClick={() => {
                setTestConnectionModalOpen(false);
              }}
            >
              Close
            </Button>
            {testResults?.success === false ? (
              <Button
                icon={ArrowPathIcon}
                onClick={() => {
                  runTest(variables);
                }}
              >
                Test again
              </Button>
            ) : dirty ? (
              <Button
                isDisabled={!testResults?.success}
                isLoading={updateLoading}
                onClick={async () => {
                  await save();
                  setTestConnectionModalOpen(false);
                }}
              >
                Save changes
              </Button>
            ) : null}
          </>
        }
        isOpen={testConnectionModalOpen}
        sx={{ maxWidth: "800px", width: "90%" }}
        title={`Test connection to ${source.definition?.name}`}
        onClose={() => {
          setTestConnectionModalOpen(false);
        }}
      >
        <Testing
          config={config}
          credentialId={credentialId}
          isSetup={false}
          plannerDatabase={plannerDatabase}
          results={testResults}
          sourceDefinition={source.definition}
          steps={testSteps}
          timeElapsed={timeElapsed}
        />
      </Modal>

      <SaveWarning dirty={dirty} />
      {deleteSource && <DeleteSourceWarning source={source} onClose={() => setDeleteSource(false)} />}
    </>
  );
};

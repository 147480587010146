import { useState } from "react";

import { useToast } from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Button } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { Modal } from "src/ui/modal";

export const JsonEditor = ({ onClose, formkit }) => {
  const { toast } = useToast();
  const { getValues, reset } = useFormContext();
  const { setConfig: _setConfig, config: _config } = useDestinationForm();

  const [config, setConfig] = useState(JSON.stringify(formkit ? getValues() : _config, null, 4));

  const beautifyJSON = (body, onError: null | ((error: Error) => void) = null) => {
    let obj;
    try {
      obj = JSON.parse(body);
    } catch (err) {
      if (onError) {
        onError(err);
      }
      return body;
    }
    return JSON.stringify(obj, null, 4);
  };

  return (
    <Modal
      bodySx={{ p: 0 }}
      footer={
        <>
          <Button
            variant="secondary"
            onClick={() => {
              setConfig(
                beautifyJSON(config, (err) => {
                  toast({
                    id: "beautify-json",
                    title: "This configuration is not valid JSON",
                    message: String(err),
                    variant: "warning",
                  });
                }),
              );
            }}
          >
            Beautify JSON
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              try {
                const obj = JSON.parse(config);
                if (formkit) {
                  reset(obj);
                } else {
                  _setConfig(obj);
                }
                onClose();
              } catch (err) {
                toast({
                  id: "apply-json-config",
                  title: "This configuration is not valid JSON",
                  message: String(err),
                  variant: "warning",
                });
              }
            }}
          >
            Apply
          </Button>
        </>
      }
      sx={{ maxWidth: "800px", width: "90%", height: "90%" }}
      title="Edit as JSON"
      onClose={onClose}
    >
      <Editor code={config} language="json" sx={{ height: "100%", width: "100%" }} onChange={setConfig} />
    </Modal>
  );
};

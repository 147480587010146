import { useState } from "react";

import { Close, Flex, Grid } from "theme-ui";
import validator from "validator";

import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { PlusIcon } from "src/ui/icons";
import { Input } from "src/ui/input";

import { EmailAlert, EmailAlertConfig } from "./types";

export const EmailForm = ({
  config,
  setConfig,
}: {
  config: EmailAlertConfig;
  setConfig: (config: EmailAlertConfig) => void;
}) => {
  const [newEmail, setNewEmail] = useState<string | undefined | null>();

  const recipients = config?.recipients || [];

  const setRecipients = (recipients: string[]) => {
    setConfig({ ...config, recipients });
  };

  return (
    <>
      <Field label="Recipients">
        {!!recipients?.length && (
          <Grid gap={2} sx={{ mb: 2 }}>
            {recipients?.map((r, idx) => (
              <Flex key={idx} sx={{ alignItems: "center" }}>
                <Input readOnly placeholder="Enter an email..." sx={{ width: "360px", maxWidth: "360px" }} value={r || ""} />
                <Close
                  sx={{ ml: 2 }}
                  onClick={() => {
                    const a = [...recipients];
                    a.splice(idx, 1);
                    setRecipients(a);
                  }}
                />
              </Flex>
            ))}
          </Grid>
        )}
        <Flex sx={{ alignItems: "center" }}>
          <Input
            placeholder="Enter an email..."
            sx={{ width: "360px", maxWidth: "360px" }}
            value={newEmail || ""}
            onChange={(email) => {
              setNewEmail(email);
            }}
          />
          <Button
            disabled={!newEmail || !validator.isEmail(newEmail)}
            iconBefore={<PlusIcon size={14} />}
            sx={{ ml: 2 }}
            variant="secondary"
            onClick={() => {
              if (newEmail) {
                setRecipients([...recipients, newEmail]);
              }

              setNewEmail(null);
            }}
          >
            Add
          </Button>
        </Flex>
      </Field>
    </>
  );
};

export const emailValidator = (alert: EmailAlert): boolean => {
  const config = alert?.config;
  return Boolean(config?.recipients?.length);
};

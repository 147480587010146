import { buttons } from "../../../design";

export default {
  ...buttons,
  switch: {
    outline: "none",
    fontWeight: "semi",
    py: 1,
    px: 2,
    fontSize: 1,
    ":first-child": {
      borderTopLeftRadius: 1,
      borderBottomLeftRadius: 1,
    },
    ":last-child": {
      borderRight: "small",
      borderColor: "primary",
      borderTopRightRadius: 1,
      borderBottomRightRadius: 1,
    },
    bg: "primary",
    borderRadius: 0,
    border: "small",
    borderRight: "none",
    borderColor: "primary",
    ":disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  close: {
    bg: "none",
    borderRadius: 0,
    color: "blacks.5",
    ":hover": {
      color: "black",
    },
    flexShrink: 0,
  },
};

import { FC, useMemo } from "react";

import { ArrowPathIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { Box, Text, Badge as HightouchUiBadge, Combobox, GroupedCombobox, IconButton } from "@hightouchio/ui";
import { Image } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermission } from "src/contexts/permission-context";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Select } from "src/ui/select";
import { NEW_ICON_MAP } from "src/utils/destinations";

import { ExtendedOption, FormkitGraphQLReference, isExtendedTypes } from "../../../../formkit";
import { ExtendedAssociationOption } from "../../../../formkit/src/api/components/associationOption";
import { toExtendedOption } from "../../../../formkit/src/api/components/option";
import { useQuery } from "../formkit";
import { useFormkitContext } from "./formkit-context";
import { formatOptionLabel, formatFromColumnOption } from "./mappings";

type Lookup = {
  object: string;
  from: string;
  by: string;
  byType: string;
};

export type AssociationOptions = ExtendedAssociationOption | FormkitGraphQLReference;

type Props = {
  useHightouchUi?: boolean;
  name: string;
  // Static association options or handler reference to fetch props base on asc object
  associationOptions?: AssociationOptions;
  associationObjectLabels?: Record<string, unknown>;
  index: number;
  onChange?: (value: any) => void;
  value: { type: "reference"; to: string; lookup: Lookup };
};

const retrieveErrorMessage = (errors, index: number, name: string): string | undefined => {
  for (const direction of ["by", "from"]) {
    const mappingPath = `${name}[${index}].lookup.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

export const AssociationMapper: FC<Readonly<Props>> = ({
  useHightouchUi = false,
  associationOptions,
  index,
  name,
  onChange,
  value,
}) => {
  const { columns, destination, destinationDefinition, loadingModel, model, reloadModel, sourceDefinition } =
    useFormkitContext();
  const { errors } = useDestinationForm();
  const asyncAscOptions =
    associationOptions?.["query"] &&
    typeof associationOptions?.["query"] === "string" &&
    associationOptions !== null &&
    associationOptions !== undefined;

  const variables = {
    ...associationOptions?.variables?.["input"]?.variables,
    ...{ object: value?.lookup?.object ?? value?.to },
  };

  const useAsyncOptions = Boolean(asyncAscOptions && (value?.lookup?.object ?? value?.to));

  const {
    data: associatedObjectFields,
    error: queryError,
    refetch,
    isFetching,
  } = useQuery<any>(
    JSON.stringify({
      name,
      variables: {
        ...associationOptions?.variables,
        input: { ...associationOptions?.variables?.["input"], variables },
      },
    }),
    {
      enabled: useAsyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: associationOptions?.["query"],
        variables: {
          ...associationOptions?.variables,
          input: { ...associationOptions?.variables?.["input"], variables },
        },
      },
    },
  );

  const ascFields: ExtendedOption[] = toExtendedOption(
    asyncAscOptions ? associatedObjectFields : associationOptions?.[value?.lookup?.object],
  );

  const errorToRender = retrieveErrorMessage(errors, index, name) || (queryError as any);

  const permission = usePermission();

  // Items in `columns` have `options` field as optional,
  // but `GroupedCombobox` expects it to be required
  const columnOptionGroups = useMemo(() => {
    return (columns ?? []).map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [columns]);

  if (useHightouchUi) {
    return (
      <Box aria-label={`${name} association mapper`}>
        <Box display="flex" flexDirection="column" gap={3} pb={6}>
          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px max-content">
            <Text>Find</Text>
            <HightouchUiBadge>{value?.lookup?.object ?? value?.to}</HightouchUiBadge>
          </Box>

          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px 16px minmax(0, 1fr) 36px">
            <Text>where</Text>

            {destinationDefinition?.icon && <Box as="img" src={destinationDefinition.icon} width="4" />}

            <Combobox<any, string>
              isDisabled={permission.unauthorized}
              isInvalid={Boolean(errors?.[`${name}[${index}].lookup.by`])}
              isLoading={isFetching}
              optionAccessory={(option) => {
                const type = isExtendedTypes(option?.extendedType) ? option?.extendedType?.type : option?.type;

                return type
                  ? {
                      type: "icon",
                      icon: NEW_ICON_MAP[type],
                    }
                  : undefined;
              }}
              optionLabel={(option) => {
                return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
              }}
              options={ascFields ?? []}
              placeholder="Select a field..."
              value={value?.lookup?.by}
              width="auto"
              onChange={(newValue) => {
                const option = ascFields.find((option) => option.value === newValue);

                onChange?.({
                  ...value,
                  lookup: {
                    ...value?.lookup,
                    by: newValue || undefined,
                    byType: option?.extendedType?.type || (option as any)?.type,
                  },
                });
              }}
            />

            {useAsyncOptions && (
              <IconButton
                aria-label="Refresh"
                icon={ArrowPathIcon}
                variant="secondary"
                onClick={() => {
                  void refetch();
                }}
              />
            )}
          </Box>

          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px 16px minmax(0, 1fr) 36px">
            <Text>equals</Text>

            {sourceDefinition?.icon && <Box as="img" src={sourceDefinition.icon} width="4" />}

            <GroupedCombobox<any, string>
              isDisabled={permission.unauthorized}
              isInvalid={Boolean(errors?.[`${name}[${index}].lookup.from`])}
              isLoading={loadingModel}
              optionAccessory={(option) => {
                return option?.value?.type === "additionalColumnReference"
                  ? {
                      type: "icon",
                      icon: Square3Stack3DIcon,
                    }
                  : undefined;
              }}
              optionGroups={columnOptionGroups}
              placeholder="Select a column..."
              value={value?.lookup?.from}
              width="auto"
              onChange={(newValue) => {
                onChange?.({
                  ...value,
                  lookup: {
                    ...value?.lookup,
                    from: newValue,
                  },
                });
              }}
            />

            <IconButton aria-label="Refresh" icon={ArrowPathIcon} variant="secondary" onClick={reloadModel} />
          </Box>
        </Box>

        {errorToRender && <FieldError error={errorToRender} />}
      </Box>
    );
  }

  return (
    <Column aria-label={`${name} association mapper`}>
      {/** Field in the destination */}
      <Row sx={{ alignItems: "center", flex: 1 }}>
        <Row sx={{ color: "base.4", mr: 2, fontWeight: "semi", whiteSpace: "nowrap", alignItems: "center" }}>
          Find <Badge sx={{ mx: 1, mb: -1, mr: 2 }}>{value?.lookup?.object ?? value?.to}</Badge> where
        </Row>
        <Select
          before={<Image src={destinationDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          formatOptionLabel={formatOptionLabel}
          isError={!!errors?.[`${name}[${index}].lookup.by`]}
          isLoading={isFetching}
          options={ascFields}
          placeholder="Select a field..."
          reload={refetch}
          sx={{ width: "200px" }}
          value={value?.lookup?.by}
          onChange={(option) => {
            if (typeof onChange === "function") {
              onChange({
                ...value,
                lookup: {
                  ...value?.lookup,
                  by: option?.value || undefined,
                  byType: option?.extendedType?.type || option?.type,
                },
              });
            }
          }}
        />
      </Row>

      {/** Field in the model */}
      <Row sx={{ alignItems: "center", mt: 2 }}>
        <Row sx={{ color: "base.4", mr: 2, fontWeight: "semi", whiteSpace: "nowrap", alignItems: "center" }}>equals</Row>
        <Select
          before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          formatOptionLabel={formatFromColumnOption}
          isError={!!errors?.[`${name}[${index}].lookup.from`]}
          options={columns}
          placeholder="Select a column..."
          value={value?.lookup?.from}
          onChange={(option) => {
            if (typeof onChange === "function") {
              onChange({ ...value, lookup: { ...value?.lookup, from: option?.value } });
            }
          }}
        />
      </Row>

      {errorToRender && <FieldError error={errorToRender} />}
    </Column>
  );
};

import { FC } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Box, IconButton, Text as HightouchUiText, Tooltip } from "@hightouchio/ui";
import { startCase } from "lodash";
import { Image, Text } from "theme-ui";

import { Row } from "src/ui/box";
import { ReloadButton } from "src/ui/button";

import { useFormkitContext } from "./formkit-context";

type Props = {
  useHightouchUi?: boolean;
  object?: string;
  columns?: number;
  reload?: () => void;
  loading?: boolean;
  usingCustomFromOptions?: boolean;
  /**
  @default Name of model
  */
  fromLabel?: string;
  /**
  @default Icon of source
  */
  fromIcon?: string;
  fromReloadOptions?: () => void;
  fromLoadingOptions?: boolean;
};

export const MappingsHeader: FC<Readonly<Props>> = ({
  useHightouchUi = false,
  object,
  columns = 4,
  reload,
  usingCustomFromOptions,
  loading,
  fromReloadOptions,
  fromLoadingOptions,
  fromLabel,
  fromIcon,
}) => {
  const { model, loadingModel, reloadModel, sourceDefinition, destinationDefinition } = useFormkitContext();

  if (useHightouchUi) {
    return (
      <>
        <Box alignItems="center" display="flex" gap={2}>
          <Box alt="" as="img" flex="none" src={fromIcon || sourceDefinition?.icon} width={5} />
          <HightouchUiText isTruncated fontWeight="semibold">
            {fromLabel || model?.name}
          </HightouchUiText>

          {(!usingCustomFromOptions || fromReloadOptions) && (
            <Tooltip message={fromReloadOptions ? "Refresh options" : "Refresh columns available in the source"}>
              <IconButton
                aria-label="Refresh source columns"
                icon={ArrowPathIcon}
                isLoading={fromLoadingOptions || loadingModel}
                size="sm"
                variant="secondary"
                onClick={fromReloadOptions || reloadModel}
              />
            </Tooltip>
          )}
        </Box>

        <Box />

        <Box alignItems="center" display="flex" gap={2}>
          <Box alt="" as="img" flex="none" src={destinationDefinition?.icon} width={5} />
          <HightouchUiText isTruncated fontWeight="semibold">
            Field from {object ? startCase(object) : destinationDefinition?.name}
          </HightouchUiText>

          {reload && (
            <Tooltip message="Refresh columns available in the destination">
              <IconButton
                aria-label="Refresh destination columns"
                icon={ArrowPathIcon}
                isLoading={loading}
                size="sm"
                variant="secondary"
                onClick={reload}
              />
            </Tooltip>
          )}
        </Box>

        <Box />
      </>
    );
  }

  return (
    <>
      <Row sx={{ alignItems: "center", flex: 1 }}>
        <Image alt="source icon" src={fromIcon || sourceDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {fromLabel || model?.name}
        </Text>
        {(!usingCustomFromOptions || fromReloadOptions) && (
          <ReloadButton
            aria-label="reload source columns"
            loading={fromLoadingOptions || loadingModel}
            sx={{ ml: 2 }}
            tooltip={fromReloadOptions ? "Click to refresh options" : "Click to refresh columns available in the source"}
            onClick={fromReloadOptions || reloadModel}
          />
        )}
      </Row>
      <Box />
      <Row sx={{ flex: 1, alignItems: "center" }}>
        <Image alt="destination icon" src={destinationDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            display: "flex",
            flexWrap: "wrap",
            fontWeight: "semi",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Text>Field from</Text>
          &nbsp;
          <Text
            sx={{
              fontWeight: "bold",
            }}
          >
            {object ? startCase(object) : destinationDefinition?.name}
          </Text>
        </Text>
        {reload && (
          <ReloadButton
            aria-label="reload destination columns"
            loading={loading}
            sx={{ ml: 2 }}
            tooltip="Click to refresh columns available in the destination"
            onClick={reload}
          />
        )}
      </Row>
      {columns === 4 && <Box />}
    </>
  );
};

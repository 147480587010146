import { ReactElement } from "react";

import { TextInput, FormField } from "@hightouchio/ui";
import * as Yup from "yup";

import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "./custom-query";

const PATH_LABEL = "Path to file";
const PATH_DESCRIPTION = "The file must be a CSV file (with header row).";

function QueryForm({ query, onChange }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  return (
    <FormField description={PATH_DESCRIPTION} label={PATH_LABEL}>
      <TextInput
        placeholder="/path/to/file.csv"
        value={query?.path ?? ""}
        onChange={(event) =>
          onChange({
            path: event.target.value,
            type: "string",
          })
        }
      />
    </FormField>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  return (
    <FormField description={PATH_DESCRIPTION} label={PATH_LABEL}>
      <code>{props.query.path}</code>
    </FormField>
  );
}

const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
  return Yup.object().shape({ type: Yup.string().required() });
});

export default { QueryForm, QueryView, querySchema };

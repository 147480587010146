import { FC } from "react";

import { RadioGroup, Radio, Checkbox as HightouchUiCheckbox, TextInput, Box, Text as HightouchUiText } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { Input } from "src/ui/input";

import { StaticType, STATIC_OPTIONS, STATIC_ICONS, FormProps, NEW_STATIC_ICONS } from "./types";

export const StaticInput: FC<Readonly<FormProps>> = ({ useHightouchUi = false, value, onChange }) => {
  const active = {
    bg: "avocado",
    color: "forest",
    ":hover": {},
  };

  const getInput = () => {
    switch (value.valueType) {
      case StaticType.STRING:
        if (useHightouchUi) {
          return (
            <TextInput
              placeholder="Enter string"
              value={value.value ?? ""}
              width="100%"
              onChange={(event) => {
                onChange({
                  ...value,
                  value: event.target.value,
                });
              }}
            />
          );
        }

        return <Input placeholder="Enter string" value={value.value} onChange={(val) => onChange({ ...value, value: val })} />;
      case StaticType.NUMBER:
        if (useHightouchUi) {
          return (
            <TextInput
              placeholder="Enter number..."
              type="number"
              value={value.value}
              width="100%"
              onChange={(event) => {
                onChange({
                  ...value,
                  value: event.target.value,
                });
              }}
            />
          );
        }

        return (
          <Input
            placeholder="Enter number"
            type="number"
            value={value.value}
            onChange={(val) => onChange({ ...value, value: val })}
          />
        );
      case StaticType.BOOLEAN:
        if (useHightouchUi) {
          return (
            <RadioGroup
              value={value.value === true ? "true" : "false"}
              onChange={(newValue) => {
                onChange({
                  ...value,
                  value: newValue === "true",
                });
              }}
            >
              <Radio label="True" value="true" />
              <Radio label="False" value="false" />
            </RadioGroup>
          );
        }

        return (
          <Row
            sx={{
              borderRadius: 1,
              border: "small",
              borderColor: "primaries.2",
              fontWeight: "semi",
              fontSize: 0,
              cursor: "pointer",
            }}
          >
            <Text
              sx={{
                px: 3,
                py: 2,
                ":hover": { bg: "base.0" },
                ...(value.value === true ? active : {}),
              }}
              onClick={() => onChange({ ...value, value: true })}
            >
              True
            </Text>
            <Text
              sx={{
                px: 3,
                py: 2,
                ":hover": { bg: "base.0" },
                ...(value.value === false ? active : {}),
              }}
              onClick={() => onChange({ ...value, value: false })}
            >
              False
            </Text>
          </Row>
        );
      case StaticType.NULL:
        if (useHightouchUi) {
          return (
            <HightouchUiCheckbox
              isChecked={value.value === null}
              label="Send a null value to your destination"
              onChange={(event) => {
                onChange({
                  ...value,
                  value: event.target.checked ? null : undefined,
                });
              }}
            />
          );
        }

        return (
          <Checkbox
            label="Send a null value to your destination"
            value={value.value === null}
            onChange={(val) => onChange({ ...value, value: val ? null : undefined })}
          />
        );
      default:
        return undefined;
    }
  };

  if (useHightouchUi) {
    return (
      <Box display="flex" flex="1" minWidth={0}>
        <Box
          borderColor="gray.300"
          borderRightWidth="1px"
          display="flex"
          flex="none"
          flexDirection="column"
          gap={1}
          p={3}
          width="180px"
        >
          {STATIC_OPTIONS.map(({ label, type }) => {
            const isActive = type === value.valueType;
            const Icon = useHightouchUi ? NEW_STATIC_ICONS[type] : STATIC_ICONS[type];

            return (
              <Box
                key={label}
                _hover={{
                  bg: isActive ? "forest.100" : "gray.100",
                }}
                alignItems="center"
                bg={isActive ? "forest.100" : undefined}
                borderRadius="base"
                cursor="pointer"
                display="flex"
                gap={2}
                px={2}
                py={1.5}
                sx={
                  isActive
                    ? {
                        span: {
                          color: "forest.600",
                        },
                      }
                    : undefined
                }
                onClick={() => {
                  onChange({
                    type: "static",
                    valueType: type,
                  });
                }}
              >
                {useHightouchUi ? <Box as={Icon} boxSize={3} /> : <Icon color="base.5" size={18} />}
                <HightouchUiText size="sm">{label}</HightouchUiText>
              </Box>
            );
          })}
        </Box>

        <Box flex="1" minWidth={0} p={3}>
          {getInput()}
        </Box>
      </Box>
    );
  }

  return (
    <Row sx={{ flex: 1 }}>
      <Column sx={{ p: 3, borderRight: "small", width: "180px" }}>
        {STATIC_OPTIONS.map(({ label, type }) => {
          const active = type === value.valueType;
          const Icon = STATIC_ICONS[type];

          return (
            <Row
              key={label}
              sx={{
                fontWeight: "semi",
                p: 2,
                borderRadius: 1,
                cursor: "pointer",
                minHeight: "32px",
                alignItems: "center",
                fontSize: 0,
                flex: 0,
                px: 2,
                bg: active ? "avocado" : undefined,
                ":hover": { bg: active ? undefined : "base.0" },
              }}
              onClick={() => onChange({ type: "static", valueType: type })}
            >
              <Icon color="base.5" size={18} sx={{ mr: 2 }} />
              <Text sx={{ color: active ? "forest" : "base.5" }}>{label}</Text>
            </Row>
          );
        })}
      </Column>
      <Column sx={{ flex: 1, p: 3 }}>
        <Row>{getInput()}</Row>
      </Column>
    </Row>
  );
};

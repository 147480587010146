import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const UsersIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M11.9491 14.5399C8.49909 14.5399 5.58813 15.1037 5.58813 17.2794C5.58813 19.4561 8.51789 20 11.9491 20C15.3991 20 18.31 19.4362 18.31 17.2605C18.31 15.0839 15.3803 14.5399 11.9491 14.5399Z" />
      <path
        d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
        opacity="0.4"
      />
      <path
        d="M21.0879 9.21923C21.6923 6.84176 19.9203 4.70654 17.6639 4.70654C17.4186 4.70654 17.184 4.73356 16.9548 4.77949C16.9243 4.78669 16.8903 4.802 16.8724 4.82902C16.8518 4.86324 16.867 4.90917 16.8894 4.93889C17.5672 5.89528 17.9567 7.0597 17.9567 8.30967C17.9567 9.50741 17.5995 10.6241 16.9727 11.5508C16.9082 11.6462 16.9655 11.775 17.0792 11.7948C17.2368 11.8227 17.398 11.8371 17.5627 11.8416C19.2058 11.8849 20.6805 10.8213 21.0879 9.21923Z"
        opacity="0.4"
      />
      <path d="M22.8093 14.817C22.5084 14.1722 21.7823 13.73 20.6782 13.513C20.1571 13.3851 18.7468 13.205 17.4351 13.2293C17.4154 13.232 17.4046 13.2455 17.4028 13.2545C17.4002 13.2671 17.4055 13.2887 17.4315 13.3022C18.0377 13.6039 20.381 14.916 20.0864 17.6834C20.0738 17.8032 20.1696 17.9068 20.2887 17.8888C20.8654 17.8059 22.349 17.4853 22.8093 16.4866C23.0636 15.9589 23.0636 15.3456 22.8093 14.817Z" />
      <path
        d="M7.04483 4.77973C6.8165 4.7329 6.58101 4.70679 6.33567 4.70679C4.07926 4.70679 2.30726 6.84201 2.91255 9.21947C3.31906 10.8216 4.79379 11.8851 6.43685 11.8419C6.60161 11.8374 6.76368 11.8221 6.92037 11.7951C7.03409 11.7753 7.09139 11.6465 7.02692 11.551C6.40014 10.6234 6.04288 9.50765 6.04288 8.30991C6.04288 7.05904 6.43327 5.89462 7.11109 4.93913C7.13258 4.90941 7.1487 4.86348 7.12721 4.82926C7.1093 4.80135 7.07617 4.78694 7.04483 4.77973Z"
        opacity="0.4"
      />
      <path d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z" />
    </Icon>
  );
};

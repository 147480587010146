import { ComponentType, FC, useEffect, useState } from "react";

import { ChevronDownIcon as NewChevronDownIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button as HightouchUiButton,
  ButtonGroup,
  Checkbox as HightouchUiCheckbox,
  Radio,
  RadioGroup as HightouchUiRadioGroup,
  Text as HightouchUiText,
  useToast,
} from "@hightouchio/ui";
import { CurlyBracesIcon, DollarSignIcon } from "lucide-react";
import { Flex, Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { ArrayIcon, ChevronDownIcon, ObjectIcon, TableIcon, TraitIcon, VariableIcon } from "src/ui/icons";
import { Popout } from "src/ui/popout";
import { RadioGroup } from "src/ui/radio";

import { ExtendedOption } from "../../../../formkit";
import { toExtendedOption } from "../../../../formkit/src/api/components/option";
import { LucideArrayIcon } from "./array-icon";
import { ArrayInput } from "./array-input";
import { ArrayPropertiesInput } from "./array-properties-input";
import { ObjectInput } from "./object-input";
import { StandardInput } from "./standard-input";
import { StaticInput } from "./static-input";
import { TemplateInput } from "./template-input";
import {
  FormProps,
  JsonColumnProps,
  Mapping,
  MappingType,
  NEW_STATIC_ICONS,
  Option,
  OverwriteStandardInputWithArrayProps,
  Props,
  SelectedOptionContext,
  STATIC_ICONS,
} from "./types";
import { VariableInput } from "./variable-input";

const isValueEmpty = (value: any): boolean => {
  return (
    (value.type === MappingType.STANDARD && typeof value.from === "undefined") ||
    (value.type === MappingType.VARIABLE && typeof value.variable === "undefined") ||
    (value.type === MappingType.STATIC && typeof value.value === "undefined") ||
    (value.type === MappingType.TEMPLATE && typeof value.template === "undefined") ||
    (value.type === MappingType.OBJECT && typeof value.from === "undefined") ||
    (value.type === MappingType.ARRAY && (typeof value.from === "undefined" || typeof value.children === "undefined"))
  );
};

const INPUTS: Record<
  MappingType,
  FC<
    FormProps & {
      jsonColumnProperties: JsonColumnProps;
      onChangeJsonColumnProperties: React.Dispatch<React.SetStateAction<JsonColumnProps>>;
      selectedOption: ExtendedOption | undefined;
      enableInLineMapper?: boolean;
      onReloadEligibleInlineMapperColumns: (currentSelectedColumn?: string) => void;
    }
  >
> = {
  [MappingType.BOOSTED]: StandardInput,
  [MappingType.STANDARD]: StandardInput,
  [MappingType.STATIC]: StaticInput,
  [MappingType.VARIABLE]: VariableInput,
  [MappingType.TEMPLATE]: TemplateInput,
  [MappingType.OBJECT]: ObjectInput,
  [MappingType.ARRAY]: ArrayInput,
};

export const Mapper: FC<
  Readonly<
    Props & {
      matchBoostingEnabled?: boolean;
      jsonColumnProperties: JsonColumnProps;
      onChangeJsonColumnProperties: React.Dispatch<React.SetStateAction<JsonColumnProps>>;
      onReloadEligibleInlineMapperColumns: (currentSelectedColumn?: string) => void;
      selectedOption: SelectedOptionContext | ExtendedOption | undefined;
      enableInLineMapper?: boolean;
      useHightouchUi?: boolean;
      parentMapping?: Mapping;
      mappingTypes?: MappingType[];
    } & OverwriteStandardInputWithArrayProps
  >
> = ({
  jsonColumnProperties,
  onChangeJsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  isDisabled = false,
  isError,
  onChange,
  enableInLineMapper,
  overwriteColumnsWithArrayProps,
  columnOptions,
  placeholder,
  useHightouchUi,
  selectedOption,
  templates,
  parentMapping,
  matchBoostingEnabled,
  mappingTypes,
  ...props
}) => {
  const [value, setValue] = useState<any>({});
  const { toast } = useToast();

  useEffect(() => {
    // type = 'boosted' is not a valid backend type.
    // re-format the value on the way into the component
    // to have a top level 'boosted' key for the radio group
    if (matchBoostingEnabled && (props.value?.from?.type === "boosted" || !props.value?.from)) {
      setValue({ ...props.value, type: "boosted" });
    } else {
      setValue(props.value);
    }
  }, [props.value, matchBoostingEnabled]);

  let MappingInput;

  if (value.type === MappingType.STANDARD && overwriteColumnsWithArrayProps) {
    MappingInput = ArrayPropertiesInput;
  } else {
    MappingInput = value.type ? INPUTS[value.type] : StandardInput;
  }

  const radioGroupOptions =
    mappingTypes && mappingTypes.length
      ? getMappingTypeOptions(mappingTypes)
      : [
          { label: "Boosted value", value: MappingType.BOOSTED },
          { label: "Column value", value: MappingType.STANDARD },
          { label: "Static value", value: MappingType.STATIC },
          { label: "Variable value", value: MappingType.VARIABLE },
          { label: "Template", value: MappingType.TEMPLATE },
        ];

  const extractedType = toExtendedOption(selectedOption)?.extendedType?.type;

  const shouldEnableArrayInlineMapper =
    (enableInLineMapper && extractedType === "ARRAY") || (enableInLineMapper && !extractedType);

  const shouldEnableObjectInlineMapper =
    (enableInLineMapper && extractedType === "OBJECT") || (enableInLineMapper && !extractedType);

  if (shouldEnableObjectInlineMapper) radioGroupOptions.push({ label: "Create an object", value: MappingType.OBJECT });
  if (shouldEnableArrayInlineMapper) radioGroupOptions.push({ label: "Create an array", value: MappingType.ARRAY });

  const boostingEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.BOOSTED) : true;
  const standardEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.STANDARD) : true;
  const staticEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.STATIC) : true;
  const variableEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.VARIABLE) : true;
  const templateEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.TEMPLATE) : true;
  const objectEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.OBJECT) : true;
  const arrayEnabled = mappingTypes && mappingTypes.length ? mappingTypes.includes(MappingType.ARRAY) : true;
  return (
    <Popout
      content={({ close }) => {
        if (useHightouchUi) {
          return (
            <>
              <Box display="flex" flex="1" minHeight={0}>
                <Box borderColor="gray.300" borderRightWidth="1px" flex="none" p={3} width="180px">
                  <HightouchUiRadioGroup
                    value={value.type}
                    onChange={(type) => {
                      setValue({ type });
                    }}
                  >
                    {matchBoostingEnabled && boostingEnabled && <Radio label="Boosted value" value={MappingType.BOOSTED} />}
                    {standardEnabled && <Radio label="Column value" value={MappingType.STANDARD} />}
                    {staticEnabled && <Radio label="Static value" value={MappingType.STATIC} />}
                    {variableEnabled && <Radio label="Variable value" value={MappingType.VARIABLE} />}
                    {templateEnabled && <Radio label="Template" value={MappingType.TEMPLATE} />}
                    {shouldEnableObjectInlineMapper && objectEnabled && (
                      <Radio label="Create an object" value={MappingType.OBJECT} />
                    )}
                    {shouldEnableArrayInlineMapper && arrayEnabled && (
                      <Radio label="Create an array" value={MappingType.ARRAY} />
                    )}
                  </HightouchUiRadioGroup>
                </Box>

                <MappingInput
                  useHightouchUi
                  columnOptions={columnOptions}
                  jsonColumnProperties={jsonColumnProperties}
                  overwriteColumnsWithArrayProps={overwriteColumnsWithArrayProps}
                  templates={templates}
                  value={value}
                  onChange={setValue}
                  onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                  onReloadEligibleInlineMapperColumns={onReloadEligibleInlineMapperColumns}
                />
              </Box>

              <Box
                alignItems="center"
                borderColor="gray.300"
                borderTopWidth="1px"
                display="flex"
                justifyContent="space-between"
                p={2}
              >
                <Box>
                  {value.type === MappingType.STANDARD && (
                    <HightouchUiCheckbox
                      isChecked={Boolean(value.ignoreNull)}
                      label="Don't sync null values"
                      onChange={(event) => {
                        setValue({
                          ...value,
                          ignoreNull: event.target.checked || undefined,
                        });
                      }}
                    />
                  )}
                </Box>

                <ButtonGroup>
                  <HightouchUiButton onClick={close}>Cancel</HightouchUiButton>
                  <HightouchUiButton
                    variant="primary"
                    onClick={() => {
                      if (value?.type === MappingType.ARRAY && !value?.from) {
                        toast({
                          id: "array-inline-mapper",
                          title: "Please select a column before proceeding.",
                          variant: "error",
                        });
                      } else if (
                        parentMapping?.type === MappingType.ARRAY &&
                        value?.type === MappingType.ARRAY &&
                        parentMapping?.from !== value?.from
                      ) {
                        toast({
                          id: "array-inline-mapper",
                          title: "You must select the same column as the top-level mapping.",
                          variant: "error",
                        });
                      } else {
                        if (value.type === "boosted") {
                          // 'boosted' is not a top level type
                          // the 'boosted' type lives in `value`, so set the
                          // top level type back to 'standard'
                          onChange({ ...value, type: "standard" });
                        } else {
                          onChange(value);
                        }

                        close();
                      }
                    }}
                  >
                    Apply
                  </HightouchUiButton>
                </ButtonGroup>
              </Box>
            </>
          );
        }
        return (
          <>
            <Row sx={{ flex: 1, overflow: "hidden" }}>
              <Column sx={{ p: 3, borderRight: "small", minWidth: "180px" }}>
                <RadioGroup
                  options={radioGroupOptions}
                  size="small"
                  value={value.type}
                  onChange={(type) => setValue({ type })}
                />
              </Column>

              <MappingInput
                columnOptions={columnOptions}
                jsonColumnProperties={jsonColumnProperties}
                overwriteStandardWithArrayProps={overwriteColumnsWithArrayProps}
                templates={templates}
                value={value}
                onChange={setValue}
                onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                onReloadEligibleInlineMapperColumns={onReloadEligibleInlineMapperColumns}
              />
            </Row>
            <Row sx={{ justifyContent: "space-between", alignItems: "center", p: 2, borderTop: "small" }}>
              <Row>
                {value.type === MappingType.STANDARD && (
                  <Checkbox
                    label="Don't sync null values"
                    value={value.ignoreNull}
                    onChange={(ignoreNull) => {
                      setValue({ ...value, ignoreNull: ignoreNull || undefined });
                    }}
                  />
                )}
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Button sx={{ mr: 2 }} variant="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (value?.type === MappingType.ARRAY && !value?.from) {
                      toast({
                        id: "array-inline-mapper",
                        title: "Please select a column before proceeding.",
                        variant: "error",
                      });
                    } else if (
                      parentMapping?.type === MappingType.ARRAY &&
                      value?.type === MappingType.ARRAY &&
                      parentMapping?.from !== value?.from
                    ) {
                      toast({
                        id: "array-inline-mapper",
                        title: "You must select the same column as the top-level mapping.",
                        variant: "error",
                      });
                    } else {
                      onChange(value);
                      close();
                    }
                  }}
                >
                  Apply
                </Button>
              </Row>
            </Row>
          </>
        );
      }}
      contentSx={{
        height: "400px",
        width: useHightouchUi ? "740px" : "700px",
        overflow: "hidden",
      }}
      disabled={isDisabled}
      strategy="fixed"
    >
      {useHightouchUi ? (
        <Box
          _hover={{
            borderColor: isError ? "danger.600" : "gray.500",
          }}
          alignItems="center"
          backgroundColor="white"
          border="1px"
          borderColor={isError ? "danger.600" : "gray.400"}
          borderRadius="md"
          cursor={isDisabled ? "not-allowed" : undefined}
          display="flex"
          height="32px"
          justifyContent="space-between"
          opacity={isDisabled ? 0.4 : undefined}
          px={isValueEmpty(props.value) ? 3 : 1}
          py={1}
          transition="100ms border-color linear"
          width="100%"
        >
          <Value useHightouchUi isError={Boolean(isError)} placeholder={placeholder} value={props.value} />
          <Box as={NewChevronDownIcon} boxSize={4} color="gray.700" />
        </Box>
      ) : (
        <Row
          sx={{
            backgroundColor: "white",
            fontSize: "0",
            alignItems: "center",
            flex: 1,
            border: "small",
            borderColor: isError ? "red" : "base.2",
            borderRadius: 1,
            px: 2,
            height: "32px",
            justifyContent: "space-between",
            cursor: "pointer",
            overflow: "hidden",
            transition: "100ms border-color",
            ":hover": {
              borderColor: isError ? "red" : "base.3",
            },
          }}
        >
          <Value isError={Boolean(isError)} placeholder={placeholder} value={props.value} />
          <ChevronDownIcon color="base.3" />
        </Row>
      )}
    </Popout>
  );
};

const Value: FC<Readonly<{ useHightouchUi?: boolean; isError: boolean; placeholder: string | undefined; value: any }>> = ({
  useHightouchUi,
  isError,
  placeholder,
  value,
}) => {
  let Icon: ComponentType<any> = useHightouchUi ? TableCellsIcon : TableIcon;
  let backgroundColor: string | undefined = useHightouchUi ? "gray.200" : "base.1";

  if (value.from?.type === MappingType.BOOSTED) {
    Icon = SparkleIconYellow;
    backgroundColor = useHightouchUi ? "forest.200" : "primaries.1";
  }

  if (value.type === MappingType.STATIC) {
    Icon = useHightouchUi ? NEW_STATIC_ICONS[value.valueType] : STATIC_ICONS[value.valueType];
    backgroundColor = useHightouchUi ? "forest.200" : "primaries.1";
  }

  if (value.type === MappingType.VARIABLE) {
    Icon = useHightouchUi ? DollarSignIcon : VariableIcon;
    backgroundColor = useHightouchUi ? "warning.200" : "yellows.0";
  }

  if (value.type === MappingType.TEMPLATE) {
    Icon = useHightouchUi ? CurlyBracesIcon : ObjectIcon;
    backgroundColor = useHightouchUi ? "ocean.200" : "blues.0";
  }

  if (value.type === MappingType.OBJECT) {
    Icon = useHightouchUi ? CurlyBracesIcon : ObjectIcon;
    backgroundColor = undefined;
  }

  if (value.type === MappingType.ARRAY) {
    Icon = useHightouchUi ? LucideArrayIcon : ArrayIcon;
    backgroundColor = undefined;
  }

  if (
    (value.type === MappingType.STANDARD && typeof value.from === "undefined") ||
    (value.type === MappingType.VARIABLE && typeof value.variable === "undefined") ||
    (value.type === MappingType.STATIC && typeof value.value === "undefined") ||
    (value.type === MappingType.TEMPLATE && typeof value.template === "undefined")
  ) {
    if (useHightouchUi) {
      return (
        <Box sx={{ span: { color: "gray.500" } }}>
          <HightouchUiText>{placeholder}</HightouchUiText>
        </Box>
      );
    }

    return <Text sx={{ color: "base.4" }}>{placeholder}</Text>;
  }

  if (useHightouchUi) {
    return (
      <Box
        bg={backgroundColor}
        borderRadius="md"
        display="grid"
        gridTemplateColumns="16px 1fr"
        alignItems="center"
        gap={1}
        height="100%"
        maxWidth="90%"
        px={2}
        sx={{ span: { color: value.type === MappingType.OBJECT ? "#03484D" : undefined } }}
      >
        {isCalculatedVisualColumnType(value.from?.type) ? (
          <TraitIcon color="base.5" size={16} sx={{ ml: -0.5 }} />
        ) : (
          <Box as={Icon} boxSize={4} ml={-0.5} />
        )}

        <HightouchUiText isTruncated size={value.type === MappingType.OBJECT || value.type === MappingType.ARRAY ? "md" : "sm"}>
          {formatValue(value)}
        </HightouchUiText>
      </Box>
    );
  }

  return (
    <Row
      sx={{
        alignItems: "center",
        bg: backgroundColor,
        borderColor: isError ? "red" : "base.2",
        borderRadius: 1,
        fontSize: 0,
        fontWeight: "bold",
        px: 1,
        py: "2px",
      }}
    >
      {isCalculatedVisualColumnType(value.from?.type) ? (
        <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
      ) : (
        <Icon color="base.5" size={14} sx={{ mr: 2 }} />
      )}
      <Text
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: value.type === MappingType.OBJECT ? "#03484D" : undefined,
        }}
      >
        {formatValue(value)}
      </Text>
    </Row>
  );
};

const formatValue = (value: any) => {
  if (typeof value === "object") {
    if (value.variable) {
      return value.variable;
    }
    if (value.type === MappingType.OBJECT) {
      return "Object:";
    }
    if (value.type === MappingType.ARRAY) {
      return (
        <Flex
          sx={{
            alignItems: "center",
            flexDirection: "row",
            gap: "3px",
          }}
        >
          <Text
            sx={{
              color: "#03484D",
            }}
          >
            Array:
          </Text>
          <Text
            sx={{
              color: "base.6",
              fontWeight: "400",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value.from || "Pick an array from the list."}
          </Text>
        </Flex>
      );
    }

    if (value.type === MappingType.STANDARD && value.custom) {
      return (
        <Flex
          sx={{
            alignItems: "center",
            flexDirection: "row",
            gap: "3px",
          }}
        >
          <Text>{resolveFromValue(value.from)}</Text>
          <Text
            sx={{
              color: "base.6",
              fontWeight: "300",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            (expected column)
          </Text>
        </Flex>
      );
    }

    if (value.from) {
      return resolveFromValue(value.from);
    }
    if (typeof value.value === "undefined") {
      return value.template;
    } else {
      return String(value.value);
    }
  }
  return value;
};

function isCalculatedVisualColumnType(type: string): boolean {
  return ["additionalColumnReference", "splitTest"].includes(type);
}

export function resolveFromValue(fromValue: any): string {
  if (typeof fromValue === "object") {
    if (fromValue.type === "boosted") {
      return fromValue.semanticType;
    }
    if (isCalculatedVisualColumnType(fromValue.type)) {
      return fromValue.columnAlias;
    }
    if (fromValue.type === "related") {
      return fromValue.column.name;
    }
    if (fromValue.type === "raw") {
      return fromValue.name;
    }
  }

  return fromValue;
}

const SparkleIconYellow = () => (
  <Box ml={-0.5}>
    <SparklesIcon color="#F5C24D" width={16} />
  </Box>
);

function getMappingTypeOptions(types: MappingType[]): Option[] {
  return (
    types.map((type) => {
      switch (type) {
        case MappingType.BOOSTED:
          return { label: "Boosted value", value: MappingType.BOOSTED };
        case MappingType.STANDARD:
          return { label: "Static value", value: MappingType.STATIC };
        case MappingType.STATIC:
          return { label: "Static value", value: MappingType.STATIC };
        case MappingType.VARIABLE:
          return { label: "Variable value", value: MappingType.VARIABLE };
        case MappingType.TEMPLATE:
          return { label: "Template", value: MappingType.TEMPLATE };
        case MappingType.OBJECT:
          return { label: "Create an object", value: MappingType.OBJECT };
        case MappingType.ARRAY:
          return { label: "Create an array", value: MappingType.ARRAY };
        default:
          throw Error("Unexpected MappingType");
      }
    }) || []
  );
}

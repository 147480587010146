import { Fragment, FC } from "react";

import { Alert } from "@hightouchio/ui";
import { Text, ThemeUIStyleObject, Message, Flex } from "theme-ui";

import { colors } from "../../../../design";
import { useUser } from "../../contexts/user-context";
import { getHightouchRegionConfig } from "../../utils/regions";

const commaSeparateComponents = (items: string[], itemSx?: ThemeUIStyleObject) => {
  return items.map((item, index) => (
    <Fragment key={index}>
      {index > 0 && ", "}
      <Text
        sx={{
          display: "inline",
          ...itemSx,
        }}
      >
        {item}
      </Text>
    </Fragment>
  ));
};

const joinIpAddresses = (ipAddresses: string[]): string => {
  return ipAddresses
    .map((ipAddress, index) => {
      const isLast = index === ipAddresses.length - 1;
      return isLast ? `or ${ipAddress}` : ipAddress;
    })
    .join(", ");
};

export const IPWhitelistMessage: FC<Readonly<{ useHightouchUi?: boolean; sx?: ThemeUIStyleObject }>> = ({
  useHightouchUi = false,
  sx = {},
}) => {
  const { region } = useUser();

  if (useHightouchUi) {
    return (
      <Alert
        message={
          <>Hightouch will connect to your source using {joinIpAddresses(getHightouchRegionConfig(region).ipAddresses)}.</>
        }
        title="Make sure to allow inbound traffic from Hightouch IP addresses"
        variant="info"
      />
    );
  }

  return (
    <Message
      sx={{
        width: "unset",
        maxWidth: "unset",
        bg: "base.1",
        borderRadius: 1,
        p: 4,
        border: `1px solid ${colors.base[2]}`,
        ...sx,
      }}
    >
      <Flex>
        <Text color="base.6" sx={{ fontSize: "13px" }}>
          Hightouch will connect to your source using these IP addresses:{" "}
          {commaSeparateComponents(getHightouchRegionConfig(region).ipAddresses, { fontWeight: "bold" })}. Make sure to allow
          inbound traffic from these IP addresses in your firewall.
        </Text>
      </Flex>
    </Message>
  );
};

import { FC, ReactElement, ReactNode } from "react";

import { Box, Row, Column, Text, Spinner } from "@hightouchio/ui";

import { FieldError } from "src/ui/field";
import { ChevronDownIcon } from "src/ui/icons";
import { NewSelect, SelectProps, PlaceholderComponentProps, SelectComponentProps } from "src/ui/new-select";

type Variant = { variant?: "primary" | "secondary" };

export function AttributeSelect<Value>({
  error,
  variant = "primary",
  searchable = true,
  ...props
}: Omit<SelectProps<Value>, "error"> & { error?: ReactNode } & Variant): ReactElement {
  return (
    <Column sx={{ whiteSpace: "nowrap" }}>
      <NewSelect
        {...props}
        searchable={searchable}
        components={{ Value: (props) => <Value variant={variant} {...props} />, Placeholder }}
        error={Boolean(error)}
        sx={{ maxWidth: "fit-content", ...props.sx }}
      />
      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
}

export const Placeholder: FC<PlaceholderComponentProps> = ({ children, disabled, error, isOpen, loading }) => (
  <Row
    _active={{
      border: "1px solid",
      borderColor: "primary.base",
      boxShadow: error ? "outlineRed" : "outline",
    }}
    _hover={{
      borderColor: isOpen ? "primary.base" : "gray.border",
    }}
    border="1px solid"
    borderColor={error ? "danger.border" : isOpen ? "primary.base" : "gray.300"}
    boxShadow={error ? "outlineRed" : isOpen ? "outline" : "none"}
    position="relative"
    alignItems="center"
    px={3}
    borderRadius="6px"
    height="32px"
    justifyContent="space-between"
    transition="all 100ms ease-in"
    bg={disabled ? "base.1" : "base.lightBackground"}
    width="fit-content"
    whiteSpace="nowrap"
  >
    <Text color="text.secondary">{children}</Text>
    <Row ml={2} width="24px" alignItems="center" justifyContent="center">
      {loading ? <Spinner size="sm" /> : <ChevronDownIcon color="text.secondary" />}
    </Row>
  </Row>
);

export const Value: FC<SelectComponentProps & Variant> = ({
  error,
  selectedGroup,
  selectedOption,
  disabled,
  isOpen,
  variant = "primary",
}) => (
  <Row
    _active={{
      border: "1px solid",
      borderColor: "primary",
      boxShadow: error ? "outlineRed" : "outline",
    }}
    _hover={{
      borderColor: isOpen ? "primary.base" : "gray.border",
    }}
    position="relative"
    alignItems="center"
    px={3}
    borderRadius="6px"
    height="32px"
    bg={disabled ? "base.1" : "base.lightBackground"}
    border="1px solid"
    borderColor={error ? "danger.border" : isOpen ? "primary.base" : "gray.300"}
    boxShadow={error ? "outlineRed" : isOpen ? "outline" : "none"}
    color={disabled ? "gray.700" : undefined}
    transition="all 100ms ease-in"
    width="fit-content"
    sx={{
      ...(variant === "secondary"
        ? {
            _hover: {
              bg: "white",
              background: "base.lightBackground",
              borderColor: isOpen ? "primary.base" : "gray.border",
            },
          }
        : {}),
    }}
  >
    {selectedOption?.render ? selectedOption?.render({}) : <Text fontWeight="medium">{selectedOption?.label}</Text>}
    {selectedGroup && (
      <Box ml={2} sx={{ span: { wordBreak: "none" } }}>
        <Text fontWeight="medium" whiteSpace="nowrap" color="text.secondary">
          {selectedGroup}
        </Text>
      </Box>
    )}
  </Row>
);

export type BooleanFunction = typeof BOOLEAN_FUNCTIONS;

function arrayContainsElementWithValueAtPath(props: {
  array: any[];
  value: any;
  path: string;
}): boolean {
  const { array, value, path } = props;
  if (!array || !Array.isArray(array)) {
    return false;
  }
  for (const item of array) {
    if (item[path] === value) {
      return true;
    }
  }
  return false;
}

/**
 * True if value is not in array
 */
function arrayNotIncludes(props?: { value: unknown; array: unknown[] }) {
  return !props?.array?.includes(props.value);
}

/**
 *  True if value matches the pattern.
 */
function stringPatternMatch(props?: { value: unknown; pattern: string }) {
  if (typeof props?.value === "string" && typeof props.pattern === "string")
    return new RegExp(props.pattern).test(props.value);

  return false;
}

export const BOOLEAN_FUNCTIONS = {
  arrayContainsElementWithValueAtPath,
  arrayNotIncludes,
  stringPatternMatch,
} as const;

import { FC, useEffect, useMemo, useState } from "react";

import { Column, Link, Box, Switch, useToast, SectionHeading } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { orderBy } from "lodash";
import pluralize from "pluralize";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Outlet, Route, Routes, useLocation, useOutletContext, useNavigate } from "react-router-dom";
import { Text } from "theme-ui";

import gitSyncImage from "src/components/extensions/assets/git-sync.png";
import { Overview } from "src/components/extensions/overview";
import { FeaturePreview } from "src/components/feature-gates";
import { GitBranchSelector } from "src/components/git/git-branch-selector";
import { GitChecksToggle } from "src/components/git/git-checks-toggle";
import { GitCredentialsFields } from "src/components/git/git-credentials-fields";
import { GitRepositorySelector } from "src/components/git/git-repository-selector";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  GitCredentials,
  GitSyncConfigsQuery,
  ResourcePermissionGrant,
  useCreateGitSyncConfigsMutation,
  useGitCredentialsQuery,
  useGitSyncConfigsQuery,
  useUpdateGitSyncConfigsMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Field } from "src/ui/field";
import { AzureIcon, BitbucketIcon, GitHubIcon, GitIcon, GitlabIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { InfoModal } from "src/ui/modal/info-modal";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { formatDatetime } from "src/utils/time";

// eslint-disable-next-line import/no-absolute-path

import { SyncStatusBadge } from "../../components/extensions/sync-status-badge";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
  Runs = "Runs",
}

const TABS = [Tab.Overview, Tab.Configuration, Tab.Runs];

export const GitSync: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch can write your sync and model configurations to YAML files in a GitHub, Bitbucket, or GitLab repository. This integration is bidirectional, so you can also edit existing resources or create new ones via Git. Use this integration for version control, rolling back changes, or building your own approval flow based on pull/merge requests."
              icon={GitIcon}
              image={gitSyncImage}
              integrations={[
                { name: "GitHub", icon: GitHubIcon },
                { name: "Bitbucket", icon: BitbucketIcon },
                { name: "GitLab", icon: GitlabIcon },
                { name: "Azure", icon: AzureIcon },
              ]}
              subtitle="Manage syncs and models in a Git repo"
              title="Version control with Git"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
        <Route element={<Runs />} path="runs" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : path === "runs" ? Tab.Runs : Tab.Overview;

  const { data: config, isLoading: configLoading } = useGitSyncConfigsQuery(
    {},
    { select: (data) => data.git_sync_configs?.[0], refetchInterval: 3000 },
  );

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(undefined, {
    select: (data) => data.git_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Git sync" }]} title="Git sync - Extensions">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Configuration) {
            navigate("configuration");
          } else if (tab === Tab.Runs) {
            navigate("runs");
          } else {
            navigate("/extensions/git-sync");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ config, credentials, loading: configLoading || credsLoading }} />
    </Page>
  );
};

interface OutletContext {
  config: GitSyncConfigsQuery["git_sync_configs"][0] | undefined;
  credentials: GitCredentials;
  loading: boolean;
}

const Configuration: FC = () => {
  const { config, credentials, loading } = useOutletContext<OutletContext>();
  const { featureFlags, workspace } = useUser();
  const { toast } = useToast();
  const formMethods = useForm();

  const { mutateAsync: create } = useCreateGitSyncConfigsMutation();
  const { mutateAsync: update } = useUpdateGitSyncConfigsMutation();

  const unidirectionalEnabled = Boolean(featureFlags?.unidirection_git_sync);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const fullResync = async () => {
    try {
      await update({
        id: config?.id ? String(config.id) : "",
        object: {
          full_resync: true,
        },
      });

      toast({
        id: "resync-git",
        title: "Resync will begin shortly",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "resync-git",
        title: "There was an error starting a resync",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  const submit = async (data) => {
    try {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credential_id: credentials?.id,
          },
        });
      } else {
        await update({
          id: String(config.id),
          object: data,
        });
      }
      if (!config?.enabled && data.enabled) {
        analytics.track("Git Sync Enabled", {
          workspace_id: workspace?.id,
        });
      }

      if (config?.enabled && !data.enabled) {
        analytics.track("Git Sync Disabled", {
          workspace_id: workspace?.id,
        });
      }

      toast({
        id: "update-git-config",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-git-config",
        title: "Couldn't save your configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      enabled: config?.enabled ?? false,
      checks_enabled: config?.checks_enabled ?? false,
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      path: config?.path ?? "",
    });
  }, [config]);

  const updatePermission = useHasPermission([{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
        <Row sx={{ justifyContent: "space-between" }}>
          <Container center={false} size="small">
            <Column gap={12}>
              <FeaturePreview
                enabled={workspace?.organization?.plan?.sku === "business_tier"}
                featureDetails={{
                  pitch: "Workspace version control managed through your git repo",
                  description:
                    "Hightouch can connect with your git provider (GitHub, GitLab, etc.) to manage, backup, and replicate your syncs and models using a git repo. This lets you manage the lifecycle of your syncs via your existing git workflows.",
                  bullets: [
                    "Bi-directional sync between your Hightouch resources and your git repo",
                    "Changes in your git repo are automatically propagated to Hightouch",
                    "Use .yaml file format to declare the configuration of syncs and model",
                    "Run CI checks on any pull request to detect for breakages",
                  ],
                  image: {
                    src: "https://cdn.sanity.io/images/pwmfmi47/production/0723ac000e333d514b51ab3b847a20ff7301d4dc-1118x450.png",
                  },
                }}
                featureName="git sync"
                variant="full"
              />
              <Column>
                <SectionHeading mb={4}>Status</SectionHeading>
                <Column gap={4}>
                  <Card>
                    <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <GitIcon />
                        <Text sx={{ ml: 2 }}>Git Sync</Text>
                      </Row>
                      <Controller
                        name="enabled"
                        render={({ field }) => (
                          <Box alignItems="center" display="flex" gap={2}>
                            <Text
                              sx={{
                                textTransform: "uppercase",
                                fontSize: "10px",
                                color: "base.4",
                                fontWeight: "bold",
                              }}
                            >
                              {field.value ? "Enabled" : "Disabled"}
                            </Text>

                            <Switch
                              isChecked={field.value}
                              isDisabled={!updatePermission.hasPermission}
                              onChange={field.onChange}
                            />
                          </Box>
                        )}
                      />
                    </Row>
                  </Card>

                  <Card>
                    {!unidirectionalEnabled && (
                      <Column sx={{ mb: 4 }}>
                        <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                          <Text>Hightouch to Git</Text>
                          <SyncStatusBadge
                            error={config?.outbound_error}
                            lastAttemptedAt={config?.last_attempted_at}
                            setup={Boolean(config)}
                          />
                        </Row>
                        <ErrorBlock error={config?.outbound_error} />
                      </Column>
                    )}
                    <Column>
                      <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Text>Git to Hightouch</Text>
                        <SyncStatusBadge
                          error={config?.inbound_error}
                          lastAttemptedAt={!config?.outbound_error ? config?.last_attempted_at : undefined}
                          setup={Boolean(config)}
                        />
                      </Row>
                      <ErrorBlock error={config?.inbound_error} />
                    </Column>
                  </Card>
                  {formMethods.getValues("enabled") && (
                    <Card>
                      <GitChecksToggle
                        credentials={credentials}
                        description="Check your Hightouch Git Sync config files for breaking changes before they are merged."
                      />
                    </Card>
                  )}
                </Column>
              </Column>

              <Column>
                <SectionHeading mb={4}>Configuration</SectionHeading>
                <Column gap={8}>
                  <GitCredentialsFields
                    credentials={credentials}
                    isSetup={Boolean(config?.git_credential?.id)}
                    page="git-sync"
                  />
                  {!credentials || (credentials?.type === "github_app" && !credentials?.credentials) ? null : (
                    <>
                      <GitRepositorySelector credentials={credentials} />
                      <GitBranchSelector credentials={credentials} />
                      <Field optional description="Specify a custom path to look for the sync and model folders." label="Path">
                        <Input placeholder="./hightouch" {...register("path")} />
                      </Field>
                    </>
                  )}
                </Column>
              </Column>
            </Column>
          </Container>

          <SidebarForm
            buttons={
              <Permission>
                <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "240px" }} onClick={handleSubmit(submit)}>
                  Save
                </Button>
                <Button
                  sx={{ width: "240px" }}
                  variant="secondary"
                  onClick={fullResync}
                  disabled={!config?.enabled}
                  tooltip="Push any changes from Hightouch to git and then pull changes from git back to Hightouch"
                >
                  Full Resync
                </Button>
              </Permission>
            }
            docsUrl="syncs/git-sync"
            name="Git sync"
          />
        </Row>
      </PermissionProvider>
    </FormProvider>
  );
};

export const Runs: FC = () => {
  const { config } = useOutletContext<OutletContext>();

  const [inboundChanges, setInboundChanges] = useState<
    GitSyncConfigsQuery["git_sync_configs"][0]["git_inbound_runs"][0]["affected_resources"] | null
  >();
  const [outboundChanges, setOutboundChanges] = useState<
    GitSyncConfigsQuery["git_sync_configs"][0]["git_outbound_runs"][0]["commits"] | null
  >();

  const outboundRuns =
    config?.git_outbound_runs?.map((o) => ({
      createdAt: o.created_at,
      type: "outbound",
      state: o.changelog_id,
      numChanges: o.commits?.length || 0,
      changes: o.commits,
    })) || [];
  const inboundRuns =
    config?.git_inbound_runs?.map((i) => ({
      createdAt: i.created_at,
      type: "inbound",
      state: i.commit,
      numChanges: i.affected_resources.syncs.length + i.affected_resources.models.length || 0,
      changes: i.affected_resources,
    })) || [];

  const rows = orderBy([...outboundRuns, ...inboundRuns], ["createdAt"], ["desc"]);

  const getCommitUrl = (commit: string) => {
    if (config?.repository) {
      const repo = config?.repository.toString();
      const url = repo.endsWith(".git") ? repo.slice(0, -4) : repo;
      return `${url}/commit/${commit}`;
    }
    return "";
  };

  const columns = useMemo(
    () => [
      {
        name: "Type",
        key: "type",
        max: "max-content",
        cell: (type) =>
          type === "inbound" ? <Badge variant="green">Inbound</Badge> : <Badge variant="indigo">Outbound</Badge>,
      },
      {
        name: "Completed",
        key: "createdAt",
        max: "max-content",
        cell: (createdAt) => formatDatetime(createdAt),
      },
      {
        name: "State",
        cell: ({ type, state }) => (
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {type === "inbound" ? <Link href={getCommitUrl(state)}>{`Commit: ${state}`}</Link> : `Changelog ID: ${state}`}
          </Box>
        ),
      },
      {
        name: "Changes",
        max: ".5fr",
        cell: ({ type, numChanges, changes }) => {
          if (numChanges) {
            return (
              <Box
                display="inline-block"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                onClick={() => {
                  if (type === "inbound") {
                    setInboundChanges(changes);
                  } else {
                    setOutboundChanges(changes);
                  }
                }}
              >
                <Link href="">{`${numChanges} ${pluralize("resource", numChanges)} changed`}</Link>
              </Box>
            );
          }
          return "None";
        },
      },
    ],
    [],
  );

  return (
    <>
      <SectionHeading mb={4}>Runs</SectionHeading>

      <Table
        columns={columns}
        data={rows}
        placeholder={{
          title: "No runs",
          body: "Enable syncing to your repository",
          error: "Runs failed to load, please try again.",
        }}
      />

      <InfoModal
        isOpen={Boolean(outboundChanges)}
        title="Git commits"
        width="500px"
        onClose={() => {
          setOutboundChanges(null);
        }}
      >
        <Column gap={2}>
          {outboundChanges?.map((c, i) => (
            <Link key={i} href={getCommitUrl(c)}>
              {getCommitUrl(c)}
            </Link>
          ))}
        </Column>
      </InfoModal>

      <InfoModal
        isOpen={Boolean(inboundChanges)}
        title="Changed resources"
        width="500px"
        onClose={() => {
          setInboundChanges(null);
        }}
      >
        <Column gap={6}>
          {Boolean(inboundChanges?.syncs?.length) && (
            <Field label="Syncs">
              {inboundChanges?.syncs?.map((s) => (
                <Link key={s} href={`/syncs/${s}`}>
                  Sync {s}
                </Link>
              ))}
            </Field>
          )}
          {Boolean(inboundChanges?.models?.length) && (
            <Field label="Models">
              {inboundChanges?.models?.map((m) => (
                <Link key={m} href={`/models/${m}`}>
                  Model {m}
                </Link>
              ))}
            </Field>
          )}
        </Column>
      </InfoModal>
    </>
  );
};

const ErrorBlock = ({ error }: { error: any }) => {
  if (!error) return null;
  return (
    <>
      {error?.fatal && (
        <Message sx={{ width: "100%", maxWidth: "100%", my: 2 }} variant="error">
          Hightouch has detected a fatal error and temporarily disabled git sync. Examples of fatal errors are: your credentials
          may be invalid, you may not have access to this repository, etc.
        </Message>
      )}
      {error?.temp && (
        <Message sx={{ width: "100%", maxWidth: "100%", my: 2 }} variant="warning">
          Hightouch has detected a possible race condition and will attempt to automatically resolve. No action is required.
        </Message>
      )}
      <Text as="pre" sx={{ bg: "base.1", p: 4, wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
        {JSON.stringify(error, null, 2)}
      </Text>
    </>
  );
};

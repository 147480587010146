import { useEffect, useMemo, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Heading, useToast } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";
import { Image } from "theme-ui";

import { SidebarForm } from "src/components/page";
import { SourceForm } from "src/components/sources/setup";
import { SourceCatalog } from "src/components/sources/source-catalog";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { useUser } from "src/contexts/user-context";
import {
  ListSourceTestStepsQueryVariables,
  SourceDefinition,
  useCreateSourceV2Mutation,
  useFormkitSourceDefinitionQuery,
  useSampleDataSourceDefinitionsQuery,
  useSourceDefinitionsQuery,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ButtonProps } from "src/ui/button/button";
import { Field } from "src/ui/field";
import { RepeatIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Step, Wizard } from "src/ui/wizard/wizard";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import * as storage from "src/utils/storage";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type CreateSourceProps = {
  initialSourceDefinition?: SourceDefinition;
  onSubmit: ({ id, definition }: { id: string; definition: SourceDefinition }) => void;
  onCancel: () => void;
  onConnectClick?: (defintion: SourceDefinition) => void;
};

export const CreateSourceWizard = ({
  initialSourceDefinition,
  onSubmit,
  onConnectClick,
  onCancel,
}: Readonly<CreateSourceProps>) => {
  const { toast } = useToast();
  const { user } = useUser();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(ResourceType.Source);
  const [definition, setDefinition] = useState<SourceDefinition>();
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>(false);
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [isContinueModalOpen, setIsContinueModalOpen] = useState<boolean>(false);

  const { appBypassSourceTests } = useFlags();

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();

  const {
    data: { id }, //IDs would exist for sources that are authenticated by oauth, yet can still be incomplete.
  } = useQueryString();

  const [step, setStep] = useWizardStepper(0);

  useSourceQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.connections_by_pk,
      onSuccess: (data) => {
        if (data) {
          setConfig(data.config);
          setDefinition(data.definition);
        }
      },
    },
  );
  const { mutateAsync: createSource, isLoading: creating } = useCreateSourceV2Mutation();
  const { mutateAsync: updateSource, isLoading: updating } = useUpdateSourceV2Mutation();
  const { data: definitions } = useSourceDefinitionsQuery(undefined, { select: (data) => data.getSourceDefinitions });
  const { data: sampleDefinitions } = useSampleDataSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSampleDataSourceDefinitions,
  });
  const { data: formkitMethods, isLoading: loadingFormkit } = useFormkitSourceDefinitionQuery(
    { type: definition?.type || "" },
    { enabled: Boolean(definition), select: (data) => data.formkitSourceDefinition },
  );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return formkitMethods.find((s) => config?.methodKey === s.key)?.method === "oauth";
    }
    return false;
  }, [formkitMethods, config?.methodKey]);

  useEffect(() => {
    if (definition) {
      setConfig({});
      setName(definition.name);
    }
  }, [definition]);

  useEffect(() => {
    if (id) {
      if (storage.load("onboarding")) {
        storage.remove("onboarding");
        navigate(`/onboarding/source/new?id=${id}`, { replace: true });
      }
      if (initialSourceDefinition) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [id]);

  useEffect(() => {
    setDefinition(initialSourceDefinition);
  }, [initialSourceDefinition]);

  const submit = async () => {
    let newId;

    const slug = await getSlug(name);

    if (id) {
      await updateSource({
        id: String(id),
        object: {
          slug,
          config,
          name,
          setup_complete: true,
        },
      });
    } else {
      const { createSourceWithSecrets } = await createSource({
        object: {
          slug,
          name,
          config,
          type: definition?.isSampleDataSource ? "sample-data" : definition?.type,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
          tunnel_id: tunnelId,
          credential_id: credentialId ? String(credentialId) : undefined,
          plan_in_warehouse: lightningEnabled,
          sample_data_source_id: definition?.isSampleDataSource ? definition.type : null,
          plan_in_warehouse_config: lightningEnabled
            ? {
                plannerDatabase,
              }
            : null,
        },
      });
      newId = createSourceWithSecrets?.id.toString();
    }

    const sourceId = String(newId ?? id);

    toast({
      id: "create-source",
      title: `Source "${name}" was created`,
      variant: "success",
    });

    analytics.track("Source Created", {
      source_name: name,
      source_type: definition?.type ?? "",
    });

    onSubmit({ id: sourceId, definition: definition! });
  };

  const variables: ListSourceTestStepsQueryVariables = {
    sourceId: id,
    sourceType: definition?.type,
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled ? { plannerDatabase } : undefined,
  };

  const steps: Step[] = [];

  if (!initialSourceDefinition) {
    steps.push({
      title: "Select source",
      continue: "Click on a source to continue",
      header: (
        <Row
          sx={{
            alignItems: "center",
            gap: 4,
          }}
        >
          <Heading>Select a data source</Heading>
        </Row>
      ),
      render: () => (
        <Column sx={{ gap: 8 }}>
          {sampleDefinitions && definitions && (
            <SourceCatalog
              sampleDefinitions={sampleDefinitions}
              selection={definition}
              definitions={definitions}
              onSelect={(source) => {
                setDefinition(source);
                setStep((step) => step + 1);
              }}
            />
          )}
        </Column>
      ),
    });
  }

  const LIGHTNING_ONLY_TEST_IDS = ["validatePlannerSchemaPermissions", "validateAuditSchemaPermissions"];
  const allNonLightningTestsPassed = testResults?.stepResults
    ?.filter((r) => !LIGHTNING_ONLY_TEST_IDS.includes(r.id))
    .every((r) => r.success);
  const canProgressWithFailedTest =
    testResults?.success === false && allNonLightningTestsPassed && definition?.supportsInWarehouseDiffing;

  if (!definition?.isSampleDataSource) {
    const continueProps: ButtonProps = { form: "source-form", type: "submit" };
    steps.push(
      ...[
        {
          title: `Connect source`,
          loading: loadingFormkit,
          continue: isOAuth && !id ? "Authorize connection to continue" : undefined,
          continueProps,
          disabled: tunnelId === null,
          onContinue: () => {},
          header: (
            <Row sx={{ alignItems: "center", gap: 4 }}>
              <Image src={definition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
              <Heading>{isOAuth ? `Authorize connection to ${definition?.name}` : `Connect to ${definition?.name}`}</Heading>
            </Row>
          ),
          render: () => {
            if (!definition) {
              return null;
            }

            return (
              <Row sx={{ alignItems: "flex-start", gap: 8 }}>
                <Column sx={{ flexGrow: 1, gap: 8 }}>
                  <SourceForm
                    config={config}
                    credentialId={credentialId}
                    definition={definition}
                    disableAuthMethod={Boolean(id)}
                    hasSetupLightning={false}
                    isSetup={true}
                    lightningEnabled={lightningEnabled}
                    plannerDatabase={plannerDatabase}
                    setConfig={setConfig}
                    setCredentialId={setCredentialId}
                    setLightningEnabled={setLightningEnabled}
                    setPlannerDatabase={setPlannerDatabase}
                    setTunnelId={setTunnelId}
                    sourceId={id}
                    tunnelId={tunnelId}
                    onConnectClick={onConnectClick}
                    onSubmit={async () => {
                      await getTestSteps(variables);
                      runTest(variables);
                      setStep(step + 1);
                    }}
                  />
                </Column>
                <SidebarForm docsUrl={definition.docs ?? ""} name={definition.name ?? ""} />
              </Row>
            );
          },
        },
        {
          title: `Test source`,
          disabled: !testResults?.success && !canProgressWithFailedTest && !appBypassSourceTests,
          continueTooltip:
            !testResults?.success && !canProgressWithFailedTest && !appBypassSourceTests
              ? "Cannot continue with setup until tests pass"
              : undefined,
          continueLabel:
            !testResults?.success && canProgressWithFailedTest ? "Continue without Lightning sync engine" : undefined,
          onContinue:
            !testResults?.success && canProgressWithFailedTest
              ? () => {
                  setIsContinueModalOpen(true);
                }
              : undefined,
          actions:
            testResults?.success === false ? (
              <Button
                iconBefore={<RepeatIcon color="gray.700" />}
                label="Test again"
                size="large"
                sx={{ ml: 4 }}
                variant="secondary"
                onClick={async () => {
                  await runTest(variables);
                }}
              />
            ) : undefined,

          header: (
            <Row sx={{ alignItems: "center", gap: 4 }}>
              <Image src={definition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
              <Heading>Test connection to {definition?.name}</Heading>
            </Row>
          ),
          render: () => {
            if (!definition) {
              return null;
            }

            return (
              <>
                <Row sx={{ alignItems: "flex-start", mt: 8 }}>
                  <Column sx={{ flexGrow: 1, gap: 8 }}>
                    <Testing
                      config={config}
                      credentialId={credentialId}
                      isSetup={true}
                      plannerDatabase={plannerDatabase}
                      results={testResults}
                      sourceDefinition={definition}
                      steps={testSteps}
                      timeElapsed={timeElapsed}
                      onContinue={() => {
                        setStep((step) => step + 1);
                      }}
                    />
                  </Column>
                  <SidebarForm docsUrl={definition?.docs ?? ""} name={definition?.name ?? ""} />
                </Row>
                <Modal
                  footer={
                    <>
                      <Button
                        iconBefore={<ArrowLeftIcon width={14} />}
                        variant="secondary"
                        onClick={() => {
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Go back
                      </Button>
                      <Button
                        iconAfter={<ArrowRightIcon color="white" width={14} />}
                        onClick={() => {
                          setPlannerDatabase(undefined);
                          setLightningEnabled(false);
                          setStep((step) => step + 1);
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Continue with read-only Standard sync engine
                      </Button>
                    </>
                  }
                  footerSx={{ justifyContent: "space-between" }}
                  isOpen={isContinueModalOpen}
                  sx={{ maxWidth: 600 }}
                  title="Lightning sync engine will be disabled"
                  onClose={() => {
                    setIsContinueModalOpen(false);
                  }}
                >
                  Hightouch was unable to verify the read and write permissions required by the Lightning sync engine. Please
                  address the failed connection tests or continue with the read-only Standard sync engine.
                </Modal>
              </>
            );
          },
        },
      ],
    );
  }

  steps.push({
    title: "Finalize source",
    submitting: creating || updating,
    disabled: !name,
    header: <Heading>Finalize settings for this source</Heading>,
    render: () => (
      <Column sx={{ gap: 16, maxWidth: "600px" }}>
        <Field
          description="Including details about the source's environment (prod/dev), data contents, and owners"
          label="Source name"
        >
          <Input value={name} onChange={(value) => setName(value)} />
        </Field>
      </Column>
    ),
  });

  return <Wizard steps={steps} step={step} setStep={setStep} title="Create source" onCancel={onCancel} onSubmit={submit} />;
};

import { GoalMetricsQuery } from "src/graphql";

export type MetricDatapoint = Record<string, unknown>;
export type MetricLine = {
  id: string;
  name: string;
};

export type MinimalSplit = {
  id: string;
  name: string;
};

type MetricResult = {
  id: string;
  name: string;
  description?: string;
  canNormalize: boolean;
  lines: MetricLine[];
  splits: MinimalSplit[];
  data: MetricDatapoint[];
};

export function getMetricKey(segmentId: string, splitId: string | null): string {
  if (!splitId) return segmentId;
  return `${segmentId}-${splitId}`;
}

export function useMetricData(
  metric: GoalMetricsQuery["goals"][0] | null,
  isNormalized: boolean,
): {
  metric: MetricResult;
} {
  if (metric === null) {
    return {
      metric: {
        id: "",
        name: "",
        description: "",
        canNormalize: false,
        data: [],
        lines: [],
        splits: [],
      },
    };
  }

  const dataMap = new Map<string, MetricDatapoint>();

  for (const instance of metric.goal_metrics) {
    const value = isNormalized ? instance.metric_value / (instance.audience_contribution_size / 100) : instance.metric_value;
    dataMap.set(instance.calculated_at, {
      ...dataMap.get(instance.calculated_at),
      [getMetricKey(instance.segment_id, instance.split_id)]: value,
      date: instance.calculated_at,
    });
  }

  const data: MetricDatapoint[] = Array.from(dataMap.values());

  const lines: MetricLine[] = [];

  const splits: MinimalSplit[] = [];

  for (const { segment: audience } of metric.audience_goals) {
    if (audience.splits.length) {
      for (const split of audience.splits) {
        lines.push({
          id: getMetricKey(audience.id, split.id),
          name: split.friendly_name,
        });
        splits.push({
          id: split.id,
          name: split.friendly_name,
        });
      }
    } else {
      lines.push({
        id: getMetricKey(audience.id, null),
        name: audience.name,
      });
    }
  }

  return {
    metric: {
      id: metric.id,
      name: metric.name,
      description: metric.description ?? undefined,
      canNormalize: true,
      data,
      lines,
      splits,
    },
  };
}

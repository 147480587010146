import { FC } from "react";

import { Icon, IconProps } from "./icon";

export const CaretDownIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M24 11.305l-7.997 11.39L8 11.305z" />
    </Icon>
  );
};

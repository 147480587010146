import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { DraftOperation, DraftsQuery } from "src/graphql";
import { EyeIcon } from "src/ui/icons";
import { Tooltip } from "src/ui/tooltip";

import { DraftCircle } from "./draft-circle";

type DraftIconProps = { sx?: ThemeUIStyleObject; draft: DraftsQuery["drafts"][0] };

export const DraftIcon: FC<DraftIconProps> = ({ sx = {}, draft }) => {
  const { user } = useUser();

  if (draft.operation === DraftOperation.Create) {
    return null;
  }

  if (draft.approval_requests.some(({ user_id: id }) => String(id) === String(user?.id))) {
    return (
      <Tooltip placement="right" text={`Your approval has been requested on changes to this ${draft.resource_type}.`}>
        <EyeIcon color="yellow" size={16} sx={sx} />
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="right" text={`Draft changes have been made to this ${draft.resource_type}.`}>
      <DraftCircle sx={sx} />
    </Tooltip>
  );
};

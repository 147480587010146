import { useEffect, useState, FC } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Text } from "theme-ui";

import { DraftSubmissionForm, publishNow, saveAndRequestApproval, saveOnly } from "src/components/drafts/draft-submission-form";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  DraftStatus,
  ModelDraft,
  ResourcePermissionGrant,
  ResourceToPermission,
  SyncDraft,
  useDraftsQuery,
  useSubmitDraftModelMutation,
  useSubmitDraftSyncMutation,
  useUpdateDraftsStatusMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

interface DraftSubmissionProps {
  getResourceId: () => string | Promise<string>;
  resource: ResourceToPermission;
  operation: DraftOperation;
  draft: SyncDraft | ModelDraft | undefined;
  open: boolean;
  onClose: () => void;
  onSubmit: (resourceId: string, { publishNow }: { publishNow: boolean }) => void;
  dependentResource?: { resourceId: string; resourceType: ResourceToPermission };
}

export const DraftSubmissionModal: FC<Readonly<DraftSubmissionProps>> = ({
  getResourceId,
  resource,
  operation,
  open,
  onClose,
  onSubmit,
  draft,
  dependentResource,
}) => {
  const { hasPermissions } = useUser();
  const canCreateResource = hasPermissions([
    {
      resource,
      grants: [ResourcePermissionGrant.Approve],
    },
  ]);

  const [comment, setComment] = useState<string>("");
  const { toast } = useToast();
  const { draftChange, editingDraft, setEditingDraft, draft: existingDraft, refetch: refetchDraft } = useDraft();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [error, setError] = useState("");
  const { mutateAsync: submitSync } = useSubmitDraftSyncMutation();
  const { mutateAsync: submitModel } = useSubmitDraftModelMutation();
  const { mutateAsync: updateDraftStatus } = useUpdateDraftsStatusMutation();
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(
    canCreateResource ? publishNow : saveAndRequestApproval,
  );
  const { data: dependentDraft } = useDraftsQuery(
    {
      resourceId: dependentResource?.resourceId.toString() ?? "",
      resourceType: dependentResource?.resourceType ?? "",
      status: "pending",
    },
    {
      enabled: Boolean(dependentResource?.resourceId) && Boolean(dependentResource?.resourceType),
      select: (data) => data?.drafts?.[0],
    },
  );
  const extraDraftIds = dependentDraft ? [dependentDraft.id] : [];

  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    const alreadyRequestedFrom = (existingDraft?.approval_requests.map((request) => request.user_id) as number[]) || [];
    setSelectedUsers(alreadyRequestedFrom);
  }, [existingDraft]);

  useEffect(() => {
    if (!open) {
      setComment("");
      setSelectedRadioOption(canCreateResource ? publishNow : saveAndRequestApproval);
    }
  }, [open]);

  const saveResource = async (resourceId: string) => {
    const draftInput = {
      resourceId: String(resourceId),
      comment,
      approverIds: selectedRadioOption === saveAndRequestApproval ? selectedUsers : [],
      operation,
    };
    try {
      if (resource === ResourceToPermission.Sync) {
        const res = await submitSync({
          ...draftInput,
          draft: draft as SyncDraft,
        });
        return res.submitDraftSync.id;
      } else if (resource === ResourceToPermission.Model) {
        const res = await submitModel({
          ...draftInput,
          draft: draft as ModelDraft,
        });
        return res.submitDraftModel.id;
      }
    } catch (err) {
      toast({
        id: "submit-draft",
        title: "Couldn't submit this draft",
        variant: "error",
      });

      setError(err.message);
      Sentry.captureException(err);
    }

    throw new Error("Invalid resource type");
  };

  const onDelete = async () => {
    const resourceId = await getResourceId();
    const func = resource === ResourceToPermission.Sync ? submitSync : submitModel;
    try {
      // clear the existing draft.
      await func({
        resourceId,
        approverIds: [],
      });
      refetchDraft();
    } catch (err) {
      Sentry.captureException(err);

      toast({
        id: "delete-draft",
        title: "Couldn't delete this draft",
        variant: "error",
      });

      // bubble up the error so we don't show a success toast.
      throw err;
    }

    // replace with the new resource.
    await saveResource(resourceId);
    setEditingDraft(true);
  };

  const title = () => {
    if (canCreateResource) {
      return "Approve & Publish";
    }
    return `This ${resource} requires approval`;
  };

  // If we've already created a draft of this resource, and we started editing the
  // existing resource, then we should show a warning that we're about to delete the existing draft
  // and replace it with a new one.
  if (
    existingDraft &&
    existingDraft.operation !== DraftOperation.Create &&
    !editingDraft &&
    draftChange &&
    Object.keys(draftChange).length > 0
  ) {
    return (
      <DeleteConfirmationModal
        isOpen={open}
        label="draft"
        text="There is an existing draft on this resource. Do you want to delete the existing draft?"
        onClose={onClose}
        onDelete={onDelete}
      />
    );
  }

  const trackAction = (resourceId) => {
    analytics.track(`Draft ${selectedRadioOption}`, {
      resource_id: resourceId,
      resource_type: resource,
      operation,
      num_requested_approvers: selectedUsers.length,
    });
  };

  const handleSave = async () => {
    setSaveLoading(true);

    const resourceId = await getResourceId();

    trackAction(resourceId);

    const draftId = await saveResource(resourceId);

    // If the user wants to publish these changes immediately, we apply the changes by changing the draft status to approved.
    if (selectedRadioOption === publishNow) {
      await updateDraftStatus({ draftIds: [draftId, ...extraDraftIds], status: DraftStatus.Approved });
      refetchDraft();
      setEditingDraft(false);
    } else {
      setEditingDraft(true);
    }

    onSubmit(resourceId, { publishNow: selectedRadioOption === publishNow });

    setSaveLoading(false);
    onClose();
  };

  const saveButtonText =
    selectedRadioOption === publishNow
      ? "Publish"
      : selectedRadioOption === saveOnly
      ? "Save draft"
      : "Save & request approval";

  return (
    <Modal
      bodySx={{ pb: 6 }} // override the default extra padding on the children
      footer={
        <>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            disabled={selectedRadioOption === saveAndRequestApproval && selectedUsers.length === 0}
            loading={saveLoading}
            onClick={handleSave}
          >
            {saveButtonText}
          </Button>
        </>
      }
      isOpen={open}
      sx={{ maxWidth: "600px", width: "100%" }}
      title={title()}
      onClose={onClose}
    >
      <DraftSubmissionForm
        canCreateResource={canCreateResource}
        comment={comment}
        resource={resource}
        selectedRadioOption={selectedRadioOption}
        selectedUsers={selectedUsers}
        setComment={setComment}
        setSelectedRadioOption={setSelectedRadioOption}
        setSelectedUsers={setSelectedUsers}
      />
      {error && <Text sx={{ color: "red", mt: 3 }}>{error}</Text>}
    </Modal>
  );
};

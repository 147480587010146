import { useEffect, useState } from "react";

import qs from "query-string";

export const useQueryString = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, string>>({});

  useEffect(() => {
    const parsed = qs.parse(window.location.search) as Record<string, string>;
    if (parsed) {
      setData(parsed);
    }
    setLoading(false);
  }, []);

  return { data, loading };
};

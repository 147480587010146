import {
  BaseFormkitNode,
  FormkitBoolean,
  FormkitContextReference,
  NodeType,
  FormkitNode,
} from "..";

export type FormkitComponent = BaseFormkitNode & {
  type: NodeType.Component;
  component: ComponentType;
  key: string;
  props: BaseComponentProps & Record<string, any>;
  children?: FormkitNode[];
};

export enum ComponentType {
  Checkbox = "checkbox",
  Switch = "switch",
  Input = "input",
  Mapping = "mapping",
  Mappings = "mappings",
  AssociationMappings = "associationMappings",
  Column = "column",
  ColumnOrConstant = "columnOrConstant",
  RadioGroup = "radioGroup",
  Select = "select",
  Textarea = "textarea",
  Editor = "editor",
  Secret = "secret",
  File = "file",
  Code = "code",
  Message = "message",
  Collapsible = "collapsible",
  Button = "button",
  KeyValueMapping = "keyValueMapping",
  NestedRadioGroup = "nestedRadioGroup",
}

export type BaseComponentProps = {
  value?: any;
  default?: any;
  disable?: FormkitBoolean | FormkitContextReference;

  // XXX: validation should ideally be a Yup.AnySchema | Yup.Lazy, since some components
  // such as COMMON_SCHEMAS.columnOrConstant use Yup.Lazy validation schema, but unfortunately
  // Yup doesn't expose its Lazy class and there's no way to access it otherwise as
  // Yup.AnySchema so we use "any" instead for now

  // XXX: this may be able to be more strongly typed if we can use identical imported types from
  // different node modules - e.g., we define the type with Yup.Lazy | Yup.AnySchema in the formkit
  // directory but Yup validation values are assigned in form.ts files in the api directory
  validation?: any;
};

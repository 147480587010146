import { forwardRef } from "react";

import { Grid, Box, FlexProps } from "theme-ui";

type RowProps = {
  gap?: number;
};

type Props = RowProps & FlexProps;

export const Row = forwardRef<HTMLDivElement, Props>(({ gap, children, sx = {}, ...props }, ref) => {
  if (gap) {
    return (
      <Grid
        gap={gap}
        sx={{ gridAutoColumns: "max-content", gridAutoFlow: "column", alignItems: "center", ...sx }}
        {...props}
        ref={ref}
      >
        {children}
      </Grid>
    );
  }

  return (
    <Box sx={{ display: "flex", ...sx }} {...props} ref={ref}>
      {children}
    </Box>
  );
});

Row.displayName = "Row";

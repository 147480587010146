import { useState, FC, useMemo } from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import { Box, Button, FormField, TextInput, Select, Paragraph, Text, ButtonGroup, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { useUser } from "src/contexts/user-context";
import { InitialQuery, useSendInviteMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { roleDisabled } from "src/pages/settings/roles";
import { Modal } from "src/ui/modal";
import { InfoModal } from "src/ui/modal/info-modal";

interface InviteFormProps {
  name: string;
  close: () => void;
  open: boolean;
}

type Role = InitialQuery["workspaces"][number]["roles"][number];

type InviteFormFields = {
  email: string;
  roleId: number;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  roleId: Yup.number().required("Role is required"),
});

export const InviteFormModal: FC<Readonly<InviteFormProps>> = ({ name, close, open }) => {
  const { workspace } = useUser();
  const location = useLocation();
  const [invited, setInvited] = useState<boolean>(false);
  const { mutateAsync: sendInvite } = useSendInviteMutation();
  const { toast } = useToast();

  const { authDisableNonSso, appAllowRoles } = useFlags();

  const roles = useMemo<Role[]>(() => {
    return [...(workspace?.roles ?? [])].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1));
  }, [workspace?.roles]);

  const defaultFormValues: InviteFormFields = {
    email: "",
    roleId: workspace?.default_role_id,
  };

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, isSubmitting },
  } = useForm<InviteFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const handleFormSuccess = async (data: InviteFormFields) => {
    if (!data.email || !data.roleId) {
      return;
    }

    await sendInvite({ recipientEmail: data.email, recipientRoleID: data.roleId });
    setInvited(true);
    analytics.track("Teammate Invited", {
      workspace_id: workspace?.id,
      workspace_slug: workspace?.slug,
      origin_page: location.pathname,
    });
  };

  const handleFormErrors = () => {
    toast({
      id: "invite-error",
      title: "Something went wrong",
      message: "Please ensure your invite details are correct",
      variant: "error",
    });
  };

  const handleClose = () => {
    close();
    setInvited(false);
    reset(defaultFormValues);
  };

  if (authDisableNonSso) {
    return (
      <InfoModal isOpen={open} title={`Invite a teammate to ${name}`} width="441px" onClose={handleClose}>
        <Paragraph>
          Inviting teammates by email is disabled in this workspace. Please use the SSO identity provider connected to your
          organization.
        </Paragraph>
      </InfoModal>
    );
  }

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        invited ? (
          <ButtonGroup>
            <Button
              onClick={() => {
                setInvited(false);
                reset(defaultFormValues);
              }}
            >
              Invite more people
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Done
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              isDisabled={!isDirty}
              isLoading={isSubmitting}
              variant="primary"
              onClick={handleSubmit(handleFormSuccess, handleFormErrors)}
            >
              Send invitation
            </Button>
          </ButtonGroup>
        )
      }
      isOpen={open}
      sx={{ maxWidth: "441px", width: "100%" }}
      title={`Invite a teammate to ${name}`}
      onClose={handleClose}
    >
      {invited ? (
        <Box>
          <Box
            alignItems="center"
            bg="grass.200"
            borderRadius="full"
            boxSize={14}
            display="flex"
            justifyContent="center"
            mx="auto"
          >
            <Box as={CheckIcon} boxSize={8} color="grass.700" />
          </Box>

          <Box maxWidth="sm" mt={2} mx="auto">
            <Paragraph textAlign="center">
              You invited <Text fontWeight="semibold">{getValues("email")}</Text> to <Text fontWeight="semibold">{name}</Text>.
            </Paragraph>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={6}>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <FormField error={error?.message} label="Email">
                <TextInput
                  {...field}
                  isInvalid={Boolean(error?.message)}
                  placeholder="jane@acme.com"
                  type="email"
                  onChange={(event) => field.onChange(event.target.value.trim())}
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name="roleId"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Depending on your role selection, new users may be able to create workspaces and invite other team members."
                error={error?.message}
                label="Role"
              >
                <Select
                  {...field}
                  isOptionDisabled={(role) => roleDisabled(appAllowRoles, workspace, role)}
                  optionLabel={(role) => role.name}
                  optionValue={(role) => role.id}
                  options={roles}
                  placeholder="Select role for this user..."
                  width="3xs"
                />
              </FormField>
            )}
          />
        </Box>
      )}
    </Modal>
  );
};

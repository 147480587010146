import { createContext, useContext, useState, FC, ReactNode } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import pluralize from "pluralize";
import { To, useNavigate } from "react-router-dom";
import { noop } from "ts-essentials";

import { AudienceEventTraitForm } from "src/components/audiences/audience-event-trait-form";
import { AudienceTraitForm } from "src/components/audiences/audience-trait-form";
import { useRelatedAudiencesQuery, useUpdateAudienceMutation } from "src/graphql";
import {
  AdditionalColumn,
  Audience,
  EventCondition,
  TraitCondition,
  isTraitCondition,
  AudienceParent,
  Relationship,
  TraitDefinition,
  FilterableColumn,
} from "src/types/visual";

type Props = {
  audience?: Audience;
  parent?: AudienceParent | null;
  children: ReactNode;
  columns?: FilterableColumn[];
};

type Requirement = { condition: "related model" | "event" | "audience"; to: To };

const STATIC_ARRAY = [];

export type QueryBuilderContextType = {
  columns: FilterableColumn[];
  events: Relationship[];
  relatedAudiences: { label: string; value: string }[];
  relationships: Relationship[];
  traits: TraitDefinition[];
  selectedCondition: TraitCondition | EventCondition | null;
  selectCondition: (condition: TraitCondition | EventCondition | null) => void;
};

const defaultContextValue: QueryBuilderContextType = {
  columns: [],
  events: [],
  relatedAudiences: [],
  relationships: [],
  traits: [],
  selectedCondition: null,
  selectCondition: noop,
};

export const QueryBuilderContext = createContext<QueryBuilderContextType>(defaultContextValue);

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);

export const QueryBuilderProvider: FC<Readonly<Props>> = ({ audience, children, columns: overrideColumns, parent }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const relatedAudiencesQuery = useRelatedAudiencesQuery(
    {
      parentModelId: parent?.id,
      modelId: audience?.id,
    },
    { enabled: Boolean(parent) },
  );

  const relationships = parent?.relationships;
  const traits = parent?.traits;
  const columns = overrideColumns ?? parent?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));
  const relatedAudiences =
    relatedAudiencesQuery.data?.segments.map(({ id, name }) => ({ value: id as string, label: name })) ?? [];

  const { mutateAsync: updateAudience } = useUpdateAudienceMutation({
    onSuccess: () => {
      // prevents audience query from invalidating
    },
  });

  // Used for adding additional columns
  const [selectedCondition, setSelectedCondition] = useState<TraitCondition | EventCondition | null>(null);
  const [missingRequirement, setMissingRequirement] = useState<Requirement | undefined>();

  const selectCondition = (condition: TraitCondition | EventCondition | null) => setSelectedCondition(condition);

  const addAdditionalColumn = async (additionalColumn: AdditionalColumn) => {
    if (!audience) {
      return;
    }

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: [additionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
        },
      },
    });

    toast({
      id: "add-trait",
      title: "Trait was added",
      variant: "success",
    });
  };

  const parentModel = parent || audience?.parent;

  return (
    <QueryBuilderContext.Provider
      value={{
        columns: columns ?? STATIC_ARRAY,
        events: events ?? STATIC_ARRAY,
        relationships: relationships ?? STATIC_ARRAY,
        traits: traits ?? STATIC_ARRAY,
        relatedAudiences,
        selectedCondition,
        selectCondition,
      }}
    >
      {children}

      <ConfirmationDialog
        confirmButtonText="Go to set up"
        isOpen={Boolean(missingRequirement)}
        title={`No ${pluralize(missingRequirement?.condition ?? "objects")} found`}
        variant="warning"
        onClose={() => setMissingRequirement(undefined)}
        onConfirm={() => navigate(missingRequirement?.to ?? "")}
      >
        <Paragraph>
          In order to use this condition you need set up {missingRequirement?.condition === "event" ? "an" : "a"}{" "}
          {missingRequirement?.condition}. Would you like to create one now?
        </Paragraph>
      </ConfirmationDialog>

      {selectedCondition &&
        (isTraitCondition(selectedCondition) ? (
          <AudienceTraitForm
            alias=""
            audience={audience}
            conditions={selectedCondition.property.column.conditions}
            parent={parentModel}
            title="Add trait"
            trait={parentModel?.traits?.find((t) => t.id === selectedCondition.property.column.traitDefinitionId)}
            onClose={() => {
              selectCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ) : (
          <AudienceEventTraitForm
            condition={selectedCondition}
            parent={parentModel}
            title="Add event trait"
            onClose={() => {
              selectCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ))}
    </QueryBuilderContext.Provider>
  );
};

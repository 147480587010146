import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const HeartIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M11.776 21.8374C9.49298 20.4273 7.37068 18.7645 5.44795 16.8796C4.09056 15.5338 3.05392 13.8905 2.41722 12.0753C1.27959 8.53523 2.60387 4.48948 6.30117 3.2884C8.25268 2.67553 10.3751 3.05175 12.0071 4.29983C13.6397 3.05315 15.7614 2.67705 17.713 3.2884C21.4103 4.48948 22.7435 8.53523 21.6059 12.0753C20.9744 13.8888 19.9439 15.5319 18.5929 16.8796C16.6684 18.7625 14.5464 20.4251 12.2649 21.8374L12.016 22L11.776 21.8374Z"
        opacity="0.4"
      />
      <path d="M12.0109 22.0001L11.776 21.8375C9.49013 20.4275 7.36487 18.7648 5.43902 16.8797C4.0752 15.5357 3.03238 13.8923 2.39052 12.0754C1.26177 8.53532 2.58605 4.48957 6.28335 3.28849C8.23486 2.67562 10.3853 3.05213 12.0109 4.31067V22.0001Z" />
      <path d="M18.2304 9.99922C18.0296 9.98629 17.8425 9.8859 17.7131 9.72157C17.5836 9.55723 17.5232 9.3434 17.5459 9.13016C17.5677 8.4278 17.168 7.78851 16.5517 7.53977C16.1609 7.43309 15.9243 7.00987 16.022 6.59249C16.1148 6.18182 16.4993 5.92647 16.8858 6.0189C16.9346 6.027 16.9816 6.04468 17.0244 6.07105C18.2601 6.54658 19.0601 7.82641 18.9965 9.22576C18.9944 9.43785 18.9117 9.63998 18.7673 9.78581C18.6229 9.93164 18.4291 10.0087 18.2304 9.99922Z" />
    </Icon>
  );
};

import { useState, useRef } from "react";

import { Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, useDisclosure } from "@chakra-ui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Row, Column, IconButton, SearchInput, Spinner, Box, Heading, Text } from "@hightouchio/ui";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { getSchemaModelType } from "src/components/audiences/utils";
import { NAV_EXPANDED_WIDTH } from "src/components/layout/constants";
import { useAudienceSchemaSearchQuery } from "src/graphql";
import { getParams, schemaIcons } from "src/pages/schema/utils";

export const Search = () => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, { isPending }] = useDebounce(search, 1000);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { source, queryString } = getParams();
  const searchRef = useRef<HTMLInputElement>(null);

  const close = () => {
    onClose();
    setSearch("");
  };

  const { data: results, isFetching } = useAudienceSchemaSearchQuery(
    { search: `%${debouncedSearch}%`, sourceId: String(source) },
    { select: (data) => data.segments },
  );

  const isPlaceholder = !results?.length;
  const isSpinner = search && (isFetching || isPending());

  return (
    <>
      <Box sx={{ button: { width: "40px", height: "40px" } }}>
        <IconButton aria-label="Search" variant="secondary" icon={MagnifyingGlassIcon} onClick={onOpen} />
      </Box>

      <Modal isOpen={isOpen} onClose={close} scrollBehavior="inside" initialFocusRef={searchRef}>
        <ModalOverlay />
        <ModalContent
          height="50%"
          maxHeight="500px"
          display="flex"
          flexDirection="column"
          p={0}
          left={`${NAV_EXPANDED_WIDTH / 2}px`}
        >
          <ModalHeader>
            <Row p={4} borderBottom="1px" borderColor="base.border" gap={4}>
              <SearchInput
                ref={searchRef}
                width="100%"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Search by name..."
              />
            </Row>
          </ModalHeader>
          <ModalBody
            m={0}
            flex={1}
            display="flex"
            flexDir="column"
            alignItems={isSpinner || isPlaceholder ? "center" : undefined}
            justifyContent={isSpinner || isPlaceholder ? "center" : undefined}
          >
            {isSpinner ? (
              <Spinner size="lg" />
            ) : isPlaceholder ? (
              <Column p={4} gap={4} align="center" justify="center">
                <Box as="img" alt="Search placeholder." src={searchPlaceholder} height="128px" />
                <Heading>No models found</Heading>
                <Text color="text.secondary">There are no models with a name matching your search</Text>
              </Column>
            ) : (
              results.map((result) => {
                const type = getSchemaModelType(result);
                return (
                  <Link
                    key={result.id}
                    to={`/schema-v2/view${queryString}&id=${result.id}`}
                    style={{ display: "contents" }}
                    onClick={close}
                  >
                    <Row
                      width="100%"
                      px={4}
                      py={2}
                      as="button"
                      _hover={{ bg: "forest.100" }}
                      _focus={{ bg: "forest.200", outline: "none" }}
                      align="center"
                      gap={4}
                    >
                      <Box as="img" src={schemaIcons[type]} alt={type} height="24px" />
                      <Text isTruncated>{result.name}</Text>
                    </Row>
                  </Link>
                );
              })
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

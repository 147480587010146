import { Validator, validator as baseValidator, ValidatorSync } from "./base";
import { array, ArrayType, validator as arrayValidator } from "./array";
import { boolean, BooleanType, validator as booleanValidator } from "./boolean";
import {
  DateType,
  DateTimeType,
  date,
  datetime,
  validator as dateValidator,
  DATE_FORMATS,
} from "./date";
import { email, EmailType, validator as emailValidator } from "./email";
import { EnumType, _enum, validator as enumValidator } from "./enum";
import { number, NumberType, validator as numberValidator } from "./number";
import { object, ObjectType, validator as objectValidator } from "./object";
import { phone, PhoneType, validator as phoneValidator } from "./phone";
import {
  reference,
  ReferenceType,
  validator as referenceValidator,
} from "./reference";
import { string, StringType, validator as stringValidator } from "./string";
import { union, UnionType, validator as unionValidator } from "./union";
import { url, UrlType, validator as urlValidator } from "./url";
import { unknown, UnknownType, validator as unknownValidator } from "./unknown";

export { DATE_FORMATS };

export type ExtendedTypes = ExtendedTypesAsync | ExtendedTypesSync;

/**
 * Asyncrounous extended types.
 * These types contains metadata needed to fetch remote contexts
 * for validation, data coersion, etc.
 */
export type ExtendedTypesAsync = ReferenceType;

/**
 * Non-async extended types.
 */
export type ExtendedTypesSync =
  | ArrayType
  | BooleanType
  | DateType
  | DateTimeType
  | EmailType
  | EnumType
  | NumberType
  | ObjectType
  | PhoneType
  | StringType
  | UnionType
  | UrlType
  | UnknownType;

export const ExtendedType = {
  number,
  string,
  boolean,
  email,
  url,
  phone,
  enum: _enum,
  reference,
  date,
  datetime,
  array,
  object,
  union,
  unknown,
} as const;

const syncValidationMap: Record<
  ExtendedTypesSync["type"],
  ValidatorSync<any>
> = {
  ARRAY: arrayValidator,
  BOOLEAN: booleanValidator,
  DATE: dateValidator,
  DATETIME: dateValidator,
  EMAIL: emailValidator,
  ENUM: enumValidator,
  NUMBER: numberValidator,
  OBJECT: objectValidator,
  PHONE: phoneValidator,
  STRING: stringValidator,
  UNION: unionValidator,
  UNKNOWN: unknownValidator,
  URL: urlValidator,
};

const asyncValidationMap: Record<ExtendedTypesAsync["type"], Validator<any>> = {
  REFERENCE: referenceValidator,
};

const noop = (..._: any[]) => {
  //
};

/**
 * Performs syncronous validation. Will skip validations on asynchronous extended types.
 * @param type synchrounous extended types
 * @returns a synchronous validate function
 */
export const validatorSync: ValidatorSync<ExtendedTypes> = (type) => {
  const baseTypeValidation = baseValidator(type);
  const typeValidator = syncValidationMap[type.type]
    ? syncValidationMap[type.type](type)
    : noop; // Skip validations. on asyncronous extended types.

  return (value) => {
    // Don't bother to validate if value is null or undefined.
    if (!type.required && value === undefined) return;
    if (!type.notNull && value === null) return;

    baseTypeValidation(value);
    typeValidator(value);
  };
};

/**
 * Validator for extended field types.
 * @param type extended type
 * @returns an asynchrounous validate function.
 */
export const validator: Validator<ExtendedTypes> = (type) => {
  const baseTypeValidation = baseValidator(type);
  const typeValidator = (
    syncValidationMap[type.type] || asyncValidationMap[type.type]
  )?.(type);

  if (!typeValidator)
    //  This is likely a bug.
    throw new Error(
      `Unable to construct validator. Invalid ExtendedType: ${type.type}.`,
    );

  return async (value) => {
    // Don't bother to validate if value is null or undefined.
    if (!type.required && value === undefined) return;
    if (!type.notNull && value === null) return;

    baseTypeValidation(value);
    await typeValidator(value);
  };
};

export const validate = (type: ExtendedTypesSync, value: unknown) => {
  validatorSync(type)(value);
};

export const typeGuard = <T extends ExtendedTypesSync>(type: T) => {
  const validate = validatorSync(type);

  return (value: unknown): value is Infer<T> => {
    try {
      validate(value);
      return true;
    } catch (_) {
      return false;
    }
  };
};

export const isType = <T extends ExtendedTypesSync>(
  type: T,
  value: unknown,
): value is Infer<T> => {
  return typeGuard(type)(value);
};

export type Infer<T extends ExtendedTypes> =
  | InferenceMap[T["type"]]
  | null
  | undefined;

type InferenceMap = {
  ARRAY: unknown[];
  BOOLEAN: boolean;
  DATE: string | Date;
  DATETIME: string | Date;
  EMAIL: string;
  ENUM: string | number;
  NUMBER: number;
  OBJECT: { [k: string]: unknown };
  PHONE: string;
  REFERENCE: string;
  STRING: string;
  UNION: unknown;
  UNKNOWN: unknown;
  URL: string;
  [k: string]: unknown;
};

export const isExtendedTypes = (value: unknown): value is ExtendedTypes => {
  return Boolean(
    value &&
      typeof value === "object" &&
      { ...syncValidationMap, ...asyncValidationMap }[value["type"]],
  );
};

import { useState } from "react";

/**
 * A hook that manages the focus of elements in a list with keyboard
 * arrows.
 *
 * Note: This hook does not actually 'focus' the element. It only calculates the
 * index of the item that should be focused.
 */
export const useArrowKeyFocus = (itemLength: number): [number | null, (filterIndex: number | null) => void] => {
  const [focusedFilterIndex, setFocusedFilterIndex] = useState<null | number>(null);

  const focusFilter = (filterIndex: number | null) => {
    switch (filterIndex) {
      case null:
        setFocusedFilterIndex(null);
        break;
      case -1:
        setFocusedFilterIndex(itemLength - 1);
        break;
      default:
        setFocusedFilterIndex(filterIndex % itemLength);
    }
  };

  return [focusedFilterIndex, focusFilter];
};

import { FC } from "react";

import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("upsert"),
  object: Yup.string().required().default("people"),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  deleteMode: Yup.string().notRequired(),
});

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const OBJECTS = [{ label: "People", value: "people" }];

const PEOPLE_PROPERTIES = [
  "email",
  "firstName",
  "lastName",
  "company",
  "city",
  "state",
  "country",
  "title",
  "phone",
  "linkedInProfile",
  "companySize",
  "industry",
];

export const ReplyioForm: FC = () => {
  const { config, setConfig } = useDestinationForm();
  const staticMapperOpts = PEOPLE_PROPERTIES.map((property) => ({ label: property, value: property }));

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField options={MODES} onChange={(mode) => setConfig({ object: config?.object, mode })} />

      <Section>
        <IdMappingField options={[{ label: "email", value: "email" }]} />
      </Section>

      <Section>
        <MappingsField options={staticMapperOpts || []} />
      </Section>

      {config?.mode === "upsert" && <DeleteField modes={["delete"]} />}
    </>
  );
};

export default {
  form: ReplyioForm,
  validation,
};

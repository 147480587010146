import { ReactNode, FC, useCallback, useRef } from "react";

import { Row, Text } from "@hightouchio/ui";

type Props = {
  selected: boolean;
  onClick: () => void;
  icon: ReactNode;
  children: ReactNode;
};

export const SelectorRow: FC<Readonly<Props>> = ({ selected, onClick, icon, children }) => {
  const initialSelected = useRef(selected);

  const scrollToActive = useCallback((node) => {
    if (node && initialSelected.current) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Row
      ref={selected ? scrollToActive : undefined}
      sx={{
        borderLeft: `2px solid ${selected ? "var(--chakra-colors-forest-base)" : "transparent"}`,
        alignItems: "center",
        borderBottom: "1px solid var(--chakra-colors-base-border)",
        py: 2,
        pl: 3,
        gap: 2,
        bg: selected ? "primary.background" : undefined,
        cursor: "pointer",
        svg: {
          fill: selected ? "forest.base" : undefined,
        },
        ":hover": {
          bg: selected ? undefined : "gray.100",
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Text>{children}</Text>
    </Row>
  );
};

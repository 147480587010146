import { FC, useState } from "react";

import { Column, Button, Checkbox, CheckboxGroup, FormField, Select, TextInput } from "@hightouchio/ui";

import { alertTypes } from "src/components/alerts";
import {
  Alert,
  AlertTypes,
  isPagerdutyAlert,
  isSlackAlert,
  isSuccessAlert,
  isSuccessAlertType,
} from "src/components/alerts/types";
import { useUser } from "src/contexts/user-context";
import { AlertingCredentialsQuery, useCreateWorkspaceAlertMutation, useUpdateWorkspaceAlertMutation } from "src/graphql";
import { Modal } from "src/ui/modal";

const isComplete = (alert) => {
  const base = alert?.name && alert?.type;

  const validator = alertTypes?.[alert?.type]?.validator;
  const platform = !validator || (validator && validator(alert));

  return base && platform;
};

interface Props {
  onClose: () => void;
  alert: Alert;
  availableCredentials: AlertingCredentialsQuery;
}

export const EditAlert: FC<Props> = ({ onClose, alert: _alert, availableCredentials }) => {
  const [alert, setAlert] = useState<Alert>({
    id: _alert?.id,
    name: _alert?.name,
    type: _alert?.type,
    fatal_error_default: _alert?.fatal_error_default,
    row_error_default: _alert?.row_error_default,
    on_success_default: isSuccessAlert(_alert) ? _alert.on_success_default : undefined,
    config: _alert?.config,
    slack_credential_id: isSlackAlert(_alert) ? _alert.slack_credential_id : undefined,
    pagerduty_credential_id: isPagerdutyAlert(_alert) ? _alert.pagerduty_credential_id : undefined,
    alert_interval: _alert?.alert_interval,
  });

  const { isLoading: updating, mutateAsync: updateAlert } = useUpdateWorkspaceAlertMutation();

  return (
    <Modal
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={!isComplete(alert)}
            isLoading={updating}
            variant="primary"
            onClick={async () => {
              if (isSuccessAlertType(alert.type)) {
                alert;
              }
              if (!alert.id) {
                return;
              }
              await updateAlert({
                id: String(_alert?.id),
                input: {
                  name: alert?.name,
                  fatal_error_default: alert?.fatal_error_default,
                  row_error_default: alert?.row_error_default,
                  on_success_default:
                    isSuccessAlertType(alert.type) && "on_success_default" in alert ? !!alert?.on_success_default : null,
                  config: alert?.config,
                  slack_credential_id: isSlackAlert(alert) ? alert?.slack_credential_id : undefined,
                  pagerduty_credential_id: isPagerdutyAlert(alert) ? alert?.pagerduty_credential_id : undefined,
                  alert_interval: alert?.alert_interval ? String(alert?.alert_interval) : null,
                },
              });
              onClose();
            }}
          >
            Save
          </Button>
        </>
      }
      sx={{ maxWidth: "800px", width: "100%" }}
      title="Edit alert"
      onClose={onClose}
    >
      <AlertForm alert={alert} availableCredentials={availableCredentials} edit={true} setAlert={setAlert} />
    </Modal>
  );
};

export const CreateAlert: FC<Omit<Props, "alert">> = ({ onClose, availableCredentials }) => {
  const { user } = useUser();
  const [alert, setAlert] = useState<any>({
    config: {},
  });

  const { isLoading: creating, mutateAsync: createAlert } = useCreateWorkspaceAlertMutation();

  return (
    <Modal
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={!isComplete(alert)}
            isLoading={creating}
            variant="primary"
            onClick={async () => {
              await createAlert({
                input: {
                  name: alert?.name,
                  type: alert?.type,
                  fatal_error_default: alert?.fatal_error_default,
                  row_error_default: alert?.row_error_default,
                  on_success_default: alert?.on_success_default,
                  config: alert?.config,
                  slack_credential_id: isSlackAlert(alert) ? alert?.slack_credential_id : undefined,
                  pagerduty_credential_id: isPagerdutyAlert(alert) ? alert?.pagerduty_credential_id : undefined,
                  alert_interval: alert?.alert_interval ? String(alert?.alert_interval) : null,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                },
              });
              onClose();
            }}
          >
            Create
          </Button>
        </>
      }
      sx={{ maxWidth: "800px", width: "100%" }}
      title="Create alert"
      onClose={onClose}
    >
      <AlertForm alert={alert} availableCredentials={availableCredentials} setAlert={setAlert} />
    </Modal>
  );
};

interface AlertFormProps {
  edit?: boolean;
  availableCredentials: AlertingCredentialsQuery;
  alert: Partial<Alert>;
  setAlert: React.Dispatch<React.SetStateAction<any>>;
}

const AlertForm: FC<AlertFormProps> = ({ alert, setAlert, edit = false, availableCredentials }) => {
  const ConfigForm = alert?.type ? alertTypes[alert.type].form : null;
  return (
    <Column gap={6}>
      <FormField label="Name">
        <TextInput
          placeholder="Enter a name..."
          value={alert?.name || ""}
          onChange={(event) => {
            !!event.target.value && setAlert({ ...alert, name: event.target.value });
          }}
        />
      </FormField>
      {!edit && (
        <FormField label="Platform">
          <Select
            isOptionDisabled={(option) =>
              (option[0] === AlertTypes.Slack && !availableCredentials.slack_credentials.length) ||
              (option[0] === AlertTypes.PagerDuty && !availableCredentials.pagerduty_credentials.length)
            }
            // Disable pagerduty or slack if no credentials are available
            optionAccessory={(object) => ({ type: "image", url: object[1].icon })}
            optionLabel={(option) => option[1].name}
            optionValue={(option) => option[0] as AlertTypes}
            options={Object.entries(alertTypes)}
            value={alert?.type}
            onChange={(selected) => {
              if (!selected) {
                return;
              }
              if (selected !== alert?.type) {
                setAlert({ ...alert, type: selected, config: {} });
              } else {
                setAlert({ ...alert, type: selected });
              }
            }}
          />
        </FormField>
      )}
      {ConfigForm && (
        <ConfigForm
          alert={alert}
          config={alert?.config || {}}
          setAlert={setAlert}
          setConfig={(config) => {
            setAlert({ ...alert, config });
          }}
        />
      )}
      <FormField
        description="Enabling the default behavior will apply this alert to all syncs for the specified alert condition."
        label="Default behaviors"
      >
        <CheckboxGroup>
          <Checkbox
            isChecked={Boolean(alert?.on_success_default)}
            isDisabled={alert?.type ? !isSuccessAlertType(alert.type) : false}
            label="Trigger on sync success"
            onChange={(event) => {
              setAlert({ ...alert, on_success_default: event.target.checked });
            }}
          />
          <Checkbox
            isChecked={Boolean(alert?.fatal_error_default)}
            label="Trigger on fatal error"
            onChange={(event) => {
              setAlert({ ...alert, fatal_error_default: event.target.checked });
            }}
          />
          <Checkbox
            isChecked={Boolean(alert?.row_error_default)}
            label="Trigger on row error"
            onChange={(event) => {
              setAlert({ ...alert, row_error_default: event.target.checked });
            }}
          />
        </CheckboxGroup>
      </FormField>
      <FormField
        isOptional
        description="The minimum interval at which this alert triggers. For example, if the interval is 60 minutes, only one alert will be sent per hour even if the alert condition is met multiple times."
        label="Minimum alert interval"
      >
        <TextInput
          min="0"
          placeholder="Enter an alert interval (minutes)..."
          type="number"
          value={alert?.alert_interval !== null ? String(alert?.alert_interval) : ""}
          onChange={(event) => {
            const alertInterval = event.target.value ? Number(event.target.value) : null;
            setAlert({ ...alert, alert_interval: alertInterval });
          }}
        />
      </FormField>
    </Column>
  );
};

import { EventCondition, IntervalUnit, RawColumn, RelatedColumn } from ".";

export type Goal = {
  id: string;
  name: string;
  description: string;
  parentModelId: string;
  aggregation: AggregationType;
  config: GoalConfig;
  attributionWindow: AttributionWindow;
  enabled: boolean;
};

export enum AggregationType {
  Count = "count",
  Sum = "sum",
}

export type GoalConfig = CountMetricConfig | SumMetricConfig;

/**
 * - `column` defines the column on which to apply the aggregation.
 *    Count aggregations count all rows after any filtering.
 *
 * - `filter` applies conditions to the selected event model.
 *    Any subconditions are optional.
 *    When we allow aggregations on the parent model, the filtering will be applied on that instead.
 */
export type CountMetricConfig = {
  eventModelId: string;
  relationshipId: string;
  filter: EventFilter;
};

export type SumMetricConfig = {
  eventModelId: string;
  relationshipId: string;
  column: RawColumn | RelatedColumn;
  filter: EventFilter;
};

type EventFilter = Omit<
  EventCondition,
  "type" | "eventModelId" | "relationshipId" | "operator" | "value" | "window"
>;

// Defines the amount of time a user contributes to the goal metric _after_ exiting the audience
export type AttributionWindow = {
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
};

import { SVGAttributes } from "react";

// Custom array icon adapted from "curly-braces" in Lucide icon set
export function LucideArrayIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.00001 3H7.00001C6.46958 3 5.96087 3.21071 5.5858 3.58579C5.21073 3.96086 5.00001 4.46957 5.00001 5V10V19C5.00001 20.1 5.90001 21 7.00001 21H8.00001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M16 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V10V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}

import { FC, ReactNode, useState } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";

interface Props {
  sx?: ThemeUIStyleObject;
  children: ReactNode;
}

export const ScrollContainer: FC<Readonly<Props>> = ({ sx = {}, children }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [overflowing, setOverflowing] = useState(false);

  const blurHeight = 48;
  const itemHeight = 24;

  return (
    <Column
      sx={{
        ...sx,
        "&::before": {
          content: "''",
          opacity: scrolledToBottom || !overflowing ? 0 : 1,
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: blurHeight,
          background: "linear-gradient(transparent, 20%, white)",
          // zIndex shouldn't block clickable elements when the scroll fade is no longer visible.
          zIndex: scrolledToBottom || !overflowing ? -1 : 1,
        },
      }}
    >
      <Column
        ref={(element) => {
          if (element) {
            if (element.clientHeight < element.scrollHeight) {
              setOverflowing(true);
            }
          }
        }}
        sx={{
          maxHeight: "calc(100vh - 296px)",
          pr: 3,
          overflowY: "auto",
        }}
        onScroll={(event) => {
          const element = event.target as HTMLDivElement;
          if (element.scrollHeight - element.scrollTop <= element.clientHeight + itemHeight) {
            setScrolledToBottom(true);
          } else {
            setScrolledToBottom(false);
          }
        }}
      >
        {children}
      </Column>
    </Column>
  );
};

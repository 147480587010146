import { EventCondition, PropertyCondition } from "./condition";
import { TraitType } from "./trait-definitions";

export enum ColumnType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Timestamp = "timestamp",
  Date = "date",
  Json = "json",
  JsonArrayStrings = "json-array-strings",
  JsonArrayNumbers = "json-array-numbers",
  Null = "null",
  Unknown = "unknown",
}

export type Column = RawColumn | RelatedColumn;

export type RelatedColumn = {
  type: "related";
  path: string[];
  column: RawColumn | TraitColumn | EventTraitColumn;
};

export type RawColumn = {
  type: "raw";
  modelId: string;
  name: string;
};

export type TraitColumn = {
  type: "trait";
  traitDefinitionId: string;
  conditions: PropertyCondition[];
};

export type EventTraitColumn = {
  type: "event_trait";
  filteredEvent: Omit<EventCondition, "operator" | "value">;
  traitType: TraitType;
  traitConfig: Record<string, unknown>;
};

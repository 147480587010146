import { FC } from "react";

import { Drawer, Tab, TabList, TabPanel, TabPanels, Tabs } from "@hightouchio/ui";

import { ResourceActivity } from "src/hooks/use-resource-activity";

import { Editor } from "../editor";

interface RawViewProps {
  open: boolean;
  onClose: () => void;
  activity: ResourceActivity;
}

export const ResourceActivityExpandedView: FC<RawViewProps> = ({ open, onClose, activity }) => {
  return (
    <Drawer isOpen={open} size="lg" title="Details" onClose={onClose}>
      <Tabs>
        <TabList>
          <Tab>Diff</Tab>
          <Tab>Raw</Tab>
          {activity.approvedDraft && <Tab>Draft</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Editor readOnly language="json" value={JSON.stringify(activity.diff, null, 2)} />
          </TabPanel>
          <TabPanel>
            <Editor readOnly language="json" value={JSON.stringify(activity.metadata.new, null, 2)} />
          </TabPanel>
          <TabPanel>
            <Editor readOnly language="json" value={JSON.stringify(activity.approvedDraft, null, 2)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Drawer>
  );
};

import { Extension, Facet, RangeSetBuilder, Range, RangeSet } from "@codemirror/state";
import { EditorView, ViewPlugin, ViewUpdate, Decoration, DecorationSet } from "@codemirror/view";
import { Change } from "diff";

// Store the line number to highlight
// See https://codemirror.net/6/docs/ref/#state.Facet
const changesFacet = Facet.define({
  combine: (values) => values[0],
});

const removedDecoration = Decoration.line({ class: "cm-removedLine" });
const addedDecoration = Decoration.line({ class: "cm-addedLine" });

const decorateLines = (view: EditorView): DecorationSet => {
  const state = view.state.facet(changesFacet) as { diffs: Change[] };

  const builder = new RangeSetBuilder<Decoration>().finish();

  if (!state) {
    return builder;
  }

  const decorators: Range<Decoration>[] = [];

  const { diffs } = state;

  let pos = 0;

  for (const diff of diffs) {
    const initialPos = pos;
    pos += diff.value.length;

    if (diff.added || diff.removed) {
      const lines = diff.value.split("\n").filter(Boolean);

      let linePos = initialPos;
      for (const line of lines) {
        decorators.push({ from: linePos, to: linePos, value: diff.added ? addedDecoration : removedDecoration });
        linePos += line.length + 1;
      }
    }
  }

  return RangeSet.of<Decoration>(decorators, true);
};

const viewPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = decorateLines(view);
    }
    update(update: ViewUpdate) {
      this.decorations = decorateLines(update.view);
    }
  },
  {
    decorations: (view) => view.decorations,
  },
);

export const highlightChanges = (diffs: Change[]): Extension => {
  return [changesFacet.of({ diffs }), viewPlugin];
};

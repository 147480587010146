import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const NullIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 18 18">
      <path d="M8.98828 13.1699C8.24609 13.1699 7.60547 13.0254 7.06641 12.7363C6.52734 12.4473 6.11328 12.0332 5.82422 11.4941C5.53906 10.9551 5.39648 10.3203 5.39648 9.58984C5.39648 8.84766 5.54297 8.20312 5.83594 7.65625C6.12891 7.10547 6.54883 6.68164 7.0957 6.38477C7.64258 6.08789 8.28711 5.93945 9.0293 5.93945C9.7793 5.93945 10.4219 6.08789 10.957 6.38477C11.4961 6.67773 11.9043 7.09961 12.1816 7.65039C12.4629 8.19727 12.6035 8.84375 12.6035 9.58984C12.6035 10.3203 12.459 10.9551 12.1699 11.4941C11.8848 12.0332 11.4707 12.4473 10.9277 12.7363C10.3848 13.0254 9.73828 13.1699 8.98828 13.1699ZM9.00586 12.6133C9.4082 12.6133 9.7168 12.5059 9.93164 12.291C10.1504 12.0723 10.3008 11.748 10.3828 11.3184C10.4688 10.8887 10.5117 10.3125 10.5117 9.58984C10.5117 8.85938 10.4668 8.27344 10.377 7.83203C10.291 7.38672 10.1367 7.05273 9.91406 6.83008C9.69531 6.60742 9.38477 6.49609 8.98242 6.49609C8.58008 6.49609 8.26953 6.60742 8.05078 6.83008C7.83594 7.05273 7.68555 7.38477 7.59961 7.82617C7.51758 8.26758 7.47656 8.85547 7.47656 9.58984C7.47656 10.3086 7.51953 10.8848 7.60547 11.3184C7.69141 11.748 7.8457 12.0723 8.06836 12.291C8.29102 12.5059 8.60352 12.6133 9.00586 12.6133Z" />
      <path d="M4.79297 9.50781H13.207V10.1289H4.79297V9.50781Z" />
    </Icon>
  );
};

import { FC, ReactNode } from "react";

import { Row, Column } from "@hightouchio/ui";
import Helmet from "react-helmet";

import { PageSidebar } from "src/components/layout/page-sidebar";

import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { DefaultPageContainerPadding, PageContainer, PageContainerProps } from "./page-container";

export type Props = {
  children: ReactNode;
  header?: ReactNode;
  sidebar?: ReactNode;
  title: string;
  contain?: boolean; // Use if container should fit screen size and not scroll at the body level
} & HeaderProps &
  PageContainerProps;

export const Page: FC<Readonly<Props>> = ({
  children,
  crumbs,
  sync,
  header,
  outsideTopbar,
  sx,
  sidebar,
  title,
  contain,
  ...containerProps
}) => {
  const headerNode = (
    <PageHeader crumbs={crumbs} outsideTopbar={outsideTopbar} sync={sync}>
      {header}
    </PageHeader>
  );

  if (sidebar) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {headerNode}
        <Row flex={1} pos="relative">
          <PageSidebar>{sidebar}</PageSidebar>
          <Column flex={1} pb={24}>
            {children}
          </Column>
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {headerNode}
      <PageContainer
        {...containerProps}
        sx={{
          pt: crumbs ? 0 : DefaultPageContainerPadding.Top,
          maxHeight: contain ? "100vh" : undefined,
          overflow: contain ? "hidden" : undefined,
          ...sx,
        }}
      >
        {children}
      </PageContainer>
    </>
  );
};

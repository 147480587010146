import { FC } from "react";

import { Row, Column, Text } from "@hightouchio/ui";

import { SelectorRow } from "src/components/explore/selector-row";
import { SqlEditor } from "src/components/sql-editor";
import { UnsavedValue } from "src/hooks/use-unsaved-value";
import { QueryableSource } from "src/types/models";
import { ChartIcon } from "src/ui/icons";

type Props = {
  source: QueryableSource;
  onSQLChange?: (sql: string) => void;
  sql: string;
  unsavedSql?: UnsavedValue<string>;
  queryErrorAtLine?: number;
};

export const SampleModelSelector: FC<Readonly<Props>> = ({ source, onSQLChange, sql, unsavedSql, queryErrorAtLine }) => {
  return (
    <Row overflow="hidden" gap={4} flex={1}>
      <Column border="1px" borderColor="base.border" borderRadius="md" overflow="hidden" minWidth={0} flex={1}>
        <Row align="center" px={4} py={4} borderBottom="1px" borderColor="base.border" gap={4} justify="space-between">
          <Text fontWeight="medium" size="lg">
            Sample queries
          </Text>
        </Row>
        <Column overflow="auto" flex={1}>
          {source?.definition?.sampleModels?.map((model) => {
            return (
              <SelectorRow
                key={model?.name ?? ""}
                icon={<ChartIcon />}
                selected={model?.sql === sql}
                onClick={() => {
                  onSQLChange?.(model?.sql ?? "");
                }}
              >
                {model?.name}
              </SelectorRow>
            );
          })}
        </Column>
      </Column>

      <Column flex={1}>
        <SqlEditor
          highlightErroredLine={queryErrorAtLine}
          placeholder={
            source?.definition?.isSampleDataSource === true
              ? "Select a sample model or enter a custom query..."
              : "Enter your query..."
          }
          source={source ?? undefined}
          unsavedValue={unsavedSql}
          value={sql ?? ""}
          onChange={onSQLChange}
        />
      </Column>
    </Row>
  );
};

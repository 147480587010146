import { ReactElement } from "react";

import { Spinner } from "@hightouchio/ui";
import { Grid, ThemeUIStyleObject } from "theme-ui";

import { Radio } from "./radio";

export interface RadioGroupProps<Value> {
  options?: {
    key?: string | number;
    label: string | ReactElement;
    value: Value;
    description?: string;
  }[];
  onChange: (value: Value) => void;
  value: Value | undefined;
  loading?: boolean;
  size?: string;
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
}

export function RadioGroup<Value>({ options, loading, onChange, value, size, disabled, sx }: Readonly<RadioGroupProps<Value>>) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <Grid gap={size === "small" ? 1 : 2} sx={{ gridAutoRows: "max-content", ...sx }}>
      {Array.isArray(options) &&
        options.map((option) => (
          <Radio
            key={option.key || option.label.toString()}
            description={option.description}
            disabled={disabled}
            label={option.label}
            size={size}
            value={option.value === value}
            onChange={() => onChange(option.value)}
          />
        ))}
    </Grid>
  );
}

import { useEffect, useState } from "react";

import { useQueryClient } from "react-query";

import {
  ListSourceTestStepsQuery,
  ListSourceTestStepsQueryVariables,
  TestSourceQuery,
  TestSourceQueryVariables,
  useListSourceTestStepsQuery,
  useTestSourceQuery,
} from "src/graphql";
import { sleep } from "src/utils/promise";

export const useSourceTesting = (): {
  results: Partial<TestSourceQuery["testSource"]> | undefined;
  steps: ListSourceTestStepsQuery["listSourceTestSteps"] | undefined;
  getTestSteps: (variables: ListSourceTestStepsQueryVariables) => Promise<void>;
  runTest: (variables: TestSourceQueryVariables) => Promise<void>;
  timeElapsed: number | undefined;
} => {
  const [testResult, setTestResult] = useState<TestSourceQuery["testSource"] | undefined>();
  const [delayedTestResult, setDelayedTestResult] = useState<Partial<TestSourceQuery["testSource"]> | undefined>();
  const [testSteps, setTestSteps] = useState<ListSourceTestStepsQuery["listSourceTestSteps"] | undefined>();
  const [counter, setCounter] = useState<number>();

  const client = useQueryClient();

  useEffect(() => {
    const set = async (testResult) => {
      for (const step of testResult.stepResults) {
        await sleep(Math.random() * (1000 - 500) + 500);
        setDelayedTestResult((result) => ({ stepResults: [...(result?.stepResults || []), step] }));
      }
      setDelayedTestResult((result) => ({ ...result, success: testResult.success }));
    };

    if (testResult) {
      setCounter(undefined);
      set(testResult);
    } else {
      setDelayedTestResult(testResult);
    }
  }, [testResult]);

  useEffect(() => {
    let timer;
    if (counter !== undefined) {
      timer = setTimeout(() => setCounter(counter + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const getTestSteps = async (variables: ListSourceTestStepsQueryVariables) => {
    const queryKey = useListSourceTestStepsQuery.getKey(variables);
    const queryFn = useListSourceTestStepsQuery.fetcher(variables);

    const response = await client.fetchQuery<ListSourceTestStepsQuery>(queryKey, {
      queryFn,
      cacheTime: 0,
    });

    setTestSteps(response?.listSourceTestSteps);
  };

  const runTest = async (variables: TestSourceQueryVariables) => {
    setTestResult(undefined);
    setDelayedTestResult(undefined);
    setCounter(0);

    const queryKey = useTestSourceQuery.getKey(variables);
    const queryFn = useTestSourceQuery.fetcher(variables);

    const response = await client.fetchQuery<TestSourceQuery>(queryKey, {
      queryFn,
      cacheTime: 0,
    });

    setTestResult(response?.testSource);
  };

  return { results: delayedTestResult, steps: testSteps, getTestSteps, runTest, timeElapsed: counter };
};

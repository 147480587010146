import { ReactNode, FC } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import { useDisclosure } from "@chakra-ui/react";
import { Box, BoxProps, Button, Column } from "@hightouchio/ui";

import { FieldError } from "src/ui/field";

import { Indices } from "../../../../../design";
import { HStack } from "./condition";

const MAX_FILTER_WIDTH = 320;

type Props = {
  children: ReactNode;
  content: ReactNode;
  error?: ReactNode | string | Error;
} & BoxProps;

export const Filter: FC<Readonly<Props>> = ({ content, children, error, ...props }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Column {...props}>
      <Popover isLazy isOpen={isOpen} onClose={onClose} placement="bottom">
        <PopoverTrigger>
          <Box as={Button} sx={{ border: "none", p: 0 }} onClick={onToggle}>
            <HStack
              _hover={{
                borderColor: isOpen ? "primary.base" : "gray.border",
                bg: "base.lightBackground",
              }}
              _active={{
                border: "1px solid",
                borderColor: "primary.base",
                boxShadow: error ? "outlineRed" : "outline",
              }}
              px={2}
              gap={1}
              alignItems="center"
              height="32px"
              fontWeight="normal"
              border="1px solid"
              borderColor={error ? "danger" : isOpen ? "primary.base" : "base.border"}
              boxShadow={error ? "outlineRed" : isOpen ? "outline" : "none"}
              bg="base.lightBackground"
              borderRadius="6px"
              maxWidth={`${MAX_FILTER_WIDTH}px`}
              overflow="hidden"
              transition="all 120ms ease-in"
              whiteSpace="nowrap"
            >
              {children}
            </HStack>
          </Box>
        </PopoverTrigger>

        <Portal>
          <Box sx={{ ">div": { zIndex: Indices.Modal } }}>
            <PopoverContent width="max-content">
              <Column align="flex-start" flex={1} maxWidth="fit-content" minWidth={0} p={2}>
                <HStack gap={2} sx={{ overflowX: "auto" }}>
                  {content}
                </HStack>
              </Column>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>

      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AudienceSchemaIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <rect fillOpacity=".7" height="4" rx="2" width="4" x="14" y="16" />
      <rect fillOpacity=".7" height="4" rx="2" width="4" x="6" y="16" />
      <path
        clipRule="evenodd"
        d="M12 4a2 2 0 0 0-.5 3.937V11.5H9A1.5 1.5 0 0 0 7.5 13v3h1v-3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v3h1v-3a1.5 1.5 0 0 0-1.5-1.5h-2.5V7.937A2 2 0 0 0 12 4Z"
        fillOpacity=".3"
        fillRule="evenodd"
      />
    </Icon>
  );
};

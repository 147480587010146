import { FC, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Spinner, Select, FormField, IconButton } from "@hightouchio/ui";
import * as Yup from "yup";

import { useMetabaseListTablesQuery } from "src/graphql";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
    refetch: listTables,
  } = useMetabaseListTablesQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(tablesError);
  }, [tablesError]);

  return (
    <FormField label="Table">
      <Row gap={2}>
        <Select
          isLoading={tablesLoading}
          options={tables?.metabaseListTables?.map((t) => ({ label: t.name, value: t.id })) ?? []}
          placeholder="Select a table..."
          value={query?.id}
          onChange={(value) => {
            if (value) {
              const table = tables?.metabaseListTables?.find((t) => t.id === value);
              if (table) {
                onChange({ ...query, id: table.id, databaseId: table.databaseId });
              }
            } else {
              onChange({ ...query, id: undefined, databaseId: undefined });
            }
          }}
        />
        <IconButton
          aria-label="Refresh tables"
          icon={ArrowPathIcon}
          isDisabled={tablesLoading}
          variant="secondary"
          onClick={() => listTables()}
        />
      </Row>
    </FormField>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
  } = useMetabaseListTablesQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(tablesError);
  }, [tablesError]);

  const table = tables?.metabaseListTables?.find((t) => t.id === query.id);
  const tableName = table?.name || `Unknown table with id ${query.id}`;

  return <FormField label="Table">{tablesLoading ? <Spinner /> : tableName}</FormField>;
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["table"]),
  id: Yup.number().required(),
  databaseId: Yup.number().required(),
});

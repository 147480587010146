import { FC, ReactNode, useEffect, useState } from "react";

import { Box, Row, EditableHeading, Heading } from "@hightouchio/ui";

import { usePermission } from "src/contexts/permission-context";

type Props = {
  title: string;
  icon?: string;
  actions?: ReactNode;
  onNameChange?: (name: string) => void;
};

export const Header: FC<Readonly<Props>> = ({ title: defaultTitle, icon, actions, onNameChange }) => {
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const permission = usePermission();

  return (
    <Row align="center" mb={8} gap={4}>
      <Row flex={1} gap={4}>
        {icon && <Box as="img" alt={title} src={icon} width="24px" height="24px" objectFit="contain" />}
        {onNameChange ? (
          <EditableHeading
            isDisabled={permission?.unauthorized}
            size="lg"
            value={title}
            onChange={setTitle}
            onSubmit={onNameChange}
          />
        ) : (
          <Heading size="lg">{title}</Heading>
        )}
      </Row>
      {actions}
    </Row>
  );
};

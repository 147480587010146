import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DBTIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon color="#FF694B" viewBox="0 0 24 24" {...props}>
      <path d="M16.43 10.032a6.115 6.115 0 0 0-2.284-2.34l1.33.629a7.722 7.722 0 0 1 2.808 2.25l2.428-4.447a2.14 2.14 0 0 0-.046-2.22 2.047 2.047 0 0 0-2.678-.654l-4.411 2.407a3.277 3.277 0 0 1-3.136 0L6.127 3.306a2.145 2.145 0 0 0-2.223.047 2.045 2.045 0 0 0-.655 2.675l2.407 4.405a3.268 3.268 0 0 1 0 3.133l-2.348 4.307a2.144 2.144 0 0 0 .064 2.248 2.046 2.046 0 0 0 2.661.63l4.549-2.48a7.718 7.718 0 0 1-2.256-2.81l-.63-1.327a6.115 6.115 0 0 0 2.343 2.282l7.947 4.334a2.048 2.048 0 0 0 2.66-.628 2.151 2.151 0 0 0 .06-2.25l-4.276-7.84Zm2.538-5.801a.82.82 0 0 1 .313 1.573.82.82 0 0 1-1.132-.755.817.817 0 0 1 .819-.818ZM5.05 5.866a.819.819 0 1 1 0-1.637.819.819 0 0 1 0 1.637Zm0 13.903a.819.819 0 0 1-.579-1.395.819.819 0 0 1 1.157 1.156.819.819 0 0 1-.578.24Zm7.777-8.586a1.636 1.636 0 0 0-1.51 1.002 1.63 1.63 0 0 0 .402 1.831 2.043 2.043 0 0 1-1.775-2.309 2.04 2.04 0 0 1 2.344-1.73 2.043 2.043 0 0 1 1.738 1.735 1.621 1.621 0 0 0-1.199-.53Zm6.14 8.588a.82.82 0 0 1-.58-1.397.82.82 0 0 1 1.398.578.817.817 0 0 1-.818.818Z" />
    </Icon>
  );
};

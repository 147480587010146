import { FC, useMemo } from "react";

import { Select } from "@hightouchio/ui";
import { ImageSelectOptionAccessory } from "@hightouchio/ui/dist/types";
import { useFlags } from "launchdarkly-react-client-sdk";

import { HightouchRegionOptions } from "src/utils/regions";

const flags = {
  us: `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 7410 3900'%3e%3cpath fill='%23b22234' d='M0 0h7410v3900H0z'/%3e%3cpath d='M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0' stroke='%23fff' stroke-width='300'/%3e%3cpath fill='%233c3b6e' d='M0 0h2964v2100H0z'/%3e%3cg fill='%23fff'%3e%3cg id='d'%3e%3cg id='c'%3e%3cg id='e'%3e%3cg id='b'%3e%3cpath id='a' d='m247 90 70.534 217.082-184.66-134.164h228.253L176.466 307.082z'/%3e%3cuse xlink:href='%23a' y='420'/%3e%3cuse xlink:href='%23a' y='840'/%3e%3cuse xlink:href='%23a' y='1260'/%3e%3c/g%3e%3cuse xlink:href='%23a' y='1680'/%3e%3c/g%3e%3cuse xlink:href='%23b' x='247' y='210'/%3e%3c/g%3e%3cuse xlink:href='%23c' x='494'/%3e%3c/g%3e%3cuse xlink:href='%23d' x='988'/%3e%3cuse xlink:href='%23c' x='1976'/%3e%3cuse xlink:href='%23e' x='2470'/%3e%3c/g%3e%3c/svg%3e`,
  eu: `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20width%3D%22810%22%20height%3D%22540%22%3E%3Cdesc%3EEuropean%20flag%3C%2Fdesc%3E%0D%0A%3Cdefs%3E%3Cg%20id%3D%22s%22%3E%3Cg%20id%3D%22c%22%3E%3Cpath%20id%3D%22t%22%20d%3D%22M0%2C0v1h0.5z%22%20transform%3D%22translate(0%2C-1)rotate(18)%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23t%22%20transform%3D%22scale(-1%2C1)%22%2F%3E%3C%2Fg%3E%3Cg%20id%3D%22a%22%3E%3Cuse%20xlink%3Ahref%3D%22%23c%22%20transform%3D%22rotate(72)%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23c%22%20transform%3D%22rotate(144)%22%2F%3E%3C%2Fg%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20transform%3D%22scale(-1%2C1)%22%2F%3E%3C%2Fg%3E%3C%2Fdefs%3E%0D%0A%3Crect%20fill%3D%22%23039%22%20width%3D%22810%22%20height%3D%22540%22%2F%3E%3Cg%20fill%3D%22%23fc0%22%20transform%3D%22scale(30)translate(13.5%2C9)%22%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20y%3D%22-6%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20y%3D%226%22%2F%3E%3Cg%20id%3D%22l%22%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20x%3D%22-6%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20transform%3D%22rotate(150)translate(0%2C6)rotate(66)%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20transform%3D%22rotate(120)translate(0%2C6)rotate(24)%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20transform%3D%22rotate(60)translate(0%2C6)rotate(12)%22%2F%3E%3Cuse%20xlink%3Ahref%3D%22%23s%22%20transform%3D%22rotate(30)translate(0%2C6)rotate(42)%22%2F%3E%3C%2Fg%3E%3Cuse%20xlink%3Ahref%3D%22%23l%22%20transform%3D%22scale(-1%2C1)%22%2F%3E%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E`,
};

type Props = {
  value: string;
  onChange: (value: string | undefined) => void;
  isDisabled?: boolean;
};

export const SelectRegion: FC<Readonly<Props>> = ({ value, onChange, isDisabled }) => {
  const { appEnableGoogleCloudRegion } = useFlags();

  const options = useMemo(() => {
    return [
      {
        value: HightouchRegionOptions.AWS_US_EAST_1,
        label: `United States (${HightouchRegionOptions.AWS_US_EAST_1})`,
        flag: flags.us,
      },
      ...(appEnableGoogleCloudRegion
        ? [
            {
              value: HightouchRegionOptions.GCP_US_EAST_4,
              label: `United States (${HightouchRegionOptions.GCP_US_EAST_4})`,
              flag: flags.us,
            },
          ]
        : []),
      {
        value: HightouchRegionOptions.AWS_EU_WEST_1,
        label: `European Union (${HightouchRegionOptions.AWS_EU_WEST_1})`,
        flag: flags.eu,
      },
    ];
  }, [appEnableGoogleCloudRegion]);

  return (
    <Select
      isDisabled={isDisabled}
      optionAccessory={optionAccessory}
      options={options}
      placeholder="Select a region"
      value={value}
      width="100%"
      onChange={onChange}
    />
  );
};

const optionAccessory = (option): ImageSelectOptionAccessory => ({
  type: "image",
  url: option.flag,
});

import { FC, useEffect, useState } from "react";

import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Column, FormField, SectionHeading, Spinner, TextInput, useToast, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";
import { Outlet, Routes, Route, useOutletContext, useLocation, useNavigate } from "react-router-dom";

import lookerImage from "src/components/extensions/assets/looker.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { TunnelSelect } from "src/components/tunnels/tunnel-select";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  useLookerCredentialsQuery,
  useCreateLookerCredentialMutation,
  useUpdateLookerCredentialMutation,
  LookerCredentialsQuery,
  ResourcePermissionGrant,
  useTestLookerExtensionQuery,
  useDeleteLookerExtensionMutation,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { LookerIcon } from "src/ui/icons";
import { Tabs } from "src/ui/tabs";

import { ConnectedExtension } from "./common";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Looker: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="When you create a Hightouch model backed by a Look, Hightouch converts that Look to SQL and runs it against your data source."
              icon={LookerIcon}
              image={lookerImage}
              subtitle="Import models from Looker"
              title="Looker"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

export const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: loading } = useLookerCredentialsQuery(undefined, {
    select: (data) => data.looker_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Looker" }]} title="Looker - Extensions">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/looker");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading }} />
    </Page>
  );
};

type LookerCredentials = LookerCredentialsQuery["looker_credentials"][0];

interface OutletContext {
  loading: boolean;
  credentials: LookerCredentials;
}
type LookerTestStatus = "failed" | "loading" | "success" | "error";

const Configuration: FC = () => {
  const { loading, credentials } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm();

  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } = useDeleteLookerExtensionMutation();
  const [testStatus, setTestStatus] = useState<LookerTestStatus>("loading");

  const isTunnel = Boolean(watch("tunnel_id"));
  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestLookerExtensionQuery({}, { select: (data) => data.testLookerExtension, enabled: !!credentials });

  const { mutateAsync: create } = useCreateLookerCredentialMutation();
  const { mutateAsync: update } = useUpdateLookerCredentialMutation();

  const submit = async (data) => {
    if (!data.tunnel_id) {
      delete data.tunnel_id;
    }

    try {
      if (credentials?.id) {
        await update({
          credentials: { ...data, id: credentials.id },
        });
      } else {
        await create({
          credentials: data,
        });
      }

      toast({
        id: "save-looker",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-looker",
        title: "There was an error saving your configuration",
        variant: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "looker-extension",
          variant: "error",
          title: "Looker credentials test failed.",
          message: "Please check your Looker API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  useEffect(() => {
    reset({
      tunnel_id: credentials?.tunnel_id ?? "",
      url: credentials?.url ?? "",
      client_id: credentials?.client_id ?? "",
      client_secret: "",
    });
  }, [credentials]);

  if (loading) {
    return <Spinner size="lg" />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Column gap={8}>
            <SectionHeading>Looker configuration</SectionHeading>
            {credentials?.id ? (
              <ConnectedExtension credentials={credentials} testStatus={testStatus}>
                <Text fontWeight="medium">
                  <Text fontWeight="semibold">Client ID: </Text>
                  {credentials?.client_id || "Not configured"}
                </Text>
              </ConnectedExtension>
            ) : (
              <>
                <Controller
                  control={control}
                  name="tunnel_id"
                  render={({ field }) => (
                    <TunnelSelect
                      value={field.value ? { id: field.value } : undefined}
                      onChange={(tunnel) => {
                        field.onChange(tunnel?.id);
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="url"
                  render={({ field }) => (
                    <FormField label="URL">
                      <TextInput isDisabled={isTunnel} {...field} />
                    </FormField>
                  )}
                />

                <Controller
                  control={control}
                  name="client_id"
                  render={({ field }) => (
                    <FormField label="Client ID">
                      <TextInput isDisabled={loading} {...field} />
                    </FormField>
                  )}
                />

                <Controller
                  control={control}
                  name="client_secret"
                  render={({ field }) => (
                    <FormField label="Client secret">
                      <TextInput type="password" isDisabled={loading} {...field} />
                    </FormField>
                  )}
                />
              </>
            )}
          </Column>
        </Container>
        <SidebarForm
          buttons={
            credentials?.id ? (
              <Permission>
                <Button
                  icon={ArrowPathIcon}
                  isDisabled={isTesting || isReTesting || extensionDeleting}
                  onClick={() => {
                    setTestStatus("loading");
                    testExtension({});
                  }}
                >
                  {isTesting || isReTesting ? "Testing..." : "Test connection"}
                </Button>
                <Button
                  icon={TrashIcon}
                  isDisabled={extensionDeleting || isTesting}
                  variant="danger"
                  onClick={async () => {
                    try {
                      await deleteExtension({ workspaceId: credentials.workspace_id });
                      toast({
                        id: "looker-extension",
                        title: "Looker disconnected",
                        variant: "success",
                      });
                    } catch (e) {
                      toast({
                        id: "looker-extension",
                        title: "Could not disconnect Looker",
                        variant: "success",
                      });
                    }
                  }}
                >
                  Disconnect
                </Button>
              </Permission>
            ) : (
              <Permission>
                <Button variant="primary" isDisabled={!isDirty} isLoading={isSubmitting} onClick={handleSubmit(submit)}>
                  Connect
                </Button>
              </Permission>
            )
          }
          docsUrl="models/looker-models"
          name="Looker"
        />
      </Row>
    </PermissionProvider>
  );
};

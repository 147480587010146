import { FC } from "react";

import { Box, Text } from "@hightouchio/ui";

import { PlaceholderComponentProps } from "../types";
import { Container } from "./container";

export const Placeholder: FC<PlaceholderComponentProps> = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <Box
        sx={{
          span: {
            color: "base.4",
          },
        }}
      >
        <Text isTruncated>{children}</Text>
      </Box>
    </Container>
  );
};

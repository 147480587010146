import { FC, useEffect, useState } from "react";

import { AudienceSplit } from "@hightouch/lib/query/visual/types";
import { Button, Link, Text, Tag } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import isEqual from "lodash/isEqual";

import { useAudienceSplitsBySegmentQuery } from "src/graphql";
import { Box, Column, Row } from "src/ui/box";
import { Table, TableColumn } from "src/ui/table/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { disambiguateSyncs, syncStatusForTag } from "src/utils/syncs";

interface SplitsSyncAssignmentFormProps {
  audienceId: number | string;
  hideSave?: boolean;
  setSelectedSplitIds?: (splitIds: string[]) => void;
  splitsAssignedToCurrentSync?: Partial<AudienceSplit>[];
  onSubmit: (updates: any) => Promise<void>;
}

export const SplitsSyncAssignmentForm: FC<Readonly<SplitsSyncAssignmentFormProps>> = ({
  audienceId,
  hideSave = false,
  setSelectedSplitIds,
  splitsAssignedToCurrentSync = [],
  onSubmit,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const { selectedRows, onRowSelect } = useRowSelect({
    defaultSelectedRows: splitsAssignedToCurrentSync.map((split) => split.id!),
  });

  const { data: audienceSplitsData, isLoading: isAudienceSplitsLoading } = useAudienceSplitsBySegmentQuery({
    segment_id: String(audienceId),
  });

  const audienceSplits = audienceSplitsData?.audience_splits ?? [];

  const audienceSplitsWithDisambiguatedSyncs = audienceSplits.map((split) => ({
    ...split,
    destination_instance_splits: disambiguateSyncs(
      split.destination_instance_splits.map(({ destination_instance }) => destination_instance),
    ),
  }));

  useEffect(() => {
    if (setSelectedSplitIds) {
      setSelectedSplitIds(selectedRows as string[]);
    }
  }, [setSelectedSplitIds, selectedRows]);

  const columns: TableColumn[] = [
    {
      name: "Split group",
      cell: ({ friendly_name }) => friendly_name,
    },
    {
      name: "Distribution",
      cell: ({ percentage }) => `${percentage}%`,
    },
    {
      name: "Assigned syncs",
      cell: ({ destination_instance_splits }) => {
        const syncTags = destination_instance_splits.map((destination_instance) => (
          <Tag
            key={destination_instance.id}
            imageUrl={destination_instance?.destination?.definition.icon}
            status={syncStatusForTag(destination_instance?.status)}
          >
            {destination_instance?.name}
          </Tag>
        ));

        return <Row sx={{ gap: "8px", overflowX: "auto" }}>{syncTags}</Row>;
      },
    },
  ];

  const isSaveDisabled =
    selectedRows.length === 0 ||
    isEqual(
      splitsAssignedToCurrentSync.map((split) => split.id),
      selectedRows,
    );

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await onSubmit(selectedRows);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Row>
      <Box sx={{ width: "100%", minWidth: "70%" }}>
        <Box sx={{ mb: "24px", maxWidth: "800px" }}>
          <Text>
            Splits divide the audience into multiple randomized groups based on specific distributions. Please select one or
            more split groups to send to this destination.
          </Text>
        </Box>
        <Table
          columns={columns}
          data={audienceSplitsWithDisambiguatedSyncs}
          loading={isAudienceSplitsLoading}
          placeholder={{
            title: "No split groups",
            body: (
              <>
                Create a split group for your <Link href={`/audiences/${audienceId}`}>audience</Link> to get started
              </>
            ),
            error: "Split groups failed to load, please try again.",
          }}
          selectedRows={selectedRows}
          onSelect={onRowSelect}
        />
      </Box>

      {!hideSave && Boolean(audienceSplitsWithDisambiguatedSyncs.length) && (
        <>
          <Column
            sx={{
              borderLeft: "small",
              pl: 8,
              position: "sticky",
              top: "0px",
              ml: 5,
              height: "100%",
              width: "100%",
            }}
          >
            <Button isJustified isDisabled={isSaveDisabled} isLoading={isSaving} variant="primary" onClick={handleSave}>
              Save
            </Button>
            {selectedRows.length === 0 && (
              <Box sx={{ color: "red", mt: 3 }}>
                <Text>Please select at least one split group to assign this sync to.</Text>
              </Box>
            )}
          </Column>
        </>
      )}
    </Row>
  );
};

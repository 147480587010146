import { useEffect, useState, FC } from "react";

import { Column, TextInput, Select, useToast, Textarea, FormField, SectionHeading } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { capitalize } from "lodash";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as y from "yup";

import { RefreshIntervals } from "src/components/audiences/column-settings";
import { getSchemaModelTypeNames } from "src/components/audiences/utils";
import { Explore } from "src/components/explore";
import { SimpleWizard, SimpleWizardStep } from "src/components/simple-wizard";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import {
  Relationship,
  useCreateEventMutation,
  useCreateObjectMutation,
  useCreateRelationshipMutation,
  useRelationshipModelsQuery,
  useUpdateModelColumnsMutation,
} from "src/graphql";
import { QueryType, QueryableSource } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { isTimestampColumn, useModelRun, useQueryState } from "src/utils/models";
import { ResourceType, useResourceSlug } from "src/utils/slug";

import { getDefaultRelationship, RelationshipFields, validationResolver } from "./relationships/relationship";

export const CreateSchemaModelWizard: FC<
  Readonly<{ source?: QueryableSource; type: SchemaModelType; onCreated?: (id: string) => void; fromModelId?: string }>
> = ({ source: initialSource, type, fromModelId, onCreated }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { typeName } = getSchemaModelTypeNames(type);
  const { getSlug } = useResourceSlug(ResourceType.Model);
  const [source, setSource] = useState<QueryableSource | undefined>(initialSource);
  const [step, setStep] = useState(0);
  const {
    queryState,
    resetQueryState,
    setSQL,
    setTable,
    setDBTModel,
    setCustomQuery,
    setLookerLook,
    setSigma,
    isQueryDefined,
  } = useQueryState();
  const [queryType, setQueryType] = useState<QueryType>(QueryType.Table);
  const [creating, setCreating] = useState(false);
  const [hasQueryColumns, setHasQueryColumns] = useState(false);

  const { data: models } = useRelationshipModelsQuery(
    { sourceId: String(source?.id) },
    { select: (data) => data.segments, enabled: Boolean(source) },
  );

  const fromModel = models?.find((model) => String(model.id) === String(fromModelId));

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(queryType, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: validationResolver(getFormSchema(type)),
    context: { models, toModel: { columns } },
    defaultValues: {
      name: "",
      description: "",
      relationship: type !== SchemaModelType.Parent ? getDefaultRelationship(String(fromModelId)) : undefined,
    },
  });

  const name = watch("name");

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: createEvent } = useCreateEventMutation();
  const { mutateAsync: updateModelColumns } = useUpdateModelColumnsMutation();
  const { mutateAsync: createRelationship } = useCreateRelationshipMutation();

  const submit: SubmitHandler<FormValues> = async (data) => {
    setCreating(true);

    const { relationship, timestamp_column, ...values } = data;
    const slug = await getSlug(data.name);

    try {
      const response = await createObject({
        input: {
          slug,
          is_schema: true,
          query_type: queryType,
          connection_id: source?.id,
          created_by: user?.id != null ? String(user?.id) : undefined,
          query_dbt_model_id: queryState?.dbtModel?.id,
          query_looker_look_id: queryState?.lookerLook?.id,
          query_table_name: queryState?.table,
          query_raw_sql: queryState?.sql,
          columns: { data: columns },
          git_sync_metadata:
            queryType === QueryType.DbtModel
              ? {
                  git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                  file_path: queryState?.dbtModel?.original_file_path,
                  dbt_model_id: queryState?.dbtModel?.id,
                }
              : null,
          ...values,
        },
      });

      const id = response.insert_segments_one?.id;

      if (type === SchemaModelType.Event) {
        await createEvent({
          input: {
            model_id: id,
            timestamp_column,
          },
        });
      }
      if (type === SchemaModelType.Parent) {
        // Enable suggestions with weekly refresh interval by default upon model creation.
        await updateModelColumns({
          id,
          input: {
            top_k_enabled: true,
            top_k_sync_interval: RefreshIntervals.Weekly,
          },
        });
      } else {
        await createRelationship({
          relationship: { ...relationship, to: String(id), from: String(fromModelId) },
        });
      }
      toast({
        id: `create-${type}`,
        title: `${capitalize(values.name)} was created`,
        variant: "success",
      });
      if (onCreated) {
        onCreated(id);
      }
    } catch (e) {
      captureException(e);
      toast({
        id: `create-${type}-error`,
        title: `Error creating ${typeName}`,
        variant: "error",
      });
    }
    setCreating(false);
  };

  const steps: SimpleWizardStep[] = [
    {
      disabled:
        !isQueryDefined(queryType) ||
        Boolean(queryError) ||
        (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      render: () => (
        <>
          {source && (
            <Explore
              cancelQuery={cancelQuery}
              columns={columns}
              isQueryDefined={isQueryDefined}
              isResultTruncated={Boolean(isResultTruncated)}
              numRowsWithoutLimit={numRowsWithoutLimit}
              rows={rows}
              runQuery={runQuery}
              source={source}
              type={queryType}
              {...queryState}
              error={queryError}
              errorAtLine={queryErrorAtLine}
              loading={queryLoading}
              rowsPerPage={15}
              onCustomQueryChange={setCustomQuery}
              onDBTModelChange={setDBTModel}
              onLookerLookChange={setLookerLook}
              onSQLChange={setSQL}
              onSigmaChange={setSigma}
              onTableChange={setTable}
              onTypeChange={setQueryType}
              overflow="unset"
            />
          )}
        </>
      ),
    },
    {
      submitting: creating,
      continueLabel: `Create ${typeName}`,
      render: () => {
        const filteredColumns = type === SchemaModelType.Event ? columns?.filter(isTimestampColumn) : columns;
        const columnOptions: { value: string; label: string }[] = filteredColumns.map(({ name }) => ({
          value: name,
          label: name,
        }));

        return (
          <Column gap={8}>
            <Column gap={6}>
              <SectionHeading>Configuration</SectionHeading>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField label="Name" tip="Something simple like “Users”" error={error?.message}>
                    <TextInput
                      isInvalid={Boolean(error)}
                      placeholder="Enter a name..."
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField label="Description" isOptional error={error?.message}>
                    <Textarea
                      isInvalid={Boolean(error)}
                      placeholder="Enter a description..."
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </FormField>
                )}
              />
              {type === SchemaModelType.Parent && (
                <Controller
                  name="primary_key"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      label="Primary key"
                      tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
                      error={error?.message}
                    >
                      <Select
                        isInvalid={Boolean(error)}
                        options={columnOptions}
                        placeholder="Select a column..."
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormField>
                  )}
                />
              )}
              {type === SchemaModelType.Event && (
                <Controller
                  name="timestamp_column"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormField label="Timestamp" error={error?.message}>
                      <Select
                        isInvalid={Boolean(error)}
                        options={columnOptions}
                        placeholder="Select a column..."
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormField>
                  )}
                />
              )}
            </Column>
            {type === SchemaModelType.Parent ? (
              <Column gap={6}>
                <SectionHeading>Content labels</SectionHeading>
                <Controller
                  name="visual_query_primary_label"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      label="Primary label"
                      tip="Used when previewing results from an audience. E.g. “full_name”"
                      error={error?.message}
                    >
                      <Select
                        isInvalid={Boolean(error)}
                        options={columnOptions}
                        placeholder="Select a column..."
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormField>
                  )}
                />
                <Controller
                  name="visual_query_secondary_label"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      label="Secondary label"
                      tip="Used when previewing results from an audience. E.g. “email”"
                      error={error?.message}
                    >
                      <Select
                        isInvalid={Boolean(error)}
                        options={columnOptions}
                        placeholder="Select a column..."
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormField>
                  )}
                />
              </Column>
            ) : (
              <Column gap={6}>
                <SectionHeading>Relationship</SectionHeading>
                {fromModel && (
                  <RelationshipFields
                    toModel={{ name, columns }}
                    fromModel={fromModel}
                    control={control as any}
                    errors={errors as any}
                    watch={watch as any}
                    setValue={setValue as any}
                    sourceId={source!.id}
                  />
                )}
              </Column>
            )}
          </Column>
        );
      },
    },
  ];

  if (!initialSource) {
    steps.unshift({
      render: () => (
        <SourceSelect
          queryType={QueryType.Visual}
          onSelect={(source) => {
            setSource(source);
            setStep((step) => step + 1);
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (source) {
      resetQueryState();
    }
  }, [source]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (type !== SchemaModelType.Parent) {
      setValue("relationship.toName", name, { shouldDirty: true });
    }
  }, [name]);

  return (
    <SimpleWizard
      setStep={setStep}
      step={step}
      steps={steps}
      title={`New ${typeName}`}
      onCancel={() => {
        navigate(-1);
      }}
      onSubmit={handleSubmit(submit)}
    />
  );
};

type FormValues = {
  name: string;
  description: string;
  primary_key: string | undefined;
  timestamp_column: string | undefined;
  visual_query_primary_label: string | undefined;
  visual_query_secondary_label: string | undefined;
  relationship: Relationship;
};

export const getFormSchema = (type: SchemaModelType) => {
  const base = {
    name: y.string().required(),
    description: y.string(),
  };
  if (type === SchemaModelType.Parent) {
    return y.object().shape({
      ...base,
      primary_key: y.string().required(),
      visual_query_primary_label: y.string().required(),
      visual_query_secondary_label: y.string().required(),
    });
  }
  const relationshipSchema = y.object().shape({
    from: y.string(),
    to: y.string(),
    cardinality: y.string().required("Select a cardinality"),
    joinMappings: y
      .array()
      .of(
        y.object().shape({
          to: y.string().required("Select a column"),
          from: y.string().required("Select a column"),
        }),
      )
      .required(),
    isMergingIntoFrom: y.boolean(),
    isMergingIntoTo: y.boolean(),
  });
  if (type === SchemaModelType.Event) {
    return y.object().shape({
      ...base,
      timestamp_column: y.string().required(),
      relationship: relationshipSchema,
    });
  }
  return y.object().shape({
    ...base,
    relationship: relationshipSchema,
  });
};

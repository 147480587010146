import { FC } from "react";

import { Button, Row, Box } from "@hightouchio/ui";

import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { DestinationIcon } from "src/ui/icons";
import { Selectable } from "src/ui/selectable";

type DestinationShadowProps = {
  category: string;
  selected: boolean;
  hit: any; // Algolia doesn't expose this type to us, so we are leaving it untyped.
  onSendEvent: (eventType: string, hit: any, eventName?: string) => void;
};

export const DestinationShadow: FC<Readonly<DestinationShadowProps>> = ({ category, selected, hit, onSendEvent }) => {
  if (!hit) {
    return null;
  }

  const messageIntercom = (hit) => {
    newIntercomMessage(
      `Hi, I would like to request a new destination. I want to sync data to ${hit.name} in order to help the _____ team accomplish _____. We use this specific functionality: _____.`,
    );
  };

  const selectShadow = (hit) => {
    messageIntercom(hit);
    analytics.track("Destination Catalog Destination Clicked", {
      destination_name: hit.name,
      destination_slug: hit.slug,
      destination_status: hit.status,
      current_category: category,
    });
    onSendEvent("click", hit, "Destination Catalog Destination Clicked");
  };

  return (
    <Row sx={{ "& > button": { borderColor: "transparent" } }}>
      <Selectable selected={selected} px={5} height="50px" onSelect={() => selectShadow(hit)} width="100%">
        <Row align="center" justify="space-between" gap={4} flex={1}>
          <Row align="center" gap={4} flex={1}>
            {hit?.icon?.asset?.url ? (
              <Box
                as="img"
                src={`${hit?.icon?.asset?.url}?h=40`}
                height="20px"
                width="20px"
                objectFit="contain"
                opacity={0.25}
                flexShrink={0}
              />
            ) : (
              <DestinationIcon size={20} sx={{ opacity: 0.25 }} />
            )}
            <Box
              as="p"
              fontSize="md"
              fontWeight="medium"
              textAlign="left"
              sx={{
                wordBreak: "break-word",
                color: selected ? "inherit" : "gray.900",
                transition: "all 100ms ease-in-out",
                "& strong": {
                  color: "grass",
                  fontWeight: 600,
                },
              }}
            >
              {hit.name}
            </Box>
          </Row>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              messageIntercom(hit);
            }}
          >
            Request for development
          </Button>
        </Row>
      </Selectable>
    </Row>
  );
};

import { FC, ReactNode, useState } from "react";

import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import { Box, Button, Column, FormField, FormFieldProps, Row, Tooltip, UpsellBadge } from "@hightouchio/ui";

import { UpsellModal } from "./modal";
import { FeatureProps } from "./types";

type FeatureFieldProps = {
  enabled: boolean;
  featureName: string;
  children: ReactNode;
  featureDetails: FeatureProps;
};

export const FeatureField: FC<Readonly<FeatureFieldProps & FormFieldProps>> = ({
  children,
  enabled,
  featureName,
  featureDetails,
  label,
  ...formFieldProps
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  if (enabled) {
    return (
      <FormField label={label} {...formFieldProps}>
        {children}
      </FormField>
    );
  }
  return (
    <>
      <Column position="relative" width="max-content">
        <FormField
          {...formFieldProps}
          // TODO: ERNEST FIX
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={
            <Row alignItems="center">
              {label}
              <UpsellBadge ml={4}>Business tier</UpsellBadge>
            </Row>
          }
        >
          <Tooltip message="This feature is only available on the business tier plan">{children}</Tooltip>
        </FormField>
        <Box bottom={0} position="absolute" right={0}>
          <Button icon={ArrowUpCircleIcon} size="sm" onClick={() => setModalOpen(true)}>
            Upgrade plan
          </Button>
        </Box>
      </Column>
      <UpsellModal
        featureDetails={featureDetails}
        featureName={featureName}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

import { FC } from "react";

import { Icon, IconProps } from "./icon";

export const CaretUpIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M8 20.695l7.997-11.39L24 20.695z" />
    </Icon>
  );
};

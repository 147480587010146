import * as yup from "yup";
import { BaseType, Props, ValidatorSync } from "./base";

export interface BooleanType extends BaseType {
  type: "BOOLEAN";
}

export function boolean(opts?: Props<BooleanType>): BooleanType {
  return {
    ...opts,
    type: "BOOLEAN",
  };
}

export const validator: ValidatorSync<BooleanType> = (_) => {
  const schema = yup.boolean();
  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};

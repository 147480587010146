import { FC, useEffect } from "react";

import { Box, Link } from "@hightouchio/ui";

import { useQueryString } from "src/utils/use-query-string";

const RedirectPage: FC = () => {
  // redirects to an API endpoint
  const {
    data: { to },
  } = useQueryString();

  const redirectUrl = to ? `${import.meta.env.VITE_API_BASE_URL}/${to}` : null;

  useEffect(() => {
    if (to && redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }
  });
  return <Box sx={{ p: 2, width: "100%" }}>{redirectUrl && <Link href={redirectUrl}>Redirecting...</Link>}</Box>;
};

export default RedirectPage;

import { FC } from "react";

import { LabelBadge, LabelBadgeGroup, Row } from "@hightouchio/ui";

type Props = {
  labels: Record<string, string>;
};

export const Labels: FC<Readonly<Props>> = ({ labels = {} }) => {
  const entries = Object.entries(labels).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Row
      maxWidth="100%"
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{ "& > div": { width: "100%" } }}
    >
      <LabelBadgeGroup>
        {entries.map(([label, value]) => (
          <LabelBadge key={label} label={label} value={String(value)} />
        ))}
      </LabelBadgeGroup>
    </Row>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const CalendarIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M3 16.87V9.25696H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z"
        fillRule="evenodd"
      />
      <path
        d="M3.00342 9.25687C3.01626 8.66987 3.06566 7.50487 3.15852 7.12987C3.63273 5.02087 5.24304 3.68087 7.54491 3.48987H16.456C18.7381 3.69087 20.3682 5.03987 20.8424 7.12987C20.9343 7.49487 20.9837 8.66887 20.9965 9.25687H3.00342Z"
        opacity="0.4"
      />
      <path d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z" />
      <path d="M15.6948 6.59C16.1196 6.59 16.4555 6.261 16.4555 5.82V2.771C16.4555 2.33 16.1196 2 15.6948 2C15.2601 2 14.9341 2.33 14.9341 2.771V5.82C14.9341 6.261 15.2601 6.59 15.6948 6.59Z" />
    </Icon>
  );
};

import { FC } from "react";

import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { CreatableSelect, Select } from "src/ui/select";
import { getSelectValue } from "src/ui/select/select";
import {
  OBJECT_LABEL,
  DEFAULT_OBJECT_LABEL,
  OBJECT_DESCRIPTION,
  DEFAULT_OBJECT_DESCRIPTION,
  OBJECT_RELOAD_TOOLTIP,
  DEFAULT_OBJECT_RELOAD_TOOLTIP,
} from "src/utils/destinations";

type Props = {
  path?: string[];
  value?: any;
  options?: any;
  error?: any;
  onChange?: (object: any) => void;
  reload?: () => void;
  loading?: boolean;
  placeholder?: string;
  isCreatable?: boolean;
  formatCreateLabel?: (value: string) => string;
};

export const ObjectField: FC<Readonly<Props>> = ({
  value: propValue,
  error: propError,
  options,
  path,
  onChange,
  reload,
  loading,
  placeholder,
  isCreatable,
  formatCreateLabel = (value) => `Create object "${value}"...`,
}) => {
  const { slug, errors, config, setConfig } = useDestinationForm();

  const value = propValue || config?.object;
  const error = propError || errors?.object;

  return (
    <Section>
      <Field
        description={
          (path ? get(OBJECT_DESCRIPTION[slug ?? ""], path) : OBJECT_DESCRIPTION[slug ?? ""]) || DEFAULT_OBJECT_DESCRIPTION
        }
        error={error}
        label={(path ? get(OBJECT_LABEL[slug ?? ""], path) : OBJECT_LABEL[slug ?? ""]) || DEFAULT_OBJECT_LABEL}
        size="large"
      >
        {(isCreatable && (
          <CreatableSelect
            formatCreateLabel={formatCreateLabel}
            isError={Boolean(error)}
            isLoading={loading}
            isValidNewOption={(inputValue) => {
              return Boolean(inputValue) && !getSelectValue(inputValue, options);
            }}
            options={options}
            placeholder={placeholder || "Select or add an object..."}
            reload={reload}
            value={value}
            width="340px"
            onChange={(selected) => {
              if (onChange) {
                onChange(selected?.value);
              } else {
                setConfig({ object: selected?.value });
              }
            }}
            onCreateOption={(newValue) => {
              if (onChange) {
                onChange(newValue);
              } else {
                setConfig({ object: newValue });
              }
            }}
          />
        )) || (
          <Select
            isError={Boolean(error)}
            isLoading={loading}
            options={options}
            placeholder={placeholder || "Select an object..."}
            reload={reload}
            reloadTooltip={
              (path ? get(OBJECT_RELOAD_TOOLTIP[slug ?? ""], path) : OBJECT_RELOAD_TOOLTIP[slug ?? ""]) ||
              DEFAULT_OBJECT_RELOAD_TOOLTIP
            }
            value={value}
            width="340px"
            onChange={(selected) => {
              if (onChange) {
                onChange(selected?.value);
              } else {
                setConfig({ object: selected?.value });
              }
            }}
          />
        )}
      </Field>
    </Section>
  );
};

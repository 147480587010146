import { components } from "react-select";

export const MultiValue = (props) => {
  const newProps = {
    ...props,
    removeProps: {
      ...props.removeProps,
      onClick: (event) => {
        event.stopPropagation();
        props.removeProps.onClick(event);
      },
    },
  };

  return <components.MultiValue {...newProps} />;
};

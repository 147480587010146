import { FC, ReactNode } from "react";

import { ThemeProvider as Provider } from "theme-ui";

import theme from ".";
import { GlobalStyles } from "./global-styles";

interface Props {
  children: ReactNode;
}

export const ThemeProvider: FC<Props> = ({ children }) => {
  return (
    <Provider theme={theme}>
      <GlobalStyles />
      {children}
    </Provider>
  );
};

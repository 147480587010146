import { FC, ReactNode } from "react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Box, Row } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { Logo } from "src/ui/brand";
import { Circle } from "src/ui/circle";
import { getInitials } from "src/utils/user";

import { Header } from "./header";
import { UserMenu } from "./user-menu";

export const LogoHeader: FC<Readonly<{ children?: ReactNode }>> = ({ children }) => {
  const { user } = useUser();

  return (
    <Header>
      <Row align="center" border="1px solid" borderColor="gray.300" color="gray.600" height="64px" px={12} width="100%">
        <Row flex={1}>
          <Logo size="130px" textColor="var(--chakra-colors-forest-700)" variant="full" />
        </Row>
        {children}
        <Row align="center" flex={1} justify="flex-end" overflow="hidden">
          <UserMenu user={user || { name: "unknown user", email: "<no email>" }}>
            <Row
              _hover={{
                borderColor: "rgba(255,255,255,.5)",
              }}
              align="center"
              border="1px solid rgba(255,255,255,.3)"
              borderRadius="6px"
              gap={2.5}
              justify={["center", "center", "center", "flex-start"]}
              px={[0, 0, 0, 2.5]}
              py={2}
              width="100%"
            >
              <Circle color="avocado" radius="32px" sx={{ color: "forest", fontWeight: "bold", fontSize: 0 }}>
                {getInitials(user?.name)}
              </Circle>
              <Box
                color="inherit"
                display={["none", "none", "none", "block"]}
                fontSize="sm"
                fontWeight={500}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {user?.email}
              </Box>
            </Row>
          </UserMenu>
          <ChevronDownIcon width="14px" />
        </Row>
      </Row>
    </Header>
  );
};

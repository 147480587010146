import { createContext, useContext, useRef, useState, FC, ReactNode } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { fromPairs, noop, omit } from "lodash";

type Props = { children: ReactNode };
export type TrackedError = {
  error: string | null;
  firstAppearedAt: number;
};
type FormErrorContextType = {
  fieldErrors: Record<string, Record<string, TrackedError>>;
  lastValidatedAt: number;

  getErrors(errorKey: string): Record<string, string | null> | null;
  removeErrors(keys: string[]): void;
  setFieldError(key: string, errors: Record<string, string | null> | null): void;
  hasValidationErrors(): boolean;
};

export const FormErrorContext = createContext<FormErrorContextType>({
  fieldErrors: {},
  lastValidatedAt: new Date().getTime(),

  getErrors: noop,
  removeErrors: noop,
  setFieldError: noop,
  hasValidationErrors: () => false,
} as FormErrorContextType);
export const useFormErrorContext = () => useContext(FormErrorContext);

export const mergeErrors = (oldErrors: Record<string, TrackedError> = {}, newErrors: Record<string, string | null> = {}) => {
  const updatedErrors = {};

  for (const key in newErrors) {
    updatedErrors[key] = {
      error: newErrors[key] ?? null,
      firstAppearedAt: oldErrors[key]?.firstAppearedAt ?? new Date().getTime(),
    };
  }

  return updatedErrors;
};

export const useFormErrors = () => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const [errorMap, setErrorMap] = useState<Record<string, Record<string, TrackedError>>>({});
  const [lastValidatedAt, setLastValidatedAt] = useState(new Date().getTime());

  // For some reason `hasValidationErrors` keeps a stale closure of errorMap
  const errorMapRef = useRef(errorMap);
  errorMapRef.current = errorMap;

  const getErrors = (errorKey: string): Record<string, string | null> | null => {
    if (!appAudienceQueryBuilderValidation) {
      return null;
    }

    if (!errorMapRef.current) {
      return null;
    }

    const errors = errorMapRef.current[errorKey];

    if (!errors) {
      return null;
    }

    const visibleErrors = Object.entries(errors)
      .filter(([, error]) => error.firstAppearedAt < lastValidatedAt)
      .map(([key, { error }]) => [key, error]);

    return fromPairs(visibleErrors);
  };

  const setFieldError = (key: string, errors: Record<string, string | null>) => {
    setErrorMap((prevErrorMap) => ({ ...prevErrorMap, [key]: mergeErrors(prevErrorMap[key], errors) }));
  };

  const removeErrors = (keys: string[]) => setErrorMap((prevErrorMap) => omit(prevErrorMap, keys));

  const hasValidationErrors = () => {
    if (!appAudienceQueryBuilderValidation) {
      return false;
    }

    setLastValidatedAt(new Date().getTime());

    // Flatten all errors into one flat array
    const errors = Object.values(errorMapRef.current)
      .flatMap(Object.values)
      .map(({ error }) => error);

    return errors.some(Boolean);
  };

  return {
    fieldErrors: errorMap,
    lastValidatedAt,

    getErrors,
    removeErrors,
    setFieldError,
    hasValidationErrors,
  };
};

/**
 * A thin context that can track errors for a form. Anything more complex than this
 * should be managed by a form library. See: formik for something good!
 */
export const FormErrorProvider: FC<Props> = ({ children }) => {
  const value = useFormErrors();

  return <FormErrorContext.Provider value={value}>{children}</FormErrorContext.Provider>;
};

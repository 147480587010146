import { useMemo, useState } from "react";

import { set } from "lodash";

import { ModelColumnsOrderBy, SyncsOrderBy } from "src/graphql";
import { SortState } from "src/ui/table";

type ModelSortState = {
  columns: SortState;
  syncs: SortState;
};

/**
 * This hook is for storing and updating the sort parameters for the `useModelQuery` hook.
 */
export const useModelSort = () => {
  const [modelSort, setModelSort] = useState<ModelSortState>({
    columns: {
      sortKey: null,
      sortDirection: "desc_nulls_last",
    },
    syncs: {
      sortKey: null,
      sortDirection: "desc_nulls_last",
    },
  });

  /**
   * Curried function used to set sort parameters for the model query.
   */
  const curriedOnSort = (tableKey: "columns" | "syncs") => (sortKey: string) => {
    setModelSort((prevModelSort) => {
      const { sortKey: prevSortKey, sortDirection: prevSortDirection } = prevModelSort[tableKey];

      // default is no sorting
      let updatedSort: SortState = { sortKey: null, sortDirection: "desc_nulls_last" };

      if (sortKey !== prevSortKey) {
        updatedSort = { sortKey, sortDirection: "asc_nulls_first" };
      } else if (prevSortDirection === "asc_nulls_first") {
        updatedSort = { sortKey, sortDirection: "desc_nulls_last" };
      }

      return { ...prevModelSort, [tableKey]: updatedSort };
    });
  };

  const syncsOrderBy = useMemo<SyncsOrderBy | undefined>(() => {
    if (!modelSort.syncs.sortKey) {
      return undefined;
    }

    return set({}, modelSort.syncs.sortKey, modelSort.syncs.sortDirection);
  }, [modelSort.syncs]);

  const columnsOrderBy = useMemo<ModelColumnsOrderBy | undefined>(() => {
    if (!modelSort.columns.sortKey) {
      return undefined;
    }

    return set({}, modelSort.columns.sortKey, modelSort.columns.sortDirection);
  }, [modelSort.columns]);

  return { curriedOnSort, syncsOrderBy, columnsOrderBy };
};

import { Validator, BaseType, Props } from "./base";

export interface ReferenceType extends BaseType {
  type: "REFERENCE";
}

export function reference(opts?: Props<ReferenceType>): ReferenceType {
  return {
    ...opts,
    async: true,
    type: "REFERENCE",
  };
}

export const validator: Validator<ReferenceType> = (_) => {
  return async (_) => {
    // TODO
  };
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const FolderIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M5.975 7A.975.975 0 0 0 5 7.975v7.9c0 .538.437.975.975.975h11.85a.975.975 0 0 0 .975-.975V9.95a.975.975 0 0 0-.975-.975h-5.352L10.498 7H5.975ZM3 7.975A2.975 2.975 0 0 1 5.975 5h5.352l1.975 1.975h4.523A2.975 2.975 0 0 1 20.8 9.95v5.925a2.975 2.975 0 0 1-2.975 2.975H5.975A2.975 2.975 0 0 1 3 15.875v-7.9Z"
        clipRule="evenodd"
        fill="currentColor"
      />
    </Icon>
  );
};

import { FC } from "react";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Box, Column, LinkButton, Text, Heading } from "@hightouchio/ui";

import { Card } from "src/ui/card";

type Props = {
  title: string;
  subtitle?: string;
  linkText?: string;
  to?: string;
};

export const Warning: FC<Props> = ({ title, subtitle, linkText, to }) => {
  return (
    <Card p={10} sx={{ maxWidth: "540px", width: "100%", mx: "auto", mt: 24 }}>
      <Column align="center" gap={6} sx={{ color: "gray.900", textAlign: "center" }}>
        <Box color="grass.500">
          <ExclamationCircleIcon fill="currentColor" width="50px" />
        </Box>
        <Heading size="lg">{title}</Heading>
        <Text>{subtitle}</Text>
        {to && <LinkButton href={to}>{linkText || ""}</LinkButton>}
      </Column>
    </Card>
  );
};

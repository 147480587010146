import { components } from "react-select";
import { Flex, Box, Image } from "theme-ui";

import EmptySVG from "./empty.svg";

export const SelectMenu = (props) => {
  const {
    children,
    selectProps: { empty, tip },
    ...rest
  } = props;

  return (
    <components.Menu {...rest}>
      <Box
        sx={{
          border: "small",
          boxShadow: "xs",
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        {empty && !props.options.length ? (
          <>
            <Image src={EmptySVG} sx={{ mx: "auto" }} />
            <Flex sx={{ p: 2, pb: 8, color: "base.4", maxWidth: "216px", textAlign: "center", fontSize: 0, mx: "auto" }}>
              {empty}
            </Flex>
          </>
        ) : (
          children
        )}
        {tip && Boolean(props.options.length) && (
          <Flex sx={{ p: 2, bg: "secondaries.0", color: "base.4", fontSize: 0 }}>{tip}</Flex>
        )}
      </Box>
    </components.Menu>
  );
};

import { format } from "numerable";

export const abbreviateNumber = (num: number): string => (num < 1000 ? String(num) : format(num, "0.00a"));

export const commaNumber = (num: number): string => (num < 100000000 ? format(num, "0,0") : abbreviateNumber(num));

export const truncateNumber = (num: number): string => (Number.isInteger(num) ? String(num) : format(num, "0.00"));

export const ordinalSuffix = (num: number): string => {
  if (num > 3 && num < 21) return "th";

  switch (num % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

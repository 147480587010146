import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ArrayIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M15 4v2h3v12h-3v2h5V4zM4 20h5v-2H6V6h3V4H4z" />;
    </Icon>
  );
};

import { FC, useState } from "react";

import { Link, Checkbox, CheckboxGroup, useToast, SectionHeading } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Grid, Text } from "theme-ui";

import { FeatureFull } from "src/components/feature-gates";
import { usePermission } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { Row } from "src/ui/box";

import { SidebarForm } from "../../components/page";
import { Permission } from "../../components/permission";
import { useUpdateSourceV2Mutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Field } from "../../ui/field";

export type WarehouseSyncLogsConfig = {
  changelog?: {
    audience: boolean;
  };
  snapshot?: {
    audience: boolean;
  };
  syncRuns?: {
    audience: boolean;
  };
};

type Props = {
  source: {
    id: string;
    warehouse_history_config: Record<string, any>;
  };
};

export const SyncLog: FC<Readonly<Props>> = ({ source }) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const [updatingWarehouseSyncLogsConfig, setUpdatingWarehouseSyncLogsConfig] = useState<boolean>(false);
  const [changelogEnabled, setChangelogEnabled] = useState<boolean>(source?.warehouse_history_config?.changelog ? true : false);
  const [snapshotEnabled, setSnapshotEnabled] = useState<boolean>(source?.warehouse_history_config?.snapshot ? true : false);
  const [syncRunEnabled, setSyncRunEnabled] = useState<boolean>(source?.warehouse_history_config?.syncRuns ? true : false);
  const [changelogAudienceEnabled, setChangelogAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.changelog?.audience ? true : false,
  );
  const [snapshotAudienceEnabled, setSnapshotAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.snapshot?.audience ? true : false,
  );
  const [syncRunAudienceEnabled, setSyncRunAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.syncRuns?.audience ? true : false,
  );

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const updateWarehouseSyncLogsConfig = async () => {
    setUpdatingWarehouseSyncLogsConfig(true);
    const warehouseSyncLogsConfig: WarehouseSyncLogsConfig = {};
    if (changelogEnabled) {
      warehouseSyncLogsConfig.changelog = {
        audience: changelogAudienceEnabled,
      };
    }
    if (snapshotEnabled) {
      warehouseSyncLogsConfig.snapshot = {
        audience: snapshotAudienceEnabled,
      };
    }
    if (syncRunEnabled) {
      warehouseSyncLogsConfig.syncRuns = {
        audience: syncRunAudienceEnabled,
      };
    }
    await updateSource({
      id: String(source.id),
      object: {
        warehouse_history_config: warehouseSyncLogsConfig,
      },
      append: {},
    });

    setUpdatingWarehouseSyncLogsConfig(false);

    toast({
      id: "update-sync-config",
      title: "Sync configuration was updated",
      variant: "success",
    });
  };

  const updatePermission = usePermission();
  const { appWarehouseSyncLogsEnabled } = useFlags();

  return (
    <FeatureFull
      enabled={workspace?.organization?.plan?.sku === "business_tier" || appWarehouseSyncLogsEnabled}
      featureDetails={{
        pitch: "Row-level sync observability right in your data warehouse",
        description:
          "With warehouse sync logs, Hightouch will write your sync results back into your data warehouse provider. You can then use your own BI tool or SQL editor to run custom analysis or monitor the health of your pipelines.",
        bullets: [
          "Audit whether individual records were successfully synced to your business tool",
          "Monitor for common sync errors and create custom alerts",
          "Integrate with your existing BI dashboards to visualize usage trends",
          "Recommended for businesses that wish to bring their own observability tooling",
        ],
        video: { src: "https://cdn.sanity.io/files/pwmfmi47/production/c81b1d3e32df55f57ca5cdec94543144baaebad4.mp4" },
      }}
      featureName="warehouse sync logs"
    >
      <Row sx={{ alignItems: "flex-start" }}>
        <Grid gap={6} mr={8}>
          <SectionHeading>Warehouse sync logs</SectionHeading>
          <Text>
            <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`}>Warehouse sync logs</Link> writes
            information on the status of each row of your sync back into your warehouse. This is useful for analyzing sync
            failures and changes in your data over time.
            <br />
            <br />
            Specific use cases include{" "}
            <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
              analyzing common syncs errors
            </Link>
            ,{" "}
            <Link
              href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}
            >
              visualizing when users enter and exit audiences
            </Link>
            , and{" "}
            <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}>
              tracking the status of rows across syncs
            </Link>
            .
          </Text>
          <Field
            description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
            label="Tables"
            size="large"
          >
            <CheckboxGroup>
              <Checkbox
                description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                isChecked={snapshotEnabled}
                isDisabled={updatePermission.unauthorized}
                label="Snapshot"
                onChange={(event) => {
                  setSnapshotEnabled(event.target.checked);
                }}
              />
              {snapshotEnabled && (
                <Row sx={{ pl: "7" }}>
                  <Checkbox
                    description="Only enable this sync log table for audience syncs"
                    isChecked={snapshotAudienceEnabled}
                    isDisabled={updatePermission.unauthorized}
                    label="Audience syncs only"
                    onChange={(event) => {
                      setSnapshotAudienceEnabled(event.target.checked);
                    }}
                  />
                </Row>
              )}
              <Checkbox
                description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                isChecked={changelogEnabled}
                isDisabled={updatePermission.unauthorized}
                label="Changelog"
                onChange={(event) => {
                  setChangelogEnabled(event.target.checked);
                }}
              />
              {changelogEnabled && (
                <Row sx={{ pl: "7" }}>
                  <Checkbox
                    description="Only enable this sync log table for audience syncs"
                    isChecked={changelogAudienceEnabled}
                    isDisabled={updatePermission.unauthorized}
                    label="Audience syncs only"
                    onChange={(event) => {
                      setChangelogAudienceEnabled(event.target.checked);
                    }}
                  />
                </Row>
              )}
              <Checkbox
                description="Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                isChecked={syncRunEnabled}
                isDisabled={updatePermission.unauthorized}
                label="Sync runs"
                onChange={(event) => {
                  setSyncRunEnabled(event.target.checked);
                }}
              />
              {syncRunEnabled && (
                <Row sx={{ pl: "7" }}>
                  <Checkbox
                    description="Only enable this sync log table for audience syncs"
                    isChecked={syncRunAudienceEnabled}
                    isDisabled={updatePermission.unauthorized}
                    label="Audience syncs only"
                    onChange={(event) => {
                      setSyncRunAudienceEnabled(event.target.checked);
                    }}
                  />
                </Row>
              )}
            </CheckboxGroup>
          </Field>
        </Grid>

        <SidebarForm
          hideInviteTeammate
          hideSendMessage
          buttons={[
            <Permission key={0}>
              <Button
                loading={updatingWarehouseSyncLogsConfig}
                sx={{ width: "100%" }}
                onClick={() => updateWarehouseSyncLogsConfig()}
              >
                Save
              </Button>
            </Permission>,
          ]}
          docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/`}
          invite="If you need help setting up this sync"
          name="warehouse sync logs"
        />
      </Row>
    </FeatureFull>
  );
};

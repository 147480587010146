import { useState } from "react";

import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Box, Button, Column, IconButton, Row, TextInput, Tooltip, Box as HightouchUiBox, Switch } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { Permission } from "../permission";

type enableEncryptionProps = { value: string; isSecret: boolean };

type KeyValueMappingProps = { mapping: any; setMapping: any; enableEncryption?: boolean };

export const KeyValueMapping = ({ mapping, setMapping, enableEncryption }: KeyValueMappingProps) => {
  const [newKey, setNewKey] = useState<null | string>(null);
  const [isSecret, setIsSecret] = useState(false);

  const setKeyValueMappingValue = ({ key, value }: { key: string; value: string | enableEncryptionProps }) => {
    const newMapping = { ...mapping };
    newMapping[key] = value;
    setMapping(newMapping);
  };

  const addValue = (): void => {
    if (!newKey) {
      return;
    }
    const mappingValue = enableEncryption ? { value: "", isSecret } : "";
    setKeyValueMappingValue({ key: newKey, value: mappingValue });
    setNewKey(null);
    setIsSecret(false);
  };

  const changeValue = ({ key, value }: { key: string; value: string }): void => {
    const valueBasedOnType =
      typeof mapping[key] === "object" ? { value, isSecret: (mapping[key]?.isSecret || false) as boolean } : value;

    setKeyValueMappingValue({ key, value: valueBasedOnType });
  };

  const retrieveInputValue = (mapping: string | { value: string; isSecret: boolean }): string => {
    if (typeof mapping === "string") {
      return mapping;
    }

    if (typeof mapping === "object" && mapping.value) {
      return mapping.value;
    }

    return "";
  };

  const hasHeaders = Object.keys(mapping || {}).length > 0;

  return (
    <Column gap={3}>
      {Object.keys(mapping || {})?.map((key, i) => (
        <Box key={i} display="grid" gap={4} gridTemplateColumns="1fr 16px 1fr 36px">
          <TextInput isReadOnly={true} value={key} width="auto" />

          <Box alignItems="center" display="flex" justifyContent="center">
            <Box as={ArrowRightIcon} boxSize={4} color="gray.500" />
          </Box>

          <TextInput
            placeholder={`Value of ${key}`}
            type={mapping[key]?.isSecret ? "password" : "text"}
            value={retrieveInputValue(mapping[key])}
            width="auto"
            onChange={(value) => changeValue({ key, value: value.target.value })}
          />

          <Box flex="none">
            <Permission>
              <IconButton
                aria-label="Delete header"
                icon={XMarkIcon}
                onClick={() => {
                  const newMapping = { ...mapping };
                  delete newMapping[key];
                  setMapping(newMapping);
                }}
              />
            </Permission>
          </Box>
        </Box>
      ))}
      <Permission>
        <Box display="grid" gap={4} gridTemplateColumns={hasHeaders ? "1fr 16px 1fr 36px" : "1fr 16px 1fr 36px"}>
          <TextInput placeholder="Key" value={newKey || ""} width="auto" onChange={(value) => setNewKey(value.target.value)} />

          <Box />

          <Row alignItems="center" gridColumn={hasHeaders ? undefined : "span 2 / span 2"} justifyContent="flex-end">
            {enableEncryption && (
              <HightouchUiBox alignItems="center" display="flex" gap={3} mr={4}>
                <Switch isChecked={isSecret} onChange={(value) => setIsSecret(value)} />
                <Text
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Secret
                </Text>
              </HightouchUiBox>
            )}

            <Tooltip
              isDisabled={Boolean(newKey && !mapping?.[newKey])}
              message={!newKey ? "Enter header key first" : "Header with this key already exists"}
            >
              <Button isDisabled={!newKey || mapping?.[newKey]} onClick={addValue}>
                Add key
              </Button>
            </Tooltip>
          </Row>

          {hasHeaders && <Box />}
        </Box>
      </Permission>
    </Column>
  );
};

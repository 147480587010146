import { capitalize } from "lodash";

import { SchemaModelType } from "src/types/schema";
import { Condition, ConditionType, AndCondition, OrCondition, AndOrCondition } from "src/types/visual";

export function toSingleCondition(
  conditions: AndCondition[] | OrCondition[] | Condition[] | undefined,
): AndOrCondition<AndCondition | OrCondition>[] | undefined {
  if (!conditions) {
    return undefined;
  }

  // Tech debt: conditions may only have one condition at the top level.
  // See https://carryinternal.slack.com/archives/C024T56QQLW/p1669910261515869
  if (Array.isArray(conditions)) {
    if (conditions.length === 0) {
      return [];
    }

    if (conditions.length > 1) {
      return [{ type: ConditionType.And, conditions }];
    }

    /* Start: new format. One condition at the top level */

    const condition = conditions?.[0];
    const hasTopLevelAndOrCondition = condition?.type === ConditionType.And || condition?.type === ConditionType.Or;

    // Verify top level condition is an and/or
    // If not, wrap it with `AND` condition
    if (
      // Has not been formatted yet
      !hasTopLevelAndOrCondition
    ) {
      return [{ type: ConditionType.And, conditions }];
    }

    // There is only one AND or OR condition that has been formatted correctly.
    // Its immediate children (if there are any) are OR conditions
    return conditions as AndCondition<AndCondition>[];

    /* End: new format */
  }

  return [conditions];
}

const schemaModelTypePaths: Record<SchemaModelType, string> = {
  [SchemaModelType.Parent]: "parent-models",
  [SchemaModelType.Related]: "related-models",
  [SchemaModelType.Event]: "events",
};

export const getSchemaModelTypeFromPath = (path: string) => {
  const type = Object.entries(schemaModelTypePaths).find(([, p]) => p === path)?.[0];
  return type as SchemaModelType;
};

export const getSchemaModelType = (object: { event: any; primary_key: string | null }) => {
  if (!object.event && object.primary_key) {
    return SchemaModelType.Parent;
  }
  if (object.event) {
    return SchemaModelType.Event;
  }
  return SchemaModelType.Related;
};

export const getSchemaModelTypeNames = (type: SchemaModelType) => {
  const typeName = type === SchemaModelType.Event ? "event" : `${type} model`;
  const typePath = schemaModelTypePaths[type];
  const typeNameCapitalized = capitalize(typeName);

  return {
    typePath,
    typeName,
    typeNameCapitalized,
  };
};

// THIS IS COPIED FROM packages/backend/sdk/interfaces/destination/mapping.ts PENDING REFACTOR
// We should be able to move this to the lib package, making it importable by the frontend

export enum StandardFieldType {
  STRING = "STRING",
  ENUM = "ENUM",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  EMAIL = "EMAIL",
  OBJECT = "OBJECT",
  ARRAY = "ARRAY",
  REFERENCE = "REFERENCE",
  UNKNOWN = "UNKNOWN",
}

/**
 * This is what most of the destinations would need to apply mappings to data.
 */

export type StandardMapping = {
  type?: "standard";

  // Field from the row.
  from: string;

  // Field on the resulting entity.
  to: string;

  ignoreNull?: boolean;
  disablePIIHashing?: boolean;
  fieldType?: string;
};

export function isStandardMapping(m: unknown): m is StandardMapping {
  return isMapping(m) && (m.type === "standard" || typeof m["from"] === "string");
}
/**
 * Represents a mapping that contains a reference (ie foreign key) to another entity
 * or object.
 */

export type ReferenceMapping = {
  type?: "reference";

  // The lookup entity.
  lookup: {
    by: string; // Field to perform the lookup.
    byType: StandardFieldType; // Type of the field.
    from: string; // Column that contains the field.
    object: string; // Entity (ie. Company)
    nullIfNotFound?: boolean; // Set the value to null if the reference is not found
  };

  // Field on the resulting entity.
  to: string;
};

export type KeyValueMapping = Record<string, string | { value: string; isSecret: boolean }>;

export function isReferenceMapping(m: unknown): m is ReferenceMapping {
  return isMapping(m) && (m.type === "reference" || (typeof m["lookup"] === "object" && m["lookup"] !== null));
}
/**
 * Represents a static value that gets determined by the sync configuration.
 */

export type StaticMapping = {
  type?: "static";
  to: string;
  value: unknown;
  valueType: StandardFieldType.BOOLEAN | StandardFieldType.DATETIME | StandardFieldType.NUMBER | StandardFieldType.STRING;
};

export function isStaticMapping(m: unknown): m is StaticMapping {
  return isMapping(m) && (m.type === "static" || (m["value"] !== undefined && m["valueType"] !== undefined));
}

export type VariableMapping = {
  type?: "variable";
  to: string;
  variable: "current-timestamp" | "model-name" | "model-id" | "sync-id" | "sync-run-id";
};

export function isVariableMapping(m: unknown): m is VariableMapping {
  return isMapping(m) && (m.type === "variable" || typeof m["variable"] === "string");
}

export type TemplateMapping = {
  type?: "template";
  to: string;
  template: string;
};

export function isTemplateMapping(m: unknown): m is TemplateMapping {
  return isMapping(m) && (m.type === "template" || typeof m["template"] === "string");
}

// WARNING!! If you add a new Mapping type that references columns, you
// *must* update `lib/query/visual/sync-transform` as well to handle
// converting visual column references into string column names.
export type Mapping =
  | StandardMapping
  | ReferenceMapping
  | StaticMapping
  | VariableMapping
  // XXX: TemplateMappings do not currently support visual column
  // references.
  | TemplateMapping;

export function isMapping(m: unknown): m is Mapping {
  return typeof m === "object" && m !== null && m !== undefined && typeof m["to"] === "string" && !m["destinationOnlyMapping"];
}

export function getMappingType(m: unknown): "standard" | "reference" | "static" | "variable" | "template" {
  if (isStandardMapping(m)) {
    return "standard";
  } else if (isReferenceMapping(m)) {
    return "reference";
  } else if (isStaticMapping(m)) {
    return "static";
  } else if (isVariableMapping(m)) {
    return "variable";
  } else if (isTemplateMapping(m)) {
    return "template";
  }

  throw new Error("Unrecognized mapping");
}

export type Column = { from: string };

export type ColumnOrConstant<C = string> = Column | C;

export function isColumn(c: unknown): c is Column {
  return (
    typeof c === "object" && c !== null && c !== undefined && typeof c["from"] === "string" && !c["destinationOnlyMapping"]
  );
}

export function isMappingArray(m: unknown): m is Mapping[] {
  return Array.isArray(m) && m.every(isMapping);
}

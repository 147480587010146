import { useCallback } from "react";

import slugify from "@sindresorhus/slugify";
import { customAlphabet } from "nanoid";
import { lowercase, numbers } from "nanoid-dictionary";
import { useQueryClient } from "react-query";

import { useGenerateResourceSlugQuery } from "src/graphql";

const nanoid = customAlphabet(lowercase + numbers, 5);

export const generateSlug = (prefix: string) => slugify(`${prefix}-${nanoid()}`);

export enum ResourceType {
  Source = "connections",
  Destination = "destinations",
  Sync = "syncs",
  SyncTemplate = "sync_templates",
  Model = "segments",
  Sequence = "sync_sequences",
}

export const useResourceSlug = (type: ResourceType) => {
  const client = useQueryClient();

  const getSlug = useCallback(
    async (name: string) => {
      const variables = {
        resourceType: type,
        slug: slugify(name),
      };

      const response = await client.fetchQuery({
        queryFn: useGenerateResourceSlugQuery.fetcher(variables),
        queryKey: useGenerateResourceSlugQuery.getKey(variables),
      });

      return response.generateResourceSlug;
    },
    [type, client],
  );

  return { getSlug };
};

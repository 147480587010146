import { range } from "lodash";

export const HOURS = range(1, 13);

export const MINUTES = range(0, 60);

export const HOUR_OPTIONS = HOURS.map((hour) => ({ label: String(hour), value: hour }));

export const MINUTE_OPTIONS = MINUTES.map((minute) => ({ label: minute < 10 ? `0${minute}` : String(minute), value: minute }));

export const AM_PM_OPTIONS = [
  {
    label: "AM",
    value: "AM",
  },
  {
    label: "PM",
    value: "PM",
  },
];

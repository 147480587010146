import { FC, ReactNode } from "react";

import { Column, Row, BoxProps, useToast } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";

import {
  AndCondition,
  AndOrCondition,
  Audience,
  AudienceParent,
  Condition,
  ConditionType,
  FilterableColumn,
  FunnelCondition,
  OrCondition,
  Relationship,
  TraitDefinition,
} from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import {
  addSubcondition,
  updateSubcondition,
  removeSubcondition as removeConditionAtIndex,
  groupSubcondition,
  ungroupSubcondition,
} from "../utils/condition-builders";
import { AddFilterButton, AndOrToggleButton } from "./condition-buttons";
import { ConditionWrapper } from "./condition-wrapper";
import { EventFilter } from "./event-filter";
import { GroupIndicatorBar } from "./group-indicator-bar";
import { NumberOfFilter } from "./number-of-filter";
import { PropertyFilter } from "./property-filter";
import { ReferencePropertyFilter } from "./reference-property-filter";
import { SegmentSetFilter } from "./segment-set-filter";

const MAX_NESTING_LEVEL = 2;

export interface CommonProps {
  audience: Audience | undefined;
  parent: AudienceParent | undefined | null;
  onRemove?: () => void;
  onUngroup?: () => void;
  isEventTrait?: boolean;
}

interface ConditionFieldProps extends CommonProps {
  columns?: FilterableColumn[];
  events?: Relationship[];
  relationships?: Relationship[];
  traits?: TraitDefinition[];
  condition: AndOrCondition<Condition>;
  level?: number;
  referenceColumns?: FilterableColumn[];
  showSecondaryFilter?: boolean; // For property filter
  onChange: (updatedCondition: Condition | AndCondition | OrCondition) => void;
}

interface AndOrConditionFieldProps extends ConditionFieldProps {
  condition: AndCondition<AndOrCondition<Condition>> | OrCondition<AndOrCondition<Condition>>;
  level?: number;
}

export interface FilterProps<TCondition extends Condition | FunnelCondition> extends CommonProps {
  columns?: FilterableColumn[];
  events?: Relationship[];
  traits?: TraitDefinition[];
  relationships?: Relationship[];
  condition: TCondition;
  onChange: (updates: Partial<TCondition>) => void;
}

export const AndOrConditionField: FC<Readonly<AndOrConditionFieldProps>> = ({
  condition,
  level = 0,
  onChange,
  onRemove,
  onUngroup,
  ...props
}) => {
  const isAudienceCreated = props.audience?.id != null;

  const { toast } = useToast();
  const { selectCondition } = useQueryBuilderContext();

  const add = (newCondition: Condition) => {
    onChange(addSubcondition(condition, newCondition));
  };

  const update = (index: number) => (newCondition: Condition) => {
    onChange(updateSubcondition(index, condition, newCondition));
  };

  const remove = (index: number) => () => {
    if (condition.conditions.length === 1) {
      // Remove the parent object (cascading delete)
      onRemove?.();
    } else {
      onChange(removeConditionAtIndex(index, condition));
    }
  };

  const insertGroup = (index: number) => () => {
    onChange(groupSubcondition(index, condition));
  };

  const removeGroup = (index: number) => () => {
    onChange(ungroupSubcondition(index, condition));
  };

  return (
    <>
      {condition.conditions.map((nestedCondition, index) => {
        const duplicate = () => {
          add({ ...nestedCondition });

          toast({
            id: "duplicate-condition-toast",
            title: "Condition duplicated",
            variant: "success",
          });
        };
        const nestedConditionIsAndOr = [ConditionType.And, ConditionType.Or].includes(nestedCondition.type);

        const sharedProps = {
          condition: nestedCondition,
          level: level + 1,
          onChange: update(index),
          onRemove: remove(index),
        };

        if (nestedConditionIsAndOr) {
          return (
            <Column key={index} gap={4} mb={4} width="100%">
              <Row>
                <GroupIndicatorBar conditionType={condition.type} />

                <ConditionField {...props} {...sharedProps} onUngroup={removeGroup(index)} />
              </Row>

              <AndOrToggleButton
                conditionType={condition.type}
                onClick={() =>
                  onChange({
                    ...condition,
                    type: condition.type === ConditionType.And ? ConditionType.Or : ConditionType.And,
                  })
                }
              />
            </Column>
          );
        }

        const canAddTrait = nestedCondition.type === ConditionType.Event && isAudienceCreated;
        const addTrait = canAddTrait ? () => selectCondition(nestedCondition) : undefined;

        return (
          <ConditionWrapper
            key={index}
            conditionType={condition.type}
            disableGrouping={level >= MAX_NESTING_LEVEL}
            onChange={(conditionType) => onChange({ ...condition, type: conditionType })}
            onAddTrait={addTrait}
            onDuplicate={duplicate}
            onGroup={insertGroup(index)}
            onUngroup={onUngroup}
            onRemove={remove(index)}
          >
            <ConditionField
              {...props}
              {...sharedProps}
              onRemove={sharedProps.condition.type === ConditionType.Property ? undefined : sharedProps.onRemove}
            />
          </ConditionWrapper>
        );
      })}

      <AddFilterButton {...props} conditionType={condition.type} onChange={add} />
    </>
  );
};

export const ConditionField: FC<Readonly<ConditionFieldProps>> = ({ condition, level = 0, onChange, ...props }) => {
  const updateCondition = <TCondition,>(updates: Partial<TCondition>) => {
    onChange(immutableUpdate(condition, { $merge: updates }));
  };

  switch (condition.type) {
    case ConditionType.And:
    case ConditionType.Or:
      return (
        <Row ml={4} width="100%">
          <Column width="100%">
            <AndOrConditionField {...props} condition={condition} level={level} onChange={onChange} />
          </Column>
        </Row>
      );
    case ConditionType.SegmentSet:
      return <SegmentSetFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.Property:
      return <PropertyFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.ReferenceProperty:
      return <ReferencePropertyFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.NumberOf:
      return <NumberOfFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.Event:
      return <EventFilter {...props} condition={condition} onChange={updateCondition} />;
    default:
      return null;
  }
};

export const HStack: FC<{ children: ReactNode } & BoxProps> = ({ children, gap, sx, ...props }) => (
  <Row
    align="center"
    width="inherit"
    sx={{
      "& > *:not(:last-child)": { mr: gap, flexShrink: 0 },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Row>
);

export const updateSubconditions = (onChange, subconditions, index) => (updates: any) => {
  onChange({ subconditions: immutableUpdate(subconditions, { [index]: { $merge: updates } }) });
};

export const removeSubcondition = (onChange, subconditions, index) => () => {
  onChange({ subconditions: subconditions.filter((_, i) => i !== index) });
};

import { FC, useEffect, useState } from "react";

import { Button, ButtonGroup, Tooltip, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useNavigate } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { validateCustomQuery } from "src/components/sources/forms/custom-query";
import { QueryType } from "src/types/models";

import { ExploreProps, Explore } from "./explore";

type ExploreWithSaveProps = ExploreProps & {
  onSave: (data?: { rows: any; columns: any }) => Promise<void>;
  getSchema: () => Promise<any>;
};

export const ExploreWithSave: FC<Readonly<ExploreWithSaveProps>> = (props: ExploreWithSaveProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const {
    sql,
    dbtModel,
    lookerLook,
    table,
    sigma,
    customQuery,
    source,
    getSchema,
    onSave: modelSave,
    rows,
    columns,
    error,
    isQueryDefined,
    type,
  } = props;

  const [validationError, setValidationError] = useState<Error | null>(null);
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const supportsResultSchema = source?.definition?.supportsResultSchema;

  const saveDisabled = supportsResultSchema ? false : !hasQueryColumns;
  const saveTooltip = saveDisabled ? "This source requires previewing your query results before saving the model" : undefined;

  const validateQuery = async (): Promise<boolean> => {
    switch (type) {
      case QueryType.Custom:
        try {
          if (source) {
            await validateCustomQuery(source, customQuery);
          }
        } catch (err) {
          setValidationError(err);
          return false;
        }
        setValidationError(null);
        return true;
      default:
        setValidationError(null);
        return true;
    }
  };

  const save = async () => {
    setSaveLoading(true);
    if (supportsResultSchema && !hasQueryColumns) {
      try {
        const { data, error } = await getSchema();
        if (error) {
          toast({
            id: "query-schema-error",
            title: "Error previewing query schema",
            variant: "error",
          });
          Sentry.captureException(error);
        } else if (data?.columns?.length) {
          await modelSave(data);
        }
      } catch (error) {
        toast({
          id: "model-save-error",
          title: "Error saving model",
          variant: "error",
        });
        Sentry.captureException(error);
      }
    } else {
      try {
        await modelSave();
      } catch (error) {
        toast({
          id: "model-save-error",
          title: "Error saving model",
          variant: "error",
        });
      }
    }

    setSaveLoading(false);
  };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [sql, dbtModel, lookerLook, table, customQuery, sigma]);

  useEffect(() => {
    if (columns?.length && !error) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  return (
    <>
      <Explore {...props} error={validationError?.message ?? props.error} />
      <ActionBar>
        <ButtonGroup>
          <Tooltip message={saveTooltip ?? ""} isDisabled={!saveTooltip}>
            <Button
              size="lg"
              variant="primary"
              isDisabled={!isQueryDefined(type) || saveDisabled}
              isLoading={saveLoading}
              onClick={async () => {
                if (await validateQuery()) {
                  save();
                }
              }}
            >
              Save
            </Button>
          </Tooltip>
          <Button
            size="lg"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};

import { TagIcon } from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";

import { ResourceActivityMapper } from "./timeline";

export const commonActivityMappings: ResourceActivityMapper[] = [
  {
    accessor: "tags",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const changes: JSX.Element[] = [];
      for (const [tag, change] of Object.entries(parsedDiff.nested)) {
        if (change.type !== "value") {
          continue;
        }
        switch (change.operation) {
          case "added":
            changes.push(
              <Text size="sm">
                Added label <Code>{tag}</Code>:<Code>{change.value}</Code>
              </Text>,
            );
            break;
          case "removed":
            changes.push(
              <Text size="sm">
                Removed label <Code>{tag}</Code>
              </Text>,
            );
            break;
          case "updated":
            changes.push(
              <Text size="sm">
                Updated label <Code>{tag}</Code> to <Code>{change.value}</Code>
              </Text>,
            );
            break;
        }
      }

      return {
        message: `${activity.metadata.user_name} updated labels`,
        icon: <TagIcon />,
        changes,
      };
    },
  },
];

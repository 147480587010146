export enum FilterType {
  Status = "status",
  //Size = "size",
  CreatedBy = "created",
  Destination = "destination",
  Label = "label",
  Type = "type",
}

export enum FilterOperator {
  Contains = "_contains",
  // Equals = "_eq",
  // GreaterThan = "_gt",
  // GreaterThanOrEqual = "_gte",
  // LessThan = "_lt",
  // LessThanOrEqual = "_lte",
  // NotEquals = "_ne",
  Includes = "_in",
  Excludes = "_nin",
  IncludesKeys = "_has_keys_all",
  ExcludesKeys = "_not_has_keys_all",
}

export type FilterValue = any;

export type Filter = {
  type: FilterType;
  operator: FilterOperator;
  value: FilterValue;
};

import { FC, useEffect, ReactNode, useState } from "react";

import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Row, Column, Button, Box, useToast, Heading, SectionHeading, Text, EmptyState } from "@hightouchio/ui";
import { groupBy, isNil, omitBy } from "lodash";
import pluralize from "pluralize";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import placeholder from "src/assets/placeholders/generic.svg";
import { PreworkspacePage } from "src/components/layout/preworkspace-page";
import { useUser } from "src/contexts/user-context";
import {
  AvailableWorkspacesQuery,
  useAvailableWorkspacesQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  useMembershipRequestsByUserQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useRequestMembershipMutation,
  useWorkspacesQuery,
  WorkspacesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { switchWorkspace } from "src/utils/workspaces";

import { PartnerConnectLogo } from "../partner-connect";

export const WorkspacesPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { toast } = useToast();
  const partnerConnection = (state as any)?.partnerConnection;

  const createWorkspace = () => {
    navigate("/workspaces/new", { state: { partnerConnection } });
  };

  const { user } = useUser();
  const { email, name, id } = user ?? {};

  const { data: workspaceData, isLoading: workspaceLoading } = useWorkspacesQuery(undefined, { suspense: true });
  const { data: availableData } = useAvailableWorkspacesQuery(undefined, { suspense: true });
  const { mutateAsync: linkWorkspace } = usePartnerConnectLinkWorkspaceMutation();

  const workspaces = workspaceData?.workspaces || [];
  const joinableWorkspaces = availableData?.getAvailableWorkspaces?.joinable || [];
  const visibleWorkspaces = availableData?.getAvailableWorkspaces?.visible || [];

  const noWorkspaces = !workspaces?.length && !joinableWorkspaces?.length && !visibleWorkspaces?.length;

  useEffect(() => {
    setTimeout(() => {
      if (!workspaceLoading) {
        analytics.identify(String(id), { email, name });
        const wsNames =
          workspaces?.map(function (obj) {
            return omitBy({ name: obj?.name, slug: obj?.slug, workspace_id: obj?.id }, isNil);
          }) || [];
        analytics.track("Manage Workspace Page Visited", { workspaces: wsNames });
      }
    }, 500);
  }, [workspaceLoading]);

  const handleSelect = async (id, slug) => {
    if (partnerConnection) {
      try {
        await linkWorkspace({ uuid: partnerConnection.uuid, workspaceId: Number(id) });
        navigate(`/partner-connect/${partnerConnection.uuid}`);
      } catch (err) {
        const message =
          err.message == "This connection doesn't have access to the selected workspace"
            ? "There was an error creating your resource. Your user may not have the correct permissions to create the resource in the selected workspace."
            : "There was an error creating your resource. Please try again. If the error persists, please contact Hightouch support.";
        toast({
          id: "partner-connect-resource-create-error",
          title: "Error creating resource",
          message: message,
          variant: "error",
        });
      }
    } else {
      switchWorkspace(id, `/${slug}`);
    }
  };

  // TODO: Refactor the API to be grouped by orgs

  const workspacesByOrg = groupBy(workspaces, "organization.slug");
  const joinableByOrg = groupBy(joinableWorkspaces, "organization.slug");
  const visibleByOrg = groupBy(visibleWorkspaces, "organization.slug");
  const organizationsBySlug = Object.fromEntries(
    [...workspaces, ...joinableWorkspaces, ...visibleWorkspaces].map((w) => [w?.organization?.slug, w.organization]),
  ) as { [key: string]: { [key: string]: string } };

  const nonOrgWorkspaces = workspacesByOrg["undefined"];
  delete workspacesByOrg["undefined"];
  const nonOrgJoinable = joinableByOrg["undefined"];
  delete joinableByOrg["undefined"];
  const nonOrgVisible = visibleByOrg["undefined"];
  delete visibleByOrg["undefined"];

  const organizations = Array.from(
    new Set([...Object.keys(workspacesByOrg), ...Object.keys(joinableByOrg), ...Object.keys(visibleByOrg)]),
  );

  if (noWorkspaces) {
    return <Navigate to="/welcome" />;
  }

  return (
    <PreworkspacePage title="Workspaces">
      <Column gap={10} maxWidth="800px" mx="auto" width="100%">
        {partnerConnection && <PartnerConnectLogo logo={partnerConnection.partnerLogo} name={partnerConnection.partnerName} />}

        <Row align="center" justify="space-between">
          <Heading size="xl">Select a workspace{partnerConnection ? " to connect" : ""}</Heading>
          <Button onClick={createWorkspace}>Create a workspace</Button>
        </Row>

        {organizations.map((slug) => (
          <Column key={slug} gap={2}>
            <Column>
              <Box color="gray.500" fontSize="sm" fontWeight="semibold" textTransform="uppercase">
                Organization
              </Box>
              <SectionHeading mb={4}>{organizationsBySlug[slug]?.name ?? ""}</SectionHeading>
            </Column>
            <Workspaces workspaces={workspacesByOrg[slug]} onSelect={handleSelect} />
            <AvailableWorkspaces
              joinableWorkspaces={joinableByOrg[slug]}
              visibleWorkspaces={visibleByOrg[slug]}
              onSelect={handleSelect}
            />
          </Column>
        ))}

        {nonOrgWorkspaces && (
          <Column gap={2}>
            <SectionHeading mb={4}>Your workspaces</SectionHeading>
            <Workspaces workspaces={nonOrgWorkspaces} onSelect={handleSelect} />
            <AvailableWorkspaces
              joinableWorkspaces={nonOrgJoinable}
              visibleWorkspaces={nonOrgVisible}
              onSelect={handleSelect}
            />
          </Column>
        )}
      </Column>
    </PreworkspacePage>
  );
};

export const AvailableWorkspaces: FC<
  Readonly<{
    onSelect: (id: number, slug: string) => Promise<void>;
    visibleWorkspaces: AvailableWorkspacesQuery["getAvailableWorkspaces"]["visible"] | undefined;
    joinableWorkspaces: AvailableWorkspacesQuery["getAvailableWorkspaces"]["joinable"] | undefined;
  }>
> = ({ visibleWorkspaces, joinableWorkspaces, onSelect }) => {
  const { user } = useUser();

  const membershipRequests = useMembershipRequestsByUserQuery(
    {
      userId: String(user?.id),
    },
    { enabled: Boolean(user), select: (data) => data.membership_requests },
  );

  return (
    <>
      {joinableWorkspaces?.map(({ name, id, slug }) => (
        <JoinableWorkspace key={id} id={id.toString()} name={name} slug={slug} onSelect={() => onSelect(id, slug)} />
      ))}
      {visibleWorkspaces?.map(({ name, id, slug }) => (
        <RequestableWorkspace key={id} id={id.toString()} name={name} requests={membershipRequests?.data} slug={slug} />
      ))}
    </>
  );
};

export const Workspaces: FC<{
  onSelect: (id: number, slug: string) => Promise<void>;
  workspaces: WorkspacesQuery["workspaces"] | undefined;
}> = ({ onSelect, workspaces }) => {
  return (
    <>
      {workspaces?.map(({ slug, name, id, memberships }) => (
        <UserWorkspace
          key={id}
          id={id}
          name={name}
          size={memberships?.length}
          slug={String(slug)}
          onSelect={() => {
            onSelect(id, slug || "");
          }}
        />
      ))}
    </>
  );
};

const Workspace: FC<
  Readonly<{
    slug: string;
    id: string;
    name: string;
    organization?: string;
    onClick?: () => void;
    children: ReactNode;
    bg?: string;
  }>
> = ({ name, onClick, children, bg = "white", slug }) => {
  const isButton = Boolean(onClick);

  return (
    <Row
      _focus={{ outline: "none" }}
      _hover={isButton ? { bg: "gray.100" } : {}}
      align="center"
      as={isButton ? "button" : "div"}
      bg={bg}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      height="56px"
      id={slug}
      px={4}
      transition="background-color 200ms"
      width="100%"
      onClick={onClick}
    >
      <Row align="center" flex={1}>
        <Text fontWeight="semibold" size="md">
          {name}
        </Text>
      </Row>

      <Row gap={4}>{children}</Row>
    </Row>
  );
};

export const UserWorkspace: FC<
  Readonly<{
    size?: number;
    slug: string;
    name: string;
    onSelect: () => void;
    id: string;
  }>
> = ({ size, onSelect, ...props }) => {
  return (
    <Workspace {...props} onClick={onSelect}>
      <Box mr={3}>
        {size} {pluralize("members", size)}
      </Box>
      <ChevronRightIcon color="var(--chakra-colors-gray-700)" width="18px" />
    </Workspace>
  );
};

export const JoinableWorkspace: FC<
  Readonly<{ name: string; slug: string; organization?: string; onSelect: () => void; id: string }>
> = ({ onSelect, ...props }) => {
  const joinWorkspace = useJoinWorkspaceWithAutoJoinMutation({
    onSuccess: () => {
      onSelect();
    },
  });

  return (
    <Workspace {...props}>
      <Button
        size="sm"
        directionIcon={ArrowRightIcon}
        id={props.slug}
        isLoading={joinWorkspace.isLoading}
        variant="primary"
        onClick={() => {
          joinWorkspace.mutateAsync({ workspaceId: String(props.id) });
        }}
      >
        Join
      </Button>
    </Workspace>
  );
};

export const RequestableWorkspace: FC<
  Readonly<{ name: string; id: string; slug: string; organization?: string; requests: { workspace_id: string }[] | undefined }>
> = ({ requests, ...props }) => {
  const { toast } = useToast();
  const [requested, setRequested] = useState(false);

  const requestMembership = useRequestMembershipMutation({
    onSuccess: () => {
      setRequested(true);
      toast({
        id: "access-requested",
        title: "Access requested",
        message: `Access to workspace ${props.name} sent! Ask a member of the workspace to approve your request in the manage members page.`,
        variant: "success",
      });
    },
  });

  const isDisabled = requested ?? requests?.some((r) => r?.workspace_id === String(props.id));

  return (
    <Workspace {...props} bg="transparent">
      <Button
        size="sm"
        isDisabled={isDisabled}
        isLoading={requestMembership.isLoading}
        onClick={() => {
          requestMembership.mutateAsync({ workspaceId: String(props.id) });
        }}
      >
        {isDisabled ? "Awaiting approval" : "Request access"}
      </Button>
    </Workspace>
  );
};

export const Placeholder: FC<{ onCreate: () => void }> = ({ onCreate }) => (
  <Column maxWidth="800px" mx="auto" p={10} width="100%">
    <EmptyState
      actions={
        <Button variant="primary" onClick={onCreate}>
          Create a workspace
        </Button>
      }
      imageUrl={placeholder}
      message="Get started by creating your first workspace. Invite people to different workspaces to keep your team’s workflows secure and organized."
      title="You have no workspaces yet"
    />
  </Column>
);

import { FC } from "react";

import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";

import { CreateDestinationWizard } from "./create-destination-wizard";

export const CreateDestination: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Create destination</title>
      </Helmet>

      <CreateDestinationWizard
        onCancel={() => {
          navigate("/destinations");
        }}
        onSubmit={({ id }) => navigate(`/destinations/${id}`)}
      />
    </>
  );
};

import { FC, useEffect } from "react";

import { SizeCap } from "@hightouch/lib/query/visual/types";
import { Box, Button, Row, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import { FilterableColumn } from "src/types/visual";
import { FieldError } from "src/ui/field";
import { NewSelect } from "src/ui/new-select";

import { AmountInput } from "../explore/visual/amount-input";

type FormValues = {
  bottom: SizeCap["bottom"] | undefined;
  limit: SizeCap["limit"] | undefined;
  orderByProperty: SizeCap["orderByProperty"] | undefined;
};

const defaultFormValues: FormValues = {
  bottom: false,
  limit: undefined,
  orderByProperty: undefined,
};

const validationSchema = Yup.object().shape({
  bottom: Yup.bool().required("direction is required"),
  limit: Yup.number().integer().min(0).typeError("limit must be a number").required("limit is required"),
  orderByProperty: Yup.object().required("property is required"),
});

type AudienceSizeCapProps = {
  properties: FilterableColumn[];
  onSave: (payload: SizeCap | undefined) => void;
  data: SizeCap | undefined;
};

export const AudienceSizeCap: FC<Readonly<AudienceSizeCapProps>> = ({ properties, data, onSave }) => {
  const { toast } = useToast();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  const propertyOptions = properties.map(({ alias, name, column_reference }) => ({
    label: alias || name,
    value: column_reference,
  }));

  const saveSizeCap = async (payload: SizeCap | undefined) => {
    try {
      await onSave(payload);

      toast({
        id: "audience-size-limit",
        variant: "success",
        title: "Audience size limit successfully applied",
      });
    } catch (err) {
      toast({
        id: "audience-size-limit",
        variant: "error",
        title: "Audience size limit was not applied due to an error",
        message: "Please try again",
      });

      Sentry.captureException(err);
    }
  };
  const removeSizeCap = async () => {
    reset(defaultFormValues);
    await saveSizeCap(undefined);
  };

  return (
    <Box px={2}>
      <Box fontWeight="semibold" mb={2}>
        Limit audience size
      </Box>
      <Row gap={2}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore - Circular reference problem with Column types */}
        <Controller
          control={control}
          name="bottom"
          render={({ field, fieldState: { error } }) => (
            <Box width="110px">
              <NewSelect
                error={Boolean(error)}
                value={field.value}
                options={[
                  { label: "Top", value: false },
                  { label: "Bottom", value: true },
                ]}
                onChange={field.onChange}
              />
              <FieldError error={error?.message} />
            </Box>
          )}
        />
        <Controller
          control={control}
          name="limit"
          render={({ field, fieldState: { error } }) => (
            <Box width="100px">
              <AmountInput
                type="number"
                placeholder="Limit"
                error={error?.message}
                value={field.value}
                onChange={field.onChange}
              />
            </Box>
          )}
        />
        <Box py="6px" height="fit-content">
          members, sorted by
        </Box>
        <Controller
          control={control}
          name="orderByProperty"
          render={({ field, fieldState: { error } }) => (
            <Box width="180px">
              <NewSelect
                error={Boolean(error)}
                placeholder="Select a property..."
                value={field.value}
                options={propertyOptions}
                onChange={field.onChange}
              />
              <FieldError error={error?.message} />
            </Box>
          )}
        />
      </Row>
      <Row color="text.tertiary" pt={2}>
        Cap your audience to a subset of members ranking highest or lowest for a given property.
      </Row>
      <Row gap={2} justifyContent="right" pt={2}>
        <Button
          isLoading={isSubmitting}
          isDisabled={data?.limit == null || isSubmitting}
          onClick={removeSizeCap}
          variant="warning"
        >
          Remove limit
        </Button>
        <Button
          isLoading={isSubmitting}
          isDisabled={!isDirty || isSubmitting}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          // tsc is complaining about the default form values not matching the expected type
          onClick={handleSubmit(saveSizeCap)}
          variant="primary"
        >
          Save
        </Button>
      </Row>
    </Box>
  );
};

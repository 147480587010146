import { FC, ReactNode } from "react";

import { Message as ThemedMessage, ThemeUIStyleObject, Text } from "theme-ui";

import { CheckCircleIcon, ErrorIcon, InfoIcon } from "../icons";

export interface MessageProps {
  children: ReactNode;
  variant?: "default" | "warning" | "error" | "green";
  sx?: ThemeUIStyleObject;
  hideIcon?: boolean;
}

const ICONS = {
  default: InfoIcon,
  warning: ErrorIcon, // WarningIcon looks bad
  error: ErrorIcon,
  green: CheckCircleIcon,
};

export const Message: FC<Readonly<MessageProps>> = ({ children, variant = "default", sx = {}, hideIcon }) => {
  const Icon = ICONS[variant];

  return (
    <ThemedMessage sx={sx} variant={variant}>
      {!hideIcon && <Icon color={null} size={20} sx={{ mr: 2 }} />}
      <Text>{children}</Text>
    </ThemedMessage>
  );
};

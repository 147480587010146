import { FC, Fragment, useEffect, useMemo } from "react";

import { ArrowPathIcon, Square3Stack3DIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button as HightouchUiButton,
  Combobox,
  GroupedCombobox,
  Text,
  Badge as HightouchUiBadge,
  IconButton,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Grid, Flex, Image } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon, XIcon } from "src/ui/icons";
import { Select } from "src/ui/select";
import { NEW_ICON_MAP } from "src/utils/destinations";

import { ExtendedTypes, isExtendedTypes, toExtendedOption } from "../../../../formkit";
import { useQuery } from "../formkit";
import { useFormkitContext } from "./formkit-context";
import { formatOptionLabel, formatFromColumnOption } from "./mappings";
import { MappingsHeader } from "./mappings-header";

export type AssociationOption = {
  label: string;
  value: string;
  extendedType?: ExtendedTypes;
  // Note: association field and object type could be different
  // Ex: label: contact_to_company - objectType: company
  objectType?: string; // Associated object type
  objectLabel?: string; // Associated object type label/name
};

type Props = {
  useHightouchUi?: boolean;
  name: string;
  options?: AssociationOption[];
  loading?: any;
  object?: string;
  error?: string;
  excludeMappings?: string[];
  reload?: () => void;
  ascOptions?: any;
};

/**
 * Example of an associationMapping
  {
    to: "company",
    type: "reference",
    lookup: {
      by: "name",
      from: "company_obj",
      byType: "STRING",
      object: "company",
    },
  }

  to: the association field you're linking (most of the time, it's the associated
    object, but could be named differently)
  lookup: find the company object by name with the model's `company_name` field
*/

export const AssociationMappings: FC<Readonly<Props>> = ({
  useHightouchUi = false,
  name,
  object,
  options,
  loading,
  reload,
  error,
  excludeMappings = [],
  ascOptions,
}) => {
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const { columns, destination, destinationDefinition, loadingModel, model, reloadModel, sourceDefinition } =
    useFormkitContext();

  const watchFieldArray = watch(name);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter((c) => !otherMappings.some((e) => c.to === e.to));
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    const valueAlreadyMapped = controlledFields.some(({ to }) => to === option.value);
    const usedInOtherMappings = excludedFields.some(({ to }) => to === option.value);

    return valueAlreadyMapped || usedInOtherMappings;
  };

  const permission = usePermission();

  // Items in `columns` have `options` field as optional,
  // but `GroupedCombobox` expects it to be required
  const columnOptionGroups = useMemo(() => {
    return (columns ?? []).map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [columns]);

  if (!watchFieldArray) {
    return null;
  }

  return (
    <Column aria-label={`${name} association mappings`}>
      <Grid
        sx={{
          gridTemplateColumns: useHightouchUi ? "minmax(0, 1fr) 16px minmax(0, 1fr) 32px" : "1fr max-content 1fr max-content",
          alignItems: "center",
        }}
      >
        {controlledFields?.length > 0 && (
          <MappingsHeader columns={4} loading={loading} object={object} reload={reload} useHightouchUi={useHightouchUi} />
        )}

        {controlledFields.map(({ id }, index) => (
          <Fragment key={id}>
            <Controller
              name={`${name}.${index}`}
              render={(data) => {
                const destinationFieldOptions = options?.map((option) => {
                  if (isOptionExcluded(option)) {
                    return { ...option, disabled: true };
                  } else {
                    return option;
                  }
                });

                const association = data?.field?.value;
                const asyncAscOptions = !Array.isArray(ascOptions) && ascOptions !== null && ascOptions !== undefined;
                const queryVariables = ascOptions?.variables?.input?.variables;
                const {
                  data: associatedObjectFields,
                  error: queryError,
                  refetch,
                  isFetching,
                } = useQuery<any, Error>(
                  JSON.stringify({
                    name,
                    variables: {
                      ...ascOptions?.variables,
                      input: {
                        ...ascOptions?.variables?.input,
                        variables: { object: association?.lookup?.object, ...queryVariables },
                      },
                    },
                  }),
                  {
                    enabled: Boolean(asyncAscOptions && association?.to),
                    fetchProps: {
                      destinationId: destination?.id,
                      modelId: model?.id,
                      query: ascOptions?.query,
                      variables: {
                        input: {
                          ...ascOptions?.variables?.input,
                          variables: { object: association?.lookup?.object, ...queryVariables },
                        },
                      },
                    },
                  },
                );

                const selectFieldProps = {
                  formatOptionLabel,
                  options: destinationFieldOptions,
                  value: data.field.value.to,
                  onChange: (option) => {
                    data.field.onChange({
                      ...data.field.value,
                      to: option?.value,
                      lookup: {
                        object: option?.objectType || option?.value,
                        objectLabel: option?.objectLabel || option?.objectType || option?.value,
                        from: association?.lookup?.from,
                      },
                    });
                  },
                };

                if (useHightouchUi) {
                  return (
                    <>
                      {association?.to ? (
                        <Box display="flex" flexDirection="column" gap={3} pb={6}>
                          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px max-content 1fr">
                            <Text>Find</Text>

                            <HightouchUiBadge>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </HightouchUiBadge>

                            <Text>ID</Text>
                          </Box>

                          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px 16px minmax(0, 1fr) 32px">
                            <Text>Where</Text>

                            {destinationDefinition?.icon && <Box as="img" src={destinationDefinition.icon} width="4" />}

                            <Combobox<any, string>
                              isDisabled={permission.unauthorized}
                              isLoading={isFetching}
                              optionAccessory={(option) => {
                                const type = isExtendedTypes(option.extendedType) ? option.extendedType.type : option.type;

                                return type && type in NEW_ICON_MAP
                                  ? {
                                      type: "icon",
                                      icon: NEW_ICON_MAP[type],
                                    }
                                  : undefined;
                              }}
                              optionLabel={(option) => {
                                return option.objectLabel ? `${option.label} (${option.objectLabel})` : option.label;
                              }}
                              options={toExtendedOption(associatedObjectFields) ?? []}
                              placeholder="Field"
                              value={association?.lookup?.by}
                              onChange={(value) => {
                                const option = toExtendedOption(associatedObjectFields)?.find((option) => {
                                  return option.value === value;
                                });

                                data.field.onChange({
                                  ...data.field.value,
                                  lookup: {
                                    ...association?.lookup,
                                    by: option?.value || undefined,
                                    byType: option?.extendedType?.type || option?.type,
                                  },
                                });
                              }}
                            />

                            <IconButton
                              aria-label="Refresh"
                              icon={ArrowPathIcon}
                              variant="secondary"
                              onClick={() => {
                                void refetch();
                              }}
                            />
                          </Box>

                          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px max-content">
                            <Text>Of</Text>

                            <HightouchUiBadge>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </HightouchUiBadge>
                          </Box>

                          <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="56px 16px minmax(0, 1fr) 32px">
                            <Text>Equals</Text>

                            {sourceDefinition?.icon && <Box as="img" src={sourceDefinition.icon} width="4" />}

                            <GroupedCombobox
                              isDisabled={permission.unauthorized}
                              isLoading={loadingModel}
                              optionAccessory={(option) => {
                                return typeof option.value === "object" && option.value?.type === "additionalColumnReference"
                                  ? {
                                      type: "icon",
                                      icon: Square3Stack3DIcon,
                                    }
                                  : undefined;
                              }}
                              optionGroups={columnOptionGroups}
                              placeholder="Column"
                              value={association?.lookup?.from}
                              onChange={(value) => {
                                data.field.onChange({
                                  ...data.field.value,
                                  lookup: { ...association?.lookup, from: value },
                                });
                              }}
                            />

                            <IconButton
                              aria-label="Refresh"
                              icon={ArrowPathIcon}
                              variant="secondary"
                              onClick={() => {
                                void reloadModel();
                              }}
                            />
                          </Box>

                          {queryError && <FieldError error={queryError} />}
                        </Box>
                      ) : (
                        <Box alignItems="center" display="grid" gap={2} gridTemplateColumns="16px 1fr">
                          {sourceDefinition?.icon && <Box as="img" flex="none" src={sourceDefinition?.icon} width="4" />}

                          <GroupedCombobox
                            isDisabled={permission.unauthorized}
                            isLoading={loadingModel}
                            optionGroups={columnOptionGroups}
                            placeholder="Column"
                            value={association?.lookup?.from}
                            width="auto"
                            onChange={(value) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { ...association?.lookup, from: value },
                              });
                            }}
                          />
                        </Box>
                      )}

                      <ArrowRightIcon color="base.3" size={16} />

                      <Combobox
                        isDisabled={permission.unauthorized}
                        isOptionDisabled={(option) => (option as any).disabled}
                        optionAccessory={(option) => {
                          const type = isExtendedTypes(option.extendedType) ? option.extendedType.type : undefined;

                          return type && type in NEW_ICON_MAP
                            ? {
                                type: "icon",
                                icon: NEW_ICON_MAP[type],
                              }
                            : undefined;
                        }}
                        optionLabel={(option) => {
                          return option.objectLabel ? `${option.label} (${option.objectLabel})` : option.label;
                        }}
                        options={destinationFieldOptions ?? []}
                        placeholder="Select a column..."
                        value={data.field.value.to}
                        width="auto"
                        onChange={(value) => {
                          const option = destinationFieldOptions?.find((option) => {
                            return option.value === value;
                          });

                          data.field.onChange({
                            ...data.field.value,
                            to: option?.value,
                            lookup: {
                              object: option?.objectType || option?.value,
                              objectLabel: option?.objectLabel || option?.objectType || option?.value,
                              from: association?.lookup?.from,
                            },
                          });
                        }}
                      />

                      <IconButton
                        aria-label="Remove mapping"
                        icon={XMarkIcon}
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </>
                  );
                }

                return (
                  <>
                    {association?.to ? (
                      <Flex sx={{ flexDirection: "column", flex: 1 }}>
                        {/** Field in the association */}
                        <Flex sx={{ alignItems: "center", flex: 1 }}>
                          <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
                            Find{" "}
                            <Badge aria-label="association field badge" sx={{ mx: 1, mb: -1, mr: 2 }}>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </Badge>{" "}
                            ID where
                          </Flex>
                          <Select
                            before={<Image src={destinationDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                            formatOptionLabel={formatOptionLabel}
                            isLoading={isFetching}
                            options={toExtendedOption(associatedObjectFields)}
                            placeholder="Field"
                            reload={refetch}
                            value={association?.lookup?.by}
                            onChange={(option) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: {
                                  ...association?.lookup,
                                  by: option?.value || undefined,
                                  byType: option?.extendedType?.type || option?.type,
                                },
                              });
                            }}
                          />
                        </Flex>

                        {/** Field in the model */}
                        <Flex sx={{ alignItems: "center", mt: 2 }}>
                          <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
                            Of{" "}
                            <Badge aria-label="model field badge" sx={{ mx: 1, mb: -1 }}>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </Badge>{" "}
                            equals
                          </Flex>
                          <Select
                            before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                            formatOptionLabel={formatFromColumnOption}
                            options={columns}
                            placeholder="Column"
                            value={association?.lookup?.from}
                            onChange={(option) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { ...association?.lookup, from: option?.value },
                              });
                            }}
                          />
                        </Flex>

                        {queryError && <FieldError error={queryError} />}
                      </Flex>
                    ) : (
                      <Select
                        before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                        formatOptionLabel={formatFromColumnOption}
                        options={columns}
                        placeholder="Column"
                        value={association?.lookup?.from}
                        onChange={(option) => {
                          data.field.onChange({
                            ...data.field.value,
                            lookup: { ...association?.lookup, from: option?.value },
                          });
                        }}
                      />
                    )}

                    <ArrowRightIcon color="base.3" size={16} />

                    <Select {...selectFieldProps} placeholder="Select a field..." />

                    <Button aria-label="remove mapping" variant="plain" onClick={() => remove(index)}>
                      <XIcon color="base.6" size={18} />
                    </Button>
                  </>
                );
              }}
            />
          </Fragment>
        ))}
      </Grid>

      <FieldError error={error} />

      <Row sx={{ mt: 4 }}>
        {useHightouchUi ? (
          <HightouchUiButton
            onClick={() => {
              append({ type: "reference " });
            }}
          >
            Add mapping
          </HightouchUiButton>
        ) : (
          <Button variant="secondary" onClick={() => append({ type: "reference" })}>
            Add mapping
          </Button>
        )}
      </Row>
    </Column>
  );
};

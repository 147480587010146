import { FC, ReactNode } from "react";

import { Row, Spinner } from "@hightouchio/ui";

import { ChevronDownIcon } from "src/ui/icons";

import { SelectComponentProps } from "../types";

interface ContainerProps extends SelectComponentProps {
  children: ReactNode;
}

export const Container: FC<ContainerProps> = ({ children, disabled, error, isOpen, loading }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "1px solid",
      borderColor: error ? "red" : isOpen ? "primary.base" : "gray.300",
      borderRadius: "md",
      height: "32px",
      flexShrink: 0,
      justifyContent: "space-between",
      transition: "100ms border-color",
      bg: disabled ? "gray.200" : "white",
      color: disabled ? "gray.500" : undefined,
      ":hover:not(:disabled):not(:active):not(:focus)": {
        borderColor: isOpen ? undefined : "gray.400",
      },
      flex: 1,
      whiteSpace: "nowrap",
    }}
  >
    {children}
    {loading ? <Spinner ml={2} size="sm" /> : <ChevronDownIcon color={isOpen ? "gray.700" : "gray.400"} sx={{ ml: 2 }} />}
  </Row>
);

import { FC, ReactNode } from "react";

import { Box, Text, ThemeUIStyleObject } from "theme-ui";

import { Indices } from "../../../../design";
import { InfoIcon } from "../icons";
import { Popout } from "../popout";
import { Placement } from "../popout/popout";

type ContentOrText = { content: ReactNode | string; text?: never } | { content?: never; text: string | ReactNode };

export type TooltipProps = {
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
  size?: number;
  placement?: Placement;
  disabled?: boolean;
  portal?: boolean;
  interactiveTip?: boolean;
  theme?: "light" | "dark";
  hasCaret?: boolean;
  popoutSx?: ThemeUIStyleObject;
} & ContentOrText;

export const Tooltip: FC<Readonly<TooltipProps>> = ({
  content,
  text,
  size = 16,
  sx = {},
  children,
  placement = "top",
  interactiveTip = false,
  disabled,
  portal,
  theme = "light",
  hasCaret = false,
  popoutSx = {},
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Popout
      content={content || <Text sx={{ fontSize: 0, fontWeight: "inherit", whiteSpace: "pre-line" }}>{text}</Text>}
      contentSx={{
        borderRadius: 2,
        maxWidth: "480px",
        p: 2,
        backgroundColor: theme === "dark" ? "base.9" : "white",
        borderColor: theme === "dark" ? "base.9" : "white",
        color: theme === "dark" ? "white" : "black",
        zIndex: Indices.Modal,
        // caret coming from the bottom of the toolip.
        "::before": {
          content: hasCaret ? "''" : "none",
          position: "absolute",
          bottom: "-5px",
          left: "50%",
          width: "10px",
          height: "10px",
          backgroundColor: theme === "dark" ? "base.9" : "white",
          transform: "translateX(-50%) rotate(45deg)",
          zIndex: "-1",
        },
      }}
      placement={placement}
      portal={portal}
      sx={popoutSx}
    >
      {({ isOpen, open, close }) => (
        <Box
          sx={{ cursor: "pointer", width: "max-content", ...sx }}
          onMouseEnter={() => {
            open();
          }}
          onMouseLeave={() => {
            // Leaves popout open to interact with it.
            if (!interactiveTip) {
              close();
            }
          }}
        >
          {children || <InfoIcon size={size} sx={{ color: isOpen ? "primary" : "base.4" }} />}
        </Box>
      )}
    </Popout>
  );
};

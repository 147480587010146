import { Model, Relationship } from "src/graphql";

export type { Model as GraphModel, Relationship as GraphRelationship };

export enum EdgeType {
  Custom = "custom",
}

export type NodeData = {
  label?: string;
  isEphemeral?: boolean;
  isSelected?: boolean;
  isSource?: boolean;
  isTarget?: boolean;
};

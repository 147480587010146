import { FC, useEffect, Fragment, ReactNode, useState, useRef } from "react";

import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Box, Button, ConfirmationDialog, Paragraph, Tooltip, Spinner, SectionHeading } from "@hightouchio/ui";
import { Text, ThemeUICSSObject } from "theme-ui";

import {
  cssVariableBottomSectionHeight,
  cssVariableTopOffset,
  cssVariableTopSectionHeight,
} from "src/components/layout/detail-page";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import { Column, Row, Container } from "src/ui/box";
import { ButtonProps } from "src/ui/button/button";

import { Indices } from "../../../../design/indices";

export interface WizardProps {
  fullscreen?: boolean;
  title: string;
  step: number;
  steps: Step[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  sx?: ThemeUICSSObject;
  onCancel: () => void;
  onSubmit: () => void;
}

export type Step = {
  title: string;
  header?: ReactNode;
  render: FC;
  loading?: boolean;
  submitting?: boolean;
  onContinue?: () => void | Promise<void>;
  continueLabel?: string;
  continueProps?: ButtonProps;
  continueTooltip?: string;
  continue?: ReactNode;
  actions?: ReactNode;
  disabled?: boolean;
  contain?: boolean; // True when children content should scroll instead of entire page
};

const size = "medium";
const bottomBarHeight = 80;
const wizardHeaderSize = 63;

export const Wizard: FC<Readonly<WizardProps>> = ({
  title,
  step,
  steps,
  setStep,
  onCancel,
  onSubmit,
  previousDisabled,
  fullscreen,
}) => {
  const headingRef = useRef<HTMLDivElement>(null);

  const headingHeight = useMeasureHeightOnce(headingRef, wizardHeaderSize);

  const data = steps[step];
  const [submitting, setSubmitting] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        setExiting(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [setExiting]);

  const isLastStep = step === steps.length - 1;

  return (
    <>
      <Column
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bg: "white",
          width: "100vw",
          minHeight: "100vh",
          zIndex: Indices.Modal,
          [cssVariableTopOffset]: `${headingHeight}px`,
          [cssVariableTopSectionHeight]: `${headingHeight}px`,
          [cssVariableBottomSectionHeight]: `${bottomBarHeight}px`,
        }}
      >
        <Row
          ref={headingRef}
          sx={{
            flexShrink: 0,
            height: "64px",
            bg: "white",
            zIndex: Indices.Content,
            justifyContent: "center",
            width: "100%",
            borderBottom: "small",
            position: "sticky",
            top: 0,
          }}
        >
          <Row sx={{ alignItems: "center", flex: 1, px: 8 }}>
            <SectionHeading>{title}</SectionHeading>
            <Row sx={{ flex: 1, alignItems: "center", justifyContent: "center", mx: 8 }}>
              {steps.map(({ title }, i) => {
                const visited = i < step;
                const current = i === step;
                return (
                  <Fragment key={i}>
                    <Row
                      sx={{
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <Row
                        sx={{
                          flexShrink: 0,
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: "secondary",
                          border: visited ? "none" : "1px solid",
                          bg: visited ? "base.5" : current ? "secondary" : "white",
                          width: "14px",
                          height: "14px",
                          borderRadius: "7px",
                        }}
                      >
                        {i < step && <CheckIcon stroke="white" strokeWidth={3} width="70%" />}
                      </Row>
                      <Box display={["none", "none", "block", "block"]}>
                        <Text sx={{ color: visited ? "base.5" : "forest", fontWeight: "bold" }}>{title}</Text>
                      </Box>
                    </Row>

                    {i < steps?.length - 1 && <Row sx={{ height: "1px", bg: "base.3", flex: 1, mx: 4, maxWidth: "48px" }} />}
                  </Fragment>
                );
              })}
            </Row>

            <Button variant="secondary" onClick={() => setExiting(true)}>
              Exit
            </Button>
          </Row>
        </Row>
        {data?.header && (
          <Column sx={{ position: "sticky", top: "64px", bg: "white", zIndex: Indices.Content }}>
            <Row
              sx={{
                width: "100%",
                height: "80px",
                bg: "base.1",
                zIndex: Indices.Content,
                justifyContent: "center",
                position: "sticky",
              }}
            >
              <Container size={size} sx={{ px: 24 }}>
                <Row sx={{ alignItems: "center", flex: 1 }}>{data?.header}</Row>
              </Container>
            </Row>
          </Column>
        )}

        <Container size={fullscreen ? "unset" : size} sx={{ px: fullscreen ? 0 : 24, py: fullscreen ? 0 : 8 }}>
          <Column sx={{ flex: 1, ...(data?.contain ? { overflow: "hidden", maxHeight: "calc(100vh - 216px)" } : {}) }}>
            {data?.loading ? <Spinner m="auto" size="lg" /> : data?.render({})}
          </Column>
        </Container>
        <Row
          sx={{
            flexShrink: 0,
            position: "sticky",
            bottom: 0,
            bg: "white",
            height: `${bottomBarHeight}px`,
            alignItems: "flex-end",
            justifyContent: "center",
            zIndex: 2,
          }}
        >
          <Row
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: `${bottomBarHeight}px`,
              bg: "white",
              width: "100%",
              borderTop: "small",
            }}
          >
            <Container size={size} sx={{ px: 24 }}>
              <Row sx={{ alignItems: "center", flex: 1 }}>
                {step > 0 && (
                  <Button
                    icon={ArrowLeftIcon}
                    isDisabled={data?.loading || previousDisabled}
                    size="lg"
                    variant="secondary"
                    onClick={() => setStep(step - 1)}
                  >
                    Go back
                  </Button>
                )}
                {!data?.loading && (
                  <Row sx={{ alignItems: "center", ml: "auto", gap: 4 }}>
                    {data?.actions || null}
                    {data?.continue ? (
                      <Text sx={{ fontWeight: "bold", fontSize: 2, color: "base.5" }}>{data?.continue}</Text>
                    ) : (
                      <Tooltip isDisabled={!data?.continueTooltip} message={data?.continueTooltip || ""}>
                        <Button
                          {...(data?.continueProps || {})}
                          directionIcon={isLastStep ? undefined : ArrowRightIcon}
                          isDisabled={data?.disabled}
                          isLoading={data?.submitting || submitting}
                          size="lg"
                          variant="primary"
                          onClick={async () => {
                            setSubmitting(true);
                            if (isLastStep) {
                              await onSubmit();
                            }
                            if (data?.onContinue) {
                              await data?.onContinue();
                            } else if (!isLastStep) {
                              setStep(step + 1);
                            }
                            setSubmitting(false);
                          }}
                        >
                          {isLastStep ? "Finish" : data?.continueLabel ?? "Continue"}
                        </Button>
                      </Tooltip>
                    )}
                  </Row>
                )}
              </Row>
            </Container>
          </Row>
        </Row>
      </Column>
      <ConfirmationDialog
        confirmButtonText="Exit"
        isOpen={exiting}
        title="Are you sure?"
        variant="warning"
        onClose={() => setExiting(false)}
        onConfirm={() => {
          setExiting(false);
          onCancel();
        }}
      >
        <Paragraph>Your progress will be lost.</Paragraph>
      </ConfirmationDialog>
    </>
  );
};

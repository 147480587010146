import { useEffect, useState, useMemo, FC, ReactElement } from "react";

import {
  Row,
  Column,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Pill,
  SearchInput,
  StatusBadge,
  Checkbox,
} from "@hightouchio/ui";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { isPresent } from "ts-extras";

import { Page } from "src/components/layout";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { SyncRequestErrorModal } from "src/components/syncs/error-modals";
import { SyncName } from "src/components/syncs/sync-name";
import { useUser } from "src/contexts/user-context";
import {
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
  useSyncAttemptQuery,
  useSyncQuery,
  SyncOp,
  AttemptedRowsQuery,
} from "src/graphql";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import { PageSpinner } from "src/ui/loading";
import { Markdown } from "src/ui/markdown";
import { Modal } from "src/ui/modal";
import { InfoModal } from "src/ui/modal/info-modal";
import { Table, SimplePagination, TableColumn } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { downloadJson, downloadCsv } from "src/utils/download";
import { SyncStatus, SyncStatusBadge, getSyncAttemptDiff, DEPRECATED_ERROR } from "src/utils/syncs";
import * as time from "src/utils/time";
import { openUrl } from "src/utils/urls";

import ShipImage from "../../components/syncs/ship.svg";

type AttemptedRow = NonNullable<AttemptedRowsQuery["getAttemptedRows"]["rows"][0]>;

enum Tab {
  SUCCESSFUL = "Successful",
  REJECTED = "Rejected",
}

const getRejectedAddedRows = (plannerType: string | undefined, syncError: unknown | undefined, syncRequest, diff) => {
  if (plannerType === "all") {
    // If there's an error, we treat all added rows as rejected rows
    return syncError ? syncRequest?.sync_request_diff?.added_count ?? 0 : 0;
  } else {
    return diff?.rejected?.add;
  }
};

export const Run: FC = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const { run_id: runId, sync_id: syncId } = useParams<{ run_id: string; sync_id: string }>();

  const [tab, setTab] = useState<Tab>(Tab.SUCCESSFUL);
  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showAdded, setShowAdded] = useState<boolean>(true);
  const [showChanged, setShowChanged] = useState<boolean>(true);
  const [showRemoved, setShowRemoved] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [runError, setRunError] = useState<SyncRequestErrorInfo>();
  const [rowError, setRowError] = useState<string>("");
  const [showErrorExport, setShowErrorExport] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showUnsupported, setShowUnsupported] = useState<boolean>(false);

  // XXX: As a hack, we disable showing row-level information for regular
  // viewers for one of our customers. This is not meant to be secure, and
  // just prevents accidentally viewing PII data. We should remove this
  // once we have first-class support for this via our permissioning
  // system.
  const { user } = useUser();
  const userId = user?.id;

  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: syncId ?? "",
    },
    { enabled: Boolean(syncId) },
  );

  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;
  const source = model?.connection;
  const destination = sync?.destination;

  const isDataProtected =
    // We don't populate the results-index for clean room sources anyway,
    // but we can show a better UI for those sources.
    Boolean(source?.definition.cleanRoomType) ||
    // This is a hack for a specific customer.
    // See https://github.com/hightouchio/hightouch/pull/2887.
    (syncId === "27126" && userId !== 63366);

  const { data: attemptData, isLoading: syncAttemptLoading } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      refetchInterval: (attemptData) => {
        if (attemptData?.sync_attempts?.[0]?.finished_at) {
          // Stop polling if this sync attempt is finished.
          return false;
        }

        return 3000;
      },
    },
  );

  const showSuccessful = tab === Tab.SUCCESSFUL;
  const showRejected = tab === Tab.REJECTED;

  const attempt = attemptData?.sync_attempts?.[0];
  const syncRequest = attempt?.sync_request;
  const syncError = syncRequest?.error ?? (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest?.sync?.segment?.primary_key;
  const resyncReason = syncRequest?.resync_reason;
  const plannerType = syncRequest?.planner_type;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const diff = getSyncAttemptDiff(attempt);

  const added =
    plannerType === "all" && !syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);

  const changed = (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed = (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);
  const successfulRows = added + changed + removed;

  const rejectedRows =
    plannerType === "all" && syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (diff?.rejected?.add ?? 0) + (diff?.rejected?.change ?? 0) + (diff?.rejected?.remove ?? 0);

  const addedRows: number | undefined | null = showSuccessful
    ? added
    : getRejectedAddedRows(plannerType?.toString(), syncError, syncRequest, diff);

  const changedRows: number | undefined | null = showSuccessful ? changed : diff?.rejected?.change;
  const removedRows: number | undefined | null = showSuccessful ? removed : diff?.rejected?.remove;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (showAdded) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (showChanged && plannerType !== "all") {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (showRemoved && plannerType !== "all") {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);
  const hasRows = Number(addedRows || 0) + Number(changedRows || 0) + Number(removedRows || 0) > 0;

  let {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
      plannerType: String(plannerType),
    },
    {
      keepPreviousData: true,

      // When disabled, this will return previous data.
      enabled: hasRows,
    },
  );
  if (!hasRows) {
    // Don't use previous data if attemptedRowsQuery is disabled.
    attemptedRowsData = {
      getAttemptedRows: {
        rows: [],
        nextPageKey: null,
      },
    };
    attemptedRowsLoading = false;
    attemptedRowsQueryError = null;
  }

  const { data: attemptedRowsByPKData } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      id: search,
      plannerType: String(plannerType),
    },
    { enabled: Boolean(search) && plannerType !== "all" },
  );

  const data = search ? attemptedRowsByPKData?.getAttemptedRowsByPrimaryKey?.rows : attemptedRowsData?.getAttemptedRows?.rows;

  const rows = useMemo(
    () =>
      (data || []).filter(isPresent).map(({ id, opType, rejectionReason, fields, batchId, requestInfoKeys, isBatchError }) => ({
        hightouchRowId: id,
        opType,
        rejectionReason,
        batchId,
        requestInfoKeys,
        isBatchError,
        fields,
        ...JSON.parse(fields),
      })),
    [data],
  );

  // Rows are no longer available when the run is older than a week
  const isRowsDataExpired = useMemo(() => {
    if (!syncRequest || rows.length > 0) {
      return false;
    }

    const weekAgo = moment().subtract(1, "week");
    return moment(syncRequest.created_at).isBefore(weekAgo);
  }, [syncRequest, rows]);

  const columns: TableColumn[] = [
    {
      key: "opType",
      name: "Type",
      max: "max-content",
      cell: (opType) => getOpTypeBadge(opType),
    },
  ];

  if (showRejected && plannerType !== "all") {
    columns.push({
      key: "rejectionReason",
      name: "Error",
      max: "150px",
      cell: (rejectionReason: string) =>
        rejectionReason ? (
          <Button
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              setRowError(rejectionReason);
            }}
          >
            View error
          </Button>
        ) : null,
    });
  }

  const row = data?.[0];

  if (row) {
    const fields = JSON.parse(row.fields);
    Object.keys(fields).forEach((key, i) => {
      columns.push({
        key,
        name: key,
        cell: (value) => (typeof value === "object" ? JSON.stringify(value) : String(value)),
        divider: i === 0,
      });
    });
  }

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  useEffect(resetPagination, [tab]);
  useEffect(resetPagination, [showAdded, showChanged, showRemoved]);

  const downloadErrors = async (format, rejectedOnly) => {
    let pageKey;
    let rows: AttemptedRow[] = [];
    setExportLoading(true);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const variables = {
        destinationInstanceId: Number(syncId),
        syncRequestId: Number(runId),
        opTypes: [SyncOp.Added, SyncOp.Changed, SyncOp.Removed],
        onlyRejected: rejectedOnly,
        onlySuccessful: false,
        pageKey,
        limit: 100,
        plannerType: String(plannerType),
      };

      try {
        const { getAttemptedRows } = await client.fetchQuery(
          useAttemptedRowsQuery.getKey(variables),
          useAttemptedRowsQuery.fetcher(variables),
        );

        if (getAttemptedRows) {
          rows = [...rows, ...getAttemptedRows.rows].filter(isPresent);
          pageKey = getAttemptedRows.nextPageKey;
        }

        if (!pageKey || !getAttemptedRows) {
          break;
        }
      } catch (e) {
        break;
      }
    }

    if (rows.length && format === "json") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        type: opType,
        error: rejectionReason,
        fields: JSON.parse(fields),
      }));

      downloadJson(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.json`);
    }

    if (rows.length && format === "csv") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        __ht_type: opType,
        __ht_error: rejectionReason,
        ...JSON.parse(fields),
      }));

      downloadCsv(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.csv`);
    }

    setExportLoading(false);
    setShowErrorExport(false);
    setShowExport(false);
  };

  const redirectBackToSync = () => {
    navigate(`/syncs/${syncId}`);
  };

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [rows, setPage, setNextLoading, setPreviousLoading]);

  if (syncLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
          },
        ]}
        title={`Run - ${model?.name ?? "Private model"} to ${
          destination?.name ?? destination?.definition?.name ?? "private destination"
        } - Syncs`}
      >
        <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
          <SyncName destination={destination} model={model} source={source} sync={sync} />
        </Row>

        <MetadataBar>
          <Column>
            <MetadataLabel>Status</MetadataLabel>
            <SyncStatusBadge request={syncRequest} />
          </Column>
          <Column>
            <MetadataLabel>Started at</MetadataLabel>
            <Text textTransform="capitalize">{syncRequest?.created_at && time.formatDatetime(syncRequest.created_at)}</Text>
          </Column>
          {attempt?.finished_at && (
            <Column>
              <MetadataLabel>Duration</MetadataLabel>
              <Text textTransform="capitalize">{time.diff(attempt?.created_at, attempt?.finished_at)}</Text>
            </Column>
          )}
          <Column>
            <MetadataLabel>Run ID</MetadataLabel>
            <Text textTransform="capitalize">{runId}</Text>
          </Column>
          {resyncReason && (
            <Column>
              <MetadataLabel>Resync reason</MetadataLabel>
              <Text textTransform="capitalize">
                {{
                  "explicit-request": "Triggered by user",
                  "added-fields": "Fields were added",
                  "changed-config": "Configuration was changed",
                  "changed-mappings": "Mappings changed",
                  "changed-source-types": "Source types changed",
                }[resyncReason] ?? resyncReason}
              </Text>
            </Column>
          )}
        </MetadataBar>

        <Tabs
          setTab={(tab) => setTab(tab as Tab)}
          tab={tab}
          tabs={[
            {
              render: () => (
                <Row align="center" gap={2}>
                  <Text>Successful</Text>
                  <Pill>{successfulRows}</Pill>
                </Row>
              ),
              value: Tab.SUCCESSFUL,
            },
            {
              render: () => (
                <Row align="center" gap={2}>
                  <Text>Rejected</Text>
                  <Pill>{rejectedRows}</Pill>
                </Row>
              ),
              value: Tab.REJECTED,
            },
          ]}
        />
        <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap", mb: 3, gap: 4 }}>
          <Row gap={8}>
            <Row align="center" gap={2}>
              <Checkbox
                isDisabled={!addedRows}
                isChecked={addedRows ? showAdded : false}
                onChange={(event) => setShowAdded(event.target.checked)}
              />
              <Text>Added</Text>
              <Pill>{addedRows}</Pill>
            </Row>
            <Row align="center" gap={2}>
              <Checkbox
                isDisabled={!changedRows}
                isChecked={changedRows ? showChanged : false}
                onChange={(event) => setShowChanged(event.target.checked)}
              />
              <Text>Changed</Text>
              <Pill>{changedRows}</Pill>
            </Row>

            <Row align="center" gap={2}>
              <Checkbox
                isDisabled={!removedRows}
                isChecked={removedRows ? showRemoved : false}
                onChange={(event) => setShowRemoved(event.target.checked)}
              />
              <Text>Removed</Text>
              <Pill>{removedRows}</Pill>
            </Row>
          </Row>
          {!isDataProtected && (
            <Row align="center" mt={4} gap={4}>
              {syncError && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(syncError.message) && (
                <Button size="sm" variant="warning" onClick={() => setRunError(syncError)}>
                  View run error
                </Button>
              )}
              <Menu>
                <MenuButton>Export</MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setShowExport(true);
                    }}
                  >
                    Export rows
                  </MenuItem>
                  {plannerType !== "all" && (
                    <MenuItem
                      onClick={() => {
                        setShowErrorExport(true);
                      }}
                    >
                      Export row errors
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
              {plannerType !== "all" && (
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setSearch(searchInput);
                  }}
                >
                  <Row align="center" gap={2}>
                    <SearchInput
                      placeholder={primaryKey ? `Search by ${primaryKey}` : ""}
                      value={searchInput}
                      onChange={(event) => {
                        const value = event.target.value;
                        setSearchInput(value);
                        if (value === "") {
                          setSearch("");
                        }
                      }}
                    />
                    <Button type="submit">Search</Button>
                  </Row>
                </form>
              )}
            </Row>
          )}
        </Row>
        <Table
          scrollable
          columns={columns}
          data={isDataProtected ? [] : rows}
          error={!!attemptedRowsQueryError}
          loading={attemptedRowsLoading}
          placeholder={{
            image: isRowsDataExpired ? ShipImage : undefined,
            title: search
              ? `No rows match your search`
              : isRowsDataExpired
              ? "Sorry, that ship has sailed"
              : isDataProtected
              ? "The run data is protected"
              : `No rows`,
            body: search ? (
              <Text>
                No rows with <strong>{primaryKey}</strong> equal to <strong>{search}</strong>
              </Text>
            ) : isRowsDataExpired ? (
              `We don't store row data for sync runs older than 7 days`
            ) : undefined,
            button: isRowsDataExpired ? (
              <Button variant="secondary" onClick={redirectBackToSync}>
                Go back to sync
              </Button>
            ) : undefined,
            error: attemptedRowsQueryError?.message,
          }}
          onRowClick={(row, event) =>
            !row.requestInfoKeys || !row.requestInfoKeys?.length
              ? setShowUnsupported(true)
              : openUrl(`/syncs/${syncId}/runs/${runId}/debug/${row?.hightouchRowId}`, navigate, event)
          }
        />
        <SimplePagination
          nextLoading={nextLoading}
          page={page}
          pages={pages}
          previousLoading={previousLoading}
          onNext={() => {
            setNextLoading(true);
            setPageKeys((pageKeys) => [...pageKeys, attemptedRowsData?.getAttemptedRows?.nextPageKey].filter(isPresent));
          }}
          onPrevious={() => {
            setPreviousLoading(true);
            setPageKeys((pageKeys) => {
              if (page === 1) {
                return [];
              } else {
                return pageKeys.slice(0, -1);
              }
            });
          }}
        />
      </Page>

      {/* Modal for sync-level errors */}
      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        onClose={() => setRunError(undefined)}
        sync={sync}
        syncRequestError={runError}
        syncStatus={sync?.status as SyncStatus}
      />

      {/* Modal for row errors */}
      <InfoModal width="800px" isOpen={Boolean(rowError)} title="Error" onClose={() => setRowError("")}>
        <Markdown>{rowError}</Markdown>
      </InfoModal>

      <Modal
        footer={
          <>
            <Button onClick={() => setShowErrorExport(false)}>Close</Button>
            <Button
              isLoading={exportLoading}
              variant="primary"
              onClick={() => {
                downloadErrors("json", true);
              }}
            >
              Export as JSON
            </Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("csv", true);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showErrorExport}
        title="Export errors"
        onClose={() => setShowErrorExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of sync errors per row.</Text>
      </Modal>
      <Modal
        footer={
          <>
            <Button onClick={() => setShowExport(false)}>Close</Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("json", false);
              }}
            >
              Export as JSON
            </Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("csv", false);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showExport}
        title="Export rows"
        onClose={() => setShowExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of synced rows.</Text>
      </Modal>
      <InfoModal isOpen={showUnsupported} title="Run debugger unsupported" onClose={() => setShowUnsupported(false)}>
        <Text>Run debugger is not supported for this destination, or no requests were made for this row.</Text>
      </InfoModal>
    </>
  );
};

export const getOpTypeBadge = (opType: string): ReactElement | null => {
  switch (opType) {
    case "ADDED":
      return <StatusBadge variant="success">Added</StatusBadge>;
    case "CHANGED":
      return <StatusBadge variant="processing">Changed</StatusBadge>;
    case "REMOVED":
      return <StatusBadge variant="error">Removed</StatusBadge>;
    default:
      return null;
  }
};

import ELK, { ElkNode } from "elkjs";
import { sortBy } from "lodash";
import { Node, Edge } from "reactflow";

import { SchemaModelType } from "src/types/schema";

import { NODE_HEIGHT, NODE_WIDTH, NODE_SPACING } from "../utils";

export const reposition = async (nodes: Node[], edges: Edge[]): Promise<Node[]> => {
  const elk = new ELK();
  const elkNodes: ElkNode[] = sortBy(nodes, [
    (n) => {
      switch (n.type) {
        case SchemaModelType.Parent:
          return 1;
        case SchemaModelType.Related:
          return 2;
        case SchemaModelType.Event:
          return 3;
      }
      return -1;
    },
  ]).map((node) => {
    return {
      id: node.id,
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    };
  });

  const elkEdges = edges.map((edge) => {
    return {
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    };
  });
  const graph: ElkNode = {
    id: "root",
    // https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
    layoutOptions: {
      "elk.layered.cycleBreaking.strategy": "GREEDY_MODEL_ORDER",
      "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES",
      "elk.layered.nodePlacement.bk.fixedAlignment": "RIGHTDOWN",
      "elk.debugMode": "true",
      "elk.algorithm": "layered",
      "elk.direction": "RIGHT",
      "elk.layered.spacing.nodeNodeBetweenLayers": `${NODE_SPACING}`,
      "elk.layered.spacing.nodeNode": "30",
      "nodePlacement.strategy": "SIMPLE",
    },
    children: elkNodes,
    edges: elkEdges,
  };
  const layout = await elk.layout(graph);

  const repositionedNodes: Node[] = [];

  for (const node of nodes) {
    const repositionedNode = layout.children?.find((n) => n.id === node.id);
    repositionedNodes.push({ ...node, position: { x: repositionedNode?.x || 0, y: repositionedNode?.y || 0 } });
  }

  return repositionedNodes;
};

import { FC, useEffect, useState } from "react";

import _ from "lodash";
import { Flex, Image, Text, Box } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { ReloadButton } from "src/ui/button";

type MappingsHeaderProps = {
  object?: string;
  lookup?: boolean;
  reload?: () => void;
  loading?: boolean;
  spacing?: number;
};

export const MappingsHeader: FC<Readonly<MappingsHeaderProps>> = ({ object, reload, loading, spacing }) => {
  const { model: latestModel, loadingModel, reloadModel, destinationDefinition, sourceDefinition } = useDestinationForm();
  const [oldModel, setOldModel] = useState(latestModel);

  const model = latestModel || oldModel;

  useEffect(() => {
    if (latestModel) {
      setOldModel(latestModel);
    }
  }, [latestModel]);

  return (
    <Flex sx={{ mb: 2 }}>
      <Flex sx={{ alignItems: "center", flex: 1 }}>
        <Image src={sourceDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontWeight: "semi",
          }}
        >
          Column from <strong>{model?.name}</strong>
        </Text>
        <ReloadButton
          loading={loadingModel}
          sx={{ ml: 2 }}
          tooltip="Click to refresh columns available in the source"
          onClick={reloadModel}
        />
      </Flex>
      <Box sx={{ width: "48px" }} />
      <Flex sx={{ flex: 1, alignItems: "center" }}>
        <Image src={destinationDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontWeight: "semi",
          }}
        >
          {object ? (
            <>
              Field from <strong>{_.startCase(object)}</strong>
            </>
          ) : (
            <>
              Field in <strong>{destinationDefinition?.name}</strong>
            </>
          )}
        </Text>
        {reload && (
          <ReloadButton
            loading={loading}
            sx={{ ml: 2 }}
            tooltip="Click to refresh fields available in the destination"
            onClick={reload}
          />
        )}
      </Flex>
      {spacing && <Box sx={{ width: spacing }} />}
    </Flex>
  );
};

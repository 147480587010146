import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const CheckIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 7L19.5999 5.6L8.9999 16.2Z" />
    </Icon>
  );
};

import { range } from "lodash";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const MIN_YEAR = 1900;
export const MAX_YEAR = 2050;

export const YEAR_OPTIONS = range(MIN_YEAR, MAX_YEAR + 1).map((year) => ({ label: String(year), value: year }));

export const MONTH_OPTIONS = MONTHS.map((label, index) => ({ label, value: index }));

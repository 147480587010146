import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChatIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#svg-chat-icon)">
        <path
          clipRule="evenodd"
          d="M19.2001 4.0001V13.5C19.2001 14.5493 19.1001 13.5 19.2001 15.4H12.5501L7.80012 19.2V15.4H5.90012C4.85078 15.4 5.00012 15.4 4.00012 15.4V5.9C4.00012 4.85066 4.00012 6 4.00022 4H17.3001C18.3495 4 17.3001 4 19.2001 4.0001ZM8.75012 8.75H6.85012V10.65H8.75012V8.75ZM10.6501 8.75H12.5501V10.65H10.6501V8.75ZM16.3501 8.75H14.4501V10.65H16.3501V8.75Z"
          fill="white"
          fillOpacity="0.3"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="svg-chat-icon">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </Icon>
  );
};

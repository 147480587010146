import { FC, ReactNode } from "react";

import { usePermission } from "src/contexts/permission-context";
import useHasPermission, { ResourcePermission } from "src/hooks/use-has-permission";

type Props = {
  children: ReactNode;
  permissions?: ResourcePermission[];
  fallback?: ReactNode;
};

export const Permission: FC<Readonly<Props>> = ({ children, permissions, fallback = null }) => {
  const { isLoading: isLoadingPermissions, hasPermission } = useHasPermission(permissions);

  const permission = usePermission();

  const unauthorized = permissions ? isLoadingPermissions || !hasPermission : permission?.unauthorized;

  return <>{unauthorized ? fallback : children}</>;
};

import { GitCredentials } from "src/graphql";

export const isGitHub = (credentials: GitCredentials | undefined) => {
  if (credentials?.tunnel_id) {
    return false;
  }
  if (credentials?.type === "github") {
    if (credentials?.username) {
      return true;
    }
  }
  if (credentials?.type === "github_app") {
    return true;
  }

  return false;
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const BooleanIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} strokeWidth="1px" viewBox="0 0 18 18">
      <path
        d="M12.5003 3.1665H5.50033C4.21166 3.1665 3.16699 4.21117 3.16699 5.49984C3.16699 6.7885 4.21166 7.83317 5.50033 7.83317H12.5003C13.789 7.83317 14.8337 6.7885 14.8337 5.49984C14.8337 4.21117 13.789 3.1665 12.5003 3.1665Z"
        fill="white"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 10.1665H5.50033C4.21166 10.1665 3.16699 11.2112 3.16699 12.4998C3.16699 13.7885 4.21166 14.8332 5.50033 14.8332H12.5003C13.789 14.8332 14.8337 13.7885 14.8337 12.4998C14.8337 11.2112 13.789 10.1665 12.5003 10.1665Z"
        fill="white"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 6.08317C12.8225 6.08317 13.0837 5.82201 13.0837 5.49984C13.0837 5.17766 12.8225 4.9165 12.5003 4.9165C12.1782 4.9165 11.917 5.17766 11.917 5.49984C11.917 5.82201 12.1782 6.08317 12.5003 6.08317Z"
        fill="white"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M5.50033 13.0832C5.8225 13.0832 6.08366 12.822 6.08366 12.4998C6.08366 12.1777 5.8225 11.9165 5.50033 11.9165C5.17815 11.9165 4.91699 12.1777 4.91699 12.4998C4.91699 12.822 5.17815 13.0832 5.50033 13.0832Z"
        fill="white"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Icon>
  );
};

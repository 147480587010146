import { createContext, useContext, FC, ReactNode } from "react";

import useHasPermission, { ResourcePermission } from "src/hooks/use-has-permission";
import { PageSpinner } from "src/ui/loading";

type Props = {
  children: ReactNode;
  permissions: ResourcePermission[];
  fallback?: ReactNode;
};

export type PermissionContextType = {
  unauthorized: boolean;
  permissions: ResourcePermission[];
};

const defaultContextValue = {
  unauthorized: false,
  permissions: [],
};

export const PermissionContext = createContext<PermissionContextType>(defaultContextValue);

export const usePermission = () => useContext(PermissionContext);

export const PermissionProvider: FC<Readonly<Props>> = ({ children, permissions, fallback }) => {
  const { isLoading: isLoadingPermissions, hasPermission } = useHasPermission(permissions);

  return (
    <PermissionContext.Provider
      value={{ unauthorized: permissions ? isLoadingPermissions || !hasPermission : false, permissions }}
    >
      {isLoadingPermissions ? fallback || <PageSpinner /> : children}
    </PermissionContext.Provider>
  );
};

import { FC, ReactNode } from "react";

import { get } from "lodash";
import { Flex } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Arrow } from "src/ui/arrow";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";
import {
  DEFAULT_ID_MAPPING_LABEL,
  DEFAULT_ID_MAPPING_DESCRIPTION,
  ID_MAPPING_DESCRIPTION,
  ID_MAPPING_LABEL,
} from "src/utils/destinations";
import { getObjectName } from "src/utils/syncs";

import { MappingDestination } from "./mappings-field";
import { MappingsHeader } from "./mappings-header";

type IdMappingFieldProps = {
  options?: any;
  loading?: boolean;
  disabled?: boolean;
  isArray?: boolean;
  error?: string;
  errors?: any;
  path?: string[];
  reload?: () => void;
  empty?: ReactNode;
  tip?: ReactNode;
  property?: string;
  isCreatable?: boolean;
  creatableTypes?: { label: string; value: string }[];
};

export const IdMappingField: FC<Readonly<IdMappingFieldProps>> = ({
  path,
  options = null,
  loading = false,
  disabled = false,
  isArray = false,
  isCreatable = false,
  error = null,
  errors: propErrors,
  property = null,
  reload,
  empty,
  tip,
  creatableTypes,
}) => {
  const { hightouchColumns, slug, config, setConfig, errors: validationErrors } = useDestinationForm();

  const errors = propErrors || validationErrors;

  const configKey = property ? property : "externalIdMapping";

  const mapping = isArray ? config?.["externalIdMappings"]?.[0] : config?.[configKey];

  const baseError = isArray ? errors?.["externalIdMappings"] : errors?.[configKey];
  const fromError = isArray ? errors?.["externalIdMappings[0].from"] : errors?.[`${configKey}.from`];
  const toError = isArray ? errors?.["externalIdMappings[0].to"] : errors?.[`${configKey}.to`];

  const setMapping = (value, other = {}) => {
    if (isArray) {
      setConfig({
        ...config,
        externalIdMappings: [value],
        ...other,
      });
    } else {
      setConfig({
        ...config,
        [configKey]: value,
        ...other,
      });
    }
  };

  return (
    <Field
      description={
        (path ? get(ID_MAPPING_DESCRIPTION[slug ?? ""], path) : ID_MAPPING_DESCRIPTION[slug ?? ""]) ||
        DEFAULT_ID_MAPPING_DESCRIPTION
      }
      error={baseError || fromError || toError || error}
      label={(path ? get(ID_MAPPING_LABEL[slug ?? ""], path) : ID_MAPPING_LABEL[slug ?? ""]) || DEFAULT_ID_MAPPING_LABEL}
      size="large"
    >
      <MappingsHeader loading={loading} object={getObjectName(config?.object)} reload={reload} />

      <Flex sx={{ alignItems: "center" }}>
        <Select
          isError={baseError || fromError}
          options={hightouchColumns}
          placeholder="Select a column..."
          value={mapping?.from}
          onChange={(selected) => {
            const val = selected.value;
            setMapping({ ...mapping, from: val });
          }}
        />
        <Arrow />
        <MappingDestination
          creatableTypes={creatableTypes}
          disabled={disabled}
          empty={empty}
          error={baseError || toError}
          isCreatable={isCreatable}
          loading={loading}
          mapping={mapping}
          options={options}
          setMapping={(mapping) => {
            const curMapping = config.mappings || [];
            const withoutExternalIdMapping = curMapping.filter((c) => {
              return c.to !== mapping.to;
            });
            setMapping(mapping, { mappings: withoutExternalIdMapping });
          }}
          tip={tip}
        />
      </Flex>
    </Field>
  );
};

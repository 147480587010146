import { FC, useState } from "react";

import { Heading, Paragraph, Label, Text } from "theme-ui";

import { useIsOrganizationSlugAvailableQuery } from "src/graphql";
import { Box, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { CheckCircleIcon, XCircleIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (organizationName: string) => void;
  onCancel: () => void;
};

export const AddOrganizationNameModal: FC<Readonly<Props>> = ({ isOpen, isLoading, onSubmit, onCancel }) => {
  const [organizationName, setOrganizationName] = useState("");

  const upgradePlan = async () => {
    onSubmit(organizationName);
  };

  const { data, isLoading: loadingSlug } = useIsOrganizationSlugAvailableQuery(
    {
      name: organizationName,
    },
    { enabled: Boolean(organizationName) },
  );
  const available = data?.isOrganizationAvailable;

  return (
    <Modal
      bodySx={{ p: 6 }}
      footer={
        <>
          <Button type="button" variant="secondary" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            disabled={organizationName === "" || loadingSlug || !available}
            loading={isLoading}
            type="button"
            variant="primary"
            onClick={upgradePlan}
          >
            Continue
          </Button>
        </>
      }
      footerSx={{ p: 6 }}
      header={
        <>
          <Box>
            <Heading variant="h2">Create your first billing organization</Heading>
            <Paragraph sx={{ mt: 3, color: "base.5" }}>
              This organization can be used to manage billing across multiple workspaces.
            </Paragraph>
          </Box>
        </>
      }
      headerSx={{ p: 6 }}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <Box>
        <Label htmlFor="organization-name" sx={{ fontWeight: "bold", color: "base.8", mb: 3 }}>
          Billing organization name
        </Label>
        <Row sx={{ alignItems: "center", position: "relative" }}>
          <Input
            name="organization-name"
            placeholder="The ACME Company"
            sx={{ userSelect: "none", ml: "-1px", pr: 8, cursor: "pointer", borderColor: isOpen ? "primary" : undefined }}
            type="text"
            value={organizationName}
            onChange={(event) => {
              setOrganizationName(event.target.value);
              if (!isOpen) {
                open();
              }
            }}
          />
          <Row sx={{ position: "absolute", right: 2, fontSize: 0, alignItems: "center" }}>
            {!organizationName ? null : loadingSlug ? (
              "Searching"
            ) : available ? (
              <>
                <CheckCircleIcon color="green" size={12} />
                <Text sx={{ ml: 1 }}>Available!</Text>
              </>
            ) : (
              <>
                <XCircleIcon color="red" size={12} />
                <Text sx={{ ml: 1, color: "red" }}>Unavailable</Text>
              </>
            )}
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};

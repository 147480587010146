import { forwardRef, Ref, useEffect, useMemo, useRef, Fragment, createElement, FC } from "react";

import { Row, Column, Spinner } from "@hightouchio/ui";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  useMatches,
  KBarResults,
  useKBar,
  VisualState,
  ActionImpl,
} from "kbar";
import { Text } from "theme-ui";

import { IconProps } from "src/ui/icons/icon";

import { Indices } from "../../../../design";
import { Input } from "../../ui/input";
import { CommandBarProvider, useCommandBar } from "./context";

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  borderRadius: "8px",
  overflow: "hidden",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

/**
 * TODO:
 *  - Add loading indicator to workspace switcher
 */

const KBAR_LISTBOX = "kbar-listbox";
const getListboxItemId = (id: number) => `kbar-listbox-item-${id}`;

const Search = ({ defaultPlaceholder = undefined }) => {
  const { isFetching } = useCommandBar();
  const { query, search, actions, currentRootActionId, activeIndex, showing, options } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    actions: state.actions,
    activeIndex: state.activeIndex,
    showing: state.visualState === VisualState.showing,
  }));

  const ownRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!ownRef.current) {
      return () => query.setSearch("");
    }

    query.setSearch("");
    ownRef.current.focus();

    return () => query.setSearch("");
  }, [currentRootActionId, query]);

  const placeholder = useMemo((): string => {
    const defaultText = defaultPlaceholder ?? "Type a command or search…";
    return currentRootActionId ? actions[currentRootActionId]?.name ?? defaultText : defaultText;
  }, [actions, currentRootActionId, defaultPlaceholder]);

  return (
    <Row p={4} align="center">
      <Input
        ref={(ref) => {
          if (!ref) {
            return;
          }

          ownRef.current = ref;
          query.inputRefSetter(ref);
        }}
        autoFocus
        aria-activedescendant={getListboxItemId(activeIndex)}
        aria-controls={KBAR_LISTBOX}
        aria-expanded={showing}
        placeholder={isFetching ? "Loading..." : placeholder}
        sx={{ border: "none", p: 0, height: "22px", minHeight: "unset", fontSize: 2, flex: 1 }}
        value={search}
        onChange={(value) => {
          query.setSearch(value);
          options?.callbacks?.onQueryChange?.(value);
        }}
        onKeyDown={(event) => {
          if (currentRootActionId && !search && event.key === "Backspace") {
            const parent = actions[currentRootActionId]?.parent;
            query.setCurrentRootAction(parent);
          }
        }}
      />
      {isFetching && <Spinner marginLeft="auto" />}
    </Row>
  );
};

const ResultItem = forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: string;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    // TODO: Fix render

    const ancestors = useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId);
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <Row
        ref={ref}
        p={4}
        cursor="pointer"
        userSelect="none"
        transition="150ms all"
        borderLeft="2px solid"
        borderColor={active ? "forest.base" : "transparent"}
        bg={active ? "forest.100" : "white"}
        align="center"
      >
        <>
          <Row flex={1} align="center">
            {typeof action.icon === "function" &&
              createElement(action.icon as FC<IconProps>, {
                size: 18,
                sx: { mr: 2 },
                color: active ? "var(--chakra-colors-forest-base)" : "black",
              })}
            {ancestors.length > 0 &&
              ancestors.map((ancestor) => (
                <Fragment key={ancestor.id}>
                  <span
                    style={{
                      opacity: 0.5,
                      marginRight: 8,
                    }}
                  >
                    {ancestor.name}
                  </span>
                  <span
                    style={{
                      marginRight: 8,
                    }}
                  >
                    &rsaquo;
                  </span>
                </Fragment>
              ))}

            <Column>
              <Text>{action.name}</Text>
              {action.subtitle && <Text sx={{ color: "base.4", fontSize: 0 }}>{action.subtitle}</Text>}
            </Column>
          </Row>

          {action.shortcut?.length ? (
            <Row gap={1}>
              {action.shortcut.map((sc) => (
                <kbd
                  key={sc}
                  style={{
                    padding: "2px 6px",
                    background: "rgba(0 0 0 / .1)",
                    borderRadius: "4px",
                    fontSize: 14,
                  }}
                >
                  {sc}
                </kbd>
              ))}
            </Row>
          ) : null}
        </>
      </Row>
    );
  },
);

ResultItem.displayName = "ResultItem";

function Results() {
  const { results, rootActionId } = useMatches();
  if (results?.length === 0) {
    return <Text sx={{ color: "base.5", p: 4 }}>No items match this search</Text>;
  }

  const filteredResults = results.filter((result) => {
    if (typeof result === "string") {
      return true;
    }

    // Exclude workspace switch options from root.
    if (rootActionId !== "workspace.switch" && result.id.startsWith("workspace.switch.")) {
      return false;
    }

    return true;
  });

  return (
    <KBarResults
      items={filteredResults}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId ?? ""} />
        )
      }
    />
  );
}

export const CommandBar = ({ children }) => {
  return (
    <CommandBarProvider>
      <KBarProvider actions={[]}>
        <KBarPortal>
          <Row
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              bg: "rgba(30,30,46,.6)",
              backdropFilter: "blur(12px)",
              justifyContent: "center",
              alignItems: "center",
              zIndex: Indices.Modal,
            }}
          >
            <KBarPositioner>
              <KBarAnimator style={animatorStyle}>
                <Row sx={{ justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                  <Column sx={{ borderRadius: 1, bg: "white", width: "500px", overflow: "hidden", maxHeight: "100%" }}>
                    <Search />
                    <Results />
                  </Column>
                </Row>
              </KBarAnimator>
            </KBarPositioner>
          </Row>
        </KBarPortal>
        {children}
      </KBarProvider>
    </CommandBarProvider>
  );
};

import { FC } from "react";

import { Row, Button, LinkButton, Paragraph, Heading } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { WarningIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

export const OverageModal: FC = () => {
  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const isOpen = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.
  const { workspace } = useUser();

  const messageIntercom = () => {
    analytics.track("SS Overage Modal Lockout Live Chat Clicked", {
      workspace_id: workspace?.id,
    });
    newIntercomMessage(`Hi, ${overageText.replace(/This/, "my")} I would like to learn more about upgrading.`);
  };

  return (
    <>
      <Modal
        footer={
          <>
            <Button onClick={() => messageIntercom()}>Talk to us</Button>
            <LinkButton variant="primary" href="/settings/billing">
              Go to billing
            </LinkButton>
          </>
        }
        header={
          <Row gap={3} align="center">
            <WarningIcon color="red" />
            <Heading>Billing Alert</Heading>
          </Row>
        }
        isOpen={isOpen}
        sx={{ maxWidth: "500px" }}
        onClose={() => null}
      >
        <Paragraph>{overageText}</Paragraph>
      </Modal>
    </>
  );
};

import { BeakerIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";

import { ResourceActivityMapper } from "src/components/resource-activity/timeline";

import { parentModelActivityMappers } from "./parent-model-activity";

export const audienceActivityMappers: ResourceActivityMapper[] = [
  ...parentModelActivityMappers,
  {
    accessor: "visual_query_filter.splitTestDefinition",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      const operation = { added: "enabled", removed: "disabled", updated: "updated" }[parsedDiff.operation];

      return {
        message: (
          <Text>
            {activity.metadata.user_name} {operation} audience splits
          </Text>
        ),
        icon: <BeakerIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.conditions",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated audience conditions</Text>,
        icon: <UserGroupIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.splitTestDefinition.splits",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated split definitions</Text>,
        icon: <UserGroupIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.additionalColumns",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: <Text>{activity.metadata.user_name} updated trait enrichments</Text>,
        icon: <UserGroupIcon />,
        changes: parsedDiff.array.map((change, idx) => {
          const { operation, value } = change;
          const operationMap = {
            added: "added",
            removed: "removed",
            updated: "updated",
          };

          return (
            <Text key={idx}>
              {operationMap[operation]} trait
              {"  "}
              {value?.alias ? <Code>{value?.alias}</Code> : "trait"}
            </Text>
          );
        }),
      };
    },
  },
];

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DestinationIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <rect height="8" width="8" x="3" y="3" />
      <rect fillOpacity="0.3" height="8" width="8" x="13" y="3" />
      <rect fillOpacity="0.3" height="8" width="8" x="3" y="13" />
      <rect height="8" width="8" x="13" y="13" />
    </Icon>
  );
};

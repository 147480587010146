import React from "react";

import { Link, Box, Switch } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { GitCredentials } from "src/graphql/types";
import { Column, Row } from "src/ui/box";
import { Message } from "src/ui/message";

import { githubCredentialsNeedUpdating } from "./git-credentials-fields";

interface GitChecksToggleProps {
  credentials: GitCredentials | undefined;
  description: string;
}

export const GitChecksToggle = (props: GitChecksToggleProps): React.ReactElement => {
  const blocked = !props.credentials || !(props.credentials.type === "github_app");
  const issue = githubCredentialsNeedUpdating(props.credentials)
    ? "Your GitHub App permissions need to be updated. Please update your Git credentials."
    : 'You must update your Git credentials to use the Hightouch "GitHub app" to enable commit checks.';

  const permission = usePermission();

  return (
    <>
      <Row sx={{ justifyContent: "space-between", alignItems: "center", pb: 1 }}>
        <Row sx={{ alignItems: "center" }}>
          <Text>GitHub Pull Request checks</Text>
        </Row>
        <Controller
          name="checks_enabled"
          render={({ field }) => {
            return (
              <Box alignItems="center" display="flex" gap={2}>
                <Text
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    color: "base.4",
                    fontWeight: "bold",
                  }}
                >
                  {field.value ? "Enabled" : "Disabled"}
                </Text>

                <Switch
                  isChecked={field.value}
                  isDisabled={Boolean(blocked ?? permission.unauthorized)}
                  onChange={field.onChange}
                />
              </Box>
            );
          }}
        />
      </Row>
      <Column sx={{ maxWidth: "70%" }}>
        <Text sx={{ fontSize: 0, color: "base.5" }}>
          {props.description}{" "}
          <Link href="https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/collaborating-on-repositories-with-code-quality-features/about-status-checks">
            Learn more about PR checks here.
          </Link>
        </Text>
        {blocked && (
          <Message hideIcon sx={{ mt: 2 }} variant="warning">
            <Text sx={{ fontSize: 0, color: "base.5" }}>{issue}</Text>
          </Message>
        )}
      </Column>
    </>
  );
};

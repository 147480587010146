import { FC, ReactNode } from "react";

import { Row, Column, Spinner } from "@hightouchio/ui";

import { Selectable } from "src/ui/selectable";

export const List: FC<Readonly<{ children: ReactNode; isLoading?: boolean; isEmpty?: boolean; placeholder?: ReactNode }>> = ({
  children,
  isLoading,
  isEmpty,
  placeholder,
}) => {
  return (
    <Column
      gap={1}
      bg="white"
      border={isEmpty ? "none" : "1px solid"}
      borderColor="gray.400"
      borderRadius="md"
      maxHeight="100%"
      height={isLoading ? "100%" : undefined}
      overflowY="auto"
      width="100%"
    >
      {isLoading ? <Spinner size="lg" m="auto" /> : isEmpty ? placeholder : children}
    </Column>
  );
};

export const ListButton = ({ children, onClick }) => {
  return (
    <Row
      width="100%"
      bg="white"
      borderRadius="sm"
      _focus={{ outline: "none", bg: "#EDF6F7" }}
      _hover={{ bg: "#EDF6F7" }}
      align="center"
      as="button"
      gap={4}
      px={3}
      py={2}
      transition="background-color 200ms"
      onClick={onClick}
    >
      {children}
    </Row>
  );
};

export const CardButton: FC<{ onClick: () => void; selected: boolean; children: ReactNode }> = ({
  onClick,
  selected,
  children,
}) => {
  return (
    <Selectable selected={selected} onSelect={onClick} height="168px" px={4} bg="white" flex={1}>
      <Column align="center" justify="center">
        {children}
      </Column>
    </Selectable>
  );
};

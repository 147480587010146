import { FC } from "react";

import { Button, FormField, TextInput, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

import { useUpdateTunnelMutation } from "src/graphql";
import { Modal } from "src/ui/modal";

type Props = {
  onClose: () => void;
  tunnel: any;
};

const validationSchema = object().shape({
  name: string().required("A name is required."),
});

export const EditTunnelForm: FC<Readonly<Props>> = ({ onClose, tunnel }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: tunnel?.name,
    },
  });

  const { toast } = useToast();

  const { isLoading: updating, mutateAsync: updateTunnel } = useUpdateTunnelMutation();

  const onSubmit = async ({ name }) => {
    try {
      await updateTunnel({ id: tunnel?.id, input: { name, updated_at: "now()" } });
      onClose();

      toast({
        id: "edit-tunnel",
        title: "Tunnel updated",
        variant: "success",
      });
    } catch (error: unknown) {
      toast({
        id: "edit-tunnel",
        title: "Something went wrong",
        message: "Failed to update the tunnel, please try again.",
        variant: "error",
      });
    }
  };

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button isLoading={updating} variant="primary" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </>
      }
      sx={{ maxWidth: "500px", width: "100%" }}
      title="Edit tunnel"
      onClose={onClose}
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <FormField error={errors.name ? String(errors.name.message) : undefined} label="Name">
            <TextInput isInvalid={Boolean(errors.name)} value={field.value} onChange={field.onChange} />
          </FormField>
        )}
      />
    </Modal>
  );
};

import { Text } from "theme-ui";

import { Badge } from "../../ui/badge";
import { Circle } from "../../ui/circle";
import { diff } from "../../utils/time";

export const SyncStatusBadge = ({
  setup,
  lastAttemptedAt,
  error,
}: {
  setup: boolean;
  lastAttemptedAt: string | null | undefined;
  error: any;
}) => {
  return (
    <Badge sx={{ alignItems: "center" }} variant="base">
      {(!setup || lastAttemptedAt) && (
        <Circle
          color={lastAttemptedAt ? (error ? (error?.temp ? "yellow" : "red") : "green") : "gray"}
          radius="12px"
          sx={{ mr: 2 }}
        />
      )}
      <Text>
        {lastAttemptedAt
          ? diff(new Date().toISOString(), lastAttemptedAt) + " ago"
          : setup
          ? "Waiting to be synced"
          : "Not synced"}
      </Text>
    </Badge>
  );
};

import { useNavigate, useParams } from "react-router-dom";

import { ModelDraftPage } from "src/components/drafts/model-draft";
import { Page } from "src/components/layout";
import { DraftOperation, ResourceToPermission, useDraftsQuery, useModelQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";

export const ModelDraft = () => {
  const { model_id: id } = useParams<{ model_id: string }>();
  const navigate = useNavigate();

  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
    },
  );
  const model = modelData?.segments_by_pk;

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: id?.toString() || "",
      resourceType: ResourceToPermission.Model,
      status: "pending",
    },
    {
      enabled: Boolean(id),
    },
  );
  const draft = drafts?.drafts?.[0];

  if (modelLoading) {
    return <PageSpinner />;
  }

  if (!draft) {
    navigate(`/models/${id}`);
    return null;
  }

  if (!model) {
    navigate("/models");
    return null;
  }

  const link = `/models/${model.id}`;

  return (
    <Page
      crumbs={[
        { label: "Models", link: "/models" },
        {
          label: model?.name ?? "",
          link: draft.operation === DraftOperation.Create ? link : `${link}?editing=true`,
        },
        { label: "Draft request" },
      ]}
      title="Draft request - Models"
    >
      <ModelDraftPage draft={draft} model={model} />
    </Page>
  );
};

import { ReactNode, forwardRef } from "react";

import { Column } from "src/ui/box";

import { Indices } from "../../../../../design";

interface HeaderProps {
  children: ReactNode;
  mb?: number;
  stickToTop?: boolean;
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(({ children, mb, stickToTop = true }, ref) => {
  return (
    <Column
      ref={ref}
      sx={{
        position: stickToTop ? "sticky" : "relative",
        width: "100%",
        top: 0,
        left: 0,
        height: "max-content",
        zIndex: Indices.Popup,
        bg: "white",
        mb,
      }}
    >
      {children}
    </Column>
  );
});

Header.displayName = "Header";

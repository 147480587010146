import { FC, ReactNode, useRef, useEffect } from "react";

import { CreateTypes } from "canvas-confetti";
import ReactCanvasConfetti, { IProps as ReactCanvasConfettiProps } from "react-canvas-confetti";
import { Box } from "theme-ui";

type Props = {
  children?: ReactNode;
  showConfetti?: boolean;
};

export const Confetti: FC<Props> = ({ children, showConfetti }) => {
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance: ReactCanvasConfettiProps["refConfetti"] = (instance) => {
    refAnimationInstance.current = instance;
  };

  const makeShot = (particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
        colors: ["#7b5cff", "#6245e0", "#b3c7ff", "#8fa5e5", "#5c86ff", "#345dd1"],
      });
  };

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  useEffect(() => {
    if (showConfetti) {
      fire();
    }
  }, [showConfetti]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {children && (
        <Box sx={{ minWidth: "100%" }} onClick={fire}>
          {children}
        </Box>
      )}
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{ position: "fixed", pointerEvents: "none", width: "100%", height: "100%", top: 0 }}
      />
    </Box>
  );
};

import * as Sentry from "@sentry/browser";

import {
  EventCondition,
  ConditionType,
  AndOrCondition,
  PropertyCondition,
  FunnelCondition,
  ReferencedPropertyCondition,
  NumberOfCondition,
} from "src/types/visual";

import { isAndOrCondition } from "./type-guards";

export type ConditionWithSubconditions = {
  type: ConditionType.Event | ConditionType.Funnel | ConditionType.NumberOf;
  subconditions?: AndOrCondition<PropertyCondition>[] | AndOrCondition<PropertyCondition | ReferencedPropertyCondition>[];
};

/**
 * Wrap subconditions in one AND/OR condition block if not already wrapped.
 *
 * @param {EventCondition | FunnelCondition | NumberOfCondition} condition
 * @returns {EventCondition | FunnelCondition | NumberOfCondition}
 */
export function formatSubconditions(condition: NumberOfCondition): NumberOfCondition;
export function formatSubconditions(condition: EventCondition): EventCondition;
export function formatSubconditions(condition: FunnelCondition): FunnelCondition;
export function formatSubconditions(condition: ConditionWithSubconditions): ConditionWithSubconditions {
  let newSubconditions: AndOrCondition<PropertyCondition | PropertyCondition | ReferencedPropertyCondition>[] | undefined = [];

  if (condition?.subconditions?.length === 1 && isAndOrCondition(condition.subconditions[0])) {
    // subconditions are of the correct format
    newSubconditions = condition.subconditions;
  } else {
    // check if subconditions are in a format that isn't supported
    const hasSubconditions = condition.subconditions?.length && condition.subconditions.length > 0;
    const hasNoAndOrConditions = condition.subconditions?.every((subcondition) => !isAndOrCondition(subcondition));

    if (hasSubconditions && !hasNoAndOrConditions) {
      const error = new Error(
        `Bad format detected in ${condition.type} condition. Not all subconditions are property conditions.`,
      );
      Sentry.captureException(error, { extra: { condition: JSON.stringify(condition) } });
    }

    // wrap subconditions in and/or condition if not already wrapped
    newSubconditions = [
      {
        type: ConditionType.And,
        conditions: condition.subconditions ?? [],
      },
    ];
  }

  return {
    ...condition,
    subconditions: newSubconditions,
  };
}

import { MouseEvent, ReactNode, useRef } from "react";

import { Row, Box, Checkbox as HightouchUiCheckbox, Column } from "@hightouchio/ui";

// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";

export type Props = {
  useHightouchUi?: boolean;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
};

export const Collapsible = ({ useHightouchUi = false, label, value, onChange, children }: Props) => {
  const checkboxContainer = useRef<HTMLSpanElement>(null);

  if (useHightouchUi) {
    const toggle = (event: MouseEvent) => {
      // Ignore clicks on checkbox
      if (checkboxContainer.current?.contains(event.target as HTMLElement)) {
        return;
      }

      onChange(!value);
    };

    return (
      <Box borderColor="gray.300" borderRadius="md" borderWidth="1px">
        <Box bg={value ? "grass.200" : undefined} borderTopRadius="md" cursor="pointer" display="flex" p={6} onClick={toggle}>
          <span ref={checkboxContainer}>
            <HightouchUiCheckbox
              isChecked={value}
              label={label}
              onChange={(event) => {
                onChange(event.target.checked);
              }}
            />
          </span>
        </Box>

        {value && (
          <Column borderColor="gray.300" borderTopWidth="1px" gap={6} p={6}>
            {children}
          </Column>
        )}
      </Box>
    );
  }

  return (
    // this box is the overall collapsible box
    <Column borderRadius="sm" border="1px solid" borderColor="base.border">
      <Row align="center">
        <Checkbox label={label} size="large" sx={{ p: 6 }} value={value} onChange={onChange} />
      </Row>
      {Boolean(value) && (
        <Column gap={6} p={6} borderTop="1px solid" borderColor="base.border">
          {children}
        </Column>
      )}
    </Column>
  );
};

Collapsible.displayName = "Collapsible";

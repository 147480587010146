import { FC } from "react";

import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { Box, Row, Text, Link, Paragraph } from "@hightouchio/ui";

import { newIntercomMessage } from "src/lib/intercom";

type HelpProps = {
  docs?: string;
  label?: string;
  mt?: number;
};

export const Help: FC<Readonly<HelpProps>> = ({ docs, label, ...props }) => (
  <Box {...props}>
    <Row alignItems="center" gap={1.5}>
      <Box as={ChatBubbleLeftEllipsisIcon} boxSize={5} color="gray.500" />
      <Text fontWeight="medium">Need help?</Text>
    </Row>

    {docs && label ? (
      <Paragraph mt={1}>
        Read our <Link href={docs}>docs</Link> or{" "}
        <Box
          display="inline"
          onClick={() => {
            newIntercomMessage(`Could you help me with ${label}?`);
          }}
        >
          <Link href="">send us a message</Link>
        </Box>
        .
      </Paragraph>
    ) : (
      <Box
        mt={1}
        onClick={() => {
          newIntercomMessage(`Could you help me with ${label}?`);
        }}
      >
        <Link href="">Send us a message</Link>.
      </Box>
    )}
  </Box>
);

import { FC, useEffect, useMemo, useState } from "react";

import { Checkbox, Column, Link, Menu, MenuButton, MenuList, Row, Text, Box } from "@hightouchio/ui";
import pluralize from "pluralize";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Schedule } from "src/components/schedule/types";
import { usePermission } from "src/contexts/permission-context";
import { SyncsBoolExp, SyncsOrderBy, useSyncsForSequencesQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { ArrowDownIcon, ArrowUpIcon, ExternalLinkIcon, XIcon } from "src/ui/icons";
import { Input, SearchInput } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

export interface SequenceMemberSync {
  id: string;
  segment: {
    id: string;
    name: string;
  };
  destination: {
    name: string;
    definition: {
      icon: string;
      name: string;
    };
  };
}

export interface SequenceMember {
  sync: SequenceMemberSync;
  abort_seq_on_failure: boolean;
  abort_seq_on_rejects?: number;
}

export interface FormState {
  slug: string;
  name: string;
  members: SequenceMember[];
  schedule: Schedule | null;
}

export const SequenceForm: FC = () => {
  const { watch } = useFormContext<FormState>();
  const { fields, append, remove, swap } = useFieldArray({
    name: "members",
  });
  const members = watch("members");
  const [showSelectSyncs, setShowSelectSyncs] = useState(false);

  const updatePermission = usePermission();

  return (
    <>
      <Column p={3} bg="base.background" borderRadius={3} mb={4} gap={4}>
        {fields.map((field, index) => (
          <>
            <Controller
              key={field.id}
              name={`members.${index}`}
              render={({ field }) => (
                <Box
                  display="grid"
                  p={3}
                  bg="white"
                  alignItems="center"
                  borderRadius={3}
                  gridTemplateColumns="max-content 1fr 1fr 1fr max-content max-content"
                >
                  <Row align="center">
                    <Row sx={{ width: "48px" }}>
                      <Button
                        disabled={index === 0}
                        sx={{ mr: 1, ":not(:disabled):hover": { svg: { fill: "base.6", transition: "150ms fill" } } }}
                        variant="plain"
                        onClick={() => swap(index, index - 1)}
                      >
                        <ArrowUpIcon color="base.4" size={16} />
                      </Button>
                      <Button
                        disabled={index === fields?.length - 1}
                        sx={{ ":not(:disabled):hover": { svg: { fill: "base.6", transition: "150ms fill" } } }}
                        variant="plain"
                        onClick={() => swap(index, index + 1)}
                      >
                        <ArrowDownIcon color="base.4" size={16} />
                      </Button>
                    </Row>

                    <Circle color="base.2" radius="24px" sx={{ color: "base.5", mr: 4 }}>
                      {index + 1}
                    </Circle>
                  </Row>
                  <Column gap={2}>
                    <Text textTransform="uppercase" color="text.secondary" fontWeight="semibold" size="sm">
                      Model
                    </Text>
                    <Text isTruncated fontWeight="medium">
                      {field.value.sync?.segment?.name}
                    </Text>
                  </Column>

                  <Column gap={2}>
                    <Text textTransform="uppercase" color="text.secondary" fontWeight="semibold" size="sm">
                      Destination
                    </Text>
                    <Row align="center" gap={2}>
                      <IntegrationIcon
                        name={field.value.sync?.destination?.definition?.name}
                        src={field.value.sync?.destination?.definition?.icon}
                      />
                      <Text fontWeight="medium" isTruncated>
                        {field.value.sync?.destination?.name}
                      </Text>
                    </Row>
                  </Column>
                  <Column gap={2} alignItems="flex-start">
                    <Text textTransform="uppercase" color="text.secondary" fontWeight="semibold" size="sm">
                      Terminate
                    </Text>
                    <Menu>
                      <MenuButton size="sm">
                        {field.value.abort_seq_on_failure && field.value.abort_seq_on_rejects
                          ? "Rejected rows, Fatal error"
                          : field.value.abort_seq_on_failure
                          ? "Fatal error"
                          : field.value.abort_seq_on_rejects
                          ? "Rejected rows"
                          : "No"}
                      </MenuButton>

                      <MenuList>
                        <Column gap={2} px={4} py={2}>
                          <Text>Terminate sequence when</Text>
                          <Column>
                            <Controller
                              name={`members.${index}.abort_seq_on_rejects`}
                              render={({ field }) => (
                                <>
                                  <Checkbox
                                    isChecked={Boolean(field.value)}
                                    isDisabled={Boolean(updatePermission?.unauthorized)}
                                    label="Sync run has rejected rows"
                                    onChange={(event) => field.onChange(event.target.checked ? true : null)}
                                  />
                                  <Row align="center" ml={8} gap={2}>
                                    <Text color={field.value ? "text.primary" : "text.secondary"}>Error rate threshold</Text>
                                    <Input
                                      disabled={!field.value}
                                      size="small"
                                      sx={{ width: "50px" }}
                                      value={field.value === true ? null : field.value}
                                      onChange={field.onChange}
                                    />
                                    <Text>%</Text>
                                  </Row>
                                </>
                              )}
                            />
                          </Column>
                          <Controller
                            name={`members.${index}.abort_seq_on_failure`}
                            render={({ field }) => (
                              <Checkbox
                                isChecked={field.value}
                                isDisabled={Boolean(updatePermission?.unauthorized)}
                                label="Sync run has fatal errors"
                                onChange={(event) => field.onChange(event.target.checked)}
                              />
                            )}
                          />
                        </Column>
                      </MenuList>
                    </Menu>
                  </Column>
                  <Link href={`/syncs/${field.value.sync?.id}`}>
                    <ExternalLinkIcon color="base.4" size={18} />
                  </Link>
                  <Button
                    sx={{ mx: 2, ":hover": { svg: { fill: "base.6", transition: "150ms fill" } } }}
                    variant="plain"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <XIcon color="base.4" size={24} />
                  </Button>
                </Box>
              )}
            />
          </>
        ))}
        <Row p={4} bg="white" alignItems="center" borderRadius={2}>
          <Button variant="secondary" onClick={() => setShowSelectSyncs(true)}>
            Add syncs
          </Button>
        </Row>
      </Column>

      {showSelectSyncs && (
        <SelectSyncs
          members={members}
          onClose={() => setShowSelectSyncs(false)}
          onSubmit={(syncs) => append(syncs.map((sync) => ({ sync, abort_seq_on_failure: true })))}
        />
      )}
    </>
  );
};

const SelectSyncs: FC<
  Readonly<{
    members: any;
    onSubmit: (syncs) => void;
    onClose: () => void;
  }>
> = ({ onSubmit, onClose, members }) => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { limit, offset, page, setPage } = useTableConfig<SyncsOrderBy>({ limit: 8 });
  const { selectedRows, onRowSelect } = useRowSelect();
  const [selectedSyncs, setSelectedSyncs] = useState<any>([]);

  const filters = useMemo(() => {
    const searchFilters: SyncsBoolExp[] = [
      { segment: { name: { _ilike: `%${search}%` } } },
      { destination: { name: { _ilike: `%${search}%` } } },
      { destination: { type: { _ilike: `%${search}%` } } },
    ];

    return { _or: searchFilters };
  }, [search]);

  const {
    data,
    isLoading: initialLoading,
    isRefetching,
  } = useSyncsForSequencesQuery({ offset, limit, filters }, { notifyOnChangeProps: "tracked", keepPreviousData: true });

  const syncs = data?.syncs;
  const syncsCount = data?.syncs_aggregate?.aggregate?.count ?? 0;

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: "segment.name",
        name: "Model",
        cell: (name) => (
          <Text isTruncated fontWeight="medium">
            {name}
          </Text>
        ),
      },
      {
        key: "destination",
        name: "Destination",
        cell: ({ definition, name }) => (
          <Row align="center" gap={2}>
            <IntegrationIcon name={definition.name} src={definition.icon} />
            <Text isTruncated fontWeight="medium">
              {name ?? definition?.name ?? "Private destination"}
            </Text>
          </Row>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, filters]);

  useEffect(() => {
    if (!isRefetching) {
      setLoading(false);
    }
  }, [isRefetching, syncs]);

  useEffect(() => {
    if (syncs) {
      const filtered = selectedSyncs.filter(({ id }) => selectedRows.includes(id));
      selectedRows.forEach((id) => {
        if (!filtered.find((sync) => sync.id === id)) {
          const sync = syncs.find((sync) => sync.id === id);
          if (sync) {
            filtered.push(sync);
          }
        }
      });
      setSelectedSyncs(filtered);
    }
  }, [selectedRows]);

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!selectedSyncs?.length}
            onClick={() => {
              onSubmit(selectedSyncs);
              onClose();
            }}
          >
            Add syncs
          </Button>
        </>
      }
      sx={{ width: "100%" }}
      title="Add syncs"
      onClose={onClose}
    >
      <Row mb={4} justify="space-between" align="center">
        <SearchInput placeholder="Search syncs by model or destination..." value={search ?? ""} onChange={setSearch} />
        {selectedRows?.length > 0 && (
          <Text>
            {selectedRows.length} {pluralize("sync", selectedRows.length)} selected
          </Text>
        )}
      </Row>
      <Table
        columns={columns}
        data={syncs}
        disabled={({ id }) => Boolean(members.find(({ sync }) => sync.id === id))}
        loading={initialLoading || (loading && isRefetching)}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
      <Row width="100%" mt={4} justify="flex-end">
        <Pagination count={syncsCount} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} />
      </Row>
    </Modal>
  );
};

import { FC } from "react";

import { TextProps, Text, BoxProps } from "@hightouchio/ui";

import { useContentVisibility } from "src/hooks/use-content-visibility";
import { TooltipProps, Tooltip } from "src/ui/tooltip";

type Props = {
  textProps?: Omit<TextProps, "children"> & BoxProps;
} & TooltipProps;

export const DeprecatedTextWithTooltip: FC<Readonly<Props>> = ({ children, disabled = false, portal, textProps, ...props }) => {
  const { isVisible, ref } = useContentVisibility<HTMLDivElement>();

  return (
    <Tooltip disabled={isVisible || disabled} portal={portal} {...props}>
      <Text ref={ref} isTruncated {...textProps}>
        {children}
      </Text>
    </Tooltip>
  );
};

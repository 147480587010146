import { Extension, Facet, RangeSetBuilder } from "@codemirror/state";
import { EditorView, ViewPlugin, ViewUpdate, Decoration, DecorationSet } from "@codemirror/view";

// Store the line number to highlight
// See https://codemirror.net/6/docs/ref/#state.Facet
const lineNumber = Facet.define<number | undefined, number>({
  combine: (values) => {
    // This plugin currently supports highlighting only a single line,
    // so an array of values, that CodeMirror sents us, needs to convert to a
    // single number
    return Array.isArray(values) && values.length > 0 ? values[0] : -1;
  },
});

const lineDecoration = Decoration.line({ class: "cm-errorLine" });

const decorateLines = (view: EditorView) => {
  const line = view.state.facet(lineNumber);
  const builder = new RangeSetBuilder<Decoration>();

  // Value is -1 when no line number was set by the `Editor` component
  if (line === -1) {
    return builder.finish();
  }

  for (const visibleRange of view.visibleRanges) {
    for (let position = visibleRange.from; position <= visibleRange.to; position++) {
      const visibleLine = view.state.doc.lineAt(position);

      if (visibleLine.number === line) {
        builder.add(visibleLine.from, visibleLine.from, lineDecoration);
      }
    }
  }

  return builder.finish();
};

const viewPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = decorateLines(view);
    }

    update(update: ViewUpdate) {
      this.decorations = decorateLines(update.view);
    }
  },
  {
    decorations: (view) => view.decorations,
  },
);

export const highlightErroredLine = (line?: number): Extension => {
  return [lineNumber.of(line), viewPlugin];
};

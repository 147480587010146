import { FC } from "react";

import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useFacebookAdsProductCatalogQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  catalogId: Yup.string().required(),
  mode: Yup.string().required().default("upsert"),
});

const FIELDS = [
  { label: "Id", value: "id", type: StandardFieldType.STRING, required: true },
  { label: "Title", value: "title", type: StandardFieldType.STRING, required: true },
  { label: "Description", value: "description", type: StandardFieldType.STRING, required: true },
  { label: "Availability", value: "availability", type: StandardFieldType.STRING, required: true }, //in stock, available for order, out of stock, discontinued
  { label: "Condition", value: "condition", type: StandardFieldType.STRING, required: true },
  { label: "Price", value: "price", type: StandardFieldType.STRING, required: true },
  { label: "Link", value: "link", type: StandardFieldType.STRING, required: true },
  { label: "Image Link", value: "image_link", type: StandardFieldType.STRING, required: true },
  { label: "Video Link", value: "video_link", type: StandardFieldType.STRING },
  { label: "Brand", value: "brand", type: StandardFieldType.STRING, required: true },
  { label: "Quantity to sell on Facebook", value: "quantity_to_sell_on_facebook", type: StandardFieldType.STRING }, // only for selling directly on facebook, not for dynamic ads
  { label: "Facebook Product Category", value: "fb_product_category", type: StandardFieldType.STRING },
  { label: "Google Product Category", value: "google_product_category", type: StandardFieldType.STRING },
  { label: "Product Type", value: "product_type", type: StandardFieldType.STRING },
  { label: "Sale Price", value: "sale_price", type: StandardFieldType.STRING },
  { label: "Sale Price Effective Date", value: "sale_price_effective_date", type: StandardFieldType.STRING },
  { label: "Unit Price", value: "unit_price", type: StandardFieldType.STRING },
  { label: "Shipping", value: "shipping", type: StandardFieldType.STRING }, //write as ... Country:Region:Service:Price.
  { label: "Shipping Weight", value: "shipping_weight", type: StandardFieldType.STRING },
  { label: "Item Group Id", value: "item_group_id", type: StandardFieldType.STRING },
  { label: "Size", value: "size", type: StandardFieldType.STRING },
  { label: "Marked For Product Launch", value: "marked_for_product_launch", type: StandardFieldType.STRING }, //marked, not marked
  { label: "Age Group", value: "age_group ", type: StandardFieldType.STRING }, //adult, all ages, teen, kids, toddler, infant, newborn
  { label: "Color", value: "color", type: StandardFieldType.STRING },
  { label: "Gender", value: "gender", type: StandardFieldType.STRING },
  { label: "Material", value: "material", type: StandardFieldType.STRING }, //cotton, denim, leather
  { label: "Disabled Capabilities", value: "disabled_capabilities", type: StandardFieldType.STRING },
  { label: "Origin Country", value: "origin_country", type: StandardFieldType.STRING }, //ISO Country Code (2 letter country code)
  { label: "Importer Name", value: "importer_name", type: StandardFieldType.STRING },
  { label: "Importer Address", value: "importer_address", type: StandardFieldType.OBJECT }, //{ street1: "1 Hacker Way", street2: "Building 18", city: "Menlo Park", region: "CA", postal_code: "94025", country: "US" }
  { label: "Additional Image Links", value: "additional_image_links", type: StandardFieldType.ARRAY },
  { label: "Age Group", value: "age_group", type: StandardFieldType.STRING }, //newborn, infant, toddler, kids, adult
  { label: "App Links", value: "applinks", type: StandardFieldType.OBJECT },
  { label: "Category", value: "category", type: StandardFieldType.STRING },
  { label: "Gtin", value: "gtin", type: StandardFieldType.STRING },
  { label: "Images", value: "images", type: StandardFieldType.ARRAY },
  { label: "Inventory", value: "inventory", type: StandardFieldType.OBJECT },
  { label: "Item Group Id", value: "item_group_id", type: StandardFieldType.STRING },
  { label: "Commerce Tax Category", value: "commerce_tax_category", type: StandardFieldType.STRING },
  { label: "Rich Text Description", value: "rich_text_description", type: StandardFieldType.STRING },
  { label: "Manufacturer Part Number", value: "manufacturer_part_number", type: StandardFieldType.STRING },
  { label: "Pattern", value: "pattern", type: StandardFieldType.STRING },
];

const CUSTOM_FIELDS = [
  { label: "Custom Label 0", value: "custom_label_0", type: StandardFieldType.STRING },
  { label: "Custom Label 1", value: "custom_label_1", type: StandardFieldType.STRING },
  { label: "Custom Label 2", value: "custom_label_2", type: StandardFieldType.STRING },
  { label: "Custom Label 3", value: "custom_label_3", type: StandardFieldType.STRING },
  { label: "Custom Label 4", value: "custom_label_4", type: StandardFieldType.STRING },
  { label: "Custom Number 0", value: "custom_number_0", type: StandardFieldType.NUMBER },
  { label: "Custom Number 1", value: "custom_number_1", type: StandardFieldType.NUMBER },
  { label: "Custom Number 2", value: "custom_number_2", type: StandardFieldType.NUMBER },
  { label: "Custom Number 3", value: "custom_number_3", type: StandardFieldType.NUMBER },
  { label: "Custom Number 4", value: "custom_number_4", type: StandardFieldType.NUMBER },
];
const MODES = [{ label: "Upsert", value: "upsert" }];

export const FacebookProductCatalogForm: FC = () => {
  const { config, setConfig, destination, errors } = useDestinationForm();
  const {
    data: productCatalogData,
    isFetching: loadingProductCatalog,
    refetch: getProductCatalogs,
  } = useFacebookAdsProductCatalogQuery({ destinationId: String(destination?.id) });

  const catalogList = productCatalogData?.facebookAdCatalogGetProductCatalogs?.fields;

  const catalogOptions = catalogList?.map((a) => ({ label: a.name, value: a.id })) || [];

  return (
    <>
      <ModeField options={MODES} />
      <Section>
        <Field label="Which catalog do you want to sync data to?" size="large">
          <Select
            isError={errors?.catalogId}
            isLoading={loadingProductCatalog}
            options={catalogOptions}
            placeholder="Select a product catalog..."
            reload={getProductCatalogs}
            value={config?.catalogId ? catalogOptions?.find((s) => config?.catalogId === s.value) : null}
            width="340px"
            onChange={(selected) => setConfig({ ...config, catalogId: selected?.value })}
          />
        </Field>
      </Section>

      <Section>
        <MappingsField options={FIELDS} />
      </Section>

      <Section>
        <MappingsField isCustom options={CUSTOM_FIELDS} />
      </Section>

      <Section>
        <DeleteField modes={["delete"]} />
      </Section>
    </>
  );
};

export default {
  form: FacebookProductCatalogForm,
  validation,
};

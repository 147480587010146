import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

import { LogoHeader } from "src/components/layout";

type Props = {
  title: string;
  children: ReactNode;
};

export const PreworkspacePage: FC<Readonly<Props>> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Column align="center" bg="gray.50" minHeight="100vh" width="100vw">
        <LogoHeader />
        <Column align="center" width="100%" flex={1} p={10}>
          {children}
        </Column>
      </Column>
    </>
  );
};

import React, { ReactElement, FC, useEffect, useState } from "react";

import { Row, Column, Button, Link, Text, Heading } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useSearchLookerLooksQuery, useDbtSyncModelsQuery, useGetSigmaExtensionQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { ExternalLinkIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Selectable } from "src/ui/selectable";

import { QUERY_TYPE_OPTIONS } from "./query-type-menu";

type Props = {
  source: {
    id: string;
    definition: { supportedQueries: string[] };
  };
  selected: QueryType | undefined;
  onChange: (value: QueryType | undefined) => void;
};

const helperContent = {
  dbt_model: {
    text: "To use this modeling method, you first need to connect Hightouch to the Git repo containing your dbt models.",
    docs: `${import.meta.env.VITE_DOCS_URL}/models/dbt-models`,
    link: `/extensions/dbt-models`,
    name: "dbt",
  },
  looker_look: {
    text: "To use this modeling method, you first need to connect Hightouch to your Looker instance.",
    docs: `${import.meta.env.VITE_DOCS_URL}/models/looker-models`,
    link: `/extensions/looker`,
    name: "Looker",
  },
  sigma: {
    text: "To use this modeling method, you first need to connect Hightouch to your Sigma account.",
    docs: `${import.meta.env.VITE_DOCS_URL}/models/sigma-models`,
    link: `/extensions/sigma`,
    name: "Sigma",
  },
};

export const QueryTypeSelect: FC<Readonly<Props>> = ({ source, selected, onChange }) => {
  const { sigmaExtension } = useFlags();
  const supportedQueries = source.definition?.supportedQueries.filter((type) => {
    if (type == QueryType.Sigma) {
      return sigmaExtension;
    }
    return QUERY_TYPE_OPTIONS[type];
  }) as QueryType[];

  const { data: searchQuery, isLoading: lookerLoading } = useSearchLookerLooksQuery();
  const looks = searchQuery?.searchLookerLooks?.looks ?? [];
  const noLooks = !lookerLoading && !looks?.length;
  const { data, isFetching: dbtLoading } = useDbtSyncModelsQuery(
    {
      sourceId: String(source?.id),
    },
    { enabled: Boolean(source?.id) },
  );
  const noDBTModels = !dbtLoading && !data?.dbt_sync_models?.length;
  const { data: sigmaCredentials, isFetching: sigmaLoading } = useGetSigmaExtensionQuery(undefined, {
    select: (data) => data.sigma_credentials,
  });
  const noSigma = !sigmaLoading && !sigmaCredentials?.length;

  useEffect(() => {
    if (!selected) {
      onChange(supportedQueries.length === 1 ? supportedQueries[0] : undefined);
    }
  }, [selected]);

  return dbtLoading || lookerLoading || sigmaLoading ? (
    <PageSpinner />
  ) : (
    <>
      <Row sx={{ flexWrap: "wrap", gap: 6 }}>
        {supportedQueries.map((type: QueryType) => {
          const disabled =
            (type === "dbt_model" && noDBTModels) || (type === "looker_look" && noLooks) || (type === "sigma" && noSigma);
          return <SelectQueryType key={type} disabled={disabled} type={type} onChange={onChange} />;
        })}
      </Row>
    </>
  );
};

function SelectQueryType({
  type,
  onChange,
  disabled,
}: {
  type: QueryType;
  onChange: (type: QueryType | undefined) => void;
  disabled: boolean;
}): ReactElement<any, any> {
  const { label, description, image } = QUERY_TYPE_OPTIONS[type];
  const [showHelper, setShowHelper] = useState(false);
  return (
    <Row
      sx={{
        opacity: disabled && !showHelper ? "50%" : "100%",
        ".preview-image": {
          opacity: 0.8,
        },
        ":hover:not(disabled)": {
          ".preview-image": {
            opacity: 1,
          },
        },
      }}
      onMouseEnter={() => setShowHelper(true)}
      onMouseLeave={() => setShowHelper(false)}
    >
      <Selectable width="300px" height="270px" bg="white" disabled={disabled} selected={false} onSelect={() => onChange(type)}>
        <Column textAlign="left" gap={4} pos="relative" p={6}>
          <Heading>{label}</Heading>

          {/* TODO remove the 'image &&' once we have an image for Sigma UI */}
          {image && React.createElement(image)}

          <Text>{description}</Text>

          {disabled && showHelper && (
            <Column pos="absolute" gap={4} top={0} left={0} bg="white" p={6} height="100%" width="100%" borderRadius="2xl">
              <Heading>{helperContent[type].name} extension not configured</Heading>
              <Text>{helperContent[type].text}</Text>
              <Row align="center" gap={4}>
                <Link href={helperContent[type].link}>
                  <Button size="sm" variant="primary">
                    Configure {helperContent[type].name}
                  </Button>
                </Link>
                <Link href={helperContent[type].docs}>
                  <Row sx={{ alignItems: "center" }}>
                    <Row mr={1}>Learn more</Row> <ExternalLinkIcon color="currentColor" size={18} />
                  </Row>
                </Link>
              </Row>
            </Column>
          )}
        </Column>
      </Selectable>
    </Row>
  );
}

import { FC } from "react";

import * as Yup from "yup";

import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { FromIdField } from "../from-id-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  fromId: Yup.mixed().required(),
  mappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Update", value: "update" }];

export const OneSignalForm: FC = () => {
  return (
    <>
      <ModeField options={MODES} value="update" />

      <Section>
        <FromIdField fieldName="OneSignal External User ID" />
      </Section>

      <Section>
        <MappingsField />
      </Section>

      <DeleteField modes={["clear"]} />
    </>
  );
};

export default {
  form: OneSignalForm,
  validation,
};

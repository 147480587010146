import { FC, useEffect } from "react";

import { Tabs, TabList, Tab, Heading, Row, Link } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import get from "lodash/get";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Page } from "src/components/layout";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant } from "src/graphql";

import { Metrics } from "../metrics/metrics";
import { EventModels } from "./event-models";
import { ParentModels } from "./parent-models";
import { RelatedModels } from "./related-models";
import { SyncTemplates } from "./sync-templates";

enum AudienceSetupPath {
  ParentModels = "/schema/parent-models",
  RelatedModels = "/schema/related-models",
  Events = "/schema/events",
  SyncTemplates = "/schema/sync-templates",
  Metrics = "/schema/metrics",
}

const PATH_TO_TAB_INDEX = {
  [AudienceSetupPath.ParentModels]: 0,
  [AudienceSetupPath.RelatedModels]: 1,
  [AudienceSetupPath.Events]: 2,
  [AudienceSetupPath.SyncTemplates]: 3,
  [AudienceSetupPath.Metrics]: 4,
};

enum NavigationTab {
  ParentModels = "Parent models",
  RelatedModels = "Related models",
  Events = "Events",
  SyncTemplates = "Sync templates",
  Metrics = "Metrics",
}

const TABS = [
  {
    label: NavigationTab.ParentModels,
    path: AudienceSetupPath.ParentModels,
  },
  {
    label: NavigationTab.RelatedModels,
    path: AudienceSetupPath.RelatedModels,
  },
  {
    label: NavigationTab.Events,
    path: AudienceSetupPath.Events,
  },
  {
    label: NavigationTab.SyncTemplates,
    path: AudienceSetupPath.SyncTemplates,
  },
  {
    label: NavigationTab.Metrics,
    path: AudienceSetupPath.Metrics,
  },
];

export const SetupAudiences: FC = () => {
  const { appEnableGoals, schemaV2 } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const tabIndex = get(PATH_TO_TAB_INDEX, location.pathname, 0);

  const handleTabChange = (nextTabIndex: number) => {
    navigate(TABS[nextTabIndex]!.path);
  };

  const isSyncTemplates = location.pathname === AudienceSetupPath.SyncTemplates;
  const isMetrics = location.pathname === AudienceSetupPath.Metrics;

  useEffect(() => {
    const allowedPaths = Object.values(AudienceSetupPath);

    if ((!appEnableGoals && isMetrics) || !allowedPaths.includes(location.pathname as AudienceSetupPath)) {
      navigate(AudienceSetupPath.ParentModels);
    }
  }, [isMetrics, appEnableGoals, location.pathname]);

  return (
    <PermissionProvider
      permissions={[
        { resource: isSyncTemplates ? "sync_template" : "audience_schema", grants: [ResourcePermissionGrant.Create] },
      ]}
    >
      <Page title={`${TABS[tabIndex]?.label} - Schema`}>
        <Row align="center" justify="space-between" mb={4}>
          <Heading size="xl">Schema</Heading>

          {schemaV2 && <Link href="/schema-v2">New schema experience</Link>}
        </Row>

        <Tabs index={tabIndex} onChange={handleTabChange}>
          <TabList>
            {TABS.filter(({ label }) => (appEnableGoals ? true : label !== NavigationTab.Metrics)).map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </TabList>
        </Tabs>

        <Routes>
          <Route path="/parent-models" element={<ParentModels />} />
          <Route path="/related-models" element={<RelatedModels />} />
          <Route path="/events" element={<EventModels />} />
          <Route path="/sync-templates" element={<SyncTemplates />} />
          {appEnableGoals && <Route path="/metrics" element={<Metrics />} />}
        </Routes>
      </Page>
    </PermissionProvider>
  );
};

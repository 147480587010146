import { FC } from "react";

import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import { LinkButton, Button, Heading, Column, Box, Text, useToast } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import { AuthLayout } from "src/components/auth/auth-layout";
import { useUser } from "src/contexts/user-context";
import { PartnerConnectionFragment, useRequestMembershipMutation } from "src/graphql";
import { Card } from "src/ui/card";

export const Unauthorized: FC = () => {
  const { toast } = useToast();
  const { user, workspace } = useUser();
  const location = useLocation();
  const state = location?.state as { error: string; partnerConnection: PartnerConnectionFragment } | undefined;

  const { isLoading: requestLoading, mutateAsync: requestMembership, isSuccess } = useRequestMembershipMutation();

  return (
    <>
      <Helmet>
        <title>Access restricted</title>
      </Helmet>

      <AuthLayout>
        <Card mt={24}>
          <Column align="center" gap={8} sx={{ color: "gray.900" }}>
            <Box color="grass.500">
              <ShieldExclamationIcon fill="currentColor" width="64px" />
            </Box>

            <Heading size="2xl">Access restricted</Heading>

            <Text>{state?.error || `You aren’t authorized to access this resource`}</Text>

            <Column gap={3} width="100%">
              {state?.partnerConnection && (
                <Button
                  isDisabled={isSuccess}
                  isLoading={requestLoading}
                  onClick={async () => {
                    await requestMembership({
                      workspaceId: String(state.partnerConnection.workspaceId),
                      partnerConnectionId: state.partnerConnection.uuid,
                    });

                    toast({
                      id: "request-access",
                      title: "Access requested",
                      variant: "success",
                    });
                  }}
                >
                  Request access
                </Button>
              )}
              {workspace ? (
                <LinkButton href="/">Back to workspace</LinkButton>
              ) : user ? (
                <LinkButton href="/workspaces">Back to workspaces</LinkButton>
              ) : (
                <>
                  <LinkButton href="/login">Login</LinkButton>
                  <LinkButton href="/signup">Signup</LinkButton>
                </>
              )}
            </Column>
          </Column>
        </Card>
      </AuthLayout>
    </>
  );
};

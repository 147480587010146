import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useActiveCampaignColumnsQuery } from "src/graphql";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { FromIdField } from "../from-id-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  object: Yup.string().required().default("contact"),
  fromId: Yup.mixed().required(),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const FIELDS = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Phone", value: "phone" },
];

const OBJECTS = [
  // { label: "Account", value: "account" },
  { label: "Contact", value: "contact" },
];

const MODES = [{ label: "Upsert", value: "upsert" }];

export const ActiveCampaignForm: FC = () => {
  const { config, destination } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useActiveCampaignColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.activeCampaignDescribeObject?.fields;

  const columnOptions =
    columns?.map((a) => ({
      label: a?.name,
      value: a?.id,
    })) || [];

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField options={MODES} value="upsert" />

      <Section>
        <FromIdField fieldName="Active Campaign Email" />
      </Section>

      <Section>
        <MappingsField options={FIELDS} />
      </Section>

      <Section>
        <MappingsField
          isCustom
          error={columnsError?.message}
          loading={loadingColumns}
          options={columnOptions}
          reload={getColumns}
        />
      </Section>
    </>
  );
};

export default {
  form: ActiveCampaignForm,
  validation,
};

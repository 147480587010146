import { ConditionType } from "src/types/visual";

export const colors = {
  base: {
    [ConditionType.And]: "teal.300",
    [ConditionType.Or]: "warning.300",
  },
  hover: {
    [ConditionType.And]: "teal.400",
    [ConditionType.Or]: "warning.400",
  },
};

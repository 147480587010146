import { FC } from "react";

import { Box, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";

import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { Banner } from "src/ui/banner";

export const OverageBanner: FC = () => {
  const { workspace, hasPermissions } = useUser();
  const isBillingPage = location.pathname.endsWith("/settings/billing");
  const { data: entitlementsData, isLoading: loadingEntitlements } = useEntitlements(!isBillingPage);
  const authorized = hasPermissions([{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }]);
  const { appShowOverageBanner } = useFlags();

  if (
    !appShowOverageBanner ||
    isBillingPage ||
    !authorized ||
    !workspace ||
    loadingEntitlements ||
    !entitlementsData.overage.overage
  ) {
    return null;
  }

  analytics.track("Overage Banner Shown", {
    workspace_id: workspace.id,
    authorized,
    ...entitlementsData.overage,
  });

  const onContactClick = () => {
    analytics.track("Overage Banner CTA Clicked", {
      workspace_id: workspace.id,
      authorized,
      ...entitlementsData.overage,
    });
  };

  return (
    <Banner sx={{ py: 3, bg: "forest" }}>
      <Text color="white" size="md" fontWeight="medium">
        This workspace has exceeded the number of destinations allowed on its current plan. Please{" "}
        <Box
          as={Link}
          to="/settings/billing"
          onClick={onContactClick}
          color="lightspeed.400"
          _hover={{ color: "lightspeed.500 !important" }}
        >
          click here
        </Box>{" "}
        to upgrade.
      </Text>
    </Banner>
  );
};

import { useState, ReactElement, useMemo, useRef, useEffect, useCallback } from "react";

import { Text } from "theme-ui";

import { Row } from "src/ui/box";
import { ReloadButton } from "src/ui/button";
import { getSearchRegExp } from "src/utils/string";

import { Input } from "../input";
import { Popout } from "../popout";
import { Option, Placeholder } from "./components";
import { MultiValue } from "./components/multi-value";
import { MultiSelectProps, SelectComponentProps, SelectOption } from "./types";

export function MultiSelect<Value>({
  disabled = false,
  width = 200,
  options,
  sx = {},
  onChange,
  value,
  loading = false,
  reload,
  reloadTooltip,
  placeholder,
  strategy,
  components,
  searchable,
  label,
}: MultiSelectProps<Value>): ReactElement {
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [search, setSearch] = useState("");

  const filteredOptions = useMemo(() => {
    if (search) {
      const regex = getSearchRegExp(search, "i");
      return options?.reduce((arr, option) => {
        if (option.options) {
          return arr.concat({ label: option.label, options: option.options.filter((o) => regex.test(o.label ?? "")) });
        } else {
          return regex.test(option.label ?? "") ? arr.concat(option) : arr;
        }
      }, []);
    }
    return options;
  }, [options, search]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: "center" });
    }
  }, [selectedRef]);

  const isSelected = useCallback(
    (option) => {
      return value?.includes(option.value);
    },
    [value, options],
  );

  const contentSx = {
    py: "2px",
    overflow: "auto",
    height: "max-content",
    minWidth: width ? undefined : "112px",
    width,
  };

  const ValueComponent = components?.Value ?? MultiValue;
  const PlaceholderComponent = components?.Placeholder ?? Placeholder;

  const handleChange = useCallback(
    (option: SelectOption<Value>) => {
      if (isSelected(option)) {
        onChange(value?.filter((v) => v !== option.value) ?? [], option);
      } else {
        onChange([...(value || []), ...(option.value ? [option.value] : [])], option);
      }
    },
    [onChange, isSelected],
  );

  const isPlaceholder = !value || value.length === 0;

  return (
    <Popout
      content={
        <>
          {searchable && (
            <>
              <Input
                ref={searchRef}
                placeholder="Search..."
                sx={{ userSelect: "none", mb: 2, px: 3, border: "none" }}
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
              />
              {!filteredOptions?.length && <Text sx={{ p: 3, color: "base.6" }}>No options found</Text>}
            </>
          )}
          {filteredOptions?.map((option) => {
            if (option.options) {
              const group = option.label;
              return (
                <>
                  <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", textTransform: "uppercase", p: 3 }}>
                    {group}
                  </Text>
                  {option.options.map((groupedOption) => {
                    const selected = isSelected(groupedOption);
                    return (
                      <Option
                        key={String(option.value)}
                        ref={selected ? selectedRef : undefined}
                        multi
                        disabled={disabled}
                        option={groupedOption}
                        selected={selected}
                        value={value}
                        onClick={handleChange}
                      />
                    );
                  })}
                </>
              );
            } else {
              const selected = isSelected(option);
              return (
                <Option
                  key={String(option.value)}
                  ref={selected ? selectedRef : undefined}
                  multi
                  disabled={disabled}
                  option={option}
                  selected={selected}
                  value={value}
                  onClick={handleChange}
                />
              );
            }
          })}
        </>
      }
      contentSx={contentSx}
      disabled={disabled}
      maxHeight={300}
      strategy={strategy}
      sx={{ flex: 1, ...sx }}
      onOpen={() => {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 100);
      }}
    >
      {({ isOpen }) => {
        const props: SelectComponentProps = {
          isOpen,
          disabled,
          loading,
        };
        return (
          <Row sx={{ alignItems: "center", width: "100%" }}>
            {isPlaceholder ? (
              <PlaceholderComponent {...props}>{placeholder}</PlaceholderComponent>
            ) : (
              <ValueComponent {...props} label={label} value={value} />
            )}
            {reload && (
              <ReloadButton disabled={disabled} loading={loading} sx={{ ml: 2 }} tooltip={reloadTooltip} onClick={reload} />
            )}
          </Row>
        );
      }}
    </Popout>
  );
}

import { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface NumberType extends BaseType {
  type: "NUMBER";
  /**
   * Min number value.
   */
  min?: number;
  /**
   * Max number value.
   */
  max?: number;

  /**
   * WARNING: Javascript number looses precision after ~16 digits.
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
   */
  precision?: number;
}

export function number(props?: Props<NumberType>): NumberType {
  return {
    ...props,
    type: "NUMBER",
  };
}

export const validator: ValidatorSync<NumberType> = (type) => {
  let schema = yup.number();

  if (typeof type.min === "number") schema = schema.min(type.min);
  if (typeof type.max === "number") schema = schema.max(type.max);

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};

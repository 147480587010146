import { FC, ReactNode } from "react";

import { Text, Column, Row } from "@hightouchio/ui";

import { TableIcon } from "src/ui/icons";
import { Strike } from "src/utils/strike";

type Props = {
  table: string;
  oldTable?: string;
  actions?: ReactNode;
};

export const TableQuery: FC<Readonly<Props>> = ({ table, oldTable, actions }) => (
  <Column border="1px" borderColor="base.border" borderRadius="md" overflow="hidden" minWidth={0} flex={1}>
    <Row align="center" px={4} py={4} borderBottom="1px" borderColor="base.border" gap={4} justify="space-between">
      <Row align="center" gap={2}>
        <TableIcon />
        <Text fontWeight="medium" size="lg">
          Table
        </Text>
      </Row>
      {actions}
    </Row>

    <Column p={4}>
      <Text>
        Selecting all rows from{" "}
        <Text fontWeight="semibold">
          <Strike _new={table} old={oldTable} />
        </Text>
      </Text>
    </Column>
  </Column>
);

/* eslint-disable jest/no-export */
import { useEffect, useCallback } from "react";

import { TestDestinationResponse, TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import { Button, Text, Spinner } from "@hightouchio/ui";

import {
  DestinationDefinitionFragment as DestinationDefinition,
  useTestNewDestinationMutation,
  useTestUpdatedDestinationMutation,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Circle } from "src/ui/circle";

export enum TestResult {
  Unknown,
  Success,
  Failed,
  Warning,
}

type NewDestinationProperties = {
  definition: DestinationDefinition;
  configuration: Record<string, unknown> | undefined;
  credentialId?: string;
  result: TestResult;
  onResult: (result: TestResult) => void;
  onTestResult: (result: TestDestinationStepStatus[] | null) => void;
  onError: (error: Error | null) => void;
  onLoading?: (loading: boolean) => void;
  size?: "lg";
};

/**
 * Custom hook for both test destination buttons.
 */
function useCommonTestDestinationEffects(
  { onError, onResult, onTestResult, onLoading }: NewDestinationProperties | UpdatedDestinationProperties,
  testing: boolean,
  testResult?: any,
) {
  useEffect(() => {
    if (onLoading) {
      onLoading(testing);
    }
  }, [testing, onLoading]);

  const fullStatus = getFullTestStatus(testResult);

  useEffect(() => {
    if (testResult?.success) {
      onResult(TestResult.Success);
      return;
    } else if (testResult?.reason) {
      onError(new Error(testResult.reason));
      onResult(TestResult.Failed);
      return;
    }
    if (!testResult?.statuses) {
      onTestResult(null);
      return;
    }
    onTestResult(testResult.statuses);
    if (fullStatus === TestResult.Success) {
      onResult(TestResult.Success);
      return;
    }
    onResult(fullStatus);
  }, [testResult?.statuses, testResult?.reason, testResult?.success, onError, onResult]);
}

/**
 * Performs a test connection to the destination when the button is clicked, and notifies the status.
 * This is required to create a new destination.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestNewDestinationButton(props: Readonly<NewDestinationProperties>): JSX.Element | null {
  const { definition, configuration, credentialId, size } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestNewDestinationMutation();

  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        type: definition.type,
        configuration,
        credentialId,
      }),
    [definition, configuration, credentialId],
  );

  useCommonTestDestinationEffects(props, testing, {
    statuses: data?.testNewDestination?.statuses,
    success: data?.testNewDestination?.success,
    reason: data?.testNewDestination?.reason,
  });

  return (
    <Button isLoading={testing} size={size} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

type UpdatedDestinationProperties = {
  destinationId: string;
  credentialId: string | undefined;
  newConfiguration: Record<string, unknown> | undefined;
  onResult: (result: TestResult) => void;
  onTestResult: (result: any) => any;
  onError: (error: Error | null) => void;
  onLoading: (loading: boolean) => void;
  size?: "lg";
};

/**
 * Performs a test connection to the destination when the button is clicked, and notifies the status.
 * This is not required to update a destination.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestUpdatedDestinationButton(props: Readonly<UpdatedDestinationProperties>): JSX.Element | null {
  const { destinationId, newConfiguration, credentialId, size } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestUpdatedDestinationMutation();
  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        destinationId,
        newConfiguration,
        credentialId,
      }),
    [destinationId, newConfiguration, credentialId],
  );

  useCommonTestDestinationEffects(props, testing, {
    statuses: data?.testUpdatedDestination?.statuses,
    success: data?.testUpdatedDestination?.success,
    reason: data?.testUpdatedDestination?.reason,
  });

  return (
    <Button isLoading={testing} size={size} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

/**
 * Displays a badge with the result of testing a destination.
 *
 * @param result Result of the test.
 * @param testing If the testing is in progress.
 *
 * @returns The badge.
 */
export function TestDestinationBadge({
  result,
  testing,
}: Readonly<{ result: TestResult; testing?: boolean }>): JSX.Element | null {
  if (testing) {
    return (
      <Badge variant="base">
        <Spinner mr={2} size="sm" />
        <Text>Connecting...</Text>
      </Badge>
    );
  }

  let color: string | undefined;
  let message: string | undefined;

  switch (result) {
    case TestResult.Success:
      color = "green";
      message = "Connected";
      break;
    case TestResult.Warning:
      color = "yellow";
      message = "Warning";
      break;
    case TestResult.Failed:
      color = "red";
      message = "Error";
      break;
    default:
      return null;
  }

  return (
    <Badge sx={{ ml: 4 }} variant="base">
      <Circle color={color} radius="12px" sx={{ mr: 2 }} />
      <Text>{message}</Text>
    </Badge>
  );
}

function getFullTestStatus(response: TestDestinationResponse): TestResult {
  if (response.success) {
    return TestResult.Success;
  } else if (response.reason) {
    return TestResult.Failed;
  }

  const statuses = response.statuses;
  const fullSuccess = statuses?.every((status) => {
    return status?.success;
  });
  if (fullSuccess) {
    return TestResult.Success;
  }

  return statuses?.every((status) => {
    return !status?.success;
  })
    ? TestResult.Failed
    : TestResult.Warning;
}

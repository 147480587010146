import { BaseType, Props, ValidatorSync } from "./base";

export interface EnumType extends BaseType {
  type: "ENUM";
  options?: { label?: string; description?: string; value: Primitive }[];
  caseInsensitive?: boolean;
}

type Primitive = string | number;

type EnumProps = Props<
  Omit<EnumType, "options"> & {
    options?: (
      | { label?: string; description?: string; value: Primitive }
      | Primitive
    )[];
  }
>;

export function _enum(opts?: EnumProps): EnumType {
  return {
    ...opts,
    options:
      opts?.options &&
      opts?.options?.map((value) =>
        typeof value === "string" || typeof value === "number"
          ? { value }
          : value,
      ),
    type: "ENUM",
  };
}

const tryToLowercase = (value: unknown) => {
  return typeof value === "string" ? value.toLowerCase() : value;
};

export const validator: ValidatorSync<EnumType> = (type) => {
  const set =
    type.options &&
    new Set(
      type.options?.map(({ value }) =>
        type.caseInsensitive ? tryToLowercase(value) : value,
      ),
    );

  return (value) => {
    if (set && !set.has(type.caseInsensitive ? tryToLowercase(value) : value))
      throw new Error(`value ${JSON.stringify(value)} is not a valid enum.`);
  };
};

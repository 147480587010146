import * as Yup from "yup";

// YUP SCHEMAS
const standardMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
  object: Yup.string().nullable().notRequired(),
  type: Yup.string().default("standard"),
  ignoreNull: Yup.boolean().notRequired(),
});

const referenceMappingSchema = Yup.object().shape({
  lookup: Yup.object().shape({
    from: Yup.mixed().nullable().required(),
    by: Yup.string().nullable().required(),
    object: Yup.string().nullable().required(),
    byType: Yup.string().nullable().notRequired(),
  }),
  to: Yup.string().nullable().required(),
  object: Yup.string().nullable().notRequired(),
  type: Yup.string().default("reference"),
});

const staticMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  value: Yup.mixed().nullable(), // This is not required so we can use `null`
  valueType: Yup.string().required(),
  type: Yup.string().default("static"),
});

const variableMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  variable: Yup.string().required(),
  type: Yup.string().default("variable"),
});

const freeformMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
});

const templateMappingSchema = Yup.object().shape({
  template: Yup.string().nullable().required(),
  to: Yup.string().nullable().required(),
  type: Yup.string().default("template"),
});

const mappingsChildrenSchema = Yup.lazy((val) => {
  const v = val as any;
  if (v?.type === "reference" || typeof v?.lookup === "object") {
    return referenceMappingSchema;
  } else if (v?.type === "static" || v?.value !== undefined) {
    return staticMappingSchema;
  } else if (v?.type === "variable" || typeof v?.variable === "string") {
    return variableMappingSchema;
  } else if (v?.type === "template" || typeof v?.template === "string") {
    return templateMappingSchema;
  } else if (v?.type === "object") {
    return objectMappingSchema;
  } else if (v?.type === "array") {
    return arrayMappingSchema;
  } else {
    return standardMappingSchema;
  }
});

const objectMappingSchema = Yup.object().shape({
  from: Yup.array().of(mappingsChildrenSchema).min(1),
  to: Yup.string().nullable().required(),
  type: Yup.string().default("object"),
  ignoreNull: Yup.boolean().notRequired(),
});

const arrayMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
  type: Yup.string().default("array"),
  ignoreNull: Yup.boolean().notRequired(),
  children: Yup.array().of(mappingsChildrenSchema).min(1),
});

export const COMMON_SCHEMAS = {
  standardMapping: standardMappingSchema,
  referenceMapping: referenceMappingSchema,
  staticMapping: staticMappingSchema,
  variableMapping: variableMappingSchema,
  objectMapping: objectMappingSchema,
  arrayMapping: arrayMappingSchema,
  freeformMapping: freeformMappingSchema,
  templateMapping: templateMappingSchema,
  standardOrStaticMapping: Yup.lazy((val) =>
    val?.["type"] === "static" || val?.["value"] !== undefined
      ? staticMappingSchema
      : standardMappingSchema,
  ),
  mappings: Yup.array().of(mappingsChildrenSchema).notRequired().default([]),
  externalIdMapping: freeformMappingSchema.required().default(undefined),
  advancedIdMapping: mappingsChildrenSchema,
  columnOrConstant: Yup.lazy((val) =>
    typeof val !== "string"
      ? Yup.object()
          .shape({ from: Yup.mixed().nullable().required() })
          .required()
          .default(null)
          .nullable()
      : Yup.string().required(),
  ),
  columnOrConstantMulti: Yup.lazy((val) =>
    !Array.isArray(val)
      ? Yup.object()
          .shape({ from: Yup.mixed().nullable().required() })
          .required()
          .default(null)
          .nullable()
      : Yup.array().min(1).required(),
  ),

  optionalColumnOrConstant: Yup.lazy((val) =>
    typeof val !== "string"
      ? Yup.object().shape({ from: Yup.string().notRequired() }).notRequired()
      : Yup.string().notRequired(),
  ),

  column: Yup.object()
    .shape({ from: Yup.mixed().required() })
    .default(null)
    .nullable(),

  requiredColumn: Yup.object()
    .shape({ from: Yup.string().required() })
    .required(),

  advancedColumn: Yup.lazy((val) => {
    const v = val as any;
    if (!val) {
      return Yup.object().nullable().notRequired();
    }
    if (v?.type === "static" || v?.value !== undefined) {
      return Yup.object().shape({
        value: Yup.mixed().nullable(), // This is not required so we can use `null`
        valueType: Yup.string().required(),
        type: Yup.string().default("static"),
      });
    } else if (v?.type === "variable" || typeof v?.variable === "string") {
      return Yup.object().shape({
        variable: Yup.string().required(),
        type: Yup.string().default("variable"),
      });
    } else if (v?.type === "template" || typeof v?.template === "string") {
      return Yup.object().shape({
        template: Yup.string().nullable().required(),
        type: Yup.string().default("template"),
      });
    } else {
      return Yup.object().shape({
        from: Yup.mixed().nullable().required(),
        object: Yup.string().nullable().notRequired(),
        type: Yup.string().default("standard"),
        ignoreNull: Yup.boolean().notRequired(),
      });
    }
  }),

  associationMappings: Yup.array().of(referenceMappingSchema),
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const PlusIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </Icon>
  );
};

import { FC, ReactNode } from "react";

import { Box } from "src/ui/box";

interface Props {
  children: ReactNode;
  top?: string;
}

export const Sidebar: FC<Props> = ({ children, top = "192px" }) => {
  return (
    <Box
      sx={{
        minWidth: "280px",
        maxWidth: "280px",
        borderLeft: "small",
        pl: 8,
        position: "sticky",
        top,
        ml: 10,
        alignSelf: "flex-start",
        height: "max-content",
      }}
    >
      {children}
    </Box>
  );
};

import { RawColumn, RelatedColumn, ColumnType } from "./column";

export type TraitDefinition = {
  name: string;
  type: TraitType;
  config: Record<string, unknown>;
  relationshipId: string;
  modelId: string;
};

export enum TraitType {
  Count = "count",
  Sum = "sum",
  Average = "average",
  RawSql = "raw_sql",
  LeastFrequent = "least_frequent",
  MostFrequent = "most_frequent",
  First = "first",
  Last = "last",
}

export type CountTraitConfig = {
  column?: RawColumn | RelatedColumn;
};

export type SumTraitConfig = {
  column: RawColumn | RelatedColumn;
};

export type AverageTraitConfig = {
  column: RawColumn | RelatedColumn;
};

export type CountDedupedTraitConfig = {
  toSelect: RawColumn | RelatedColumn;
};

export type OrderDedupedTraitConfig = {
  orderBy: RawColumn | RelatedColumn;
  toSelect: RawColumn | RelatedColumn;
};

export type RawSqlTraitConfig = {
  // The raw SQL for the aggregation. Columns must be referred to with `{{column "name"}}`.
  // For example:  `COUNT(DISTINCT {{column "product_id"}})`
  aggregation: string;

  // The type of the result of the trait. This is used to determine what
  // property operators are compatible with the trait.
  resultingType: ColumnType;

  // The raw SQL for the trait's default value (i.e. if the trait evaluate to NULL).
  // An empty string is treated as NULL.
  // Note that string literals must be quoted appropriately.
  defaultValue?: string;
};

import { Heading, Link, Text, Column, Button } from "@hightouchio/ui";
import { InlineWidget } from "react-calendly";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Card } from "src/ui/card";

function ApprovalPage() {
  return (
    <AuthLayout heading="We'd love to get your team onboarded to Hightouch!">
      <Card>
        <Column align="center" gap={8} sx={{ color: "gray.900" }}>
          <Heading size="2xl">Arrange a meeting</Heading>

          <InlineWidget styles={{ height: "400px", width: "600px" }} url="https://calendly.com/hightouchio/20-minute-meeting" />

          <Text>
            Or email <Link href="mailto:hello@hightouch.io">hello@hightouch.io</Link> for more information.
          </Text>

          <Button onClick={() => (window.location.href = `${import.meta.env.VITE_API_BASE_URL}/auth/logout`)}>
            Sign in with a different account
          </Button>
        </Column>
      </Card>
    </AuthLayout>
  );
}

export default ApprovalPage;

import { FC } from "react";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Row, Heading, Column, Button } from "@hightouchio/ui";
import { useNavigate } from "react-router-dom";

import * as analytics from "src/lib/analytics";
import { Confetti } from "src/ui/confetti";
import { Modal } from "src/ui/modal";

type ConfettiModalProps = {
  syncId: string;
};

export const ConfettiModal: FC<ConfettiModalProps> = ({ syncId }) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen onClose={() => {}}>
      <Confetti showConfetti={true}>
        <Column align="center" p={4}>
          <CheckCircleIcon height="64px" color="var(--chakra-colors-success-base)" />

          <Heading size="lg" my={8}>
            Your first sync has been added to this workspace!
          </Heading>

          <Row width="100%" justify="stretch" gap={4} sx={{ a: { width: "100%" }, button: { width: "100%" } }}>
            <Button
              size="lg"
              variant="secondary"
              onClick={() => {
                navigate(`/syncs/${syncId}`, {});
                analytics.track("Go To Syncs Clicked", {});
              }}
            >
              View sync
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => {
                navigate(`/syncs/${syncId}?autorun=true`, {});
                analytics.track("Onboarding Run Sync Button Clicked", {
                  sync_id: syncId,
                });
              }}
            >
              Run sync
            </Button>
          </Row>
        </Column>
      </Confetti>
    </Modal>
  );
};

import { FC, useState, useEffect } from "react";

import { useToast, FormField, Column, TextInput, Heading, Text, Textarea } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { Destinations } from "src/components/clone/destinations";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import { useAudienceQuery, useCreateAudienceMutation, useDestinationDefinitionsQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useQueryState } from "src/utils/models";

export const CloneAudience: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
  } = useQueryState();

  const { data: audienceData, isLoading: modelLoading } = useAudienceQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id) },
  );

  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const audience = audienceData?.segments_by_pk;
  const parentModel = audience?.parent;
  const syncs = audience?.syncs;
  const source = audience?.connection;

  useEffect(() => {
    setName(audience?.name ?? "");
  }, [audience?.name]);

  const { data: destinationDefinitions, isLoading: destinationDefinitionsLoading } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const create = async () => {
    const result = await createAudience({
      input: {
        query_type: QueryType.Visual,
        visual_query_parent_id: parentModel?.id,
        visual_query_filter: queryState?.visualQueryFilter,
        name,
        description,
        primary_key: parentModel?.primary_key,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        destination_instances: {
          data: selectedSyncs.size
            ? syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                  destination_id: destination!.id,
                  config,
                  schedule,
                  schedule_paused: true,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                  row_threshold_attempted,
                  row_threshold_total,
                  alert_instances: {
                    data: sync_alerts.map((alert) => ({
                      alert_id: alert.id,
                      fatal_error: alert.fatal_error,
                      row_error: alert.row_error,
                    })),
                  },
                })) ?? []
            : [],
        },
      },
    });

    toast({
      id: "clone-audience",
      title: `Cloned ${audience?.name} and ${selectedSyncs?.size} syncs`,
      variant: "success",
    });

    navigate(`/audiences/${result.insert_segments_one?.id}`);
  };

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  if (modelLoading || destinationDefinitionsLoading) {
    return <PageSpinner />;
  }

  const steps: Step[] = [
    {
      title: "Change audience",
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            audience={audience}
            canRedo={canRedoVisualQueryFilterChange}
            canUndo={canUndoVisualQueryFilterChange}
            parentModel={parentModel}
            queryState={queryState}
            source={source}
            onRedo={redoVisualQueryFilterChange}
            onUndo={undoVisualQueryFilterChange}
            onVisualQueryFilterChange={setVisualQueryFilter}
          />
        </FormErrorProvider>
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select syncs",
      header: (
        <Column gap={2}>
          <Heading>Connect destinations</Heading>
          <Text fontWeight="semibold" color="text.secondary">
            Select all the destinations you'd like to sync to. Cloned syncs are disabled by default, review the cloned
            configuration before enabling.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations
          definitions={destinationDefinitions}
          selected={selectedSyncs}
          setSelected={setSelectedSyncs}
          syncs={syncs}
        />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !name,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => (
      <Column gap={8}>
        <FormField label="Audience name">
          <TextInput placeholder={audience?.name} value={name} onChange={(event) => setName(event.target.value)} />
        </FormField>
        <FormField isOptional label="Description">
          <Textarea placeholder="Enter a description..." value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormField>
      </Column>
    ),
  });

  return (
    <>
      <Helmet>
        <title>{audience?.name ? `Clone "${audience.name}" audience` : "Clone audience"}</title>
      </Helmet>

      <Wizard
        fullscreen={step === 0}
        setStep={setStep}
        step={step}
        steps={steps}
        title="Clone audience"
        onCancel={() => {
          navigate(`/audiences/${id}`);
        }}
        onSubmit={create}
      />
    </>
  );
};

import { FC } from "react";

import { useToken } from "@chakra-ui/system";

import { IconProps } from "./icon";

export const OnboardingIcon: FC<Readonly<IconProps>> = (props) => {
  const [color] = useToken("colors", [props.color ?? ""]);

  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2644 10.7357L6.14288 16.8571M11.4539 6.14066C12.1691 5.42461 13.0782 4.93368 14.0696 4.72761L17.436 4.02801C18.3521 3.83753 19.1625 4.64795 18.972 5.56401L18.2724 8.93039C18.0663 9.92178 16.6437 11.7626 15.9277 12.4778M6.58687 14.0302L6.57735 14.0935C6.58687 15.0252 6.58687 15.0347 7.0432 15.491C7.71509 16.1629 9.19929 16.4104 10.1405 16.2727M12.6626 5.24105C14.4969 7.10562 15.491 8.06657 17.3223 9.90075"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 15.9247L12.2409 18.6919C12.3224 18.9963 12.7508 19.1034 13.0016 18.8828L15.4959 16.6822C15.6634 16.5344 15.7595 16.3338 15.7613 16.1237L15.7857 13.6426"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.3"
      />
      <path
        d="M7.03799 12.2136L4.30416 11.5508C4.00338 11.4779 3.8976 11.0946 4.11548 10.8701L6.2896 8.63839C6.43565 8.48848 6.6338 8.40258 6.84142 8.40096L9.29263 8.37908M10.4286 7.21387L8.75976 8.92607L6.97641 12.571"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.3"
      />
    </svg>
  );
};

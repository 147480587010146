import { FC } from "react";

import { Heading } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";
import { Grid, Text } from "theme-ui";

import { Page } from "src/components/layout";
import { useExtensionsQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Card } from "src/ui/card";
import {
  AlertingIcon,
  AzureIcon,
  BitbucketIcon,
  CheckCircleIcon,
  DatadogIcon,
  DBTIcon,
  FivetranIcon,
  GitHubIcon,
  GitIcon,
  GitlabIcon,
  LookerIcon,
  MonitoringIcon,
  PagerDutyIcon,
  SigmaIcon,
  SlackIcon,
} from "src/ui/icons";
import { IconProps } from "src/ui/icons/icon";
import { PageSpinner } from "src/ui/loading";

export const Extensions: FC = () => {
  const { data, isLoading: loading } = useExtensionsQuery();

  const { fivetranExtension, sigmaExtension } = useFlags();

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Page title="Extensions">
      <Heading mb={2} size="xl">
        Extensions
      </Heading>
      <Text sx={{ color: "base.5", mb: 10, fontSize: 2 }}>Connect your favorite tools to get more out of Hightouch</Text>

      <Grid columns="repeat(auto-fit, 350px)" gap={6}>
        <ExtensionCard
          description="Get notified about sync errors"
          icon={AlertingIcon}
          integrations={[SlackIcon, PagerDutyIcon]}
          route="alerting"
          setup={Boolean(data?.alerting?.length)}
          title="Alerting"
        />
        <ExtensionCard
          description="Import models and analyses from dbt"
          icon={DBTIcon}
          route="dbt-models"
          setup={Boolean(data?.dbtModels?.length)}
          title="dbt models"
        />
        <ExtensionCard
          description="Schedule syncs to run after dbt jobs"
          icon={DBTIcon}
          route="dbt-cloud"
          setup={Boolean(data?.dbtCloud?.length)}
          title="dbt Cloud"
        />
        {fivetranExtension && (
          <ExtensionCard
            description="Schedule syncs to run after Fivetran jobs"
            icon={FivetranIcon}
            route="fivetran"
            setup={Boolean(data?.fivetran?.length)}
            title="Fivetran"
          />
        )}
        <ExtensionCard
          description="Import models from Looker"
          icon={LookerIcon}
          route="looker"
          setup={Boolean(data?.looker?.length)}
          title="Looker"
        />
        <ExtensionCard
          description="Send metrics and events to other tools"
          icon={MonitoringIcon}
          integrations={[DatadogIcon]}
          route="monitoring"
          setup={Boolean(data?.monitoring?.length)}
          title="Monitoring"
        />
        {sigmaExtension && (
          <ExtensionCard
            description="Import models from Sigma"
            icon={SigmaIcon}
            route="sigma"
            setup={Boolean(data?.sigma?.length)}
            title="Sigma"
          />
        )}
        <ExtensionCard
          description="Manage syncs and models in a Git repo"
          icon={GitIcon}
          integrations={[GitHubIcon, BitbucketIcon, GitlabIcon, AzureIcon]}
          route="git-sync"
          setup={Boolean(data?.gitSync?.[0]?.enabled)}
          title="Version control with Git"
        />
      </Grid>
    </Page>
  );
};

export const ExtensionCard: FC<{
  title: string;
  icon: FC<IconProps>;
  description: string;
  route: string;
  setup: boolean;
  integrations?: FC<IconProps>[];
}> = ({ title, icon: Icon, description, route, setup, integrations }) => {
  return (
    <Link to={`/extensions/${route}${setup ? "/configuration" : ""}`}>
      <Card
        footer={
          <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Row sx={{ alignItems: "center" }}>
              {integrations?.map((Icon, i) => (
                <Icon key={i} size={20} sx={{ mr: 4 }} />
              ))}
            </Row>
            <Text sx={{ color: "ocean" }}>{setup ? "Manage" : "Set up extension"}</Text>
          </Row>
        }
        sx={{ cursor: "pointer", ":hover": { bg: "gray.100" } }}
      >
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 3 }}>
          <Icon size={30} />
          {setup && <CheckCircleIcon color="green" />}
        </Row>
        <Text sx={{ mb: 2, fontWeight: "bold", fontSize: 2 }}>{title}</Text>
        <Text sx={{ color: "base.5" }}>{description}</Text>
      </Card>
    </Link>
  );
};

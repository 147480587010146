import { FC, ReactNode, useMemo, useRef, useState } from "react";

import {
  Link,
  Row,
  Column,
  Text as HightouchUiText,
  SectionHeading,
  RadioGroup as HightouchUiRadioGroup,
  Radio,
  Box,
  FormField,
  TextInput,
  Alert,
  Paragraph,
  Badge as HightouchUiBadge,
  CodeSnippet,
  Spinner,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Text } from "theme-ui";

import { ChooseTunnelForm, CreateNormalTunnelForm, CreateReverseTunnelForm } from "src/components/tunnels/create-tunnel";
import { TunnelSelect } from "src/components/tunnels/tunnel-select";
import { Form as FormkitForm } from "src/formkit/components/form";
import { useFormkitContext } from "src/formkit/components/formkit-context";
import { processFormNode } from "src/formkit/formkit";
import { SourceDefinition, useCloudCredentialsV2Query, useTestTunnelQuery, useTunnelsQuery } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Code } from "src/ui/code";
import { Field } from "src/ui/field";
import { LightningIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Markdown } from "src/ui/markdown";
import { Message } from "src/ui/message";
import { NewSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";

import { Context, DEFAULT_PERMISSIONS, Permissions, PERMISSIONS } from "./constants";
import { LightningIcon as ShinyLightningIcon, LightningIconApi } from "./lightning-icon";
import { ProviderSection } from "./providers";

export type FormMethodProps = {
  definition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  setupMethods: Record<string, any>;
  credentialId: string | undefined;
  setCredentialId: (credential: string) => void;
  tunnelId: string | null | undefined;
  setTunnelId: (tunnel: string | null | undefined) => void;
  isSetup: boolean;
} & SyncEngineProps;

export enum Section {
  AuthMethod = "authMethod",
  Tunneling = "tunneling",
  Providers = "providers",
  Form = "form",
  Diffing = "diffing",
  CredentialsForm = "credentials",
}

export const FormMethod: FC<Readonly<FormMethodProps>> = ({
  definition,
  config,
  setupMethods,
  tunnelId,
  setTunnelId,
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  credentialId,
  setCredentialId,
  hasSetupLightning,
}) => {
  const { useHightouchUi } = useFormkitContext();
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingSource = setupMethods?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingSource?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.form, undefined, undefined, { useHightouchUi })}
        </FormkitForm>
      ),
    [matchingSource?.key, useHightouchUi],
  );

  const CredentialsForm = useMemo(
    () =>
      matchingSource?.credentialsForm && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.credentialsForm, undefined, undefined, { useHightouchUi })}
        </FormkitForm>
      ),
    [matchingSource?.key, useHightouchUi],
  );

  const showTunneling =
    // Does the source form support tunneling?
    matchingSource?.tunneling &&
    // If the source is Snowflake, check that the user has the matching feature flag.
    (definition.type !== "snowflake" || (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));

  const sections: Section[] = useMemo(() => {
    const steps: Section[] = [];
    if (showTunneling) {
      steps.push(Section.Tunneling);
    }

    if (["gcp", "aws", "azure"].includes(matchingSource?.provider)) {
      steps.push(Section.Providers);
    }

    if (matchingSource?.form) {
      steps.push(Section.Form);
    }

    if (definition.supportsInWarehouseDiffing) {
      steps.push(Section.Diffing);
    }

    if (matchingSource?.credentialsForm) {
      steps.push(Section.CredentialsForm);
    }

    return steps;
  }, [matchingSource, definition.supportsInWarehouseDiffing, showTunneling]);

  const hasMultipleSetupMethods = setupMethods.length > 1;

  return (
    <>
      <Step
        description={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Tunneling}
        sections={sections}
        title="Choose your connection type"
      >
        <Tunnel name={definition.name} value={tunnelId} onChange={setTunnelId} />
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Providers}
        sections={sections}
        title="Configure your service account"
      >
        <ProviderSection credentialId={credentialId} provider={matchingSource.provider} setCredentialId={setCredentialId} />
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Form}
        sections={sections}
        title={`Configure your ${definition.name} source`}
      >
        {Form}
      </Step>
      <Step
        description={
          "For optimal sync performance, Hightouch tracks incremental changes to your data model and only processes rows which have been added, changed, or removed since the last sync run. You can choose whether this tracking should be performed in Hightouch's infrastructure or natively in your data warehouse."
        }
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Diffing}
        sections={sections}
        title="Choose your sync engine"
      >
        <SyncEngine
          config={config}
          credentialId={credentialId}
          definition={definition}
          hasSetupLightning={hasSetupLightning}
          lightningEnabled={lightningEnabled}
          plannerDatabase={plannerDatabase}
          setLightningEnabled={setLightningEnabled}
          setPlannerDatabase={setPlannerDatabase}
          supportsPlannerDatabase={definition.supportsCrossDbReference}
        />
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.CredentialsForm}
        sections={sections}
        title={`Provide your ${definition.name} credentials`}
      >
        {CredentialsForm}
      </Step>
    </>
  );
};

export type SyncEngineProps = {
  hasSetupLightning: boolean;
  lightningEnabled: boolean | undefined;
  setLightningEnabled: (enabled: boolean | undefined) => void;
  plannerDatabase: string | undefined;
  setPlannerDatabase: (database: string | undefined) => void;
};

const SyncEngine: FC<
  SyncEngineProps & {
    supportsPlannerDatabase: boolean;
    definition: SourceDefinition;
    config: Record<string, unknown> | undefined;
    credentialId: string | undefined;
  }
> = ({
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  supportsPlannerDatabase,
  definition,
  config,
  credentialId,
  hasSetupLightning,
}) => {
  const name = definition.name;
  const { data } = useCloudCredentialsV2Query({ id: String(credentialId) }, { enabled: Boolean(credentialId) });

  const ctx = { definitionName: name, configuration: config, credential: data?.getCloudCredentials?.[0], plannerDatabase };

  const lightningPermissions = PERMISSIONS[definition.type]?.["lightning"] || DEFAULT_PERMISSIONS["lightning"];
  const standardPermissions = PERMISSIONS[definition.type]?.["default"] || DEFAULT_PERMISSIONS["default"];

  const { useHightouchUi } = useFormkitContext();

  const lightningIcon = useRef<LightningIconApi>(null);

  if (useHightouchUi) {
    return (
      <>
        {hasSetupLightning && (
          <Alert
            mb={6}
            message="This source is configured to use the Lightning sync engine, which uses the compute power of your database to calculate incremental changes to your data. Once enabled, the Lightning sync engine cannot be disabled."
            title="Information"
            variant="info"
          />
        )}

        <HightouchUiRadioGroup
          isDisabled={hasSetupLightning}
          value={lightningEnabled ? "lightning" : "standard"}
          onChange={(syncEngine) => {
            setLightningEnabled(syncEngine === "lightning");

            if (syncEngine === "lightning") {
              lightningIcon.current?.shine();
            }
          }}
        >
          <Radio
            badge={<HightouchUiBadge>Easier to set up</HightouchUiBadge>}
            description={
              <Markdown
                useHightouchUi
              >{`This engine is easier to set up because it only requires ***read access*** to ${definition.name}. It works by running a query in your database, reading its full results, and then calculating incremental changes.`}</Markdown>
            }
            label="Standard sync engine"
            value="standard"
          />
          <Radio
            badge={<ShinyLightningIcon ref={lightningIcon} />}
            description={
              <Markdown
                useHightouchUi
              >{`This engine is more difficult to configure but results in faster syncs. It requires ***read and write access*** to ${name}. It works by storing previously-synced data in a separate schema in your database. During a sync, Hightouch uses the compute power of your database to calculate incremental changes.`}</Markdown>
            }
            label="Lightning sync engine"
            value="lightning"
          />
        </HightouchUiRadioGroup>

        {lightningEnabled && supportsPlannerDatabase && (
          <FormField
            isOptional
            description="The vast majority of users can safely skip this question. In exceptionally rare cases, you may require the Lightning sync engine to store previously-synced records in a different location than the database or project used to run queries. Leave blank to skip."
            label="Override location of Lightning sync engine cache?"
            mt={6}
          >
            <TextInput
              isDisabled={hasSetupLightning}
              placeholder="Enter a different database or project..."
              value={plannerDatabase ?? ""}
              onChange={(event) => {
                setPlannerDatabase(event.target.value);
              }}
            />
          </FormField>
        )}

        {!lightningEnabled && standardPermissions && !hasSetupLightning && (
          <Box mt={4}>
            <AdditionalPermissions ctx={ctx} permissions={standardPermissions} />
          </Box>
        )}

        {lightningEnabled && lightningPermissions && !hasSetupLightning && (
          <Box mt={4}>
            <AdditionalPermissions ctx={ctx} permissions={lightningPermissions} />
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      {hasSetupLightning ? (
        // TODO(ziari): This styling is really messy and should be cleaned up once we adopt Hightouch UI.
        // I did this so that the Lightning engine callout would be styled the same as the IP address callout.
        <Message hideIcon sx={{ mt: 4, maxWidth: "100%", bg: "base.1", border: `1px solid #E9ECF5` }}>
          <Text sx={{ fontWeight: 400, fontSize: 13, color: "base.6" }}>
            This source is configured to use the Lightning sync engine, which uses the compute power of your database to
            calculate incremental changes to your data. Once enabled, the Lightning sync engine cannot be disabled.
          </Text>
        </Message>
      ) : (
        <RadioGroup
          disabled={hasSetupLightning}
          options={[
            {
              label: (
                <>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ mr: 2 }}>Standard sync engine</Text>
                    <Badge variant="base">Easier to set up</Badge>
                  </Row>
                </>
              ),
              description: `This engine is easier to set up because it only requires ***read access*** to ${definition.name}. It works by running a query in your database, reading its full results, and then calculating incremental changes.`,
              value: false,
            },
            {
              label: (
                <>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ mr: 2 }}>Lightning sync engine</Text>
                    <LightningIcon color="base.5" size={18} />
                  </Row>
                </>
              ),
              description: `This engine is more difficult to configure but results in faster syncs. It requires ***read and write access*** to ${name}. It works by storing previously-synced data in a separate schema in your database. During a sync, Hightouch uses the compute power of your database to calculate incremental changes.`,
              value: true,
            },
          ]}
          sx={{ mt: 4 }}
          value={lightningEnabled}
          onChange={(v) => {
            setLightningEnabled(v);
          }}
        />
      )}

      {lightningEnabled && supportsPlannerDatabase && (
        <Field
          optional
          description="The vast majority of users can safely skip this question. In exceptionally rare cases, you may require the Lightning sync engine to store previously-synced records in a different location than the database or project used to run queries. Leave blank to skip."
          label="Override location of Lightning sync engine cache?"
          sx={{ my: 8 }}
        >
          <Input
            disabled={hasSetupLightning}
            placeholder="Enter a different database or project..."
            value={plannerDatabase}
            onChange={(value) => {
              setPlannerDatabase(value);
            }}
          />
        </Field>
      )}

      {!lightningEnabled && standardPermissions && !hasSetupLightning && (
        <AdditionalPermissions ctx={ctx} permissions={standardPermissions} />
      )}
      {lightningEnabled && lightningPermissions && !hasSetupLightning && (
        <AdditionalPermissions ctx={ctx} permissions={lightningPermissions} />
      )}
    </>
  );
};

const AdditionalPermissions: FC<
  Readonly<{ ctx: Context; permissions: NonNullable<Permissions["default"] | Permissions["lightning"]> }>
> = ({ permissions, ctx }) => {
  const { useHightouchUi } = useFormkitContext();

  if (useHightouchUi) {
    return (
      <Alert
        message={
          <>
            <Markdown useHightouchUi>{permissions.reason(ctx)}</Markdown>

            {permissions.code.length > 0 && (
              <Box display="flex" flexDirection="column" gap={3} mt={3}>
                {permissions.code.map((codeBlock, index) => (
                  <CodeSnippet
                    key={index}
                    code={codeBlock.code(ctx)}
                    label={permissions.code.length > 1 ? codeBlock.title(ctx) : undefined}
                  />
                ))}
              </Box>
            )}
          </>
        }
        title="Additional permissions are required. Please modify and run the snippet below."
        variant="warning"
      />
    );
  }

  return (
    <Message hideIcon sx={{ mt: 4, maxWidth: "100%" }} variant="warning">
      <Column sx={{ gap: 1 }}>
        <Text sx={{ fontWeight: "bold" }}>Additional permissions are required. Please modify and run the snippet below.</Text>
        <Text sx={{ fontWeight: "semi", color: "base.6", whiteSpace: "pre-wrap" }}>
          <Markdown>{permissions.reason(ctx)}</Markdown>
        </Text>

        {Boolean(permissions.code.length) &&
          permissions.code.map((block, idx) => (
            <Code key={idx} sx={{ mb: 0 }} title={permissions.code.length > 1 ? block.title(ctx) : undefined}>
              <Text sx={{ whiteSpace: "pre-wrap" }}>{block.code(ctx)}</Text>
            </Code>
          ))}
      </Column>
    </Message>
  );
};

type TunnelProps = {
  name: string;
  value: string | null | undefined;
  onChange: (tunnel: string | null | undefined) => void;
};

const Tunnel: FC<TunnelProps> = ({ name, value, onChange }) => {
  const [setupStep, setSetupStep] = useState<"choose" | "normal" | "reverse" | undefined>();
  const [tunnelType, setTunnelType] = useState<"normal" | "reverse" | undefined>("normal");

  const { data: tunnelsData, isFetching: tunnelsLoading, refetch: refetchOptions } = useTunnelsQuery();
  const tunnelsOptions =
    tunnelsData?.getTunnels?.map(({ type, tunnel: { name, id } }) => ({ label: `${name} (${type})`, value: id ?? "" })) ?? [];

  const {
    data: tunnelTest,
    isFetching: tunnelTestLoading,
    refetch: refetchTest,
  } = useTestTunnelQuery(
    {
      id: value ?? "",
    },
    {
      enabled: Boolean(value),
    },
  );

  const onCreate = async (tunnel) => {
    if (tunnel.tunnel.id) {
      onChange(tunnel.tunnel.id);
      await refetchOptions();
    }
  };

  const hasExistingTunnels = tunnelsOptions?.length > 0;

  const { useHightouchUi } = useFormkitContext();
  const connectViaTunnel = value !== undefined;

  if (useHightouchUi) {
    return (
      <>
        <HightouchUiRadioGroup
          orientation="vertical"
          value={connectViaTunnel ? "tunnel" : "direct"}
          onChange={(value) => {
            onChange(value === "tunnel" ? null : undefined);
          }}
        >
          <Radio
            badge={<HightouchUiBadge>Most common</HightouchUiBadge>}
            label={`Connect directly to ${name}`}
            value="direct"
          />

          <Radio label="Connect via SSH tunnel" value="tunnel" />
        </HightouchUiRadioGroup>

        {connectViaTunnel && (
          <Box mt={6}>
            <TunnelSelect
              useHightouchUi
              value={value ? { id: value } : undefined}
              onChange={(tunnel) => {
                onChange(tunnel?.id ?? null);
              }}
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <RadioGroup
        options={[
          {
            label: (
              <>
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Connect directly to {name}</Text>
                  <Badge variant="base">Most common</Badge>
                </Row>
              </>
            ),
            value: false,
          },
          { label: `Connect via SSH tunnel`, value: true },
        ]}
        size="large"
        sx={{ mt: 4 }}
        value={value !== undefined}
        onChange={(v) => {
          onChange(v ? null : undefined);
        }}
      />
      {value !== undefined && (
        <Column sx={{ mt: 4, alignItems: "start" }}>
          {hasExistingTunnels ? (
            <>
              <Row sx={{ alignItems: "sstandard center" }}>
                <NewSelect
                  loading={tunnelsLoading}
                  options={tunnelsOptions}
                  placeholder="Select an existing tunnel..."
                  reload={async () => {
                    await refetchOptions();
                    if (value) {
                      await refetchTest();
                    }
                  }}
                  sx={{ width: "360px" }}
                  value={value}
                  width={360}
                  onChange={onChange}
                />

                {tunnelTestLoading && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Spinner size="sm" />
                    <Text sx={{ ml: 2, fontSize: 0 }}>Testing...</Text>
                  </Row>
                )}

                {!tunnelTestLoading && tunnelTest?.checkTunnel.success && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Circle color="green" radius="8px" />
                    <Text sx={{ ml: 2, fontSize: 0 }}>Active</Text>
                  </Row>
                )}

                {!tunnelTestLoading && tunnelTest?.checkTunnel.success === false && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Circle color="red" radius="8px" />
                    <Text sx={{ ml: 2 }}>
                      Disconnected. Go to <Link href="/settings/tunnels">tunnel settings</Link> to reconnect.
                    </Text>
                  </Row>
                )}
              </Row>

              <Text sx={{ mt: 2, fontSize: 0 }}>
                Alternatively,{" "}
                <Box display="inline-block" onClick={() => setSetupStep("choose")}>
                  <Link href="">create a new tunnel.</Link>
                </Box>
              </Text>
            </>
          ) : (
            <>
              <Button
                sx={{ mt: 2 }}
                variant="primary"
                onClick={() => {
                  setSetupStep("choose");
                }}
              >
                Set up your tunnel
              </Button>
            </>
          )}
        </Column>
      )}
      {setupStep === "choose" && (
        <ChooseTunnelForm
          setTunnelType={setTunnelType}
          tunnelType={tunnelType}
          onClose={() => {
            setSetupStep(undefined);
          }}
          onContinue={() => {
            setSetupStep(tunnelType);
          }}
        />
      )}
      {setupStep === "normal" && <CreateNormalTunnelForm onClose={() => setSetupStep(undefined)} onCreate={onCreate} />}
      {setupStep === "reverse" && <CreateReverseTunnelForm onClose={() => setSetupStep(undefined)} onCreate={onCreate} />}
    </>
  );
};

export const Step = ({
  title,
  sections,
  section,
  children,
  description,
  hasMultipleSetupMethods,
}: {
  sections: Section[];
  section: Section;
  title: string;
  description?: string;
  children: ReactNode;
  hasMultipleSetupMethods: boolean;
}) => {
  const { useHightouchUi } = useFormkitContext();

  const index = sections.indexOf(section);
  if (index === -1) {
    return null;
  }

  if (useHightouchUi) {
    return (
      <Box sx={{ ":not(:last-child)": { borderBottom: "1px", borderColor: "base.border" }, pb: 8 }}>
        {(hasMultipleSetupMethods || sections.length > 1) && (
          <>
            <Box sx={{ span: { color: "gray.500" } }}>
              <HightouchUiText fontWeight="semibold" letterSpacing="wide" size="sm" textTransform="uppercase">
                Step {index + 1 + (hasMultipleSetupMethods ? 1 : 0)}
              </HightouchUiText>
            </Box>

            <SectionHeading>{title}</SectionHeading>
          </>
        )}

        {description && (
          <Paragraph mt={2}>
            <Markdown useHightouchUi>{description}</Markdown>
          </Paragraph>
        )}

        <Box mt={4}>{children}</Box>
      </Box>
    );
  }

  return (
    <>
      <Column sx={{ ":not(:last-child)": { borderBottom: "1px solid", borderColor: "base.border" }, pb: 8 }}>
        <Column sx={{ mb: 4, gap: 2 }}>
          {(hasMultipleSetupMethods || sections.length > 1) && (
            <>
              <Text sx={{ fontWeight: "bold", textTransform: "uppercase", color: "base.5", fontSize: 0 }}>
                Step {index + 1 + Number(hasMultipleSetupMethods)}
              </Text>
              <SectionHeading>{title}</SectionHeading>
            </>
          )}
          {description && (
            <Text sx={{ color: "base.5" }}>
              <Markdown>{description}</Markdown>
            </Text>
          )}
        </Column>
        <Column>{children}</Column>
      </Column>
    </>
  );
};

import { FC, useEffect, useMemo, useState, MouseEventHandler, MouseEvent as ReactMouseEvent } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { Box, Column, IconButton, Row, Tooltip as HightouchUiTooltip, Switch, Text } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { Portal } from "react-portal";
import { Bar, BarChart, Cell, LabelList, Pie, PieChart, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

import { Table, TableColumn } from "src/ui/table";
import { DeprecatedTextWithTooltip, TextWithTooltip } from "src/ui/text";
import { abbreviateNumber } from "src/utils/numbers";

import { Indices } from "../../../../design";

const axisWidth = 120;
const horizontalPadding = 4;

const purpleColor = "#E7ECFE";
const greenColor = "#E1F9FE";
const salmonColor = "#FFE8E3";
const fuschiaColor = "#F6E3FF";
const blueColor = "#E3FAFF";
const yellowColor = "#FEFCE7";
const redColor = "#FEE7E7";
const orangeColor = "#fef3e7";
const tealColor = "#e7fef7";
const grayColor = "#F2F2F2";
const greenerColor = "#CCFFCC";

export const colors = [
  purpleColor,
  greenColor,
  salmonColor,
  fuschiaColor,
  blueColor,
  yellowColor,
  redColor,
  orangeColor,
  tealColor,
  grayColor,
  greenerColor,
];
export const hoverColors = [
  "#728ff9",
  "#6de1fa",
  "#ff856a",
  "#cf6aff",
  "#6ae4ff",
  "#f9ed72",
  "#f97272",
  "#f9b872",
  "#72f9d0",
  "#b5b5b5",
  "#00e500",
];

const getVisibleValue = (value: unknown) => {
  if (value === null) {
    return "null";
  }

  if (value === "") {
    return "<empty string>";
  }

  return value;
};

const valueFormatter = (value: number | string, formatKey: "percentage" | "count"): string => {
  if (formatKey === "percentage" && typeof value === "number") {
    return `${(value * 100).toFixed(1)}%`;
  } else if (typeof value === "number") {
    return abbreviateNumber(value);
  }

  if (value.length > 11) {
    return value.slice(0, 11) + "...";
  }

  return value;
};

type Props = {
  chartType: "bar" | "pie" | "table";
  color?: string;
  data: { value: string; count: number; percentage?: number }[];
  graphName: string;
  graphWidth: number;
  hoverColor?: string;
  isActive?: boolean;
  isAnimationActive?: boolean;
  secondaryAxisLabel?: string;
  onFilterChange?: () => void;
  onMouseEnterCell?: MouseEventHandler<HTMLDivElement>;
  onMouseLeaveCell?: MouseEventHandler<HTMLDivElement>;
  onRemoveGraph: (graphName: string) => void;
};

export const BreakdownGraph: FC<Props> = ({
  chartType,
  color = colors[0],
  data,
  graphName,
  graphWidth: _graphWidth,
  hoverColor = hoverColors[0],
  isActive,
  isAnimationActive,
  secondaryAxisLabel,
  onMouseEnterCell,
  onMouseLeaveCell,
  onRemoveGraph,
}) => {
  const [animate, setAnimate] = useState(true);
  const [activeCell, setActiveCell] = useState<number | null>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [dataFormat, setDataFormat] = useState<"count" | "percentage">("count");
  const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");

  const formattedData = useMemo(() => data.map(({ value, ...rest }) => ({ ...rest, value: getVisibleValue(value) })), [data]);

  const filteredData = formattedData.filter(({ value }) => showAllData || value !== "Other");
  const hasOtherColumn = useMemo(() => formattedData.some(({ value }) => value === "Other"), [data]);

  const graphWidth = _graphWidth - horizontalPadding * 4 * 2; // Remove horizontal padding of <li> elements

  const enterCell = (event: ReactMouseEvent<HTMLDivElement>, cellIndex: number) => {
    setAnimate(false);
    setActiveCell(cellIndex);
    onMouseEnterCell?.(event);
  };

  const leaveCell = (event: ReactMouseEvent<HTMLDivElement>) => {
    setActiveCell(null);
    onMouseEnterCell?.(event);
  };

  const valueAxisName = (
    <>
      {capitalize(graphName)}
      {!showAllData && chartType !== "table" && hasOtherColumn && (
        <Box as={Text} display="inline-block" color="gray.500" ml={1}>
          (top 10)
        </Box>
      )}
    </>
  );

  const secondaryAxisName = (
    <>
      {capitalize(secondaryAxisLabel)}
      <Box as={Text} display="inline-block" color="gray.500" ml={1}>
        {dataFormat === "percentage" ? " (percentage of total)" : ""}
      </Box>
    </>
  );

  const columns: TableColumn[] = [
    {
      name: capitalize(graphName),
      cell: ({ value }) => <TextWithTooltip message={value}>{value}</TextWithTooltip>,
    },
    {
      name: "Count",
      cell: ({ count }) => <TextWithTooltip message={count}>{count}</TextWithTooltip>,
    },
  ];

  const hasFilters = hasOtherColumn || chartType === "bar";
  const showSecondaryAxisName = chartType !== "bar" || layout === "vertical";

  return (
    <Column
      as="li"
      mx={horizontalPadding}
      width={chartType === "table" ? "100%" : graphWidth}
      sx={{
        ":not(:last-child)": { mb: 8 },
      }}
      onMouseLeave={onMouseLeaveCell}
    >
      <Column>
        {chartType !== "bar" && (
          <Row alignItems="center" justifyContent="space-between" width="100%" mb={2}>
            {chartType === "pie" && (
              <DeprecatedTextWithTooltip
                content={<Text whiteSpace="nowrap">{showSecondaryAxisName ? valueAxisName : secondaryAxisName}</Text>}
                sx={{
                  pr: 2,
                  fontWeight: 500,
                }}
              >
                {showSecondaryAxisName ? valueAxisName : secondaryAxisName}
              </DeprecatedTextWithTooltip>
            )}
            <IconButton aria-label="Remove breakdown" icon={XMarkIcon} ml="auto" onClick={() => onRemoveGraph(graphName)} />
          </Row>
        )}
        {chartType === "pie" && (
          <PieChart key={`graphName-${showAllData.toString()}-pie`} height={230} width={graphWidth}>
            <Pie
              animationBegin={0}
              animationDuration={600}
              data={filteredData}
              dataKey="count"
              fill={color}
              isAnimationActive={isAnimationActive || animate}
              nameKey="value"
              onMouseEnter={enterCell}
              onMouseLeave={leaveCell}
            >
              {filteredData.map((_, cellIndex) => (
                <Cell
                  key={`cell-${cellIndex}`}
                  fill={
                    isActive && activeCell === cellIndex
                      ? hoverColors[cellIndex % hoverColors.length]
                      : colors[cellIndex % colors.length]
                  }
                />
              ))}
              <LabelList
                dataKey="value"
                fill="#6d6d6d"
                formatter={(value) => valueFormatter(value, "count")}
                position="outside"
                stroke="transparent"
              />
            </Pie>
            <Tooltip content={<TooltipContent />} isAnimationActive={false} />
          </PieChart>
        )}
        {chartType === "bar" && (
          <>
            <Row alignItems="center" justifyContent="space-between" width="100%" mb={2}>
              <DeprecatedTextWithTooltip
                content={<Text whiteSpace="nowrap">{showSecondaryAxisName ? valueAxisName : secondaryAxisName}</Text>}
                textProps={{
                  pr: 2,
                  fontWeight: "medium",
                  textAlign: "right",
                  width: `${axisWidth}px`,
                }}
              >
                {showSecondaryAxisName ? valueAxisName : secondaryAxisName}
              </DeprecatedTextWithTooltip>
              <IconButton aria-label="Remove breakdown" icon={XMarkIcon} onClick={() => onRemoveGraph(graphName)} />
            </Row>

            <BarChart
              key={`graphName-${showAllData.toString()}-bar`}
              data={filteredData}
              height={230}
              layout={layout}
              margin={{ right: 50 }}
              width={graphWidth}
            >
              {layout === "vertical" && (
                <>
                  <XAxis
                    allowDecimals={dataFormat === "percentage"}
                    dataKey={dataFormat}
                    tickFormatter={(value) => valueFormatter(value, dataFormat)}
                    type="number"
                  />
                  <YAxis
                    dataKey="value"
                    interval={0}
                    label={{
                      textOverflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    offset={100}
                    tickFormatter={(value) => valueFormatter(value, "count")}
                    type="category"
                    width={axisWidth}
                  />
                </>
              )}
              {layout === "horizontal" && (
                <>
                  <XAxis
                    angle={-40}
                    dataKey="value"
                    height={70}
                    interval={0}
                    textAnchor="end"
                    tickFormatter={(value) => valueFormatter(value, "count")}
                  />
                  <YAxis
                    allowDecimals={dataFormat === "percentage"}
                    dataKey={dataFormat}
                    tickFormatter={(value) => valueFormatter(value, dataFormat)}
                    width={axisWidth}
                  />
                </>
              )}
              <Tooltip content={<TooltipContent />} cursor={{ fill: "transparent" }} isAnimationActive={false} />
              <Bar
                dataKey={dataFormat}
                fill={color}
                isAnimationActive={isAnimationActive || animate}
                onMouseEnter={enterCell}
                onMouseLeave={leaveCell}
              >
                {filteredData.map((_, cellIndex) => (
                  <Cell key={`cell-${cellIndex}`} fill={isActive && activeCell === cellIndex ? hoverColor : color} />
                ))}
              </Bar>
            </BarChart>
            <Row ml={`${axisWidth}px`} justifyContent="center" sx={{ span: { textAlign: "center" } }}>
              <Text mb={2} fontWeight="medium" overflowWrap="break-word">
                {layout === "vertical" ? secondaryAxisName : valueAxisName}
              </Text>
            </Row>
          </>
        )}
        {chartType === "table" && (
          <Row mb={8}>
            <Table columns={columns} data={data} />
          </Row>
        )}
      </Column>
      {chartType !== "table" && hasFilters && (
        <Column>
          <Row
            pt={4}
            mt={-2}
            justifyContent="space-between"
            {...(graphWidth < 450
              ? {
                  flexDirection: "column",
                }
              : {})}
          >
            {hasOtherColumn && (
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <HightouchUiTooltip message="Display the number of values that aren't in the top 10">
                  <Text color="text.tertiary" textTransform="uppercase" fontWeight="semibold">
                    Show all data
                  </Text>
                </HightouchUiTooltip>

                <Switch
                  isChecked={showAllData}
                  onChange={(value) => {
                    setAnimate(true);
                    setShowAllData(value);
                  }}
                />
              </Box>
            )}
            {chartType === "bar" && (
              <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <Text color="text.tertiary" textTransform="uppercase" fontWeight="semibold">
                  Switch axes
                </Text>

                <Switch
                  isChecked={layout === "horizontal"}
                  onChange={() => {
                    setAnimate(true);
                    setLayout((prevValue) => (prevValue === "horizontal" ? "vertical" : "horizontal"));
                  }}
                />
              </Box>
            )}
            {chartType === "bar" && (
              <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <Text color="text.tertiary" textTransform="uppercase" fontWeight="semibold">
                  Percentage
                </Text>

                <Switch
                  isChecked={dataFormat === "percentage"}
                  onChange={() => {
                    setAnimate(true);
                    setDataFormat((prevValue) => (prevValue === "count" ? "percentage" : "count"));
                  }}
                />
              </Box>
            )}
          </Row>
        </Column>
      )}
    </Column>
  );
};

const TooltipContent = ({ label, payload }: TooltipProps<number, string>) => {
  const [position, setPosition] = useState<{ x: number | null; y: number | null }>({ x: null, y: null });

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      if (!payload?.[0]) {
        return null;
      }

      return setPosition({
        x: event.pageX,
        y: event.pageY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [payload?.length]);

  if (!payload?.[0]) {
    return null;
  }

  const {
    payload: { count, percentage, value },
  } = payload[0];

  return (
    <Portal>
      <Box
        position="absolute"
        top={`${position.y === null ? 0 : position.y + 20}px`}
        left={`${position.x === null ? 0 : position.x + 20}px`}
        mr={3}
        display={position.x === null ? "none" : "visible"}
        zIndex={Indices.Modal}
        bg="white"
        p={4}
        border="small"
        borderRadius={2}
      >
        <Text fontWeight="medium" mb={1}>
          {label ?? value}
        </Text>
        <Text>
          Count: {count} or {(percentage * 100).toFixed(2)}%
        </Text>
      </Box>
    </Portal>
  );
};

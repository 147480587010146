import { FC } from "react";

import { Column } from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "../mappings-field";

export const validation = Yup.object().shape(
  {
    eventType: Yup.string().required(),
    addEventName: Yup.string().when("eventType", {
      is: "track",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    removeEventName: Yup.string().when("eventType", {
      is: "track",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    anonymousIdFrom: Yup.mixed().when("userIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required("Either anonymous ID or user ID column must be selected"),
    }),
    userIdFrom: Yup.mixed().when("anonymousIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required("Either anonymous ID or user ID column must be selected"),
    }),
    sendInitialEvents: Yup.boolean().notRequired(),
    mappings: COMMON_SCHEMAS.mappings,
  },
  [["anonymousIdFrom", "userIdFrom"]],
);

const EVENTS = [
  { label: "Track", value: "track" },
  { label: "Identify", value: "identify" },
];

export const RudderStackForm: FC = () => {
  const { hightouchColumns, errors, config, setConfig, loadingModel, reloadModel } = useDestinationForm();

  return (
    <>
      <Section>
        <Field error={errors?.eventType} label="Which event type would you like to send?" size="large">
          <Select
            isError={errors?.eventType}
            options={EVENTS}
            placeholder="Select event type..."
            value={EVENTS?.find((o) => o.value === config?.eventType)}
            width="340px"
            onChange={(selected) => {
              const val = selected.value;
              setConfig({ eventType: val });
            }}
          />
        </Field>
      </Section>

      {config?.eventType && (
        <Section>
          <Column gap={8}>
            <Field
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.userIdFrom}
              label="Which column contains the event user ID?"
            >
              <Select
                isClearable
                isError={errors?.userIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.userIdFrom}
                width="340px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>

            <Field
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.anonymousIdFrom}
              label="Which column contains the event anonymous ID?"
            >
              <Select
                isClearable
                isError={errors?.anonymousIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.anonymousIdFrom}
                width="340px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, anonymousIdFrom: val });
                }}
              />
            </Field>
            {config.eventType === "track" && (
              <>
                <Checkbox
                  label="Send events for initial result set?"
                  value={config.sendInitialEvents}
                  onChange={(sendInitialEvents) => {
                    setConfig({ ...config, sendInitialEvents });
                  }}
                />

                <Field error={errors?.addEventName} label="What is the added rows event name?">
                  <Input
                    defaultValue={config?.addEventName}
                    error={errors?.addEventName}
                    placeholder="Enter event name..."
                    sx={{ width: "240px" }}
                    onChange={(value) => {
                      setConfig({
                        ...config,
                        addEventName: value,
                      });
                    }}
                  />
                </Field>

                <Field error={errors?.removeEventName} label="What is the removed rows event name?">
                  <Input
                    defaultValue={config?.removeEventName}
                    error={errors?.removeEventName}
                    placeholder="Enter event name..."
                    sx={{ width: "240px" }}
                    onChange={(value) => {
                      setConfig({
                        ...config,
                        removeEventName: value,
                      });
                    }}
                  />
                </Field>
              </>
            )}
          </Column>
        </Section>
      )}

      {config?.eventType && (
        <Section>
          <MappingsField />
        </Section>
      )}
    </>
  );
};

export default {
  form: RudderStackForm,
  validation,
};

import { FC } from "react";

import { Box, CSSProperties, ThemeUIStyleObject } from "theme-ui";

import {
  SourceDefinitionFragment as SourceDefinition,
  DestinationDefinitionFragment as DestinationDefinition,
} from "src/graphql";
import { Badge } from "src/ui/badge";

export type IntegrationBadgeProps =
  | {
      definition: SourceDefinition;
      isSample?: boolean;
      integrationType: "source";
    }
  | {
      definition: DestinationDefinition;
      isSample?: boolean;
      integrationType: "destination";
    };

export const IntegrationBadge: FC<Readonly<IntegrationBadgeProps>> = ({ definition, integrationType, isSample = false }) => {
  const badgeStyles = integrationType === "source" ? sourceBadgeStyles : destinationBadgeStyles;

  const lastMonth: Date = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  if (definition.status === "released") {
    if (isSample && integrationType === "source") {
      return (
        <Box style={badgeStyles}>
          <Badge
            sx={caretStyles}
            tooltip={`This ${integrationType} is a preconfigured Postgres database containing sample data.`}
            tooltipSx={tooltipStyles}
            variant="orange"
          >
            Sample
          </Badge>
        </Box>
      );
    }
  }

  if (definition.status === "released" && typeof definition.releasedAt === "string" && definition.releasedAt.length > 0) {
    const releasedAt = new Date(definition.releasedAt);

    // we do not show the "new" badge in the source catalog
    if (releasedAt > lastMonth && integrationType === "destination") {
      return (
        <Box style={badgeStyles}>
          <Badge sx={caretStyles} variant="green">
            New
          </Badge>
        </Box>
      );
    }
  }

  if (definition.status === "alpha") {
    return (
      <Box style={badgeStyles}>
        <Badge sx={caretStyles} variant="primary">
          Private preview
        </Badge>
      </Box>
    );
  }

  if (definition.status === "coming_soon") {
    return (
      <Box style={badgeStyles}>
        <Badge sx={caretStyles} variant="primary">
          Coming soon
        </Badge>
      </Box>
    );
  }

  return null;
};

const sourceBadgeStyles: CSSProperties = {
  position: "absolute",
  right: -8,
  top: -8,
};

const destinationBadgeStyles: CSSProperties = {
  position: "relative",
};

const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  px: 3,
};

import { FC } from "react";

import { ThemeUIStyleObject, Text } from "theme-ui";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";

import { ChevronLeftIcon, ChevronRightIcon } from "../icons";

export type SimplePaginationProps = {
  onNext: () => void;
  onPrevious: () => void;
  nextLoading?: boolean;
  previousLoading?: boolean;
  page: number;
  pages?: number;
  sx?: ThemeUIStyleObject;
};

export const SimplePagination: FC<Readonly<SimplePaginationProps>> = ({
  page,
  pages,
  onNext,
  onPrevious,
  nextLoading,
  previousLoading,
  sx = {},
}) => {
  if (!pages || pages <= 1) {
    return null;
  }

  return (
    <Row sx={{ alignSelf: "flex-end", mt: 4, alignItems: "center", ...sx }}>
      <Button
        disabled={page === 0}
        loading={previousLoading}
        size="small"
        sx={{ minWidth: 0, px: 1, color: "base.5" }}
        variant="secondary"
        onClick={onPrevious}
      >
        <ChevronLeftIcon size={18} />
      </Button>

      <Text sx={{ color: "base.5", mx: 2 }}>{pages ? `${page + 1} / ${pages}` : page + 1}</Text>

      <Button
        disabled={page === pages - 1}
        loading={nextLoading}
        size="small"
        sx={{ minWidth: 0, px: 1, color: "base.5" }}
        variant="secondary"
        onClick={onNext}
      >
        <ChevronRightIcon size={18} />
      </Button>
    </Row>
  );
};

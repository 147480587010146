/**
 * Predicate to filter arrays that are typed but may contain null/undefined/false values in order to render conditional items
 * e.g. const numbersOnly: number[] = [1, 2, null, undefined, false].filter(notEmpty)
 */
export function isPresent<TValue>(value: TValue | null | undefined | boolean): value is TValue {
  return value != null && value !== false;
}

export type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

export type Either<T, U> = Only<T, U> | Only<U, T>;

import { formatDistanceStrict, format, parseISO } from "date-fns";

export const diff = (start: string, end: string): string | undefined => {
  if (start && end) {
    return formatDistanceStrict(new Date(end), new Date(start), { roundingMethod: "floor" });
  }

  return undefined;
};

export const formatDatetime = (timestamp: string) => {
  if (timestamp) {
    return format(parseISO(timestamp), "MM/dd/yy 'at' p");
  }
  return null;
};

export const formatDate = (timestamp: string) => {
  if (timestamp) {
    return format(parseISO(timestamp), "MM/dd/yy");
  }
  return null;
};

export const formatDateOrDatetime = (timestamp: string, includeTime: boolean) => {
  return includeTime ? formatDatetime(timestamp) : formatDate(timestamp);
};

import { FC, useMemo, useCallback, ReactNode } from "react";

import { Completion } from "@codemirror/autocomplete";
import { SQLConfig, StandardSQL, schemaCompletionSource } from "@codemirror/lang-sql";
import { LanguageSupport } from "@codemirror/language";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Column, Text, Button, Row, ButtonGroup } from "@hightouchio/ui";
import { format, FormatFnOptions as SqlFormatterOptions } from "sql-formatter";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useSourceObjectDefinitionsQuery, Maybe } from "src/graphql";
import { UnsavedValue } from "src/hooks/use-unsaved-value";

import { Editor, Props as EditorProps } from "../editor";

const formatterLanguageBySourceType: Record<string, SqlFormatterOptions["language"]> = {
  athena: "hive",
  bigquery: "bigquery",
  mssql: "tsql",
  mysql: "mysql",
  postgres: "postgresql",
  redshift: "redshift",
  // What is the best default for these?
  // postgresql seems to be more correct than sql
  // 1 known issue is 'sql' causes || to convert to | | which is invalid
  snowflake: "postgresql",
  trino: "postgresql",
  clickhouse: "postgresql",
  databricks: "postgresql",
  looker: "postgresql",
  metabase: "postgresql",
};

export function formatSourceSql(
  source: {
    id: string;
    type: string;
    name: string;
    definition: { name: string; icon: string; isSampleDataSource: boolean };
  },
  sql: string,
): string {
  const formatterLanguage = source ? formatterLanguageBySourceType[source.type] : undefined;
  return format(sql, {
    keywordCase: "upper",
    language: formatterLanguage,
  });
}

interface Props extends Omit<EditorProps, "language"> {
  /**
   * Line number to highlight with a red background, in case there's an error
   */
  highlightErroredLine?: number;

  /**
   * Source to show database schema autocomplete for
   */
  source:
    | Maybe<{
        id: string;
        type: string;
        name: string;
        definition: { name: string; icon: string; isSampleDataSource: boolean };
      }>
    | undefined;

  /**
   * Result of `useUnsavedValue` hook to support storing drafts in local storage
   */
  unsavedValue?: UnsavedValue<string>;

  /**
   * Additional actions to show in the editor header
   */
  actions?: ReactNode;

  /**
   * Whether the editor should show the beautify button
   */
  isBeautifyable?: boolean;

  /**
   * Width of the editor
   *  - Defaults to 100%
   */
  width?: string;
}

export const SqlEditor: FC<Props> = ({
  highlightErroredLine: highlightErroredLineNumber,
  source,
  value,
  unsavedValue,
  onChange,
  actions,
  isBeautifyable = true,
  width = "100%",
  ...props
}) => {
  const { data } = useSourceObjectDefinitionsQuery(
    { sourceId: source?.id ?? "" },
    {
      enabled: Boolean(source?.id),
    },
  );

  const config = useMemo<SQLConfig | undefined>(() => {
    if (!data) {
      return undefined;
    }

    const schema: Record<string, Completion[]> = {};
    const tables: Completion[] = [];

    for (const objectDefinition of data.object_definitions) {
      const schemaName = objectDefinition.object_definition_group?.name;

      if (schemaName?.toLowerCase() === "hightouch_planner") {
        continue;
      }

      const tableName = schemaName ? `${schemaName}.${objectDefinition.name}` : objectDefinition.name;

      tables.push({
        label: tableName,
        type: "table",
      });

      schema[tableName] = [];

      for (const objectSchema of objectDefinition.object_schemas) {
        schema[tableName]?.push({
          label: objectSchema.key,
          type: "column",
        });
      }
    }

    return { schema, tables };
  }, [data]);

  const language = useMemo(() => {
    if (!config) {
      return new LanguageSupport(StandardSQL.language);
    }

    return new LanguageSupport(StandardSQL.language, [
      StandardSQL.language.data.of({
        autocomplete: schemaCompletionSource({
          ...config,
          defaultSchema: "public",
        }),
      }),
    ]);
  }, [config]);

  const changeValue = useCallback(
    (value: string) => {
      if (unsavedValue) {
        unsavedValue.set(value);
      }

      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [unsavedValue, onChange],
  );

  const restoreValue = useCallback(() => {
    if (!unsavedValue) {
      return;
    }

    if (typeof onChange === "function" && typeof unsavedValue.value === "string") {
      onChange(unsavedValue.value);
    }

    unsavedValue.restore();
  }, [unsavedValue, onChange]);

  const formatterLanguage = source ? formatterLanguageBySourceType[source.type] : undefined;

  const beautify = useCallback(() => {
    changeValue(
      format(value, {
        keywordCase: "upper",
        language: formatterLanguage,
      }),
    );
  }, [value, formatterLanguage, changeValue]);

  return (
    <Column
      width={width}
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      minHeight={props.readOnly ? "250px" : undefined}
      flex={1}
    >
      <Row
        align="center"
        px={4}
        height="64px"
        borderBottom="1px"
        borderColor="base.border"
        gap={4}
        justify="space-between"
        flexShrink={0}
      >
        <Row align="center" gap={2}>
          <IntegrationIcon src={source?.definition.icon} name={source?.definition.name} />
          <Text fontWeight="medium" size="lg">
            {source?.name ?? "Private source"}
          </Text>
        </Row>
        <ButtonGroup>
          {isBeautifyable && formatterLanguage && (
            <Button onClick={beautify} icon={SparklesIcon}>
              Beautify
            </Button>
          )}
          {actions}
        </ButtonGroup>
      </Row>

      {unsavedValue?.value && (
        <Row border="1px" borderColor="base.border" p={2} bg="warning.background" align="center" justify="space-between">
          <Text>You have unsaved changes from last session.</Text>
          <ButtonGroup>
            <Button onClick={unsavedValue.clear}>Dismiss</Button>
            <Button onClick={restoreValue}>Restore</Button>
          </ButtonGroup>
        </Row>
      )}

      <Editor
        minHeight="unset"
        highlightErroredLine={highlightErroredLineNumber}
        language={language}
        value={value}
        onChange={onChange}
        {...props}
      />
    </Column>
  );
};

import { FC, ReactNode } from "react";

import { Link, Column, Row, Text, Spinner } from "@hightouchio/ui";

import { DbtSyncModelQuery, useDbtSyncModelQuery } from "src/graphql";
import { DBTIcon } from "src/ui/icons";
import { Strike } from "src/utils/strike";

type Props = {
  id?: number | null;
  model?: DbtSyncModelQuery["dbt_sync_models_by_pk"];
  oldModel?: DbtSyncModelQuery["dbt_sync_models_by_pk"];
  actions?: ReactNode;
};

export const DbtQuery: FC<Readonly<Props>> = ({ id, model: propsModel, oldModel, actions }) => {
  const { data, isLoading } = useDbtSyncModelQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id), select: (data) => data.dbt_sync_models_by_pk },
  );

  const model = data ?? propsModel;

  const getName = (model) => model?.data?.alias || model?.name;

  return (
    <Column border="1px" borderColor="base.border" borderRadius="md" overflow="hidden" minWidth={0} flex={1}>
      <Row align="center" p={4} borderBottom="1px" borderColor="base.border" gap={4} justify="space-between">
        <Row align="center" gap={2}>
          <DBTIcon />
          <Text fontWeight="medium" size="lg">
            dbt model
          </Text>
        </Row>
        {actions}
      </Row>
      <Column p={4} gap={4} overflow="auto">
        {isLoading ? (
          <Spinner size="lg" m="auto" />
        ) : (
          <>
            <Column>
              <Text fontWeight="medium">Name</Text>
              <Strike _new={getName(model)} old={getName(oldModel)} />
            </Column>
            <Column>
              <Text fontWeight="medium">dbt Unique ID</Text>
              <Strike _new={model?.dbt_unique_id} old={oldModel?.dbt_unique_id} />
            </Column>
            {model?.data?.description && (
              <Column>
                <Text fontWeight="medium">Description</Text>
                <Strike _new={model?.data?.description} old={oldModel?.data?.description} />
              </Column>
            )}
            {model?.dbt_sync_config?.repository && (
              <Column>
                <Text fontWeight="medium">Repository</Text>
                <Link href={model?.dbt_sync_config?.repository}>{model?.dbt_sync_config?.repository}</Link>
              </Column>
            )}
          </>
        )}
      </Column>
    </Column>
  );
};

import { IntervalValue } from "./interval";

export interface TimeRangeValue {
  before: string | IntervalValue;
  after: string | IntervalValue;
}

export function isTimeRangeType(range: unknown): range is TimeRangeValue {
  return (
    typeof range === "object" &&
    range != null &&
    (range as TimeRangeValue).before != null &&
    (range as TimeRangeValue).after != null
  );
}

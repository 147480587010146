import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const TableIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M3 8.667A2.667 2.667 0 0 1 5.667 6h11.666A2.667 2.667 0 0 1 20 8.667v6.666A2.667 2.667 0 0 1 17.333 18H5.667A2.667 2.667 0 0 1 3 15.333V8.667ZM5.667 8A.667.667 0 0 0 5 8.667v.666h13v-.666A.667.667 0 0 0 17.333 8H5.667ZM18 11.333h-5.5v1.334H18v-1.334Zm0 3.334h-5.5V16h4.833a.667.667 0 0 0 .667-.667v-.666ZM10.5 16v-1.333H5v.666c0 .368.298.667.667.667H10.5ZM5 12.667h5.5v-1.334H5v1.334Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

import { FC } from "react";

import { Box, Link } from "@hightouchio/ui";
import { Image, ThemeUIStyleObject } from "theme-ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";
import { SourceQuery, useDiscoverSourceMutation, useSourceObjectDefinitionsQuery } from "src/graphql";
import { Badge } from "src/ui/badge";

export type SourceConfig = Record<string, unknown>;

export type SourceTunnel = NonNullable<NonNullable<SourceQuery["connections_by_pk"]>["tunnel"]>;

export const useObjectDefinitions = (sourceId: string | undefined, enable = true) => {
  const {
    data: objectDefinitionsData,
    isFetching: objectDefinitionsLoading,
    error: objectDefinitionsError,
    refetch: refetchObjectDefinitions,
  } = useSourceObjectDefinitionsQuery({ sourceId: sourceId ?? "" }, { enabled: Boolean(sourceId) && enable });

  const { isLoading: discoverLoading, error: discoverError, mutateAsync: discover } = useDiscoverSourceMutation();

  const objectDefinitions = objectDefinitionsData?.object_definitions;

  const objectDefinitionOptions =
    objectDefinitions?.map(({ name, object_definition_group }) => ({
      label: `${object_definition_group?.name}.${name}`,
      value: `${object_definition_group?.name}.${name}`,
    })) || [];

  const refetch = async () => {
    try {
      sourceId && (await discover({ sourceId }));
      refetchObjectDefinitions();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refetch,
    objectDefinitions,
    objectDefinitionOptions,
    loading: discoverLoading || objectDefinitionsLoading,
    error: (discoverError || objectDefinitionsError)?.message,
  };
};

type SourceTileProps = {
  source?: {
    name: string;
    id: string;
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
  placeholder?: boolean;
};

export const SourceTile: FC<Readonly<SourceTileProps>> = ({ source, placeholder }) => {
  const name = !source && placeholder ? "Private source" : source?.name;

  return (
    <>
      <IntegrationIcon name={source?.definition?.name} src={source?.definition?.icon} />
      <Box
        sx={{ fontWeight: "semi", fontSize: "lg", ml: 2, a: { color: "inherit", fontSize: "inherit", fontWeight: "inherit" } }}
      >
        {source?.id ? <Link href={`/sources/${source?.id}`}>{name}</Link> : name}
      </Box>

      <SourceBadges isSampleDataSource={source?.definition.isSampleDataSource} />
    </>
  );
};

export function SourceIcon({
  placeholder,
  source,
  sx = {},
}: Readonly<{
  placeholder?: boolean;
  source:
    | {
        definition: {
          name: string;
          icon: string;
        };
      }
    | undefined
    | null;
  sx: ThemeUIStyleObject;
}>): JSX.Element | null {
  const alt = placeholder && !source ? "Private source" : source?.definition?.name;
  const src = placeholder && !source ? placeholderSource : source?.definition?.icon;
  return <Image alt={alt} src={src} sx={{ ...sx, objectFit: "contain" }} />;
}

export function SourceBadges({
  isSampleDataSource,
  sx = {},
}: Readonly<{
  isSampleDataSource: boolean | undefined;
  sx?: ThemeUIStyleObject;
}>): JSX.Element | null {
  return isSampleDataSource ? (
    <Badge sx={{ ml: 2, ...sx }} variant="orange">
      Sample
    </Badge>
  ) : null;
}

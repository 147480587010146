import { useState, FC } from "react";

import { Box, Text, Button, Row, Link, useToast, TextInput, Pill, ButtonGroup } from "@hightouchio/ui";
import { useNavigate } from "react-router-dom";
import { Text as LegacyText } from "theme-ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useDeleteSourceMutation, useDestinationDefinitionsQuery, useListAllSourceDependenciesQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Field } from "src/ui/field";
import { ExternalLinkIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";

enum Tab {
  MODELS = "models",
  AUDIENCES = "audiences",
  SYNCS = "syncs",
}

type Props = {
  source: any;
  onClose: any;
};

export const DeleteSourceWarning: FC<Readonly<Props>> = ({ onClose, source }) => {
  const [tab, setTab] = useState<Tab>(Tab.MODELS);
  const [confirmation, setConfirmation] = useState<string>("");
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: sourceDependenciesData, isLoading: loadingDependencies } = useListAllSourceDependenciesQuery({
    ids: [source?.id],
  });

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const { isLoading: deleting, mutateAsync: deleteSource } = useDeleteSourceMutation();

  const { audiences, models, syncs } = sourceDependenciesData?.listAllSourceDependencies.sources[0]?.dependencies || {};

  const syncColumns = [
    {
      name: "Model/Audience",
      cell: ({ modelName }) => {
        return (
          <Row align="center" overflow="hidden" gap={2}>
            <IntegrationIcon name={source?.name} src={source?.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {modelName ?? "Private model"}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "Destination",
      cell: ({ destinationName, destinationType, syncId }) => {
        const destinationDefinition = destinationDefinitions?.find(({ type }) => type === destinationType);

        return (
          <Row align="center" overflow="hidden" gap={2}>
            <IntegrationIcon name={destinationDefinition?.name} src={destinationDefinition?.icon} />
            <Text isTruncated fontWeight="medium">
              {destinationName ?? "Private destination"}
            </Text>
            <Link href={`/syncs/${syncId}`}>
              <ExternalLinkIcon size={20} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const TABS = [
    { value: Tab.MODELS, label: "Models", length: models?.length },
    { value: Tab.AUDIENCES, label: "Audiences", length: audiences?.length },
    { value: Tab.SYNCS, label: "Syncs", length: syncs?.length },
  ];

  const toSingleColumn = (resourceType) => [
    {
      name: "Name",
      cell: (resource) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon name={source.definition?.name} src={source.definition?.icon} />
            <Text isTruncated fontWeight="medium">
              {resource.name}
            </Text>
            <Link href={`/${resourceType}/${resource.id}`}>
              <ExternalLinkIcon size={20} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const singleColumnTable = (resourceType, resourceData) => {
    return (
      <Table
        columns={toSingleColumn(resourceType)}
        data={resourceData}
        loading={loadingDependencies}
        placeholder={placeholderContent(resourceType)}
      />
    );
  };

  const placeholderContent = (resource) => ({
    title: `No ${resource} associated with this source were found`,
    error: `Failed to load ${resource}, please try again.`,
  });

  return (
    <Modal
      bodySx={{ px: 0 }}
      footer={
        <>
          <Field label="Type your source name to confirm deletion">
            <TextInput
              placeholder={source.name}
              value={confirmation}
              onChange={(event) => setConfirmation(event.target.value)}
            />
          </Field>
          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              isDisabled={confirmation !== source.name}
              isLoading={deleting}
              variant="danger"
              onClick={async () => {
                await deleteSource({ id: source?.id });

                analytics.track("Source Deleted", {
                  source_id: source?.id,
                  source_name: source?.name,
                  source_type: source.definition?.name,
                });

                toast({
                  id: "delete-source",
                  title: `Source ${source?.name} was deleted`,
                  variant: "success",
                });

                navigate("/sources");
              }}
            >
              Delete all
            </Button>
          </ButtonGroup>
        </>
      }
      title="Delete this source?"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: "625px",
        maxHeight: "625px",
      }}
      footerSx={{ alignItems: "end", justifyContent: "space-between" }}
      onClose={onClose}
    >
      <Box mb={4} fontSize="md" px={5}>
        This action will permanently delete the <i>{source.name}</i> source and cannot be undone. In order to properly delete
        this source, we will also need to permanently delete all of its associated resources:
      </Box>

      <Tabs
        setTab={(tab) => setTab(tab as Tab)}
        tab={tab}
        tabs={TABS.map(({ value, label, length }) => ({
          render: () => (
            <Box>
              <LegacyText sx={{ display: "inline" }}>{label}</LegacyText>
              <Pill ml={2}>{length}</Pill>
            </Box>
          ),
          value,
        }))}
      />

      {tab === Tab.MODELS && singleColumnTable("models", models)}
      {tab === Tab.AUDIENCES && singleColumnTable("audiences", audiences)}

      {tab === Tab.SYNCS && (
        <Table columns={syncColumns} data={syncs} loading={loadingDependencies} placeholder={placeholderContent("syncs")} />
      )}
    </Modal>
  );
};

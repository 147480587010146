import { ReactElement, useEffect } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, FormField, Select, Spinner, IconButton, Text } from "@hightouchio/ui";
import * as Yup from "yup";

import { useGooglesheetsSourceListSheetsQuery } from "src/graphql";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export function QueryForm({ source, query, onChange, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const {
    data: sheets,
    isLoading: sheetsLoading,
    error: sheetsError,
    refetch: listSheets,
  } = useGooglesheetsSourceListSheetsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    if (sheetsError) {
      setError(sheetsError);
    }
  }, [sheetsError]);

  return (
    <FormField label="Sheet name" error={sheetsError?.message}>
      <Row gap={2}>
        <Select
          isLoading={sheetsLoading}
          options={sheets?.googlesheetsSourceListSheets?.map((name) => ({ label: name ?? "", value: name })) ?? []}
          placeholder="Select a sheet..."
          value={query?.name as any}
          onChange={(name) => onChange({ ...query, name })}
        />
        <IconButton
          aria-label="Refresh sheets"
          icon={ArrowPathIcon}
          isDisabled={sheetsLoading}
          variant="secondary"
          onClick={() => listSheets()}
        />
      </Row>
    </FormField>
  );
}

export function QueryView({ source, query, setError }: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  const {
    data: sheets,
    isLoading: sheetsLoading,
    error: sheetsError,
  } = useGooglesheetsSourceListSheetsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(sheetsError);
  }, [sheetsError]);

  const sheet = sheets?.googlesheetsSourceListSheets?.find((name) => name === query.name);
  const sheetName = sheet || `Unknown sheet ${query.name}`;

  return <FormField label="Sheet">{sheetsLoading ? <Spinner /> : <Text>{sheetName}</Text>}</FormField>;
}

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["sheet"]),
  name: Yup.string().required(),
});

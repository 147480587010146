import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SequencesIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M4.76465 13.0002C4.42001 13.6022 3.77148 14.0078 3.02783 14.0078C1.92347 14.0078 1.02783 13.1123 1.02783 12.0075C1.02783 10.9033 1.92347 10.0078 3.02783 10.0078C3.76531 10.0078 4.40924 10.4067 4.756 11.0002H7.2998C7.64671 10.4067 8.29079 10.0078 9.02783 10.0078C9.76531 10.0078 10.4092 10.4067 10.756 11.0002H13.2998C13.6467 10.4067 14.2908 10.0078 15.0278 10.0078C15.7653 10.0078 16.4092 10.4067 16.756 11.0002H18V13.0002H16.7646C16.42 13.6022 15.7715 14.0078 15.0278 14.0078C14.2846 14.0078 13.6359 13.6022 13.2912 13.0002H10.7646C10.42 13.6022 9.77148 14.0078 9.02783 14.0078C8.28462 14.0078 7.63594 13.6022 7.29116 13.0002H4.76465Z"
        fillOpacity="0.3"
        fillRule="evenodd"
      />
      <path d="M18 17V7L23 12L18 17Z" />
    </Icon>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SearchIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M16.1981 14.608H15.364L15.0684 14.3231C16.3354 12.8458 16.99 10.8303 16.631 8.68826C16.1348 5.75476 13.6852 3.41218 10.7289 3.05341C6.2627 2.50469 2.50393 6.26126 3.05296 10.7248C3.41195 13.6794 5.7559 16.1275 8.69112 16.6235C10.8345 16.9823 12.8511 16.328 14.3293 15.0618L14.6144 15.3572V16.1908L19.1016 20.6755C19.5345 21.1081 20.2419 21.1081 20.6748 20.6755C21.1077 20.2429 21.1077 19.5359 20.6748 19.1032L16.1981 14.608ZM9.8631 14.608C7.23407 14.608 5.11184 12.487 5.11184 9.85955C5.11184 7.23206 7.23407 5.11108 9.8631 5.11108C12.4921 5.11108 14.6144 7.23206 14.6144 9.85955C14.6144 12.487 12.4921 14.608 9.8631 14.608Z" />
    </Icon>
  );
};

import { FC } from "react";

import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import {
  TYPE_LABEL,
  DEFAULT_TYPE_LABEL,
  TYPE_DESCRIPTIONS,
  DEFAULT_TYPE_DESCRIPTIONS,
  TYPE_DESCRIPTION,
  DEFAULT_TYPE_DESCRIPTION,
} from "src/utils/destinations";

type TypeFieldProps = {
  error?: string;
  options: { label: string; value: string }[];
  value?: string;
  onChange?: (value: string) => void;
  path?: string[];
};

export const TypeField: FC<Readonly<TypeFieldProps>> = ({ path, error, options, value, onChange }) => {
  const { config, setConfig, errors, slug } = useDestinationForm();

  const val = value || config?.type;
  const err = error || errors?.type;

  return (
    <Section>
      <Field
        description={TYPE_DESCRIPTION[slug ?? ""] || DEFAULT_TYPE_DESCRIPTION}
        error={err}
        label={TYPE_LABEL[slug ?? ""] || DEFAULT_TYPE_LABEL}
        size="large"
      >
        <RadioGroup
          options={options.map((option) => ({
            ...option,
            description:
              (path
                ? get(TYPE_DESCRIPTIONS?.[slug ?? ""], path)?.[option.value]
                : TYPE_DESCRIPTIONS?.[slug ?? ""]?.[option.value]) || DEFAULT_TYPE_DESCRIPTIONS?.[option.value],
          }))}
          value={val}
          onChange={(type) => {
            if (onChange) {
              onChange(type as string);
            } else {
              setConfig({
                type,
              });
            }
          }}
        />
      </Field>
    </Section>
  );
};

import { FC } from "react";

import { Box, Link } from "@hightouchio/ui";
import { Flex, Paragraph, Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Row } from "src/ui/box";
import { WarningIcon } from "src/ui/icons";

export const OverageContentAlert: FC = () => {
  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.
  const { workspace } = useUser();

  const messageIntercom = () => {
    analytics.track("SS Overage Content Lockout Live Chat Clicked", {
      workspace_id: workspace?.id,
    });
    newIntercomMessage(`Hi, ${overageText.replace(/This/, "my")} I would like to learn more about upgrading.`);
  };

  return (
    <Flex
      sx={{
        borderRadius: 1,
        bg: "reds.0",
        border: "small",
        borderColor: "red",
        position: "relative",
        width: "100%",
        flexDirection: "column",
        mt: 4,
        p: 4,
      }}
    >
      <Row gap={3} sx={{ alignItems: "center" }}>
        <WarningIcon color="red" size={20} />
        <Text sx={{ color: "red", fontWeight: 600 }}>{overageText}</Text>
      </Row>

      <Paragraph sx={{ mt: 2 }}>
        Please <Link href="/settings/billing">click here</Link> to upgrade or{" "}
        <Box display="inline-block" onClick={() => messageIntercom()}>
          <Link href="">speak with</Link>{" "}
        </Box>{" "}
        a Hightouch representative.
      </Paragraph>
    </Flex>
  );
};

import { FC, CSSProperties } from "react";

import styled from "@emotion/styled";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-liquid";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-sqlserver";

export interface EditorProps {
  theme?: string;
  language?: string;
  onChange?: (value: string) => void;
  code: string;
  sx?: CSSProperties;
  minLines?: number;
  maxLines?: number;
  placeholder?: string;
}

const Ace = (props) => {
  return <AceEditor className={props?.className} {...props} />;
};

const StyledAce = styled(Ace)`
  & .ace_text-layer,
  & .ace_gutter-layer,
  & .ace_content,
  & .ace_gutter {
    will-change: initial !important;
  }
  & .ace_scrollbar-v {
    z-index: 0 !important;
  }
  z-index: 0 !important;

  & .ace_cursor {
    display: ${(props) => (props.readOnly ? "none !important" : "block")};
  }
`;

export const Editor: FC<Readonly<EditorProps>> = ({
  theme = "sqlserver",
  language = "sql",
  onChange,
  code,
  sx = {},
  minLines,
  maxLines,
  placeholder,
}) => {
  const readOnly = !onChange;

  return (
    <StyledAce
      debounceChangePeriod={0}
      editorProps={{ $blockScrolling: Infinity }}
      enableBasicAutocompletion={readOnly ? false : true}
      enableLiveAutocompletion={readOnly ? false : true}
      enableSnippets={true}
      highlightActiveLine={false}
      maxLines={maxLines}
      minLines={minLines}
      mode={language}
      placeholder={placeholder}
      readOnly={!onChange}
      setOptions={{ useWorker: false }}
      showGutter={true}
      showPrintMargin={false}
      style={sx}
      theme={theme}
      value={String(code)}
      onChange={onChange}
      onLoad={(editorInstance) => {
        document.addEventListener("mouseup", () => editorInstance.resize());
      }}
    />
  );
};

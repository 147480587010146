import { FC } from "react";

import { lowerFirst } from "lodash";
import moment from "moment";
import { Text } from "theme-ui";
import { ElementOf } from "ts-essentials";

import { Banner } from "src/ui/banner";

import { SyncQuery } from "../../../graphql";
import { WarningIcon } from "../../../ui/icons";

export const ExpiredSyncWarning: FC<{ sync: ElementOf<SyncQuery["syncs"]> }> = ({ sync }) => {
  const endDate = sync?.schedule?.endDate ? new Date(sync.schedule.endDate) : undefined;

  let expiry = sync?.schedule_paused && endDate && endDate.getTime() < Date.now() ? moment(endDate).calendar() : undefined;
  if (expiry && /^(Last|Yesterday|Today) /.test(expiry)) {
    expiry = lowerFirst(expiry);
  }

  return (
    (expiry && (
      <Banner sx={{ py: 3, bg: "forest" }}>
        <WarningIcon color="white" />
        <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1 }}>This sync was scheduled to end {expiry}.</Text>{" "}
        <WarningIcon color="white" />
      </Banner>
    )) ||
    null
  );
};

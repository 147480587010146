import { Spinner } from "@hightouchio/ui";
import { default as LoadingSkeleton } from "react-loading-skeleton";
import { Flex, ThemeUICSSObject } from "theme-ui";
import "react-loading-skeleton/dist/skeleton.css";

export const Skeleton = LoadingSkeleton;

export const PageSpinner = () => (
  <Flex sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
    <Spinner size="lg" />
  </Flex>
);

export const OverlaySpinner = ({ sx = {} }: { sx?: ThemeUICSSObject }) => (
  <Flex
    sx={{
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      minHeight: 0,
      height: "100%",
      width: "100%",
      bg: "rgba(250, 251, 252, .75)",
      ...sx,
    }}
  >
    <Spinner size="lg" />
  </Flex>
);

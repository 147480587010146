import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SettingSolidIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M20.8617 13.176C20.9097 12.792 20.9457 12.408 20.9457 12C20.9457 11.592 20.9097 11.208 20.8617 10.824L23.3937 8.844C23.6217 8.664 23.6817 8.34 23.5377 8.076L21.1377 3.924C20.9937 3.66 20.6697 3.564 20.4057 3.66L17.4177 4.86C16.7937 4.38 16.1217 3.984 15.3897 3.684L14.9337 0.504C14.8977 0.216 14.6457 0 14.3457 0H9.54571C9.24571 0 8.99371 0.216 8.95771 0.504L8.50171 3.684C7.76971 3.984 7.09771 4.392 6.47371 4.86L3.48571 3.66C3.20971 3.552 2.89771 3.66 2.75371 3.924L0.353706 8.076C0.197706 8.34 0.269706 8.664 0.497706 8.844L3.02971 10.824C2.98171 11.208 2.94571 11.604 2.94571 12C2.94571 12.396 2.98171 12.792 3.02971 13.176L0.497706 15.156C0.269706 15.336 0.209706 15.66 0.353706 15.924L2.75371 20.076C2.89771 20.34 3.22171 20.436 3.48571 20.34L6.47371 19.14C7.09771 19.62 7.76971 20.016 8.50171 20.316L8.95771 23.496C8.99371 23.784 9.24571 24 9.54571 24H14.3457C14.6457 24 14.8977 23.784 14.9337 23.496L15.3897 20.316C16.1217 20.016 16.7937 19.608 17.4177 19.14L20.4057 20.34C20.6817 20.448 20.9937 20.34 21.1377 20.076L23.5377 15.924C23.6817 15.66 23.6217 15.336 23.3937 15.156L20.8617 13.176V13.176ZM11.9457 16.2C9.62971 16.2 7.74571 14.316 7.74571 12C7.74571 9.684 9.62971 7.8 11.9457 7.8C14.2617 7.8 16.1457 9.684 16.1457 12C16.1457 14.316 14.2617 16.2 11.9457 16.2Z" />
    </Icon>
  );
};

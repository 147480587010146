import { FC, useMemo, useState } from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";
import { Column, Pill, Row, Text } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";
import { ResourceSelect } from "src/components/resource-select";
import { useParentsQuery, ParentModelsQuery, SegmentsBoolExp } from "src/graphql";
import { TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

export type ParentModel = ParentModelsQuery["segments"][0];

interface Props {
  onSelect: (ParentModel: ParentModel) => void;
}

export const ParentModelSelect: FC<Readonly<Props>> = ({ onSelect }) => {
  const [search, setSearch] = useState("");

  const filters = useMemo(() => {
    const baseFilters: SegmentsBoolExp = {
      is_schema: { _eq: true },
      primary_key: { _is_null: false },
    };

    if (search) {
      baseFilters._and = [{ name: { _ilike: `%${search}%` } }];
    }

    return baseFilters;
  }, [search]);

  const parents = useParentsQuery(
    {
      filters,
    },
    { select: (data) => data.segments },
  );

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: ({ name, connection: source, matchboosting_enabled }) => {
          return (
            <Row align="center" gap={2} overflow="hidden">
              <IntegrationIcon
                name={source?.definition?.name ?? "Private source"}
                src={source?.definition?.icon ?? placeholderSource}
              />

              <Column gap={1}>
                <Text isTruncated fontWeight="medium">
                  {name ?? "Private source"}
                </Text>

                {matchboosting_enabled && (
                  <Pill>
                    <Row gap={2} align="center">
                      <SparklesIcon color="#F5C24D" width={16} />
                      Boosted
                    </Row>
                  </Pill>
                )}
              </Column>
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        breakpoint: "sm",
      },
    ],
    [parents.data],
  );

  return (
    <ResourceSelect
      columns={columns}
      data={parents.data}
      error={Boolean(parents.error)}
      label="parent model"
      loading={parents.isLoading}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};

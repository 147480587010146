import { FC, ReactNode } from "react";

import { Portal } from "react-portal";
import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

import { Indices } from "../../../../design";

export interface Props {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
  fullscreen?: boolean;
  isOpen: boolean;
}

export const Overlay: FC<Readonly<Props>> = ({ children, sx = {}, fullscreen = false, isOpen }) => {
  return (
    <Portal>
      <Row
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          bg: "rgba(30,30,46,.6)",
          backdropFilter: "blur(12px)",
          justifyContent: "center",
          zIndex: Indices.Modal,
          display: isOpen ? "flex" : "none",
          transition: "opacity ease-in-out 0.3s",
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "auto" : "none",
        }}
      ></Row>
      <Row
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: fullscreen && !isOpen ? "100%" : 0,
          left: 0,
          justifyContent: "center",
          alignItems: fullscreen ? "flex-end" : "center",
          zIndex: Indices.Modal,
          display: isOpen ? "flex" : "none",
          transition: fullscreen ? "top ease-in-out 0.3s" : "",
        }}
      >
        <Row
          sx={{
            width: fullscreen ? "100%" : "90%",
            height: fullscreen ? "95%" : "90%",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            ...sx,
          }}
        >
          {children}
        </Row>
      </Row>
    </Portal>
  );
};

import { FC, ReactNode, useState } from "react";

import { Button, Link, Column, Spinner, Row, Text, ButtonGroup } from "@hightouchio/ui";

import { Editor } from "src/components/editor";
import { formatSourceSql } from "src/components/sql-editor";
import { GetSigmaWorkbookDetailQuery, useGetSigmaWorkbookDetailQuery } from "src/graphql";
import { Sigma } from "src/types/models";
import { SigmaIcon } from "src/ui/icons";
import { InfoModal } from "src/ui/modal/info-modal";
import { Strike } from "src/utils/strike";

function getSigmaElementInfo(
  sigma: Sigma,
  workbook?: GetSigmaWorkbookDetailQuery["getSigmaWorkbookDetail"],
): { elementSql: string; elementName: string; pageName: string; workbookName: string } {
  const sigmaQuery = sigma.query;
  const workbookName = workbook?.name ?? "";
  const pages = workbook?.pages || [];

  const page = pages.find((page) => page?.pageId === sigmaQuery.pageId) || { elements: [], name: "" };
  const pageName = page?.name ?? "";
  const elements = page?.elements || [];

  const element = elements.find((element) => element?.elementId === sigmaQuery.elementId) ?? { name: "", sql: "" };
  const elementName = element?.name;
  const elementSql = element?.sql;

  return {
    elementSql,
    elementName,
    pageName,
    workbookName,
  };
}

type Props = {
  oldSigma?: Sigma;
  sigma: Sigma;
  source: {
    id: string;
    name: string;
    type: string;
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
  actions?: ReactNode;
};

export const SigmaQuery: FC<Readonly<Props>> = ({ sigma, oldSigma, source, actions }) => {
  const [showSql, setShowSql] = useState<boolean>(false);
  const sigmaQuery = sigma?.query;
  const oldSigmaQuery = oldSigma?.query;

  const {
    data: workbook,
    isLoading: workbookLoading,
    error: workbookError,
  } = useGetSigmaWorkbookDetailQuery(
    { workbookId: sigmaQuery?.workbookId ?? "" },
    { enabled: Boolean(sigmaQuery?.workbookId), select: (data) => data.getSigmaWorkbookDetail },
  );
  const {
    data: oldWorkbook,
    isLoading: oldWorkbookLoading,
    error: oldWorkbookError,
  } = useGetSigmaWorkbookDetailQuery(
    { workbookId: oldSigmaQuery?.workbookId ?? "" },
    { enabled: Boolean(oldSigmaQuery?.workbookId), select: (data) => data.getSigmaWorkbookDetail },
  );

  if (workbookError || oldWorkbookError) {
    return (
      <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Text color="danger.base">
          Please check that your Sigma credentials are configured correctly in <Link href="/extensions/sigma">settings</Link>.
        </Text>
      </Column>
    );
  }

  const sigmaInfo = getSigmaElementInfo(sigma, workbook);

  let oldSigmaInfo;
  if (oldSigma) {
    oldSigmaInfo = getSigmaElementInfo(oldSigma, oldWorkbook);
  }
  const formattedSql = sigmaInfo?.elementSql ? formatSourceSql(source, sigmaInfo.elementSql) : "";

  const loading = workbookLoading || oldWorkbookLoading;

  return (
    <Column width="100%" border="1px" borderColor="base.border" borderRadius="md" overflow="hidden" minWidth={0}>
      <Row align="center" p={4} borderBottom="1px" borderColor="base.border" gap={4} justify="space-between">
        <Row align="center" gap={2}>
          <SigmaIcon />
          <Text fontWeight="medium" size="lg">
            Sigma workbook
          </Text>
        </Row>
        <ButtonGroup>
          {sigmaInfo?.elementSql && <Button onClick={() => setShowSql(true)}>View SQL</Button>}
          {actions}
        </ButtonGroup>
      </Row>

      <Column p={4} gap={4} overflow="auto">
        {loading ? (
          <Spinner size="lg" m="auto" />
        ) : (
          <>
            <Column>
              <Text fontWeight="medium">Workbook</Text>
              <Strike _new={sigmaInfo?.workbookName} old={oldSigmaInfo?.workbookName} />
            </Column>
            <Column>
              <Text fontWeight="medium">Page</Text>
              <Strike _new={sigmaInfo?.pageName} old={oldSigmaInfo?.pageName} />
            </Column>
            <Column>
              <Text fontWeight="medium">Element</Text>
              <Strike _new={sigmaInfo?.elementName} old={oldSigmaInfo?.elementName} />
            </Column>
          </>
        )}
      </Column>

      <InfoModal height="90%" isOpen={showSql} title="SQL" p={0} width="800px" onClose={() => setShowSql(false)}>
        <Editor readOnly language="sql" value={formattedSql} />
      </InfoModal>
    </Column>
  );
};

import { FC } from "react";

import { Text } from "@hightouchio/ui";

import { SelectComponentProps } from "../types";
import { Container } from "./container";

export const Value: FC<SelectComponentProps> = (props) => {
  return (
    <Container {...props}>
      {props.selectedOption?.render ? props.selectedOption.render({}) : <Text isTruncated>{props.selectedOption?.label}</Text>}
    </Container>
  );
};

import { FC, useEffect } from "react";

import { Box, Paragraph, Text as HightouchUiText, SkeletonBox, Skeleton } from "@hightouchio/ui";
import _ from "lodash";
import { DollarSignIcon } from "lucide-react";
import { Text, Grid, Container } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Editor } from "src/ui/editor";
import { VariableIcon } from "src/ui/icons";
import { flattenOptions } from "src/ui/select/select";

import { useFormkitContext } from "./formkit-context";
import { extractEligibleInlineMapperColumns, isValidJsonColumnMetadata } from "./mappings";
import { FormProps, JsonColumnProps } from "./types";

const rowSx = {
  minHeight: "36px",
  alignItems: "center",
  fontSize: 0,
  fontWeight: "semi",
  px: 1,
  borderBottom: "small",
  pointerEvents: undefined,
  ":hover": { bg: "base.0" },
};

export const TemplateInput: FC<Readonly<FormProps & { jsonColumnProperties: JsonColumnProps }>> = ({
  value,
  onChange,
  templates,
  overwriteColumnsWithArrayProps,
  columnOptions,
  jsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  useHightouchUi,
}) => {
  const formkitColumns = useFormkitContext().columns;
  const columns = overwriteColumnsWithArrayProps ? columnOptions ?? [] : formkitColumns;
  const { rows, reloadRows, loadingRows } = useFormkitContext();

  /**
   * These two useEffects work together to handle two functionalities:
   * 1. On load, we want to check if the json column metadata is valid. We will reloadRows to validate the metadata if it is not.
   * 2. handleReloadRows, which calls onReloadEligibleInlineMapperColumns will load the metadata to state only if it is valid. Therefore, it needs to have 'rows' as a dependency
   *    because if rows is updated, it can mean that either 1. we loaded the new valid metadata after we found that the previous one is invalid. 2. The user clicked on the 'Reload button' to re-validate the metadata and we need to extract that new data and set it to state.
   * This approach is needed to avoid multiple re-renders. The ideal approach would've been to make `reloadRows` a promise that resolves with the row data and we preform the extraction afterwards. However, that was not how reloadRows worked at this time, so this approach was chosen for the sake of time.
   * If this should be revisited, reloadRows should be changed into a promise so it can handle this scenario without multiple useEffects.
   */

  const handleReloadRows = async () => {
    await onReloadEligibleInlineMapperColumns(value.from);
  };

  useEffect(() => {
    if (!overwriteColumnsWithArrayProps) return;

    const jsonColumns = extractEligibleInlineMapperColumns(formkitColumns);
    if (!isValidJsonColumnMetadata(jsonColumns)) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    handleReloadRows();
  }, [rows, jsonColumnProperties.selectedColumnProps, jsonColumnProperties.allColumnsProps]);

  // TODO: Properly support merged columns
  const normalizedColumns = _.uniqBy(
    flattenOptions(columns).map((option) => ({ label: option.label, value: option.label })),
    "value",
  );

  const contexts = [
    // XXX these are defined provided as context in
    // `/packages/backend/sdk/lib/liquid-template/liquid.ts`
    { label: "Model name", value: "context['model_name']" },
    { label: "Model ID", value: "context['model_id']" },
    { label: "Sync ID", value: "context['sync_id']" },
    { label: "Sync Run ID", value: "context['sync_run_id']" },
    { label: "Timestamp", value: "context['timestamp']" },
  ];

  const sortedColumns = _.orderBy(normalizedColumns, "label");
  const columnsWithContext = [...sortedColumns, ...contexts];
  const unique = _.uniq(columnsWithContext);
  const sortedTemplates = _.orderBy(templates, "name");

  const insertVar = (val: string) => {
    let template = value.template;
    const isContext = contexts.find(({ value }) => val === value);
    if (!template || template.length === 0) {
      template = isContext ? `{{ ${val} }}` : `{{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    // if template already has closing braces, insert variable on its own
    template = template.trimRight();
    if (template.endsWith("}}")) {
      template = isContext ? `${template} {{ ${val} }}` : `${template} {{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template ends with row, append field
    if (template.endsWith("row")) {
      template = isContext ? `${template.substring(0, template.length - 3)}${val} }}` : `${template}['${val}'] }}`;
    } else if (template.endsWith("row.")) {
      template = isContext
        ? `${template.substring(0, template.length - 4)}${val} }}`
        : template.substring(0, template.length - 1);
      template = `${template}['${val}'] }}`;
    } else if (template.endsWith("{{")) {
      template = isContext ? `${template} ${val} }}` : `${template} row['${val}'] }}`;
    } else {
      //add closing braces and append field
      template = isContext ? `${template} }} {{ ${val} }}` : `${template} }} {{ row['${val}'] }}`;
    }
    onChange({ ...value, template: template });
  };

  const insertFunction = (val: string, placeholders?: string[]) => {
    let template = value.template;
    //function with placeholders i.e. includes: 'substring'
    const insertVal = placeholders ? `${val} : ${placeholders.join(", ")}` : `${val}`;

    //if template editor is empty fill with placeholder variable and append function
    if (!template || template.length === 0) {
      template = `{{ row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }
    template = template.trimRight();

    //if template ends with opening brackets, add placeholder var and append function
    if (template.endsWith("{{")) {
      template = `${template} row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template is closed, pipe function at end
    if (template.endsWith("}}")) template = template.substring(0, template.length - 2);
    template = template.trimRight();
    if (!template.endsWith("|")) {
      template = `${template} |`;
    }
    template = `${template} ${insertVal} }}`;
    onChange({ ...value, template: template });
  };

  if (useHightouchUi) {
    return (
      <Box display="flex" flex="1" flexDirection="column" gap={3} minWidth={0} p={3}>
        <Box borderColor="gray.300" borderWidth="1px" flex="none" height="120px">
          <Editor
            code={value.template || ""}
            language="liquid"
            sx={{ height: "100%" }}
            onChange={(val) => onChange({ ...value, template: val })}
          />
        </Box>

        <Box display="flex" flex="1" gap={2} minHeight={0}>
          <Box display="flex" flex="none" flexDirection="column" width="140px">
            <Box borderBottomWidth="2px" borderColor="gray.300" flex="none">
              <HightouchUiText>Variables</HightouchUiText>
            </Box>

            <Box display="flex" flex="1" flexDirection="column" minHeight={0} overflowY="auto" overscrollBehaviorY="contain">
              {overwriteColumnsWithArrayProps && loadingRows ? (
                <ShimmerLoadingState />
              ) : (
                unique.map(({ label, value }) => (
                  <Box
                    key={label}
                    _hover={{
                      bg: "gray.100",
                    }}
                    alignItems="center"
                    borderBottomWidth="1px"
                    borderColor="gray.300"
                    cursor="pointer"
                    display="flex"
                    flex="none"
                    gap={1}
                    height={8}
                    px={1}
                    onClick={() => {
                      insertVar(value);
                    }}
                  >
                    <Box as={DollarSignIcon} boxSize={3} />
                    <HightouchUiText isTruncated size="sm">
                      {label}
                    </HightouchUiText>
                  </Box>
                ))
              )}
            </Box>
          </Box>

          <Box display="flex" flex="1" flexDirection="column" minWidth={0}>
            <Box borderBottomWidth="2px" borderColor="gray.300" flex="none">
              <HightouchUiText>Functions</HightouchUiText>
            </Box>

            <Box display="flex" flex="1" flexDirection="column" minHeight={0} overflowY="auto" overscrollBehaviorY="contain">
              {sortedTemplates.map(({ name, placeholders, description }) => (
                <Box
                  key={name}
                  _hover={{
                    bg: "gray.100",
                  }}
                  borderBottomWidth="1px"
                  borderColor="gray.300"
                  cursor="pointer"
                  display="flex"
                  flex="none"
                  p={1}
                  onClick={() => {
                    insertFunction(name, placeholders);
                  }}
                >
                  <Box flex="none" width="120px">
                    <HightouchUiText isTruncated size="sm">
                      {name}
                    </HightouchUiText>
                  </Box>

                  <Box flex="1" minWidth={0}>
                    <Paragraph size="sm">{description}</Paragraph>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid gap={2} sx={{ flex: "1 1 0", width: "100%", overflow: "hidden", p: 4 }}>
      <Row sx={{ padding: "3px", border: "1px solid #E9ECF5" }}>
        <Editor
          code={value.template || ""}
          language="liquid"
          sx={{ height: "120px" }}
          onChange={(val) => onChange({ ...value, template: val })}
        />
      </Row>
      <Row sx={{ flex: 1, overflow: "hidden" }}>
        <Column sx={{ mr: 2, width: "140px" }}>
          <Row
            sx={{
              borderBottom: "medium",
              alignItems: "center",
              fontWeight: "semi",
              fontSize: 1,
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Variables</Text>
          </Row>
          {overwriteColumnsWithArrayProps && loadingRows ? (
            <ShimmerLoadingState />
          ) : (
            <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
              {unique.map(({ label, value }) => {
                return (
                  <Row
                    key={`template-${label}`}
                    sx={{
                      ...rowSx,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      insertVar(value);
                    }}
                  >
                    <VariableIcon color="base.5" size={18}></VariableIcon>
                    <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{label}</Text>
                  </Row>
                );
              })}
            </Column>
          )}
        </Column>
        <Column>
          <Row
            sx={{
              fontWeight: "semi",
              borderBottom: "medium",
              cursor: "default",
              fontSize: 1,
              alignItems: "center",
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Functions</Text>
          </Row>
          <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
            {sortedTemplates.map((template) => {
              return (
                <Row
                  key={template.name}
                  sx={{
                    minHeight: "36px",
                    alignItems: "center",
                    fontSize: 0,
                    cursor: "pointer",
                    p: 1,
                    borderBottom: "small",
                    pointerEvents: undefined,
                    ":hover": { bg: "base.0" },
                  }}
                  onClick={() => {
                    insertFunction(template.name, template.placeholders);
                  }}
                >
                  <Text
                    sx={{
                      fontWeight: "semi",
                      pr: 2,
                      flex: 1,
                      minWidth: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {template.name}
                  </Text>
                  <Text sx={{ color: "base.5", flex: 3, pl: 2 }}>{template.description}</Text>
                </Row>
              );
            })}
          </Column>
        </Column>
      </Row>
    </Grid>
  );
};

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {Array(4).map((index) => (
      <Row key={index} sx={rowSx}>
        <Container sx={{ width: "100%", margin: 0 }}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Container>
      </Row>
    ))}
  </Skeleton>
);

import { FC, useState } from "react";

import { Box, BoxProps, Row, Text } from "@hightouchio/ui";
import {
  set,
  eachWeekOfInterval,
  getYear,
  getMonth,
  getDate,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  addMonths,
  subMonths,
  isSameDay,
} from "date-fns";
import { Grid } from "theme-ui";

import { Button } from "src/ui/button";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "src/ui/icons";
import { NewSelect } from "src/ui/new-select";

import { WEEKDAYS, MONTHS, MIN_YEAR, MAX_YEAR, MONTH_OPTIONS, YEAR_OPTIONS } from "./constants";

export type Props = {
  value: Date;
  onChange: (value: Date) => void;
};

export const Calendar: FC<Readonly<Props>> = ({ value, onChange }) => {
  const [current, setCurrent] = useState<Date>(value);
  const monthNumber = getMonth(current);
  const yearNumber = getYear(current);

  const nextMonth = () => {
    setCurrent(addMonths(current, 1));
  };

  const previousMonth = () => {
    setCurrent(subMonths(current, 1));
  };

  const start = startOfMonth(current);
  const end = endOfMonth(current);

  const weekStarts = eachWeekOfInterval({ start, end });

  const days = weekStarts
    .map((weekStart) => eachDayOfInterval({ start: startOfWeek(weekStart), end: endOfWeek(weekStart) }))
    .flat();

  return (
    <Box sx={{ width: "min-content" }}>
      <Row sx={{ alignItems: "center", mb: 2 }}>
        <Button disabled={yearNumber === MIN_YEAR && monthNumber === 0} variant="plain" onClick={previousMonth}>
          <ChevronLeftIcon />
        </Button>

        <Row sx={{ fontWeight: "bold", textAlign: "center", justifyContent: "center", flex: 1 }}>
          <NewSelect
            autoHide={false}
            components={{
              Value: () => (
                <Row sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Text>{MONTHS[monthNumber]}</Text>

                  <ChevronDownIcon size={16} sx={{ ml: 1 }} />
                </Row>
              ),
            }}
            options={MONTH_OPTIONS}
            sx={{ flex: "0 1 auto" }}
            value={monthNumber}
            width={120}
            onChange={(value) => setCurrent(set(current, { month: value }))}
          />
          <NewSelect
            autoHide={false}
            components={{
              Value: () => (
                <Row sx={{ alignItems: "center" }}>
                  <Text>{yearNumber}</Text>
                  <ChevronDownIcon size={16} sx={{ ml: 1 }} />
                </Row>
              ),
            }}
            options={YEAR_OPTIONS}
            sx={{ flex: "0 1 auto", ml: 2 }}
            value={yearNumber}
            width={80}
            onChange={(value) => setCurrent(set(current, { year: value }))}
          />
        </Row>

        <Button disabled={yearNumber === MAX_YEAR && monthNumber === 11} variant="plain" onClick={nextMonth}>
          <ChevronRightIcon />
        </Button>
      </Row>

      <Grid columns={7} gap={2} sx={{ justifyItems: "center" }}>
        {WEEKDAYS.map((day) => (
          <Text key={day} fontWeight="semibold">
            {day}
          </Text>
        ))}
        {days.map((date, i) => (
          <Cell key={i} current={current} date={date} value={value} onChange={onChange} />
        ))}
      </Grid>
    </Box>
  );
};

export const Cell: FC<Readonly<{ date: Date; current: Date; onChange: (value: Date) => void; value: Date }>> = ({
  current,
  date,
  onChange,
  value,
}) => {
  const currentMonth = getMonth(date) === getMonth(current);
  const selected = isSameDay(value, date);

  const styles: BoxProps["sx"] = currentMonth
    ? {
        cursor: "pointer",
        color: "black",
        ":hover": { bg: "primary.background" },
      }
    : {
        color: "primary",
      };

  if (selected) {
    styles.bg = "primary.pressed";
    styles.color = "white";
    styles.pointerEvents = "none";
    styles.cursor = "auto";
  }

  const props = currentMonth
    ? {
        onClick: () => onChange(set(current, { date: getDate(date) })),
      }
    : {};

  return (
    <Row
      {...props}
      userSelect="none"
      borderRadius="3px"
      flexShrink={0}
      width="24px"
      height="24px"
      alignItems="center"
      justifyContent="center"
      sx={styles}
    >
      {getDate(date)}
    </Row>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const MessageIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04988C4.26988 21.0002 1.99988 18.7502 1.99988 15.9602V15.9502C1.99988 15.9502 2.00588 11.5242 2.01388 9.29824C2.01488 8.88024 2.49488 8.64624 2.82188 8.90624C5.19788 10.7912 9.44688 14.2282 9.49988 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.97724C21.5069 8.71624 21.9889 8.95024 21.9899 9.36724C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z"
        opacity="0.4"
      />
      <path d="M21.476 5.67351C20.61 4.04151 18.906 2.99951 17.03 2.99951H7.05001C5.17401 2.99951 3.47001 4.04151 2.60401 5.67351C2.41001 6.03851 2.50201 6.49351 2.82501 6.75151L10.25 12.6905C10.77 13.1105 11.4 13.3195 12.03 13.3195C12.034 13.3195 12.037 13.3195 12.04 13.3195C12.043 13.3195 12.047 13.3195 12.05 13.3195C12.68 13.3195 13.31 13.1105 13.83 12.6905L21.255 6.75151C21.578 6.49351 21.67 6.03851 21.476 5.67351Z" />
    </Icon>
  );
};

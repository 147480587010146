import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";

export const Form: FC<{ children: ReactNode; compact?: boolean; disableBorder?: boolean }> = ({
  children,
  compact = false,
  disableBorder = false,
}) => {
  return (
    <Column
      gap={compact ? 3 : 12}
      sx={{
        "& > div:not(:last-of-type)": {
          pb: compact ? 3 : 12,
          borderBottom: disableBorder ? undefined : "1px solid",
          borderColor: "base.border",
        },
        width: "100%",
      }}
    >
      {children}
    </Column>
  );
};

import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

/**
 * This hook creates a `string` state, where value is saved to the URL in the
 * query parameter named via `parameter` option. On page load, initial value
 * is restored from the URL as well.
 *
 * It's useful for things like search input, where we want to store the search
 * query in the `?search=abc` part of the URL, so that last state of the page
 * is rendered when user goes back to that page.
 */
const useQueryState = (parameter: string): [string | null, (value: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParam = useCallback(
    (value: string) => {
      if (value === "") {
        searchParams.delete(parameter);
        setSearchParams(searchParams);
        return;
      }
      searchParams.set(parameter, value);
      setSearchParams(searchParams);
    },
    [searchParams, parameter],
  );

  return [searchParams.get(parameter), setParam];
};

export default useQueryState;

import { FC, useState } from "react";

import { ArrowsRightLeftIcon, ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Heading, Checkbox, Button, Text, Row, Box, Column, Link, CheckboxGroup, Paragraph } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { Navigate, useParams, useNavigate } from "react-router-dom";

import { AuthLayout } from "src/components/auth/auth-layout";
import {
  useAvailableWorkspacesQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  usePartnerConnectGetConnectionQuery,
  useWorkspacesQuery,
} from "src/graphql";
import { Logo } from "src/ui/brand";
import { Card } from "src/ui/card";
import { PageSpinner } from "src/ui/loading";
import { switchWorkspace } from "src/utils/workspaces";

const PartnerConnect: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();
  const uuid = params.uuid ?? "";

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedSharing, setHasAcceptedSharing] = useState(false);

  const partnerConnection = usePartnerConnectGetConnectionQuery(
    { uuid },
    {
      select: (data) => data.partnerConnectGetConnection,
      enabled: Boolean(uuid),
      cacheTime: 0,
    },
  );
  const workspaces = useWorkspacesQuery(undefined, {
    select: (data) => data.workspaces,
    cacheTime: 0,
  });
  const joinableWorkspaces = useAvailableWorkspacesQuery(undefined, {
    select: (data) => data.getAvailableWorkspaces.joinable,
    cacheTime: 0,
  });
  const { mutateAsync: joinWithAutojoin, isLoading: joiningWorkspace } = useJoinWorkspaceWithAutoJoinMutation();

  if (partnerConnection.isLoading || workspaces.isLoading || joinableWorkspaces.isLoading) {
    return <PageSpinner />;
  }

  const linkedWorkspace = workspaces.data?.find((w) => w?.id === partnerConnection.data?.workspaceId);
  const joinableLinkedWorkspace = joinableWorkspaces.data?.find(({ id }) => id === partnerConnection.data?.workspaceId);

  if (partnerConnection.error) {
    return <Navigate state={{ error: partnerConnection.error?.message }} to="/unauthorized" />;
  }

  if (partnerConnection.data?.workspaceId) {
    if (!linkedWorkspace && !joinableLinkedWorkspace) {
      return (
        <Navigate
          state={{
            partnerConnection,
            error: `You are not authorized to access the workspace ${partnerConnection.data?.partnerName} is connected to.`,
          }}
          to="/unauthorized"
        />
      );
    }

    return (
      <Card sx={{ mx: "auto", mt: 24, maxWidth: "540px", width: "100%" }}>
        <Column align="center" gap={12}>
          <PartnerConnectLogo logo={partnerConnection.data?.partnerLogo} name={partnerConnection.data?.partnerName} />
          <Heading size="2xl">{partnerConnection.data?.partnerName} connected!</Heading>

          <Paragraph textAlign="center">
            Your Hightouch workspace <strong>"{(linkedWorkspace || joinableLinkedWorkspace)?.name}"</strong> is connected to{" "}
            {partnerConnection.data?.partnerName}.
          </Paragraph>

          <Button
            isLoading={joiningWorkspace}
            size="lg"
            variant="primary"
            onClick={async () => {
              if (joinableLinkedWorkspace) {
                await joinWithAutojoin({ workspaceId: String(joinableLinkedWorkspace.id) });
              }
              if (partnerConnection.data?.workspaceId) {
                await switchWorkspace(
                  partnerConnection.data?.workspaceId,
                  `/${(linkedWorkspace || joinableLinkedWorkspace)?.slug}`,
                );
              }
            }}
          >
            Go to workspace
          </Button>
        </Column>
      </Card>
    );
  }

  if (!partnerConnection.data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Connect Hightouch to {partnerConnection.data?.partnerName}</title>
      </Helmet>

      <AuthLayout heading={`Connect Hightouch to ${partnerConnection.data?.partnerName}`}>
        <Card>
          <Column align="center" gap={12}>
            <PartnerConnectLogo logo={partnerConnection.data?.partnerLogo} name={partnerConnection.data?.partnerName} />

            <CheckboxGroup>
              <Checkbox
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                description={
                  <>
                    I agree to Hightouch's{" "}
                    <Link href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service/`} rel="noreferrer" target="_blank">
                      terms of service
                    </Link>{" "}
                    related to its usage in conjunction with {partnerConnection.data?.partnerName}.
                  </>
                }
                isChecked={hasAcceptedTerms}
                label="Terms of service"
                onChange={(event) => setHasAcceptedTerms(event.target.checked)}
              />
              <Checkbox
                description={`I acknowledge that Hightouch will share data on its usage with ${partnerConnection.data?.partnerName}`}
                isChecked={hasAcceptedSharing}
                label="Usage sharing"
                onChange={(event) => setHasAcceptedSharing(event.target.checked)}
              />
            </CheckboxGroup>

            <Button
              directionIcon={ArrowRightIcon}
              isDisabled={!(hasAcceptedSharing && hasAcceptedTerms)}
              size="lg"
              variant="primary"
              onClick={() => {
                const workspaceCount = workspaces.data?.length ?? 0;
                navigate(workspaceCount > 0 ? "/workspaces" : "/workspaces/new", {
                  state: { partnerConnection: partnerConnection.data },
                });
              }}
            >
              Continue
            </Button>
          </Column>
        </Card>
      </AuthLayout>
    </>
  );
};

export default PartnerConnect;

export const PartnerConnectLogo: FC<Readonly<{ name: string; logo: string }>> = ({ name, logo }) => (
  <Row align="center" gap={4} mx="auto">
    <Logo size="48px" />
    <Box color="gray.500">
      <ArrowsRightLeftIcon fill="currentColor" width="36px" />
    </Box>
    <Box as="img" alt={name} src={logo} sx={{ width: "48px", objectFit: "contain" }} />
  </Row>
);

export const PartnerConnectHeader: FC<Readonly<{ partnerInformation: any }>> = ({ partnerInformation }) => {
  return (
    <Column align="center" color="gray.700" gap={8} mb={8}>
      <PartnerConnectLogo logo={partnerInformation.partnerLogo} name={partnerInformation.partnerName} />

      <Column gap={2}>
        {partnerInformation.resources.map((resource) => (
          <Row key={resource} align="center" gap={2}>
            <Box color="forest.600">
              <CheckCircleIcon fill="currentColor" width="24px" />
            </Box>
            <Text fontWeight="medium">Connect your {resource.split(" ").slice(1).join(" ")}</Text>
          </Row>
        ))}
      </Column>
    </Column>
  );
};

import { FC, ReactNode, useEffect } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { BoxProps, Column, IconButton, Row, Heading } from "@hightouchio/ui";

import { Overlay } from "src/ui/overlay";

type Props = {
  title?: string;
  isOpen?: boolean;
  children: ReactNode;
  p?: BoxProps["p"];
  width?: BoxProps["width"];
  height?: BoxProps["height"];
  onClose: () => void;
};

export const InfoModal: FC<Readonly<Props>> = ({ isOpen = true, title, children, onClose, width, height, p = 6 }) => {
  useEffect(() => {
    if (isOpen && document) {
      document.body.style.overflowY = "hidden";
    }

    return () => {
      if (document) {
        document.body.style.overflowY = "";
      }
    };
  }, [isOpen]);

  return (
    <Overlay fullscreen isOpen={isOpen}>
      <Column
        bg="white"
        borderRadius="lg"
        height={height}
        maxHeight="90%"
        maxWidth={width}
        overflow="hidden"
        width={width ? "100%" : "max-content"}
      >
        <Row
          align="center"
          borderBottom="1px solid"
          borderColor="gray.300"
          flexShrink={0}
          height="64px"
          justify="space-between"
          pl={6}
          pr={3}
        >
          <Heading isTruncated>{title}</Heading>
          <IconButton aria-label="Close modal" icon={XMarkIcon} size="lg" onClick={onClose} />
        </Row>
        <Column height="calc(100% - 64px)" overflowY="auto" p={p}>
          {children}
        </Column>
      </Column>
    </Overlay>
  );
};

import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Circle } from "src/ui/circle";

type DraftCircleProps = { sx?: ThemeUIStyleObject };

export const DraftCircle: FC<DraftCircleProps> = ({ sx = {} }) => {
  return <Circle color="yellow" radius="8px" sx={sx} />;
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChevronLeftIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M15.707 6.293a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 0 1.414l5 5a1 1 0 0 0 1.414-1.414L11.414 12l4.293-4.293a1 1 0 0 0 0-1.414Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ArrowUpIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.1,6.4l-6-6c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.5-0.3C12.4,0.1,12.3,0,12.1,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.5,0.3l-6,6C4.3,7,4.3,8,4.9,8.6c0.6,0.6,1.6,0.6,2.2,0l3.4-3.4v17.3
	c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V5.2l3.4,3.4C17.2,8.9,17.6,9,18,9c0.4,0,0.8-0.1,1.1-0.4C19.7,8,19.7,7,19.1,6.4z"
      />
    </Icon>
  );
};

import { FC, useEffect, useState } from "react";

import { ChatBubbleLeftRightIcon, CheckCircleIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
import { Text, Row, Column, Box, Button, Heading, ButtonGroup } from "@hightouchio/ui";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";

import customerSuccessAvatars from "src/assets/customer-success-avatars.png";
import { Page } from "src/components/layout";
import placeholder from "src/components/onboarding/placeholder.svg";
import { DestinationCatalog } from "src/components/welcome/destination-catalog";
import { SourceList } from "src/components/welcome/source-catalog";
import syncArrowIcon from "src/components/welcome/sync-arrow.svg";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinition,
  DestinationQuery,
  SourceDefinition,
  SourceQuery,
  useDestinationDefinitionsQuery,
  useDestinationQuery,
  useSourceDefinitionsQuery,
  useSourceQuery,
  useUpdateWorkspaceMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { CreateDestinationWizard } from "src/pages/destinations/create-destination-wizard";
import { CreateSourceWizard } from "src/pages/sources/create-source-wizard";
import { CreateOnboardingSyncWizard } from "src/pages/syncs/create/create-onboarding-sync-wizard";
import { Card } from "src/ui/card";
import { PageSpinner } from "src/ui/loading";
import { InfoModal } from "src/ui/modal/info-modal";
import * as storage from "src/utils/storage";

export const Setup: FC = () => {
  const { onboarding, workspace } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const updateWorkspace = useUpdateWorkspaceMutation();
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [destinationDefinition, setDestinationDefinition] = useState<DestinationDefinition>();
  const [source, setSource] = useState<SourceQuery["connections_by_pk"]>();
  const [destination, setDestination] = useState<DestinationQuery["destinations_by_pk"]>();

  const sourceQuery = useSourceQuery(
    { id: workspace?.onboarding?.sourceId },
    {
      enabled: Boolean(workspace?.onboarding?.sourceId),
      select: (data) => data.connections_by_pk,
    },
  );
  useEffect(() => {
    setSource(sourceQuery.data);
  }, [sourceQuery.data]);

  const destinationQuery = useDestinationQuery(
    { id: workspace?.onboarding?.destinationId },
    {
      enabled: Boolean(workspace?.onboarding?.destinationId),
      select: (data) => data.destinations_by_pk,
    },
  );
  useEffect(() => {
    setDestination(destinationQuery.data);
  }, [destinationQuery.data]);

  const sourceDefinitionsQuery = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
  });
  useEffect(() => {
    if (sourceDefinitionsQuery.data && workspace?.onboarding?.sourceType && !sourceDefinition) {
      setSourceDefinition(sourceDefinitionsQuery.data.find((def) => def.type === workspace.onboarding.sourceType));
    }
  }, [sourceDefinitionsQuery.data, workspace?.onboarding?.sourceType]);

  const destinationDefinitionsQuery = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });
  useEffect(() => {
    if (destinationDefinitionsQuery.data && workspace?.onboarding?.destinationType && !destinationDefinition) {
      setDestinationDefinition(
        destinationDefinitionsQuery.data.find((def) => def.type === workspace.onboarding.destinationType),
      );
    }
  }, [destinationDefinitionsQuery.data, workspace?.onboarding?.destinationType]);

  if (!onboarding) {
    return <Navigate to="/syncs" />;
  }

  if (
    sourceQuery.isLoading ||
    destinationDefinitionsQuery.isLoading ||
    destinationQuery.isLoading ||
    sourceDefinitionsQuery.isLoading
  ) {
    return <PageSpinner />;
  }

  return (
    <Page title="Setup" sx={{ bg: "gray.50" }} fullWidth>
      <Column flex={1} width="100%" pt={12} mx="auto" maxWidth="1000px" gap={10}>
        <Column textAlign="center" mb={16} align="center">
          <Title
            destination={destination}
            destinationDefinition={destinationDefinition}
            source={source}
            sourceDefinition={sourceDefinition}
          />
        </Column>

        <Column flex={1} gap={8} align="center">
          <Row width="100%" gap={8} align="center">
            <Card p={5} pos="relative" flex={1}>
              <Row gap={6}>
                <SourceCard
                  definition={sourceDefinition}
                  source={source}
                  onChange={() => {
                    setSource(undefined);
                    updateWorkspace.mutateAsync({
                      id: workspace?.id,
                      input: { onboarding: { ...(workspace?.onboarding ?? {}), sourceId: null } },
                    });
                    navigate("source/catalog");
                  }}
                />
              </Row>
            </Card>

            <Box as="img" src={syncArrowIcon} />

            <Card p={5} pos="relative" flex={1}>
              <Row gap={6}>
                <DestinationCard
                  destination={destination}
                  definition={destinationDefinition}
                  onChange={() => {
                    setDestination(undefined);
                    updateWorkspace.mutateAsync({
                      id: workspace?.id,
                      input: { onboarding: { ...(workspace?.onboarding ?? {}), destinationId: null } },
                    });
                    navigate("destination/catalog");
                  }}
                />
              </Row>
            </Card>
          </Row>

          {Boolean(source && destination) && (
            <Button variant="primary" onClick={() => navigate("sync")} size="lg">
              Configure sync
            </Button>
          )}
        </Column>

        <Card>
          <Column gap={4} pos="relative">
            <Heading>Need help setting up?</Heading>
            <Box
              as="img"
              alt="Customer success team."
              src={customerSuccessAvatars}
              height="40px"
              pos="absolute"
              top={0}
              right={0}
            />
            <Box maxW="600px">
              If you feel stuck, please chat with us or schedule a call. Our team will help you configure and test your first
              sync
              {sourceDefinition && destinationDefinition
                ? ` from ${sourceDefinition?.name} to ${destinationDefinition?.name}`
                : ""}
              . This isn’t a sales call. We promise.
            </Box>
            <ButtonGroup>
              <Button
                icon={ChatBubbleLeftRightIcon}
                onClick={() => {
                  newIntercomMessage("I'd like help creating my first sync.");
                  analytics.track("Help Requested", {
                    origin_page: location.pathname,
                  });
                }}
              >
                Chat with us
              </Button>
              <Button
                icon={CalendarDaysIcon}
                onClick={() => {
                  window.open("https://calendly.com/hightouch-experts/consultation", "_blank");
                  analytics.track("Book Session Opened", {
                    origin_page: location.pathname,
                  });
                }}
              >
                Schedule a call
              </Button>
            </ButtonGroup>
          </Column>
        </Card>
      </Column>

      <Routes>
        <Route
          path="source/new"
          element={
            <CreateSourceWizard
              initialSourceDefinition={source?.definition || sourceDefinition}
              onConnectClick={() => {
                storage.save("onboarding", true);
              }}
              onCancel={() => {
                storage.remove("onboarding");
                navigate("/onboarding");
              }}
              onSubmit={({ id, definition }) => {
                storage.remove("onboarding");
                updateWorkspace.mutateAsync({
                  id: workspace?.id,
                  input: { onboarding: { ...(workspace?.onboarding ?? {}), sourceId: id } },
                });
                analytics.track("Source Config Completed", {
                  source_type: definition?.type,
                  source_id: id,
                });
                navigate("/onboarding");
              }}
            />
          }
        />
        <Route
          path="source/catalog"
          element={
            <InfoModal title="Select a source" onClose={() => navigate(-1)} width="600px" height="100%">
              <SourceList
                onSelect={(definition) => {
                  setSourceDefinition(definition);
                  updateWorkspace.mutateAsync({
                    id: workspace?.id,
                    input: { onboarding: { ...(workspace?.onboarding ?? {}), sourceType: definition.type } },
                  });
                  analytics.track("Source Declared", {
                    source_type: definition.type,
                  });
                  navigate("/onboarding");
                }}
              />
            </InfoModal>
          }
        />
        <Route
          path="destination/new"
          element={
            <CreateDestinationWizard
              initialDestinationDefinition={destination?.definition || destinationDefinition}
              onConnectClick={() => {
                storage.save("onboarding", true);
              }}
              onCancel={() => {
                storage.remove("onboarding");
                navigate("/onboarding");
              }}
              onSubmit={({ id, definition }) => {
                storage.remove("onboarding");
                updateWorkspace.mutateAsync({
                  id: workspace?.id,
                  input: { onboarding: { ...(workspace?.onboarding ?? {}), destinationId: id } },
                });
                analytics.track("Destination Config Completed", {
                  destination_type: definition?.type,
                  destination_id: id,
                });
                navigate("/onboarding");
              }}
            />
          }
        />
        <Route
          path="destination/catalog"
          element={
            <InfoModal title="Select a destination" onClose={() => navigate(-1)} width="800px" height="100%">
              <DestinationCatalog
                onSelect={(definition) => {
                  setDestinationDefinition(definition);
                  updateWorkspace.mutateAsync({
                    id: workspace?.id,
                    input: { onboarding: { ...(workspace?.onboarding ?? {}), destinationType: definition.type } },
                  });
                  analytics.track("Destination Declared", {
                    destination_type: definition.type,
                  });
                  navigate("/onboarding");
                }}
              />
            </InfoModal>
          }
        />
        <Route
          path="sync"
          element={
            source && destination ? (
              <CreateOnboardingSyncWizard
                source={source}
                destination={destination}
                onSubmit={({ id }) => {
                  navigate(`/syncs`, { state: { onboardingSync: id } });
                }}
                onCancel={() => {
                  navigate("/onboarding");
                }}
              />
            ) : (
              <Navigate to="/onboarding" />
            )
          }
        />
      </Routes>
    </Page>
  );
};

type SourceCardProps = {
  definition: SourceDefinition | undefined;
  source: SourceQuery["connections_by_pk"] | undefined;
  onChange(): void;
};

const SourceCard: FC<SourceCardProps> = ({ definition, source, onChange }) => {
  const navigate = useNavigate();

  if (source) {
    return (
      <>
        <Box as="img" src={definition?.icon} sx={{ height: "40px", objectFit: "contain" }} />
        <Column gap={2} alignItems="flex-start">
          <Heading size="md">{source.name}</Heading>
          <Row align="center" gap={2} height="36px" justify="center" color="green">
            <CheckCircleIcon color="var(--chakra-colors-success-base)" width="24px" />
            <Text fontWeight="medium">Source connected!</Text>
          </Row>
        </Column>
        <Box
          as="button"
          pos="absolute"
          top={3}
          right={3}
          fontSize="sm"
          color="gray.600"
          _hover={{ color: "gray.800" }}
          transition="color 200ms"
          onClick={onChange}
        >
          Change
        </Box>
      </>
    );
  }
  if (definition) {
    return (
      <>
        <Box as="img" src={definition.icon} sx={{ height: "40px", objectFit: "contain" }} />
        <Column gap={2} alignItems="flex-start">
          <Heading size="md">{definition.name}</Heading>
          <Button
            variant="primary"
            onClick={() => {
              analytics.track("Source Config Started", {
                source_type: definition.name,
              });
              navigate("source/new");
            }}
          >
            Connect source
          </Button>
        </Column>
        <Box
          as="button"
          pos="absolute"
          top={3}
          right={3}
          fontSize="sm"
          color="gray.600"
          _hover={{ color: "gray.800" }}
          transition="color 200ms"
          onClick={onChange}
        >
          Change
        </Box>
      </>
    );
  }
  return (
    <>
      <Box as="img" src={placeholder} />
      <Column gap={2}>
        <Heading size="md">Source</Heading>
        <Button variant="primary" onClick={() => navigate("source/catalog")}>
          Select source
        </Button>
      </Column>
    </>
  );
};

const DestinationCard = ({ definition, destination, onChange }) => {
  const navigate = useNavigate();

  if (destination) {
    return (
      <>
        <Box as="img" src={definition?.icon} sx={{ height: "40px", objectFit: "contain" }} />
        <Column gap={2} alignItems="flex-start">
          <Heading size="md">{destination.name}</Heading>
          <Row align="center" gap={2} height="36px" justify="center" color="green">
            <CheckCircleIcon color="var(--chakra-colors-success-base)" width="24px" />
            <Text fontWeight="medium">Destination connected!</Text>
          </Row>
        </Column>
        <Box
          as="button"
          pos="absolute"
          top={3}
          right={3}
          fontSize="sm"
          color="gray.600"
          _hover={{ color: "gray.800" }}
          transition="color 200ms"
          onClick={onChange}
        >
          Change
        </Box>
      </>
    );
  }

  if (definition) {
    return (
      <>
        <Box as="img" src={definition.icon} sx={{ height: "40px", objectFit: "contain" }} />
        <Column gap={2} alignItems="flex-start">
          <Heading size="md">{definition.name}</Heading>
          <Button
            variant="primary"
            onClick={() => {
              analytics.track("Destination Config Started", {
                destination_type: definition.name,
              });
              navigate("destination/new");
            }}
          >
            Connect destination
          </Button>
        </Column>
        <Box
          as="button"
          pos="absolute"
          top={3}
          right={3}
          fontSize="sm"
          color="gray.600"
          _hover={{ color: "gray.800" }}
          transition="color 200ms"
          onClick={onChange}
        >
          Change
        </Box>
      </>
    );
  }

  return (
    <>
      <Box as="img" src={placeholder} />
      <Column gap={2}>
        <Heading size="md">Destination</Heading>
        <Button variant="primary" onClick={() => navigate("destination/catalog")}>
          Select destination
        </Button>
      </Column>
    </>
  );
};

const Title = ({ destinationDefinition, destination, source, sourceDefinition }) => {
  // neither source nor destination declared
  let heading = "Select the tools you want to sync";
  let description = "Begin by choosing your data source and destination";

  if (source && destination) {
    // source connected, destination connected
    heading = "Configure your first sync";
    description = `Start sending data from ${source.name} to ${destination.name}`;
  } else if (source && destinationDefinition) {
    // source connected, destination declared
    heading = "Connect to your destination";
    description = `Grant Hightouch access to ${destinationDefinition.name}`;
  } else if (sourceDefinition && destination) {
    // source declared, destination connected
    heading = "Connect to your source";
    description = `Grant Hightouch access to ${sourceDefinition.name}`;
  } else if (sourceDefinition && destinationDefinition) {
    // source declared, destination declared
    heading = "Connect to your source and destination";
    description = `Grant Hightouch access to ${sourceDefinition.name} and ${destinationDefinition.name}`;
  } else if (source || sourceDefinition) {
    // source declared or connected, destination not declared
    heading = "Select your destination";
    description = `Tell us where you want to send your data`;
  } else if (destination || destinationDefinition) {
    // source not declared, destination declared or connected
    heading = "Select your source";
    description = `Tell us where your data is stored and managed`;
  }

  return (
    <>
      <Heading size="2xl" mb={3}>
        {heading}
      </Heading>
      <Box fontSize="lg" color="gray.600">
        {description}
      </Box>
    </>
  );
};

import { FC } from "react";

import { Box, Switch } from "@hightouchio/ui";
import { Text } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useUserflowAttributesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("upsert"),
  object: Yup.string().required().default("users"),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings.test(
    "hasOneOf",
    "Mappings may only contain one of either 'groups' or 'memberships' fields.",
    function () {
      const mappings = this.parent.mappings.map(({ to }) => to);
      return !(mappings.includes("groups") && mappings.includes("memberships"));
    },
  ),
  pruneMemberships: Yup.boolean().notRequired(),
  deleteMode: Yup.string().notRequired(),
});

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const OBJECTS = [
  { label: "Users", value: "users" },
  { label: "Groups", value: "groups" },
];

const STATIC_USER_PROPERTIES = ["groups", "memberships"];

export const UserflowForm: FC = () => {
  const { config, setConfig, destination } = useDestinationForm();
  const staticMapperOpts = STATIC_USER_PROPERTIES.map((property) => ({ label: property, value: property }));

  const {
    data: userflowData,
    error: userflowError,
    isFetching: loadingUserflowData,
    refetch: getAttributes,
  } = useUserflowAttributesQuery({
    destinationId: String(destination?.id),
    object: config?.object,
  });

  const fields = userflowData?.userflowGetAttributes?.attributes;

  const attributeOptions =
    fields?.map((field) => ({
      label: field?.displayName,
      value: field?.name,
      type: field?.type,
    })) || [];

  const idLabel = config?.object === "users" ? "User ID" : "Group ID";

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField options={MODES} onChange={(mode) => setConfig({ object: config?.object, mode })} />

      <Section>
        <IdMappingField options={[{ label: idLabel, value: "id" }]} />
      </Section>

      <Section>
        <MappingsField
          isCreatable
          error={userflowError?.message}
          loading={loadingUserflowData}
          options={config?.object === "users" ? [...staticMapperOpts, ...attributeOptions] : attributeOptions}
          reload={getAttributes}
        />
      </Section>

      {config?.mappings.map(({ to }) => to).some((m) => m === "groups" || m === "memberships") && (
        <Section>
          <Field
            description="By default, the Userflow API will only update the memberships/groups that are included in the request. Existing memberships that are not included will not be removed, unless you enable membership pruning. Only enable pruning if the groups or memberships list is set and contains all the groups the user belongs to. When a membership is deleted, the group itself is left intact."
            label="Would you like to enable membership pruning?"
            size="large"
          >
            <Box alignItems="center" display="flex" gap={2}>
              <Text
                sx={{
                  textTransform: "uppercase",
                  fontSize: "10px",
                  color: "base.4",
                  fontWeight: "bold",
                }}
              >
                Enable membership pruning
              </Text>

              <Switch
                isChecked={Boolean(config?.pruneMemberships)}
                onChange={(pruneMemberships) => {
                  setConfig({ ...config, pruneMemberships });
                }}
              />
            </Box>
          </Field>
        </Section>
      )}

      <DeleteField modes={config?.mode === "upsert" ? ["delete"] : ["clear"]} />
    </>
  );
};

export default {
  form: UserflowForm,
  validation,
};

import { useState, FC } from "react";

import { PlusIcon } from "@heroicons/react/24/outline";
import {
  Link,
  Box,
  Text as HightouchUiText,
  Button as HightouchUiButton,
  Select as HightouchUiSelect,
  FormField,
  Spinner,
} from "@hightouchio/ui";
import { Text, Flex } from "theme-ui";

import { useTunnelsQuery, useTestTunnelQuery, CreateNormalTunnelMutation, CreateReverseTunnelMutation } from "src/graphql";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";
import { SourceTunnel } from "src/utils/sources";

import { Permission } from "../permission";
import { CreateReverseTunnelForm, CreateNormalTunnelForm, ChooseTunnelForm } from "./create-tunnel";

export interface TunnelSelectProps {
  useHightouchUi?: boolean;
  value:
    | {
        id: string | undefined;
        tunnel_id?: string;
      }
    | undefined;
  optional?: boolean;
  onChange: (tunnel: SourceTunnel | undefined) => void;
}

export const TunnelSelect: FC<TunnelSelectProps> = ({ useHightouchUi = false, onChange, value, optional = false }) => {
  const [askingTunnelType, setAskingTunnelType] = useState(false);
  const [tunnelType, setTunnelType] = useState<"normal" | "reverse">("normal");
  const [creatingTunnel, setCreatingTunnel] = useState<boolean>(false);
  const [creatingReverseTunnel, setCreatingReverseTunnel] = useState<boolean>(false);

  const { data, refetch } = useTunnelsQuery();
  const tunnelOptions = data?.getTunnels?.map(({ tunnel: { name, id } }) => ({ label: name ?? "", value: id })) ?? [];

  const { data: tunnelTest, status: tunnelTestStatus } = useTestTunnelQuery(
    {
      id: value?.id ?? "",
    },
    {
      enabled: typeof value?.id === "string",
    },
  );

  const tunnelCreated = (
    { tunnel }: CreateNormalTunnelMutation["createNormalTunnel"] | CreateReverseTunnelMutation["createReverseTunnel"],
    name: string,
  ) => {
    if (tunnel.id) {
      onChange({
        id: tunnel.id,
        name,
      });

      void refetch();
    }
  };

  const FieldComponent = useHightouchUi ? FormField : Field;
  const fieldComponentProps = useHightouchUi
    ? {
        isOptional: optional,
        label: "Tunnel",
        description: "Tunnels allow easy access to services running within a private network.",
      }
    : {
        label: "Tunnel",
        description: "Tunnels allow easy access to services running within a private network.",
        optional,
      };

  return (
    <>
      <Permission>
        <FieldComponent {...fieldComponentProps}>
          {tunnelOptions.length || value ? (
            <>
              {useHightouchUi ? (
                <Box alignItems="center" display="flex" gap={3}>
                  <HightouchUiSelect
                    isClearable
                    options={[{ label: "None", value: undefined }, ...tunnelOptions]}
                    placeholder="Select a tunnel..."
                    value={value?.id || value?.tunnel_id}
                    onChange={(newValue) => {
                      const option = tunnelOptions.find((option) => option.value === newValue);

                      if (option?.value) {
                        onChange({
                          id: option.value,
                          name: option.label,
                        });
                      } else {
                        onChange(undefined);
                      }
                    }}
                  />

                  <HightouchUiButton icon={PlusIcon} onClick={() => setAskingTunnelType(true)}>
                    New tunnel
                  </HightouchUiButton>
                </Box>
              ) : (
                <Select
                  isClearable
                  options={tunnelOptions}
                  placeholder="Select a tunnel..."
                  value={value?.id || value?.tunnel_id}
                  onChange={(option) => {
                    if (option) {
                      onChange({ id: option?.value, name: option?.label });
                    } else {
                      onChange(undefined);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              {useHightouchUi ? (
                <Box alignItems="center" display="flex" gap={4}>
                  <HightouchUiButton onClick={() => setCreatingTunnel(true)}>Create a tunnel</HightouchUiButton>
                  <HightouchUiText textTransform="uppercase">or</HightouchUiText>
                  <HightouchUiButton onClick={() => setCreatingReverseTunnel(true)}>Create a reverse tunnel</HightouchUiButton>
                </Box>
              ) : (
                <Flex sx={{ alignItems: "center" }}>
                  <Button variant="secondary" onClick={() => setCreatingTunnel(true)}>
                    Create a tunnel
                  </Button>
                  <Text sx={{ mx: 4, fontSize: 0, fontWeight: "bold" }}>OR</Text>
                  <Button variant="secondary" onClick={() => setCreatingReverseTunnel(true)}>
                    Create a reverse tunnel
                  </Button>
                </Flex>
              )}
            </>
          )}

          {tunnelTestStatus === "loading" && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Spinner size="sm" />
              <Text sx={{ ml: 2, fontSize: 12 }}>Testing...</Text>
            </Flex>
          )}

          {tunnelTest?.checkTunnel.success && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Circle color="green" radius="8px" />
              <Text sx={{ ml: 2, fontSize: 12 }}>Active</Text>
            </Flex>
          )}

          {tunnelTest?.checkTunnel.success === false && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Circle color="red" radius="8px" />
              <Text sx={{ ml: 2, fontSize: "11px" }}>
                Disconnected. Go to <Link href="/settings/tunnels">tunnel settings</Link> to re-connect.
              </Text>
            </Flex>
          )}
        </FieldComponent>
      </Permission>

      {askingTunnelType && (
        <ChooseTunnelForm
          setTunnelType={setTunnelType}
          tunnelType={tunnelType}
          onClose={() => {
            setAskingTunnelType(false);
            setTunnelType("normal");
          }}
          onContinue={() => {
            setAskingTunnelType(false);

            if (tunnelType === "normal") {
              setCreatingTunnel(true);
            } else {
              setCreatingReverseTunnel(true);
            }
          }}
        />
      )}

      {creatingTunnel && <CreateNormalTunnelForm onClose={() => setCreatingTunnel(false)} onCreate={tunnelCreated} />}
      {creatingReverseTunnel && (
        <CreateReverseTunnelForm onClose={() => setCreatingReverseTunnel(false)} onCreate={tunnelCreated} />
      )}
    </>
  );
};

import { FC, PropsWithChildren } from "react";

import { Flex, ThemeUIStyleObject } from "theme-ui";

export type CircleProps = PropsWithChildren<{
  radius: string;
  color?: string;
  sx?: ThemeUIStyleObject;
}>;

export const Circle: FC<Readonly<CircleProps>> = ({ radius, children, color, sx = {} }) => (
  <Flex
    sx={{
      borderRadius: "50%",
      width: radius,
      height: radius,
      bg: color,
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      ...sx,
    }}
  >
    {children}
  </Flex>
);

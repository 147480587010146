import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import LR from "logrocket";

import { useUser } from "src/contexts/user-context";

export const Logrocket = () => {
  const { user, featureFlags } = useUser();

  useEffect(() => {
    if (import.meta.env.PROD && user && !featureFlags?.logrocket_disabled) {
      LR.init("hightouch/hightouch-app");
      LR.identify(String(user?.id), {
        name: user?.name,
        email: user?.email,
      });
      LR.getSessionURL((sessionURL) => {
        if (sessionURL) {
          Sentry.configureScope((scope) => {
            scope.setExtra("logrocket", sessionURL);
          });
        }
      });
    }
  }, [!!user, featureFlags]);

  return null;
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AlertingIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 22 28">
      <path
        d="M10.9998 22.5996C10.35 22.5996 9.72686 22.8577 9.26739 23.3172C8.80793 23.7767 8.5498 24.3998 8.5498 25.0496C8.5498 25.6994 8.80793 26.3226 9.26739 26.782C9.72686 27.2415 10.35 27.4996 10.9998 27.4996C11.6496 27.4996 12.2728 27.2415 12.7322 26.782C13.1917 26.3226 13.4498 25.6994 13.4498 25.0496C13.4498 24.3998 13.1917 23.7767 12.7322 23.3172C12.2728 22.8577 11.6496 22.5996 10.9998 22.5996V22.5996Z"
        fill="#B6DCFE"
      />
      <path
        d="M11.0002 22.95C12.158 22.95 13.1002 23.8922 13.1002 25.05C13.1002 26.2078 12.158 27.15 11.0002 27.15C9.8424 27.15 8.9002 26.2078 8.9002 25.05C8.9002 23.8922 9.8424 22.95 11.0002 22.95ZM11.0002 22.25C9.4539 22.25 8.2002 23.5037 8.2002 25.05C8.2002 26.5963 9.4539 27.85 11.0002 27.85C12.5465 27.85 13.8002 26.5963 13.8002 25.05C13.8002 23.5037 12.5465 22.25 11.0002 22.25Z"
        fill="#4788C7"
      />
      <path
        d="M0.849609 23.6V21.4804C2.42251 21.3054 3.64961 19.9684 3.64961 18.35V9.95C3.64961 6.681 5.84761 3.7753 8.99481 2.8842L9.24961 2.8121V2.25C9.24961 1.2854 10.035 0.5 10.9996 0.5C11.9642 0.5 12.7496 1.2854 12.7496 2.25V2.8121L13.0044 2.8842C16.1516 3.7753 18.3496 6.681 18.3496 9.95V18.35C18.3496 19.9691 19.5767 21.3061 21.1496 21.4804V23.6H0.849609Z"
        fill="#DFF0FE"
      />
      <path
        d="M11 0.850391C11.7721 0.850391 12.4 1.47829 12.4 2.25039V2.54789V3.07709L12.9089 3.22129C15.9063 4.07039 18 6.83749 18 9.95039V18.3504C18 20.0409 19.2047 21.4549 20.8 21.7804V23.2504H1.2V21.7804C2.7953 21.4549 4 20.0409 4 18.3504V9.95039C4 6.83749 6.0937 4.07039 9.0911 3.22129L9.6 3.07709V2.54789V2.25039C9.6 1.47829 10.2279 0.850391 11 0.850391ZM11 0.150391C9.8401 0.150391 8.9 1.09049 8.9 2.25039C8.9 2.31969 8.9 2.42749 8.9 2.54789C5.6695 3.46279 3.3 6.42659 3.3 9.95039V18.3504C3.3 19.8967 2.0463 21.1504 0.5 21.1504V23.9504H21.5V21.1504C19.9537 21.1504 18.7 19.8967 18.7 18.3504V9.95039C18.7 6.42659 16.3305 3.46279 13.1 2.54789C13.1 2.42749 13.1 2.31969 13.1 2.25039C13.1 1.09049 12.1599 0.150391 11 0.150391Z"
        fill="#4788C7"
      />
    </Icon>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ModelIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <rect fillOpacity="0.3" height="20" width="20" x="2" y="2" />
      <rect height="12" width="2" x="11" y="6" />
      <rect height="9" width="2" x="7" y="9" />
      <rect height="6" width="2" x="15" y="12" />
    </Icon>
  );
};

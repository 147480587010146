import { FC } from "react";

import { Spinner } from "@hightouchio/ui";
import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import {
  MODE_LABEL,
  DEFAULT_MODE_LABEL,
  MODE_DESCRIPTIONS,
  DEFAULT_MODE_DESCRIPTIONS,
  MODE_DESCRIPTION,
  DEFAULT_MODE_DESCRIPTION,
} from "src/utils/destinations";

type ModeFieldProps = {
  error?: string;
  options: { label: string; value: string; description?: string | null }[];
  value?: string;
  reload?: () => void;
  loading?: boolean;
  onChange?: (value: string) => void;
  path?: string[];
};

export const ModeField: FC<Readonly<ModeFieldProps>> = ({
  value: propValue,
  error: propError,
  options,
  onChange,
  reload,
  loading,
  path,
}) => {
  const { slug, errors, config, setConfig } = useDestinationForm();

  const value = propValue || config?.mode;
  const error = propError || errors?.mode;

  return (
    <Section>
      <Field
        description={MODE_DESCRIPTION[slug ?? ""] || DEFAULT_MODE_DESCRIPTION}
        error={error}
        label={MODE_LABEL[slug ?? ""] || DEFAULT_MODE_LABEL}
        reload={reload}
        size="large"
      >
        {loading ? (
          <Spinner />
        ) : (
          <RadioGroup
            options={options.map((option) => ({
              ...option,
              description:
                option?.description ||
                (path
                  ? get(MODE_DESCRIPTIONS?.[slug ?? ""], path)?.[option.value]
                  : MODE_DESCRIPTIONS?.[slug ?? ""]?.[option.value]) ||
                DEFAULT_MODE_DESCRIPTIONS?.[option.value],
            }))}
            value={value}
            onChange={(mode) => {
              if (onChange) {
                onChange(mode as string);
              } else {
                setConfig({ mode });
              }
            }}
          />
        )}
      </Field>
    </Section>
  );
};

import { Box, Row, Button } from "@hightouchio/ui";

import googleLogo from "src/components/logos/google.svg";
import azureLogo from "src/components/logos/microsoft.svg";

export const GoogleButton = ({ onClick, action }) => (
  <Button size="lg" onClick={onClick}>
    <Row align="center">
      <Box as="img" src={googleLogo} sx={{ width: "20px", mr: 3, objectFit: "contain" }} />
      {`${action} with Google`}
    </Row>
  </Button>
);

export const MicrosoftButton = ({ onClick, action }) => (
  <Button size="lg" onClick={onClick}>
    <Row align="center">
      <Box as="img" src={azureLogo} sx={{ width: "20px", mr: 3, objectFit: "contain" }} />
      {`${action} with Microsoft`}
    </Row>
  </Button>
);

export const EmailButton = ({ onClick, action }) => (
  <Button size="lg" onClick={onClick}>
    {`${action} with email`}
  </Button>
);

import { FC, useMemo, useState } from "react";

import { Row, Text, Button, StatusIndicator, Paragraph, Box } from "@hightouchio/ui";

import { useGitSyncAttemptsByIdQuery, useGitSyncAttemptsQuery } from "src/graphql";
import { InfoModal } from "src/ui/modal/info-modal";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

type GitActivityProps = {
  id?: string;
  error?: boolean;
};

export const GitActivity: FC<Readonly<GitActivityProps>> = ({ id, error }) => {
  const { offset, limit, page, setPage } = useTableConfig({ limit: 10 });

  const { data: gitActivityByIdData, isFetching: gitActivityByIdLoading } = useGitSyncAttemptsByIdQuery(
    {
      syncId: Number(id),
      offset,
      limit,
    },
    { enabled: Boolean(id), notifyOnChangeProps: "tracked", keepPreviousData: true },
  );
  const { data: gitActivityData, isFetching: gitActivityLoading } = useGitSyncAttemptsQuery(
    { offset, limit },
    { enabled: !id, notifyOnChangeProps: "tracked", keepPreviousData: true },
  );

  const data = id ? gitActivityByIdData : gitActivityData;

  const gitActivity = data?.git_sync_attempts;
  const count = data?.git_sync_attempts_aggregate?.aggregate?.count ?? 0;

  const [attemptError, setError] = useState<string>("");

  const columns = useMemo(
    () => [
      {
        name: "Started",
        key: "created_at",
        cell: (createdAt) => formatDatetime(createdAt),
      },
      {
        name: "Type",
        key: "type",
        cell: (type) => (type === "inbound" ? <Text>Inbound</Text> : <Text>Outbound</Text>),
      },
      {
        name: "State",
        key: "state",
        cell: (state) =>
          state === "success" ? (
            <StatusIndicator variant="success">Success</StatusIndicator>
          ) : (
            <StatusIndicator variant="error">Error</StatusIndicator>
          ),
      },
      {
        name: "Error",
        key: "error",
        cell: (error) =>
          error ? (
            <Button
              onClick={() => {
                setError(error.message);
              }}
            >
              View
            </Button>
          ) : null,
      },
    ],
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        data={gitActivity}
        error={error}
        loading={gitActivityLoading || gitActivityByIdLoading}
        placeholder={{
          title: "No recent git activity.",
          body: "To view git activity, update this sync or change the definition in your git repository.",
          error: "Git activity failed to load, please try again.",
        }}
      />
      <Row width="100%" mt={4} justify="flex-end">
        <Pagination count={count} label="git syncs" page={page} rowsPerPage={limit} setPage={setPage} />
      </Row>

      <InfoModal isOpen={Boolean(attemptError)} title="Error" onClose={() => setError("")}>
        <Box width="xl">
          <Paragraph>{attemptError}</Paragraph>
        </Box>
      </InfoModal>
    </>
  );
};

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AudienceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z" />
      <path d="M16 17C16 14.7909 14.2091 13 12 13C9.79086 13 8 14.7909 8 17V20H16V17Z" />
      <path
        d="M20 10C20 11.1046 19.1046 12 18 12C16.8954 12 16 11.1046 16 10C16 8.89543 16.8954 8 18 8C19.1046 8 20 8.89543 20 10Z"
        fillOpacity="0.3"
      />
      <path
        d="M18 20V17C18 15.9459 17.7282 14.9552 17.2507 14.0943C17.4902 14.0327 17.7413 14 18 14C19.6569 14 21 15.3431 21 17V20H18Z"
        fillOpacity="0.3"
      />
      <path
        d="M4 10C4 11.1046 4.89543 12 6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10Z"
        fillOpacity="0.3"
      />
      <path
        d="M6 20V17C6 15.9459 6.27185 14.9552 6.74926 14.0943C6.50977 14.0327 6.25871 14 6 14C4.34315 14 3 15.3431 3 17V20H6Z"
        fillOpacity="0.3"
      />
    </Icon>
  );
};

import { useState, useEffect, FC, useRef, ReactNode } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Text, Box, Heading, Link, Row, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from "@hightouchio/ui";
import { useQueryClient } from "react-query";

import GrafanaLogo from "src/components/logos/grafana.svg";
import NbaLogo from "src/components/logos/nba.svg";
import NgrokLogo from "src/components/logos/ngrok.svg";
import { PricingCard } from "src/components/pricing/pricing-card";
import { useUser } from "src/contexts/user-context";
import { BillingOrganizationQuery, useCheckoutUrlQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";

import { Indices } from "../../../../design/indices";
import { AddOrganizationNameModal } from "../modals/add-organization-name-modal";

type Organization = BillingOrganizationQuery["getBillingOrganization"];
interface Props {
  organization: Organization | undefined;
  onClose: () => void;
}

enum BillingIntervals {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

const TABS = [BillingIntervals.YEARLY, BillingIntervals.MONTHLY];

export const PricingDisplay: FC<Readonly<Props>> = ({ organization, onClose }) => {
  const { workspace } = useUser();
  const { toast } = useToast();
  const client = useQueryClient();
  const [addOrgNameState, setAddOrgNameState] = useState<"closed" | "open" | "loading">("closed");
  const [selectedSku, setSelectedCta] = useState<string | undefined>();
  const [loadingSku, setLoadingSku] = useState<string | undefined>();
  const [isIntervalMonthly, setInterval] = useState(Boolean(organization?.plan?.sku?.endsWith("month")));
  const [tabIndex, setTabIndex] = useState<number>(0);
  const headingRef = useRef<HTMLDivElement | null>(null);
  const tabListRef = useRef<HTMLDivElement | null>(null);

  const pricingPlans = getPricingPlans(isIntervalMonthly);

  useEffect(() => {
    setInterval(Boolean(organization?.plan?.sku?.endsWith("month")));
  }, [organization]);

  useEffect(() => {
    if (headingRef?.current && tabListRef?.current) {
      tabListRef.current.style.top = `${headingRef.current.offsetHeight}px`;
    }
  }, [headingRef, tabListRef]);

  const changeInterval = (value: number) => {
    analytics.track("Annual/Monthly Pricing Switched", {
      workspace_id: workspace?.id,
      viewing: TABS[value],
    });
    setTabIndex(value);
    setInterval(TABS[value] === BillingIntervals.MONTHLY);
  };

  function ctaClick(sku: string) {
    analytics.track("Plan Checkout Clicked", {
      workspace_id: workspace?.id,
      sku,
    });
    const plan = pricingPlans.find((pricingPlan) => pricingPlan.sku === sku);
    if (plan?.ctaUrl) {
      return window.open(plan.ctaUrl, "_blank");
    }
    if (organization) {
      setLoadingSku(sku);
      return goToCheckout(sku, organization.name, () => {
        setLoadingSku(undefined);
      });
    } else {
      setSelectedCta(sku);
      return setAddOrgNameState("open");
    }
  }

  const goToCheckout = async (sku: string, orgName: string, fallback: () => void) => {
    const variables = {
      lookup_key: sku,
      workspace_id: workspace?.id,
      org_name: orgName,
    };

    analytics.track("Sending To Checkout", variables);

    try {
      const { getCheckoutUrl } = await client.fetchQuery(
        useCheckoutUrlQuery.getKey(variables),
        useCheckoutUrlQuery.fetcher(variables),
      );

      if (getCheckoutUrl.redirect) {
        location.href = getCheckoutUrl.redirect;
      } else {
        toast({
          id: "go-to-checkout",
          title: "Couldn't generate checkout URL",
          variant: "error",
        });

        analytics.track("Error Generating Checkout", variables);
        fallback();
      }
    } catch (e) {
      toast({
        id: "go-to-checkout",
        title: e.message,
        variant: "error",
      });

      analytics.track("Error Generating Checkout", variables);
      fallback();
    }
  };

  return (
    <>
      <Box
        ref={headingRef}
        backgroundColor="gray.100"
        mb={0}
        position="sticky"
        pt={4}
        top={0}
        width="100%"
        px={4}
        zIndex={Indices.Modal}
      >
        <Box
          _hover={{ color: "gray.800" }}
          aria-label="Close pricing modal"
          as="button"
          color="gray.600"
          position="absolute"
          right={4}
          top={4}
          transition="all"
          transitionDuration="faster"
          type="button"
          onClick={onClose}
        >
          <Box as={XMarkIcon} boxSize={5} />
        </Box>
        <Heading size="xl">Plans</Heading>
        <Text fontWeight="semibold" color="text.secondary">
          Select a plan that best fits your needs.
        </Text>
      </Box>
      <Tabs index={tabIndex} onChange={changeInterval}>
        <Box ref={tabListRef} backgroundColor="gray.100" position="sticky" pt={4} width="100%" zIndex={Indices.Modal}>
          <TabList>
            <Tab>
              <Row>
                Yearly&nbsp;
                <Text color="ocean.base">(save 20%)</Text>
              </Row>
            </Tab>
            <Tab>Monthly</Tab>
          </TabList>
        </Box>
        <TabPanels>
          {TABS.map((_, panelIndex) => (
            <TabPanel key={panelIndex}>
              <Box alignItems="stretch" display="flex" flexWrap="wrap" gap={4} p={4} justifyContent="center">
                {pricingPlans?.map((card) => {
                  return (
                    <PricingCard
                      key={card.sku}
                      checkList={card.checkList}
                      checkListHeading={card.checkListHeading}
                      ctaDisabled={card.sku === organization?.plan?.sku}
                      ctaLoading={loadingSku === card.sku}
                      ctaText={card.sku === organization?.plan?.sku ? "Current plan" : card.ctaText}
                      ctaUrl={card.ctaUrl}
                      partnerLogos={card.partnerLogos}
                      plan={card.plan}
                      price={card.price}
                      priceHint={card.priceHint}
                      priceUnit={card.priceUnit}
                      sku={card.sku}
                      onCtaClick={ctaClick}
                    />
                  );
                })}
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <AddOrganizationNameModal
        isLoading={addOrgNameState === "loading"}
        isOpen={addOrgNameState !== "closed"}
        onCancel={() => {
          analytics.track("Org Name Modal Canceled", {
            workspace_id: workspace?.id,
          });
          setAddOrgNameState("closed");
        }}
        onSubmit={(orgName) => {
          setAddOrgNameState("loading");
          analytics.track("Org Name Entered", {
            workspace_id: workspace?.id,
            orgName,
          });

          if (selectedSku) {
            goToCheckout(selectedSku, orgName, () => setAddOrgNameState("open"));
          }
        }}
      />
    </>
  );
};

export interface PricingPlan {
  plan: string;
  sku: string;
  price: string;
  priceUnit?: string;
  priceHint?: string;
  checkList: (string | ReactNode)[];
  checkListHeading?: string;
  partnerLogos: { img: string; alt: string }[];
  ctaText: string;
  ctaUrl?: string;
}

export const getPricingPlans = (isIntervalMonthly: boolean): PricingPlan[] => [
  {
    plan: "Starter",
    sku: isIntervalMonthly ? "starter_plan_month" : "starter_plan_year",
    ctaText: "Select plan",
    price: isIntervalMonthly ? "$450" : "$350",
    priceUnit: isIntervalMonthly ? " / month" : " / month*",
    priceHint: isIntervalMonthly ? "" : "*Billed annually",
    checkListHeading: "Included:",
    checkList: [
      "2 billable destinations",
      "Unlimited 30-day trials of any additional destination",
      <Text key="starter-1">
        Unlimited free destinations: Slack, Google Sheets,{" "}
        <Link href="https://hightouch.com/docs/pricing/ss-pricing#free-destinations">and more</Link>
      </Text>,
      "Unlimited data fields",
      "Unlimited sources",
      "Unlimited source rows",
      "Unlimited user seats",
    ],
    partnerLogos: [{ img: GrafanaLogo, alt: "Grafana logo." }],
  },
  {
    plan: "Pro",
    sku: isIntervalMonthly ? "pro_plan_month" : "pro_plan_year",
    ctaText: "Select plan",
    price: isIntervalMonthly ? "$1,000" : "$800",
    priceUnit: isIntervalMonthly ? " / month" : " / month*",
    priceHint: isIntervalMonthly ? "" : "*Billed annually",
    checkListHeading: "Included:",
    checkList: [
      "4 billable destinations",
      "Unlimited 30-day trials of any additional destination",
      <Text key="pro-1">
        Unlimited free destinations: Slack, Google Sheets,{" "}
        <Link href="https://hightouch.com/docs/pricing/ss-pricing#free-destinations">and more</Link>
      </Text>,
      "Unlimited data fields",
      "Unlimited sources",
      "Unlimited source rows",
      "Unlimited user seats",
      "Custom alerting",
    ],
    partnerLogos: [{ img: NgrokLogo, alt: "Ngrok logo." }],
  },
  {
    sku: "",
    plan: "Enterprise",
    ctaText: "Talk to us",
    ctaUrl: "https://hightouch.com/demo?ref=/pricing",
    price: "Custom pricing",
    priceHint: isIntervalMonthly ? "" : "Consult with a solutions specialist",
    checkListHeading: "Everything in the Pro plan, plus:",
    checkList: [
      "Custom # of destinations",
      <Text key="enterprise-1">
        Premium destinations: NetSuite, SFMC,{" "}
        <Link href="https://hightouch.com/docs/pricing/ss-pricing#premium-destinations">and more</Link>
      </Text>,
      "Audience builder (add on)",
      "Granular RBAC and label-based access controls",
      "SAML SSO (Okta, Azure AD, Auth0, OneLogin, PingIdentity)",
      "Git version control",
      "Approval flows and drafts",
      "Real-time syncing (sub-hourly)",
      "Bring-your-own storage for sync planning",
      "Warehouse sync logs",
      "Dedicated solutions architect",
      "White glove onboarding",
      "Custom procurement and SLAs",
      "Audit logs",
      "Fivetran sync scheduler",
    ],
    partnerLogos: [{ img: NbaLogo, alt: "N.B.A. logo." }],
  },
];

export const pricingPlans = [...getPricingPlans(false), ...getPricingPlans(true)];

import { Text } from "theme-ui";

import { Column } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";
import { abbreviateNumber } from "src/utils/numbers";

export const NumberBadge = ({ value, color, tooltip }) => (
  <Tooltip size={14} text={tooltip}>
    <Column sx={{ alignItems: "center", justifyContent: "center", height: "20px", px: 1, bg: `${color}s.0`, borderRadius: 1 }}>
      <Text sx={{ color, fontWeight: "bold" }}>{typeof value !== "undefined" ? abbreviateNumber(value) : "-"}</Text>
    </Column>
  </Tooltip>
);

import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { RepeatIcon } from "src/ui/icons";

import { Button } from "./button";

export interface ReloadButtonProps {
  loading?: boolean;
  disabled?: boolean;
  tooltip?: string;
  sx?: ThemeUIStyleObject;
  onClick: () => void;
}

export const ReloadButton: FC<Readonly<ReloadButtonProps>> = ({
  loading = false,
  disabled = false,
  tooltip,
  sx = {},
  onClick,
  ...props
}) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      sx={{
        borderRadius: "50%",
        fontSize: 2,
        p: 0,
        height: "30px",
        width: "30px",
        maxWidth: "unset",
        minWidth: "unset",
        color: "base.4",
        ":hover": {
          svg: {
            stroke: "primary",
          },
        },
        ...sx,
      }}
      tooltip={tooltip}
      variant="secondary"
      onClick={onClick}
      {...props}
    >
      <RepeatIcon size={14} />
    </Button>
  );
};

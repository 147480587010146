import { MouseEvent } from "react";

import { NavigateFunction } from "react-router-dom";

export const openUrl = (url: string, navigate: NavigateFunction, event: MouseEvent) => {
  if (event.metaKey || event.ctrlKey) {
    const slug = window.location.pathname.split("/")[1];
    const urlWithSlug = url.startsWith(`/${slug}`) ? url : `/${slug}${url}`;
    const win = window.open(urlWithSlug, "_blank");
    win?.focus();
  } else {
    navigate(url);
  }
};

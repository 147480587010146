import { useState, forwardRef, useImperativeHandle, useRef } from "react";

import { withTheme, FormProps as RfjsFormProps } from "@rjsf/core";
import { Textarea as ThemedTextarea, Grid, Flex, Close } from "theme-ui";

import { Button } from "src/ui/button";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Select } from "src/ui/select";

import { ChevronDownIcon, ChevronUpIcon } from "../icons";

// the base checkbox didn't seem to work

export interface FormProps extends Pick<RfjsFormProps<unknown>, "schema" | "uiSchema"> {
  value: unknown;
  onChange: (value: unknown) => void;
}

const ObjectFieldTemplate = (props) => {
  const { idSchema, title, description, properties, required, onAddClick, schema, uiSchema } = props;
  const isRoot = idSchema?.$id === "root";
  return (
    <Field
      description={description}
      label={title}
      nested={!isRoot}
      required={required}
      size={isRoot ? "large" : undefined}
      sx={{ flex: "1 1 auto" }}
    >
      <Grid gap={8}>
        {properties.map((element) => element.content)}
        {schema?.additionalProperties && !uiSchema?.["ui:options"]?.expandable && (
          <Button
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              onAddClick(props.schema)();
            }}
          >
            Add Property to {title}
          </Button>
        )}
      </Grid>
    </Field>
  );
};

const ArrayFieldTemplate = (props) => {
  const { title, description, items, required } = props;

  return (
    <Field nested description={description} label={title} required={required} sx={{ flex: "1 1 auto" }}>
      <Grid gap={4}>
        {items.map((element) => (
          <Flex key={element.key} sx={{ alignItems: "center" }}>
            <Flex sx={{ flex: "1 1 auto" }}>{element.children}</Flex>
            <Flex sx={{ alignItems: "center", ml: 6, flex: "0 0 auto" }}>
              <Button
                disabled={!element.hasMoveUp}
                sx={{ p: 0, mr: 1 }}
                variant="secondary"
                onClick={element.onReorderClick(element.index, element.index - 1)}
              >
                <ChevronUpIcon />
              </Button>
              <Button
                disabled={!element.hasMoveDown}
                sx={{ p: 0 }}
                variant="secondary"
                onClick={element.onReorderClick(element.index, element.index + 1)}
              >
                <ChevronDownIcon />
              </Button>
              <Close sx={{ ml: 2 }} onClick={element.onDropIndexClick(element.index)} />
            </Flex>
          </Flex>
        ))}
        <Flex>
          <Button disabled={!props.canAdd} variant="secondary" onClick={props.onAddClick}>
            Add Item to {title}
          </Button>
        </Flex>
      </Grid>
    </Field>
  );
};

const FieldTemplate = (props) => {
  const {
    id,
    displayLabel,
    hidden,
    label,
    rawHelp,
    required,
    rawDescription,
    rawErrors,
    children,
    onDropPropertyClick,
    onKeyChange,
    schema,
  } = props;
  const [propertyKey, setPropertyKey] = useState(label);

  if (hidden) {
    return null;
  }

  return (
    <Field
      key={id}
      description={displayLabel && rawDescription}
      error={rawErrors?.join("\n")}
      help={rawHelp}
      label={displayLabel && label}
      labelComponent={
        onKeyChange && schema?.__additional_property
          ? () => (
              <Input
                sx={{ width: "240px", maxWidth: "240px" }}
                value={propertyKey}
                onBlur={() => {
                  onKeyChange(propertyKey);
                }}
                onChange={(value) => {
                  setPropertyKey(value);
                }}
              />
            )
          : undefined
      }
      required={label && required}
      sx={{ flex: "1 1 auto" }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Flex sx={{ flex: "1 1 auto" }}>{children}</Flex>
        {onDropPropertyClick && schema?.__additional_property && (
          <Flex sx={{ alignItems: "center", ml: 2, flex: "0 0 auto" }}>
            <Close
              sx={{ ml: 2 }}
              onClick={(e) => {
                onDropPropertyClick(label)(e);
              }}
            />
          </Flex>
        )}
      </Flex>
    </Field>
  );
};

const baseProps = (props) => ({
  autoFocus: props.autofocus,
  disabled: props.disabled,
  required: props.required,
  readOnly: props.readonly,
});

const BaseInput = (props) => {
  return (
    <Input
      {...baseProps(props)}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      onChange={(value) => {
        props.onChange(value);
      }}
    />
  );
};

const RangeWidget = (props) => (
  <input
    {...baseProps(props)}
    type="range"
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const FileWidget = (props) => (
  <input
    {...baseProps(props)}
    accept={props.uiSchema?.["ui:options"]?.accept}
    type="file"
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const TextareaWidget = (props) => (
  <ThemedTextarea
    {...baseProps(props)}
    placeholder={props.placeholder}
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const CheckboxWidget = (props) => (
  <Checkbox
    {...baseProps(props)}
    label={props.label}
    value={props.value}
    onChange={
      !props?.readonly
        ? () => {
            props.onChange(!props?.value);
          }
        : undefined
    }
  />
);

const RadioWidget = (props) => {
  return (
    <RadioGroup
      size="small"
      {...baseProps(props)}
      options={props.options?.enumOptions}
      value={props.value}
      onChange={(value) => props.onChange(value)}
    />
  );
};

const SelectWidget = (props) => {
  return (
    <Select
      {...baseProps(props)}
      isMulti={props.multiple}
      options={props.options?.enumOptions}
      placeholder={props.placeholder}
      value={props.value}
      onChange={
        props.multiple
          ? (arr) => {
              const values = arr?.map(({ value }) => value);
              props.onChange(values);
            }
          : ({ value }) => props.onChange(value)
      }
    />
  );
};

const theme = {
  fields: {},
  widgets: { BaseInput, FileWidget, SelectWidget, CheckboxWidget, TextareaWidget, RangeWidget, RadioWidget },
};
const ThemedForm = withTheme(theme);

export interface FormRef {
  triggerValidation: () => void;
}

export const JsonForm = forwardRef<FormRef, FormProps>(({ schema, uiSchema, value, onChange }, ref) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => ({
    triggerValidation() {
      submitButtonRef?.current?.click();
    },
  }));
  return (
    <ThemedForm
      noHtml5Validate
      ArrayFieldTemplate={ArrayFieldTemplate}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      formData={value}
      schema={schema}
      showErrorList={false}
      uiSchema={uiSchema}
      onChange={(data) => {
        onChange(data?.formData);
      }}
    >
      <button ref={submitButtonRef} style={{ display: "none" }} type="submit" />
    </ThemedForm>
  );
});

JsonForm.displayName = "JsonForm";

import { SourceDefinition, Maybe, ModelQuery } from "src/graphql";

type ModelQuerySegment = NonNullable<ModelQuery["segments_by_pk"]>;

export type Model = {
  id: ModelQuerySegment["id"];
  name: ModelQuerySegment["name"];
  connection: Maybe<{
    id: string;
    type: string;
  }>;
  query_type?: ModelQuerySegment["query_type"];
  query_raw_sql?: ModelQuerySegment["query_raw_sql"];
  query_table_name?: ModelQuerySegment["query_table_name"];
  query_dbt_model_id?: ModelQuerySegment["query_dbt_model_id"];
  query_looker_look_id?: ModelQuerySegment["query_looker_look_id"];
  query_integrations?: ModelQuerySegment["query_integrations"];
  custom_query?: ModelQuerySegment["custom_query"];
  visual_query_filter?: ModelQuerySegment["visual_query_filter"];
  columns?: ModelQuerySegment["columns"];
  draft?: boolean;
  // `title` property doesn't seem to exist, may have been a leftover from the past code
  // so, try removing it when we have a strict TypeScript config
  title?: string;
};

// Copy from packages/backend/core/lib/query/index.ts
export enum QueryType {
  RawSql = "raw_sql",
  Visual = "visual",
  Table = "table",
  DbtModel = "dbt_model",
  Dbt = "dbt",
  LookerLook = "looker_look",
  Sigma = "sigma",
  Custom = "custom",
}

export type QueryableSource = {
  name: string;
  id: string;
  type: string;
  config: Record<string, any>;
  definition: {
    name: string;
    icon: string;
    type: string;
    supportsResultSchema: boolean;
    isSampleDataSource: boolean;
    supportedQueries: SourceDefinition["supportedQueries"];
    sampleModels: SourceDefinition["sampleModels"];
    cleanRoomType: SourceDefinition["cleanRoomType"];
  };
};

export type DBTModel = {
  id: number;
  alias: string;
  description: string;
  name: string;
  original_file_path: string;
  git_sync_config: {
    id: number;
    schema: string;
  };
};

export type DBTSyncModel = {
  id: number;
  name: string;
  schema: string;
  dbt_unique_id: string;
  dbt_sync_config: {
    id: number;
    branch: string;
    repository: string;
  };
  data: {
    raw_sql: string;
    compiled_sql: string;
    [key: string]: any;
  };
  removed: boolean;
};

export type LookerLook = {
  id: string | undefined | null;
  title: string;
};

// Note: The inclusion of the query field is because this is also a typing of the segments.query_integrations JSONB column.
// Other integrations could be `{ query: { queryType: "new_integration", field1: string, field2: string } }` etc
export type Sigma = {
  query: {
    queryType: QueryType.Sigma;
    elementId: string | undefined;
    pageId: string | undefined;
    workbookId: string | undefined;
  };
};

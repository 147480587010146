import { FC, ReactNode } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";

export const SIZES = {
  small: "818px",
  medium: "1288px",
  large: "1448px",
  xlarge: "1640px",
  page: "1200px",
  full: "unset",
};

export type ContainerSize = keyof typeof SIZES;

export type Props = {
  children: ReactNode;
  size?: string;
  center?: boolean;
  sx?: ThemeUIStyleObject;
};

export const Container: FC<Readonly<Props>> = ({ children, sx = {}, center = true, size = "large" }) => {
  return (
    <Column
      sx={{
        width: "100%",
        mx: center ? "auto" : 0,
        flex: 1,
        maxWidth: SIZES[size] ?? size,
        ...sx,
      }}
    >
      {children}
    </Column>
  );
};

import { FC, useEffect, useState } from "react";

import { Box, useToast, Heading, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";

import { DestinationSelect } from "src/components/destinations/destination-select";
import { DestinationForm } from "src/components/destinations/sync-form";
import { ParentModelSelect } from "src/components/models/parent-model-select";
import { ScheduleManager } from "src/components/schedule";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { FormkitDestination, FormkitModel } from "src/formkit/components/formkit-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateSyncTemplateMutation,
  CreateSyncTemplateMutationVariables,
} from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { ResourceType, useResourceSlug } from "src/utils/slug";

type Config = CreateSyncTemplateMutationVariables["object"]["config"];

export const CreateSyncTemplate: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { schemaV2 } = useFlags();

  const [destination, setDestination] = useState<FormkitDestination | undefined>();
  const [destinationDefinition, setDestinationDefinition] = useState<DestinationDefinition | undefined>();
  const [parentModel, setParentModel] = useState<FormkitModel | undefined>();
  const [step, setStep] = useState<number>(0);
  const [config, setConfig] = useState<Config | undefined>();
  const [schedule, setSchedule] = useState<Schedule | null>({ type: ScheduleType.MANUAL });
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(ResourceType.SyncTemplate);

  const source = parentModel?.connection;

  const { mutateAsync: createSyncTemplate, isLoading: creating } = useCreateSyncTemplateMutation();

  useEffect(() => {
    if (parentModel?.name && destination?.name) {
      setName(`${parentModel.name} to ${destination.name}`);
    }
  }, [parentModel?.name, destination?.name]);

  const create = async () => {
    if (!destination || !parentModel) {
      return;
    }

    const slug = await getSlug(name);

    createSyncTemplate({
      object: {
        slug,
        name,
        config: { ...config, configVersion: destinationDefinition?.configVersion },
        destination_id: destination.id,
        segment_id: parentModel.id,
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });

    toast({
      id: "create-sync-template",
      title: "Sync template was created",
      variant: "success",
    });

    navigate(schemaV2 ? `/schema-v2/settings/sync-templates` : "/schema/sync-templates");
  };

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: (
        <Column sx={{ gap: 2 }}>
          <Heading>Parent models</Heading>
          <Text color="text.secondary">
            Only audiences that are built on the selected parent model can be synced with this template.
          </Text>
        </Column>
      ),
      render: () => (
        <ParentModelSelect
          onSelect={(model) => {
            setParentModel(model as FormkitModel);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Select destination",
      continue: "Click on a destination to continue",
      header: (
        <Column>
          <Heading>Select a destination</Heading>
        </Column>
      ),
      render: () => (
        <DestinationSelect
          allowedDestinations={source?.definition?.allowedDestinations}
          onSelect={(destination) => {
            setDestination(destination);
            setDestinationDefinition(destination.definition);
            setStep(2);
          }}
        />
      ),
    },
    {
      title: "Configure sync template",
      continueProps: { form: "destination-form", type: "submit" },
      onContinue: () => {},
      header: destination && destinationDefinition && (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Box
            as="img"
            alt={destinationDefinition.name}
            src={destinationDefinition.icon}
            sx={{ width: "32px", objectFit: "contain" }}
          />
          <Heading>Configure sync template for {destination.name || destinationDefinition.name}</Heading>
        </Row>
      ),
      render: () => (
        <>
          {destination && destinationDefinition && parentModel && (
            <DestinationForm
              hideSave
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={parentModel}
              slug={destinationDefinition.type}
              sourceDefinition={source?.definition}
              onSubmit={(config) => {
                setConfig(config);
                setStep(step + 1);
                return Promise.resolve();
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Finalize sync template",
      disabled: !name || !schedule,
      submitting: creating,
      header: <Heading>Finalize settings for this sync template</Heading>,
      render: () => {
        return (
          <Column sx={{ gap: 8, maxWidth: "600px" }}>
            <Field label="Template name">
              <Input placeholder={destination?.name ?? undefined} value={name} onChange={setName} />
            </Field>

            <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>New sync template</title>
      </Helmet>

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New sync template"
        onCancel={() => {
          navigate(schemaV2 ? "/schema-v2/settings/sync-templates" : "/schema/sync-templates");
        }}
        onSubmit={create}
      />
    </>
  );
};

import {
  Bars3BottomRightIcon,
  Cog6ToothIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import { ResourceActivityMapper } from "src/components/resource-activity/timeline";

export const modelActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "primary_key",
    parser: (activity, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the primary key from <Code>{oldValue}</Code> to{" "}
            <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <ViewColumnsIcon />,
      };
    },
  },
  {
    accessor: "query_table_name",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the query source table to <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <TableCellsIcon />,
      };
    },
  },
  {
    accessor: "query_raw_sql",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the model SQL query</Text>,
        icon: <Bars3BottomRightIcon />,
      };
    },
  },
  {
    accessor: "folder_id",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the model folder</Text>,
        icon: <FolderIcon />,
      };
    },
  },
];

export const modelColumnMappers: ResourceActivityMapper[] = [
  {
    accessor: "custom_type",
    parser: (activity, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      if (parsedDiff.value === null) {
        return {
          message: (
            <Text>
              {activity.metadata.user_name} removed custom type <Code>{oldValue}</Code> from{" "}
              <Code>{activity.metadata.new.type}</Code> column <Code>{activity.metadata.new.name}</Code>
            </Text>
          ),
          icon: <Cog6ToothIcon />,
        };
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the type of column <Code>{activity.metadata.new.name}</Code> to{" "}
            <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <Cog6ToothIcon />,
      };
    },
  },
  {
    accessor: "type",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the type of column <Code>{activity.metadata.new.name}</Code> to{" "}
            <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <Cog6ToothIcon />,
      };
    },
  },
  {
    accessor: "alias",
    parser: (activity, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      if (parsedDiff.value === null || parsedDiff.value === "") {
        return {
          message: (
            <Text>
              {activity.metadata.user_name} removed alias <Code>{oldValue}</Code> from <Code>{activity.metadata.new.type}</Code>{" "}
              column <Code>{activity.metadata.new.name}</Code>
            </Text>
          ),
          icon: <QuestionMarkCircleIcon />,
        };
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the alias of column <Code>{activity.metadata.new.name}</Code> to{" "}
            <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <QuestionMarkCircleIcon />,
      };
    },
  },
  {
    accessor: "disable_preview",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} {parsedDiff.value ? "redacted" : "unredacted"} column{" "}
            <Code>{activity.metadata.new.name}</Code>
          </Text>
        ),
        icon: <QuestionMarkCircleIcon />,
      };
    },
  },
];

export const modelColumnIgnoreFields = ["top_k_sync_interval", "top_k_enabled", "raw_type"];

import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const LightningIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M11 15H6L13 1V9H18L11 23V15Z" />
    </Icon>
  );
};

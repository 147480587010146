import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const VariableIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} size={18} sx={{ pt: 1 }}>
      <path
        d="M9.11 8.175C7.4075 7.7325 6.86 7.275 6.86 6.5625C6.86 5.745 7.6175 5.175 8.885 5.175C10.22 5.175 10.715 5.8125 10.76 6.75H12.4175C12.365 5.46 11.5775 4.275 10.01 3.8925V2.25H7.76V3.87C6.305 4.185 5.135 5.13 5.135 6.5775C5.135 8.31 6.5675 9.1725 8.66 9.675C10.535 10.125 10.91 10.785 10.91 11.4825C10.91 12 10.5425 12.825 8.885 12.825C7.34 12.825 6.7325 12.135 6.65 11.25H5C5.09 12.8925 6.32 13.815 7.76 14.1225V15.75H10.01V14.1375C11.4725 13.86 12.635 13.0125 12.635 11.475C12.635 9.345 10.8125 8.6175 9.11 8.175Z"
        fill="#0F1825"
        fillOpacity="0.6"
      />
    </Icon>
  );
};

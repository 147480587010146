import { FC } from "react";

import { DestinationBadge } from "@hightouchio/ui";
import { Link } from "react-router-dom";
import { Image, Box, Text } from "theme-ui";

import placeholderDestination from "src/components/permission/destination.svg";
import { Maybe } from "src/graphql";
import { QueryType } from "src/types/models";
import { Arrow } from "src/ui/arrow";
import { Row } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";
import { SourceBadges, SourceIcon } from "src/utils/sources";
import { getObjectName } from "src/utils/syncs";

type Props = {
  model: any;
  source:
    | Maybe<{
        definition: {
          name: string;
          icon: string;
          isSampleDataSource: boolean;
        };
      }>
    | undefined;
  destination:
    | Maybe<{
        id: string;
        name: Maybe<string>;
        definition: {
          name: string;
          icon: string;
        };
      }>
    | undefined;
  sync: any;
};

export const SyncName: FC<Readonly<Props>> = ({ model, source, destination, sync }) => {
  return (
    <Row sx={{ alignItems: "center", flex: 1 }}>
      {model ? (
        <Link to={model?.query_type === QueryType.Visual ? `/audiences/${model?.id}` : `/models/${model?.id}`}>
          <Row
            sx={{
              alignItems: "center",
              color: "unset",
              p: 3,
              border: "small",
              borderColor: "white",
              ":hover": {
                bg: "base.0",
                borderColor: "primaries.3",
                borderRadius: 1,
              },
            }}
          >
            <SourceIcon placeholder source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>
                {model?.query_type === QueryType.Visual ? "Audience" : "Model"}
              </Text>
              <IntegrationRow>
                <Text sx={{ fontWeight: "bold" }}>{model?.name || "Private model"}</Text>
                <SourceBadges isSampleDataSource={source?.definition?.isSampleDataSource} sx={{ ml: 0 }} />
              </IntegrationRow>
            </Box>
          </Row>
        </Link>
      ) : (
        <Tooltip text="This source is only visible to some users">
          <Row sx={{ alignItems: "center", margin: 3 }}>
            <SourceIcon placeholder source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Model</Text>
              <Text sx={{ fontWeight: "bold" }}>Private Model</Text>
              <SourceBadges isSampleDataSource={source?.definition?.isSampleDataSource} />
            </Box>
          </Row>
        </Tooltip>
      )}

      <Arrow size={20} />

      <Row sx={{ alignItems: "center" }}>
        {destination ? (
          <Link to={`/destinations/${destination?.id}`}>
            <Row
              sx={{
                alignItems: "center",
                color: "unset",
                p: 3,
                border: "small",
                borderColor: "white",
                ":hover": {
                  bg: "base.0",
                  borderColor: "primaries.3",
                  borderRadius: 1,
                },
              }}
            >
              <Image alt={destination?.definition?.name} src={destination?.definition?.icon} width={32} />
              <Box sx={{ ml: 4 }}>
                <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Destination</Text>
                <IntegrationRow>
                  <Text sx={{ fontWeight: "bold" }}>{destination?.name || destination?.definition?.name}</Text>
                  {sync?.config?.object && <DestinationBadge>{getObjectName(sync.config.object)}</DestinationBadge>}
                </IntegrationRow>
              </Box>
            </Row>
          </Link>
        ) : (
          <Tooltip text="This destination is only visible to some users">
            <Row sx={{ alignItems: "center", margin: 3 }}>
              <Row sx={{ alignItems: "center" }}>
                <Image alt="Private destination" src={placeholderDestination} width={32} />
                <Box sx={{ ml: 4 }}>
                  <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Destination</Text>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ fontWeight: "bold" }}>Private Destination</Text>
                  </Row>
                </Box>
              </Row>
            </Row>
          </Tooltip>
        )}
      </Row>
    </Row>
  );
};

export const IntegrationRow = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: ["column", "column", "column", "row", "row"],
      gap: 2,
      alignItems: ["flex-start", "flex-start", "flex-start", "center", "center"],
      flexWrap: "wrap",
    }}
  >
    {children}
  </Box>
);

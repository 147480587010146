import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ForwardIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="m4 18 8.5-6L4 6v12zm9-12v12l8.5-6L13 6z"></path>
    </Icon>
  );
};

import { BaseType, Props, ValidatorSync } from "./base";

export interface UnknownType extends BaseType {
  type: "UNKNOWN";
}

export function unknown(opts?: Props<UnknownType>): UnknownType {
  return {
    ...opts,
    type: "UNKNOWN",
  };
}

export const validator: ValidatorSync<UnknownType> = (_) => {
  return (_) => {
    // always true
  };
};

import { useState, FC, useEffect } from "react";

import { Column, SectionHeading, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { uniqueId } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Outlet, Routes, Route, useOutletContext, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import monitoringImage from "src/components/extensions/assets/monitoring.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  DatadogCredentialsQuery,
  ResourcePermissionGrant,
  useCreateDatadogCredentialsMutation,
  useDatadogCredentialsQuery,
  useUpdateDatadogCredentialsMutation,
  useValidateDatadogCredentialsQuery,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { MonitoringIcon, DatadogIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";
import { Tabs } from "src/ui/tabs";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Monitoring: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch integrates with tools like Datadog so that you can monitor sync health in the same place as your other data infrastructure. Hightouch emits real-time events (like sync completion) and aggregated metrics (like the number of rows processed)."
              icon={MonitoringIcon}
              image={monitoringImage}
              integrations={[{ name: "Datadog", icon: DatadogIcon }]}
              subtitle="Send metrics and events to other tools"
              title="Monitoring"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: loading } = useDatadogCredentialsQuery(undefined, {
    select: (data) => data?.datadog_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Monitoring" }]} title="Monitoring - Extensions">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/monitoring");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading }} />
    </Page>
  );
};

const siteOptions = [
  { label: "US1", value: "datadoghq.com" },
  { label: "US3", value: "us3.datadoghq.com" },
  { label: "US5", value: "us5.datadoghq.com" },
  { label: "EU1", value: "datadoghq.eu" },
  { label: "US1-FED", value: "app.ddog-gov.com" },
];

type DatadogCredentials = DatadogCredentialsQuery["datadog_credentials"][0];

interface OutletContext {
  credentials: DatadogCredentials;
  loading: boolean;
}

const validation = yup
  .object({
    api_key: yup.string(),
    site: yup.string().required(),
  })
  .required();

const Configuration: FC = () => {
  const client = useQueryClient();
  const { credentials, loading } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const [error, setError] = useState<string | undefined>();
  const { mutateAsync: create } = useCreateDatadogCredentialsMutation();
  const { mutateAsync: update } = useUpdateDatadogCredentialsMutation();

  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const site = watch("site");
  const apiKey = watch("api_key");

  const validate = async () => {
    const { ValidateDatadogCredentials } = await client.fetchQuery(uniqueId(), {
      queryFn: useValidateDatadogCredentialsQuery.fetcher({
        site: site,
        apiKey: apiKey,
      }),
    });

    if (ValidateDatadogCredentials?.success) {
      setError(undefined);
      return true;
    }

    setError("Datadog configuration is invalid");

    toast({
      id: "save-monitoring",
      title: "There was an error saving your configuration",
      variant: "error",
    });

    return false;
  };

  const submit = async (data) => {
    try {
      const valid = await validate();

      if (!valid) {
        return;
      }

      if (!credentials) {
        await create({
          input: data,
        });
      } else {
        await update({
          id: credentials.id,
          credentials: data,
        });
      }

      toast({
        id: "save-monitoring",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-monitoring",
        title: "There was an error saving your configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      site: credentials?.site ?? "",
      api_key: "",
    });
  }, [credentials]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Column gap={8}>
            <SectionHeading>Datadog configuration</SectionHeading>

            <Field error={errors.site?.type === "required" && "Site is required"} label="Site">
              <Controller
                control={control}
                name="site"
                render={({ field }) => (
                  <Select
                    options={siteOptions}
                    placeholder="Select a datadog site for your datadog account"
                    value={field.value}
                    onChange={(selected) => {
                      field.onChange(selected?.value);
                    }}
                  />
                )}
              />
            </Field>

            <Field error={errors.api_key?.type === "required" && "API key is required"} label="API key">
              <Controller
                control={control}
                name="api_key"
                render={({ field }) => (
                  <SensitiveField hideSecret={Boolean(credentials?.id)} value={field.value} onChange={field.onChange} />
                )}
              />
            </Field>

            <FieldError error={error} />
          </Column>
        </Container>

        <SidebarForm
          buttons={
            <Permission>
              <Button loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
                Save
              </Button>
            </Permission>
          }
          docsUrl="/syncs/datadog"
          name="Datadog"
        />
      </Row>
    </PermissionProvider>
  );
};

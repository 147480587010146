import { useEffect, FC, useState } from "react";

import { useToast } from "@hightouchio/ui";
import { useNavigate, useParams } from "react-router-dom";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, useModelQuery, ModelQuery as ModelGqlQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

export const ModelQueryWrapper: FC = () => {
  const { model_id: modelId } = useParams<{ model_id: string }>();
  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: modelId ?? "",
    },
    { enabled: Boolean(modelId) },
  );
  const navigate = useNavigate();

  if (modelLoading || !modelData) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={modelData.segments_by_pk?.draft || false}
      resourceId={modelId}
      resourceType={ResourceToPermission.Model}
      onPublish={() => navigate(`/models/${modelId}`)}
    >
      <ModelQuery modelData={modelData} modelLoading={modelLoading} />
    </DraftProvider>
  );
};

interface Props {
  modelData: ModelGqlQuery | undefined;
  modelLoading: boolean;
}

const ModelQuery: FC<Props> = ({ modelData, modelLoading }: Props) => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const { queryState, initQueryState, setSQL, setDBTModel, setLookerLook, setTable, setCustomQuery, setSigma, isQueryDefined } =
    useQueryState();

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    setSubmitDraftModalOpen,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const update = useUpdateQuery();

  const [model, setModel] = useState<ModelGqlQuery["segments_by_pk"]>();
  const source = model?.connection;
  const type = model?.query_type as QueryType;

  useEffect(() => {
    const model = modelData?.segments_by_pk;
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      setModel(copy as ModelGqlQuery["segments_by_pk"]);
    } else {
      setModel(model);
    }
  }, [modelData, editingDraft]);

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
    asyncPagination,
    rowsCount,
    page,
    setPage,
  } = useModelRun(type, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const save = async (data) => {
    await update({ model, queryState, columns: data?.columns ?? rawColumns, overwriteMetadata: true });

    if (workspace?.approvals_required) {
      setSubmitDraftModalOpen(true);
    } else {
      toast({
        id: "update-model-query",
        title: "Query was updated",
        variant: "success",
      });
    }
  };

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  if (modelLoading) {
    return <PageSpinner />;
  }

  const modelId = modelData?.segments_by_pk?.id;

  const link = `/models/${modelId}`;

  return (
    <Page
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Model}
            setEditingDraft={setEditingDraft}
            sx={{ top: 0 }}
            onViewDraft={onViewDraft}
          />
        )
      }
      title={`${modelData?.segments_by_pk?.name ?? "Unnamed model"} - Models`}
      crumbs={[
        { label: "Models", link: "/models" },
        {
          label: model?.name ?? "",
          link: editingDraftChanges ? `${link}?editing=true` : link,
        },
        {
          label: "Query",
        },
      ]}
    >
      <ExploreWithSave
        asyncPagination={asyncPagination}
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isQueryDefined={isQueryDefined}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        page={page}
        reset={resetRunState}
        rows={rows}
        rowsCount={rowsCount}
        runQuery={runQuery}
        source={source}
        type={type}
        onPageChange={setPage}
        onSave={save}
        {...queryState}
        rowsPerPage={100}
        onCustomQueryChange={setCustomQuery}
        onDBTModelChange={setDBTModel}
        onLookerLookChange={setLookerLook}
        onSQLChange={setSQL}
        onSigmaChange={setSigma}
        onTableChange={setTable}
      />
    </Page>
  );
};

import { ReactElement } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Spinner, Box as HightouchUiBox, RadioGroup as HightouchUiRadioGroup, Button, Radio } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Box, Grid } from "theme-ui";

import { ReloadButton } from "src/ui/button";
import { RadioGroup } from "src/ui/radio";

import { ExtendedOption } from "../../../../formkit";

export type Props = {
  useHightouchUi?: boolean;
  options: ExtendedOption[];
  value: string[];
  /**
   * A prop that determines whether the parent options of the child option will be shown
   * as selected. This does not affect the logic and is for visual purposes only.
   */
  allNestedSelected: boolean;
  loading: any;
  reload: () => void;
  onChange: (value: string[]) => void;
};

export type ChildOptionProps = Pick<Props, "onChange" | "value" | "allNestedSelected"> & {
  useHightouchUi?: boolean;
  option: ExtendedOption;
  parentOptions: string[];
  depthTracker: number;
};

export const NestedRadioGroup = ({
  useHightouchUi = false,
  options,
  value,
  allNestedSelected,
  loading,
  reload,
  onChange,
}: Props) => {
  if (loading) {
    return <Spinner />;
  }

  if (useHightouchUi) {
    return (
      <HightouchUiBox display="grid" gap={2} gridTemplateColumns="minmax(0, 1fr) max-content">
        <HightouchUiBox
          borderColor="gray.300"
          borderRadius="md"
          borderWidth="1px"
          display="flex"
          flex="1"
          flexDirection="column"
          gap={3}
          maxHeight="400px"
          minWidth={0}
          overflow="auto"
          p={3}
        >
          {options?.map((option, index) => (
            <ChildOption
              key={index}
              useHightouchUi
              allNestedSelected={allNestedSelected}
              depthTracker={0}
              option={option}
              parentOptions={[String(option.value)]}
              value={value}
              onChange={onChange}
            />
          ))}
        </HightouchUiBox>

        {typeof reload === "function" && (
          <Button icon={ArrowPathIcon} onClick={reload}>
            Refresh
          </Button>
        )}
      </HightouchUiBox>
    );
  }

  return (
    <Row>
      <Box sx={{ position: "relative", maxHeight: "400px", border: "small", overflow: "auto", p: 3, borderRadius: 4, flex: 1 }}>
        <Grid gap={2}>
          {Array.isArray(options) &&
            options?.map((option, i) => {
              return (
                <ChildOption
                  key={i}
                  allNestedSelected={allNestedSelected}
                  depthTracker={0}
                  option={option}
                  parentOptions={[String(option.value)]}
                  value={value}
                  onChange={onChange}
                />
              );
            })}
        </Grid>
      </Box>
      {reload && <ReloadButton loading={loading} sx={{ ml: 2 }} onClick={reload} />}
    </Row>
  );
};

const ChildOption = ({
  useHightouchUi = false,
  option,
  value = [],
  parentOptions,
  depthTracker,
  allNestedSelected = false,
  onChange,
}: ChildOptionProps) => {
  const children: ReactElement[] = [];
  if (option?.childrenOptions) {
    let index = 0;

    for (const childAccount of Object.values(option?.childrenOptions) || []) {
      children.push(
        <ChildOption
          key={index++}
          allNestedSelected={allNestedSelected}
          depthTracker={depthTracker + 1}
          option={childAccount}
          parentOptions={[...parentOptions, String(childAccount.value)]}
          useHightouchUi={useHightouchUi}
          value={value}
          onChange={onChange}
        />,
      );
    }
  }

  /**
   * We use this comparison to determine whether RadioGroup's prop 'value' should be false (unselected) or
   * the selected option. This is done at every nested layer to achieve the visual result that every parent option
   * of the selected child option is also seen as selected.
   *
   * For example, selecting option A1 in the following nested radiogroup will achieve the result:
   * (x) - A0
   *  (x) - A1
   * ( ) - B
   * ( ) - C
   **/
  const hasSameParent = isEqual(parentOptions, value.slice(0, depthTracker + 1));

  if (useHightouchUi) {
    return (
      <HightouchUiBox>
        <HightouchUiRadioGroup
          isDisabled={option.disabled}
          value={allNestedSelected ? String(hasSameParent && value[depthTracker]) : value[value.length - 1]}
          onChange={() => {
            onChange(parentOptions);
          }}
        >
          <Radio label={option.label} value={String(option.value)} />
        </HightouchUiRadioGroup>

        {children.length > 0 && (
          <HightouchUiBox
            borderColor="gray.300"
            borderLeftWidth="2px"
            display="flex"
            flexDirection="column"
            gap={3}
            ml={2}
            mt={3}
            pl={4}
          >
            {children}
          </HightouchUiBox>
        )}
      </HightouchUiBox>
    );
  }

  return (
    <Box>
      <RadioGroup
        disabled={option.disabled}
        options={[{ label: option.label, value: option.value }]}
        value={allNestedSelected ? hasSameParent && value[depthTracker] : value[value.length - 1]}
        onChange={() => {
          onChange(parentOptions);
        }}
      />
      {!!children?.length && (
        <Box sx={{ ml: 4, pl: 1, mt: 1, borderLeft: "medium", borderColor: "blacks.0" }}>
          <Grid gap={1}>{children}</Grid>
        </Box>
      )}
    </Box>
  );
};

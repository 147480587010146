import { forwardRef } from "react";

import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { CheckCircleIcon } from "src/ui/icons";

export const Option = forwardRef<HTMLDivElement, any>(({ option, onClick, disabled, selected, multi = false }, ref) => {
  return (
    <Row
      ref={ref}
      sx={{
        alignItems: "center",
        py: "2px",
        px: 1,
        cursor: disabled ? "not-allowed" : "pointer",
        ":hover": {
          "> div": { bg: disabled || selected ? undefined : "base.1" },
        },
      }}
      onClick={() => onClick(option)}
    >
      <Row
        sx={{
          py: 2,
          px: 2,
          flex: 1,
          alignItems: "center",
          fontSize: 1,
          borderRadius: 1,
          transition: "100ms color, 100ms background-color",
          color: disabled ? "gray.200" : selected ? "forest" : undefined,
          bg: selected ? "avocado" : undefined,
          ...(option.sx || {}),
        }}
      >
        {option.render ? (
          option.render({})
        ) : (
          <Column>
            <Row>
              {option.render ? option.render({}) : option.label}
              {selected && multi && <CheckCircleIcon color="forest" size={16} sx={{ ml: "auto" }} />}
            </Row>
            {option.subLabel && <Text sx={{ color: "secondaries.8", fontSize: 0 }}>{option.subLabel}</Text>}
          </Column>
        )}
      </Row>
    </Row>
  );
});

Option.displayName = "Option";

import { FC } from "react";

import * as Yup from "yup";

import { IdMappingField } from "src/components/destinations/id-mapping-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useGainsightPxAccountAttributesQuery, useGainsightPxUserAttributesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Label } from "src/ui/label";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("upsert"),
  object: Yup.string().required().default("users"),
  externalIdMapping: Yup.object().when("mode", {
    is: "upsert",
    then: COMMON_SCHEMAS.externalIdMapping,
    otherwise: Yup.object().notRequired().default(undefined),
  }),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Upsert", value: "upsert" }];

const OBJECTS = [
  { label: "Users", value: "users" },
  { label: "Accounts", value: "account" },
];

export const GainsightpxForm: FC = () => {
  const { config, setConfig, destination } = useDestinationForm();

  const {
    data: userAttributesData,
    error: userAttributesError,
    isFetching: loadingUserAttributes,
    refetch: getUserAttributes,
  } = useGainsightPxUserAttributesQuery({ destinationId: String(destination?.id) });

  const {
    data: accountAttributesData,
    error: accountAttributesError,
    isFetching: loadingAccountAttributes,
    refetch: getAccountAttributes,
  } = useGainsightPxAccountAttributesQuery({ destinationId: String(destination?.id) });

  const userAttributeList = userAttributesData?.gainsightpxGetUserAttributes?.fields;
  const userAttributeOptions =
    userAttributeList?.map((a) => ({ label: a.name, value: a.id, type: a.standardType, origin: a.origin })) || [];

  const accountAttributeList = accountAttributesData?.gainsightpxGetAccountAttributes?.fields;

  const accountAttributeOptions =
    accountAttributeList?.map((a) => ({ label: a.name, value: a.id, type: a.standardType, origin: a.origin })) || [];

  const EXTERNAL_FIELDS =
    config?.object === "users"
      ? [
          { label: "Identify ID", value: "identifyId" },
          { label: "User Email", value: "email" },
        ]
      : [{ label: "Account ID", value: "id" }];

  return (
    <>
      <ObjectField
        options={OBJECTS}
        onChange={(object) => {
          setConfig({ ...config, object: object });
        }}
      />

      <ModeField
        options={MODES}
        onChange={(mode) => {
          setConfig({
            ...config,
            object: config?.object,
            mode,
          });
        }}
      />

      {config?.mode && config?.object && (
        <>
          <Section>
            <IdMappingField options={EXTERNAL_FIELDS} />
          </Section>

          <Section>
            <Label description="A property/tag key is required to insert into Gainsight PX" size="large">
              Which environment would you like to sync to?
            </Label>
            <Field required label="Tag Key">
              <Input
                defaultValue={config?.propertyKey}
                placeholder="Property Key"
                sx={{ width: "240px" }}
                onChange={(propertyKey) => setConfig({ ...config, propertyKey })}
              />
            </Field>
          </Section>

          {config?.object === "users" && (
            <>
              <Section>
                <MappingsField
                  error={userAttributesError?.message}
                  loading={loadingUserAttributes}
                  options={[...userAttributeOptions].filter((a) => a.origin === "DEFAULT")}
                  reload={getUserAttributes}
                />
              </Section>

              <Section>
                <MappingsField isCustom options={[...userAttributeOptions].filter((a) => a.origin === "CUSTOM")} />
              </Section>
            </>
          )}
          {config?.object === "account" && (
            <>
              <Section>
                <MappingsField
                  error={accountAttributesError?.message}
                  loading={loadingAccountAttributes}
                  options={[...accountAttributeOptions].filter((a) => a.origin === "DEFAULT")}
                  reload={getAccountAttributes}
                />
              </Section>

              <Section>
                <MappingsField isCustom options={[...accountAttributeOptions].filter((a) => a.origin === "CUSTOM")} />
              </Section>
            </>
          )}

          <DeleteField modes={["delete"]} />
        </>
      )}
    </>
  );
};

export default {
  validation,
  form: GainsightpxForm,
};

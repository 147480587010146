import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Text } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

export const SaveWarning = ({ dirty: _dirty }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [destination, _setDestination] = useState<string | undefined>();

  useEffect(() => {
    if (confirmed && destination) {
      navigate(destination);
    }
  }, [confirmed, destination]);

  return (
    <>
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Go back
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                setConfirmed(true);
              }}
            >
              Yes, continue
            </Button>
          </>
        }
        isOpen={isOpen}
        sx={{ width: "552px" }}
        title="Lose changes?"
        onClose={() => setIsOpen(false)}
      >
        <Text sx={{ fontSize: 2 }}>You haven’t saved your latest changes. Are you sure you want to leave?</Text>
      </Modal>
    </>
  );
};

import { useState, FC, ReactNode, useRef, useEffect } from "react";

import { Box, Column, Row, Text, Tooltip } from "@hightouchio/ui";
import { NodeToolbar } from "@reactflow/node-toolbar";
import { useNavigate } from "react-router-dom";
import { Handle as ReactflowHandle, HandleProps, Position } from "reactflow";

import { ResourcePermissionGrant } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { useGraphContext } from "src/pages/schema/graph/graph";
import { getParams, OPTIONS, schemaIcons } from "src/pages/schema/utils";
import { SchemaModelType } from "src/types/schema";
import { PlusIcon } from "src/ui/icons";
import { TextWithTooltip } from "src/ui/text";

const NodeGenerator = ({ type }: { type: SchemaModelType }) => {
  const node = (node): JSX.Element => {
    const { id, data } = node;
    const navigate = useNavigate();
    const [showAdd, setShowAdd] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { queryString } = getParams();
    const { setHighlight, highlight, highlightedNodes } = useGraphContext();
    const isActive = showAdd || showMenu;
    const isDimmed = Boolean(highlight && !highlightedNodes.includes(id));

    return (
      <Column
        display="contents"
        onMouseEnter={() => {
          setShowAdd(true);
          setHighlight(node);
        }}
        onMouseLeave={() => {
          if (!showMenu) {
            setShowAdd(false);
          }
          setHighlight(null);
        }}
      >
        <Handle isDimmed={isDimmed} isVisible={data.isTarget} type="target" position={Position.Left} />
        <Handle isDimmed={isDimmed} isVisible={data.isSource} type="source" position={Position.Right} />
        <Node
          isSelected={data.isSelected}
          isActive={isActive}
          isEphemeral={data.isEphemeral}
          isDimmed={isDimmed}
          type={type}
          label={data.label}
          onClick={() => {
            navigate({ pathname: "view/query", search: `${queryString}&id=${id}` });
          }}
        >
          {isActive && (
            <MenuButton
              isActive={showMenu}
              onClick={() => {
                setShowMenu((state) => !state);
              }}
            />
          )}
          <Menu
            isVisible={showMenu}
            onChange={async (option) => {
              navigate({ pathname: "new", search: `${queryString}&id=${id}&type=${option.type}` });
            }}
            onClose={() => {
              setShowMenu(false);
              setShowAdd(false);
            }}
          />
        </Node>
      </Column>
    );
  };

  return node;
};

export const ParentNode = NodeGenerator({ type: SchemaModelType.Parent });
export const EventNode = NodeGenerator({ type: SchemaModelType.Event });
export const RelatedNode = NodeGenerator({ type: SchemaModelType.Related });

export const Node: FC<
  Readonly<{
    type: SchemaModelType;
    label?: string;
    isEphemeral?: boolean;
    onClick: () => void;
    children?: ReactNode;
    isActive?: boolean;
    isSelected?: boolean;
    isDimmed?: boolean;
  }>
> = ({ type, children, onClick, label, isEphemeral, isActive, isSelected, isDimmed }) => {
  const highlight = isSelected || isEphemeral;

  return (
    <Column
      height="100%"
      width="100%"
      borderRadius="md"
      bg="white"
      pl={4}
      pr={6}
      opacity={isDimmed ? 0.25 : 1}
      boxShadow={isActive || isSelected ? "lg" : "md"}
      border="1px"
      borderColor={highlight ? "primary.base" : "base.border"}
      outline="2px solid"
      outlineColor={highlight ? "teal.200" : "transparent"}
      transition="outline-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out"
      position="relative"
      justifyContent="stretch"
    >
      <Row onClick={onClick} height="100%" align="center" gap={2}>
        <Box as="img" src={schemaIcons[type]} alt={type} />
        <Column overflow="hidden">
          <Text size="sm" fontWeight="semibold" textTransform="uppercase" color="text.secondary">
            {type} model
          </Text>
          {label ? (
            <TextWithTooltip fontWeight="medium" isTruncated message={label}>
              {label}
            </TextWithTooltip>
          ) : (
            <Text color="text.tertiary" fontWeight="medium">
              Pending...
            </Text>
          )}
        </Column>
      </Row>
      {children}
    </Column>
  );
};

export const Handle: FC<Readonly<HandleProps & { isVisible?: boolean; isDimmed?: boolean }>> = ({
  isVisible,
  isDimmed,
  ...props
}) => {
  return (
    <ReactflowHandle
      {...props}
      style={{
        visibility: isVisible ? "visible" : "hidden",
        transition: "opacity 200ms ease-in-out",
        opacity: isDimmed ? 0.25 : 1,
        backgroundColor: "white",
        width: "8px",
        height: "8px",
        zIndex: 1,
        border: "2px solid var(--chakra-colors-teal-400)",
        left: props.position === Position.Left ? 0 : undefined,
        right: props.position === Position.Right ? 0 : undefined,
        transform: props.position === Position.Left ? "translate(-50%, -50%)" : "translate(50%, -50%)",
      }}
    />
  );
};

export const Menu = ({ isVisible, onClose, onChange }) => {
  const updatePermission = useHasPermission([{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!ref?.current?.contains(e.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("click", handleOutsideClick, false);
    } else {
      document.removeEventListener("click", handleOutsideClick, false);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <NodeToolbar isVisible position={Position.Right} offset={-16}>
      <Column
        ref={ref}
        width="360px"
        border="1px"
        borderColor="base.border"
        borderRadius="md"
        bg="white"
        py={2}
        pos="absolute"
        top={6}
        sx={{ "& > span": { width: "100%" } }}
      >
        {OPTIONS.map((option) => (
          <Tooltip
            key={option.type}
            message="You do not have permission to perform this action."
            isDisabled={updatePermission.hasPermission}
          >
            <Row
              width="100%"
              gap={2}
              align="flex-start"
              py={2}
              px={2}
              onClick={async () => {
                onClose();
                onChange(option);
              }}
              cursor="pointer"
              _hover={{
                bg: "gray.100",
              }}
              pointerEvents={updatePermission.hasPermission ? "auto" : "none"}
              opacity={updatePermission.hasPermission ? 1 : 0.5}
            >
              <Box as="img" src={option.icon} alt={option.type} width="20px" />
              <Column>
                <Text size="md" fontWeight="medium">
                  {option.label}
                </Text>
                <Text size="sm" color="text.secondary">
                  {option.description}
                </Text>
              </Column>
            </Row>
          </Tooltip>
        ))}
      </Column>
    </NodeToolbar>
  );
};

const MenuButton = ({ onClick, isActive }) => {
  return (
    <Row
      position="absolute"
      right={0}
      top="50%"
      width="32px"
      height="32px"
      borderRadius="50%"
      bg="white"
      justify="center"
      align="center"
      border="1px"
      borderColor="base.border"
      outline="3px solid"
      outlineColor={isActive ? "teal.200" : "transparent"}
      transform={isActive ? "translate(50%,-50%) rotate(45deg)" : "translate(50%,-50%)"}
      transition="transform 0.2s, outline-color 0.2s, background-color 0.2s"
      zIndex={2}
      _hover={{
        bg: "teal.50",
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <PlusIcon size={16} color="var(--chakra-colors-teal-base)" />
    </Row>
  );
};
